import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MaterialsComponent } from 'app/layout/actions/materials/materials.component';
import { ComponentDialogComponent } from 'app/shared/dialogs/component/component-dialog.component';
import { MaterialsService } from 'app/shared/services/materials/materials.service';
import Utils from 'app/shared/utils/utils';

/**
 * @ignore
 */
@Component({
  selector: 'app-materials-widget',
  templateUrl: './materials-widget.component.html',
  styleUrls: ['./materials-widget.component.scss']
})
export class MaterialsWidgetComponent implements OnInit {

  @Input() jobId;

  utils = Utils;

  list = [];

  constructor(private materialService: MaterialsService, public dialog: MatDialog) { }

  ngOnInit() {
    if (this.jobId) {
      this.materialService.getMaterialsByAction(this.jobId).then(async data => {
        this.list = [];
        let over = false;
        Utils.loadingData = true;
        while (!over) {
          this.list.push(...data.result);

          if (data.offset + data.limit < data['total-count']) {
            data = await this.materialService.getMaterialsByAction(this.jobId, data.offset + data.limit);
          } else {
            over = true;
            Utils.loadingData = false;
          }
        }
      });
    }
  }

  openAssetDialog(fileId) {
    let data = {
      component: MaterialsComponent,
      assetData: {
        actionId: this.jobId,
        materialId: fileId,
        materials: this.list
      }
    }

    const assetDialog = this.dialog.open(ComponentDialogComponent, { width: '90vw', height: '90vh', data: data });
    assetDialog.afterClosed().subscribe(result => { });

  }

}
