import { Component, Inject, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

/**
 * This component defines the view of the tag dialog
 */
@Component({
  selector: 'app-tags-dialog',
  templateUrl: './tags-dialog.component.html',
  styleUrls: ['./tags-dialog.component.scss']
})
export class TagsDialogComponent {

  /**
   * {@link paginator} pagination.
   */
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  /**
   * Download formats.
   */
  public downloadFormats: string[] = [];
  /**
   * Format
   */
  public format: string;

  /**
   * Page size. Number of pages.
   */
  public pageSize = 20;
  /**
   * Tags.
   */
  public tags = [];
  /**
   * Showed tags.
   */
  public showedTags = [];
  /**
   * {@link paginator}
   */
  paginatorCreator : boolean = false;

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data Object with tags and filters
   */
  constructor(
    private dialogRef: MatDialogRef<TagsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
      this.pageSize = (window.innerWidth <= 1000) ? 10 : 20;

      this.tags = data['tags'].sort((x,y) => (x.name.toUpperCase() > y.name.toUpperCase() ? 1 : -1));
      this.showedTags = this.tags.slice(0, this.pageSize);

      this.dialogRef.afterClosed().subscribe(result => {
        if (result){
        }
      });
  }

  /**
   * Pagination logic. 
   */
  ngDoCheck(){
    if (this.paginator != null && !this.paginatorCreator ){
      this.paginator.page.subscribe(paginator => {
        this.showedTags = this.tags.slice(paginator.pageIndex * paginator.pageSize, (paginator.pageIndex + 1)* paginator.pageSize);
      });
      this.paginatorCreator = true;
    }
  }

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Change filter
   * @param tag 
   */
  changeFilter(tag) {
    if (this.isSelected(tag)) {
      this.data.c.filter.tags.splice(this.data.filter.indexOf(tag), 1)
    } else {
      this.data.c.filter.tags.push(tag);
    }

    this.data.c.getAssets();
  }

  /**
   * Check if the tag is selected
   * @param tag Tag
   * @returns {boolean} true | false if tag is selected.
   */
  isSelected(tag) {
    return this.data.c.filter.tags.some(x => x.id === tag.id);
  }

}
