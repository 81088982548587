import { Injectable } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';

import { LiquidCacheService } from 'ngx-liquid-cache';

import { UserService } from '../user/user.service';
import { Material } from 'app/shared/model/material.model';
import { OCService } from '../satellites/oc.service';

import Utils from 'app/shared/utils/utils';
import { HCMSService } from '../satellites/hcms.service';
import { ActionsService } from '../actions/actions.service';
import { AppSettings } from 'app/shared/app.settings';
import { Mail } from 'app/shared/model/mail.model';
import { MailService } from '../mail/mail.service';

/**
 * Service defined to manage asset ({@link Material}) statuses / workflows 
 */
@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  /**
   * Censhare workflow
   */
  private censhareWorkflow = [
    { "censhareId": AppSettings.DEFAULT_WORKFLOW_STEP, "approvalState": "pending" },
    { "censhareId": AppSettings.REJECTED_WORKFLOW_STEP, "approvalState": "rejected" },
    { "censhareId": AppSettings.APPROVED_WORKFLOW_STEP, "approvalState": "approved" }
  ];

  /**
   * Constructor
   * @param userService Service with the functions related to the users.
   * @param ocService Service whit the functions related to the online channel.
   * @param hcmsService Service whit the functions related to censhare using restangular.
   * @param actionsService Service with the functions related to the actions.
   * @param sanitizer Service with the functions related to the dom sanitizer.
   * @param cache Service with the functions related to the cache.
   * @param mailService Service with the functions related to the mails.
   */
  constructor(private userService: UserService,
              private ocService: OCService,
              private hcmsService: HCMSService,
              private actionsService: ActionsService,
              private sanitizer: DomSanitizer,
              private cache: LiquidCacheService,
              private mailService: MailService) {}

  /**
   * 
   * @param {Material} asset 
   */
  createApproval(asset: Material) {
    let url = this.cache.get('userLinks' + asset.approvalAssetId);

    if (!url) {
      let urlParams = "";
      urlParams += "/projectId/" + asset.action.approvalprojectId;
      urlParams += "/assetId/" + asset.approvalAssetId;
      urlParams += "/assetName/" + Utils.getEncodedValue(asset.name);
      urlParams += "/email/" + this.userService.getCurrentUser().email;
      urlParams += "/firstName/" + this.userService.getCurrentUser().firstName;
      urlParams += "/lastName/" + this.userService.getCurrentUser().lastName;

      if (asset.approvalReviewId) {
        urlParams += "/reviewId/" + asset.approvalReviewId;
      }

      this.ocService.get().one("soap/createApproval/r/" + this.randomHash() + "/env/" + environment.env + urlParams).get().subscribe(result => {
        asset.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.userLink ? result.userLink : asset.approvalDeeplink);

        if (result.userLink) {
          this.cache.set('userLinks' + asset.approvalAssetId, result.userLink);
        }
      });

    } else {
      asset.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  /**
   * Get random hash.
   * @returns Random hash
   */
  private randomHash() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  /**
   * Activate user.
   * @param {boolean} activated Indicates if you want to activate the user.
   * @returns 
   */
  activateUser (activated: boolean) {
    return this.ocService.get().one("soap/activateUser/r/" + this.randomHash() + "/env/" + environment.env + "/email/" + this.userService.getCurrentUser().email + "/active/" + (activated ? "true" : "false")).get();
  }

  /**
   * Lock asset
   * @param {string} assetId Asset id 
   * @param {number} lock  workflow step
   * @returns 
   */
  lockAsset (assetId, lock) {
    return this.ocService.get().one("soap/lockAsset/r/" + this.randomHash() + "/env/" + environment.env + "/assetId/" + assetId + "/lock/" + lock).get();
  }

  /**
   * 
   * @param {Material} asset Asset (Material) 
   * @param {string} state Material state. ('pending')
   */
  setApproval(asset, state, updateAllParentRelations = false, allParents = []) {
    this.hcmsService.get().one(Utils.getEntity(asset.type), asset.id).get()
      .subscribe(approval => {

        let now = Date.now();

        let currentUser = this.userService.getCurrentUser();
        if (!currentUser.display_name) {
          currentUser.display_name = currentUser.firstName + ' ' + currentUser.lastName;
        }

        let step;

        switch (state) {
          case 'rejected':
            step = Utils.getRejectedWorkflowStep(asset.type);
            break;
          case 'approved':
            step = Utils.getApprovedWorkflowStep(asset.type);
            break;
          case 'pending':
            step = Utils.getDefaultWorkflowStep(asset.type);
            break;
        }

        if (!step) {
          step = this.getCenshareWorkflow(state);
        }
        approval.workflowStep = step;
        approval.approvalBy = currentUser.display_name;
        approval.approvalTime = now;
        approval.workflowTarget = approval.createdBy;
        approval.step_time = now;
        if (updateAllParentRelations) approval.parents = [...approval.parents, ...allParents.filter(x => !approval.parents.includes(x))];

        if (approval.approvalFeedback) {
          let lastComment = approval.approvalFeedback.reduce((acc, val) => acc.concat(val.reply), approval.approvalFeedback).filter(x => x != null).sort((a,b) => b.date.localeCompare(a.date))[0];

          lastComment.approvalBy = currentUser.display_name;
          lastComment.approvalTime = now;
          lastComment.approvalState = state;
        } else if (approval.communication) {
          let lastComment = approval.communication.filter(x => x != null).sort((a,b) => b.timestamp - a.timestamp)[0];

          lastComment.approvalBy = currentUser.display_name;
          lastComment.approvalTime = now;
          lastComment.approvalState = state;
        }

        approval.put().subscribe(x => {

          asset.saving = false;
          Utils.updateStatus = true;
          asset = Object.assign(asset, x);
          asset.autoUpdate = Date.now();

          if (asset.approvalAssetId && asset.workflowStep == Utils.getApprovedWorkflowStep(asset.type)) {
            this.lockAsset(asset.approvalAssetId, true);
          }
          asset.parents_info.forEach(parentAsset => {
            if(parentAsset.type.split('.').includes('order')){

              this.actionsService.getAction(parentAsset).then(action => {
                let pm = this.userService.getUserData(action[UserService.PROJECT_MANAGER]);
  
                let template = "";
  
                if (pm && pm.id) {
                  if (action.materials.every(mat => mat.workflowStep == Utils.getApprovedWorkflowStep(mat.type))) {
                    template = AppSettings.MAIL_NOMATERIALACTIONAPPROVED;
                  } else if (!action.materials.some(mat => mat.workflowStep == Utils.getDefaultWorkflowStep(mat.type))) {
                    template = AppSettings.MAIL_NOMATERIALACTION;
                  }
  
                  if (template) {
                    let mail: Mail = new Mail(template + '_' + action.id + '_' + pm.id, template, +action.id, +pm.id);
                    this.mailService.saveMail(mail);
  
                    if (action[UserService.CO_MANAGER] && action[UserService.CO_MANAGER].length > 0) {
                      action[UserService.CO_MANAGER].forEach(co_pm => {
                        let mail: Mail = new Mail(template + '_' + action.id + '_' + co_pm, template, +action.id, +co_pm);
                        this.mailService.saveMail(mail);
                      });
                    }
                  }
                }
              });
            }
          });

        });
      });
  }

  /**
   * Obtain the workflow depending on the state
   * 
   * @param {string} state The state of the asset (approval) 
   * @returns Number corresponding to the workflow defined in censhareWorkflow
   */
  private getCenshareWorkflow(state): number {
    return this.censhareWorkflow.find(a => a.approvalState == state) != null ? this.censhareWorkflow.find(a => a.approvalState == state).censhareId : this.censhareWorkflow.find(a => a.approvalState == 'pending').censhareId;
  }

}
