import { environment } from './../../../environments/environment';
import { ErrorHandler, Injectable} from '@angular/core';
import { ErrorsService } from '../services/errors/errors.service';
/**
 * @ignore
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private errorsService:ErrorsService){}

  handleError(error) {

    if (environment.env === 'dev') {
      console.log(error);
    }

    this.errorsService.showInternalError(error);


     // IMPORTANT: Rethrow the error otherwise it gets swallowed
     //throw error;
  }

}
