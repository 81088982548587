import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LiquidCache } from 'ngx-liquid-cache';
import { OCService } from '../satellites/oc.service';
import { AppSettings } from 'app/shared/app.settings';
import Utils from 'app/shared/utils/utils';

@Injectable()
export class WorkflowService {

  /**
   * Workflows steps
   */
  private workflows = [];
  /**
   * Workflow steps
   */
  private workflowSteps = [];
  /**
   * Workflow states
   */
  private workflowStates = [];

  /**
   * Status: Pending
   */
  static readonly STATUS_PENDING: string = 'Pending';

  /**
   * Status: Rejected
   */
  static readonly STATUS_REJECTED: string = 'Rejected';

  /**
   * Status: Approved
   */
  static readonly STATUS_APPROVED: string = 'Approved';

  actionInApproval = { 'workflow': AppSettings.WORKFLOW_ACTION, 'step': 10 };

  /**
   * Constructor
   * @param ocService Service with the functions related to the online channel.
   */
  constructor(private ocService: OCService) {

    this.getWorkflowStates().subscribe(data => this.workflowStates = data.filter(state => state.enabled == "1"));
    this.getWorkflows().subscribe(data => this.workflows = data);
    this.getWorkflowSteps().subscribe(data => this.workflowSteps = data);

  }

  /**
   * Gets workflow states and stores it in the cache
   * @returns the workflow state list
   */
  @LiquidCache('workflowStates')
  getWorkflowStates(): Observable<any> {
    return this.ocService.get().all('tables/workflowState/').getList();
  }

  /**
   * Gets workflows and stores it in the cache
   * @returns the workflow list
   */
  @LiquidCache('workflows')
  getWorkflows(): Observable<any> {
    return this.ocService.get().all('tables/workflow/').getList(); // + id/AppSettings.WORKFLOW_ID).getList();
  }

  /**
   * Gets workflow steps and stores it in the cache
   * @returns the workflow steps list
   */
  @LiquidCache('workflowsSteps')
  getWorkflowSteps(): Observable<any> {
    return this.ocService.get().all('tables/workflowStep/').getList(); //wf_id/' + AppSettings.WORKFLOW_ID).getList();
  }

  /**
   * @param step Check is Action is in approval
   * @returns True if action is approval
   */
  isActionInApproval(step) {
    let inApproval = false;

    if (step) {
      return step.wf_id == this.actionInApproval.workflow && step.wf_step >= this.actionInApproval.step;
    }
    return inApproval;
  }

  /**
   * Check workflow status
   * @param step workflow step
   * @param type workflow type
   * @returns status
   */
  getWorkflowStatus(step, type=null) {

    let status = '';
    if (type) {
      if (step == Utils.getDefaultWorkflowStep(type)) status = WorkflowService.STATUS_PENDING;
      if (step == Utils.getRejectedWorkflowStep(type)) status = WorkflowService.STATUS_REJECTED;
      if (step == Utils.getApprovedWorkflowStep(type)) status = WorkflowService.STATUS_APPROVED;
    } else {
      if (step == 500) status = WorkflowService.STATUS_PENDING;
      if (step == 510) status = WorkflowService.STATUS_REJECTED;
      if (step == 520) status = WorkflowService.STATUS_APPROVED;
    }

    return status;

  }

  /**
   * Get workflow state related with step
   * @param wf_id workflow from which it is obtained
   * @param wf_step workflow step
   * @returns state
   */
  getStateWithWorkflowStep(wf_id, wf_step) {
    let step = this.getWorkflowStep(wf_id, wf_step);
    if (step) {
      return this.getStateById(step.wf_state_id);
    }
  }

  /**
   * Get workflow steps
   * @param wf_id workflow from which it is obtained
   * @returns workflow steps
   */
  getWorkflowStepsByWorkflow(wf_id) {
    return this.workflowSteps.filter(step => step.wf_id == wf_id);
  }

  /**
   * Get workflow step
   * @param wf_id workflow from which it is obtained
   * @param wf_step workflow step
   * @returns workflow step
   */
  getWorkflowStep(wf_id, wf_step) {
    //return this.workflowSteps.find(step => step.wf_id == wf_id && step.wf_step == wf_step);
    
    const step: any = this.workflowSteps.find(item => item.wf_id == wf_id && item.wf_step == wf_step);
    if (step && step.name == 'Verification') {
      step.name = 'In production';
    }
    return step;
  }

  /**
   * Get State by id
   * @param id id to get state
   * @returns state
   */
  getStateById(id) {
    return this.workflowStates.find(state => state.id == id);
  }

}
