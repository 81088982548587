import { Asset } from "./asset.model";

/**
 * This class defines the structure for the asset collections
 */
export class Collection extends Asset {
    /**
     * id of the user who created the collection 
     */
    user: Array<number>;
    /**
     * array of the ids of the assets contained in the collection
     */
    assets: Array<any> = [];
    /**
     * array of the keys of the brands associated tod the collection
     */
    brands: Array<any> = [];
    /**
     * array of the assets with their data
     */
    assetsObject: Array<any> = [];
}