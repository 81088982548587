import { EventEmitter, Injectable, Output } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { Team } from 'app/shared/model/team.model';

/**
 * Service defined to manage all operations needed with Teams
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ProfessionalTeamService {

  /**
   * Event that when executed stop the refreshing of the list.
   */
  @Output() updateTeams: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Constructor.
   * @param hcmsService Service whit the functions related to censhare using restangular.
   */
  constructor(private hcmsService: HCMSService) { }

  /**
   * Register a team
   * @returns a team
   */
  public registerTeam(team) {
    return this.hcmsService.get().all('entity/professionalteam').post(team, {}).toPromise();
  }

  /**
   * Get all teams
   * @returns All teams
   */
  public getAllTeams() {
    return this.hcmsService.get().one('entity/professionalteam').get().toPromise();
  }

  /**
   * Get all teams
   * @returns All teams
   */
  public getTeamsByAgency(agencyId) {
    return this.hcmsService.get().one('entity/professionalteam?query=agency=' + agencyId).get().toPromise();
  }

   /**
   * Get a team by id
   *
   * @param id Team ID
   * @returns All agencies by page
   */
    public getTeamById(id) {
      return this.hcmsService.get().one('entity/professionalteam', id).get().toPromise();
    }

   /**
   * Edits the team passed as param
   * @param team, the team to be edited
   * @returns the edited team
   */
  public async editTeam(team) {
    const newTeam = Object.assign(new Team, this.hcmsService.get().copy(team).plain());

    let updateTeam = await this.hcmsService.get().one('entity/professionalteam', team.id).get().toPromise();
    updateTeam = Object.assign(updateTeam, newTeam);
    return updateTeam.save().toPromise();
  }

  /**
   * Delete team
   * @returns response to request
   */
  public deleteTeam(teamId): any {
    return this.getTeamById(teamId).then( team => {
      return team.remove().toPromise();
    });
  }

  /**
   * Delete team´s picture
   * @returns response to request
   */
  public deletePicture(teamId): any {
    return this.getTeamById(teamId).then( updated => {
      const pictureId = updated.pictures[0].id;
      updated.removeMainPicture = +pictureId;
      return updated.put().toPromise();
    });
  }

  /**
   * Upload picture of team
   * @returns response to request
   */
  public uploadPicture(teamId, fileFormData): any {
    return this.hcmsService.get().all('entity/professionalteam/' + teamId).customPUT(fileFormData, '', {}, {}).toPromise();
  }

}

