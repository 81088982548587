import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AssetFeaturesService } from '../asset_features/asset-features.service';
import { ListsService } from '../lists/lists.service';
import { HCMSService } from '../satellites/hcms.service';
import { UserService } from '../user/user.service';
import { WorkflowService } from '../workflows/workflow.service';

export enum CHARTS_TYPE { cake, bar, table };

/**
 * This is the generic ReportChart class from which the will inherit all types of reporting charts.
 */
class ReportChartEnum {

  /**
   * Key.
   */
  key:string;
  /**
   * i18n label
   */
  label:string;
  /**
   * Type
   */
  type: CHARTS_TYPE;
  /**
   * function that returns the data to be displayed by the chart
   */
  dataFunction: string;
  /**
   * Columns to be displayed in the chart table (if it has)
   */
  tableKeys: Array<string> = [];
  /**
   * Indicates whether the table header is being displayed.
   */
  showTableHeader: boolean = true;

  /**
   * @ignore
   */
  constructor(
    key: string,
    label: string,
    type: CHARTS_TYPE,
    dataFuntion : string,
    tableKeys?: Array<string>,
    showTableHeader?: boolean
  ){
    this.key = key;
    this.label = label;
    this.type = type;
    this.dataFunction = dataFuntion;
    this.tableKeys = tableKeys;
    this.showTableHeader = showTableHeader;
  }
}

/**
 * This defines all types of reporting charts 
 */
export class REPORTING_CHARTS extends ReportChartEnum {
  /**
   * Jobs last 12 months.
   */
  public static readonly JOBS_LAST_12_MONTHS = new ReportChartEnum('jobs_last_12_months', 'reports.jobs_last_12_months', CHARTS_TYPE.bar, 'getJobsLast12Months');
  /**
   * My jobs last 12 months.
   */
  public static readonly MY_JOBS_LAST_12_MONTHS = new ReportChartEnum('my_jobs_last_12_months', 'reports.my_jobs_last_12_months', CHARTS_TYPE.bar, 'getMyJobsLast12Months');
  /**
   * Open jobs channels.
   */
  public static readonly OPEN_JOBS_CHANNELS = new ReportChartEnum('open_jobs_channel', 'reports.open_jobs_channel', CHARTS_TYPE.cake, 'getOpenJobsByChannels');
  /**
   * My open jobs channels.
   */
  public static readonly MY_OPEN_JOBS_CHANNELS = new ReportChartEnum('my_open_jobs_channel', 'reports.my_open_jobs_channel', CHARTS_TYPE.cake, 'getMyOpenJobsByChannel');
  /**
   * Open jobs.
   */
  public static readonly OPEN_JOBS = new ReportChartEnum('open_jobs', 'reports.open_jobs', CHARTS_TYPE.table, 'getOpenJobs', ['name', 'priorityCntr', 'startDate', 'deadline'], true);
  /**
   * Open projects.
   */
  public static readonly OPEN_PROJECTS = new ReportChartEnum('open_projects', 'reports.open_projects', CHARTS_TYPE.table, 'getOpenProjects', ['name', 'totalJobs', 'priorityCntr', 'startDate', 'deadline'], true);
  /**
   * Closed jobs.
   */
  public static readonly CLOSED_JOBS = new ReportChartEnum('closed_jobs', 'reports.closed_jobs', CHARTS_TYPE.table, 'getClosedJobs', ['name', 'priorityCntr', 'startDate', 'deadline'], true);
  /**
   * Closed projects.
   */
  public static readonly CLOSED_PROJECTS = new ReportChartEnum('closed_projects', 'reports.closed_projects', CHARTS_TYPE.table, 'getClosedProjects', ['name', 'totalJobs', 'priorityCntr', 'startDate', 'deadline'], true);
  /**
   * Open requests.
   */
  public static readonly OPEN_REQUESTS = new ReportChartEnum('open_requests', 'reports.open_requests', CHARTS_TYPE.table, 'getOpenRequests', ['name', 'priorityCntr', 'startDate', 'deadline'], true);
}

/**
 * Service defined to manage the necessary operations for reports widgets ({@link ReportChartEnum})
 */
@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  /**
   * Current user.
   */
  private currentUser;

  /**
   * All favorite charts of the user logged
   */
  public favoriteCharts : BehaviorSubject<Array<String>> = new BehaviorSubject([]);
  /**
   * All channels of the user logged. This will filtered depending on needs when displaying data
   */
  private channels : Array<any>;
  /**
   * All jobs of the user logged. This will filtered depending on needs when displaying data
   */
  public jobs : BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  /**
   * All projects of the user logged. This will filtered depending on needs when displaying data
   */
  public projects : BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  /**
   * All requests of the user logged. This will filtered depending on needs when displaying data
   */
  public requests : BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  /**
   * Constructor.
   * @param userService Service with the functions related to the users.
   * @param hcmsService Service with the functions related to the censhare.
   * @param assetFeaturesService Service with the functions related to the asset features.
   * @param listsService Service with the functions related to the lists.
   * @param translateService Service with the functions related to the translations.
   * @param workflowsService Service with the functions related to the workflows.
   */
  constructor(
    private userService : UserService,
    private hcmsService : HCMSService,
    private assetFeaturesService : AssetFeaturesService,
    private listsService : ListsService,
    private translateService : TranslateService,
    private workflowsService : WorkflowService,
  ) {
    this.currentUser = this.userService.getCurrentUser();
    this.loadUserFavoriteCharts();
    this.getChannels();
    this.getJobsData();
    this.getProjectsData();
    this.getRequestsData();
  }

  /**
   * Get channels.
   */
  private async getChannels(){
    let channels = await this.assetFeaturesService.getFeatureValue('sp:cntr.channel_hubz');
    this.channels = channels.plain();
  }

  /**
   * Get jobs data.
   */
  private async getJobsData() {
    let jobs = await this.listsService.getJobs();
    jobs.forEach(job => {
      let creation = new Date(job.createdAttr);
      let month = this.translateService.instant('month.' + creation.getMonth().toString());
      job.dateSegment = month + ' ' + creation.getFullYear();

      job.workflowName = this.workflowsService.getWorkflowStep(job.workflow, job.workflowStep).name;

      if (job.channel) {
        let channel = this.channels.find(x => x.value_key === job.channel);
        if (channel){
          job.channelName = channel.name;
        } else {
          job.channelName = job.channel;
        }
      }
    });

    this.jobs.next(jobs);
  }

  /**
   * Get projects data
   */
  private async getProjectsData() {
    let projects = await this.listsService.getProjects();
    projects.forEach(project => {
      let creation = new Date(project.createdAttr);
      let month = this.translateService.instant('month.' + creation.getMonth().toString());
      project.dateSegment = month + ' ' + creation.getFullYear();

      project.workflowName = this.workflowsService.getWorkflowStep(project.workflow, project.workflowStep).name;

      if (project.channel) {
        let channel = this.channels.find(x => x.value_key === project.channel);
        if (channel){
          project.channelName = channel.name;
        } else {
          project.channelName = project.channel;
        }
      }

      project.jobs = project.childs.filter(x => x != null && x.type === 'order.');
      project.totalJobs = project.jobs.length;
    });

    this.projects.next(projects);
  }

  /**
   * Get requests data.
   */
  private async getRequestsData() {
    let requests = await this.listsService.getRequests();
    this.requests.next(requests);
  }

  /**
   * Load user favorite chats.
   */
  private loadUserFavoriteCharts() {
    this.hcmsService.get().one('entity/webuser_charts', +this.currentUser.id).get().toPromise().then(data => {
      this.favoriteCharts.next((data && data.charts)?data.charts:[]);
    });
  }

  /**
   * Add or delete chart from favorites.
   * 
   * To finish the change, this update the webusers_charts favorite list with current favorites.
   * 
   * @param {string} key The key of the chart
   */
  public changeFavoriteChart(key: string){
    let current : Array<String> = this.favoriteCharts.value;
    let index = current.findIndex(element => element === key);
    if (index > -1){
      current.splice(index, 1);
    } else {
      current.push(key);
    }

    this.favoriteCharts.next(current);
    this.saveFavoriteCharts();
  }

  /**
   * Save favorite charts.
   */
  private saveFavoriteCharts(){
    this.hcmsService.get().one('entity/webuser_charts', +this.currentUser.id).get().toPromise().then(data => {
      data.charts = this.favoriteCharts.value;
      data.put();
    });
  }
}
