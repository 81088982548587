import { Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MomentCustomAdapter } from 'app/shared/utils/MomentCustomAdapter';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AppSettings } from 'app/shared/app.settings';
import { AssetFeaturesService } from 'app/shared/services/asset_features/asset-features.service';
import { BriefingService } from 'app/shared/services/briefing/briefing.service';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { UserService } from 'app/shared/services/user/user.service';
import { MatRadioChange } from '@angular/material/radio';
import { AssetService } from 'app/shared/services/asset/asset.service';
import * as moment from 'moment';
import { NgxTimepickerFieldComponent } from 'ngx-material-timepicker';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import * as CustomEditor from '../../../../ckeditor/ckeditor';
import { Briefing, DetailedBriefing } from 'app/shared/model/detailedBriefing.model';
import Utils from 'app/shared/utils/utils';
import { Material } from 'app/shared/model/material.model';
import { MimetypeService } from 'app/shared/services/mimetype/mimetype.service';
import { MaterialsService } from 'app/shared/services/materials/materials.service';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';
import { Media } from 'app/shared/model/media.model';
import { Subscription } from 'rxjs/internal/Subscription';

/**
 * Constant with the formats of the date
 */
export const DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: { dateInput: 'DD/MM/YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
};

/**
 * This component contains the long-form form for the creation of a new {@link Briefing} 
 */
@Component({
  selector: 'app-detailed-briefing',
  templateUrl: './detailed-briefing.component.html',
  styleUrls: ['./detailed-briefing.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentCustomAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true, displayDefaultIndicatorType: false} }
  ]
})
export class DetailedBriefingComponent implements OnInit {

  AppSettings = AppSettings;

  /** The id of the briefing if it is being edited */
  @Input() assetId;

  /** {@link NgxTimepickerFieldComponent} used to control the start time picker.  */
  @ViewChild('startTime', { static: true }) startTime: NgxTimepickerFieldComponent;

  /** {@link NgxTimepickerFieldComponent} used to control the end time picker.  */
  @ViewChild('endTime', { static: true }) endTime: NgxTimepickerFieldComponent;

  /** {@link MatStepper} used to control the different steps of form.  */
  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  /** This event executes a function in the parent component that changes the tab, displaying the component  {@link ShortcutBriefingComponent} */
  @Output() shortcut = new EventEmitter<void>();

  /** step number containing the file attachments   */
  private readonly ATTACHMENT_STEP: number = 6;
  /** the default start hour  */
  private DEFAULT_STARTDATE_HOUR = "00";

    /** the default start minute  */
  private DEFAULT_STARTDATE_MINUTE = "00";

    /** the default start second  */
  private DEFAULT_STARTDATE_SECOND = "00";

  /** the default deadline hour  */
  private DEFAULT_DEADLINE_HOUR = '23';

  /** the default deadline minute  */
  private DEFAULT_DEADLINE_MINUTE = '59';

  /** the default deadline second  */
  private DEFAULT_DEADLINE_SECOND = '59';

  /** {@link CustomEditor} that shows the ckEditor */
  public Editor = CustomEditor;

  /** Form with the data of the briefing */
  formGroup: FormGroup;

  /** {@link User} with the current user that is logged in. */
  currentUser;

  /** The object being edited */
  editableDetail;

  /** the {@link briefing} object inside the one being edited */
  editableBriefing;

  /** All {@link project} that the current user has */
  projects = [];

  /** All clients */
  clients = [];

  /** All channels  */
  channelValues = [];

  /** All deliverables of the selected channel */
  deliverables = [];

  /** All objectives of the selected channel and deliverable */
  objectives = [];

  /** Array of hubz */
  hubz = [];

  /** The hubz selected */
  selectedHub;

  /** The deliverable selected */
  selectedDeliverable;

  /** The objective selected */
  selectedObjective;

  /** array of the units (of measurement) that a material may have */
  unitValues = [];

  /** The priority selected */
  priority;

  /** Array with the priorities that a briefing can have  */
  priorityValues = [];

  /** The default time */
  defaultTime = '00:00';

  /** @ignore */
  actions = [];

  /** Boolean indicating if a new briefing is being created, keeps the newly created modal open */
  creatingBriefing = false;

  /** @ignore */
  onDrag = false;

  /** Boolean indicating an error in the start date of the briefing */
  startDateError = false;

  /** Boolean indicating an error in the deadline date of the briefing */
  deadlineError = false;

  /** @ignore */
  deadlineBiggerError = false;

  /** boolean indicating that the title can be edited */
  titleEnabled = false;

  /** boolean indicating that the form is valid */
  isValidFormSubmitted = false;

  /** array with deleted files */
  deleteFiles = [];

  /**array with the selected files to add to the briefing */
  files = [];

  /** @ignore */
  listMode: boolean = false;

  /** Array of all assets {@link asset} */
  assets: Media[] = [];

  /** Indicates the parameter by which the assets are to be sorted.*/
  sorting: string = "date";

  /** Indicates the output order of the assets whether ascending or descending.  */
  direction: number = -1;

  /** Indicates the last date on which the assets were obtained.*/
  lastRefresh = {
    value: new Date()
  };
  /** subscription that brings all assets  */
  private assetListSubscription: Subscription;

  /** array with the files to be added to the briefing */
  mediaLibraryFiles: any[] = [];

  /**list of all customers */
  customerDataList: any[] = [];
  /** This event executes a function in the parent component that close the
   * dialog (this component)
   * and remains in the component {@link BriefingsComponent}
  */
  @Output() reload = new EventEmitter<void>();
  /**
   * 
   * @param datepipe Pipe with the date pipe used to format dates.
   * @param dialog Service with the functions related to the dialog.
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param userService Service with the functions related to the users.
   * @param formBuilder Service with the functions related to the forms.
   * @param projectsService Service with the functions related to the projects.
   * @param briefingService Service with the functions related to the briefings.
   * @param assetFeatureService Service with the functions related to the properties of assets..
   * @param actionsService Service with the functions related to the actions.
   * @param assetService Service with the functions related to the assets.
   * @param mimetypeService Service with the functions related to the mime types.
   * @param materialsService Service with the functions related to the materials.
   */
  constructor(
    private datepipe: DatePipe,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private projectsService: ProjectsService,
    private briefingService: BriefingService,
    private assetFeatureService: AssetFeaturesService,
    private actionsService: ActionsService,
    private assetService: AssetService,
    private mimetypeService: MimetypeService,
    private materialsService: MaterialsService,
    ) {
      this.defaultTime = this.datepipe.transform(new Date(), 'HH') + ':' + this.datepipe.transform(new Date(), 'mm');
      this.currentUser = this.userService.getCurrentUser();

      this.projectsService.getProjectsByUser(this.currentUser.id).then(projects => {
        if (projects.result && projects.result.length > 0) {
          this.projects = [...projects.result];
        }
      });


      this.formGroup = this.formBuilder.group({
        formArray: this.formBuilder.array([
          this.formBuilder.group({
            project: [null, Validators.compose([])],
            client: [null, Validators.compose([])],
          }),
          this.formBuilder.group({
            selectedHub: ['root.', Validators.compose([Validators.required])],
          }),
          this.formBuilder.group({
            selectedDeli: [null, Validators.compose([Validators.required])],
          }),
          this.formBuilder.group({
            selectedObjective: [null, Validators.compose([])],
          }),
          this.formBuilder.group({
            width: [null, Validators.compose([])],
            height: [null, Validators.compose([])],
            depth: [null, Validators.compose([])],
            selectedUnit: [null, Validators.compose([])],
          }),
          this.formBuilder.group({
            startDate: [moment.utc(), Validators.compose([Validators.required])],
            startDateTime: [null, Validators.compose([])],
            deadline: [null, Validators.compose([Validators.required])],
            deadlineTime: [null, Validators.compose([])],
            priority: ['normal', Validators.compose([])],
            budget: [null, Validators.compose([])],
        }, {validator: this.matchingStartDateBeforeDueDate('startDate', 'startDateTime', 'deadline', 'deadlineTime')}),
          this.formBuilder.group({
            // file
          }),
          this.formBuilder.group({
            // action: [null, Validators.compose([])],
            message: [null, Validators.compose([Validators.required])], ////// --> YES
            title: [{value: 'New detailed briefing', disabled: true}, Validators.compose([Validators.required])], ////// --> YES
          }),
        ])
      });

      this.briefingService.getChannelValues().subscribe(data => {
        this.channelValues = data.filter(x => x.enabled).sort((a, b) => (a.sorting > b.sorting) ? 1 : -1);
        this.hubz = this.assetFeatureService.getFeatureValueByParentAndLevel(this.channelValues, 'root.', 2);
      });

      this.assetService.getUnitValues().subscribe(data => {
        this.unitValues = data.filter(x => x.enabled).sort((a, b) => (a.sorting > b.sorting) ? 1 : -1);
      });

      this.assetService.getPriorityValues().subscribe(data => {
        this.priorityValues = data.filter(x => x.enabled && x.value_key !== 'urgent').sort((x, y) => (x.name > y.name ? 1 : -1));
      });

      this.actionsService.getUserActions(this.currentUser.id).then(data => {
        if (data.result && data.result.length > 0) {
          const actions = data.result.filter(
            x => x.workflowStep &&
            (x.workflowStep === ActionsService.JOB_WORKFLOW_PLANNED || x.workflowStep === ActionsService.JOB_WORKFLOW_IN_PROGRESS)
          );
          this.actions = [...actions];
        }
      });

      this.briefingService.getClientValues().subscribe(data => {
        this.clients = data.filter(x => x.enabled).sort((a, b) => (a.name > b.name) ? 1 : -1);
      });

  }

  /** Returns a FormArray with the name 'formArray'. */
  get formArray(): FormArray | null {
    return this.formGroup.get('formArray') as FormArray;
  }
  /**
   * This function validates that the start date and due date fields are correct.
   * Both that they have a value and that the start date is earlier than the later date.
   * @param startKey start date label
   * @param startTimeKey start time label
   * @param dueKey deadline date label
   * @param dueTimeKey deadline time label
   * @returns Boolean, is true when all four fields are valid
   */
  matchingStartDateBeforeDueDate(startKey, startTimeKey, dueKey, dueTimeKey) {
    return (group: FormGroup) => {
      let startDate: string = '', dueDate: string ='';
      let startInput = group.controls[startKey];
      let startTimeInput = group.controls[startTimeKey];
      let dueInput = group.controls[dueKey];
      let dueTimeInput = group.controls[dueTimeKey];

      if (startInput.value !== null && dueInput.value !== null) {
        let startDateTime = this.DEFAULT_STARTDATE_HOUR + ':' + this.DEFAULT_STARTDATE_MINUTE + ':' + this.DEFAULT_STARTDATE_SECOND;
        let dueTime = this.DEFAULT_DEADLINE_HOUR + ':' + this.DEFAULT_DEADLINE_MINUTE + ':' + this.DEFAULT_DEADLINE_SECOND;

        if (startTimeInput.value) startDateTime = startTimeInput.value + ':' + this.DEFAULT_STARTDATE_SECOND;
        if (dueTimeInput.value) dueTime = dueTimeInput.value + ':' + this.DEFAULT_DEADLINE_SECOND;

        if (startInput.value instanceof moment) {
          startDate = (moment)(startInput.value).format('yyyy-MM-DD') + 'T' + startDateTime;
        } else {
          startDate = startInput.value + 'T' + startDateTime;
        }

        if (dueInput.value instanceof moment) {
          dueDate = (moment)(dueInput.value).format('yyyy-MM-DD') + 'T' + dueTime;
        } else {
          dueDate = dueInput.value + 'T' + dueTime;
        }

        if (dueDate < startDate) {
          return dueInput.setErrors({startBeforeEnd: true});
        }
      } else {
        return null;
      }
    }
  }

  /** Initialize the component and if is editing you get all the data from the briefing and you get all the data from media library */
  ngOnInit() {
    if (this.assetId) {
      // this.briefingService.getAssetDetailedBriefing(this.assetId).then(data => {
      this.briefingService.getDetailedBriefing(this.assetId).then(data => {
        if (data && data.result && data.result.length > 0) {
          this.editableDetail = data.result[0];
          this.editableBriefing = data.result[0].briefings[0];

          if (this.editableDetail.parents) {
            this.formArray.at(0).get('project').setValue(this.editableDetail.parents[0]);
          }
          const splitChannelHubz = this.editableBriefing.channel_hubz.split('.');
          if (splitChannelHubz.length >= 3) {
            this.selectedHub = splitChannelHubz.slice(0, 2).join('.') + '.';
            this.formArray.at(1).get('selectedHub').setValue(this.selectedHub);
            this.deliverables = this.assetFeatureService.getFeatureValueByParentAndLevel(this.channelValues, this.selectedHub, 3);
          }
          if (splitChannelHubz.length >= 4) {
            this.selectedDeliverable = splitChannelHubz.slice(0, 3).join('.') + '.';
            this.formArray.at(2).get('selectedDeli').setValue(this.selectedDeliverable);
            this.objectives = this.assetFeatureService.getFeatureValueByParentAndLevel(this.channelValues, this.selectedDeliverable, 4);
          }
          if (splitChannelHubz.length >= 5) {
            this.formArray.at(3).get('selectedObjective').setValue(splitChannelHubz.slice(0, 4).join('.') + '.');
          }

          if (this.editableDetail.width) {
            this.formArray.at(4).get('width').setValue(this.editableDetail.width);
          }
          if (this.editableDetail.height) {
            this.formArray.at(4).get('height').setValue(this.editableDetail.height);
          }
          if (this.editableDetail.depth) {
            this.formArray.at(4).get('depth').setValue(this.editableDetail.depth);
          }
          if (this.editableDetail.unit) {
            this.formArray.at(4).get('selectedUnit').setValue(this.editableDetail.unit);
          }
          if (this.editableDetail.budget) {
            this.formArray.at(5).get('budget').setValue(this.editableDetail.budget);
          }
          if (this.editableDetail.priorityCntr) {
            this.formArray.at(5).get('priority').setValue(this.editableDetail.priorityCntr);
          }
          if (this.editableDetail.startDate) {
            this.formArray.at(5).get('startDate').patchValue(moment(this.editableDetail.startDate));
            this.startTime.changeHour(+this.datepipe.transform(this.editableBriefing.startDate, 'HH', 'GMT+00:00'));
            this.startTime.changeMinute(+this.datepipe.transform(this.editableBriefing.startDate, 'mm'));
          }
          if (this.editableDetail.deadline) {
            this.formArray.at(5).get('deadline').patchValue(moment(this.editableDetail.deadline));
            this.endTime.changeHour(+this.datepipe.transform(this.editableDetail.deadline, 'HH', 'GMT+00:00'));
            this.endTime.changeMinute(+this.datepipe.transform(this.editableDetail.deadline, 'mm'));
          }
          if (this.editableDetail.info) {
            this.formArray.at(7).get('message').setValue(this.editableDetail.info);
          }
          if (this.editableBriefing.client) {
            this.formArray.at(0).get('client').setValue(this.editableBriefing.client);
          }

          if (this.editableDetail.customerdata && this.editableDetail.customerdata.length > 0 && this.editableDetail.customerdata[0].files && this.editableDetail.customerdata[0].files.length > 0) {
            this.customerDataList = this.editableDetail.customerdata[0].files;
          }
        }
      });
    }
    this.startTime.registerOnChange((event) => {
      this.formGroup.controls['startDateTime'].patchValue(event);
    });

    this.endTime.registerOnChange((event) => {
      this.formGroup.controls['deadlineTime'].patchValue(event);
    });

    this.onChanges();

    this.getAllAssets();
  }
  /**
   * Get all {@link Assets}
   */
  getAllAssets() {
    this.assetListSubscription = this.assetService.getAssets().subscribe(data => {
      this.lastRefresh.value = new Date();
      this.assets = data;
      if (this.sorting) {
        this.sort();
      }
    });
  }
  /**
   * Sorts {@link Assets} by date or alphabetical order
   */
  sort() {
    switch (this.sorting) {
      case 'abc':
        this.assets = this.assets.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()) * this.direction);
        break;
      case 'date':
        this.assets = this.assets.sort((a, b) => (a.created) ? (a.created.localeCompare(b.created) * this.direction) : a.createdAttr.localeCompare(b.createdAttr) * this.direction);
        break;
    }
  }
  /** Increases the width of the dialog (this component) when you reach the step of selecting attachments */
  updateStepWidth(event) {
    if (event.selectedIndex == this.ATTACHMENT_STEP) {
      this.dialogRef.updateSize('90%');
    } else {
      this.dialogRef.updateSize('860px');
    }
  }
  /**
   * Add library {@link Assets} to the new briefing library
   * @param event list of assets selected
   */
  updateLibraryFiles(event) {
    this.mediaLibraryFiles = event;
  }

  public onChanges(): void {

    // prefill title with values
    this.formGroup.valueChanges.subscribe(val => {

      if (!this.titleEnabled) {
        const titleArray = [];

        if (this.formArray.at(1).get('selectedHub').value) {
          let value = this.channelValues.find(x => x.value_key === this.formArray.at(1).get('selectedHub').value);
          if (value) titleArray.push(value.name);
        }
        if (this.formArray.at(2).get('selectedDeli').value) {
          let value = this.channelValues.find(x => x.value_key === this.formArray.at(2).get('selectedDeli').value);
          if (value) titleArray.push(value.name);
        }
        if (this.formArray.at(3).get('selectedObjective').value) {
          let value = this.channelValues.find(x => x.value_key === this.formArray.at(3).get('selectedObjective').value);
          if (value) titleArray.push(value.name);
        }
        if (this.formArray.at(4).get('width').value && this.formArray.at(4).get('height').value) {
            titleArray.push(this.formArray.at(4).get('width').value);
            titleArray.push(this.formArray.at(4).get('height').value);
            if (this.formArray.at(4).get('selectedUnit').value) {
              titleArray.push(this.formArray.at(4).get('selectedUnit').value);
            }
        }
        if ( this.formArray.at(5).get('startDate').value) {
          titleArray.push(this.datepipe.transform(this.formArray.at(5).get('startDate').value.toDate(), 'yyyy-MM-dd'));
        }
        if ( this.formArray.at(5).get('deadline').value) {
          titleArray.push(this.datepipe.transform(this.formArray.at(5).get('deadline').value.toDate(), 'yyyy-MM-dd'));
        }
        this.formArray.at(7).get('title').setValue(titleArray.join('_'), { emitEvent: true, onlySelf: true });
      }
    });
  }
  /**
   * Allows you to change the {@link Job} title
   */
  public enableTitle() {
    if (!this.titleEnabled) {
      this.titleEnabled = true;
      this.formArray.at(7).get('title').enable();
    }
  }
  /**
   * Select or change hub selection
   * @param mrChange The selected hub
   */
  public selectHub(mrChange: MatRadioChange) {
    this.selectedHub = mrChange.value;
    this.deliverables = this.assetFeatureService.getFeatureValueByParentAndLevel(this.channelValues, this.selectedHub, 3);
  }

  /**
   * Select or change deliverable selection
   * @param mrChange The selected deliverable
   */
  public selectDeliverable(mrChange: MatRadioChange) {
    this.selectedDeliverable = mrChange.value;
    this.objectives = this.assetFeatureService.getFeatureValueByParentAndLevel(this.channelValues, this.selectedDeliverable, 4);
  }

  /**
   * Select or change objective selection
   * @param mrChange The selected objective
   */
  public selectObjective(mrChange: MatRadioChange) {
    this.selectedObjective = mrChange.value;
  }
  /**
   * Sets the date and time of the selected field to the current one. 
   * @param start Boolean, is true when the field to be set is the start date.
   *  False when it is the expiration date.
   */
  public saveCurrentTime(start: boolean = true) {

    let currentHour: number = +this.datepipe.transform(new Date(), 'HH');
    let currentMinutes: number = +this.datepipe.transform(new Date(), 'mm');
    let date: string = (start) ? 'startDate' : 'deadline';

    this.formArray.at(5).get(date).patchValue(moment.utc());

    if (start){
      this.startTime.changeHour(currentHour);
      this.startTime.changeMinute(currentMinutes);
    } else {
      this.endTime.changeHour(currentHour);
      this.endTime.changeMinute(currentMinutes);
    }
  }

  /**
   * Cancels the creation of a new brifing and closes the dialgo
   */
  cancelBriefing() {
    this.creatingBriefing = false;
    this.reload.emit();
  }
  /**
   * Prevents the default behavior of drag and drop events 
   * @param event drop and drag
   */
  preventDefaultEvent(event) {
    event.preventDefault();
  }
  /**
   * @ignore
   */
  onSubmit() {
    //console.log(this.formGroup.value);
  }
  /**
   * Checks if the start date of a job is within the start and due dates of a project
   * @param projectSelected {@link Project}
   * @param startDateJob Job start date
   * @param deadlineDateJob Job dead line date
   * @returns Boolean
   */
  checkIfOutsideTheProjectPeriod(projectSelected, startDateJob, deadlineDateJob) {
    return (startDateJob < projectSelected.startDate || deadlineDateJob > projectSelected.deadline)? true : false;
  }
  /**
   * Set the date and time from the startDate field and returns it in the format YYYYY-MM-DDThh:mm:ss
   * @returns date in format YYYYY-MM-ddThh:mm:ss
   */
  getCompleteFormStartDate() {
    let date: string ='';
    let startDateTime = this.DEFAULT_STARTDATE_HOUR + ':' + this.DEFAULT_STARTDATE_MINUTE + ':' + this.DEFAULT_STARTDATE_SECOND;

    if (this.formArray.at(5).get('startDateTime').value) {
      startDateTime = this.formArray.at(5).get('startDateTime').value + ':' + this.DEFAULT_STARTDATE_SECOND;
    }

    if (this.formArray.at(5).get('startDate').value instanceof moment) {
      date = this.datepipe.transform(this.formArray.at(5).get('startDate').value.toDate(), 'yyyy-MM-dd') + 'T' + startDateTime;
    } else {
      date = this.formArray.at(5).get('startDate').value + 'T' + startDateTime;
    }

    return date;
  }
  /**
   * Set the date and time from the deadline field and returns it in the format YYYYY-MM-DDThh:mm:ss
   * @returns date in format YYYYY-MM-ddThh:mm:ss
   */
  getCompleteFormDeadline() {
    let date: string ='';
    let deadlineTime = this.DEFAULT_DEADLINE_HOUR + ':' + this.DEFAULT_DEADLINE_MINUTE + ':' + this.DEFAULT_DEADLINE_SECOND;

    if (this.formArray.at(5).get('deadlineTime').value) {
      deadlineTime = this.formArray.at(5).get('deadlineTime').value + ':' + this.DEFAULT_DEADLINE_SECOND;
    }

    if (this.formArray.at(5).get('deadline').value instanceof moment) {
      date = this.datepipe.transform(this.formArray.at(5).get('deadline').value.toDate(), 'yyyy-MM-dd') + 'T' + deadlineTime;
    } else {
      date = this.formArray.at(5).get('deadline').value + 'T' + deadlineTime;
    }

    return date;
  }
  /**
   * Change the date if the start briefing date is earlier than the project date or the due date is later, it opens a modal that matches the wrong date.
   * @param projectSelected The project selected
   * @param startDateJob  The start date job
   * @param deadlineDateJob The deadline date job
   */
  saveByAdjustingProjectPeriod (projectSelected, startDateJob, deadlineDateJob) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'adjustProjectPeriod'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (startDateJob < projectSelected.startDate) {
          this.projectsService.saveStartDate(projectSelected.id, startDateJob);
        }
        if (deadlineDateJob > projectSelected.deadline) {
          this.projectsService.saveDeadline(projectSelected.id, deadlineDateJob);
        }
      }
      this.saveData(false);
      dialogRef = null;
    });
  }

  // save - STATUS -> "in Progress" -> still editable // set default values / disable all validations
  /**
   * Save the new briefing
   */
  public saveDetailedBriefing() {
    this.creatingBriefing = true;
    this.formArray.at(7).get('title').enable();
    this.saveData();
  }

  // create - STATUS -> "send" -> read only // only if form valid
  /**
   * Update the new briefing
   */
  public createDetailedBriefing() {

    this.isValidFormSubmitted = true;

    if (this.formArray.invalid) {
      const invalidStep = this.formArray.controls.findIndex(x => x.status === 'INVALID');
      this.stepper.selectedIndex = invalidStep;
      return;
    }

    this.isValidFormSubmitted = false;

    this.creatingBriefing = true;
    this.formArray.at(7).get('title').enable();
    let startDateJob = this.getCompleteFormStartDate();
    let deadlineDateJob = this.getCompleteFormDeadline();
    let projectSelected = this.projects.find(x => x.id === this.formArray.at(0).get('project').value);

    //TODO: When 'create project' is available, add conditional if it is a job or a project. Only adjust project if it's a job
    if (projectSelected && this.checkIfOutsideTheProjectPeriod(projectSelected, startDateJob, deadlineDateJob)) {
      this.saveByAdjustingProjectPeriod(projectSelected, startDateJob, deadlineDateJob);
    } else {
      this.saveData(false);
    }
  }
  /**
   * Save the new or updated briefing
   * @param saving Boolean, which indicates if any step is missing to complete the briefing.
   */
  public saveData(saving = true) {

    let briefing: Briefing;
    let detailed: DetailedBriefing;
    // update
    if (this.assetId && this.editableDetail && this.editableBriefing) {
      briefing = this.editableBriefing;

      if (this.editableDetail.customerdata && this.editableDetail.customerdata.length > 0 && this.editableDetail.customerdata[0].files && this.editableDetail.customerdata[0].files.length > 0) {
        this.editableDetail.customerdata[0].files.forEach(file => {
          delete file.downloadLink;
        });
      }
      detailed = this.editableDetail;

    // new
    } else {
      briefing = new Briefing();
      detailed = new DetailedBriefing();
    }

    briefing.createdByNew = +this.currentUser.id;
    briefing.modifiedByNew = +this.currentUser.id;
    briefing.owner_rel[0] = +this.currentUser.id;
    briefing.workflowStep = saving ? +BriefingService.STEP_IN_PROGRESS : +BriefingService.STEP_SENT;

    if (this.formArray.at(0).get('client').value) {
      briefing.client = this.formArray.at(0).get('client').value;
      detailed.client = this.formArray.at(0).get('client').value;
    }

    if (this.formArray.at(7).get('message').value) {
      briefing.info = this.formArray.at(7).get('message').value;
      detailed.info = this.formArray.at(7).get('message').value;
    }

    if (this.formArray.at(7).get('title').value) {
      briefing.name = this.formArray.at(7).get('title').value;
      detailed.name = this.formArray.at(7).get('title').value;
    } else {
      briefing.name = 'New detailed briefing';
      detailed.name = 'New detailed briefing';
    }

    if (this.formArray.at(5).get('priority').value) {
      briefing.priorityCntr = this.formArray.at(5).get('priority').value;
      detailed.priorityCntr = this.formArray.at(5).get('priority').value;
    }

    // if (this.form.value.briefingtype && this.form.value.briefingtype == 'project') {
    //   shortcut.type = 'project.';
    //   shortcut.workflow = AppSettings.WORKFLOW_PROJECT;
    //   delete shortcut.agencydata;
    //   delete shortcut.finaldata;
    //   delete shortcut.costestimate;
    // } else if (this.form.value.briefingtype && this.form.value.briefingtype == 'action') {
      detailed.type = 'order.';
      detailed.workflow = AppSettings.WORKFLOW_ACTION;
    // }

    detailed.workflowStep = 10;
    detailed.createdByNew = +this.currentUser.id;
    detailed.modifiedByNew = +this.currentUser.id;

    if (this.formArray.at(5).get('deadline').value) {
      let deadline = this.formArray.at(5).get('deadline').value;
      if (!this.formArray.at(5).get('deadlineTime').value) {
        deadline.hour(this.DEFAULT_DEADLINE_HOUR);
        deadline.minute(this.DEFAULT_DEADLINE_MINUTE);
      } else {
        let deadlineTokens = this.formArray.at(5).get('deadlineTime').value.split(':');
        deadline.hour(deadlineTokens[0]);
        deadline.minute(deadlineTokens[1]);
      }
      deadline.second(this.DEFAULT_DEADLINE_SECOND);
      detailed.deadline = this.datepipe.transform(deadline.toDate(), 'yyyy-MM-ddTHH:mm:ss');
    }

    if (this.formArray.at(5).get('startDate').value) {
      let startDate = this.formArray.at(5).get('startDate').value;
      if (!this.formArray.at(5).get('startDateTime').value) {
        startDate.hour(this.DEFAULT_STARTDATE_HOUR);
        startDate.minute(this.DEFAULT_STARTDATE_MINUTE);
      } else {
        let startDateTokens = this.formArray.at(5).get('startDateTime').value.split(':');
        startDate.hour(startDateTokens[0]);
        startDate.minute(startDateTokens[1]);
      }
      startDate.second(this.DEFAULT_STARTDATE_SECOND);
      detailed.startDate = this.datepipe.transform(startDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');
    }

    detailed.owner_rel[0] = +this.currentUser.id;

    if (this.formArray.at(0).get('project').value) {
      detailed.parents = [+this.formArray.at(0).get('project').value];
    }

    if (this.formArray.at(3).get('selectedObjective').value) {
      detailed.channel_hubz = this.formArray.at(3).get('selectedObjective').value;
      briefing.channel_hubz = this.formArray.at(3).get('selectedObjective').value;
    } else if (this.formArray.at(2).get('selectedDeli').value) {
      detailed.channel_hubz = this.formArray.at(2).get('selectedDeli').value;
      briefing.channel_hubz = this.formArray.at(2).get('selectedDeli').value;
    } else if (this.formArray.at(1).get('selectedHub').value) {
      detailed.channel_hubz = this.formArray.at(1).get('selectedHub').value;
      briefing.channel_hubz = this.formArray.at(1).get('selectedHub').value;
    }

    if (this.formArray.at(4).get('width').value) {
      detailed.width = this.formArray.at(4).get('width').value;
    }
    if (this.formArray.at(4).get('height').value) {
      detailed.height = this.formArray.at(4).get('height').value;
    }
    if (this.formArray.at(4).get('depth').value) {
      detailed.depth = this.formArray.at(4).get('depth').value;
    }
    if (this.formArray.at(4).get('selectedUnit').value) {
      detailed.unit = this.formArray.at(4).get('selectedUnit').value;
    }
    if (this.formArray.at(5).get('budget').value) {
      detailed.budget = this.formArray.at(5).get('budget').value;
    }

    detailed.briefings[0] = briefing;

    if (this.assetId) {
      this.briefingService.updateDetailedBriefing(this.editableDetail.id, detailed).then(data => {
        let newFiles = null;

        if (this.files && this.files.filter(x => !x.id).length > 0) newFiles = this.files.filter(x => !x.id);

        if (newFiles && data.customerdata && data.customerdata.length > 0){
          let customerdata = data.customerdata[0];
          newFiles.forEach(file => {
            this.addFileToFolder(+customerdata.id, file);
          });
        }

        if (data.parents && data.parents.length > 0) {
          let selectedProject: number = 0;
          if (this.formArray.at(0).get('project').value) selectedProject = this.formArray.at(0).get('project').value;

          data.parents.filter(x => +x !== +selectedProject).forEach(project => {
            this.projectsService.removeAction(+project, this.editableDetail.id);
          });

        }

        if (this.mediaLibraryFiles && this.mediaLibraryFiles.length > 0 && data.customerdata && data.customerdata.length > 0) {
          let addedMediaIdFiles: number[] = [];
          let newMediaFiles: any[] = [];

          if (data.customerdata[0].files && data.customerdata[0].files.length > 0) {
            addedMediaIdFiles = data.customerdata[0].files.map(x => x.id);
          }

          this.mediaLibraryFiles.forEach(file => {
            if (!addedMediaIdFiles.includes(file.id)) {
              this.addParentToFile(+data.customerdata[0].id, file);
            }
          });
        }

        this.finishProcess();
      });
    } else {
      this.briefingService.createDetailedBriefing(detailed).then(result => {
        if (this.files && result.customerdata && result.customerdata.length > 0){
          let customerdata = result.customerdata[0];
          this.files.forEach(file => {
            this.addFileToFolder(+customerdata.id, file);
          });
        }
        if (this.mediaLibraryFiles && this.mediaLibraryFiles.length > 0 && result.customerdata && result.customerdata.length > 0) {
          let customerdata = result.customerdata[0];
          this.addMediaLibraryFiles(+customerdata.id);
        }
        this.finishProcess();
      });
    }
  }
  /**
   * Add the assets to the briefing media library
   * @param customerdataId client id of the briefing
   */
  public async addMediaLibraryFiles(customerdataId: number) {
    for (let file of this.mediaLibraryFiles) {
      await this.addParentToFile(customerdataId, file);
    }
  }
  /**
   * Complete the {@link Asset}  information included in the briefing media library
   * @param parent Client id of the briefing
   * @param file asset to which the information will be added
   */
  public async addParentToFile(parent: number, file) {
    if (file && !file.parents) {
      file.parents = [];
    }

    file.parents.push(+parent);

    let result = await this.assetService.editAsset(file);
  }
  /**
   *  Creates a new material in the parents passed as param
   * @param parent Client id of the briefing and the parent of the new material
   * @param file , the new material
   */
  public async addFileToFolder(parent: number, file) {

    let fileFormData = new FormData();

    let mime = file.type;
    let extension = Utils.getFileExtension(file.name);

    let type = this.mimetypeService.getMimetypeByMimetype(mime);
    if (!type && extension) {
      type = this.mimetypeService.getMimetypeByExtension(extension);
    }

    let newMaterial: Material = new Material();

    if (type && type.extension == '.svg') {
      newMaterial.svgfile = true;
    }

    newMaterial.name = file.name;
    newMaterial.parents.push(+parent);
    newMaterial.type = type.def_assettype;
    newMaterial.downloadLink = "formdata:file0";
    newMaterial.domain = AppSettings.DOMAIN;
    delete newMaterial.created;
    delete newMaterial.selected;
    delete newMaterial.preview;
    delete newMaterial.step_time;

    newMaterial.workflow = Utils.getWorkflowId(newMaterial.type);
    newMaterial.workflowStep = Utils.getDefaultWorkflowStep(newMaterial.type);

    fileFormData.append("entity", JSON.stringify(newMaterial));
    fileFormData.append("file0", file);

    let result = await this.materialsService.saveMaterial(newMaterial, fileFormData);

  }
  /**
   * Adds all files (selected or dropped) to the list
   * @param event The drop event
   */
  public showFile(event) {
    event.preventDefault();

    if (event.dataTransfer != null && event.dataTransfer.files != null && event.dataTransfer.files.length > 0) {

      Array.from(event.dataTransfer.files).forEach(file => this.files.push(file));
    } else if (event.srcElement != null && event.srcElement.files != null && event.srcElement.files.length > 0) {
      Array.from(event.srcElement.files).forEach(file => this.files.push(file));
      event.target.value = '';
    }
  }
  /**
   * Removes the selecte file from the list
   * @param file The selected file
   */
  public deleteFile(file) {
    this.files.splice(this.files.indexOf(file), 1);

    if (file.id) this.deleteFiles.push(file);
  }
  /**
   * End the creation of a new brifing and closes the dialog (this component)
   * and remains in the component {@link BriefingsComponent}
   */
  public finishProcess() {
    this.creatingBriefing = false;
    this.deleteFiles = [];
    this.reload.emit();
  }
}