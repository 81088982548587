import { AppSettings } from "../app.settings";
import { TasksService } from "../services/tasks/tasks.service";
import { Action } from "./action.model";
import { Asset } from "./asset.model";

/**
 * This class defines the tasks structure
 */
export class Task extends Asset {
    /**
     * date the task starts
     */
    startDate: string;
    /**
     * deadline for the task
     */
    deadline: string;
    /**
     * id of the user who created the task
     */
    owner: number;
    /**
     * Array with the ids of the parents of the task
     */
    parents: Array<number>;
    /**
     * Array with the ids of the jobs of the task
     */
    jobs: Array<Action>;
    /**
     * indicates whether this task is on the watch list
     */
    watchlist: boolean;
    /**
     * Array with task assets
     */
    files: Array<any>;
    /**
     * priority of task
     */
    priority: number;
    /**
     * Indicates if it is a milestone
     */
    milestone: boolean;
    /**
     * Info of task
     */
    info: string;
    /**
     * Array of the {@link Materials} contained in the task
     */
    materials: Array<any>;
    /**
     * Status of task
     */
    status: string = 'open';
    /**
     * Indicates whether the task has ever been opened
     */
    unopened: boolean;
    /**
     * Array with the id {@link User} of the owner of the task.
     */
    // ownerUser: User;
    owner_rel: number[] = [];
    /**
     * array of ids with task objectives
     */
    targets: number[] = [];
    /**
     * work hours of task
     */
    workHours: number;
    /**
     * planned hours of task
     */
    plannedHours: number;

    /**
     * workflow of task
     */
    workflow: number = AppSettings.WORKFLOW_TASK;
    /**
     * workflow step of task
     */
    workflowStep: number = TasksService.TASK_WORKFLOW_PLANNED;
    /**
     * step of task
     */
    step;
    /**
     * if it is a task of a request
     */
    request_task: boolean = false;
    /**
     * Comments on the task
     */

    communication = [];

    /**
     * Obtain the type name of the task (depending on the task: internal, external or request task)
     */
    get typeName() {
        let val = this.type;
        if (TasksService.EXTERNAL_TASK_TYPES.some(t => t.key === this.type)) {
            val = TasksService.EXTERNAL_TASK_TYPES.find(t => t.key === this.type).name;
        } else if (TasksService.INTERNAL_TASK_TYPES.some(t => t.key === this.type)) {
            val = TasksService.INTERNAL_TASK_TYPES.find(t => t.key === this.type).name;
        } else if (TasksService.REQUEST_TASK_TYPES.some(t => t.key === this.type)) {
            val = TasksService.REQUEST_TASK_TYPES.find(t => t.key === this.type).name;
        }
        return val;
    }

    /**
     * Obtain the priority name of the task
     */
    get priorityName() {
        let val: string | number = this.priority;

        if (TasksService.PRIORITIES.some(p => p.key === this.priority)) {
            val = TasksService.PRIORITIES.find(p => p.key === this.priority).name;
        }
        return val;
    }
}

/**
 * Defines final file for tasks
 */
export class FinalData {
    /**
     * name of FinalData
     */
    name: string = "Final data";
    /**
     * domain of FinalData
     */
    domain: string = AppSettings.DOMAIN;
}

/**
 * Defines customer file for tasks
 */
export class CustomerData {
    /**
     * name of CustomerData
     */
    name: string = "Customer data";
    /**
     * domain of CustomerData
     */
    domain: string = AppSettings.DOMAIN;
}

/**
 * Defines estimation file for tasks
 */
export class CostEstimate {
    /**
     * name of CostEstimate
     */
    name: string = "Cost Estimate";
    /**
     * domain of CostEstimate
     */
    domain: string = AppSettings.DOMAIN;
}

/**
 * Defines consulting file for tasks
 */
export class Consulting {
    /**
     * name of Consulting
     */
    name: string = "Consulting";
    /**
     * domain of Consulting
     */
    domain: string = AppSettings.DOMAIN;
}
