import { Component, Input, OnInit } from '@angular/core';

/**
 * @ignore
 */
@Component({
  selector: 'app-history-widget',
  templateUrl: './history-widget.component.html',
  styleUrls: ['./history-widget.component.scss']
})
export class HistoryWidgetComponent implements OnInit {

  @Input() jobId;
  @Input() project;

  loadingData = false;

  constructor() { }

  ngOnInit() {
  }

}
