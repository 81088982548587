import { ChangeDetectorRef, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TasksService } from 'app/shared/services/tasks/tasks.service';
import { UserService } from 'app/shared/services/user/user.service';

/**
 * @ignore
 */
@Component({
  selector: 'app-tasks-widget',
  templateUrl: './tasks-widget.component.html',
  styleUrls: ['./tasks-widget.component.scss']
})
export class TasksWidgetComponent implements OnInit, OnDestroy {

  /**
   * The job id from where we should obtain the task list. Data only from {@link ActionDetailComponent}
   */
  @Input() jobId;

  /**
   * The jobs list from where we should obtain the task list. Data only from {@link ProjectDetailComponent}
   */
  @Input() jobs = [];

  /**
   * Indicates if the tasks list should come from the user rather than job or jobs list
   */
  @Input() userTasks = false;

  /**
   * Indicates if total of tasks list should be displayed as a bubble in the widget
   */
  @Input() showTotal : boolean = false;

  /**
   * The title of widget according to listing displayed
   */
  @Input() label : string = 'taskList';

  /**
   * Indicates if widget is displayed in dashboard
   */
  @Input() dashboard: boolean = false;

  /**
   * Amount of items to show
   */
  @Input() maxDisplay;

  @Input() mainManager: boolean = false;

  loadingData: boolean = true;

  list = [];
  taskList = [];
  currentUser;
  queryParams;

  constructor(
    private cdr: ChangeDetectorRef,
    private tasksService: TasksService,
    private usersService: UserService
  ) {}

  /**
   * Initialize the listing to display depending on where they are required (job, jobs list, user tasks)
   */
  ngOnInit() {
    this.currentUser = this.usersService.getCurrentUser();
    if (this.jobId) {
      this.tasksService.getTasksByAction(this.jobId).then(async data => {
        this.taskList = [];
        let over = false;
        while (!over) {
          this.taskList.push(...this.tasksService.fillData(this.currentUser, data.result));

          if (data.offset + data.limit < data['total-count']) {
            data = await this.tasksService.getTasksByAction(this.jobId, data.offset + data.limit);
          } else {
            over = true;
            this.loadingData = false;
            this.filterTasks();
            this.sorting(1);
            this.taskList.map(x => x.id);
            this.queryParams = {jobs: this.jobId};
          }
        }

      });
    } else if (this.jobs && this.jobs.length > 0){
      this.taskList = [];
      this.jobs.forEach(job => {
        this.tasksService.getTasksByAction(job).then(async data => {
          let over = false;
          while (!over) {
            this.taskList.push(...this.tasksService.fillData(this.currentUser, data.result));
            if (data.offset + data.limit < data['total-count']) {
              data = await this.tasksService.getTasksByAction(this.jobId, data.offset + data.limit);
            } else {
              over = true;
              this.loadingData = false;
              this.filterTasks();
              this.sorting(1);
              this.queryParams = {jobs: this.jobs};
            }
          }
        });
      });
    } else if (this.userTasks){
      this.taskList = [];
      this.tasksService.getTasks().then(async data => {
        let over = false;
        while (!over) {
        this.taskList.push(...this.tasksService.fillData(this.currentUser, data.result));
          if (data.offset + data.limit < data['total-count']) {
            data = await this.tasksService.getTasks(data.offset + data.limit);
          } else {
            this.taskList = this.taskList.filter(task => (task.responsible && +task.responsible === +this.currentUser.id));
            this.loadingData = false;
            this.filterTasks();
            over = true;
            this.sorting(1);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.tasksService.uploadTaskUnopened(this.list.filter(task => task.unopened));
  }
  
  /**
   * Filters the tasks list depending on where the widget is displayed and the logged-in user profile.
   */
  filterTasks(){
    if (this.dashboard) {
      if (this.currentUser.customer){
        this.taskList = this.taskList.filter(task => task.workflowStep && +task.workflowStep != TasksService.TASK_WORKFLOW_DONE && +task.workflowStep != TasksService.TASK_WORKFLOW_ON_HOLD && +task.workflowStep != TasksService.TASK_WORKFLOW_PLANNED && +task.workflowStep != TasksService.TASK_WORKFLOW_APPROVED && +task.workflowStep != TasksService.TASK_WORKFLOW_REJECTED);
      } else {
        this.taskList = this.taskList.filter(task => task.workflowStep && +task.workflowStep != TasksService.TASK_WORKFLOW_DONE && +task.workflowStep != TasksService.TASK_WORKFLOW_ON_HOLD && +task.workflowStep != TasksService.TASK_WORKFLOW_APPROVED && +task.workflowStep != TasksService.TASK_WORKFLOW_REJECTED );
      }
    }
    this.cdr.detectChanges();
  }

  /**
   * Sorts the jobs list according to the the option
   * 
   * @param option The option to be sorted. 1: by date. 2: by priority. 3: by name
   */
  sorting(option) {
    switch (option) {
      case 1:
        this.list = this.taskList.sort((a, b) => new Date(b.createdAttr).getTime() - new Date(a.createdAttr).getTime());
        break;
      case 2:
        this.list = this.taskList.sort((a, b) => a.priority - b.priority);
        break;
      case 3:
        this.list = this.taskList.sort((a, b) => a.name.localeCompare(b.name));
        break;
    }
  }

}