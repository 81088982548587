import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { Briefing } from 'app/shared/model/briefing.model';
import { Shortcut, ShortcutBriefing } from 'app/shared/model/shortcut.model';
import { DatePipe } from '@angular/common';
import { AppSettings } from 'app/shared/app.settings';
import { Material } from 'app/shared/model/material.model';
import Utils from 'app/shared/utils/utils';
import { MaterialsService } from '../materials/materials.service';
import { MimetypeService } from '../mimetype/mimetype.service';
import { ActionsService } from '../actions/actions.service';
import { OCService } from '../satellites/oc.service';
import { Observable } from 'rxjs';
import { DetailedBriefing } from 'app/shared/model/detailedBriefing.model';

/**
 * Service defined to operate with {@link Briefing}, {@link DetailedBriefing}, {@link Shorcut} and {@link ShortcutBriefing}
 */
@Injectable({
  providedIn: 'root'
})
export class BriefingService {

  /**
   * Step in progress.
   */
  static readonly STEP_IN_PROGRESS = "10";
  /**
   * Step sent.
   */
  static readonly STEP_SENT = "20";

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   * @param datepipe Service with the date pipe used to format dates.
   * @param materialsService Service with the functions related to the materials.
   * @param actionsService Service with the functions related to the actions.
   * @param mimetypeService Service with the functions related to the mime types.
   * @param ocService Service with the functions related to the online channel.
   */
  constructor(
    private hcmsService : HCMSService,
    public datepipe: DatePipe,
    private materialsService: MaterialsService,
    private actionsService: ActionsService,
    private mimetypeService: MimetypeService,
    private ocService: OCService
  ) {}

  /**
   * Get briefing.
   * @param id Briefing ID.
   * @returns Briefing.
   */
  public getBriefing(id) {
    return this.hcmsService.get().one('entity/briefing', id).get().toPromise();
  }

  /**
   * Update briefing.
   * @param id Briefing ID.
   * @param briefing Briefing.
   * @returns Briefing updated.
   */
  public updateBriefing(id, briefing) {
    return this.getBriefing(id).then(updated => {
      updated.route = 'entity/briefing';
      if (briefing && briefing.step) {
        updated.workflowStep = briefing.step;
      }
      if (briefing && briefing.channel) {
        updated.channel = briefing.channel;
      }
      if (briefing && briefing.objective) {
        updated.objective = briefing.objective;
      }
      if (briefing && briefing.priorityCntr) {
        updated.priorityCntr = briefing.priorityCntr;
      }
      return updated.put().toPromise();
    });
  }

  /**
   * Duplicate a briefing. This involves creating a briefing and a shortcut containing it.
   * 
   * @param briefing The briefing to duplicate
   * @param currentUser The user that duplicate the briefing, the user logged.
   * @returns The shorcut cuplicated (with the briefing included).
   */
  duplicateBriefing(briefing, currentUser) {
    return this.getShortcut(briefing.id).then(data => {
      if (data.result && data.result.length > 0) {
        let asset = data.result[0];
        let newBriefing = Object.assign({}, asset.briefings[0]);
        delete newBriefing.id;
        newBriefing.name = 'Copy of ' + newBriefing.name;
        newBriefing.workflowStep = +BriefingService.STEP_IN_PROGRESS;
        newBriefing.createdByNew = +currentUser.id;
        newBriefing.modifiedByNew = +currentUser.id;

        let newShortcut = new Shortcut();
        newShortcut.briefings.push(newBriefing);
        newShortcut.info = asset.info;
        newShortcut.name = 'Copy of ' + asset.name;
        newShortcut.priorityCntr = asset.priorityCntr;
        newShortcut.type = asset.type;
        newShortcut.workflow = asset.workflow;
        newShortcut.workflowStep = 10;
        newShortcut.deadline = asset.deadline;
        newShortcut.startDate = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss');
        newShortcut.owner_rel.push(+currentUser.id);
        newShortcut.createdByNew = +currentUser.id;
        newShortcut.modifiedByNew = +currentUser.id;

        if (asset.type == 'project.') {
          delete newShortcut.agencydata;
          delete newShortcut.finaldata;
          delete newShortcut.costestimate;
        }

        return this.createShortcut(newShortcut).then(result => {
          let files = asset.customerdata[0].files;
          if (files && result.customerdata && result.customerdata.length > 0){
            let newFiles = [];
            files.forEach(file => {
              let newFile = Object.assign({}, file);
              delete newFile.id;
              newFiles.push(newFile);
            });

            let customerdata = result.customerdata[0];
            newFiles.forEach(file => {
              this.addFileToFolder(+customerdata.id, file);
            });
          }
          return result;
        });
      }
    });
  }

  /**
   * Add file to folder.
   * @param parent 
   * @param file 
   */
  private async addFileToFolder(parent : number, file) {
    let fileFormData = new FormData();
    let mime = file.type;
    let extension = Utils.getFileExtension(file.name);
    let type = this.mimetypeService.getMimetypeByMimetype(mime);
    let newMaterial: Material = new Material();

    if (!type && extension) {
      type = this.mimetypeService.getMimetypeByExtension(extension);
    }

    if (type && type.extension == '.svg') {
      newMaterial.svgfile = true;
    }

    newMaterial.name = file.name;
    newMaterial.parents.push(+parent);
    newMaterial.type = type.def_assettype;
    newMaterial.downloadLink = "formdata:file0";
    newMaterial.domain = AppSettings.DOMAIN;
    delete newMaterial.created;
    delete newMaterial.selected;
    delete newMaterial.preview;
    delete newMaterial.step_time;

    newMaterial.workflow = Utils.getWorkflowId(newMaterial.type);
    newMaterial.workflowStep = Utils.getDefaultWorkflowStep(newMaterial.type);

    fileFormData.append("entity", JSON.stringify(newMaterial));
    fileFormData.append("file0", file);

    let result = await this.materialsService.saveMaterial(newMaterial, fileFormData);

  }

  /**
   * Obtain the entity route depending on asset (detailed briefing) type
   * 
   * @param {string} assetType The detailed briefing type
   * @returns The entity route required.
   */
  public getDetailedBriefingEntity(assetType: string) {
    switch (assetType) {
      case 'project.':
        return 'entity/detailed_briefing_project';
      case 'order.':
        return 'entity/detailed_briefing_job';
      default:
        return 'entity/detailed_briefing';
    }
  }

  /**
   * Create detailed briefing.
   * @param detailedBriefing Detailed briefing.
   * @returns Briefing.
   */
  public createDetailedBriefing(detailedBriefing: DetailedBriefing) {
    let route = this.getDetailedBriefingEntity(detailedBriefing.type);
    return this.hcmsService.get().all(route).post(detailedBriefing).toPromise();
  }

  /**
   * Get detailed briefing.
   * @param {string} briefingId Briefing ID.
   * @returns Detailed briefing.
   */
  public getDetailedBriefing(briefingId) {
    let query = "briefings.id=" + briefingId;
    return this.hcmsService.get().one('entity/action?query=' + query).get().toPromise();
  }

  /**
   * Obtain the asset (job or project) of a detailed briefing
   *  
   * When it's available create project detailed briefing, replace route with the following:
   * 
   * ``` let route = this.getDetailedBriefingEntity(type); ```
   * 
   * @param assetId The asset id
   * @param {string} type The detailed briefing type
   * @returns the asset (job or project)
   */
  public getAssetDetailedBriefing(assetId, type: string = '') {
    let route = 'entity/action';
    return this.hcmsService.get().one(route, assetId).get().toPromise();
  }

  /**
   * Update detailed briefing.
   * @param {string} id Detailed briefing ID. 
   * @param detailedBriefing Detailed briefing.
   * @returns Detailed briefing updated. (promise)
   */
  public updateDetailedBriefing(id, detailedBriefing) {
    let route = this.getDetailedBriefingEntity(detailedBriefing.type);
    return this.hcmsService.get().one(route, id).get().toPromise().then(updated => {
      updated = Object.assign(updated, detailedBriefing);
      return updated.put().toPromise();
    });
  }

  /**
   * Obtain the entity route depending on asset (briefing) type
   * 
   * @param {string} assetType The briefing type
   * @returns The entity route required.
   */
  public getShortCutEntity(assetType: string) {
    switch (assetType) {
      case 'project.':
        return 'entity/shortcutproject';
      case 'order.':
        return 'entity/shortcutjob';
      default:
        return 'entity/shortcut';
    }
  }

  /**
   * Create shortcut
   * @param {Shortcut} shortcut {@link ShortCut}
   * @returns Promise
   */
  public createShortcut(shortcut: Shortcut) {
    let route = this.getShortCutEntity(shortcut.type);
    return this.hcmsService.get().all(route).post(shortcut).toPromise();
  }

  /**
   * Get shotcut
   * @param briefingId Briefing ID. 
   * @returns Shortcut
   */
  public getShortcut(briefingId) {
    let query = "briefings.id=" + briefingId;
    return this.hcmsService.get().one('entity/shortcut?query=' + query).get().toPromise();
  }

  /**
   * Get asset shortcut.
   * @param {string} assetId Asset ID.
   * @param {string} type Type
   * @returns Asset shortcut.
   */
  public getAssetShortcut(assetId, type: string = '') {
    let route = this.getShortCutEntity(type);
    return this.hcmsService.get().one(route, assetId).get().toPromise();
  }

  /**
   * Update briefing shortcut.
   * @param {string} id Briefing ID.
   * @param {string} type type.
   * @param deletedFiles Deleted files.
   * @param briefing Briefing.
   * @param currentUser Current user.
   * @returns Briefing updated. (Promise)
   */
  public updateBriefingShortcut(id, type, deletedFiles, briefing, currentUser) {
    return this.getAssetShortcut(id, type).then(async updated => {
      let briefingShortcut : ShortcutBriefing = new ShortcutBriefing();
      briefingShortcut.modifiedByNew = +currentUser.id;
      briefingShortcut.createdByNew = +currentUser.id;
      briefingShortcut.workflowStep = briefing && briefing.step ? briefing.step : +BriefingService.STEP_IN_PROGRESS;
      briefingShortcut.name = updated.name;
      briefingShortcut.priorityCntr = updated.priorityCntr;
      briefingShortcut.info = updated.info;

      let shortcut : Shortcut = new Shortcut();
      shortcut.briefings.push(briefingShortcut);

      if (briefing.startDate) shortcut.startDate = briefing.startDate;
      if (briefing.deadline) shortcut.deadline = briefing.deadline;

      if (briefing.type && briefing.type == 'project') {
        shortcut.removeRelation = [];
        if (updated.agencydata && updated.agencydata.length > 0) shortcut.removeRelation.push(updated.agencydata[0].id);
        if (updated.finaldata && updated.finaldata.length > 0) shortcut.removeRelation.push(updated.finaldata[0].id);
        if (updated.costestimate && updated.costestimate.length > 0) shortcut.removeRelation.push(updated.costestimate[0].id);
      }

      if (briefing.name) {
        shortcut.name = briefing.name;
        briefingShortcut.name = briefing.name;
      }

      if ((!briefing.type || briefing.type == 'action') && briefing.project > 0) {
        if (!updated.parents) {
          updated.parents = [];
        }

        updated.parents.push(briefing.project);
      }

      if (briefing.priority) {
        shortcut.priorityCntr = briefing.priority;
        briefingShortcut.priorityCntr = briefing.priority;
      }

      if (briefing.info) {
        shortcut.info = briefing.info;
        briefingShortcut.info = briefing.info;
      }

      if (deletedFiles && deletedFiles.length > 0) {
        shortcut.customerdata[0].removeRelation = [];

        deletedFiles.forEach(file => {
          shortcut.customerdata[0].removeRelation.push(file.id);
        });
      }

      updated.route = this.getShortCutEntity(updated.type);
      updated = Object.assign(updated, this.hcmsService.get().copy(shortcut).plain());

      if (briefing.type) {
        if (briefing.type == 'project') {
          updated.type = 'project.';
          updated.workflow = AppSettings.WORKFLOW_PROJECT;
        } else {
          updated.type = 'order.';
          updated.workflow = AppSettings.WORKFLOW_ACTION;

          let actions = await this.actionsService.getActionsByParents([updated.id]);

          if (actions && actions.result && actions.result.length > 0) {
            if (!updated.removeRelation) {
              updated.removeRelation = [];
            }

            actions.result.forEach(action => {
              updated.removeRelation.push(action.id);
            });
          }
        }
      }

      return updated.save().toPromise();
    });
  }

  /**
   * Create briefing.
   * @param file File.
   * @param parent Parent.
   * @returns 
   */
  public createBriefing(file, parent) {
    let briefing : Briefing = Object.assign(new Briefing, {});
    briefing.name = file.name;
    briefing.domain = parent.domain;
    briefing.parents = [];
    briefing.parents.push(parent.id);
    briefing.downloadLink = "formdata:file0";

    let formData = new FormData();
    formData.append("entity",JSON.stringify(briefing));
    formData.append("file0", file);

    return this.hcmsService.get().all('entity/briefing').post(formData).toPromise();
  }

  /**
   * Get channel values.
   * @returns Channel values.
   */
  public getChannelValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:cntr.channel_hubz').getList();
  }

  /**
   * Get client values.
   * @returns Client values.
   */
  public getClientValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:cntr.client').getList();
  }

}
