import { AppSettings } from "../app.settings";

/**
 * This class defines the shorcut basic structure
 */
export class Shortcut {
    /**
     * id of Shortcut
     */
    id: number;
    /**
     * name of Shortcut
     */
    name: string;
    /**
     * domain of Shortcut
     */
    domain: string = AppSettings.DOMAIN;
    /**
     * workflow of Shortcut
     */
    workflow: number;
    /**
     * workflow step of Shortcut
     */
    workflowStep: number;
    /**
     * type of Shortcut
     */
    type: string;
    /**
     * date the task starts
     */
    startDate: string;
    /**
     * deadline of Shortcut
     */
    deadline: string;
    /**
     * info of Shortcut
     */
    info: string;
    /**
     * Identifier of the creator of the Shortcut
     */
    createdByNew: number;
    /**
     * Identifier of the modified of the Shortcut
     */
    modifiedByNew: number;
    /**
     * Manager id of Shortcut
     */
    manager_rel: Array<Number> = [];
    /**
     * Owner id of Shortcut
     */
    owner_rel: Array<Number> = [];
    /**
     * priority of Shortcut
     */
    priorityCntr: string;
    /**
     * Array with the ids of the parents of the Shortcut
     */
    parents: Array<number>;
    /**
     * Array with the ids of the elements from which the relationship with this Shortcut is to be deleted
     */
    removeRelation: Array<number>;

    /**
     * briefings associated with specific info for shortcut
     */
    /**
     * briefings  of Shortcut
     */
    briefings : Array<ShortcutBriefing> = [];

    /**
     * agencydata  of Shortcut
     */
    agencydata : Array<AgencyData> = [new AgencyData()];
    /**
     * finaldata  of Shortcut
     */
    finaldata : Array<FinalData> = [new FinalData()];
    /**
     * customerdata  of Shortcut
     */
    customerdata : Array<CustomerData> = [new CustomerData()];
    /**
     * consulting  of Shortcut
     */
    consulting : Array<Consulting> = [new Consulting()];
    /**
     * costestimate  of Shortcut
     */
    costestimate : Array<CostEstimate> = [new CostEstimate()];
}

/**
 * This class defines the shorcut for briefings
 */
export class ShortcutBriefing {
    /**
     * id of Shortcut
     */
    id: number;
    /**
     * name of Shortcut
     */
    name: string = 'Briefing';
    /**
     * type of Shortcut
     */
    type: string = 'briefing.';
    /**
     * domain of Shortcut
     */
    domain: string = AppSettings.DOMAIN;
    /**
     * workflow of Shortcut
     */
    workflow: number = AppSettings.WORKFLOW_BRIEFING;
    /**
     * workflowStep of Shortcut
     */
    workflowStep: number;
    /**
     * info of Shortcut
     */
    info: string;
    /**
     * createdByNew of Shortcut
     */
    createdByNew: number;
    /**
     * modifiedByNew of Shortcut
     */
    modifiedByNew: number;
    /**
     * owner_rel of Shortcut
     */
    owner_rel: Array<Number> = [];
    /**
     * priorityCntr of Shortcut
     */
    priorityCntr: string;
}

/**
 * Defines customer file for shorcuts
 */
export class CustomerData {
    /**
     * id of Shortcut
     */
    id: number;
    /**
     * type of Shortcut
     */
    type: string = 'group.';
    /**
     * name of Shortcut
     */
    name: string = "01 Customer data";
    /**
     * domain  of Shortcut
     */
    domain : string = AppSettings.DOMAIN;
    /**
     * removeRelation of Shortcut
     */
    removeRelation: Array<number>;
}

/**
 * Defines agency file for shorcuts
 */
export class AgencyData {
    /**
     * id of Shortcut
     */
    id: number;
    /**
     * type of Shortcut
     */
    type: string = 'group.';
    /**
     * name of Shortcut
     */
    name: string = "02 Agency data";
    /**
     * domain  of Shortcut
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines final file for shorcuts
 */
export class FinalData {
    /**
     * id of Shortcut
     */
    id: number;
    /**
     * type of Shortcut
     */
    type: string = 'group.';
    /**
     * name of Shortcut
     */
    name: string = "03 Final data";
    /**
     * domain  of Shortcut
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines estimation file for shorcuts
 */
export class CostEstimate {
    /**
     * id of Shortcut
     */
    id: number;
    /**
     * type of Shortcut
     */
    type: string = 'group.';
    /**
     * name of Shortcut
     */
    name: string = "04 Cost Estimate";
    /**
     * domain  of Shortcut
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines consulting file for shorcuts
 */
export class Consulting {
    /**
     * id of Shortcut
     */
    id: number;
    /**
     * type of Shortcut
     */
    type: string = 'group.';
    /**
     * name of Shortcut
     */
    name: string = "05 Consulting";
    /**
     * domain  of Shortcut
     */
    domain : string = AppSettings.DOMAIN;
}