import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * @ignore
 */
@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input() rating;
  @Input() editable = false;
  @Output() newRating = new EventEmitter<any>();

  starClass1 = 'emptyStar';
  starClass2 = 'emptyStar';
  starClass3 = 'emptyStar';
  starClass4 = 'emptyStar';
  starClass5 = 'emptyStar';
  selecting = false;
  tempRating = 0;

  constructor() { }

  ngOnInit() {
  }

  selectStar(index) {
    this.selecting = true;
    this.tempRating = index;

    for (let i = 1; i <= index; i++) {
      switch (i) {
        case 1:
          this.starClass1 = 'fullStar';
          break;
        case 2:
          this.starClass2 = 'fullStar';
          break;
        case 3:
          this.starClass3 = 'fullStar';
          break;
        case 4:
          this.starClass4 = 'fullStar';
          break;
        case 5:
          this.starClass5 = 'fullStar';
          break;
      }
    }

    for (let i = index + 1; i <= 5; i++) {
      switch (i) {
        case 1:
          this.starClass1 = 'emptyStar';
          break;
        case 2:
          this.starClass2 = 'emptyStar';
          break;
        case 3:
          this.starClass3 = 'emptyStar';
          break;
        case 4:
          this.starClass4 = 'emptyStar';
          break;
        case 5:
          this.starClass5 = 'emptyStar';
          break;
      }
    }
  }

  deselectStar() {
    this.starClass1 = 'emptyStar';
    this.starClass2 = 'emptyStar';
    this.starClass3 = 'emptyStar';
    this.starClass4 = 'emptyStar';
    this.starClass5 = 'emptyStar';
    this.tempRating = 0;
    this.selecting = false;
  }

  checkStar(mode, value) {
    let visible = false;

    if (this.selecting) {
      switch (mode) {
        case 'full':
          visible = this.tempRating >= value;
          break;
        case 'half':
          visible = this.tempRating < value && this.tempRating >= value - 0.5;
          break;
        case 'empty':
          visible = this.tempRating < value && this.tempRating < value - 0.5;
          break;
      }
    } else {
      switch (mode) {
        case 'full':
          visible = this.rating >= value;
          break;
        case 'half':
          visible = this.rating < value && this.rating >= value - 0.5;
          break;
        case 'empty':
          visible = this.rating < value && this.rating < value - 0.5;
          break;
      }
    }

    return visible;
  }

  setRating(value) {
    this.rating = value;
    this.newRating.emit(value);
  }
}
