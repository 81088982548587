import { Asset } from './asset.model';

/**
 * This class defines the team structure
 */
export class Team extends Asset {

    /**
     * Name of Team
     */
    name: string;

    /**
     * Agency id
     */
    agencyId: number;

    /**
     * {@link TeamPicture} of team
     */
    pictures: TeamPicture[] = [];

    /**
     * Members in a team
     */
    members: any[] = [];

    /**
     * check mark to delete the image of the user with that id
     */
    removeMainPicture: number;

    saving: boolean = false;
}

/**
 * Defines the info for TeamPicture
 */
export class TeamPicture {

    /**
     * id of TeamPicture
     */
    id: number;
    /**
     * name of TeamPicture
     */
    name: string;
    /**
     * type of TeamPicture
     */
    type: string;
    /**
     * domain of TeamPicture
     */
    domain: string;
    /**
     * download link of TeamPicture
     */
    downloadLink: string;
    /**
     * Array with the coindicates whether it is necessary to reload the preview
     */
    reloadPreview = false;
    /**
     * version of TeamPicture
     */
    version: number;
    /**
     * image of TeamPicture
     */
    image: string;

}
