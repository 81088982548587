import { AppSettings } from "../app.settings";

/**
 * This class defines the basic structure of any asset
 */
export class Asset {

    /**
     * name  of asset
     */
    name : string;
    /**
     * id  of asset
     */
    id : number;
    /**
     * domain  of asset
     */
    domain : string = AppSettings.DOMAIN;
    /**
     * domain2  of asset
     */
    domain2 : string;
    /**
     * type of asset
     */
    type: string;
    /**
     * date of created of asset
     */
    created: string;
    /**
     *  date of created of asset
     */
    createdAttr: string;
    /**
     * info  of asset
     */
    info : string;
    /**
     * Workflow step of the asset.
     */
    workflow: number;
    /**
     * workflow of the asset.
     */
    workflowStep: number = 10;
    /**
     * Workflow target of asset
     */
    workflowTarget: number;
    /**
     * id of the {@link User} who created the asset
     */
    createdBy: number;
    /**
     * id of the {@link User} who modified the asset
     */
    modifiedBy: number;
    /**
     * date of modification of the asset
     */
    modifiedAttr: string;

    /**
     * Indicates whether approval is required
     */
    needsApproval: boolean;

    /**
     * mark to delet the asset
     */
    markDeletion: number;
    /**
     * indicates whether it is marked for deletion
     */
    markForDeletion: boolean;
    /**
     * Array of link to download assets
     */
    downloads;

    /**
     * tags of asset
     */
    tags: any[];
    /**
     * image of asset
     */
    image: string;
}