import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { OCService } from '../satellites/oc.service';
import { UserService } from '../user/user.service';
import { Media } from 'app/shared/model/media.model';
import Utils from 'app/shared/utils/utils';
import { AppSettings } from 'app/shared/app.settings';
import { CommonService } from '../common/common.service';
import { DatePipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { BaseService } from '../base/base.service';
import { BehaviorSubject } from 'rxjs';
import { CollectionsService } from '../collections/collections.service';
import { DamService } from '../../../layout/dam/service/dam.service';
import { WorkflowService } from '../workflows/workflow.service';
import { NotificationsService } from '../notifcations/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

/**
 * Service defined to manage operations with "Assets" of "Media" type.
 */
@Injectable({
  providedIn: 'root'
})
export class AssetService extends BaseService<Media> {

  /* Private */
  /**
   * User collections
   */
  private userCollections;

  /**
   * subscription that brings all collections
   */
  private collectionsSubcription: Subscription;

  /* Protected */
  /**
   * Entity.
   */
  protected entity = 'entity/media';
  /**
   * Options.
   */
  protected options = {
    query: '(workflowStep=520|workflowStep=530)&show=true',
    limit: AppSettings.LIMIT,
    offset: 0,
    order: '-createdAttr'
  };

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   * @param ocService Service with the functions related to the online channel.
   * @param userService Service with the functions related to the users.
   * @param commonService Service with the common functions.
   * @param datepipe Pipe with the date pipe used to format dates.
   * @param collectionsService Service with the functions related to the collections.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   * @param damService Service with the functions related to the dam.
   * @param workflowService Service with the functions related to the workflow.
   */
  constructor(
    protected hcmsService: HCMSService,
    private ocService: OCService,
    protected userService: UserService,
    private commonService: CommonService,
    public datepipe: DatePipe,
    private collectionsService: CollectionsService,
    private notifications: NotificationsService,
    private translateService: TranslateService,
    private damService: DamService,
    private workflowService: WorkflowService
  ) {
    super(hcmsService, userService);

    this.init();
    this.collectionsSubcription = this.collectionsService.collections.subscribe(collections => { this.userCollections = collections });
  }

  /**
   * It is unsubscribed when the component is destroyed.
   */
  ngOnDestroy() {
    if (this.collectionsSubcription) {
      this.collectionsSubcription.unsubscribe();
    }
  }

  /* Protected methods */
  /**
   * Fill data
   * @param asset Item to be filled with data.
   */
  protected fillData(asset) {
    asset.inCollection = [];
    let fileType: any = this.damService.getMimetypeByMimetype(asset.mimetype);

    if (fileType) {
      asset.fileType = fileType.name;
    }
    asset.status = this.workflowService.getWorkflowStatus(asset.workflowStep);

    if (this.userCollections) {
      this.userCollections.forEach(col => {
        if (col.assets && col.assets.includes(asset.id)) {
          asset.inCollection.push(col.id);
        }
      });
    }
  }

  /**
   * Edit asset
   * @param asset Asset to be edited. 
   * @returns Promise response.
   */
  public async editAsset(asset) {
    let newAsset = Object.assign(new Media, this.hcmsService.get().copy(asset).plain());
    delete newAsset.downloadLink;

    if (!newAsset.step_time) {
      delete newAsset.step_time;
    } else {
      newAsset.step_time = +newAsset.step_time;
    }

    let updateAsset = await this.hcmsService.get().one(Utils.getEntity(newAsset.type), newAsset.id).get().toPromise();
    updateAsset = Object.assign(updateAsset, newAsset);

    return updateAsset.save().toPromise();

  }

  /**
   * Get last asset.
   * @returns Last asset.
   */
  getLastAsset() {
    return this.hcmsService.get().one('entity/media?query=(workflowStep=520%7C(type="picture."%26workflowStep=40)%7C(type="pdf."%26workflowStep=20)%7C(type="office."%26workflowStep=20)%7C(type="text."%26workflowStep=30))%26show=true&limit=1&offset=0&order=-createdAttr').get().toPromise();
  }

  public getBackgroundImagesById(id) {
    return this.hcmsService.get().one('entity/view/' + id + '?limit=10').get().toPromise();
  }

  public getBackgroundImagesByTag(tag) {
    return this.hcmsService.get().one('entity/view?query=idtags=' + tag + '&limit=10').get().toPromise();
  }

  /**
   * Get update asset.
   * @param asset 
   * @returns 
   */
  getUpdateAsset(asset) {
    return this.hcmsService.get().one(Utils.getEntity(asset.type), asset.id).get().toPromise();
  }

  /**
   * Share assets
   * @param {string} recipients Emails array.
   * @param {string} message Email body.
   * @param {Media[]} assets Shared assets.
   */
  shareAssets(recipients: string, message: string, assets: Media[]) {
    let user = this.userService.getCurrentUser();
    if (!message) message = ' ';
    let encodedMessage = encodeURIComponent(encodeURIComponent(btoa(message)));
    let subject = encodeURIComponent(encodeURIComponent(btoa('Bildauswahl')));
    
    let env = environment.env == 'dev'? encodeURIComponent(encodeURIComponent(btoa('.dev'))) : '';
    let assetsString = assets.map(x => x.id).toString();
    let currentLang = this.commonService.getCurrentLanguage();

    let recipentsArray = recipients.split(',');
    recipentsArray = [].concat(...recipentsArray.map(x => x.split(';')));
    recipentsArray = recipentsArray.map(x => x.trim());

    this.ocService.get().one(`sendemail/recommendation/${user.id}/${recipentsArray.join(',')}/${encodedMessage}/${assetsString}/${currentLang}/${subject}/${env}`).get()
      .subscribe(result => {
        if (result.emailSent) {
          this.notifications.show(this.translateService.instant('successfully_shared'));
        }
      })
  }

  /**
   * Obtain the file from downloadLink
   *
   * @param {string} downloadLink
   * @returns The file required
   */
  getFileBlob(downloadLink) {
    return this.hcmsService.get().one(downloadLink.substring(downloadLink.indexOf('/entity/'))).withHttpConfig({ responseType: 'blob' }).get();
  }

  /**
   * Update asset metadata
   * @param asset Asset to be updated
   * @returns Asset updated.
   */
  updateMetadata(asset) {
    return this.getAssetToUpdate(asset.id, asset.type).then(current => {

      if (asset.valid_until) current.valid_until = asset.valid_until;
      if (asset.title) current.title = asset.title;
      if (asset.info) current.info = asset.info;
      if (asset.source) current.source = asset.source;
      if (asset.copyright) current.copyright = asset.copyright;
      current.show = asset.show;
      current.domain = asset.domain;

      if (current.show) {
        current.workflowStep = Utils.getApprovedWorkflowStep(current.type);
      }

      if (asset.tags) {

        let keywords = "";
        let tags: Array<number> = [];

        asset.tags.forEach((tag, index) => {
          tags.push(tag.id);
          keywords += tag.name;

          if (index < asset.tags.length - 1)
            keywords += ',';
        });

        current.keywords = keywords
        current.iptc_keywords = keywords
        current.tags = tags;
      }

      if (asset.valid_until) {
        let validUntil = new Date(asset.valid_until);
        current.valid_until = this.datepipe.transform(validUntil, 'yyyy-MM-dd');
      }

      return current.put().toPromise();

    });
  }

  /**
   * Get asset to update.
   * @param {string} id Asset id
   * @param {string} type Asset type 
   * @returns Asset
   */
  getAssetToUpdate(id, type) {
    let route = Utils.getEntity(type);
    return this.hcmsService.get().one(route, id).get().toPromise();
  }

  /**
   * Get domain values.
   * @param parent 
   * @returns Domain values.
   */
  public getDomainValues(parent): Observable<any> {
    return this.ocService.get().all('tables/domain/parent/' + parent).getList();
  }

  /**
   * Get channel values.
   * @returns channels values.
   */
  public getChannelValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:cntr.channel_hubz').getList();
  }

  /**
   * Get unit values.
   * @returns Unit values.
   */
  public getUnitValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:unit_cntr').getList();
  }

  /**
   * Get priority values.
   * @returns Priority values.
   */
  public getPriorityValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:priority_cntr').getList();
  }

  /**
   * Get country values.
   * @returns Country values.
   */
  public getCountryValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/censhare:address.country-code').getList();
  }

  /**
   * Update event relation message.
   * @returns Response promise.
   */
  public updateEventRelationMessage(assetId, assetType, message) {
    return this.getAssetToUpdate(assetId, assetType).then(current => {
      current.eventRelationMessage = message;

      return current.put().toPromise();
    });
  }

  /**
   * Get budget values.
   * @returns Budget values.
   */
  public getBudgetValues(): any {
    return {
      initialBudget: 10350,
      costEstimate: 1000,
      additionalQuote: 12000,
      invoice: 9800
    };
  }

  /**
   * Save budget
   * @returns {boolean} true.
   */
  public saveBudget(): any {
    return true;
  }

  /**
   * Update asset.
   * @returns Updated Asset (promise).
   */
  public updateAsset(asset) {
    let route = Utils.getEntity(asset.type);
    return this.hcmsService.get().one(route, asset.id).get().toPromise().then(updated => {
      updated = Object.assign(updated, asset);
      return updated.put().toPromise();
    });
  }

  /**
   * Update the asset start date and deadline.
   *
   * @param assetId The asset id
   * @param {string} startDate The start date of the asset
   * @param {string} deadline The deadline of the asset
   * @returns The asset updated
   */
  public async saveStartDateAndDeadlineOfAssetById(assetId, startDate, deadline) {
    return await this.ocService.get().one('ganttdates/' + assetId + '/start/' + startDate + '/end/' + deadline).get().toPromise();
  }
}