import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RolesService } from 'app/shared/services/user/roles.service';
import { UserService } from 'app/shared/services/user/user.service';
import Utils from 'app/shared/utils/utils';
import { ActionsService } from 'app/shared/services/actions/actions.service';

/**
 * This component defines the widget for asset budgets
 */
@Component({
  selector: 'app-budget-widget',
  templateUrl: './budget-widget.component.html',
  styleUrls: ['./budget-widget.component.scss']
})
export class BudgetWidgetComponent implements OnInit {

  /**
   * The asset to asseociate a budget
   */
  @Input() assetId;
  /**
   * Event to be launched when editing
   */
  @Output() isEditing = new EventEmitter<boolean>();

  /**
   * Indicates that data is loading
   */
  loadingData = false;
  /**
   * Indicates you are in edit mode
   */
  public editMode = false;
  /**
   * Asset
   */
  public asset: any = {};
  /**
   * Form
   */
  public budgetForm: FormGroup;
  /**
   * Indicates power user
   */
  public isPowerUser;
  /**
   * Indicates Manager user
   */
  public isManagerUser;
  /**
   * Indicates Production user
   */
  public isProductionUser;
  /**
   * Current user
   */
  private currentUser;

  /**
   * Constructor
   * @param formBuilder Service with the functions related to the forms.
   * @param rolesService Service with the functions related to the roles.
   * @param userService Service with the functions related to the users.
   * @param actionsService Service with the functions related to the assets.
   */
  constructor(
    private formBuilder: FormBuilder,
    private rolesService: RolesService,
    private userService: UserService,
    private actionsService: ActionsService,
  ) {
    this.currentUser = this.userService.getCurrentUser();
    this.isManagerUser = this.rolesService.checkRole(this.currentUser, RolesService.PROJECT_MANAGER_ROLE);
    this.isProductionUser = this.rolesService.checkRole(this.currentUser, RolesService.PRODUCTION_ROLE);

    this.budgetForm = this.formBuilder.group({
      'budget': [{value:null, disabled: this.isManagerUser || this.isProductionUser }, Validators.compose([]) ],
      'cost': [null, Validators.compose([])],
      'additionalQuote': [null, Validators.compose([])],
      'invoice': [null, Validators.compose([])]
    });
    
   }

  ngOnInit() {
    this.loadingData = true;

    if (this.assetId) {
      this.actionsService.getAction(this.assetId).then(data => {
        this.asset = data.plain();
        let {budget, cost, additionalQuote, invoice} = data;
        this.budgetForm.setValue({
          budget: budget ? budget : 0,
          cost: cost? cost: 0,
          additionalQuote: additionalQuote? additionalQuote: 0,
          invoice: invoice? invoice: 0
        });
        this.loadingData = false;
      })
    }    
  }

  /**
   * Save the budget of an asset
   */
  public saveBudget(): void {

    Utils.updatingWidget = true;
    let action = Object.assign(this.asset, this.budgetForm.value)

    this.actionsService.editAction(action).then( data => {
      if (data) {
        this.asset = data;
        this.editMode = false;
        Utils.reloadData = Utils.RELOAD_DATA_BUDGET_WIDGET;
      }
    })
  }

  /**
   * Change view to edit mode.
   * @param editMode event to set true o false de edit mode
   */
  public updateEditMode(editMode): void {
    this.editMode = editMode;
    this.isEditing.emit(editMode);
  }

}
