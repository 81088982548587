import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BREAKPOINTS, DEFAULT_BREAKPOINTS, FlexLayoutModule, LAYOUT_CONFIG } from '@angular/flex-layout';
import { MaterialModule } from './shared/material/material.module';

import { FooterComponent } from './shared/footer/footer.component';
import { NgxLiquidCacheModule, LiquidCacheConfig, LiquidCacheStorageTypes } from 'ngx-liquid-cache';
import { environment } from 'environments/environment';
import { RestangularModule } from 'ngx-restangular';
import { UserService } from './shared/services/user/user.service';
import { WorkflowService } from './shared/services/workflows/workflow.service';
import { AppSettings } from './shared/app.settings';

import { NgxImageZoomModule } from 'ngx-image-zoom';
import { User } from './shared/model/user.model';
import { ErrorsComponent } from './errors/errors.component';
import { ErrorsService } from './shared/services/errors/errors.service';
import { GlobalErrorHandler } from './shared/errors/global.error';
import { NotificationsComponent } from './shared/components/notifications/notifications.component';
import { DatePipe } from '@angular/common';
import { CommunicationChatComponent } from './shared/components/communication-chat/communication-chat.component';
import { CommonComponentsModule } from './shared/modules/commonComponents.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
// import Utils from './shared/utils/utils';
import { BryntumSchedulerProModule } from '@bryntum/schedulerpro-angular';


// import {
//     MsalModule,
//     MsalInterceptor,
//     MsalService,
//     BroadcastService,
// } from '@azure/msal-angular';
// import { Configuration } from 'msal';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeDe, 'de', localeDeExtra);

require('formdata-polyfill');

/**
 * AoT requires an exported function for factories
 * @param http {@link HttpClient}
 * @returns 
 */
export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

/**
 * Cache management
 */
const liquidCacheConfig: LiquidCacheConfig = {
    storageType: LiquidCacheStorageTypes.localStorage,
    localStoragePrefix: AppSettings.APP_PREFIX
};

/**
 * @ignore
 * @param RestangularProvider 
 */
export function RestangularConfigFactory(RestangularProvider) {

    RestangularProvider.setBaseUrl(environment.baseHCSMUrl);
    RestangularProvider.setDefaultHeaders({ 'Access-Control-Allow-Origin': true });
    RestangularProvider.setFullRequestInterceptor(function (element, operation, route, url: string, headers, params, httpConfig) {

        let currentUser: User = JSON.parse(localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE));
        if (currentUser) {
            if (operation == 'put') {
                if (element instanceof FormData) {

                    let entity: any = element.get('entity');
                    entity = JSON.parse(entity);

                    entity.modifiedByNew = +currentUser.id;

                    element.set('entity', JSON.stringify(entity));
                } else {
                    element.modifiedByNew = +currentUser.id;
                }
            }
            if (operation == 'post') {

                if (element instanceof FormData) {

                    let entity: any = element.get('entity');
                    entity = JSON.parse(entity);

                    entity.modifiedByNew = +currentUser.id;
                    entity.createdByNew = +currentUser.id;

                    element.set('entity', JSON.stringify(entity));
                } else {
                    element.modifiedByNew = +currentUser.id;
                    element.createdByNew = +currentUser.id;
                }
            }
        }

        if (url.indexOf(environment.baseHCSMUrl) >= 0 && url.indexOf('/schema/')) {
            headers.Authorization = environment.hcmsAuth;
        } else if (url.indexOf(environment.baseOCUrl) < 0) {

            var jwtUser = localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + 'jwtUser');

            if (jwtUser) {
                headers.Authorization = jwtUser;
            }
        }
        else {
            headers = {
                'Access-Control-Allow-Origin': true
            };
            if(environment.ocSatelliteAccess){
                headers.Authorization = environment.ocSatelliteAccess;
            }
        }

        let customParams = {};
        if (environment.env) customParams['environment'] = environment.env;
        if (AppSettings.OC_SITE) customParams['site'] = AppSettings.OC_SITE;
        customParams['lang'] = 'en';

        params = Object.assign({}, params, customParams);

        return {
            element: element,
            params: params,
            headers: headers,
            httpConfig: httpConfig
        };
    });
    RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {
        ErrorsService.instance.showHttpErrorResponse(response);
        if (response.url && response.url.indexOf(environment.baseHCSMUrl) > -1 && response.status === 401 && response.data && response.data.error.indexOf('invalid JWT token') > -1) {
            (window as any).location = "/login?expired=true";
        }

        if (response.status === 412) {
            setTimeout(() => {
                const newHeaders = response.request.headers;
                newHeaders.headers.delete('if-match');
                newHeaders.normalizedNames.delete('if-match');

                const newRequest = response.request.clone({ headers: newHeaders });

                response.repeatRequest(newRequest).subscribe();
            }, 3000);
        }

        if (response.status === 502 && (response.data.error.indexOf('is not checked out') > -1
            || response.data.error.indexOf('Outdated asset data') > -1)) {
            setTimeout(() => {
                const newHeaders = response.request.headers;
                newHeaders.headers.delete('if-match');
                newHeaders.normalizedNames.delete('if-match');

                const newRequest = response.request.clone({ headers: newHeaders });

                response.repeatRequest(newRequest).subscribe();
            }, 3000);
        }
        return true;
    });

    RestangularProvider.addResponseInterceptor(function (data, operation, what, url, response, deferred) {
        if (operation === 'get' && url.indexOf(environment.baseHCSMUrl) > -1) {

            let currentUser: User = JSON.parse(localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE));
            if (AppSettings.CHECK_MAIN_DOMAIN.filter(x => url.indexOf(x) > -1).length > 0) {
                if (data && data.result && data.result instanceof Array) {
                    data.result = data.result.filter(x => x.domain && x.domain.indexOf(AppSettings.DOMAIN) > -1);
                    data.result.forEach(x => {
                        if (x.materials) {
                            x.materials = x.materials.filter(m => m.domain && m.domain.indexOf(AppSettings.DOMAIN) > -1);
                        }
                    });
                } else if (data && data.domain && data.domain.indexOf(AppSettings.DOMAIN) < 0) {
                    data = {};
                }
            } else if (currentUser) {
                if (data && data.result && data.result instanceof Array) {

                    if (!(data.result.map(x => x.type).includes('sp:keyword.') || data.result.map(x => x.type).includes('text.banner.'))) {
                        data.result = data.result.filter(x => UserService.shouldShow(x, currentUser));
                    }
                    data.result.forEach(x => {
                        if (x.materials) {
                            x.materials = x.materials.filter(m => m.domain && m.domain.indexOf(currentUser.domain) > -1);
                        }
                    });
                } /*else if (!UserService.CHECK_FOR_TYPES.includes(data.type) && data && data.domain && data.domain.indexOf(currentUser.domain) < 0) {
                    data = {};
                }*/ else if (!UserService.shouldShow(data, currentUser)) {
                    data = {};
                }
            }
        }

        return data;
    });
}

export const BreakPointsProvider = { 
    provide: BREAKPOINTS,
    useValue: DEFAULT_BREAKPOINTS
};

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
    declarations: [AppComponent, FooterComponent, ErrorsComponent, NotificationsComponent, CommunicationChatComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        OverlayModule,
        HttpClientModule,
        //FlexLayoutModule.withConfig({ addFlexToParent: false }),
        {
            ngModule: FlexLayoutModule,
            providers: [
                {
                    provide: LAYOUT_CONFIG,
                    useValue: {
                        addFlexToParent: false,
                        addOrientationBps: true,
                        disableDefaultBps: true,
                        disableVendorPrefixes: false,
                        serverLoaded: false,
                        useColumnBasisZero: true
                    }
                }
            ]
        },
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CommonComponentsModule,
        RestangularModule.forRoot(RestangularConfigFactory),
        NgxLiquidCacheModule.forRoot(liquidCacheConfig),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            isolate: true
        }),
        NgxImageZoomModule.forRoot(),
        // MsalModule.forRoot({
        //     auth: {
        //         clientId: environment.clientId,
        //         authority: environment.authority,
        //         redirectUri: environment.redirectUrl,
        //     },
        //     cache: {
        //         cacheLocation: 'localStorage',
        //         storeAuthStateInCookie: isIE,
        //     },
        // },
        //     {
        //         popUp: !isIE,
        //         consentScopes: environment.scopes,
        //         // Deprecated 'unprotectedResources'
        //         // unprotectedResources: [],
        //         protectedResourceMap: [
        //             ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        //             ["assets/images/logos/*", null],
        //             ["assets/images/icons/*", null],
        //             ["assets/images/bkgs/*", null],
        //             ["assets/favicon/*", null],
        //             ["assets/i18n/en.json", null],
        //             ["assets/i18n/de.json", null],
        //             ["assets/fonts/dm-sam-v6-latin/*", null],
        //             ["assets/fonts/FontinSans/*", null],
        //             ["assets/fonts/OpenSans/*", null],
        //             ["assets/fonts/SegoeUI/*", null],
        //         ],
        //         extraQueryParameters: {}
        //     }),
        BryntumSchedulerProModule
    ],
    entryComponents: [ErrorsComponent, NotificationsComponent, CommunicationChatComponent],
    providers: [BreakPointsProvider, UserService, WorkflowService, ErrorsService, DatePipe,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: MsalInterceptor,
        //     multi: true
        // }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
