import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';

/**
 * This class defines the custom adapter for dates
 */
@Injectable()
export class MomentCustomAdapter extends MomentDateAdapter {

  /**
   * The constructor
   * @param dateLocale The locale date
   */
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  /**
   * Gets the fist day of a week
   * @returns The number one (Monday)
   */
  getFirstDayOfWeek(): number {
    return 1;
 }
}
