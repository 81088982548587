import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Media } from 'app/shared/model/media.model';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/shared/services/user/user.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { MailService } from 'app/shared/services/mail/mail.service';
import { User } from 'app/shared/model/user.model';
import { Mail } from 'app/shared/model/mail.model';
import { AppSettings } from 'app/shared/app.settings';

/**
 * @ignore
 */
@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent {

  recipients: string[] = [];
  groups: string[] = [];
  message: string = "";

  users: User[] = [];

  groupSelected: string[] = [];

  groupsCenshare;

  constructor(
    private dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Media[],
    private assetService: AssetService,
    private translateService: TranslateService,
    private userService: UserService,
    public roleService: RolesService,
    private mailService: MailService) {

    this.userService.getGroups().then(data => this.groupsCenshare = data.result);

    this.dialogRef.afterClosed().subscribe(result => {

      if (this.groups.length > 0 && result && this.recipients) {

        this.message += this.translateService.instant('message_saludation') + this.userService.getCurrentUser().display_name;

        this.recipients.forEach(recipent => {
          const mail = new Mail(AppSettings.MAIL_NOTIFICATION + '_' + recipent, AppSettings.MAIL_NOTIFICATION, +recipent, +recipent, this.message);
          mail.materials = data.map(x => x.id);

          this.mailService.saveMail(mail, true);
        });
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  groupChanged() {
    this.users = [];
    this.groups.forEach(group => {

      let newUsers = [];

      if (group.startsWith('#R#')) {
        newUsers = this.userService.getUsersByRole(group.substring(3));
      } else if (group.startsWith('#G#')) {
        newUsers = this.groupsCenshare.find(gc => gc.name === group.substring(3)).users;
      }

      newUsers = newUsers.filter(x => !this.users.some(y => y.id === x.id));
      this.users.push(...newUsers);

      /* if (!this.groupSelected.includes(group)) {
        this.recipients.push(...newUsers.map(user => user.id));
      }*/

    });

    this.recipients = this.recipients.filter(x => this.users.some(y => y.id === +x));
    this.groupSelected = this.groups;
  }

  getUsers() {
    return [].concat(...this.groups.map(role => this.userService.getUsersByRole(role)));
  }

}
