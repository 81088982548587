import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorsComponent } from 'app/errors/errors.component';
import { HttpErrorResponse } from '@angular/common/http';
import Utils from 'app/shared/utils/utils';
import { AppSettings } from 'app/shared/app.settings';
import { NotificationsService } from '../notifcations/notifications.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Service defined to manage the snackbar error notifications
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  /**
   * Error service.
   */
  static instance : ErrorsService;

  /**
   * Duration in seconds.
   */
  private durationInSeconds = 5;
  /**
   * {@link Utils} imported class
   */
  private utils = Utils;

  /**
   * Constructor.
   * @param snackBar Service with the functions related to the snack bar.
   * @param notificationsService Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   */
  constructor(
    private snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {
    if (!ErrorsService.instance) ErrorsService.instance = this;
  }

  /**
   * Show the HTTP Error Response if it is not ommitable
   * 
   * @param error Error to show
   * @returns If error is ommitable, return. If not, show the snackbar 
   */
  showHttpErrorResponse(error : HttpErrorResponse) {
    if (error && error.status && error.status === 423) {
      this.notificationsService.show(this.translateService.instant('assets_locked'), null, null, 4000, 'error-snack');
    }

    if (this.ommitError(error)) return;

    let data : any = {error:error, action:this.getAction(error)}
    this.showError(data);
  }

  /**
   * Show an internal error if it is not ommitable
   * 
   * @param error Error to show
   * @returns If error is ommitable, return. If not, show the snackbar 
   */
  showInternalError(error) {

    if (this.ommitError(error)) return;

    let data : any = {internal_error:error};
    this.showError(data);
  }

  /**
   * Check if an error is ommitable
   * 
   * @param error Eror to check
   * @returns True if error is ommitable, false in other case.
   */
  private ommitError(error) :boolean {
    if (error.url && error.url.indexOf('/assets/i18n/') > -1) return true;
    if (error.rejection && error.rejection instanceof HttpErrorResponse) return true;
    if (error instanceof HttpErrorResponse && error['statusText'] && error['statusText'] === 'Unknown Error') return true;

    return false;
  }

  /**
   * Show error
   * @param data Data error. 
   */
  private showError(data) {
    if (AppSettings.SHOW_ERRORS) {
      this.snackBar.openFromComponent(ErrorsComponent, {
        data: data,
        duration: this.durationInSeconds * 1000,
      });
    }
  }

  /**
   * Get action
   * @param error Error
   * @returns {string}
   */
  private getAction(error: any) {
    if (error.request) {
      let method = error.request.method.toLowerCase();
      let action = this.utils.getHttpAction(error.request.url);
      return action + "_" + method;
    }
  }

}
