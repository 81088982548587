import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from 'app/shared/services/user/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MomentCustomAdapter } from 'app/shared/utils/MomentCustomAdapter';
import { AppSettings } from 'app/shared/app.settings';
import { Mail } from 'app/shared/model/mail.model';
import { MailService } from 'app/shared/services/mail/mail.service';

import * as CustomEditor from '../../../ckeditor/ckeditor';
import * as moment from 'moment';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { NgxTimepickerFieldComponent } from 'ngx-material-timepicker';
import { ViewChild } from '@angular/core';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import Utils from 'app/shared/utils/utils';
import { RequestProject } from 'app/shared/model/request.model';
import { RequestService } from 'app/shared/services/request/request.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { ProfessionalTeamService } from 'app/shared/services/professionalTeam/professional-team.service';
import * as _ from 'lodash';

/**
 * Constant with the formats of the date
 */
export const DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: { dateInput: 'YYYY-MM-DD', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
};

/**
 * This component defines the view of new project.
 */
@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentCustomAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class NewProjectComponent implements OnInit {

  /**
   * {@link NgxTimepickerFieldComponent} used to control the start time picker.
   */
  @ViewChild('startTime', { static: true }) startTime: NgxTimepickerFieldComponent;

  /**
   * {@link NgxTimepickerFieldComponent} used to control the end time picker.
   */
  @ViewChild('endTime', { static: true }) endTime: NgxTimepickerFieldComponent;

  /**
   * {@link MatSelect} used to control the team select.
   */
  @ViewChild('teamSelect', { static: true }) teamSelect: MatSelect;

  /**
   * Default deadline hour
   */
  private DEFAULT_DEADLINE_HOUR = "23";

  /**
   * Default deadline minute
   */
  private DEFAULT_DEADLINE_MINUTE = "59";

  /**
   * Default deadline second
   */
  private DEFAULT_DEADLINE_SECOND = "59";

  /**
   * {@link AppSettings} imported class
   */
  AppSettings = AppSettings;

  /**
   * {@link CustomEditor} that shows the ckEditor
   */
  public Editor = CustomEditor;

  /**
   * @ignore
   */
  onDrag = false;

  /**
   * Form with the data of the new project
   */
  form: FormGroup;

  /**
   * Default workflow step
   */
  defaultStep = ProjectsService.WF_PROJECT_PLANNED;

  /**
   * The request selected
   */
  request;

  /**
   * {@link User} with the current user that is logged in.
   */
  currentUser;

  /**
   * True if the project is being created
   */
  creatingProject = false;

  /**
   * @ignore
   */
  files = [];

  /**
   * The different values of the project priority
   */
  priorities = [];

  /**
   * Default time used in the start time picker
   */
  defaultTime = '00:00';

  /**
   * The date of today
   */
  today: Date = new Date();

  /**
   * @ignore
   */
  addJob: boolean = false;

  /**
   * Array of {@link Action} that can be added to the project
   */
  jobs = [];

  /**
   * Selectable users for the team
   */
  selectableUsers = [];

  /**
   * True if the user selected is being added to the team
   */
  addingMember: boolean = false;

  /**
   * The selected user to add in the team
   */
  selectedUser;

  /**
   * {@link User} with the data of the owner.
   */
  owner;

  /**
   * Array of {@link Users} with the project managers data
   */
  managers = [];

  /**
   * True if all users are loaded
   */
  teamReady = false;

  isManagerPlus = false;

  /**
   * The constructor
   * @param dialogRef Service with the functions related to the dialogs.
   * @param data loaded from the dialog
   * @param userService Service with the functions related to the users.
   * @param formBuilder Service with the functions related to the forms.
   * @param datepipe Service with the date pipe used to format dates.
   * @param requestService Service with the functions related to the request.
   * @param actionsService Service with the functions related to the actions.
   * @param translateService Service with the functions related to the translations.
   */
  constructor(public dialogRef: MatDialogRef<NewProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private requestService: RequestService,
    public actionsService: ActionsService,
    public translateService: TranslateService,
    public rolesService: RolesService,
    private mailService: MailService,
    ) {

    this.defaultTime = this.datepipe.transform(new Date(), 'HH') + ':' + this.datepipe.transform(new Date(), 'mm');

    this.request = data.request;

    // Find power users and if there isnt, owner_rel will be the owner
    if (this.request && this.request.owner_rel && this.request.owner_rel.length > 0) {
      _.forEach(this.request.owner_rel, (userId) => {
        userService.getUpdatedUser(userId).then(user => {
          if (_.includes(_.first(user.roles), 'power.') || _.isUndefined(this.owner)) {
            if (_.isUndefined(this.owner)) {
              this.owner = user;
            } else if (_.includes(_.first(this.owner.roles), 'power.')) {
              this.managers.push(user);
            } else {
              this.managers.push(this.owner);
              this.owner = user;
            }
          }
        });
      });
    }

    // Get all managers as object, not id
    if (this.request && this.request.manager_rel && this.request.manager_rel.length > 0) {
      this.request.manager_rel.forEach( (manager, key) => {
        userService.getUpdatedUser(manager).then(user => {
          this.managers.push(user);
          this.teamReady = this.request.manager_rel.length - 1 === key ? true : false;
        });
      });
    }

    this.currentUser = this.userService.getCurrentUser();
    this.isManagerPlus = this.rolesService.isManagerPlus(this.currentUser);

    this.priorities = [...ProjectsService.PRIORITIES];


    this.form = this.formBuilder.group({
      'info': [(this.request && this.request.info) ? this.request.info : null, Validators.compose([])],
      'name': [(this.request && this.request.name) ? this.request.name : null, Validators.compose([Validators.required])],
      'priority': [3, Validators.compose([Validators.required])],
      'startDate': [moment.utc(), Validators.compose([])],
      'startDateTime': [null, Validators.compose([])],
      'deadline': [this.request && this.request.deadline ? moment(this.request.deadline) : null, Validators.compose([Validators.required])],
      'deadlineTime': [null, Validators.compose([])],
      'addJob': [false, Validators.compose([])],
      'job': [null, Validators.compose([])],
    });

    if (!this.isManagerPlus) {
      this.selectableUsers = this.userService.getSelectablesUsers();
    } else {
      this.selectableUsers = this.userService.getPartnersId(this.currentUser).map(x => this.userService.getUserData(x));
    }


    actionsService.getActions().then(async data => {
      let over = false;
      this.jobs = [];
      Utils.loadingData = true;
      while (!over) {
        this.jobs.push(...data.result);

        if (data.offset + data.limit < data['total-count']) {
          data = await actionsService.getActions(data.offset + data.limit);
        } else {
          over = true;
          Utils.loadingData = false;
        }
      }
    })
  }

  /**
   * Updates the selected user
   * @param event, the selectionChange event
   */
  updateSelectedMember(event) {
    this.selectedUser = (event && event.value) ? event.value : null;
  }

  /**
   * Adds the selected user as member of the team
   */
  addNewMember() {
    if (this.selectedUser) {
      this.selectedUser.new = true;
      this.managers.push(this.selectedUser);
    }
    if (this.teamSelect) {
      this.teamSelect.value = null;
    }
    this.selectedUser = null;
  }

  /**
   * Removes the manager passed as param
   * @param manager, the manager to be removed
   */
  removeManager(manager) {
    let index = this.managers.findIndex(current => +current.id === +manager.id);
    this.managers.splice(index, 1);
  }

  /**
   * Closes the dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
     * Initialize the component and retrieve the data.
     */
  ngOnInit() { }

  /**
   * Checks if the user is in the managers list
   * @param user, the user to be checked
   * @returns true if the user is in the managers list
   */
  checkManagerId(user) {
    return (this.managers && this.managers.some(current => +current.id === +user));
  }

  /**
   * Saves the data of the new project
   */
  public saveData() {
    if (this.form.valid) {
      this.creatingProject = true;

      let project: RequestProject = new RequestProject();
      project.createdByNew = +this.currentUser.id;
      project.modifiedByNew = +this.currentUser.id;
      project.workflowStep = ProjectsService.WF_PROJECT_PLANNED;

      if (this.form.value.name) {
        project.name = this.form.value.name;
      }

      if (this.form.value.info) {
        project.info = this.form.value.info;
      }

      if (this.form.value.priority) {
        project.priority = this.form.value.priority;
      }

      if (this.owner) {
        project.owner_rel.push(+this.owner.id);
      }

      if (this.managers) {
        this.managers.forEach(manager => {
          if (manager instanceof Object) {
            project.manager_rel.push(+manager.id);
          } else {
            project.manager_rel.push(+manager);
          }
        });
      }

      if (this.form.value.deadline) {
        let deadline = this.form.value.deadline;
        if (!this.form.value.deadlineTime) {
          deadline.hour(this.DEFAULT_DEADLINE_HOUR);
          deadline.minute(this.DEFAULT_DEADLINE_MINUTE);
        } else {
          let deadlineTokens = this.form.value.deadlineTime.split(':');
          deadline.hour(deadlineTokens[0]);
          deadline.minute(deadlineTokens[1]);
        }
        deadline.second(this.DEFAULT_DEADLINE_SECOND);
        project.deadline = this.datepipe.transform(deadline.toDate(), 'yyyy-MM-ddTHH:mm:ss');
      }

      if (this.form.value.startDate) {
        let startDate = this.form.value.startDate;
        if (!this.form.value.startDateTime) {
          startDate.hour(this.DEFAULT_DEADLINE_HOUR);
          startDate.minute(this.DEFAULT_DEADLINE_MINUTE);
        } else {
          let startDateTokens = this.form.value.startDateTime.split(':');
          startDate.hour(startDateTokens[0]);
          startDate.minute(startDateTokens[1]);
        }
        startDate.second(this.DEFAULT_DEADLINE_SECOND);
        project.startDate = this.datepipe.transform(startDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');
      }

      if (this.form.value.job) {
        project.jobs = [+this.form.value.job];
      }

      if (this.request && this.request.id) {
        project.parents = [+this.request.id];
      }

      this.requestService.createRequestProject(project).then(result => {
        if (this.request && this.request.id) {
          this.requestService.changeRequestWorkflow(+this.request.id, +RequestService.CLOSED).then(() => this.finsihProcess());
        } else {
            this.finsihProcess();
        }
        // Power users must receive an email
        let usersMail: any[] = [];
        // Check if there is power user inside owners
        _.includes(_.first(this.owner.roles), 'power.') ? usersMail.push(this.owner) : _.noop();
        // Check if there is power user inside managers
        usersMail = _.union(usersMail, _.filter(this.managers, (user) => _.includes(_.first(user.roles), 'power.')));

        // Send emails to power users
        _.forEach(usersMail, (user) => {
          const mail: Mail = new Mail(
            AppSettings.MAIL_NEW_PROJECT_FROM_REQUEST_POWER_USER + '_' + result.id + '_' + +user.id,
            AppSettings.MAIL_NEW_PROJECT_FROM_REQUEST_POWER_USER,
            +result.id,
            +user.id
          );
          this.mailService.saveMail(mail);
        });

      });

    }
  }

  /**
   * Finishes the save process
   */
  private finsihProcess() {
    this.creatingProject = false;
    this.dialogRef.close(true);
  }

  /**
   * Sets the current time in startDate/deadline input
   * @param start, true if the input to be updated is the startDate input, false to update the deadline input
   */
  public saveCurrentTime(start: boolean = true) {

    let currentHour: number = +this.datepipe.transform(new Date(), 'HH');
    let currentMinutes: number = +this.datepipe.transform(new Date(), 'mm');
    let date: string = (start) ? 'startDate' : 'deadline';

    this.form.controls[date].patchValue(moment.utc());

    if (start) {
      this.startTime.changeHour(currentHour);
      this.startTime.changeMinute(currentMinutes);
    } else {
      this.endTime.changeHour(currentHour);
      this.endTime.changeMinute(currentMinutes);
    }
  }

}
