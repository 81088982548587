import { AppSettings } from './shared/app.settings';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * @ignore
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(translate: TranslateService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {

        iconRegistry.addSvgIcon('svg-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/logos/logo_siku_wiking_svg.svg'));
        iconRegistry.addSvgIcon('actions', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_actions.svg'));
        iconRegistry.addSvgIcon('library', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_library.svg'));
        iconRegistry.addSvgIcon('projects', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_projects.svg'));
        iconRegistry.addSvgIcon('add-circle-outline',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add_circle_outline.svg'));
        iconRegistry.addSvgIcon('add-circle',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add_circle.svg'));
        iconRegistry.addSvgIcon('arrow-right', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/arrow-right.svg'));
        iconRegistry.addSvgIcon('at', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/at.svg'));
        iconRegistry.addSvgIcon('attach', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__attach.svg'));
        iconRegistry.addSvgIcon('badge-ad', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/badge-ad.svg'));
        iconRegistry.addSvgIcon('bag', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/bag.svg'));
        iconRegistry.addSvgIcon('bell', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/bell.svg'));
        iconRegistry.addSvgIcon('book', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/book.svg'));
        iconRegistry.addSvgIcon('box-arrow-in-down-left',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/box-arrow-in-down-left.svg'));
        iconRegistry.addSvgIcon('box-arrow-up-right',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/box-arrow-up-right.svg'));
        iconRegistry.addSvgIcon('box-seam', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/box-seam.svg'));
        iconRegistry.addSvgIcon('briefcase', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/briefcase.svg'));
        iconRegistry.addSvgIcon('brush', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/brush.svg'));
        iconRegistry.addSvgIcon('bullseye', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/bullseye.svg'));
        iconRegistry.addSvgIcon('building', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/building.svg'));
        iconRegistry.addSvgIcon('camera-reels', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/camera-reels.svg'));
        iconRegistry.addSvgIcon('card-text', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/card-text.svg'));
        iconRegistry.addSvgIcon('chat-left-text', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/chat-left-text.svg'));
        iconRegistry.addSvgIcon('chat-left', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/chat-left.svg'));
        iconRegistry.addSvgIcon('chat-square-text', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/chat-square-text.svg'));
        iconRegistry.addSvgIcon('chevron-left', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/chevron-left.svg'));
        iconRegistry.addSvgIcon('chevron-right', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/chevron-right.svg'));
        iconRegistry.addSvgIcon('clipboard-plus', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/clipboard-plus.svg'));
        iconRegistry.addSvgIcon('cloud-arrow-up', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/cloud-arrow-up.svg'));
        iconRegistry.addSvgIcon('cmd', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__cmd.svg'));
        iconRegistry.addSvgIcon('collection', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/collection.svg'));
        iconRegistry.addSvgIcon('dashboard', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__dashboard.svg'));
        iconRegistry.addSvgIcon('download', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/download.svg'));
        iconRegistry.addSvgIcon('display', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/display.svg'));
        iconRegistry.addSvgIcon('eye', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/eye.svg'));
        iconRegistry.addSvgIcon('expand_less', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/expand_less.svg'));
        iconRegistry.addSvgIcon('expand_more', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/expand_more.svg'));
        iconRegistry.addSvgIcon('files', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/files.svg'));
        iconRegistry.addSvgIcon('flower1', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/flower1.svg'));
        iconRegistry.addSvgIcon('folder2', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/folder2.svg'));
        iconRegistry.addSvgIcon('funnel', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/funnel.svg'));
        iconRegistry.addSvgIcon('gallery', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__gallery.svg'));
        iconRegistry.addSvgIcon('globe', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/globe.svg'));
        iconRegistry.addSvgIcon('graph-up', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/graph-up.svg'));
        iconRegistry.addSvgIcon('hand-thumbs-up', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/hand-thumbs-up.svg'));
        iconRegistry.addSvgIcon('house', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/house.svg'));
        iconRegistry.addSvgIcon('house-door', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/house-door.svg'));
        iconRegistry.addSvgIcon('icon-jobs', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__jobs.svg'));
        iconRegistry.addSvgIcon('image', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/image.svg'));
        iconRegistry.addSvgIcon('image-upload', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__image_upload.svg'));
        iconRegistry.addSvgIcon('inbox', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/inbox.svg'));
        iconRegistry.addSvgIcon('info-circle', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/info-circle.svg'));
        iconRegistry.addSvgIcon('journal-check', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/journal-check.svg'));
        iconRegistry.addSvgIcon('link', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/link.svg'));
        iconRegistry.addSvgIcon('link45', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__link.svg'));
        iconRegistry.addSvgIcon('logout', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__logout.svg'));
        iconRegistry.addSvgIcon('mailbox', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/mailbox.svg'));
        iconRegistry.addSvgIcon('map', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/map.svg'));
        iconRegistry.addSvgIcon('newspaper', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/newspaper.svg'));
        iconRegistry.addSvgIcon('paperclip', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/paperclip.svg'));
        iconRegistry.addSvgIcon('pen', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/pen.svg'));
        iconRegistry.addSvgIcon('pencil', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/pencil.svg'));
        iconRegistry.addSvgIcon('person-circle', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/person-circle.svg'));
        iconRegistry.addSvgIcon('phone', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/phone.svg'));
        iconRegistry.addSvgIcon('pie-chart', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/pie-chart.svg'));
        iconRegistry.addSvgIcon('pip', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/pip.svg'));
        iconRegistry.addSvgIcon('plus', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/plus.svg'));
        iconRegistry.addSvgIcon('printer', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/printer.svg'));
        iconRegistry.addSvgIcon('reply', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/reply.svg'));
        iconRegistry.addSvgIcon('question-circle-fill',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/question-circle-fill.svg'));
        iconRegistry.addSvgIcon('save', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__save.svg'));
        iconRegistry.addSvgIcon('schedule', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/schedule.svg'));
        iconRegistry.addSvgIcon('search', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/search.svg'));
        iconRegistry.addSvgIcon('send', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__send.svg'));
        iconRegistry.addSvgIcon('subdirectory_arrow_right',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/subdirectory_arrow_right.svg'));
        iconRegistry.addSvgIcon('star', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/star.svg'));
        iconRegistry.addSvgIcon('three-dots-vertical',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/three-dots-vertical.svg'));
        iconRegistry.addSvgIcon('trash', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/trash.svg'));
        iconRegistry.addSvgIcon('trash-simple', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/trash-simple.svg'));
        iconRegistry.addSvgIcon('type', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/type.svg'));
        iconRegistry.addSvgIcon('ui-radios-grid', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/ui-radios-grid.svg'));
        iconRegistry.addSvgIcon('upload', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/upload.svg'));
        iconRegistry.addSvgIcon('users', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/users.svg'));
        iconRegistry.addSvgIcon('vision', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon__vision.svg'));
        iconRegistry.addSvgIcon('wallet', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/wallet.svg'));
        iconRegistry.addSvgIcon('x', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/x.svg'));
        iconRegistry.addSvgIcon('ms-teams', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/ms-teams.svg'));
        iconRegistry.addSvgIcon('textsms_black', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/textsms_black.svg'));
        iconRegistry.addSvgIcon('hubz-brand', sanitizer.bypassSecurityTrustResourceUrl('assets/images/logos/TheHubz_logo.svg'));
        iconRegistry.addSvgIcon('hubz-brand-neg', sanitizer.bypassSecurityTrustResourceUrl('assets/images/logos/TheHubz_logo_negative.svg'));
        iconRegistry.addSvgIcon('sp-brand', sanitizer.bypassSecurityTrustResourceUrl('assets/images/logos/SP_LOGO_RGB.svg'));

        translate.setDefaultLang(AppSettings.APP_DEFAULT_LANGUAGE);

    }

    preventDefaultEvent(event) {
        event.preventDefault();
    }
}
