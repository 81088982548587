import { Component, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import { Table } from '../tables/table';
import { UserService } from '../services/user/user.service';
import { WorkflowService } from '../services/workflows/workflow.service';
import Utils from 'app/shared/utils/utils';
import { LiquidCacheService } from 'ngx-liquid-cache';
import { Router } from '@angular/router';
import { CommonService } from '../services/common/common.service';
import { FormControl } from '@angular/forms';
import { TrackingService } from '../services/tracking/tracking.service';
import { MatDialog } from '@angular/material/dialog';
import { CompareDialogComponent } from '../dialogs/compare/compare-dialog.component';
import { TranslateService } from '@ngx-translate/core';
/**
 * This component defines the history of a material, showing the information of the different versions.
 */
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent extends Table implements OnInit {
  /**
   * Id of the material being displayed
   */
  @Input() data;
  /**
   * It's always true
   */
  @Input() showMaterialData;
  /**
   * @ignore
   */
  @Input() compareEnabled = false;
  /**
   * @ignore
   */
  @Input() showBulk = true;
  /**
   * @ignore
   */
  @Input() widget = false;
  /**
   * @ignore
   */
  @Input() project = false;
  /**
   * Maximum number of items that can be selected
   */
  private maxSelected = 3;
  /**
   * Id of the material being displayed
   */
  currentData;
  /**
   * @ignore
   */
  position = new FormControl('above');
  /**
   * Columns to show in the list panel.
   */
  columns = ['select', 'preview', 'name', 'version', 'step', 'modified_date', 'modified_by'];
  /**
   * @ignore
   */
  filters = [];
  /**
   * @ignore
   */
  showNewButton: boolean = false;
  /**
   * File types that have multipage
   */
  multipageTypes = ['pdf.', 'presentation.', 'office.', 'layout.', 'spreadsheet.'];
  /**
   * True if have multipage
   */
  isMultipage: boolean = false;
  /**
   * Column by which the list is to be sorted by default
   */
  defaultSortColumn = 'version';
  /**
   * Address by which the list is to be sorted by default, in ascending or descending order
   */
  defaultSortDirection = 'desc';
  /**
   * Array of materials with the different versions
   */
  list;
    /**
   * Array of materials with the preview of the different versions
   */
  previews;
  /**
   * @ignore
   */
  utils = Utils;
  /**
   * True when data is being loaded
   */
  loading = true;
  /**
   * 
   * @param usersService Service with the functions related to the users.
   * @param workflowsService Service with the functions related to the workflows.
   * @param cache Service with the functions related to the cache.
   * @param commonService Service with the common functions.
   * @param router Service with the functions related to the router.
   * @param trackingService Service with the functions related to the translations.
   * @param dialog Service with the functions related to the dialog.
   * @param translateService Service with the functions related to the translations.
   */
  constructor(usersService: UserService, private workflowsService: WorkflowService,
    cache: LiquidCacheService, private commonService: CommonService,
    router: Router,
    protected trackingService: TrackingService,
    public dialog: MatDialog,
    private translateService: TranslateService) {
    super(usersService, cache, router, trackingService);
  }
  /**
   * Initialize the component and retrieve the jobs.
   */
  ngOnInit() {

    this.currentData = this.data;

    if (this.widget) {
      this.defaultSortColumn = 'modified_date';
      this.defaultSortDirection = 'asc';
      this.columns = ['display_message', 'modified_date', 'modified_by'];
    }
    this.commonService.getHistory(this.currentData).subscribe(history => {
    
      if (history.versions) {
        this.list = history.versions;
        this.previews = history.previews;
      }

      this.loading = false;
      this.checkNewData()
      this.changeList()
    });

    if (this.showMaterialData) {
      // this.columns.unshift('preview');
      if( !this.columns.includes('content_version') ) this.columns.push('content_version')
      if( !this.columns.includes('approval') ) this.columns.push('approval')
      
      if (this.currentData.previews) {
        this.previews = this.currentData.previews;
      }
    }

  }
  /**
   * Detects when material has been changed (currentData) and reloads the data.
   * @param changes contains the variables that have changed in the component
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.data && this.currentData !== this.data ){

      this.ngOnInit()

    } 
  }
  /**
   * Decodes the message version of the material from its metadata.
   * @param history Information from one version of the material
   * @param index Index of the version of the material in the list
   */
  decodeDisplayMessage(history, index) {
    let display_message: string = history.display_message;
    history.display_message = '';
    display_message = display_message.substring(0, display_message.length - 1);

    let multipleMessages = display_message.split(';');
    let metadataMessages = multipleMessages.filter(m => m.startsWith('metadata-changed'));
    let otherMessages = multipleMessages.filter(m => !m.startsWith('metadata-changed'));

    if (metadataMessages && metadataMessages.length > 0) {
      let metadataFields = [];

      metadataMessages.forEach(element => {
        let splitMetadata = element.split(',');

        if (splitMetadata && splitMetadata.length >= 2) {
          metadataFields.push(splitMetadata[1]);
        }
      });

      if (this.project) {
        history.display_message += this.translateService.instant('metadata-changed-project');
      } else {
        history.display_message += this.translateService.instant('metadata-changed-job');
      }

      if (metadataFields && metadataFields.length > 0) {
        history.display_message += ' (';
        metadataFields.forEach(field => {
          history.display_message += this.translateService.instant('widget.' + field) + ', ';
        });
        history.display_message = history.display_message.substring(0, history.display_message.length - 2);
        history.display_message += ') / ';
      }
    }

    if (otherMessages && otherMessages.length > 0) {
      otherMessages.forEach(element => {
        if (element.startsWith('status-changed')) {
          if (this.project) {
            history.display_message += this.translateService.instant('status-changed-project');
          } else {
            history.display_message += this.translateService.instant('status-changed-job');
          }

          if (index > 0) {
            if (this.list[index - 1].step && history.step) {
              history.display_message += ' (' + this.list[index - 1].step.name + ' => ' + history.step.name + ') / ';
            }
          }
        } else if (element.startsWith('creation')) {
          if (this.project) {
            history.display_message += this.translateService.instant('creation-project') + ' / ';
          } else {
            history.display_message += this.translateService.instant('creation-job') + ' / ';
          }
        } else if (element.startsWith('task-created') || element.startsWith('material-uploaded')
          || element.startsWith('user-added') || element.startsWith('user-removed') || element.startsWith('file-uploaded')
          || element.startsWith('task-detail-change') || element.startsWith('material-replace') || element.startsWith('file-downloaded')) {
          let splitMessage = element.split(',');

          if (splitMessage && splitMessage.length > 0) {
            let message = splitMessage[0];
            let name = splitMessage[1];
            history.display_message += this.translateService.instant(message) + ' (' + name + ')';

            if ((element.startsWith('file-uploaded') && splitMessage.length >= 4) || (element.startsWith('file-downloaded') && splitMessage.length >= 3)) {
              let folderIndex = element.startsWith('file-downloaded') ? 2 : 3;
              let folderName = splitMessage[folderIndex];
              let translateFolderName = '';

              if (folderName.toLowerCase().includes('customer')) {
                translateFolderName = this.translateService.instant('widget.customer-data');
              } else if (folderName.toLowerCase().includes('agency')) {
                translateFolderName = this.translateService.instant('widget.agency-data');
              } else if (folderName.toLowerCase().includes('final')) {
                translateFolderName = this.translateService.instant('widget.final-data');
              } else if (folderName.toLowerCase().includes('cost')) {
                translateFolderName = this.translateService.instant('widget.cost-estimate');
              } else if (folderName.toLowerCase().includes('consulting')) {
                translateFolderName = this.translateService.instant('widget.consulting');
              }

              history.display_message = history.display_message.substring(0, history.display_message.length - 1);
              let connectionType = element.startsWith('file-downloaded') ? ' from ' : ' in ';
              history.display_message += connectionType + translateFolderName + ')';
            }

            history.display_message += ' / ';
          }
        } else if (element.startsWith('material-status-change')) {
          let splitMessage = element.split(',');

          if (splitMessage && splitMessage.length >= 2) {
            let message = splitMessage[0];
            let name = splitMessage[1];

            history.display_message += this.translateService.instant(message) + ' (' + name + ')';

            if (splitMessage.length >= 6) {
              let prevWf = splitMessage[2];
              let prevStep = splitMessage[3];
              let wf = splitMessage[4];
              let step = splitMessage[5];

              const prevStepO = this.workflowsService.getWorkflowStep(prevWf, prevStep);
              const stepO = this.workflowsService.getWorkflowStep(wf, step);

              history.display_message += ' (' + prevStepO.name + ' => ' + stepO.name + ')';
            }

            history.display_message += history.display_message + ' / ';
          }
        } else if (element.startsWith('startdate-changed') || element.startsWith('enddate-changed')) {
          let splitMessage = element.split(',');
          let message = splitMessage[0];
          let oldDate = splitMessage[1];
          let newDate = splitMessage[2];

          history.display_message += this.translateService.instant(message);
          history.display_message += ' (' + oldDate + ' => ' + newDate + ') / ';
        } else {
          if (element) {
            history.display_message += this.translateService.instant(element) + ' / ';
          }
        }
      });
    }

    history.display_message = history.display_message.substring(0, history.display_message.length - 2);
  }
  /**
   * Fill in the details of the versions of the material
   */
  fillData() {
    if (this.widget) {
      this.list = this.list.filter(x => x.display_message);
    }

    this.list.forEach((history, index) => {
      history.item_name = history.name;
      history.version = +history.version;

      if (history.modified_by && this.usersService.getUserData(history.modified_by)) {
        history.modified_by = this.usersService.getUserData(history.modified_by);
      } else if (!history.modified_by || (history.modified_by && !history.modified_by.initials)) {
        history.modified_by = {
          "initials": "SP",
          "display_name": "Serviceplan"
        };
      }

      if (history.wf_id && history.wf_step) {
        history.step = this.workflowsService.getWorkflowStep(history.wf_id, history.wf_step);
        history.status = this.workflowsService.getStateWithWorkflowStep(history.wf_id, history.wf_step);
        history.approval = this.workflowsService.getWorkflowStatus(history.wf_step, history.type);
      }

      if (this.showMaterialData && this.previews && this.previews.length > 0) {
        if (this.multipageTypes.includes(history.type)) {
          this.isMultipage = true;
          let previews = this.previews.filter(preview => preview.asset_version == history.content_version);
          if (previews && previews.length > 0 && previews[0].page) {
            previews.sort((a, b) => (Number(a.page) > Number(b.page)) ? 1 : -1);
          }
          if (previews) {
            history.preview = previews;
          }
        } else {
          let preview = this.previews.find(preview => preview.asset_version == history.content_version);
          if (preview) {
            history.preview = preview;
          }
        }
      }

      if (this.widget) {
        this.decodeDisplayMessage(history, index);
      }

    });
  }
  /**
   * Prevents selection of an item when the maximum number of selectable items has been exceeded.
   * @param $event event change the checkbox
   * @param item A version of the list
   */
  checkMaxSelected($event, item) {
    if (this.maxSelected && this.list.filter(x => x.checked).length > this.maxSelected) {
      $event.source.checked = false;
      item.checked = false;
    }
  }
  /**
   * Opens a dialogue with the component {@link CompareDialogComponent}
   * @param id id of the version of material
   * @param version number of version of material
   */
  openZoomDialog(id, version) {
    const compareDialog = this.dialog.open(CompareDialogComponent, {
      data: { histories: this.list.filter(x => x.id == id && x.version == version) },
      panelClass: 'preview'
    });
  }

}
