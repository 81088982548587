import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation/confirmation-dialog.component';
import { environment } from 'environments/environment';

/**
 * This component defines the view of the dialog info component.
 */
@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  /**
   * Url
   */
  readonly URL: string = environment.baseOCUrl + "downloadFile/";
  /**
   * Material selected
   */
  dataSelected = false;
  /**
   * Url completed
   */
  completeURL = '';

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data Data required for the component. 
   */
  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  /**
   * Gets the ids of the materials and completes the url
   */
  ngOnInit() {
    if (this.data && this.data.length > 0) {
      this.dataSelected = true;

      let materialIds = this.getMaterialIds(this.data);
      this.completeURL = this.URL + materialIds;
    }
  }

  /**
   * Close to dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Get materials Ids
   * @param data Materials array
   * @returns Materials ids
   */
  getMaterialIds(data) {
    let materialIds = '';

    for (let i = 0; i < data.length; i++) {
      materialIds = materialIds + data[i].id + ';'
    }

    materialIds = materialIds.substring(0, materialIds.length - 1);

    return materialIds;
  }

}
