/**
 * This class defines the filters structure
 */
export class Filter {
    
    /**
     * name of filter
     */
    name : string = '';
    /**
     * type of filter
     */
    type : string = '';
    /**
     * all values that the filter can have
     */
    values = [];
    /**
     * values to be displayed from the filter
     */
    displayValues = [];
    /**
     * current filter value
     */
    value = null;
    /**
     * width of filter
     */
    width : number = 33;

    /**
     * indicates if this filter is to be displayed
     */
    skip : boolean = false;

    /**
     * key of filter
     */
    key : string = '';
    /**
     * @ignore
     */
    removeIfEmpty : boolean = false;
    /**
     * minimum filter value
     */
    valueMin;
    /**
     * 
     * maximum  filter value
     */
    valueMax;

    /**
     * default value
     */
    defaultValue;

    /**
     * The constructor
     * @param name name of filter
     * @param type type of filter 
     * @param skip skip of filter 
     * @param key key of filter 
     * @param removeIfEmpty @ignore 
     */
    constructor(name, type, skip = false, key = '', removeIfEmpty = false, defaultValue = null) {

        this.name = name;
        this.type = type;

        this.skip = skip;
	    if (key) {
            this.key = key;
        } else {
            this.key = name;
        }
        if(this.type === 'range'){
            this.value = new FilterRange();
        }
        this.removeIfEmpty = removeIfEmpty;

        this.defaultValue = defaultValue;

    }
}

/**
 * Filter range useful for dates
 */
export class FilterRange {

    /**
     * value from which the filter can start
     */
    from:any =  null;
    /**
     * maximum value that the filter can reach
     */
    to:any =  null;
        
}