import { Injectable } from '@angular/core';
import { LiquidCacheService } from 'ngx-liquid-cache';
import { RecentlyViewedDetail, RecentlyViewed } from './recently-viewed';
import { User } from 'app/shared/model/user.model';
import { UserService } from '../user/user.service';

/**
 * Service defined to manage the recently viewed.
 */
@Injectable({
  providedIn: 'root'
})
export class RecentlyViewedService {
  /**
   * Recently viewed key.
   */
  private static readonly RECENTLY_VIEWED_KEY: string = 'recenttly_view';
  /**
   * Recently viewed limit.
   */
  private static readonly RECENTLY_VIEWED_LIMIT: number = 3;

  /**
   * Constructor.
   * @param cache  Service with the functions related to the cache.
   * @param userService Service with the functions related to the users.
   */
  constructor(
    protected cache: LiquidCacheService,
    protected userService: UserService
  ) { }

  /**
   * Get list
   * @param key Key 
   * @returns 
   */
  getList(key) {
    const user = this.userService.getCurrentUser();
    let details = this.cache.get(RecentlyViewedService.RECENTLY_VIEWED_KEY + '_' + user.id);
    if (details) {
      return details[key];
    }
  }

  /**
   * Sve element
   * @param element Element to be saved. 
   * @param key Key
   */
  saveElement(element, key) {
    const user = this.userService.getCurrentUser();
    let detail = this.createDetail(element);
    let details = this.cache.get(RecentlyViewedService.RECENTLY_VIEWED_KEY + '_' + user.id);
    if (!details) {
      details = new RecentlyViewed();
    }

    let position = details[key].map(x => x.id).indexOf(detail.id);
    if (position == - 1) {
      details[key].push(detail);
    }

    if (details[key].length > RecentlyViewedService.RECENTLY_VIEWED_LIMIT) {
      details[key].shift();
    }

    this.cache.set(RecentlyViewedService.RECENTLY_VIEWED_KEY + '_' + user.id, details)

  }

  /**
   * Create detail
   * @param element Element 
   * @returns 
   */
  createDetail(element) {
    let data: RecentlyViewedDetail = new RecentlyViewedDetail();
    data.id = element.id;
    data.type = (element.type != null)?element.type.replace('.', ''):'';
    data.title = element.name;
    data.rp_name = (element.ownerUser && element.ownerUser.display_name) ? element.ownerUser.display_name : '';
    data.rp_image = (element.ownerUser && element.ownerUser.mainPicture && element.ownerUser.mainPicture.image) ? element.ownerUser.mainPicture.image : '';
    data.rp_initials = (element.ownerUser && element.ownerUser.initials) ? element.ownerUser.initials : '';
    data.rp_id = (element.ownerUser) ? element.ownerUser.id : 0;

    switch (element.type) {
      case "project.": {
        data.link = '/projects/detail/' + element.id;
        break;
      }
      case "order.": {
        data.link = '/jobs/detail/' + element.id;
        break;
      }
      case "task.": {
        data.link = '/tasks/detail/' + element.id;
        break;
      }
      default: {
        break;
      }
    }
    return data;
  }

}
