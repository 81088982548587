/**
 * This class defines the New User info required structure
 */
export class NewUser {
    /**
     * name of New User
     */
    name: string;
    /**
     * first name of New User
     */
    firstName: string;
    /**
     * last name of New User
     */
    lastName: string;
    /**
     * domain of New User
     */
    domain: string;
    /**
     * domain 2 of New User
     */
    domain2: string;
    /**
     * language of New User
     */
    language: string;
    /**
     * workflow of New User
     */
    workflow: number;
    /**
     * workflow step of New User
     */
    workflowStep: number;
    /**
     * email of New User
     */
    email: string;
    /**
     * roles of New User
     */
    roles = [];
    /**
     * auth of New User
     */
    auth: Auth;
    /**
     * {@link EmailStruct} of New User
     */
    emailStruct: EmailStruct;
    /**
     * Indicates whether the activation message has been sent.
     */
    activatedEmailSent : boolean = true;
}

/**
 * @ignore
 * Defines the info of auth
 */
export class Auth {
    type: string = 'website';
    login: string
}

/**
 * Defines the info of email
 */
export class EmailStruct {
    /**
     * type of EmailStruct
     */
    type: string = 'work';
    /**
     * email of EmailStruct
     */
    email: string;
}