import { Asset } from "./asset.model";

/**
 * This class defines the user (logged) structure
 */
export class User extends Asset {

    /**
     * first name of User
     */
    firstName: string;
    /**
     * last name of User
     */
    lastName: string;
    /**
     * gender of User
     */
    gender: string;
    /**
     * email of User
     */
    email: string;
    /**
     * @ignore
     */
    party: string;
    /**
     * initials of User
     */
    initials: string;
    /**
     * language of User
     */
    language: string;
    /**
     * array of watchlist ids in which the user is located
     */
    watchlist: Array<string> = [];
    /**
     * roles of User
     */
    roles: string[] = [];
    /**
     * collection of User
     */
    collection: number;

    /**
     * {@link UserPicture} of User
     */
    pictures: UserPicture[] = [];
    /**
     * {@link UserPicture} of User
     */
    mainPicture: UserPicture;

    /**
     * User display name
     */
    display_name: string;

    /**
     * check mark to delete the image of the user with that id
     */
    removeMainPicture: number;
}

/**
 * Defines the info for User picture
 */
export class UserPicture {

    /**
     * id of UserPicture
     */
    id: number;
    /**
     * name of UserPicture
     */
    name: string;
    /**
     * type of UserPicture
     */
    type: string;
    /**
     * domain of UserPicture
     */
    domain: string;
    /**
     * download link of UserPicture
     */
    downloadLink: string;
    /**
     * Array with the coindicates whether it is necessary to reload the preview. of UserPicture
     */
    reloadPreview: boolean = false;
    /**
     * version of UserPicture
     */
    version: number;
    /**
     * image of UserPicture
     */
    image: string;

}