import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Asset } from 'app/shared/model/asset.model';
import { CommonService } from 'app/shared/services/common/common.service';
import { UserService } from 'app/shared/services/user/user.service';
import Utils from 'app/shared/utils/utils';

@Component({
  selector: 'app-connect-assets',
  templateUrl: './connect-assets.component.html',
  styleUrls: ['./connect-assets.component.scss']
})
export class ConnectAssetsComponent implements OnInit {

  connectionControl = new FormControl();
  filteredOptions: Observable<Asset[]>;

  /**
   * Asset
   */
  asset;
  /**
   * Asset type
   */
  type;
  /**
   * Actions/Jobs list
   */
  list = [];
  /**
   * If true, show error
   */
  showError = false;
  /**
   * If true, you are adding asset to the parent
   */
  addingParent = false;


  /**
   * Constructor
   * @param data Object with asset and type asset.
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param commonService Service with the common functions.
   * @param actionsService Service with the functions related to the actions.
   * @param projectsService Service with the functions related to the projects.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConnectAssetsComponent>,
    private commonService:CommonService,
    private actionsService:ActionsService,
    private projectsService:ProjectsService) {

    if (data && data.asset) {
      this.asset = data.asset;
    }

    if (data && data.type) {
      this.type = data.type;
      this.loadList();
    }
  }

  /**
   * Add asset to the parent
   */
  public saveData() {
    this.showError = false;
    if (this.connectionControl.value && this.connectionControl.value.id) {
      this.addingParent = true;
      this.commonService.addParent(this.asset, this.connectionControl.value).then(result => {
        this.addingParent = false;
        this.dialogRef.close();
      });
    } else {
      this.showError = true;
    }
  }

  ngOnInit() {
    this.filteredOptions = this.connectionControl.valueChanges
      .pipe(
        startWith<string | Asset>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterList(name) : this.list.slice())
      );
  }

  /**
   * Filters the list items matching the name
   * @param {string} name Name by which the list is filtered
   * @returns Array of assets
   */
  private filterList(name: string): Asset[] {
    return this.list.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) > -1);
  }

  /**
   * Display value
   * @param {Asset} user User
   * @returns If it receives the user it returns his name, otherwise undefined
   */
  displayValue(user?: Asset): string | undefined {
    return user ? user.name : undefined;
  }

  /**
   * Get actions
   */
  private getActions() {
    this.actionsService.getActions().then(async data => {
      if (data && data.result) {
        let over = false;
        this.list = [];
        Utils.loadingData = true;
        while (!over) {
          this.list.push(...data.result);

          if (data.offset + data.limit < data['total-count']) {
            data = await this.actionsService.getActions(data.offset + data.limit);
          } else {
            over = true;
            Utils.loadingData = false;
          }
        }
      }
    });
  }

  /**
   * Get dam actions
   */
  private getDamActions() {
    this.actionsService.getDamActions().then(async data => {
      if (data && data.result) {
        let over = false;
        this.list = [];
        Utils.loadingData = true;
        while (!over) {
          this.list.push(...data.result);

          if (data.offset + data.limit < data['total-count']) {
            data = await this.actionsService.getDamActions(data.offset + data.limit);
          } else {
            over = true;
            Utils.loadingData = false;
          }
        }
      }
    });
  }

  /**
   * Gete projects
   */
  private getProjects() {
    this.projectsService.getProjects().then(async data => {
      if (data && data.result) {
        let over = false;
        this.list = [];
        Utils.loadingData = true;
        while (!over) {
          this.list.push(...data.result);

          if (data.offset + data.limit < data['total-count']) {
            data = await this.projectsService.getProjects(data.offset + data.limit);
          } else {
            over = true;
            Utils.loadingData = false;
          }
        }
      }
    });
  }

  /**
   * @ignore
   */
  private getCampaigns() {}

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Loads the list according to asset type
   */
  private loadList() {
    switch (this.type){
      case 'material': {
        this.getActions(); break;
      }
      case 'action': {
        this.getProjects(); break;
      }
      case 'project': {
        this.getCampaigns(); break;
      }
      case 'dam': {
        this.getDamActions(); break;
      }
    }
  }

}
