import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Filter } from 'app/shared/model/filter.model';
import { UserService } from 'app/shared/services/user/user.service';
import { LiquidCacheService } from 'ngx-liquid-cache';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { RolesService } from 'app/shared/services/user/roles.service';
import { EditRoleDialogComponent } from 'app/shared/components/team/edit-role/edit-role-dialog.component';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';
import { Table } from 'app/shared/tables/table';
import { TrackingService } from 'app/shared/services/tracking/tracking.service';
import { Mail } from 'app/shared/model/mail.model';
import { AppSettings } from 'app/shared/app.settings';
import { MailService } from 'app/shared/services/mail/mail.service';

/**
 * @ignore
 */
@Component({
  selector: 'app-team-tab',
  templateUrl: './team-tab.component.html',
  styleUrls: ['./team-tab.component.scss']
})
export class TeamTabComponent implements OnInit {

  @ViewChild('quickTeam', { static: false }) private quickTeam;

  @Input() assetId = null;
  @Output() updatedTeam = new EventEmitter<any>();

  teamSize;
  team;

  list = [];

  currentUser;
  isManager = false;

  addMember = {
    editing: false,
    step: 1,
    type: 1,
    person: -1,
    role: '',
    next: false,
    update: false
  }

  constructor(private usersService: UserService,
    cache: LiquidCacheService,
    router: Router,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private rolesService: RolesService,
    protected trackingService: TrackingService) {
  }

  ngOnInit() {
    this.currentUser = this.usersService.getCurrentUser();
    this.isManager = this.rolesService.checkRole(this.currentUser, RolesService.PROJECT_MANAGER_ROLE, true);
    this.usersService.getTeam(this.assetId).then(result => {
      this.refreshTeam(result);
    });
  }

  refreshTeam(team) {

    this.team = team;
    this.addMember.update = false;
    this.list = [];
    this.team.teams = this.usersService.getTeams(team);
    Array.from(this.team.teams.keys()).forEach(team => {

      let users = this.team.teams.get(team);
      users.forEach(user => {

        let newUser = Object.assign({}, user);
        newUser.role = this.translateService.instant('role.' + team);
        newUser.key = team;
        newUser.item_name = user.name;
        this.list.push(newUser);

        if (team === UserService.OWNER) this.team.owner = newUser;
        if (team === UserService.PROJECT_MANAGER) this.team.pm = newUser;

      });
    });
  }

  openChangeRole (item) {

    let dialogRef = this.dialog.open(EditRoleDialogComponent, {
      width: '500px',
      data: {
        user : item,
        team: this.team,
        assetId: this.assetId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updatedTeam.emit(result);
        this.updatedTeamEvent(result);
      }
      dialogRef = null;
    });
  }

  openConfirmationRemoveDialog(item) {

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'removeMember'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        item.remove = true;
        this.usersService.removeUserFromTeam(this.team, item.role, item.id).then(result => {
          this.updatedTeam.emit(result.plain());
          this.updatedTeamEvent(result.plain());
        });
      }
      dialogRef = null;
    });
  }

  canEdit () {
    return this.isManager && (this.usersService.canEditTeam(this.currentUser, this.team) || !this.team.pm);
  }

  hasRole (item) {
    return this.usersService.hasRole(item.key);
  }

  updatedTeamEvent (result){
    this.team = result;
    if (this.quickTeam) this.quickTeam.refreshTeam(result);
    this.refreshTeam(result);
  }
}
