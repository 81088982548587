import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { MomentCustomAdapter } from 'app/shared/utils/MomentCustomAdapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TasksService } from 'app/shared/services/tasks/tasks.service';
import { UserService } from 'app/shared/services/user/user.service';
import { AppSettings } from 'app/shared/app.settings';

import * as CustomEditor from '../../../ckeditor/ckeditor';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { NgxTimepickerFieldComponent } from 'ngx-material-timepicker';
import { Task } from 'app/shared/model/task.model';
import { NotificationsService } from 'app/shared/services/notifcations/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { MaterialsService } from 'app/shared/services/materials/materials.service';
import { MailService } from 'app/shared/services/mail/mail.service';
import { Mail } from 'app/shared/model/mail.model';

/**
 * Constant with the formats of the date
 */
export const DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: {dateInput: 'YYYY-MM-DD', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
};

/**
 * This component defines the creation of new task of a request {@link Request}
 */
@Component({
  selector: 'app-new-request-task',
  templateUrl: './new-request-task.component.html',
  styleUrls: ['./new-request-task.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentCustomAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
  ]
})
export class NewRequestTaskComponent implements OnInit {
  /**
   *  {@link NgxTimepickerFieldComponent} used to control the start time picker.  */
  @ViewChild('startTime', { static: true }) startTime: NgxTimepickerFieldComponent;
  /** 
   * {@link NgxTimepickerFieldComponent} used to control the end time picker. 
   */
  @ViewChild('endTime', { static: true }) endTime: NgxTimepickerFieldComponent;
  /**
   * {@link MatSelect} used to control the team select.
   */
  @ViewChild('teamSelect', { static: true }) teamSelect: MatSelect;

  AppSettings = AppSettings;
  /** 
   * {@link CustomEditor} that shows the ckEditor
   */
  public Editor = CustomEditor;
  /**
   * {@link Request} the request type project for which the task is to be created
   */
  request;
  /**
   * Array with all available task types
   */
  taskTypes = TasksService.REQUEST_TASK_TYPES;
  /** 
   * {@link User} with the current user that is logged in.
   */
  currentUser;
   /**
   * Selectable users for the team
   */
  selectableUsers = [];
  /**
   * True if the user selected is being added to the team
   */
  addingMember: boolean = false;
  /**
   * The selected user to add in the team
   */
  selectedUser;
   /**
   * {@link User} with the data of the owner.
   */
  owner;
  /**
   * {@link User} with the id of the request owner.
   */
  requestOwnerId;
  /**
   * {@link User} with the id of the request owner.
   */
  requestOwner;
  /**
   * Array of {@link Users} with the project managers data
   */
  managers = [];
  /**
   * Form with the data of the new task
   */
  form: FormGroup;
  /**
   * True if is creating a task
   */
  creatingTask = false;
  /** 
   * Array of files belonging to the request
  */
  files = [];
  /** 
   * Array of materials to be attached to the new task
  */
  selectedMaterials = [];
  /**
   * If the task type allows upload a file.
   */
  canUploadFile : boolean = false;
  /**
   * @ignore
   */
  onDrag = false;
  /**
   * The target of task materials
   */
  uploadTarget = [];
  /**
   *  Array with the priorities that a briefing can have 
   */
  priorities = [];
  /**
   * @ignore
   */
  defaultTime = '00:00';
  /** 
   * Current date
   */
  today: Date = new Date();
  /**
   * Default workflow step
   */
  defaultStep = TasksService.TASK_WORKFLOW_PLANNED;
  /**
   * Open workflow step
   */
  openStep = TasksService.TASK_WORKFLOW_OPEN;

  /** the default start hour  */
  private DEFAULT_STARTDATE_HOUR = "00";

    /** the default start minute  */
  private DEFAULT_STARTDATE_MINUTE = "00";

    /** the default start second  */
  private DEFAULT_STARTDATE_SECOND = "00";

  /** the default deadline hour  */
  private DEFAULT_DEADLINE_HOUR = '23';

  /** the default deadline minute  */
  private DEFAULT_DEADLINE_MINUTE = '59';

  /** the default deadline second  */
  private DEFAULT_DEADLINE_SECOND = '59';

  loaded = false;
  
  /**
   * 
   * @param dialogRef Service with the functions related to the dialogs.
   * @param data loaded from the dialog
   * @param formBuilder Service with the functions related to the forms.
   * @param userService Service with the functions related to the users.
   * @param datepipe Service with the date pipe used to format dates.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   * @param tasksService Service with the functions related to the tasks
   * @param materialsService Service with the functions related to the materials.
   * @param mailService Service with the functions related to the mails.
   */
  constructor(
    public dialogRef: MatDialogRef<NewRequestTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder : FormBuilder,
    private userService : UserService,
    private datepipe: DatePipe,
    private notifications : NotificationsService,
    private translateService : TranslateService,
    private tasksService : TasksService,
    private materialsService: MaterialsService,
    private mailService: MailService,
    ) {

    this.request = data.request;
    this.currentUser = this.userService.getCurrentUser();
    this.owner = this.currentUser;

    if (this.request && this.request.owner_rel && this.request.owner_rel.length > 0 ){
      this.requestOwnerId = this.request.owner_rel[0];
      userService.getUpdatedUser(this.request.owner_rel[0]).then(user => {
        this.requestOwner = user;
      });
    }


    if (this.request && this.request.manager_rel && this.request.manager_rel.length > 0 ){
      this.request.manager_rel.forEach(manager => {
        if (manager != +this.owner.id) {
          this.managers.push(manager);
        }
      });
    }

    this.form = this.formBuilder.group({
      'name': [(this.request && this.request.name)?this.request.name:null, Validators.compose([Validators.required])],
      'tasktype': [null, Validators.compose([Validators.required])],
      'info': [null, Validators.compose([])],
      'priority': [3, Validators.compose([Validators.required])],
      'startDate': [moment.utc(), Validators.compose([])],
      'startDateTime': [null, Validators.compose([])],
      'deadline': [null, Validators.compose([Validators.required])],
      'deadlineTime': [null, Validators.compose([])],
      'files': [null, Validators.compose([])],
    });

    this.selectableUsers = this.userService.getSelectablesUsers();
    this.priorities = [...TasksService.PRIORITIES];
    this.selectableUsers = this.selectableUsers.filter(user => user.id != this.owner.id && (user.id == +this.requestOwnerId || this.managers.includes(user.id)));
  }
  /**
   * @ignore
   */
  ngOnInit() {}

  ngAfterViewInit() {
   

    setTimeout(() => {
      this.loaded = true;
    }, 200 );
    
  }

  /**
   * Closes the dialog.
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Creates a new task with the data from the {@link form}.
   * 
   * @param {number} step Workflow step for a task.
   */
  async saveData(step: number) {
    if (this.form.valid) {

      this.creatingTask = true;

      await this.materialsService.createNewMaterialsInTask(this.files, this.selectedMaterials, this.request, this.uploadTarget);

      let task: Task = new Task();
      if (this.form.value.tasktype) task.type = this.form.value.tasktype;
      if (this.form.value.name) task.name = this.form.value.name;
      if (this.form.value.priority) task.priority = +this.form.value.priority;


      if (this.form.value.info) {
        let comment = {
          'userAsset': +this.currentUser.id,
          'timestamp': Math.round(Date.now() / 1000) * 1000,
          'entry': this.form.value.info,
        };

        task.communication = [comment];
      }

      if (this.form.value.startDate) {
        let startDate = this.form.value.startDate;
        if (!this.form.value.startDateTime) {
          startDate.hour(this.DEFAULT_STARTDATE_HOUR);
          startDate.minute(this.DEFAULT_STARTDATE_MINUTE);
        } else {
          let startDateTokens = this.form.value.startDateTime.split(':');
          startDate.hour(startDateTokens[0]);
          startDate.minute(startDateTokens[1]);
        }

        startDate.second(this.DEFAULT_STARTDATE_SECOND);
        task.startDate = this.datepipe.transform(startDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');
      }

      if (this.form.value.deadline) {
        let deadline = this.form.value.deadline;
        if (!this.form.value.deadlineTime) {
          deadline.hour(this.DEFAULT_DEADLINE_HOUR);
          deadline.minute(this.DEFAULT_DEADLINE_MINUTE);
        } else {
          let deadlineTokens = this.form.value.deadlineTime.split(':');
          deadline.hour(deadlineTokens[0]);
          deadline.minute(deadlineTokens[1]);
        }
        deadline.second(this.DEFAULT_DEADLINE_SECOND);
        task.deadline = this.datepipe.transform(deadline.toDate(), 'yyyy-MM-ddTHH:mm:ss');
      }

      if (this.form.value.startDate && this.form.value.deadline) {
        let startDate = this.form.value.startDate;
        let deadline = this.form.value.deadline;

        if (deadline < startDate) {
          this.notifications.show(this.translateService.instant('invalid_date_range'), null, null, 5000, 'error-snack');
          this.creatingTask = false;
          return '';
        }
      }

      if(this.owner){
        task.owner_rel = [+this.owner.id];
      }

      if (this.selectedMaterials) {
        task.files = Array.from(new Set(this.selectedMaterials));
      }

      task.workflowStep = step;
      task.parents = [+this.request.id];
      task.jobs = [this.request];
      task.request_task = true;

      let tasks = [];
      task.targets = [];
      if (this.managers && this.managers.length > 0) {
        this.managers.forEach(manager=> {
          let targets = [];
          targets.push(manager instanceof Object? +manager.id : +manager);
          task.targets = Array.from(new Set(targets));
          task['responsible'] = task.targets[0];
          tasks.push(Object.assign(new Task, task));
        });
      } else {
        tasks.push(task);
      }

      for(let index=0; index<tasks.length; index++) {
        await this.tasksService.newTask(tasks[index]).then(result => {
          if (index === (tasks.length - 1)) {
            this.finsihProcess(result);
          }
        });
      }
    }
  }

  /**
   * End the process of creating a task by closing the dialog and changing the creatingTask flag.
   * 
   * @param {Task} result Object resulting from the method {@link saveData}.
   */
  private finsihProcess(result: Task) {
    this.creatingTask = false;
    this.dialogRef.close(result);
  }

  /**
   * Updates the selected member.
   * 
   * @param event 
   */
  updateSelectedMember(event){
    this.selectedUser = (event && event.value)?event.value:null;
  }

  /**
   *  Add new member.
   */
  addNewMember() {
    if (this.selectedUser){
      this.selectedUser.new = true;
      this.managers.push(this.selectedUser);
    }
    if (this.teamSelect){
      this.teamSelect.value = null;
    }
    this.selectedUser = null;
  }

  /**
   * Removes a manager from the managers array {@link managers}
   * 
   * @param {object} manager User to delete
   */
  removeManager(manager){
    let index = this.managers.findIndex(current => manager instanceof Object? +current.id === +manager.id : +current === +manager);
    this.managers.splice(index, 1);
  }

  /**
   * Checks if the user is a manager 
   * 
   * @param {object} user User to check if is a manager.
   * @returns {boolean} true | false if the user is manager.
   */
  checkManagerId(user){
    return (this.managers && this.managers.some(current => (current instanceof Object? +current.id === +user : +current === +user)));
  }

  /**
   * Sets and saves the current date.
   * 
   * @param {boolean} start  If true, sets the start date, if false, the dead line.
   */
  public saveCurrentTime(start: boolean = true) {

    let currentHour: number = +this.datepipe.transform(new Date(), 'HH');
    let currentMinutes: number = +this.datepipe.transform(new Date(), 'mm');
    let date: string = (start) ? 'startDate' : 'deadline';

    this.form.controls[date].patchValue(moment.utc());

    if (start){
      this.startTime.changeHour(currentHour);
      this.startTime.changeMinute(currentMinutes);
    } else {
      this.endTime.changeHour(currentHour);
      this.endTime.changeMinute(currentMinutes);
    }
  }

  /**
   * Assign the type of task.
   */
  public changeTask() {
    if (this.form.value.tasktype) {
      let type = this.taskTypes.find(x => x.key === this.form.value.tasktype);
      if (type) {
        this.form.patchValue({ taskname: type.name });
        this.canUploadFile = type.newFile;
        this.uploadTarget = type.materialsTarget;
      }
    } else {
      this.canUploadFile = false;
      this.uploadTarget = [];
    }
  }
  
  /**
   * Show a file
   * 
   * @param event 
   */
  public showFile(event) {
    event.preventDefault();

    if (event.dataTransfer != null && event.dataTransfer.files != null && event.dataTransfer.files.length > 0) {

      Array.from(event.dataTransfer.files).forEach(file => this.files.push(file));
    } else if(event.srcElement != null && event.srcElement.files != null && event.srcElement.files.length > 0) {
      Array.from(event.srcElement.files).forEach(file => this.files.push(file));
      event.target.value = '';
    }
  }
  
  /**
   * Delete a file
   * 
   * @param file file to delete.
   */
  public deleteFile(file) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  /**
   * Prevents the default behavior of an event
   * 
   * @param event 
   */
  preventDefaultEvent(event){
    event.preventDefault();
  }
}
