import { AppSettings } from "../app.settings";
import { environment } from "environments/environment";
import * as moment from 'moment';

/**
 * This class defines useful common methods and attributes
 */
export default class Utils {

  
  static showTermsOfUse: boolean = false;

  /**
   * True if the iframe is opened
   */
  static iframeOpened: boolean = false;

  /**
   * @ignore
   */
  static resetTab: boolean = false;

  /**
   * True if the component is being loaded
   */
  static loadingData: boolean = false;

  /**
   * True if the status has been updated
   */
  static updateStatus: boolean = false;

  /**
   * @ignore
   */
  static updatingWidget: boolean = false;

  /**
   * The type of the accordion that launches the event
   */
  static accordionType: string = '';

  // -1: None, -2: Project list, -3: Actions list, -4: Current User data, -5: Tasks list
  /**
   * Constant to stop any reload
   */
  static readonly RELOAD_DATA_NONE: number = -1;

  /**
   * Constant to reload the project list
   */
  static readonly RELOAD_DATA_PROJECTS_LIST: number = -2;

  /**
   * Constant to reload the jobs list
   */
  static readonly RELOAD_DATA_ACTIONS_LIST: number = -3;

  /**
   * Constant to reload the data of the current user
   */
  static readonly RELOAD_DATA_CURRENT_USER_DATA: number = -4;

  /**
   * Constant to reload the tasks list
   */
  static readonly RELOAD_DATA_TASKS_LIST: number = -5;

  /**
   * Constant to reload the notifications
   */
  static readonly RELOAD_DATA_NOTIFICATIONS: number = -6;

  /**
   * Constant to reload the infocenter widget
   */
  static readonly RELOAD_DATA_INFOCENTER_WIDGET: number = -7;

  /**
   * Constant to reload the materials list
   */
  static readonly RELOAD_DATA_MATERIALS_LIST: number = -8;

  /**
   * Constant to reload the task widget
   */
  static readonly RELOAD_DATA_TASK_WIDGET: number = -9;

  /**
   * Constant to reload the budget widget
   */
  static readonly RELOAD_DATA_BUDGET_WIDGET: number = -10;
  
  /**
   * Constant to reload the files widget
   */
  static readonly RELOAD_DATA_FILE_WIDGET: number = -11;
  /**
   * Constant to reload the budget widget
   */
  static readonly RELOAD_DATA_ALL_BUDGETS_WIDGET: number = -12;

  /**
   * Constant with the umlaut character
   */
  static readonly UMLAUT_CHAR: string = "%u0308";

  /**
   * Constant with the escaped characters and its html code
   */
  static readonly ESCAPED_CHARACTERS: Map<string, string> =
    new Map([
      ["a%u0308", "ä"],
      ["e%u0308", "ë"],
      ["i%u0308", "ï"],
      ["o%u0308", "ö"],
      ["u%u0308", "ü"]
    ]);

  /**
   * Attribute with the value of the component to reload
   */
  static reloadData: number = Utils.RELOAD_DATA_NONE;

  /**
   * Attribute used to decode text
   */
  static he = require('he');

  /**
   * Number of the navigation menu selected
   */
  static selectNavigation: number = 0;

  /**
   * Navigation element retrieved from {@link AppSettings} and related to the selected navigation number
   */
  static navigationList = AppSettings.SIDEBAR_NAVLIST_CONFIG[Utils.selectNavigation];

  /**
   * Updates the navigation number and the navigation element
   * @param nav The new index if the navigation element
   */
  static changeNav(nav) {
    Utils.selectNavigation = nav;
    Utils.navigationList = AppSettings.SIDEBAR_NAVLIST_CONFIG[Utils.selectNavigation];
  }

  /**
   * Gets the extension of a file's name
   * @param filename The name of the file
   * @returns The extension of the file's name
   */
  static getFileExtension(filename) {
    return '.' + filename.split('.').pop();
  }

  /**
   * Gets the name of a file without the extension. Includes the '.'
   * @param filename The name of the file
   * @returns The name of the file without the extension
   */
  static getFilenameWithoutExtension(filename) {
    return filename.split('.').slice(0, -1).join('.');
  }

  /**
   * Gets the time in millis for a date passed as param
   * @param date The date
   * @returns The time in milliseconds
   */
  static getMillis(date): number {
    return new Date(date).getTime();
  }

  /**
   * Gets the time in millis for a date object passed as param
   * @param date The date object
   * @returns The time in milliseconds
   */
  static getMillisDate(date: Date): number {
    return date.getTime();
  }

  /**
   * Gets the http action using the url passed as param
   * @param url The url
   * @returns The new url
   */
  static getHttpAction(url: string) {

    if (url.indexOf(environment.baseHCSMUrl) > -1) {
      url = url.replace(environment.baseHCSMUrl + 'entity/', '');
      let nodes = url.split('/');
      if (nodes.length >= 1) {
        url = nodes[0];
      }
    }

    if (url.indexOf(environment.baseOCUrl) > -1) {
      url = url.replace(environment.baseOCUrl, '');
    }

    return url;
  }

  /**
   * Gets the entity to use in a team update depending on the type of the asset
   * @param assetType The type of the asset
   * @returns The entity
   */
  static getTeamEntity(assetType: string) {
    switch (assetType) {
      case 'project.':
        return 'entity/teamprojectupdate';
      case 'order.':
        return 'entity/teamactionupdate';
      case 'task.':
        return 'entity/teamtaskupdate';
      case 'request.':
        return 'entity/teamrequestupdate';
    }
  }

  /**
   * Gets the entity to use in a material update depending on the type of the asset
   * @param assetType The type of the asset
   * @returns The entity
   */
  static getEntity(assetType: string) {
    switch (assetType) {
      case 'picture.':
        return 'entity/imageupdate';
      case 'video.':
        return 'entity/videoupdate';
      case 'pdf.':
        return 'entity/pdfupdate';
      case 'audio.':
        return 'entity/audioupdate';
      case 'web-page.':
        return 'entity/webpageupdate';
      case 'order.':
        return 'entity/actionupdate';
      case 'project.':
        return 'entity/project';
      case 'group.':
        return 'entity/groupupdate';
      case 'presentation.':
        return 'entity/presentationupdate';
      case 'office.':
        return 'entity/officeupdate';
      case 'spreadsheet.':
        return 'entity/spreadsheetupdate';
      case 'zip.':
        return 'entity/zipupdate';
      case 'document.design.':
        return 'entity/designupdate';
      case 'text.':
        return 'entity/textupdate';
      case 'task.text.':
        return 'entity/task_text';
    }
  }

  /**
   * Gets the element with the ide passed as param
   * @param id The id to search
   * @param array The array of elements
   * @returns The element with the id if it is in the array. An object with an empty name if not.
   */
  static getById(id: string, array: any[]) {
    let element = array.find((x: any) => x.id == id);
    return element ? element : { name: '' };
  }

  /**
   * Gets the encoded value of a string
   * @param value The string element
   * @returns The enconded value of the string
   */
  static getEncodedValue(value: string): string {
    return encodeURIComponent(encodeURIComponent(btoa(value)));
  }

  /**
   * Gets the icon depending on the type of asset
   * @param assetType The type of the asset
   * @returns The icon
   */
  static getIcon(assetType: string) {
    switch (assetType) {
      case 'web-page.':
        return 'web';
      case 'pdf.':
        return 'picture_as_pdf';
      case 'audio.':
        return 'music_video';
      case 'group.':
        return 'folder';
      case 'picture.':
        return 'photo';
      case 'video.':
        return 'movie';
      case 'presentation.':
        return 'photo_library';
      case 'zip.':
        return 'create_new_folder';
      default:
        return 'attachment';
    }
  }

  /**
   * Parse the color component to hexadecimal
   * @param c The color component
   * @returns The hexadecimal value
   */
  static componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  /**
   * Parse a decimal number to a hexadecimal color
   * @param number The decimal number
   * @returns The hexadecimal color
   */
  static dec2hex(number) {
    var intnumber = number - 0,
      red, green, blue;
    red = (intnumber >> 16) & 0xFF;
    green = (intnumber >> 8) & 0xFF;
    blue = intnumber & 0xFF;
    return '#' + this.componentToHex(red) + this.componentToHex(green) + this.componentToHex(blue);
  }

  /**
   * Gets the workflow id depending on the type of the asset
   * @param assetType The type of the asset
   * @returns The workflow id
   */
  static getWorkflowId(assetType: string) {
    switch (assetType) {
      case 'picture.':
        return AppSettings.WORKFLOW_ID_PICTURE;
      case 'video.':
        return AppSettings.WORKFLOW_ID_VIDEO;
      case 'pdf.':
        return AppSettings.WORKFLOW_ID_PDF;
      case 'audio.':
        return AppSettings.WORKFLOW_ID_AUDIO;
      case 'office.':
        return AppSettings.WORKFLOW_ID_OFFICE;
      case 'spreadsheet.':
        return AppSettings.WORKFLOW_ID_SPREADSHEET;
      case 'presentation.':
        return AppSettings.WORKFLOW_ID_PRESENTATION;
      case 'zip.':
        return AppSettings.WORKFLOW_ID_ZIP;
      case 'web-page.':
        return AppSettings.WORKFLOW_ID_WEBPAGE;
      case 'text.':
        return AppSettings.WORKFLOW_ID_TEXT;
    }
  }

  /**
   * Gets the defult workflow step depending on the type of the asset
   * @param assetType The type of the asset
   * @returns The default workflow step
   */
  static getDefaultWorkflowStep(assetType: string) {
    switch (assetType) {
      case 'picture.':
        return AppSettings.DEFAULT_WORKFLOW_STEP_PICTURE;
      case 'video.':
        return AppSettings.DEFAULT_WORKFLOW_STEP;
      case 'pdf.':
        return AppSettings.DEFAULT_WORKFLOW_STEP_PDF;
      case 'audio.':
        return AppSettings.DEFAULT_WORKFLOW_STEP;
      case 'office.':
        return AppSettings.DEFAULT_WORKFLOW_STEP_OFFICE;
      case 'spreadsheet.':
        return AppSettings.DEFAULT_WORKFLOW_STEP;
      case 'presentation.':
        return AppSettings.DEFAULT_WORKFLOW_STEP;
      case 'zip.':
        return AppSettings.DEFAULT_WORKFLOW_STEP;
      case 'web-page.':
        return AppSettings.DEFAULT_WORKFLOW_STEP;
      case 'text.':
        return AppSettings.DEFAULT_WORKFLOW_STEP_TEXT;
    }
  }

  /**
   * Gets the rejected workflow step depending on the type of the asset
   * @param assetType The type of the asset
   * @returns The rejected workflow step
   */
  static getRejectedWorkflowStep(assetType: string) {
    switch (assetType) {
      case 'picture.':
        return AppSettings.REJECTED_WORKFLOW_STEP_PICTURE;
      case 'video.':
        return AppSettings.REJECTED_WORKFLOW_STEP;
      case 'pdf.':
        return AppSettings.REJECTED_WORKFLOW_STEP_PDF;
      case 'audio.':
        return AppSettings.REJECTED_WORKFLOW_STEP;
      case 'office.':
        return AppSettings.REJECTED_WORKFLOW_STEP_OFFICE;
      case 'spreadsheet.':
        return AppSettings.REJECTED_WORKFLOW_STEP;
      case 'presentation.':
        return AppSettings.REJECTED_WORKFLOW_STEP;
      case 'zip.':
        return AppSettings.REJECTED_WORKFLOW_STEP;
      case 'web-page.':
        return AppSettings.REJECTED_WORKFLOW_STEP;
      case 'text.':
        return AppSettings.REJECTED_WORKFLOW_STEP_TEXT;
    }
  }

  /**
   * Gets the approved workflow step depending on the type of the asset
   * @param assetType The type of the asset
   * @returns The approved workflow step
   */
  static getApprovedWorkflowStep(assetType: string) {
    switch (assetType) {
      case 'picture.':
        return AppSettings.APPROVED_WORKFLOW_STEP_PICTURE;
      case 'video.':
        return AppSettings.APPROVED_WORKFLOW_STEP;
      case 'pdf.':
        return AppSettings.APPROVED_WORKFLOW_STEP_PDF;
      case 'audio.':
        return AppSettings.APPROVED_WORKFLOW_STEP;
      case 'office.':
        return AppSettings.APPROVED_WORKFLOW_STEP_OFFICE;
      case 'spreadsheet.':
        return AppSettings.APPROVED_WORKFLOW_STEP;
      case 'presentation.':
        return AppSettings.APPROVED_WORKFLOW_STEP;
      case 'zip.':
        return AppSettings.APPROVED_WORKFLOW_STEP;
      case 'web-page.':
        return AppSettings.APPROVED_WORKFLOW_STEP;
      case 'text.':
        return AppSettings.APPROVED_WORKFLOW_STEP_TEXT;
    }
  }

  /**
   * Compares to objects to know if the are equals
   * @param a The first object
   * @param b The second object
   * @returns True if the objects are equals
   */
  static equal(a, b): boolean {
    let result: boolean = true;

    if (a !== Object(a) || b !== Object(b)) {
      return a == b;
    }
    if (a != b) {
      if (a && b) {
        if (Object.keys(a).length > 0 && Object.keys(b).length > 0) {

          if (Object.keys(a).length == Object.keys(b).length) {
            Object.keys(a).forEach(x => {
              if (result) {
                result = this.equal(a[x], b[x]);
              }
            });
          } else {
            result = false;
          }

        } else {
          result = a.length == 0 && b.length == 0;
        }
      } else {
        result = false;
      }
    }

    return result;
  }

  /**
   * Get a random hashcode
   * @returns The random hashcode
   */
  static randomHash() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  /**
   * Gets the string passed as param with the first character in capital letters
   * @param s The string to convert
   * @returns The string with its first character in capital letters
   */
  static capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  /**
   * Gets the feature used to remove a relation
   * @param s The relation to remove
   * @returns The feature used to remove the relation
   */
  static getSchemaRemoveFeature(s) {
    switch (s) {
      case 'Responsible person':
      case 'owner_rel':
        return 'removeOwner';
      case 'Project Manager':
      case 'projectmanager_rel':
        return 'removeProjectmanager';
      case 'Deputy':
      case 'Power User':
      case 'co-owner':
        return 'removeCoowner';
      case 'Assistant Manager':
      case 'co-manager':
        return 'removeComanager';
      case 'Decision-Maker':
      case 'decision-maker':
        return 'removeDecisionmaker';
      case 'Contributor':
      case 'contributor':
        return 'removeContributor';
      case 'Reader':
      case 'reader':
        return 'removeReader';
      case 'Production':
      case 'production':
        return 'removeProduction';
    }
  }

  /**
   * Replaces 'http' for 'https'
   * @param s The http url
   * @returns The https url
   */
  static replaceHttp(s) {
    return s.replace('http://', 'https://');
  }

  /**
   * Gets the normalized text removing the escaped characters and replacing them for their html code
   * @param text The texto to be normalized
   * @returns The normalized text without escaped characters
   */
  static normalize(text: string) {
    if (text) {
      for (var i = 0; i < text.length; i++) {
        var char = text.charAt(i);
        var escapedChar = escape(char);

        if (this.UMLAUT_CHAR == escapedChar) {
          var searchString = text.charAt(i - 1) + char;
          var escapedSearchString = text.charAt(i - 1) + escapedChar;
          if (this.ESCAPED_CHARACTERS.has(escapedSearchString)) {
            text = text.replace(searchString, this.ESCAPED_CHARACTERS.get(escapedSearchString));
          }
        }
      }
    }

    return text;
  }

  /**
   * Gets the size of a file
   * @param size The size of the file
   * @param cs Optional. The unit of the size
   * @returns The size of the file
   */
  static getSize(size, cs = 0) {

    const s = ['B', 'KB', 'MB', 'GB', 'TB'];

    if (size > 1024) {
      return Utils.getSize(size / 1024, cs + 1);
    }

    return size.toFixed(2) + s[Math.min(cs, s.length - 1)];
  }

  /**
   * Decodes a text using he library
   * @param text
   * @returns
   */
  static decode(text: string) {
    var decodedText = '';

    if (text) {
      decodedText = this.he.decode(text);
    }

    return decodedText;
  }

  /**
   * Clones an array
   * @param oldArray The array to be cloned
   * @returns The cloned array
   */
  static cloneArray(oldArray) {
    let clonedArray = [];

    if (oldArray && oldArray.length > 0) {
      oldArray.forEach(object => clonedArray.push(Object.assign({}, object)));
    }

    return clonedArray;
  }

  /**
   * Groups the elements of the list using the key
   * @param list The list of elements
   * @param keyGetter The function the get the key of the elements
   * @returns A map with all the elements grouped by their key
   */
  static groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  /**
   * Gets the number of days between two dates
   * @param d1 The first date
   * @param d2 The second date
   * @returns The number of days between dates
   */
  static getNumDaysBetween(d1: Date, d2: Date) {
    let newD1 = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), 0, 0, 0, 0);
    let newD2 = new Date(d2.getUTCFullYear(), d2.getUTCMonth(), d2.getUTCDate(), 0, 0, 0, 0);
    var diff = newD2.getTime() - newD1.getTime();
    return diff / (1000 * 60 * 60 * 24);
  }

  /**
   * Parses a date using the format passed as param
   * @param input The date
   * @param format The format
   * @returns The formatted date
   */
  static parseDate(input, format) {
    format = format || 'yyyy-mm-dd'; // default format
    var parts = input.match(/(\d+)/g),
      i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++; });

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
  }

  /**
     * Give recurring dates between 2 dates
     * @param input The date
     * @param input The date
     * @returns The formatted date
     */
  static recurringDates(startDate, endDate, interval, intervalType, noweekends) {
    intervalType = intervalType || 'Date';
    var date = startDate;
    var recurrent = [];
    var setget = { set: 'set' + intervalType, get: 'get' + intervalType };

    while (date < endDate) {
      recurrent.push(noweekends ? noWeekend() : new Date(date));
      date[setget.set](date[setget.get]() + interval);
    }

    // add 1 day for sunday, subtract one for saturday
    function noWeekend() {
      var add, currdate = new Date(date), day = date.getDay();
      if (~[6, 0].indexOf(day)) {
        currdate.setDate(currdate.getDate() + (add = day == 6 ? -1 : 1));
      }
      return new Date(currdate);
    }

    return recurrent;
  }

  /**
   * The date is corrected to avoid alterations to the date
   * @param date Date to be corrected
   * @returns Corrected date
   */
  static fixDate(date) {
    return date.replace('Z', '');
  }

  /**
   * Obtains the date of the asset in a correct format
   * 
   * @returns The date in a correct format
   */
   static formatDateCorretly( date ){
     let result = null;
     let test: moment.Moment;
     if(date) {
        if(typeof date === 'string') {
          result = moment(date, "YYYY-MM-DD+00:00").format('yyyy-MM-DDTHH:mm:00+0000');
        } else {
          result = date;
        }
      }
      result = moment(date, "YYYY-MM-DD+00:00").format('yyyy-MM-DDTHH:mm:00+0000');
      
      return result.replace('+0000', 'Z');

    /*let start = null;

    if (this.infocenterForm.value.startDate) {
      let date : moment.Moment;
      if (typeof this.infocenterForm.value.startDate === 'string') {
        date = moment(this.infocenterForm.value.startDate, "YYYY-MM-DD+00:00");
      } else {
        date = this.infocenterForm.value.startDate;
      }

      if (this.infocenterForm.value.startDateTime) {
        date.hour(+this.infocenterForm.value.startDateTime.split(':')[0]);
        date.minute(+this.infocenterForm.value.startDateTime.split(':')[1]);
      } else {
        date.hour(+this.defaultStartTime.split(':')[0]);
        date.minute(+this.defaultStartTime.split(':')[1]);
      }

      start = date.format('yyyy-MM-DDTHH:mm:00+0000');
      start = start.replace('+0000', 'Z');
    }

    return start;

    */
  }
}