import { Pipe, PipeTransform } from '@angular/core';
/**
 * @ignore
 */
@Pipe({
  name: 'showElement'
})
export class ShowElementPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value && value.indexOf('.') > -1) {
      const arr = value.split('.');
      if (arr.length <= args) return '';
      return this.capitalizeFirstLetter(arr[args]);
    } else return value;
  }

  private capitalizeFirstLetter(text) {
    if (text === 'emea') {
      return 'EMEA';
    }
    return text.charAt(0).toUpperCase() + text.slice(1).split('_').reduce((acc, x) => acc + ' ' + this.capitalizeFirstLetter(x));
  }

}
