import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ClickOutsideModule } from 'ng-click-outside';
import { DragScrollModule } from 'ngx-drag-scroll';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularDraggableModule } from 'angular2-draggable'
import { BryntumSchedulerProModule } from '@bryntum/schedulerpro-angular'
import { NgxCurrencyModule } from 'ngx-currency';

@NgModule({
  exports: [
    FlexLayoutModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    NgxMaterialTimepickerModule,
    ClickOutsideModule,
    DragScrollModule,
    CKEditorModule,
    AngularDraggableModule,
    BryntumSchedulerProModule,
    NgxCurrencyModule
  ]
})
export class SharedModule { }
