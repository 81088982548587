import { Component, ContentChild, Input, OnInit } from '@angular/core';
import Utils from 'app/shared/utils/utils';
import { FileItemComponent } from '../widgets/files-widget/file-item/file-item.component';

/**
 * This component defines the main functionality of the accordion component.
 */
@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {

  /**
   * Accordion title
   */
  @Input() title: string;
  /**
   * @ignore
   */
  @Input() small: boolean;
  /**
   * Max height
   */
  @Input() maxHeight: number;
  /**
   * Indicate if it occupies the entire width
   */
  @Input() full: boolean;
  /**
   * Indicate whether the background is transparent
   */
  @Input() transparent: boolean;
  /**
   * 
   */
  @Input() gantt: any;
  /**
   * Indicates whether the slash is white
   */
  @Input() white: boolean = true;
  /**
   * Indicates whether links are displayed
   */
  @Input() links: boolean = false;
  /**
   * Indicates if it can be downloaded
   */
  @Input() download: boolean = false;
  /**
   * Indicates file type
   */
  @Input() type: string;
  /**
   * Indicates if it a Power User
   */
  @Input() isPowerUser: boolean = false;
  /**
   * Indicates if it a Operator
   */
  @Input() isOperator: boolean = false;
  @ContentChild('fileItem', {static: true}) fileItem: FileItemComponent;

  /**
   * True if the accordion is open, false if it is closed.
   */
  opened = true;
  utils = Utils;

  /**
   * Indicates if it can be uploaded
   */
  canUpload : boolean = false;
  /**
   * Indicates if it can be downloaded
   */
  canDownload : boolean = false;

  constructor() { }

  /**
   * Check if it can be uploaded and if it can be downloaded.
   */
  ngOnInit() {
    this.canUpload = !this.isPowerUser || (this.type == 'customer-data' && this.isPowerUser);
    this.canDownload = !this.isPowerUser || (this.type != 'agency-data' && this.type != 'consulting' && this.isPowerUser);
  }

  /**
   * Open and close accordion
   */
  toggleAccordion(): void {
    this.opened = !this.opened;
  }

  /**
   * Opens a dialog with file information 
   */
  openInfoDialog(): void {
    if (this.fileItem) {
      this.fileItem.openInfoDialogArray(this.fileItem.data);
    }
  }

  /**
   * Download files
   */
  downloadFiles(): void {
    if (this.fileItem) {
      this.fileItem.downloadFiles(this.fileItem.data);
    }
  }

  /**
   * upload a file
   * @param event 
   */
  uploadFile(event): void {
    event.preventDefault();
    Utils.accordionType = this.type;
    Utils.loadingData = true;
    if (this.fileItem) {
      this.fileItem.uploadFile(event);
    }
  }

  /**
   * Change the view
   * @param {string} type 
   */
  toggleView(type: string): void {
    if (type === 'list') {
      this.gantt.list = true;
      this.gantt.gantt = false;
    } else {
      this.gantt.gantt = true;
      this.gantt.list = false;
    }
  }
}
