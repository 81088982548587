import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { UserService } from 'app/shared/services/user/user.service';
import Utils from 'app/shared/utils/utils';
import * as _ from 'lodash';

/**
 * @ignore
 */
@Component({
  selector: 'app-jobs-widget',
  templateUrl: './jobs-widget.component.html',
  styleUrls: ['./jobs-widget.component.scss']
})
export class JobsWidgetComponent implements OnInit {

  /**
   * The project for which to obtain the jobs list. May come empty.
   */
  @Input() project;

  /**
   * The jobs list. May come empty
   */
  @Input() jobs;

  /**
   * The title of widget according to listing displayed
   */
  @Input() label : string;

  /**
   * The footer text (link) of widget according to listing displayed
   */
  @Input() footerText = 'all_jobs';

  /**
   * Different params depending on listing to display
   */
  @Input() queryParams = {};


  /**
   * Amount of items to show
   */
  @Input() maxDisplay = 10;

  /**
   * Force width depending on roles
   */
  forceWidth = false;

  utils = Utils;

  currentUser;
  list = [];
  jobsList = [];


  constructor(
    private usersService: UserService,
    private actionsService: ActionsService
  ) { }

  /**
   * Initialize the listing to display, from project or jobs list, depending on project input is empty or not.
   */
  ngOnInit() {
    this.currentUser = this.usersService.getCurrentUser();
    if (this.project && !this.jobs) {
      this.queryParams['project'] = this.project.id;
      this.actionsService.getActionsByProject(this.project.id).then(async data => {
        this.jobsList = [];
        let over = false;
        Utils.loadingData = true;
        while (!over) {
          this.jobsList.push(...this.actionsService.fillData(this.currentUser, data.result));

          if (data.offset + data.limit < data['total-count']) {
            data = await this.actionsService.getActionsByProject(this.project.id, data.offset + data.limit);
          } else {
            over = true;
            Utils.loadingData = false;
            this.sorting(1);
          }
        }
      });
      this.forceWidth = true;
    } else if (this.jobs){
      this.jobsList = this.jobs;
      this.sorting(1);
      Utils.loadingData = false;
      if (_.indexOf(this.currentUser.roles, 'root.cntr.operator.') >= 0) {
        this.forceWidth = true;
      }

    }
  }

  /**
   * Sort the jobs list according to the the option
   * 
   * @param option The option to be sorted. 1: by date. 2: by priority. 3: by name
   */
  sorting(option) {
    switch (option) {
      case 1:
        this.list = this.jobsList.sort((a, b) => new Date(b.createdAttr).getTime() - new Date(a.createdAttr).getTime());
        break;
      case 2:
        this.list = this.jobsList.sort((a, b) => a.priority - b.priority);
        break;
      case 3:
        this.list = this.jobsList.sort((a, b) => a.name.localeCompare(b.name));
        break;
    }
  }

}
