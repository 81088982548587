import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewBriefingComponent } from 'app/layout/briefings/new-briefing/new-briefing.component';

/**
 * @ignore
 */
@Component({
  selector: 'app-new-briefing-widget',
  templateUrl: './new-briefing-widget.component.html',
  styleUrls: ['./new-briefing-widget.component.scss']
})
export class NewBriefingWidgetComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {}

  /**
   * Opens a new briefing dialog
   */
  openNewBriefingDialog(): void {
    const newBriefingDialog = this.dialog.open(NewBriefingComponent, { panelClass: 'newBriefingDialog' });
  }

}
