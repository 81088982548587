import { Component, OnInit, Input } from '@angular/core';
import { User } from 'app/shared/model/user.model';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/shared/services/user/user.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { Team } from 'app/shared/model/team.model';

/**
 * @ignore
 */
@Component({
  selector: 'app-user-circle',
  templateUrl: './user-circle.component.html',
  styleUrls: ['./user-circle.component.scss']
})
export class UserCircleComponent implements OnInit {

  @Input() team: Team;
  @Input() user: User;
  @Input() addMember: any = {};
  /** Team initials */
  teamInitials

  position = new FormControl('above');

  tooltip = '';

  constructor(
    private translateService: TranslateService, 
    private userService: UserService,
    private rolesService: RolesService
    ) { }

  ngOnInit() {
    this.addMember.circleCount++;
    if(typeof this.user === 'number'){
      this.user = this.userService.getUserData(this.user);
    }

    if (this.user && this.user['fix_name']){
      this.tooltip = this.user['fix_name'];
    } else if (this.user && this.user.display_name){
      this.tooltip = this.user.display_name;
    }
    if(this.user && this.user.roles && this.user.roles.length > 0){
      let rolesName = this.user.roles.map( role => this.rolesService.getRole(role).name)
      this.tooltip += '\r\n' + rolesName.join('\r\n');
    }
    if(this.team){
      this.tooltip = this.team.name;
      this.getInitials()
    }

  }
  getInitials(){
    this.teamInitials= this.team.name.split(' ').map((n)=>n[0]).join('').toUpperCase()
  }
}
