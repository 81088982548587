import { AuthUser, Auth, EmailStruct } from 'app/shared/guard/model/auth.model';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { HCMSService } from '../services/satellites/hcms.service';
import { LiquidCacheService } from 'ngx-liquid-cache';
import { Router } from '@angular/router';
import { CollectionsService } from '../services/collections/collections.service';
import { Authentication } from './authentication/Authentication';
import { AppSettings } from '../app.settings';
import { User, UserPicture } from '../model/user.model';
import { TranslateService } from '@ngx-translate/core';
import { OCService } from '../services/satellites/oc.service';
import { CommonService } from '../services/common/common.service';
import { RolesService } from '../services/user/roles.service';
import { Mail } from '../model/mail.model';
import { MailService } from '../services/mail/mail.service';
import { environment } from 'environments/environment';
import * as CryptoJS from 'crypto-js';
import { Address, Agency, Email, Login, Phone } from '../model/agency.model';
import { forEach } from 'jszip';
import { MimetypeService } from '../services/mimetype/mimetype.service';
import { Material } from '../model/material.model';
import Utils from '../utils/utils';
import { MaterialsService } from '../services/materials/materials.service';
/**
 * Service defined to manage operations of auth.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * the redirection url 
   */
  static redirectUrl: string;

  /**
   * the redirection url 
   */
  redirectUrl: string;
  /**
   * {@link Authentication}
   */
  authentication: Authentication;

  
  /**
   * 
   * @param hcmsService Service with the functions related to the censhare.
   * @param ocService Service whit the functions related to the online channel.
   * @param userService Service with the functions related to the users.
   * @param cache Service with the functions related to the cache.
   * @param router Service with the functions related to the router.
   * @param collectionsService Service with the functions related to the collections.
   * @param translateService Service with the functions related to the translations.
   * @param commonService Service with the common functions.
   * @param mailService Service with the functions related to the mails.
   * @param mimetypeService Service with the functions related to the mime types.
   * @param materialsService Service with the functions related to the materials.
   */
  constructor(
    private hcmsService: HCMSService,
    private ocService: OCService,
    private userService: UserService,
    private cache: LiquidCacheService,
    private router: Router,
    private collectionsService: CollectionsService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private mailService: MailService,
    private mimetypeService: MimetypeService,
    private materialsService: MaterialsService
  ) {

    this.authentication = new Authentication(hcmsService, ocService, userService);

  }
  /**
   * Check if the user and password are valid to enter the system.
   * @param login user's email
   * @param password user's password
   * @returns error message if login failed, otherwise null
   */
  login(login: string, password: string): Promise<string> {

    return this.authentication.validUser(login, password).then(user => {

      if (!user)
        return 'invalid_login_or_password';
      if (user.workflowStep && +user.workflowStep === UserService.WORKFLOW_WEBUSER_STEP_PENDING_TO_APPROVE) {
         return 'login_not_actived';
      }
      if (user.workflowStep && +user.workflowStep === UserService.WORKFLOW_WEBUSER_STEP_ACCOUNT_DISABLED) {
        return 'login_disabled';
      }
      if (user.confirmation || (user.workflowStep && +user.workflowStep === UserService.WORKFLOW_WEBUSER_STEP_NOT_CONFIRMED)) {
        return 'login_not_confirmed';
      }


      this.loginUserToSystem(user.id);
      return null;

    });

  }
  /**
   * Check if the party user and password are valid to enter the system and is activated.
   * @param login user's email
   * @param password user's password
   * @returns error message if login failed, otherwise null 
   */
  partyLogin(login: string, password: string): Promise<string> {

    return this.authentication.validPartyUser(login, password).then(user => {

      if (!user || !user.id)
        return 'invalid_login_or_password';

      if (user.workflow == AppSettings.WORKFLOW_WEBUSER && user.workflowStep == UserService.WORKFLOW_WEBUSER_STEP_PENDING_TO_APPROVE)
        return 'login_not_actived';

      this.loginUserToSystem(user.id);
      return null;

    });

  }
  /**
   * Checks the validity of the user
   * @param login user's email
   * @param password user's password
   * @returns  if the user is valid it returns the token
   */
  async validUser(login: string, password: string): Promise<any> {
    return this.authentication.validUser(login, password)
  }
  /**
   * Send a recovery password 
   * @param login user's email
   * @returns message with the information if the recovery password could be sent
   */
  async resetPassword(login: string): Promise<string> {
    const user: AuthUser = await this.authentication.getUserByLogin(login);
    if (user) {

      let current = await this.authentication.getUser(user);
      current.passwordRecovery = await this.authentication.createNewKey();

      let currentLang = this.commonService.getCurrentLanguage();
      let updated = await this.authentication.updateUser(current);
      if (updated) {

        let mail: Mail = new Mail(AppSettings.MAIL_RECOVERY + '_' + updated.id, AppSettings.MAIL_RECOVERY, -1, +updated.id);
        this.mailService.saveMail(mail, false);
        return 'recovery_password_email_sent';

      }
    }
    return 'recovery_password_invalid_email';
  }
  /**
   * Send an invitation email
   * @param login user's email
   * @param showMessage True if there is a message to display.
   * @returns Response to the request 
   */
  async sendInvitationMail(login: string, showMessage=true){
    const user: AuthUser = await this.authentication.getUserByLogin(login);
    if (user) {

      let current = await this.authentication.getUser(user);
      current.passwordRecovery = await this.authentication.createNewKey();

      let updated = await this.authentication.updateUser(current);
      if (updated) {

        let mail: Mail = new Mail(AppSettings.MAIL_INVITATION + '_' + updated.id, AppSettings.MAIL_INVITATION, -1, +updated.id);
        return this.mailService.saveMail(mail, showMessage);
      }
    }
    return null;
  }
  /**
   * Confirm password change
   * @param login user's email
   * @param token @ignore
   * @param newPassword new password
   * @returns Notification message if the password could be updated
   */
  async confirmResetPassword(login: string, token: string, newPassword: string): Promise<any> {
    const user: AuthUser = await this.authentication.getUserByLogin(login);
    /*if (!(user && user.passwordRecovery && user.passwordRecovery.key === token)) {
      return {'text':'invalid_recovery_token', 'valid':false};
    }*/

    let updated = await this.resetUserPassword(user, newPassword);
    if (updated) {
      return {'text':'new_password_activated', 'valid':true};
    }
  }
  /**
   * Change user password
   * @param user {@link AuthUser}
   * @param newPassword new password
   * @returns Response to the request
   */
  async resetUserPassword(user: AuthUser, newPassword: string) {
    let current = await this.authentication.getUser(user);
    let encryptedPassword = CryptoJS.AES.encrypt(newPassword, AppSettings.partyLogin.key, AppSettings.partyLogin.options);
    current.auth.password = await this.authentication.hashPassword(newPassword);
    current.encodedPassword = btoa(encryptedPassword);

    if(current.passwordRecovery)
      delete current.passwordRecovery;

    return await this.authentication.updateUser(current);
  }
  /**
   * Change user password
   * @param auth {@link AuthUser}
   * @param newPassword new password
   * @returns Response to the request
   */
  async changeUserPassword(auth: AuthUser, newPassword: string) {
    return this.resetUserPassword(auth, newPassword).then(result => result);
  }
  /**
   * Confirm user activation
   * @param login user's email
   * @param token user token
   * @param companyUser True if the user is a company user
   * @returns Message that you have been activated or the response to the request that an email has been sent for activation
   */
  async confirmUser(login: string, token: string, companyUser: boolean = false): Promise<string> {
    const user: AuthUser = await this.authentication.getUserByLogin(login);
    if (!(user && user['confirmation'] && user['confirmation'].key === token)) {
      return 'invalid_confirmation_token';
    }

    let active = await this.isInWhitelist(login);

    let current = await this.authentication.getUser(user);
    delete current.confirmation;

    if (active || companyUser) {
      current.workflowStep = UserService.WORKFLOW_WEBUSER_STEP_APPROVED;
    } else {
      current.workflowStep = UserService.WORKFLOW_WEBUSER_STEP_PENDING_TO_APPROVE;
    }

    let updated = await this.authentication.updateUser(current);
    if (updated) {
      if (!active && !companyUser) {
        return await this.sendActivationEmails(current);
      }

      if (companyUser){
        return await this.sendActivationCompanyUser(current);
      }

      return 'user_actived';
    }
  }
  /**
   * New user registration
   * @param login user's email
   * @param password user password
   * @param firstName user's name
   * @param lastName user's last name
   * @param agency user's agency
   * @param position user's position 
   * @param encodedPassword encoded password
   * @param companyUser user's company
   * @returns the error message or the response to the request to send a confirmation email
   */
  async register(login: string, password: string, firstName: string, lastName: string,
    gender: string, company: string, companyContact: string): Promise<string> {
    const user: AuthUser = await this.authentication.getUserByLogin(login);
    if (user) {
      return 'registration_procees_error_duplicated';
    }

    let authData: Auth = new Auth();
    authData.login = login;
    authData.password = await this.authentication.hashPassword(password);

    let emailStruct: EmailStruct = new EmailStruct();
    emailStruct.email = login;

    let newUser: AuthUser = new AuthUser();
    newUser.auth = authData;
    newUser.emailStruct = emailStruct;
    newUser.confirmation = await this.authentication.createNewKey();
    newUser.name = firstName + ' ' + lastName;
    newUser.firstName = firstName;
    newUser.lastName = lastName;
    newUser.email = login;
    newUser.gender = gender;
    newUser.company = company;
    newUser.companyContact = companyContact ? companyContact : '';
    //newUser.workMessage = yourWork;
    newUser.roles = [RolesService.DEFAULT_ROLE];

    let updated: AuthUser = await this.hcmsService.get().all('entity/auth').post(newUser, {}, {'Authorization': Authentication.getAuthToken()}).toPromise();
    if (updated) {
      if (updated.active) return await this.sendConfirmationEmail(updated);
      return await this.sendActivationEmails(updated);
    }

    return 'registration_procees_error';
  }
  /**
   * Registration of a new company
   * @param company new Company
   * @param user data of the user creating the company
   * @param logos company logos
   * @param showReels 
   * @returns error message or request success
   */
  async registerCompany(company, user, logos: any[], showReels: any[]): Promise<string> {
    /*const user: AuthUser = await this.authentication.getUserByLogin(login);
    if (user) {
      return 'registration_procees_error_duplicated';
    }*/

    let newCompany: Agency = new Agency();

    let pcEmail = new Email();
    pcEmail.email = company.pcEmail;

    let phone = new Phone();
    phone.phoneNumber = company.mobilePhone;

    if (company.address) {
      company.address.forEach(addr => {
        let address = new Address();
        address.street = addr.street;
        address.streetNumber = addr.number.toString();
        address.postcode = addr.postcode.toString();
        address.city = addr.city;
        address.country = addr.country;

        newCompany.address.push(address);
      });
    }

    newCompany.name = company.companyname;
    newCompany.pcEmail = pcEmail;
    newCompany.phone = phone;
    newCompany.opLanguages = company.opLanguages;
    newCompany.vat = company.vat;
    newCompany.bio = company.bio;
    newCompany.objectives = company.objectives;
    newCompany.expertises = company.expertises;

    if (company.numEmployees) newCompany.numEmployees = company.numEmployees;
    if (company.link) newCompany.link = company.link;

    let updated: Agency = await this.hcmsService.get().all('entity/agency').post(newCompany).toPromise();
    if (updated) {
      const encryptedPassword = CryptoJS.AES.encrypt(user.password, AppSettings.partyLogin.key, AppSettings.partyLogin.options);
      if (logos && logos.length > 0) {
        await this.saveCompanyPicture(updated, logos[0]);
      }

      await this.register(user.email, user.password, user.firstname, user.lastname, btoa(encryptedPassword), newCompany.name, newCompany.name);
      
      if (showReels && showReels.length > 0) {
        showReels.forEach(file => this.saveShowReels(+updated.id, file));
      }

      return 'newCompany.message.registration_successfully';
    }

    return 'newCompany.message.registration_procees_error';
  }
  /**
   * Saves the company's image
   * @param company company to which the logo is to be added
   * @param logo logo
   * @returns the response to the request
   */
  async saveCompanyPicture(company, logo){

    let type = this.mimetypeService.getFileMimetype(logo);
    let picture: UserPicture = new UserPicture();
    picture.type = type.def_assettype;
    picture.name = logo.name;
    picture.downloadLink = "formdata:file0";
    picture.domain = company.domain;
    picture.reloadPreview = true;
    company.pictures = [picture];

    let fileFormData = new FormData();
    fileFormData.append("entity", JSON.stringify(company));
    fileFormData.append("file0", logo);

    return this.hcmsService.get().all('entity/agency/' + company.id).customPUT(fileFormData, '', {}, {}).toPromise();
  }
  /**
   * Save the showreels
   * @param parent company id
   * @param file file to save
   */
  async saveShowReels(parent, file) {
    let fileFormData = new FormData();

    let mime = file.type;
    let extension = Utils.getFileExtension(file.name);

    let type = this.mimetypeService.getMimetypeByMimetype(mime);
    if (!type && extension) {
      type = this.mimetypeService.getMimetypeByExtension(extension);
    }

    let newMaterial: Material = new Material();

    if (type && type.extension == '.svg') {
      newMaterial.svgfile = true;
    }

    newMaterial.name = file.name;
    newMaterial.parents.push(+parent);
    newMaterial.type = type.def_assettype;
    newMaterial.downloadLink = "formdata:file0";
    newMaterial.domain = AppSettings.DOMAIN;
    delete newMaterial.created;
    delete newMaterial.selected;
    delete newMaterial.preview;
    delete newMaterial.step_time;

    fileFormData.append("entity", JSON.stringify(newMaterial));
    fileFormData.append("file0", file);

    let result = await this.materialsService.saveShowReel(newMaterial, fileFormData);
  }
  /**
   * Check if it is on the whitelist
   * @param email user's email
   * @returns true if it is on the whitelist
   */
  async isInWhitelist(email: string) : Promise<boolean> {

    let domain = email.substring(email.lastIndexOf("@") +1);
    let emails = await this.ocService.get().one('config_list/whitelist').get().toPromise();
    return emails.length === 0 || emails.plain().indexOf(domain) > -1;

  }
  /**
   * Send an activation message to the user
   * @param updated {@link AuthUSer}
   * @returns Response message 
   */
  async sendActivationEmails(updated: AuthUser) : Promise<string> {

    let mail: Mail = new Mail(AppSettings.MAIL_NEWUSER + '_' + updated.id, AppSettings.MAIL_NEWUSER, -1, +updated.id);
    this.mailService.saveMail(mail, false);

    this.userService.getAdmins().subscribe(data => {

      if (data.result) {
        data.result.forEach(admin => {
          let mailAdmin: Mail = new Mail(AppSettings.MAIL_NEWUSERADMINISTRATOR + '_' + updated.id + '_' + admin.id, AppSettings.MAIL_NEWUSERADMINISTRATOR, +updated.id, +admin.id);
          this.mailService.saveMail(mailAdmin, false);
        });
      }
    });

    return 'registration_procees_success_need_active';
  }
  /**
   * 
   * Send an activation message to the company user
   * @param updated company user{@link AuthUSer}
   * @returns Response message
   */
  async sendActivationCompanyUser(updated: AuthUser) : Promise<string> {

    if (updated && updated.agencyMainContact && updated.agencyMainContact.length > 0){
      let company = updated.agencyMainContact[0];
      this.hcmsService.get().one('entity/agency', +company).get().subscribe(agency => {
        agency.workflowStep = AppSettings.WORKFLOW_COMPANY_SEND;
        agency.put();
      });
    }

    let mail: Mail = new Mail(AppSettings.MAIL_CONFIRM_COMPANY_USER + '_' + updated.id, AppSettings.MAIL_CONFIRM_COMPANY_USER, -1, +updated.id);
    this.mailService.saveMail(mail, false);
    return 'user_actived';
  }
  /**
   * Sends a confirmation email to the user
   * @param updated user {@link AuthUser}
   * @param notification true if have a notification
   * @returns Response message
   */
  async sendConfirmationEmail(updated: AuthUser, notification: boolean = false){
    let mail: Mail = new Mail(AppSettings.MAIL_CONFIRMATION + '_' + updated.id, AppSettings.MAIL_CONFIRMATION, -1, +updated.id);
    this.mailService.saveMail(mail, notification);
    return 'registration_procees_success';
  }
  /**
   * 
   * Sends a confirmation email to the company user
   * @param updated user {@link AuthUser}
   * @param notification true if have a notification
   * @returns Response message
   */
  async sendNewCompanyUserEmail(updated: AuthUser, notification: boolean = false){
    let mail: Mail = new Mail(AppSettings.MAIL_NEW_COMPANY_USER + '_' + updated.id, AppSettings.MAIL_NEW_COMPANY_USER, -1, +updated.id);
    this.mailService.saveMail(mail, notification);
    return 'registration_procees_success';
  }

  /**
   * Check if a user is logged in
   * @returns True if the user is logged in.
   */
  checkLoggedIn() {
    return this.userService.getCurrentUser() !== null;
  }
  /**
   * Logout
   */
  logout() {

    this.removeUserAndCachedData();
    this.router.navigate(['/login']);

  }
  /**
   * Delete user and cached information and localstorage information.
   */
  removeUserAndCachedData() {
    this.userService.removeCurrenUser();

    localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + 'jwtUser');
    // localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + 'jwt');

    this.cache.remove('webusers');
    //this.cache.remove('partyusers');
    this.cache.remove('main_domain');
    this.cache.remove('main_domain2');
    this.cache.remove('domains');
    this.cache.remove('domains2');
    this.cache.remove('keywords');

    // remove for now, as they have changed - maybe keep later for performance...?
    this.cache.remove('workflows');
    this.cache.remove('workflowsSteps');
    this.cache.remove('roles');

    Object.keys(this.cache.cachedElements).filter(x => x.startsWith('userLinks')).forEach(x => {
      this.cache.remove(x);
    });

    // Remove persistent filters
    Object.keys(this.cache.cachedElements).filter(x => x.indexOf('##') >= 0).forEach(x => {
      this.cache.remove(x);
    });

    if (!environment.production) {
      Object.keys(this.cache.cachedElements).filter(x => x.indexOf(UserService.WIDGET_CONFIG_KEY) >= 0).forEach(x => {
        this.cache.remove(x);
      });
    }

  }
  /**
   * Obtains the user's basic data when logging in.
   * @param id user id
   */
  private loginUserToSystem(id) {

    this.hcmsService.get().one('entity/webuser', id).get().toPromise()
      .then((data: User) => {
        if (data != null) {

          let user: User = Object.assign(new User, this.hcmsService.get().copy(data).plain());

          if (!user.collection) {
            this.collectionsService.createCollection(user.id);
          }

          this.translateService.use('en');
          /*if (user.language) {
            this.translateService.use(user.language);
          }*/

          this.userService.setCurrenUser(user);
            Utils.showTermsOfUse = true;
            this.collectionsService.loadUserCollection();

            const url = AuthService.redirectUrl;
            AuthService.redirectUrl = '';

          //setTimeout(() =>
            this.router.navigate([url ? url : '/'])
          //, 500);

        }
      });

  }
  /**
   * Obtains an updated token from the user
   * @param id user id
   * @returns response to the request
   */
  getAuthUserToUpdate(id,) {
    return this.hcmsService.get().one('entity/auth', id).get({}, {'Authorization': Authentication.getAuthToken()}).toPromise();
  }
  /**
   * Obtains updated keys for the user
   * @param user user
   * @returns response to the request
   */
  newConfirmationKey (user) {
    return this.getAuthUserToUpdate(user.id).then(async updated => {

      updated.confirmation = await this.authentication.createNewKey();
      return updated.put().toPromise().then(result => this.sendConfirmationEmail(result, true));

    });
  }

}