import { Component, Inject, InjectionToken, Injector, ReflectiveInjector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Component dialog data
 */
export const COMPONENT_DIALOG_DATA = new InjectionToken<any>('app.asset-data');
/**
 * Component dialog data type
 */
export const COMPONENT_DIALOG_DATA_TYPE: InjectionToken<any> = new InjectionToken<any>('app.asset-data-type');

/**
 * This component defines the main view of the dialog component
 */
@Component({
  selector: 'app-component-dialog',
  templateUrl: './component-dialog.component.html',
  styleUrls: ['./component-dialog.component.scss']
})
export class ComponentDialogComponent {

  /**
   * {@link Injector}
   */
  myInjector: Injector;

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data Data required for the component.
   * @param injector The injector to use as the parent for the new component.
   */
  constructor(
    private dialogRef: MatDialogRef<ComponentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector
  ) {
    this.myInjector = ReflectiveInjector.resolveAndCreate([{ provide: COMPONENT_DIALOG_DATA, useValue: data.assetData }], injector);
  }

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }
}
