import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils/utils';
/**
 * Define pipe SecureUrlPipe
 */
@Pipe({
  name: 'secureUrl'
})
export class SecureUrlPipe implements PipeTransform {
  /**
   * Transforms the public display url you get to a private path in the app
   * @param value link
   * @param args @ignore
   * @returns secure url
   */
  transform(value: string, args?: any): any {

    if (value) {
      var patt = "/entity/.*?/";
      var result = value.match(patt);
      if (result && result[0] && value.indexOf('/entity/recommendation') < 0) value = value.replace(result[0], "/entity/view/");

      return Utils.replaceHttp(value);
    }

    return value;
  }

}
