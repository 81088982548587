import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BryntumSchedulerProComponent } from '@bryntum/schedulerpro-angular';
import { ResourceTimeRangeModel, Grid, DragHelper, DomHelper, SchedulerEventStore, DateHelper, EventModel, Rectangle, Store, PresetManager, ViewPreset, SchedulerPro, ProjectModel, CalendarModel, CalendarIntervalModel, RecurringTimeSpan, RecurringTimeSpansMixin, ResourceTimeRangeStore } from '@bryntum/schedulerpro/schedulerpro.lite.umd.js';
import { BryntumGridComponent } from '@bryntum/schedulerpro-angular';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { SecureUrlPipe } from 'app/shared/pipes/secure-url.pipe';
import { SchedulerTeamsService } from 'app/shared/services/schedulerTeam/scheduler-team.service';
import Utils from 'app/shared/utils/utils';
import { RolesService } from 'app/shared/services/user/roles.service';
import { UserService } from 'app/shared/services/user/user.service';
import { TasksService } from 'app/shared/services/tasks/tasks.service';

// We want to use recurring time ranges
// so we make a special model extending standard ResourceTimeRangeModel
// with RecurringTimeSpan which adds recurrence support
// @ts-ignore
class MyResourceTimeRange extends RecurringTimeSpan(ResourceTimeRangeModel) { };

// Define a new store extending standard ResourceTimeRangeStore
// with RecurringTimeSpansMixin mixin to add recurrence support to the store.
// This store will contain time ranges.
// @ts-ignore
class MyResourceTimeRangeStore extends RecurringTimeSpansMixin(ResourceTimeRangeStore) {
    static get defaultConfig() {
        return {
            // use our new MyResourceTimeRange model
            modelClass: MyResourceTimeRange
        };
    }
};


/**
 * This component defines the scheduler panel to visualization of team tasks, members an its tasks
 */
@Component({
    selector: 'app-scheduler-manager',
    templateUrl: './scheduler-manager.component.html',
    styleUrls: ['./scheduler-manager.component.scss']
})
export class SchedulerManagerComponent implements OnInit, AfterViewInit {

    /**
     * {@link BryntumSchedulerComponent} used to control the scheduler angular component.
     */
    @ViewChild(BryntumSchedulerProComponent, { static: true }) schedulerproComponent: BryntumSchedulerProComponent;
    /**
     * {@link BryntumGridComponent} used to control the grid angular component.
     */
    @ViewChild(BryntumGridComponent, { static: true }) gridComponent: BryntumGridComponent;

    /**
     * Scheduler Team Data
     */
    @Input() schedulerTeam;

    /**
     * Filters to apply
     */
    @Input() filters;

    /**
     * Filtered list.
     */
    filteredList = [];

    /**
     * List of unnasigned tasks
     */
    unplannedlist;

    /**
     * List of team members (scheduler resources)
     */
    resources;

    /**
     * List of events of team members
     */
    events;

    /**
     * Instance of schedulerPro
     */
    private schedulerpro: SchedulerPro;

    /**
     * Instance of Grid
     */
    private grid: Grid;

    /**
     * scheduler event store
     */
    private eventStore: SchedulerEventStore;

    /**
     * Scheduler configuration
     */
    schedulerProConfig;

    /**
     * Grid configuration
     */
    gridUnplannedConfig;

    /**
   * is resource manager
   */
    public isResourceManager = false;

    /**
     * Constructor
     * 
     * @param datepipe 
     * @param translateService 
     * @param schedulerTeamsService 
     * @param secureUrlPipe 
     */
    constructor(
        public datepipe: DatePipe,
        private translateService: TranslateService,
        protected schedulerTeamsService: SchedulerTeamsService,
        private secureUrlPipe: SecureUrlPipe,
        protected usersService: UserService,
        protected rolesService: RolesService,
        private tasksService: TasksService,) {
    }

    /**
     * method that is called after the view of the component has started to be displayed.
     */
    ngAfterViewInit(): void {
        this.schedulerpro = this.schedulerproComponent.instance;
        this.grid = this.gridComponent.instance;
        this.schedulerpro.eventStore.autoCommit = true;
        this.eventStore = this.schedulerpro.eventStore;
        const { schedulerpro, grid, eventStore } = this;

        eventStore.on({
            update: ({ record, changes }) => {
                if ('resourceId' in changes && !record.resourceId) {
                    eventStore.remove(record);

                    record.responsible_object = record.data.responsible_object = [this.schedulerTeam];
                    if (record.data.endDateActual) delete record.data.endDateActual;
                    if (record.data.startDateActual) delete record.data.startDateActual;
                    record.data.startDate = record.originalData.startDate;
                    record.data.endDate = record.originalData.endDate;
                    record.data.constraintDate = null;
                    record.data.constraintType = null;
                    record.data.earlyEndDate = null;
                    record.data.earlyStartDate = null;

                    (grid.store as Store).add(new EventModel(record.data));

                } else {
                    record.startDateActual = record.startDate;
                    record.endDateActual = record.endDate;
                }

            },
            add: ({ records }) => {
            }
        });

        this.initDrag();

        (this.schedulerpro.features as any).resourceNonWorkingTime.maxTimeAxisUnit = 'week';

    }


    /**
     * Initializes the behaviour of dragging events from grid to scheduler
     */
    initDrag(): void {

        if (this.isResourceManager) {
            let _this = this;
            const
                { schedulerpro, grid } = this,
                drag = new DragHelper({
                    cloneTarget: true,
                    mode: 'translateXY',
                    // Only allow drops on the schedule area
                    dropTargetSelector: '.b-timeline-subgrid',
                    // Only allow drag of row elements inside on the unplanned grid
                    targetSelector: '.b-grid-row:not(.b-group-row)',
                    constrain: false,
                    outerElement: grid.element,
                    // Configure DragHelper with schedule's scrollManager to allow scrolling while dragging
                    scrollManager: schedulerpro.scrollManager
                });

            drag.on({
                dragstart: ({ context }) => {
                    const
                        mouseX = context.clientX,
                        proxy = context.element,
                        task = grid.getRecordFromElement(context.grabbed) as EventModel,
                        newWidth = 250
                        // newWidth = schedulerpro.timeAxisViewModel.getDistanceForDuration(task.duration * _this.schedulerTeamsService.getDaysInMillisecs())
                        ;

                    // save a reference to the task so we can access it later
                    context.task = task;

                    // Mutate dragged element (grid row) to look like an event bar
                    proxy.classList.remove('b-grid-row');
                    proxy.classList.add('b-sch-event-wrap');
                    proxy.classList.add('b-sch-event');
                    proxy.classList.add('b-unassigned-class');
                    proxy.classList.add(`b-${schedulerpro.mode}`);
                    proxy.innerHTML = `${task.name}`;

                    // If the new width is narrower than the grabbed element...
                    if (context.grabbed.offsetWidth > newWidth) {
                        const proxyRect = Rectangle.from(context.grabbed);

                        // If the mouse is off (nearly or) the end, centre the element on the mouse
                        if (mouseX > proxyRect.x + newWidth - 20) {
                            context.newX = context.elementStartX = context.elementX = mouseX - newWidth / 2;
                            DomHelper.setTranslateX(proxy, context.newX);
                        }
                    }

                    proxy.style.width = `${newWidth}px`;

                    // Prevent tooltips from showing while dragging
                    schedulerpro.element.classList.add('b-dragging-event');

                },
                drag: ({ context }) => {
                    const
                        { task } = context,
                        startDate = schedulerpro.getDateFromCoordinate(DomHelper.getTranslateX(context.element), 'round', false),
                        resource = context.target && schedulerpro.resolveResourceRecord(context.target)
                        ;

                    // Don't allow drops anywhere, only allow drops if the drop is on the time axis and on top of a Resource


                    context.valid = /*context.valid &&*/ Boolean(startDate && resource) /*&&
                        (this.schedulerproComponent.allowOverlap || schedulerpro.isDateRangeAvailable(startDate, endDate, null, resource))*/;

                    // Save reference to resource so we can use it in onTaskDrop
                    context.resource = resource;
                },
                drop: async ({ context }) => {
                    const
                        task = context.task,
                        target = context.target
                        ;

                    // If drop was done in a valid location, set the startDate and transfer the task to the Scheduler event store
                    if (context.valid && target) {
                        const date = schedulerpro.getDateFromCoordinate(DomHelper.getTranslateX(context.element), 'round', false),
                            // Try resolving event record from target element, to determine if drop was on another event
                            targetEventRecord = schedulerpro.resolveEventRecord(context.target);

                        if (date && context.resource) {
                            // Remove from grid first so that the data change
                            // below does not fire events into the grid.
                            (grid.store as Store).remove(task);

                            task.resource = context.resource;
                            task.setStartDate(date, false);
                            task.plannedHours = task.originalData.plannedHours;

                            if (task.originalData.plannedHours) {
                                task.setDuration(task.originalData.plannedHours / 24);   
                            } else {
                                task.setDuration((this.schedulerpro.project as any).calendar.calculateDurationMs(new Date(task.originalData.startDate), new Date(task.originalData.endDate)) / 1000 / 60 / 60 / 24);
                                //task.setDuration(context.resource.calendar.calculateDurationMs(new Date(task.originalData.startDate), new Date(task.originalData.endDate)) / 1000 / 60 / 60 / 24);    
                            }
                            
                            schedulerpro.eventStore.add(task, true);
                            // await schedulerpro.eventStore.project.commitAsync();
                        }

                        context.finalize();
                    } else {
                        drag.abort();
                    }

                    schedulerpro.element.classList.remove('b-dragging-event');
                }
            });
        }

    }

    /**
     * Initializes the component and retrieve the tasks.
     */
    ngOnInit() {
        const user = this.usersService.getCurrentUser();
        this.isResourceManager = this.rolesService.isResourceManager(user);

        this.setData();
    }



    projectLoad() {

        this.schedulerpro.eventStore.allRecords.forEach(t => {

            if (!(t as any).originalData.endDate && (t as any).originalData.deadline) {
                (t as any).originalData.endDate = (t as any).originalData.deadline;
            }

            const startDate = (t as any).originalData.startDateActual ? (t as any).originalData.startDateActual.replace('Z', '') : (t as any).originalData.startDate.replace('Z', '');
            const endDate = (t as any).originalData.deadlineActual ? (t as any).originalData.deadlineActual.replace('Z', '') : (t as any).originalData.endDate.replace('Z', '');
            const durationHours =  (t as any).originalData.plannedHours;

            if (durationHours) {
                (t as any).setDuration(durationHours / 24);
                (t as any).setEndDate(new Date(endDate), true);
            } else {
                (t as any).setStartDate(new Date(startDate), false);
                (t as any).setEndDate(new Date(endDate), false);
            }
        });

        (this.grid.store as Store).allRecords.forEach(t => {

            if (!(t as any).originalData.endDate && (t as any).originalData.deadline) {
                (t as any).originalData.endDate = (t as any).originalData.deadline;
            }

            const startDate = (t as any).originalData.startDate.replace('Z', '');
            const endDate = (t as any).originalData.endDate.replace('Z', '');
            const durationHours =  (t as any).originalData.plannedHours;

            if (durationHours) {
                (t as any).setDuration(durationHours / 24);
                (t as any).setEndDate(new Date(endDate), true);
            } else {
                (t as any).setStartDate(new Date(startDate), false);
                (t as any).setEndDate(new Date(endDate), false);
            }
            
        });
    }

    /**
     * Initializes the Scheduler and Grid with data
     */
    setData() {
        let _this = this;
        this.unplannedlist = this.schedulerTeam.team_tasks ? this.schedulerTeam.team_tasks.filter(x => x.responsible_object && x.responsible_object[0].id === this.schedulerTeam.id) : [];
        this.resources = this.schedulerTeam.members;

        this.events = this.schedulerTeam.events;

        if (!this.events) {
            this.events = [];
        }

        let startDate = this.events.length > 0 ? this.events.reduce((a, b) => (a.startDate < b.startDate ? a : b)).startDate : moment().subtract(1, 'months').toDate();
        let endDate = this.events.length > 0 ? this.events.reduce((a, b) => (a.endDate > b.endDate ? a : b)).endDate : moment().add(1, 'months').toDate();

        if (this.resources) {
            this.resources.forEach(x => {
                x.imageUrl = x.pictures && x.pictures[0] && x.pictures[0].image ? this.secureUrlPipe.transform(x.pictures[0].image.replace(this.schedulerTeam.id, x.id) + '/thumbnail') : '';
            });

        } else {
            this.resources = [];
        }

        const customMonthPreset = new ViewPreset({
            id: 'customMonthPreset',              // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'myPreset'
            name: 'Month View',        // A human-readable name provided to be used in GUI, e.i. preset picker, etc.
            tickWidth: 30,                // Time column width in horizontal mode
            tickHeight: 30,                // Time column height in vertical mode
            shiftIncrement: 1,             // Controls how much time to skip when calling shiftNext and shiftPrevious.
            shiftUnit: 'month',         // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
            // defaultSpan: 30,            // By default, if no end date is supplied to a view it will show 12 hours
            timeResolution: {              // Dates will be snapped to this resolution
                unit: 'minute',       // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
                increment: 1
            },
            headers: [      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
                {
                    unit: 'month',
                    dateFormat: 'MMMM,YYYY'
                },
                {
                    unit: 'week',
                    renderer(startDate, endDate, headerConfig, cellIdx) {
                        return 'CW ' + moment(startDate).week();
                    }
                },
                {
                    unit: 'day',
                    dateFormat: 'DD'
                }

            ],
            columnLinesFor: 2              // Defines header level column lines will be drawn for. Defaults to the last level.
        });

        const customDayPreset = new ViewPreset({
            id: 'customDayPreset',              // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'myPreset'
            name: 'Day View',        // A human-readable name provided to be used in GUI, e.i. preset picker, etc.
            tickWidth: 30,                // Time column width in horizontal mode
            tickHeight: 30,                // Time column height in vertical mode
            shiftIncrement: 1,             // Controls how much time to skip when calling shiftNext and shiftPrevious.
            shiftUnit: 'day',         // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
            // defaultSpan: 24,            // By default, if no end date is supplied to a view it will show 12 hours
            timeResolution: {              // Dates will be snapped to this resolution
                unit: 'minute',       // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
                increment: 1
            },
            headers: [      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
                {
                    unit: 'day',
                    dateFormat: 'dddd, DD.MM.YYYY'
                },
                {
                    unit: 'hour',
                    dateFormat: 'HH'
                }

            ],
            columnLinesFor: 1              // Defines header level column lines will be drawn for. Defaults to the last level.
        });

        const customWeekPreset = new ViewPreset({
            id: 'customWeekPreset',              // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'myPreset'
            name: 'Week View',        // A human-readable name provided to be used in GUI, e.i. preset picker, etc.
            tickWidth: 30,                // Time column width in horizontal mode
            tickHeight: 30,                // Time column height in vertical mode
            shiftIncrement: 1,             // Controls how much time to skip when calling shiftNext and shiftPrevious.
            shiftUnit: 'week',         // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
            // defaultSpan: 24,            // By default, if no end date is supplied to a view it will show 12 hours
            timeResolution: {              // Dates will be snapped to this resolution
                unit: 'minute',       // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
                increment: 1
            },
            headers: [      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
                {
                    unit: 'week',
                    renderer(startDate, endDate, headerConfig, cellIdx) {
                        return 'CW ' + moment(startDate).week() + '(' + DateHelper.format(startDate, 'DD') + '-' + DateHelper.format(DateHelper.add(endDate, -1, 'day'), 'DD') + ')';
                    }
                },
                {
                    unit: 'day',
                    dateFormat: 'dd'
                },
                {
                    unit: 'day',
                    dateFormat: 'DD'
                }

            ],
            columnLinesFor: 1              // Defines header level column lines will be drawn for. Defaults to the last level.
        });
        PresetManager.add(customMonthPreset);
        PresetManager.add(customWeekPreset);
        PresetManager.add(customDayPreset);

        const calendarsData = [new CalendarModel({
            "id": "default",
            "name": "default",
            "intervals": [
                new CalendarIntervalModel({
                    "recurrentStartDate": "on Sat at 0:00",
                    "recurrentEndDate": "on Mon at 0:00",
                    "isWorking": false
                }),
                new CalendarIntervalModel({
                    "recurrentStartDate": "every weekday at 20:00",
                    "recurrentEndDate": "every weekday at 08:00",
                    "isWorking": false
                }),
            ]
        })
        ];

        this.resources.forEach(x => {
            x.calendar = 'user_' + x.id;
            const intervals = [];

            if (x.vacations) {
                x.vacations.forEach(v => {

                    const startDate = new Date(v.from);
                    const endDate = new Date(v.to);

                    if (v.startTime) {
                        startDate.setHours(+v.startTime.split(':')[0], +v.startTime.split(':')[1]);
                    } else {
                        startDate.setHours(0);
                    }

                    if (v.endTime) {
                        endDate.setHours(+v.endTime.split(':')[0], +v.endTime.split(':')[1]);
                    } else {
                        endDate.setHours(23, 59, 59);
                    }


                    if (v.recurrence) {



                        let recurrence = 1;
                        let type = 'Date';

                        switch (v.recurrence) {
                            case 'weekly':
                                recurrence = 7;
                                break;
                            case 'biweekly':
                                recurrence = 14;
                                break;
                            case 'monthly':
                                recurrence = 1;
                                type = 'Month';
                                break;
                            case 'yearly':
                                recurrence = 1;
                                type = 'Year';
                                break;
                        }

                        const recurrentDates = Utils.recurringDates(startDate, endDate, recurrence, type, true);

                        recurrentDates.forEach(d => {
                            const interval = new CalendarIntervalModel();
                            interval.isWorking = false;
                            interval.startDate = d;

                            const ed = new Date(d.getTime());
                            ed.setHours(endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());
                            interval.endDate = ed;
                            intervals.push(interval);
                        });
                    } else {
                        const interval = new CalendarIntervalModel();
                        interval.isWorking = false;
                        interval.startDate = startDate;
                        interval.endDate = endDate;
                        intervals.push(interval);
                    }

                });
            }
            calendarsData.push(new CalendarModel({
                "id": 'user_' + x.id,
                "name": 'user_' + x.id,
                "intervals": intervals
            }));
        });

        // @ts-ignore
        const resourceTimeRangeStore = new MyResourceTimeRangeStore({
            data: this.getResourcesAvailability(this.resources, startDate)
        });

        const project = ((window as any).project = new ProjectModel({
            transport: {
                load: {
                    url: 'assets/conf/data.json'
                }
            },
            calendarsData: calendarsData,
            resourceTimeRangeStore: resourceTimeRangeStore,
            onLoad: () => this.projectLoad(),
            autoLoad: true
        }));


        // SCHEDULER
        this.schedulerProConfig = {
            project,
            rowHeight: 50,
            autoHeight: true,
            barMargin: 4,
            eventColor: 'rgb(168, 226, 230)',
            startDate: startDate,
            endDate: endDate,
            // multiEventSelect: true,
            workingTime: {
                fromHour: 8,
                toHour: 20
            },
            weekStartDay: 1,

            tbar: {
                cls: 'toolbar-custom',
                items: [

                    {
                        text: '<<',
                        ref: 'previousRef',
                        cls: 'b-raised mat-flat-button mat-accent',
                        onClick() {
                            _this.onShiftPreviousClick();
                        }
                    },
                    {
                        text: 'Today',
                        ref: 'todayRef',
                        cls: 'b-raised mat-flat-button mat-accent',
                        onClick() {
                            _this.schedulerpro.scrollToNow({ block: "start", edgeOffset: 200 });
                        }
                    },
                    {
                        text: '>>',
                        ref: 'nextRef',
                        cls: 'b-raised mat-flat-button mat-accent',
                        onClick() {
                            _this.onShiftNextClick();
                        }
                    },
                    '->',
                    {
                        type: 'buttongroup',
                        items: [
                            {
                                text: 'Day',
                                pressed: true,
                                ref: 'dayRef',
                                cls: 'b-pressed b-raised mat-flat-button mat-accent customDayPreset presetView',
                                onClick() {
                                    _this.applyViewPreset('customDayPreset');
                                }
                            },
                            {
                                text: 'Week',
                                ref: 'weekRef',

                                cls: 'b-pressed b-raised mat-flat-button mat-accent customWeekPreset presetView',
                                onClick() {
                                    _this.applyViewPreset('customWeekPreset');
                                }
                            },
                            {
                                text: 'Month',
                                ref: 'monthRef',

                                cls: 'b-pressed b-raised mat-flat-button mat-accent customMonthPreset presetView',
                                onClick() {
                                    _this.applyViewPreset('customMonthPreset');
                                }
                            }

                        ]
                    },
                    {
                        type: 'button',
                        icon: 'b-icon b-fa-save',
                        text: `${this.translateService.instant('scheduler.save')}`,
                        cls: 'b-raised mat-flat-button mat-accent',
                        toggleable: false,
                        onClick: () => _this.onSave()
                    },
                ]
            },

            columns: [
                {
                    type: "resourceInfo",
                    text: 'Name',
                    editor: false,
                    width: 200,
                    showEventCount: true,
                    showRole: false,
                    showMeta: false

                }
            ],
            viewPreset: 'customMonthPreset',

            stripeFeature: true,
            timeRangesFeature: {
                showCurrentTimeLine: {
                    name: 'Today'
                }
            },
            nonWorkingTimeFeature: true,
            resourceNonWorkingTimeFeature: true,
            resourceTimeRangesFeature: true,
            // resourceTimeRangeStore,
            // resourceTimeRanges: resourceTimeRangeData,
            // resourceImagePath : 'assets/users/',

            createEventOnDblClick: false,
            // eventMenuFeature: false,
            eventResizeFeature: false,
            scheduleMenuFeature: false,
            scheduleContextMenuFeature: false,
            contextMenuFeature: false,
            cellMenuFeature: false,
            timeAxisHeaderMenuFeature: false,
            headerMenuFeature: false,
            headerContextMenuFeature: false,
            eventEditFeature: false,
            eventFilterFeature: false,
            eventDragSelectFeature: true,
            eventDragCreateFeature: false,
            taskEditFeature: false,
            dependenciesFeature: false,
            dependencyEditFeature: false,
            resources: this.resources,
            events: this.events,
            enableRecurringEvents: false,
            eventMenuFeature: {
                items: {
                    deleteEvent: false,
                    copyEvent: false,
                    cutEvent: false,
                    // custom item with inline handler
                    unassign: {
                        text: 'Unassign',
                        icon: 'b-fa b-fa-user-times',
                        weight: 200,
                        onItem: ({ eventRecord, resourceRecord }): void => eventRecord.unassign(resourceRecord)
                    }
                },
                processItems({eventRecord, items}) {
                    if (eventRecord.originalData.type !== 'task.production.') {
                        items.unassign.disabled = true;
                    }
                }
            }
        };

        //GRID
        this.gridUnplannedConfig = {
            stripeFeature: true,
            sortFeature: 'name',
            autoHeight: true,

            columns: [
                {
                    text: 'Unassigned tasks',
                    flex: 1,
                    field: 'name'
                }, {
                    text: 'Status',
                    width: 200,
                    align: 'right',
                    editor: false,
                    field: 'workflowStep',
                    renderer: (data) => {
                        return data.record.workflowStep ? `${this.translateService.instant('workflow.task.' + data.record.workflowStep)}` : ``;
                    }
                }, {
                    text: 'Priority',
                    width: 200,
                    align: 'right',
                    editor: false,
                    field: 'priority',
                    renderer: (data) => {
                        return data.record.priority ? `${this.translateService.instant('priority_ids.' + data.record.priority)}` : ``;
                    }
                }, {
                    text: "Startdate",
                    width: 200,
                    align: 'right',
                    editor: false,
                    field: 'startDate',
                    renderer: (data) => {
                        return data.record.startDate ? `${this.datepipe.transform(data.record.startDate, 'dd.MM.yy')}` : ``;
                    }
                }, {
                    text: "Deadline",
                    width: 200,
                    align: 'right',
                    editor: false,
                    field: 'deadline',
                    renderer: (data) => {
                        return data.record.endDate ? `${this.datepipe.transform(data.record.endDate, 'dd.MM.yy')}` : ``;
                    }
                }
            ],

            data: this.unplannedlist.length > 0 ? this.unplannedlist.map(x => new EventModel(x)) : [],

            rowHeight: 50,
            contextMenuTriggerEvent: '',
            cellEditFeature: false
        }
    }

    /**
     * Get the availabilities of all resources from scheduler as timeranges array
     * 
     * @param resources The resources from scheduler
     * @param startDate The startDate of the scheduler
     * @returns A list of time ranges
     */
    private getResourcesAvailability(resources, startDate) {
        let availabiliies = [];
        let start = typeof(startDate) === 'string'? startDate.split("T") : startDate.toISOString().split("T");
        let days =['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

        resources.forEach(resource => {

            if (resource['availability-monday'] === undefined || resource['availability-monday'] === null || 
                (resource['availability-monday'] === 0 && resource['availability-tuesday'] === 0 && resource['availability-wednesday'] === 0 
                && resource['availability-thursday'] === 0 && resource['availability-friday'] === 0 && resource['availability-saturday'] === 0 
                && resource['availability-sunday'] === 0))
            {
                let mainContact = resource['agencyMainContact'];
                if (!mainContact || mainContact.length < 1 || mainContact[0]['availability-monday'] === undefined || mainContact[0]['availability-monday'] === null || 
                    (mainContact[0]['availability-monday'] === 0 && mainContact[0]['availability-tuesday'] === 0 && mainContact[0]['availability-wednesday'] === 0 
                    && mainContact[0]['availability-thursday'] === 0 && mainContact[0]['availability-friday'] === 0 && mainContact[0]['availability-saturday'] === 0 
                    && mainContact[0]['availability-sunday'] === 0))
                {
                    let agencyEmp = resource['agencyEmployee'];
                    if (agencyEmp && agencyEmp.length > 1 && agencyEmp[0]['availability-monday'] !== undefined && agencyEmp[0]['availability-monday'] !== null && 
                        (agencyEmp[0]['availability-monday'] !== 0 || agencyEmp[0]['availability-tuesday'] !== 0 || agencyEmp[0]['availability-wednesday'] !== 0 
                        || agencyEmp[0]['availability-thursday'] !== 0 || agencyEmp[0]['availability-friday'] !== 0 || agencyEmp[0]['availability-saturday'] !== 0 
                        || agencyEmp[0]['availability-sunday'] !== 0))
                    {
                        days.forEach(day => {
                            availabiliies.push(this.setTimeRange(resource.id, start[0] + "T08:00", agencyEmp[0]['availability-' + day], day.substr(0, 2).toUpperCase()));
                        });
                    }

                } else {
                    days.forEach(day => {
                        availabiliies.push(this.setTimeRange(resource.id, start[0] + "T08:00", mainContact[0]['availability-' + day], day.substr(0, 2).toUpperCase()));
                    });
                }
            } else {
                days.forEach(day => {
                    availabiliies.push(this.setTimeRange(resource.id, start[0] + "T08:00", resource['availability-' + day], day.substr(0, 2).toUpperCase()));
                });
            }
        });

        return availabiliies;
    }

    /**
     * Set a time range from data of availability
     * 
     * @param resourceId Id from resource
     * @param startDate Start date of scheduler
     * @param hours Availability hours
     * @param day Recurring day of the week
     * @returns A Time Range object
     */
    private setTimeRange(resourceId, startDate, hours, day) {
        return {
            resourceId: resourceId,
            startDate: startDate,
            duration: 1,
            name: hours + 'h',
            style: 'color: black; background-color: rgba(255, 0, 0, ' + (1 - (hours / 8)) + ');',
            recurrenceRule: 'FREQ=WEEKLY;INTERVAL=1;BYDAY=' + day
        };
    }

    /**
     * Save all changes in the scheduler
     */
    async onSave() {
        this.schedulerpro.mask('Saving data');
        this.schedulerTeam.members.forEach(member => {
            let eventsMember = this.schedulerpro.eventStore.records.filter(x => x.getData('resourceId') == +member.id);
            const oldTasks = !!member.tasks ? member.tasks : [];
            
            member.tasks = this.getTasksFromEventModel(eventsMember, +member.id);
            member.newTasks =  member.tasks.filter(x => !oldTasks.some(y => y.id === x.id));
        });
        this.schedulerTeam.team_tasks = (this.grid.store as Store).records;

        await this.schedulerTeamsService.saveScheduler(this.schedulerTeam);

        this.schedulerTeam.members.forEach(member => {
            if (member.newTasks && member.newTasks.length > 0) {
                member.newTasks.forEach(x => 
                        this.tasksService.sendNewTaskAssignedMail(x)
                );
            }
        });
        this.schedulerpro.mask(null);
    }

    /**
     * Parse the resource events into member tasks.
     * 
     * @param events list of events to parse
     * @returns list of tasks parsed
     */
    private getTasksFromEventModel(events, responsible) {
        let tasks = [];
        events.forEach(event => {

            let newTask = {
                'endDate': event.originalData.endDate,
                'endDateActual': this.datepipe.transform(event.getData('endDate'), 'yyyy-MM-ddTHH:mm:ss') + 'Z',
                'id': event.getData('id'),
                'name': event.getData('name'),
                'startDate': event.originalData.startDate,
                'startDateActual': this.datepipe.transform(event.getData('startDate'), 'yyyy-MM-ddTHH:mm:ss') + 'Z',
                'type': event.getData('type'),
                'responsible': responsible
            }

            if (event.getData('plannedHours')) newTask['plannedHours'] = event.getData('plannedHours');
            tasks.push(newTask);
        });

        return tasks;
    }

    private applyViewPreset(view) {

        let list = document.getElementsByClassName('presetView');

        for (var i = 0; i < list.length; i++) {
            const element = list[i] as HTMLElement;
            element.classList.remove('b-pressed');

        }

        list = document.getElementsByClassName(view);

        for (var i = 0; i < list.length; i++) {
            const element = list[i] as HTMLElement;
            element.classList.add('b-pressed');
        }

        this.schedulerpro.viewPreset = view;
    }

    /**
     * Moves horizontally the gantt view one unit time (day, week, month) to the left
     */
    onShiftPreviousClick() {
        this.schedulerpro.shiftPrevious();
    }

    /**
     * Moves horizontally the gantt view one unit time (day, week, month) to the right
     */
    onShiftNextClick() {
        this.schedulerpro.shiftNext();
    }
}
