import { AppSettings } from "../app.settings";

/**
 * This class defines the structure of agencies
 */

export class Agency {
    /**
     * id of the agency
     */
    id: number;
    /**
     * name of the agency
     */
    name: string;
    /**
     * domain of the agency
     */
    domain: string = AppSettings.DOMAIN;
    /**
     * type of the agency
     */
    type: string;
    /**
     * email of the agency
     */
    pcEmail: Email;
    /**
     * phone of the agency
     */
    phone: Phone;
    /**
     * {@link Login} of the agency
     */
    login: Login;
    /**
     * Array of {@link Address} of the agency
     */
    address: Array<Address> = [];
    /**
     * operating language of the agency
     */
    opLanguages: Array<string>;
    /**
     * number of employees of the agency
     */
    numEmployees: string;
    /**
     * vat of the agency
     */
    vat: string;
    /**
     * bio of the agency
     */
    bio: string;
    /**
     * objectives of the agency
     */
    objectives: Array<string>;
    /**
     * expertises of the agency
     */
    expertises: Array<string>;
    /**
     * link of the agency
     */
    link: string;
    /**
     * workflow of the agency
     */
    workflow: number = AppSettings.WORKFLOW_COMPANY;
    /**
     * workflowStep of the agency
     */
    workflowStep: number = AppSettings.WORKFLOW_COMPANY_PENDING;
}

/**
 * This class defines the structure of Email data
 */
export class Email {
    /**
     * type of email
     */
    type: string = 'work';
    /**
     * email of email
     */
    email: string;
}

/**
 * This class defines the structure of phone data
 */
export class Phone {
    /**
     * type of phone
     */
    phoneType: string = 'work';
    /**
     * phone number of phone
     */
    phoneNumber: string;
}

/**
 * This class defines the structure of Login data
 */
export class Login {
    /**
     * type of login
     */
    type: string = 'website';
    /**
     * user login
     */
    login: string;
    /**
     * password of login
     */
    password: string;
}

/**
 * This class defines the structure of address data
 */
export class Address {
    /**
     * type of address
     */
    type: string = 'work';
    /**
     * Street of address
     */
    street: string;
    /**
     * Street number of address
     */
    /**
     * streetNumber of address
     */
    streetNumber: string;
    /**
     * postcode of address
     */
    postcode: string;
    /**
     * city of address
     */
    city: string;
    /**
     * country of address
     */
    country: string;
}