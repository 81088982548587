import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { AppSettings } from 'app/shared/app.settings';
import { UserService } from '../user/user.service';
import { WorkflowService } from '../workflows/workflow.service';
import { RolesService } from '../user/roles.service';
import { NotificationsService } from '../notifcations/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Mail } from 'app/shared/model/mail.model';
import { MailService } from '../mail/mail.service';
import { MsteamsService } from '../msteams/msteams.service';
import { MsTeamsMessageCard } from 'app/shared/model/msteams-message-card.model';
import { environment } from 'environments/environment';
import { ProfessionalTeamService } from '../professionalTeam/professional-team.service';

/**
 * Service defined to manage all operations needed with Tasks ({@link Task})
 *
 * The task listings are defined in a separate service for the listing, {@link ListsService}.
 */
@Injectable({
  providedIn: 'root'
})
export class TasksService {
  /**
   * Task workflow planned
   */
  static readonly TASK_WORKFLOW_PLANNED: number = 10;
  /**
   * Task workflow open
   */
  static readonly TASK_WORKFLOW_OPEN: number = 20;
  /**
   * Task workflow in progress
   */
  static readonly TASK_WORKFLOW_IN_PROGRESS: number = 30;
  /**
   * Task workflow verification
   */
  static readonly TASK_WORKFLOW_VERIFICATION: number = 40;
  /**
   * Task workflow rejected
   */
  static readonly TASK_WORKFLOW_REJECTED: number = 45;
  /**
   * Task workflow approved
   */
  static readonly TASK_WORKFLOW_APPROVED: number = 50;
  /**
   * Task workflow done
   */
  static readonly TASK_WORKFLOW_DONE: number = 60;
  /**
   * Task workflow on hold
   */
  static readonly TASK_WORKFLOW_ON_HOLD: number = 70;
  /**
   * states to which the task can be changed by a user a task
   */
  static readonly CHANGE_STATUS = [
    TasksService.TASK_WORKFLOW_PLANNED,
    TasksService.TASK_WORKFLOW_APPROVED,
    TasksService.TASK_WORKFLOW_ON_HOLD
  ];
  /**
   * Types of request tasks
   */
  static readonly REQUEST_TASK_TYPES = [
    { name: 'Cost estimate', key: 'task.cost_estimate.', newFile: true, materialsTarget: ['costestimate'], addMaterial: true, materialsSelection: ['costestimate'] },
    { name: 'Questions', key: 'task.questions.', newFile: true, materialsTarget: ['customerdata'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Others', key: 'task.others.', newFile: true, materialsTarget: ['customerdata'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Timing', key: 'task.timing.', newFile: false, materialsTarget: [], addMaterial: false, materialsSelection: [] },
    { name: 'Capacities', key: 'task.capacities.', newFile: false, materialsTarget: [], addMaterial: false, materialsSelection: [] },
  ];
  /**
   * Types of extenal tasks
   */
  static readonly EXTERNAL_TASK_TYPES = [
    { name: 'Cost estimate', key: 'task.cost_estimate.', newFile: true, visible: true, materialsTarget: ['costestimate'], addMaterial: true, materialsSelection: ['costestimate'] },
    { name: 'Coordination', key: 'task.coordination.', newFile: true, visible: true, materialsTarget: ['asset'], addMaterial: true, materialsSelection: ['asset'] },
    { name: 'Final Approval', key: 'task.final_approval.', newFile: true, visible: true, materialsTarget: ['finaldata'], addMaterial: true, materialsSelection: ['finaldata'] },
    { name: 'Questions', key: 'task.questions.', newFile: true, visible: true, materialsTarget: ['customerdata'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Metadata', key: 'task.metadata.', newFile: false, visible: true, materialsTarget: [], addMaterial: false, materialsSelection: [] },
    { name: 'Milestone', key: 'task.milestone.', newFile: false, visible: true, materialsTarget: [], addMaterial: false, materialsSelection: [] },
    { name: 'Others', key: 'task.others.', newFile: true, visible: true, materialsTarget: ['customerdata'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
  ];
  /**
   * Types of internal tasks
   */
  static readonly INTERNAL_TASK_TYPES = [
    { name: 'Text', key: 'task.text.', newFile: true, visible: false, materialsTarget: ['consulting'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Proof reading', key: 'task.proof_reading.', newFile: true, visible: false, materialsTarget: ['consulting'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Creation', key: 'task.creation.', newFile: true, visible: false, materialsTarget: ['consulting'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Adaptation', key: 'task.adaptation.', newFile: true, visible: false, materialsTarget: ['consulting'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Final art', key: 'task.final_art.', newFile: true, visible: false, materialsTarget: ['consulting'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Image editing', key: 'task.image_editing.', newFile: true, visible: false, materialsTarget: ['consulting'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Translation', key: 'task.translation.', newFile: true, visible: false, materialsTarget: ['consulting'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Moving image', key: 'task.moving_image.', newFile: true, visible: false, materialsTarget: ['consulting'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Programming', key: 'task.programming.', newFile: true, visible: false, materialsTarget: ['consulting'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Online', key: 'task.online.', newFile: true, visible: false, materialsTarget: ['consulting'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] },
    { name: 'Production', key: 'task.production.', newFile: true, visible: true, materialsTarget: ['asset'], addMaterial: true, materialsSelection: ['asset'] },
  ];
  /**
   * Types of customer tasks
   */
  static readonly CUSTOMER_TASKS = [
    { name: 'Questions', key: 'task.questions.', newFile: true, visible: true, materialsTarget: ['customerdata'], addMaterial: true, materialsSelection: ['finaldata', 'customerdata', 'consulting', 'costestimate'] }
  ];
  /**
   * Types of production tasks
   */
  static readonly PRODUCTION_TASKS = [
    { name: 'Production', key: 'task.production.', newFile: true, visible: true, materialsTarget: ['asset'], addMaterial: true, materialsSelection: ['asset'] }
  ];
  /**
   * All types of tasks
   */
  static readonly ALL_TASKS = [...TasksService.EXTERNAL_TASK_TYPES, ...TasksService.INTERNAL_TASK_TYPES, ...TasksService.REQUEST_TASK_TYPES];
  /**
   * Priorities of tasks
   */
  static readonly PRIORITIES = [
    // { key: 1, name: "Urgent" },
    { key: 2, name: "High" },
    { key: 3, name: "Normal" },
    { key: 4, name: "Low" }
  ];

  /**
   * this defines the different statuses that a normal task can have and what functionality
   * the different user roles have in each status.
   */
  static readonly TASK_STATUS = [
    { key: 'open', change: RolesService.POWER_ROLE, selectable: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE] },
    { key: 'approved', change: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE], selectable: RolesService.POWER_ROLE },
    { key: 'rejected', change: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE], selectable: RolesService.POWER_ROLE },
  ];

  /**
   * this defines the different statuses that a request task can have and what functionality
   * the different user roles have in each status.
   */
  static readonly REQUEST_TASK_STATUS = [
    { key: 'open', change: [RolesService.POWER_ROLE, RolesService.OPERATOR_ROLE], selectable: [RolesService.OPERATOR_ROLE] },
    { key: 'approved', change: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE,], selectable: [RolesService.OPERATOR_ROLE, RolesService.POWER_ROLE] },
    { key: 'rejected', change: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE,], selectable: [RolesService.OPERATOR_ROLE, RolesService.POWER_ROLE] },
  ];
  /**
   * 
   * @param hcmsService Service with the functions related to the censhare.
   * @param usersService Service with the functions related to the users. 
   * @param workflowsService Service with the functions related to the workflows.
   * @param rolesService Service with the functions related to the roles.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   * @param mailService Service with the functions related to the mails.
   * @param msteamService Service sith the functions related to operate with messages.
   */
  constructor(private hcmsService: HCMSService,
    private usersService: UserService,
    private workflowsService: WorkflowService,
    private rolesService: RolesService,
    private notifications: NotificationsService,
    private translateService: TranslateService,
    private mailService: MailService,
    private msteamService: MsteamsService,
    private teamsService: ProfessionalTeamService) { }

  /**
   * Obtain the task entity rout depending on asset (task) type
   *
   * @param {string} assetType The task type
   * @returns The entity route required.
   */
  public getTaskEntity(assetType: string) {
    const TASK_ENTITY_OPTIONS = {
      'task.cost_estimate.': 'entity/task_cost_estimate',
      'task.coordination.' : 'entity/task_coordination',
      'task.final_approval.' : 'entity/task_final_approval',
      'task.questions.' : 'entity/task_questions',
      'task.metadata.' : 'entity/task_metadata',
      'task.milestone.' : 'entity/task_milestone',
      'task.others.' : 'entity/task_others',
      'task.text.': 'entity/task_text',
      'task.proof_reading.': 'entity/task_proof_reading',
      'task.creation.': 'entity/task_creation',
      'task.adaptation.': 'entity/task_adaptation',
      'task.final_art.': 'entity/task_final_art',
      'task.image_editing.': 'entity/task_image_editing',
      'task.translation.': 'entity/task_translation',
      'task.moving_image.': 'entity/task_moving_image',
      'task.programming.': 'entity/task_programming',
      'task.online.': 'entity/task_online',
      'task.timing.': 'entity/task_timing',
      'task.capacities.': 'entity/task_capacities',
      'task.production.': 'entity/task_production',
    };
    const TASK_ENTITY_DEFAULT = 'entity/task';

    return TASK_ENTITY_OPTIONS[assetType] || TASK_ENTITY_DEFAULT;
  }
  /**
   * Get work folders
   * @param action action 
   * @param workList workList
   * @returns work folders
   */
  getWorkFolders(action, workList) {
    let folders: Array<number> = [];
    workList.forEach(folder => {
      if (folder && folder === 'asset') {
        folders.push(+action.id);
      } else if (action[folder] && action[folder].length > 0) {
        folders.push(+action[folder][0].id);
      }
    });
    return folders;
  }

  /**
   * checks whether a task is internal
   * @param type type of task
   * @returns true if the task is interal
   */
  isInternal(type): boolean {
    return TasksService.INTERNAL_TASK_TYPES.map(x => x.key).filter(x => x === type).length > 0;
  }

  /**
   * Create new task
   * @param task task data
   * @returns the new task
   */
  async newTask(task) {
    
    let responsible;
    if(task.responsible){
      responsible = this.usersService.getUserData(task.responsible);
      if(!responsible){
        let team = await this.teamsService.getTeamById(task.responsible);
        responsible = responsible? responsible.display_name :team.plain().name;
      }
    }

    let route = this.getTaskEntity(task.type);
    const urls: any = await this.getWebhooksURLs(task);
    
    return await this.hcmsService.get().all(route).post(task).toPromise().then(asset => {
      let link = '/tasks/detail/' + asset.id;

      if (asset.responsible) {
        this.sendNewTaskAssignedMail(asset);
      }

      const title = this.translateService.instant('task_created');
      const linkLabel = this.translateService.instant('teamsconnector.task.link');
      const text = this.translateService.instant('teamsconnector.task.text',
        {
          from: this.usersService.getCurrentUser().display_name,
          to: asset.responsible ? responsible  : '',
          taskname: asset.name,
          jobname: task.jobs && task.jobs.length > 0 ? task.jobs.map(x => x.name).join(',') : ''
        }
      );

      if (!asset.responsible) {
        text.replace('to **<at>@</at>** ', '');
      }

      const msteamMessageCard: MsTeamsMessageCard = new MsTeamsMessageCard(title, text, linkLabel, environment.baseAppUrl + link, AppSettings.BASECOLOR);


      urls.forEach(url => {
        this.msteamService.sendMessage(url, msteamMessageCard);
      });

      
      
      return asset;
    });

  }

  private async getWebhooksURLs(task) {
    let urls: string[] = [];
    for (const jobId of task.parents) {
      await this.usersService.getTeam(jobId).then(team => {
        let teamMerge = [].concat(team.owner_rel, team.projectmanager_rel, team['co-owner'], team['co-manager'], team['decision-maker'], team.contributor, team.reader, team.production, team.mainManager);

        teamMerge = Array.from(new Set(teamMerge = teamMerge.filter(x => x)));
        teamMerge = teamMerge.filter(x => x);

        teamMerge = teamMerge.map(x => this.usersService.getUserData(x)).filter(x => x && x.agencyEmployee && x.agencyEmployee.length > 0);

        teamMerge.forEach(x => {
          x.agencyEmployee.filter(y => y.webhook).forEach(y => {
            urls.push(y.webhook);
          });
        });
      });
    }
    urls = Array.from(new Set(urls));

    return urls;
  }

  /**
   * Obtain all tasks (not request tasks) by job id
   *
   * @param actionid The job id
   * @param page
   * @returns A list of a tasks belonging to a job.
   */
  public getTasksByAction(actionid, page = 0) {
    return this.hcmsService.get().one('entity/task?query=((!request_task %7C request_task=false)%26parents=' + actionid + ')&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Fill all tasks data
   * @param currentUser the current user
   * @param tasks task of user
   * @returns all tasks with completed information
   */
  public fillData(currentUser, tasks) {
    tasks.forEach(task => this.fillTaskData(currentUser, task));
    return tasks;
  }

  /**
   * Fill a task data
   * @param currentUser the current user
   * @param task the task
   */
  public fillTaskData(currentUser, task) {

    task.item_name = task.name;
    task.checked = false;

    if (currentUser.watchlist) {
      task.watchlist = currentUser && currentUser.watchlist.indexOf(task.id) > -1;
    }

    task.owner = this.usersService.getRelationUser(task, UserService.OWNER, UserService.REMOVE_OWNER);
    task.createdByNewO = this.usersService.getUserData(task.createdByNew);

    task.targetsO = [];

    if (task.targets) {
      task.targets.forEach(target => {
        task.targetsO.push(this.usersService.getUserData(target));
      });
    } else {
      task.targets = [];
    }


    if (task.workflow && task.workflowStep) {
      task.step = this.workflowsService.getWorkflowStep(task.workflow, task.workflowStep);
      task.state = this.workflowsService.getStateWithWorkflowStep(task.workflow, task.workflowStep);
      if (task.step) task.progress = task.step;
    }

    if (task.responsible) {
      task.responsibleData = this.usersService.getUserData(task.responsible);
    } else if (task.owner) {
      task.responsibleData = this.usersService.getUserData(task.owner);
    }

  }

  /**
   * Obtain all tasks visible to the logged user.
   *
   * @param page
   * @returns A list of a tasks
   */
  public getTasks(page = 0) {
    let query: string = '';
    let user = this.usersService.getCurrentUser();
    if (!this.rolesService.checkRole(user, RolesService.PRODUCTION_ROLE) && !this.rolesService.checkRole(user, RolesService.PROJECT_MANAGER_ROLE, true))
      query = this.filterTaskByUserRelation(); // + '%26'

    // return this.hcmsService.get().one('entity/task?query=(' + query + '(!request_task %7C request_task=false))&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
    return this.hcmsService.get().one('entity/task?query=' + query + '&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Filter task by user relation
   * @param id @ignore
   * @returns ther relation query
   */
  filterTaskByUserRelation(id: number = 0): string {
    if (id == 0) id = this.usersService.getCurrentUser().id;
    const query = "owner_rel=" + id + "|targets=" + id;

    return !this.rolesService.isPowerUser(this.usersService.getCurrentUser()) ? query : query + "|(responsible=" + id + "%26 request_task=true)";
  }

  public async getTasksByResponsibles(ids) {

    let query = 'entity/task?query=';
    ids.forEach(id => query += 'responsible='+id+'|');
    query = query.substring(0, query.length - 1);
    const result = await this.hcmsService.get().one(query).get().toPromise();

    return result.result;
  }

  /**
   * Filter task by user relation
   * @param id @ignore
   * @returns ther relation query
   */
   filterTaskByUserResponsible(id: number = 0): string {
    if (id == 0) id = this.usersService.getCurrentUser().id;

    return "(responsible=" + id + ")";
  }

  /**
   * Save a task
   * @param task the task 
   * @returns the saved task
   */
  public saveTask(task) {
    return this.hcmsService.get().all('entity/task').post(task).toPromise().then(task => {

      const title = this.translateService.instant('task_created');

      let link = ['/jobs', 'detail', task.id];
      this.notifications.show(title, link, this.translateService.instant('open_task'), 20000);

      if (task.owner) {
        this.sendNewTaskAssignedMail(task);
      }

      return task;
    });
  }

  /**
   * Edit task
   * @param taskUpdate task updated
   * @returns the task updated
   */
  public editTask(taskUpdate) {
    return this.getTask(taskUpdate.id).then(async x => {
      let responsible = 0;
      let updateResponsible = 0;

      const urls: any = await this.getWebhooksURLs(x);

      if (x.responsible) {
        responsible = x.responsible;
      }
      
      if (taskUpdate.responsible) {
        updateResponsible = taskUpdate.responsible;
      }
      
      let sendMailToNewResponsible = responsible != updateResponsible;
      
      x.route = this.getTaskEntity(x.type);
      x = Object.assign(x, this.hcmsService.get().copy(taskUpdate).plain());
      
      return x.save().toPromise().then(task => {

        const link = '/tasks/detail/' + x.id;
        let title = this.translateService.instant('teamsconnector.editedtask.title');
        let linkLabel = this.translateService.instant('teamsconnector.editedtask.link');
        let text = this.translateService.instant('teamsconnector.editedtask.text',
          {
            from: this.usersService.getCurrentUser().display_name,
            to: this.usersService.getUserData(task.responsible)? this.usersService.getUserData(task.responsible).display_name : '',
            taskname: task.name,
            jobname: task.jobs && task.jobs.length > 0 ? task.jobs.map(x => x.name).join(',') : ''
          }
        );

        const msteamMessageCard: MsTeamsMessageCard = new MsTeamsMessageCard(title, text, linkLabel, environment.baseAppUrl + link, AppSettings.BASECOLOR);

        urls.forEach(url => {
          this.msteamService.sendMessage(url, msteamMessageCard);
        });


        if (sendMailToNewResponsible) {
          this.sendNewTaskAssignedMail(task);
        }

        return task;
      });
    });
  }

  /**
   * Mark all tasks as open
   * @param tasks all tasks
   */
  public async uploadTaskUnopened(tasks) {
    if(tasks.length > 0){

      for (let current of tasks) {
        let task = await this.getTask(current.id, current.type);
        task.unopened = false;
        await task.put().toPromise();
      }
    }
  }

  /**
   * Sends an email that the task has been assigned to you.
   * @param task the task assigned
   */
  sendNewTaskAssignedMail(task) {
    let mail: Mail = new Mail(AppSettings.MAIL_NEWTASKASSIGNED + '_' + task.id + '_' + +task.responsible, AppSettings.MAIL_NEWTASKASSIGNED, +task.id, +task.responsible);
    this.mailService.saveMail(mail);
  }

  /**
   * Obtain a task by id. The type is necesary to get first the rout to build de request.
   *
   * @param id The task id
   * @param {string} type The task type
   * @returns The task required
   */
  public async getTask(id, type = 'task.') {
    let route = this.getTaskEntity(type);
    return this.hcmsService.get().one(route, +id).get().toPromise();
  }

  /**
   * Update task files
   * @param asset the task
   * @param type the task type
   * @param files the files of tasks
   * @returns the request
   */
  public updateTaskFiles(asset, type, files) {

    return this.getTask(asset.id, type).then(updated => {

      if (!updated.files) updated.files = [];
      updated.files = [...updated.files, ...files];

      return updated.put();
    });
  }

  /**
   * Change workflow of task
   * @param task the task
   * @param step new workflow step
   * @returns the request
   */
  changeWorkflow(task, step) {
    return this.getTask(task.id, task.type).then(updated => {
      updated.workflow = AppSettings.WORKFLOW_TASK;
      updated.workflowStep = +step;
      return updated.put().toPromise().then( taskUpdated => {
        if(+step === TasksService.TASK_WORKFLOW_APPROVED || +step === TasksService.TASK_WORKFLOW_REJECTED){
          this.sendStatusFeedbackEmail(taskUpdated.plain())
        }
      });
    });
  }

  /**
   * Change the responsible of task
   * @param task the task
   * @param responsible the new responsible
   * @param step workflow step
   * @returns the request
   */
  changeResponsible(task, responsible: number, step: number) {
    return this.getTask(task.id, task.type).then(updated => {
      updated.responsible = responsible;
      updated.workflow = AppSettings.WORKFLOW_TASK;
      updated.workflowStep = +step;
      return updated.put();
    });
  }

  /**
   * Update the task deadline
   *
   * @param taskId The task id
   * @param {string} taskType The task type, needed to get the specific route
   * @param {string} deadline The deadline of the task
   * @returns The task updated
   */
  public saveDeadline(taskId, taskType, deadline) {
    return this.getTask(taskId, taskType).then(updated => {
      updated.deadline = deadline;
      return updated.put();
    });
  }

  /**
   * Update the task start date.
   *
   * @param taskId The task id
   * @param {string} taskType The task type, needed to get the specific route
   * @param {string} startDate The start date of the task
   * @returns The task updated
   */
  public saveStartDate(taskId, taskType, startDate) {
    return this.getTask(taskId, taskType).then(updated => {
      updated.startDate = startDate;
      return updated.put();
    });
  }

  /**
   * Update the task start date and deadline.
   *
   * @param taskId The task id
   * @param {string} taskType The task type, needed to get the specific route
   * @param {string} startDate The start date of the task
   * @param {string} deadline The deadline of the task
   * @returns The task updated
   */
  public async saveStartDateAndDeadline(taskId, taskType, startDate, deadline) {
    return await this.getTask(taskId, taskType).then(async updated => {
      updated.startDate = startDate;
      updated.deadline = deadline;
      return await updated.put().toPromise().catch((error) => {
        console.error(error);
      });
    });
  }

  /**
   * Set the next asset and/or previous asset of a task
   * 
   * @param taskId the task id
   * @param {string} taskType The task type, needed to get the specific route
   * @param nextAsset The next asset id
   * @param previousAsset the previous asset id
   * @returns The task updated
   */
  public saveRelatedAssets(taskId, taskType, nextAsset, previousAsset) {

    return this.getTask(taskId, taskType).then(updated => {
      if (nextAsset) updated.nextAsset = nextAsset;
      if (previousAsset) updated.previousAsset = previousAsset;

      return updated.put().toPromise();
    });
  }
  private sendStatusFeedbackEmail(task){
    if(!task.targets) return;
    task.targets = task.targets.map(target => this.usersService.getUserData(target))
    let currentUser = this.usersService.getCurrentUser();
    let usersToNotify: any[]= []
    if(task.workflowStep === TasksService.TASK_WORKFLOW_APPROVED && this.rolesService.checkRole(currentUser, RolesService.OPERATOR_ROLE)){
      usersToNotify =task.targets.filter( target => target.roles.includes(RolesService.PROJECT_MANAGER_ROLE) || target.roles.includes(RolesService.POWER_ROLE))
    }else{
      usersToNotify =task.targets.filter( target => target.roles.includes(RolesService.PROJECT_MANAGER_ROLE))
    }

    if( task.type === "task.cost_estimate." && task.request_task && usersToNotify.length > 0){
      
      usersToNotify.forEach((user) => {
          let template;
          if( task.workflowStep === TasksService.TASK_WORKFLOW_APPROVED ) {
            template = this.rolesService.checkRole(user, RolesService.PROJECT_MANAGER_ROLE) ? AppSettings.MAIL_COST_ESTIMATE_MANAGER_APPROVED : AppSettings.MAIL_COST_ESTIMATE_APPROVED
          } else {
            template = AppSettings.MAIL_COST_ESTIMATE_MANAGER_DECLINED;
          }
          console.log(template);
          let mail: Mail = new Mail(template + '_' + task.id + '_' +user.id, template, +task.id, +user.id);
          this.mailService.saveMail(mail).then(()=>{ })
        })
    }
  }
}