import { Component, OnInit, Input } from '@angular/core';
import { RecentlyViewedService } from 'app/shared/services/recentlyViewed/recently-viewed.service';
/**
 * @ignore
 */
@Component({
  selector: 'app-recently-viewed',
  templateUrl: './recently-viewed.component.html',
  styleUrls: ['./recently-viewed.component.scss']
})
export class RecentlyViewedComponent implements OnInit {

  @Input() key: string;
  elements = [];
  constructor(private recentlyViewedService: RecentlyViewedService) {}

  ngOnInit() {
    this.elements = this.recentlyViewedService.getList(this.key);
  }

}
