import { Injectable } from '@angular/core';
import { LiquidCache } from 'ngx-liquid-cache';
import { Observable } from 'rxjs';
import { AppSettings } from 'app/shared/app.settings';
import { OCService } from '../satellites/oc.service';

/**
 * @ignore
 */
@Injectable({
  providedIn: 'root'
})
export class DomainService {

  main_domain;
  main_domain2;
  domains = [];
  domains2 = [];

  constructor(private ocService: OCService) {
    this.getCachedMainDomain().subscribe(x => this.main_domain = x.find(domain => domain.pathid === AppSettings.DOMAIN));
    this.getCachedMainDomain2().subscribe(x => this.main_domain2 = x.find(domain => domain.pathid === AppSettings.DOMAIN2));
    this.getCachedDomains().subscribe(x => this.domains = x);
    this.getCachedDomains2().subscribe(x => this.domains2 = x);
  }

  @LiquidCache('main_domain')
  private getCachedMainDomain(): Observable<any> {
    return this.ocService.get().all('tables/domain/enabled/1/pathid/' + AppSettings.DOMAIN + '*').getList();
  }

  @LiquidCache('main_domain2')
  private getCachedMainDomain2(): Observable<any> {
    return this.ocService.get().all('tables/domain2/enabled/1/pathid/' + AppSettings.DOMAIN2 + '*').getList();
  }

  @LiquidCache('domains')
  private getCachedDomains(): Observable<any> {
    return this.ocService.get().all('tables/domain/enabled/1/parent/' + AppSettings.DOMAIN + '*').getList();
  }

  @LiquidCache('domains2')
  private getCachedDomains2() : Observable<any> {
    return this.ocService.get().all('tables/domain2/enabled/1/parent/' + AppSettings.DOMAIN2 + '*').getList();
  }

  getMainDomain() {
    return this.main_domain;
  }

  getMainDomain2() {
    return this.main_domain2;
  }

  getDomains() {
    return this.domains;
  }

  getDomains2() {
    return this.domains2;
  }

  getSubdomains(parent: string) : Array<any> {
    return this.domains.filter(x => x.parent == parent).sort((x,y) => (x.name > y.name ? 1 : -1 ));
  }

  getSubdomains2(parent: string) : Array<any> {
    return this.domains2.filter(x => x.parent == parent).sort((x,y) => (x.name > y.name ? 1 : -1 ));
  }

  getDomain(key) {
    if (this.main_domain && key === this.main_domain.pathid) return this.main_domain
    return this.domains.find(x => x.pathid ===  key);
  }

  getDomain2(key) {
    return this.domains2.find(x => x.pathid ===  key);
  }
  }
