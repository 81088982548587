import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { AppSettings } from 'app/shared/app.settings';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';
import { Material } from 'app/shared/model/material.model';
import { Shortcut, ShortcutBriefing } from 'app/shared/model/shortcut.model';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { BriefingService } from 'app/shared/services/briefing/briefing.service';
import { MaterialsService } from 'app/shared/services/materials/materials.service';
import { MimetypeService } from 'app/shared/services/mimetype/mimetype.service';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { UserService } from 'app/shared/services/user/user.service';
import { MomentCustomAdapter } from 'app/shared/utils/MomentCustomAdapter';
import Utils from 'app/shared/utils/utils';
import * as moment from 'moment';
import { NgxTimepickerFieldComponent } from 'ngx-material-timepicker';
import * as CustomEditor from '../../../../ckeditor/ckeditor';

/**
 * Constant with the formats of the date
 */
export const DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: { dateInput: 'DD/MM/YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
};

/**
 * This component contains the shortcut form for the creation of a new {@link Briefing} 
 */
@Component({
  selector: 'app-shortcut-briefing',
  templateUrl: './shortcut-briefing.component.html',
  styleUrls: ['./shortcut-briefing.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentCustomAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class ShortcutBriefingComponent implements OnInit {
    /** the id of the briefing if it is being edited */
  @Input() assetId;
  /** This event executes a function in the parent component that close the dialog 
   * and remains in the component {@link BriefingsComponent}  */
  @Output() reload = new EventEmitter<void>();

   /** {@link NgxTimepickerFieldComponent} used to control the start time picker.  */
  @ViewChild('startTime', { static: true }) startTime: NgxTimepickerFieldComponent;

  /** {@link NgxTimepickerFieldComponent} used to control the end time picker.  */
  @ViewChild('endTime', { static: true }) endTime: NgxTimepickerFieldComponent;

  /** the default start hour  */
  private DEFAULT_STARTDATE_HOUR = "00";

    /** the default start minute  */
  private DEFAULT_STARTDATE_MINUTE = "00";

    /** the default start second  */
  private DEFAULT_STARTDATE_SECOND = "00";

  /** the default deadline hour  */
  private DEFAULT_DEADLINE_HOUR = '23';

  /** the default deadline minute  */
  private DEFAULT_DEADLINE_MINUTE = '59';

  /** the default deadline second  */
  private DEFAULT_DEADLINE_SECOND = '59';

  AppSettings = AppSettings;

  /** {@link CustomEditor} that shows the ckEditor */
  public Editor = CustomEditor;

  /** Form with the data of the briefing */
  form: FormGroup;

  /**array with the selected files to add to the briefing */
  files = [];

  /** Array with the priorities that a briefing can have  */
  priorities = [];

  /** Boolean indicating if a new briefing is being created, keeps the newly created modal open */
  creatingBriefing : boolean = false;

  /** @ignore */
  onDrag : boolean = false;

  /** The default time */
  defaultTime : string = '00:00';
  
  /** {@link User} with the current user that is logged in. */
  currentUser;

  /** Boolean indicating an error in the deadline date of the briefing */
  deadlineError: boolean = false;

  /** @ignore */
  deadlineBiggerError: boolean = false;

  /** Boolean indicating an error in the start date of the briefing */
  startDateError: boolean = false;
  
  /** Boolean indicating  if the type of briefing is defined*/
  typeError: boolean = false;

  /** Boolean indicating  if the name of briefing is defined*/
  nameError: boolean = false;
  
  /**Boolean, is true if this function has been executed by the click event of the save button */
  isSaveButton: boolean = false;

  /** the {@link briefing} object inside the one being edited */
  editableBriefing;
  
  /** array with deleted files */
  deleteFiles = [];
  
  /** Boolean indicating  if the type of briefing is job*/
  showProject : boolean = false;

  /** All {@link project} that the current user has */
  projects = [];
  /**Current date */
  today: Date = new Date();
  /**
   * The constructor
   * @param datepipe {@link DatePipe} with the date pipe used to format dates.
   * @param formBuilder {@link FormBuilder} with the functions related to the forms.
   * @param dialog {@link MatDialog} dialog service.
   * @param projectsService {@link ProjectsService} with the functions related to the projects.
   * @param materialsService {@link MaterialsService} with the functions related to the materials.
   * @param briefingService {@link BriefingService} with the functions related to the briefings.
   * @param mimetypeService {@link MimetypeService} with the functions related to the mime type.
   * @param usersService {@link UserService} with the functions related to the users.
   * @param assetService {@link AssetService} with the functions related to the assets.
   */
  constructor(public datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private projectsService: ProjectsService,
    private materialsService: MaterialsService,
    private briefingService: BriefingService,
    private mimetypeService: MimetypeService,
    private usersService: UserService,
    private assetService: AssetService
    ) {
      this.defaultTime = this.datepipe.transform(new Date(), 'HH') + ':' + this.datepipe.transform(new Date(), 'mm');

      this.form = this.formBuilder.group({
        'briefingtype': [null, Validators.compose([Validators.required])],
        'briefingname': [null, Validators.compose([Validators.required])],
        'briefingproject': [null, Validators.compose([])],
        'startDate': [moment.utc(), Validators.compose([])],
        'startDateTime': [null, Validators.compose([])],
        'deadline': [null, Validators.compose([])],
        'deadlineTime': [null, Validators.compose([])],
        'info': [null, Validators.compose([])],
        'priority': ['normal', Validators.compose([])]
      });
     }
  /**Initialises the component and if editing gets the briefing data and completes the form. */
  ngOnInit() {
    this.currentUser = this.usersService.getCurrentUser();
    this.startTime.registerOnChange((event) => {
      this.form.controls['startDateTime'].patchValue(event);
    });
    this.endTime.registerOnChange((event) => {
      this.form.controls['deadlineTime'].patchValue(event);
    });

    this.assetService.getPriorityValues().subscribe(data => {
      this.priorities = data;
      this.priorities = this.priorities.filter(x => x.enabled && x.value_key !== 'urgent');
      this.priorities.sort((x,y) => (x.name.toUpperCase() > y.name.toUpperCase() ? 1 : -1));
    });

    this.projectsService.getProjectsByUser(this.currentUser.id).then(data => {
      if (data && data.result && data.result.length > 0) this.projects = data.result;
    });

    if (this.assetId) {
      this.briefingService.getShortcut(this.assetId).then(data => {
        if (data && data.result && data.result.length > 0) {
          this.editableBriefing = data.result[0];
          this.today = null;
          this.form.patchValue({
            briefingtype: this.editableBriefing.type == 'order.' ? 'action' : 'project',
            briefingname: this.editableBriefing.name,
            briefingproject: this.editableBriefing.type == 'order.' && this.editableBriefing.parents && this.editableBriefing.parents.length > 0 ? this.editableBriefing.parents[0] : '',
            startDate: this.editableBriefing.startDate ? this.editableBriefing.startDate : null,
            startDateTime: null,
            deadline: this.editableBriefing.deadline ? this.editableBriefing.deadline : null,
            deadlineTime: null,
            info: this.editableBriefing.info ? this.editableBriefing.info: null,
            priority: this.editableBriefing.priorityCntr ? this.editableBriefing.priorityCntr : null
          });

          if (this.editableBriefing.startDate) {
            let startDate = this.datepipe.transform(this.editableBriefing.startDate, 'yyyy-MM-dd', '+0000');
            this.form.controls['startDate'].patchValue(startDate);
            this.startTime.changeHour(+this.datepipe.transform(this.editableBriefing.startDate, 'HH', 'GMT+00:00'));
            this.startTime.changeMinute(+this.datepipe.transform(this.editableBriefing.startDate, 'mm'));
          }

          if (this.editableBriefing.deadline) {
            let date = this.datepipe.transform(this.editableBriefing.deadline, 'yyyy-MM-dd', '+0000');
            this.form.controls['deadline'].patchValue(date);
            this.endTime.changeHour(+this.datepipe.transform(this.editableBriefing.deadline, 'HH', 'GMT+00:00'));
            this.endTime.changeMinute(+this.datepipe.transform(this.editableBriefing.deadline, 'mm'));
          }

          if (this.editableBriefing.customerdata && this.editableBriefing.customerdata.length > 0 && this.editableBriefing.customerdata[0]
            && this.editableBriefing.customerdata[0].files && this.editableBriefing.customerdata[0].files.length > 0) {
            this.files = [...this.files, ...this.editableBriefing.customerdata[0].files];
          }

          this.showProject = this.editableBriefing.type == 'order.' ? true : false;
        }
      });
    }
  }
  /**
   * Save the new or updated briefing
   * @param isSaveButton Boolean, is true if this function has been executed by the click event of the save button,
   *  if false it means that it has been executed by the submit event of the form.
   */
  async saveData(isSaveButton) {
    this.deadlineError = false;
    this.deadlineBiggerError = false;
    this.startDateError = false;
    this.nameError = false;
    this.typeError = false;
    this.isSaveButton = isSaveButton;
    let startDate = this.getCompleteFormStartDate();
    let deadlineDate = this.getCompleteFormDeadline();

    if (!isSaveButton && !this.form.value.startDate) this.startDateError = true;
    if (!isSaveButton && !this.form.value.deadline) this.deadlineError = true;

    if (this.form.value.deadline && this.form.value.startDate) {
      if (deadlineDate < startDate) this.deadlineBiggerError = true;
    }

    if (this.form.valid && !this.startDateError && !this.deadlineError && !this.deadlineBiggerError) {
      this.creatingBriefing = true;
      let projectSelected = this.projects.find(x => x.id === this.form.value.briefingproject);

      if (this.form.value.briefingtype == 'action' && projectSelected 
        && this.checkIfOutsideTheProjectPeriod(projectSelected, startDate, deadlineDate)) {
          this.saveByAdjustingProjectPeriod(projectSelected, startDate, deadlineDate);
      } else {
        this.createOrUpdateBriefing();
      }
    } else {
      if (!this.form.value.briefingname) this.nameError = true;
      if (!this.form.value.briefingtype) this.typeError = true;
    }
  }
  /**
   * Manages whether the briefing is being edited or created
   */
  createOrUpdateBriefing() {
    if (this.assetId) {
      this.updateExistingBriefingShortcut();
    } else {
      this.createNewBriefingShortcut();
    }
  }
  /**
   * Checks if the start date of a job is within the start and due dates of a project
   * @param projectSelected {@link Project}
   * @param startDateJob Job start date
   * @param deadlineDateJob Job dead line date
   * @returns Boolean
   */
  checkIfOutsideTheProjectPeriod(projectSelected, startDateJob, deadlineDateJob) {
    return (startDateJob < projectSelected.startDate || deadlineDateJob > projectSelected.deadline)? true : false;
  }
  /**
   * Change the date if the start briefing date is earlier than the project date or the due date is later, it opens a modal that matches the wrong date.
   * @param projectSelected The project selected
   * @param startDateJob  The start date job
   * @param deadlineDateJob The deadline date job
   */
  saveByAdjustingProjectPeriod (projectSelected, startDateJob, deadlineDateJob) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'adjustProjectPeriod'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (startDateJob < projectSelected.startDate) {
          this.projectsService.saveStartDate(projectSelected.id, startDateJob);
        }
        if (deadlineDateJob > projectSelected.deadline) {
          this.projectsService.saveDeadline(projectSelected.id, deadlineDateJob);
        }
      }
      this.createOrUpdateBriefing();
      dialogRef = null;
    });
  }
  /**
   * End the creation of a new brifing and closes the dialog (this component) and remains in the component {@link BriefingsComponent}
   */
  public finishProcess() {
    this.creatingBriefing = false;
    this.deleteFiles = [];
    this.reload.emit();
  }
  /**
   * Create the new briefing
   */
  createNewBriefingShortcut() {
    let briefing : ShortcutBriefing = new ShortcutBriefing();
    briefing.createdByNew = +this.currentUser.id;
    briefing.modifiedByNew = +this.currentUser.id;
    briefing.owner_rel.push(+this.currentUser.id);
    briefing.workflowStep = this.isSaveButton ? +BriefingService.STEP_IN_PROGRESS : +BriefingService.STEP_SENT;

    let shortcut : Shortcut = new Shortcut();
    shortcut.briefings.push(briefing);
    if (this.form.value.info) {
      briefing.info = this.form.value.info;
      shortcut.info = this.form.value.info;
    }
    if (this.form.value.briefingname) {
      briefing.name = this.form.value.briefingname;
      shortcut.name = this.form.value.briefingname;
    }
    if (this.form.value.priority) {
      briefing.priorityCntr = this.form.value.priority;
      shortcut.priorityCntr = this.form.value.priority;
    }
    if (this.form.value.briefingtype && this.form.value.briefingtype == 'project') {
      shortcut.type = 'project.';
      shortcut.workflow = AppSettings.WORKFLOW_PROJECT;
      delete shortcut.agencydata;
      delete shortcut.finaldata;
      delete shortcut.costestimate;
    } else if (this.form.value.briefingtype && this.form.value.briefingtype == 'action') {
      shortcut.type = 'order.';
      shortcut.workflow = AppSettings.WORKFLOW_ACTION;
    }

    shortcut.workflowStep = 10;
    shortcut.createdByNew = +this.currentUser.id;
    shortcut.modifiedByNew = +this.currentUser.id;

    if (this.form.value.deadline) {
      let deadline = this.form.value.deadline;
      if (!this.form.value.deadlineTime) {
        deadline.hour(this.DEFAULT_DEADLINE_HOUR);
        deadline.minute(this.DEFAULT_DEADLINE_MINUTE);
      } else {
        let deadlineTokens = this.form.value.deadlineTime.split(':');
        deadline.hour(deadlineTokens[0]);
        deadline.minute(deadlineTokens[1]);
      }
      deadline.second(this.DEFAULT_DEADLINE_SECOND);
      shortcut.deadline = this.datepipe.transform(deadline.toDate(), 'yyyy-MM-ddTHH:mm:ss');
    }

    if (this.form.value.startDate) {
      let startDate = this.form.value.startDate;
      if (!this.form.value.startDateTime) {
        startDate.hour(this.DEFAULT_STARTDATE_HOUR);
        startDate.minute(this.DEFAULT_STARTDATE_MINUTE);
      } else {
        let startDateTokens = this.form.value.startDateTime.split(':');
        startDate.hour(startDateTokens[0]);
        startDate.minute(startDateTokens[1]);
      }
      startDate.second(this.DEFAULT_STARTDATE_SECOND);
      shortcut.startDate = this.datepipe.transform(startDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');
    }

    shortcut.owner_rel.push(+this.currentUser.id);

    if (this.form.value.briefingtype == 'action' && this.form.value.briefingproject) shortcut.parents = [this.form.value.briefingproject];

    this.briefingService.createShortcut(shortcut).then(result => {
      if (this.files && result.customerdata && result.customerdata.length > 0){
        let customerdata = result.customerdata[0];
        this.files.forEach(file => {
          this.addFileToFolder(+customerdata.id, file);
        });
      }
      this.finishProcess();
    });
  }
  /**
   * Update the briefing
   */
  updateExistingBriefingShortcut() {
    let shortcutType = this.editableBriefing.type == 'order.' ? 'action' : 'project';
    let customerDataFiles = this.editableBriefing.customerdata[0].files;
    let newStartDate;
    let newDeadline;
    let newProject;

    if (this.form.value.deadline) {
      if (this.form.value.deadline instanceof moment) {
        let deadline = this.form.value.deadline;
        if (!this.form.value.deadlineTime) {
          deadline.hour(this.DEFAULT_DEADLINE_HOUR);
          deadline.minute(this.DEFAULT_DEADLINE_MINUTE);
        } else {
          let deadlineTokens = this.form.value.deadlineTime.split(':');
          deadline.hour(deadlineTokens[0]);
          deadline.minute(deadlineTokens[1]);
        }
        deadline.second(this.DEFAULT_DEADLINE_SECOND);
        newDeadline = this.datepipe.transform(deadline.toDate(), 'yyyy-MM-ddTHH:mm:ss');
      } else {
        let deadlineTime = this.DEFAULT_DEADLINE_HOUR + ':' + this.DEFAULT_DEADLINE_MINUTE + ':' + this.DEFAULT_DEADLINE_SECOND;

        if (this.form.value.deadlineTime) {
          deadlineTime = this.form.value.deadlineTime + ':' + this.DEFAULT_DEADLINE_SECOND;
        }
        newDeadline = this.form.value.deadline + 'T' + deadlineTime + 'Z';
      }
    }

    if (this.form.value.startDate) {
      if (this.form.value.startDate instanceof moment) {
        let startDate = this.form.value.startDate;
        if (!this.form.value.startDateTime) {
          startDate.hour(this.DEFAULT_STARTDATE_HOUR);
          startDate.minute(this.DEFAULT_STARTDATE_MINUTE);
        } else {
          let startDateTokens = this.form.value.startDateTime.split(':');
          startDate.hour(startDateTokens[0]);
          startDate.minute(startDateTokens[1]);
        }
        startDate.second(this.DEFAULT_STARTDATE_SECOND);
        newStartDate = this.datepipe.transform(startDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');
      } else {
        let startDateTime = this.DEFAULT_STARTDATE_HOUR + ':' + this.DEFAULT_STARTDATE_MINUTE + ':' + this.DEFAULT_STARTDATE_SECOND;

        if (this.form.value.startDateTime) {
          startDateTime = this.form.value.startDateTime + ':' + this.DEFAULT_STARTDATE_SECOND;
        }
        newStartDate = this.form.value.startDate + 'T' + startDateTime + 'Z';
      }
    }

    if (this.form.value.briefingtype == 'action') {
      if ((this.editableBriefing.parents && this.editableBriefing.parents.length > 0 && this.form.value.briefingproject && this.form.value.briefingproject != this.editableBriefing.parents[0]) ||
        ((!this.editableBriefing.parents || (this.editableBriefing.parents && this.editableBriefing.parents.length == 0)) && this.form.value.briefingproject)) {
        newProject = this.form.value.briefingproject;
      } else if (this.editableBriefing.parents && this.editableBriefing.parents.length > 0 && !this.form.value.briefingproject) {
        newProject = 0;
      } else {
        newProject = -1;
      }
    }

    let workflowStep = this.isSaveButton ? +BriefingService.STEP_IN_PROGRESS : +BriefingService.STEP_SENT;

    let briefingChanges = {
      "type": this.form.value.briefingtype != shortcutType ? this.form.value.briefingtype : null,
      "name": this.form.value.briefingname != this.editableBriefing.name ? this.form.value.briefingname : null,
      "project": newProject,
      "step": workflowStep != this.editableBriefing.workflowStep ? workflowStep : null,
      "startDate": newStartDate != this.editableBriefing.startDate ? newStartDate : null,
      "deadline": newDeadline != this.editableBriefing.deadline ? newDeadline : null,
      "priority": this.form.value.priority != this.editableBriefing.priorityCntr ? this.form.value.priority : null,
      "info": this.form.value.info != this.editableBriefing.info ? this.form.value.info : null,
      "files": this.files.filter(x => !x.id).length > 0 || (this.files && customerDataFiles && this.files.length != customerDataFiles.length) ? this.files : null
    };

    if (this.hasChanges(briefingChanges)) {
      this.briefingService.updateBriefingShortcut(this.editableBriefing.id, this.editableBriefing.type, this.deleteFiles, briefingChanges, this.currentUser).then(data => {
        let newFiles = null;

        if (this.files && this.files.filter(x => !x.id).length > 0) newFiles = this.files.filter(x => !x.id);

        if (newFiles && data.customerdata && data.customerdata.length > 0){
          let customerdata = data.customerdata[0];
          newFiles.forEach(file => {
            this.addFileToFolder(+customerdata.id, file);
          });
        }

        if (newProject >= 0 && this.editableBriefing.parents && this.editableBriefing.parents.length > 0) {
          this.projectsService.removeAction(this.editableBriefing.parents[0], this.editableBriefing.id);
        }

        this.finishProcess();
      });
    } else {
      this.creatingBriefing = false;
    }
  }
    /**
   * Sets the date and time of the selected field to the current one. 
   * @param start Boolean, is true when the field to be set is the start date.
   *  False when it is the expiration date.
   */
  public saveCurrentTime(start: boolean = true) {

    let currentHour: number = +this.datepipe.transform(new Date(), 'HH');
    let currentMinutes: number = +this.datepipe.transform(new Date(), 'mm');
    let date: string = (start) ? 'startDate' : 'deadline';

    this.form.controls[date].patchValue(moment.utc());

    if (start){
      this.startTime.changeHour(currentHour);
      this.startTime.changeMinute(currentMinutes);
    } else {
      this.endTime.changeHour(currentHour);
      this.endTime.changeMinute(currentMinutes);
    }
  }
  /**
   * Set the date and time from the startDate field and returns it in the format YYYYY-MM-DDThh:mm:ss
   * @returns date in format YYYYY-MM-ddThh:mm:ss
   */
  getCompleteFormStartDate() {
    let date: string ='';
    let startDateTime = this.DEFAULT_STARTDATE_HOUR + ':' + this.DEFAULT_STARTDATE_MINUTE + ':' + this.DEFAULT_STARTDATE_SECOND;

    if (this.form.value.startDateTime) {
      startDateTime = this.form.value.startDateTime + ':' + this.DEFAULT_STARTDATE_SECOND;
    }

    if (this.form.value.startDate instanceof moment) {
      date = this.datepipe.transform(this.form.value.startDate.toDate(), 'yyyy-MM-dd') + 'T' + startDateTime;
    } else {
      date = this.form.value.startDate + 'T' + startDateTime;
    }

    return date;
  }
  /**
   * Set the date and time from the deadline field and returns it in the format YYYYY-MM-DDThh:mm:ss
   * @returns date in format YYYYY-MM-ddThh:mm:ss
   */
  getCompleteFormDeadline() {
    let date: string ='';
    let deadlineTime = this.DEFAULT_DEADLINE_HOUR + ':' + this.DEFAULT_DEADLINE_MINUTE + ':' + this.DEFAULT_DEADLINE_SECOND;

    if (this.form.value.deadlineTime) {
      deadlineTime = this.form.value.deadlineTime + ':' + this.DEFAULT_DEADLINE_SECOND;
    }

    if (this.form.value.deadline instanceof moment) {
      date = this.datepipe.transform(this.form.value.deadline.toDate(), 'yyyy-MM-dd') + 'T' + deadlineTime;
    } else {
      date = this.form.value.deadline + 'T' + deadlineTime;
    }

    return date;
  }
  /**
   * Adds all files (selected or dropped) to the list
   * @param event The drop event
   */
  public showFile(event) {
    event.preventDefault();

    if (event.dataTransfer != null && event.dataTransfer.files != null && event.dataTransfer.files.length > 0) {

      Array.from(event.dataTransfer.files).forEach(file => this.files.push(file));
    } else if(event.srcElement != null && event.srcElement.files != null && event.srcElement.files.length > 0) {
      Array.from(event.srcElement.files).forEach(file => this.files.push(file));
      event.target.value = '';
    }
  }
  /**
   * Removes the selecte file from the list
   * @param file The selected file
   */
  public deleteFile(file) {
    this.files.splice(this.files.indexOf(file), 1);

    if (file.id) this.deleteFiles.push(file);
  }
  /**
   * Prevents the default behavior of drag and drop events 
   * @param event drop and drag
   */
  preventDefaultEvent(event){
    event.preventDefault();
  }
  /**
   * *  Creates a new material in the parents passed as param
   * @param parent Client id of the briefing and the parent of the new material
   * @param file , the new material
   */
  public async addFileToFolder(parent : number, file) {

    let fileFormData = new FormData();

    let mime = file.type;
    let extension = Utils.getFileExtension(file.name);

    let type = this.mimetypeService.getMimetypeByMimetype(mime);
    if (!type && extension) {
      type = this.mimetypeService.getMimetypeByExtension(extension);
    }

    let newMaterial: Material = new Material();

    if (type && type.extension == '.svg') {
      newMaterial.svgfile = true;
    }

    newMaterial.name = file.name;
    newMaterial.parents.push(+parent);
    newMaterial.type = type.def_assettype;
    newMaterial.downloadLink = "formdata:file0";
    newMaterial.domain = AppSettings.DOMAIN;
    delete newMaterial.created;
    delete newMaterial.selected;
    delete newMaterial.preview;
    delete newMaterial.step_time;

    newMaterial.workflow = Utils.getWorkflowId(newMaterial.type);
    newMaterial.workflowStep = Utils.getDefaultWorkflowStep(newMaterial.type);

    fileFormData.append("entity", JSON.stringify(newMaterial));
    fileFormData.append("file0", file);

    let result = await this.materialsService.saveMaterial(newMaterial, fileFormData);

  }
  /**
   * Change the nameError to true
   * @param event event keypress
   */
  changeError(event) {
    this.nameError = false;
  }
  /**
   * checks if any briefing form data has been modified
   * @param briefing {@link Briefing}
   * @returns Boolean, true if any form field has been modified
   */
  hasChanges(briefing) {
    let changes = false;

    if (briefing.type || briefing.name || briefing.project >= 0 || briefing.step || briefing.startDate || briefing.deadline || briefing.priority || briefing.info || briefing.files) {
      changes = true;
    }

    return changes;
  }
  /**
   * Hides the project selection if the briefing type is a project
   */
  updateShowProject() {
    this.typeError = false;
    this.showProject = this.form.value.briefingtype == 'action' ? true : false;
  }

}