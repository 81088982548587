import { Injectable } from '@angular/core';
import Utils from 'app/shared/utils/utils';
import { HCMSService } from '../satellites/hcms.service';
import { OCService } from '../satellites/oc.service';
import { Language } from 'app/shared/model/language.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

/**
 * Service defined to operate with common solutions.
 */
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   * @param ocService Service with the functions related to the oc.
   * @param translateService Service with the functions related to the translations.
   */
  constructor(
    private hcmsService : HCMSService,
    private ocService: OCService,
    private translateService: TranslateService
  ) {}

  /**
   * @ignore
   */
  static readonly CNTR_POSITION: string = 'sp:cntr.position';
  /**
   * @ignore
   */
  static readonly CNTR_HUBZ_CHANNEL : string = 'sp:cntr.channel_hubz';
  /**
   * @ignore
   */
  static readonly CNTR_HUBZ_CLIENTS: string = 'sp:cntr.client';

  /**
   * Languages.
   */
  private languages : Language[]= [
    {id: 'en', label: 'english'},
    {id: 'de', label: 'german'},
  ]

  /**
   * Get Current language.
   * @returns current language.
   */
  public getCurrentLanguage() {
    let currentLang = this.translateService.currentLang;
    if (!currentLang) currentLang = this.translateService.defaultLang;
    return currentLang;
  }

  /**
   * Get languages.
   * @returns Array of languages.
   */
  public getLanguages() : Language[] {
    return this.languages;
  }

  /**
   * Add parent.
   * @param {Asset} asset Asset to be added.
   * @param parent Parent
   * @returns 
   */
  public addParent(asset, parent) {
    let route = Utils.getEntity(asset.type);
    if (!route && asset.rawType) {
      route = Utils.getEntity(asset.rawType);
    }

    return this.hcmsService.get().one(route, asset.id).get().toPromise().then(data => {
      if (data) {
        let parents = [];
        if (data.parents) {
          parents = data.parents;
        }
        parents.push(parent.id);
        data.parents = parents;

        return data.put().toPromise();
      }
    });
  }

  /**
   * Get history.
   * @param {string} id ID.
   * @returns {History} History.
   */
  public getHistory (id) {
    return this.ocService.get().one('versions', id).get();
  }

  /**
   * Get feature values
   * @param featureName Feature name. 
   * @returns Features.
   */
  public getFeatureValues(featureName : string): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/' + featureName).getList();
  }

}
