import { Component, Input } from '@angular/core';
import Utils from 'app/shared/utils/utils';

/**
 * This component defines the view of a folder to select files
 */
@Component({
  selector: 'app-select-file-item',
  templateUrl: './select-file-item.component.html',
  styleUrls: ['./select-file-item.component.scss']
})
export class SelectFileItemComponent {

  /**
   * The selected files
   */
   @Input() selectedFiles;

  /**
   * The source folder to show
   */
  @Input() source;

  /**
   * Array to store if the preview of the files has errors
   */
  loadErrorPreview = [];

  /**
   * {@link Utils} imported class
   */
  utils = Utils;

  /**
   * Loads the error in the array
   * @param media The media file that has the error in the preview
   */
  loadError(media) {
    this.loadErrorPreview[media.id.toString()] = true;
  }


  /**
   * Add files to array 
   * 
   * @param add 
   * @param id 
   */
  selectFiles (add, material) {
    if (add) {
      this.selectedFiles.push(material);
    } else {
      this.selectedFiles.splice(this.selectedFiles.findIndex(x => x.id === material.id), 1);
    }
  }

}
