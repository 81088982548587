import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { CommunicationService } from 'app/shared/services/communication/communication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'app/shared/services/user/user.service';

@Component({
  selector: 'app-communication-chat',
  templateUrl: './communication-chat.component.html',
  styleUrls: ['./communication-chat.component.scss']
})
export class CommunicationChatComponent implements OnInit {

  /**
   * Asset in which you are commenting
   */
  asset: any;
  /**
   * array of comments
   */
  comments : any;

  public form: FormGroup;
  /**
   * Indicates whether a comment is being sent
   */
  sending: boolean = false;

  private sentNotificationTeam = [];

  /**
   * Current user
   */
  private currentUser;
  /**
   * The current user can write internal comments
   */
  canWriteInternalComments: boolean = false;
  /**
   * The current user can read internal comments
   */
  canReadInternalComments: boolean = false;

  /**
   * You are replying to a comment
   */
  replying: boolean = false;
  /**
   * Cooment to reply
   */
  commentToReply;
  /**
   * Array of replies
   */
  replies = new Map();

  /**
   * Constructor
   * @param sheet Desused
   * @param data Action/Job ID
   * @param communicationService Service with the functions related to the communications
   * @param formBuilder Service with the functions related to the forms.
   * @param userService Service with the functions related to the users
   */
  constructor(
    private sheet: MatBottomSheetRef<CommunicationChatComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private communicationService : CommunicationService,
    public formBuilder: FormBuilder,
    private userService: UserService
  ) {
    if (data && data.id) {
      this.getCommunication(data.id);
    } else {
      this.comments = [];
    }
  }

  /**
   * The form is created, the current user is obtained and checked if he/she can write and read internal comments.
   */
  ngOnInit() {
    this.form = this.formBuilder.group({
      'message': [null, Validators.compose([Validators.required, Validators.minLength(3)])]
    });

    this.currentUser = this.userService.getCurrentUser();
    this.canWriteInternalComments = this.communicationService.canWriteInternalComments(this.currentUser);
    this.canReadInternalComments = this.communicationService.canReadInternalComments(this.currentUser);
  }

  /**
   * Gets the message/comment with the id passed as argument
   * @param {string} id Comment Id
   */
  async getCommunication(id){
    let communication = await this.communicationService.getCommunication(id);
    if (communication) {
      this.asset = communication.plain();
      if (communication.communication) {
        this.fillComments(communication.communication);
      } else {
        this.comments = [];
      }
    }
  }

  /**
   * Fill comments
   * @param comments Array of comments
   */
  fillComments(comments) {
    this.comments = comments;
    if (!this.canReadInternalComments){
      this.comments = comments.filter(x => x.internal_comment == null || !x.internal_comment)
    }

    this.replies = new Map();
    this.comments.forEach(comment => {
      if (comment.reply){
        comment.reply.forEach(reply => {
          let replies = (this.replies.has(reply.replyTo))?this.replies.get(reply.replyTo):[];
          replies.push(reply);
          this.replies.set(reply.replyTo, replies);
        });

        delete comment.reply;
      }
    });
  }

  /**
   * Get replys
   * @param comment Comment from which answers are sought
   * @returns Array with the answers of the indicated comment
   */
  getReplies(comment){
    return (this.replies.has(comment.timestamp))?this.replies.get(comment.timestamp):[];
  }

  /**
   * Creates a reply to the indicated comment
   * @param comment Comment to which you wish to reply
   */
  replyComment(comment){
    this.replying = true;
    this.commentToReply = comment;
  }

  /**
   * Cancel reply
   */
  cancelReply(){
    this.replying = false;
    this.commentToReply = null;
  }

  /**
   * Create a commentary
   * @param {boolean} isInternal Indicate if the comment is internal (Viewable only by Service Plan users)
   */
  sendComment(isInternal: boolean = false) {
    if (this.form.valid) {
      this.sending = true;
      let comment = {
        'entry': this.form.value.message,
        'actionRequired': false,
        'userAsset': +this.userService.getCurrentUser().id,
        'timestamp': Math.round(Date.now() / 1000) * 1000,
        'internal_comment': isInternal,
        'page': 0
      };

      this.communicationService.saveComment(this.asset, comment).then(result  => {
        if (result){
          this.refreshComments(result, comment);
        }
      });
    }
  }

  /**
   * Create a reply
   * @param {boolean} isInternal Indicate if the comment is internal (Viewable only by Service Plan users)
   */
  sendReply(isInternal: boolean = false){
    if (this.form.valid) {
      this.sending = true;
      let reply = {
        'entry': this.form.value.message,
        'actionRequired': false,
        'userAsset': +this.userService.getCurrentUser().id,
        'timestamp': Math.round(Date.now() / 1000) * 1000,
        'internal_comment': isInternal,
        'replyTo': this.commentToReply.timestamp
      };

      this.communicationService.saveReply(this.asset, this.commentToReply, reply).then(result  => {
        if (result){
          this.refreshComments(result, this.commentToReply);
        }
      });
    }
  }

  /**
   * Refresh comments after creating or replying to a comment and execute {@link saveNotification}
   * @param result Result of the call to create a comment or reply
   * @param comment Comment
   */
  private refreshComments(result, comment){
    this.asset = result.plain()
    this.fillComments(result.communication);
    this.form.reset();
    this.sending = false;

    if (this.asset.type == 'project.' || this.asset.type == 'order.') {
      this.userService.getTeam(this.asset.id).then(data => {
        this.userService.getTeamRoles().forEach(role => {
          this.saveNotification(this.asset[role.key], comment, this.asset);
        });
        this.sentNotificationTeam = [];
      });
    }
  }

  /**
   * Sends a notification to members who have participated in this conversation
   * @param members Members
   * @param chat Comment
   * @param asset saveNotification
   */
  saveNotification(members, chat, asset) {
    if (members) {
      members.forEach(member => {
        let webUserMember = this.userService.getUserData(member);
        if (webUserMember && webUserMember.id != chat.userAsset && this.sentNotificationTeam.indexOf(webUserMember.id) == -1) {
          let type;

          if (asset.type == 'project.') {
            type = 'project';
          } else if (asset.type == 'order.') {
            type = 'job';
          }

          let notification = {
            'id': asset.id,
            'user': chat.userAsset,
            'comment': chat.entry,
            'timestamp': chat.timestamp,
            'type': type
          };

          if (!webUserMember.notifications) {
            webUserMember.notifications = [];
          }

          webUserMember.notifications.push(notification);
          this.sentNotificationTeam.push(webUserMember.id);
          this.userService.editUser(webUserMember);
        }
      });
    }
  }

}
