import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchService } from 'app/shared/services/search/search.service';
import { AssetFeaturesService } from 'app/shared/services/asset_features/asset-features.service';
import { DatePipe } from '@angular/common';

/**
 * This component defines the view of the search dialog component.
 */
@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent {

  /**
   * Properties
   */
  public properties;
  /**
   * Filters
   */
  public values = [];
  /**
   * Prefix
   */
  public prefix = 'default';

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param searchService Service with the functions related to the search.
   * @param assetFeatureServie Service with the functions related to the asset.
   * @param datePipe Pipe with the date pipe used to format dates.
   * @param data Data required for the component. 
   */
  constructor(
    private dialogRef: MatDialogRef<SearchDialogComponent>,
    private searchService: SearchService,
    public assetFeatureService: AssetFeaturesService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.properties = this.searchService.schemaProperties;

    this.values = this.data.filters;
    this.prefix = this.data.prefix;

    if (!this.values) {
      this.values = [];
    }

    Object.keys(this.properties).forEach(key => {
      this.properties[key].key = key;
      if (this.properties[key].type === 'hierarchy' && !this.values[key]) {
        this.values[key] = [''];
      }
      if (this.properties[key].type === 'date' && !this.values[key]) {
        this.values[key] = {};
      }
    });

    this.properties = Object.keys(this.properties).map(x => this.properties[x]).sort((a, b) => a['search:sort'] - b['search:sort']);

    this.dialogRef.beforeClosed().subscribe(result => {

      if (result) {
        Object.keys(result).forEach(x => {
          if (result[x] && result[x].startDate) {
            result[x].startDate = this.datePipe.transform(result[x].startDate, 'yyyy-MM-ddT00:00:00');
          }

          if (result[x] && result[x].endDate) {
            result[x].endDate = this.datePipe.transform(result[x].endDate, 'yyyy-MM-ddT23:59:59');
          }

          if (result[x] && ((Array.isArray(result[x]) && result[x].length < 2) || (Object.keys(result[x]).length === 0 && result[x].constructor === Object))) {
            delete result[x];
          }
        });
      }
    });
  }

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Get features values
   * @param property Property
   * @returns Features values
   */
  getFeatureValues(property) {
    if (property['search:parent']) {
      return property.featureValues.filter(x => +x[property['search:parent_key']] === +this.values[property['search:parent']]);
    }
    return property.featureValues;
  }

}
