import { AppSettings } from "app/shared/app.settings";
/**
 * This class defines the structure of the check out of asset
 */
export class CheckOut {
    /**
     * The check out name
     */
    private readonly ASSET_NAME = "CheckOut Abort - ";
    
    /**
     * The check out name
     */
    name : string;
    /**
     * The unique asset identifier
     */
    id : number;
    /**
     * The domain of the asset
     */
    domain : string = AppSettings.DOMAIN;
    /**
     * Identifier of the creator of the new asset
     */
    createdByNew: number;
    /**
     * Identifier of the person who modified the asset
     */
    modifiedByNew: number;
    /**
     * The unique check out identifier
     */
    checkout_id: number;
    /**
     * True if you are currently viewing the asset
     */
    checkout: boolean = true;
    /**
     * The constructor
     * @param id The unique asset identifier
     */
    constructor(id: number){
        this.name = this.ASSET_NAME + id;
        this.checkout_id = id;
    }
}
