import { Asset } from "./asset.model";
import { Page } from "./page.model";
import { SafeResourceUrl } from "@angular/platform-browser";

/**
 * This class defines the Media files structure
 */
export class Media extends Asset{
    /**
     * end date of validity of the Media
     */
    valid_until: Date;
    /**
     * indicates whether it has been selected
     */
    selected: boolean = false;
    /**
     * copyright of Media
     */
    copyright: string;
    /**
     * size of Media
     */
    size: number;
    /**
     * tags of Media
     */
    tags: Asset[] = [];
    /**
     * download link  of Media
     */
    downloadLink : string;
    /**
     * mime type of Media
     */
    mimetype: string;
    /**
     * array of collections {@link Collection}
     */
    collections: Asset[] = [];
    /**
     * array of ids of the collections containing this Media
     */
    inCollection: number[] = [];
    /**
     * @ignore
     */
    assetsObject: number[] = [];
    /**
     * tipe file of Media
     */
    fileType: string;

    /**
     * pages of Media
     */
    pages: Page [] = [];

    /**
     * image  of Media
     */
    image : string;
    /**
     * variants of Media
     */
    variants = [];
    /**
     * color of Media
     */
    color;
    /**
     * source of Media
     */
    source;

    /**
     * safe url to visit Media
     */
    safeUrl : SafeResourceUrl;

    /**
     * main picture of Media
     */
    mainPictures: Asset[] = []
    /**
     * link to download Media
     */
    downloads = {};
    /**
     * @ignore
     */
    iccProfile;
    /**
     * @ignore
     */
    moreInfo;
    /**
     * instructions of Media
     */
    instructions;
    /**
     * id of the user who created the media
     */
    creator: string;
    /**
     * indicates whether it is available for download
     */
    downloadAvailable;
    
    antrieb: string;
    trim: string;
    showExpiredAlert;
    showExpiredMessage;

    brand: string[] = [];
    scale: string;
    licensedbrand: string;
    publicationYear: string;
    dateiType: string;
    status: string;

    confidential: boolean;

}