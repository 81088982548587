import { Injectable } from '@angular/core';
import { MsTeamsMessageCard } from 'app/shared/model/msteams-message-card.model';

import { IncomingWebhook } from 'ms-teams-webhook';

/**
 * Service defined to operate with messages.
 */
@Injectable({
  providedIn: 'root'
})
export class MsteamsService {

  /**
   * Send message.
   * @param url Url.
   * @param {MsTeamMessageCard} msteamsMessage {@link MsTeamMessageCard} Message 
   */
  sendMessage(url, msteamsMessage: MsTeamsMessageCard) {
    const webhook = new IncomingWebhook(url);

    webhook.send(msteamsMessage.getRequest()).then(x => {
      console.log('then', x);
    }).catch(x => {
      console.log('catch', x);
    }).finally(() => {
      console.log('finally');
    });
  }
}
