import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import Utils from 'app/shared/utils/utils';
import { Material } from 'app/shared/model/material.model';
import { AppSettings } from 'app/shared/app.settings';
import { Action } from 'app/shared/model/action.model';
import { WorkflowService } from '../workflows/workflow.service';
import { NotificationsService } from '../notifcations/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Marker, Vector2d } from 'app/shared/model/marker.model';
import { MimetypeService } from '../mimetype/mimetype.service';
import { TasksService } from '../tasks/tasks.service';
import { DamService } from '../../../layout/dam/service/dam.service';

/**
 * Service defined to manage all operations needed with Materials ({@link Material})
 */
@Injectable({
  providedIn: 'root'
})
export class MaterialsService {

  /**
   * Array of the different types that a material can be
   */
  materialsType = ["picture.", "video.", "pdf.", "audio.", "web-page.", "presentation.", "zip.", "office.", "spreadsheet."];

  /**
   * The constructor
   * @param hcmsService Service whit the functions related to censhare using restangular.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   * @param mimetypeService Service with the functions related to the mime types.
   * @param tasksService Service with the functions related to the tasks.
   * @param damService Service with the functions related to the dam list.
   */
  constructor(private hcmsService: HCMSService,
    private notifications: NotificationsService,
    private translateService: TranslateService,
    private mimetypeService: MimetypeService,
    private tasksService: TasksService,
    private damService: DamService
    ) { }

  /**
   * Gets the material which has the id passed as param
   * @param id, the id of the material
   * @returns the material which has the id
   */
  public getMaterial(id) {
    return this.hcmsService.get().one('entity/material/', id).get().toPromise();
  }

  /**
   * Gets all material
   * @returns the list with all the materials
   */
  public getMaterials() {
    return this.hcmsService.get().one('entity/material').get().toPromise();
  }

  /**
   * Gets the list of material types
   * @returns The list of material types
   */
  public getMaterialTypes() {
    return this.materialsType;
  }

  /**
   * Gets the list of materials of one parent
   * @param parentId, the parent Id
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns The list of materials that have the parentId as parent
   */
  public getMaterialsByParent(parentId, page = 0) {
    return this.hcmsService.get().one('entity/material?query=parents=' + parentId + '&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Gets the list of materials of a list of parents
   * @param parents, Array that contains the ids of the parents
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns The list of materials that have one of the parentIds as parent
   */
  public getMaterialsByParents(parents : Array<number>, page = 0) {
    let query = "";
    parents.forEach(parent => query += 'parents=' + parent + '|');
    return this.hcmsService.get().one('entity/material?query=' + query.substring(0, query.length - 1) + '&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Gets the list of materials filtered by job
   * @param actionid, the job id
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns the list of materials that belong to de job
   */
  public getMaterialsByAction(actionid, page = 0) {
    return this.getMaterialsByParent(actionid, page);
  }

  /**
   * Get the list of materials that have userId as the last user that has modified the material
   * @param userid, the user id
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns the list of material filtered by the user id
   */
  public getMaterialsByUser(userid, page = 0) {
    return this.hcmsService.get().one('entity/material?query=modifiedByNew=' + userid + '&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Creates a new material inside a task
   * @param files, array with the new materials
   * @param selectedMaterials, the selected materials of the job
   * @param action, the job
   * @param uploadTarget, the upload target for the material
   */
  async createNewMaterialsInTask(files, selectedMaterials, action, uploadTarget) {

    if (files){

      let parents = [];
      if (uploadTarget && action){
        parents = this.tasksService.getWorkFolders(action, uploadTarget);
      }

      for (const file of files) {
        let material = await this.createMaterialWithFile(file, parents);
        if (material){
          selectedMaterials.push(material.id);
        }
      }
    }
  }

  /**
   * Creates a new material in the parents passed as param
   * @param file, the new material
   * @param parents, the parents of the new material
   * @returns the new material
   */
  public async createMaterialWithFile(file, parents) : Promise<any>{

    let fileFormData = new FormData();
    let newMaterial : Material = new Material();
    let type = this.mimetypeService.getFileMimetype(file);

    newMaterial.name = file.name;
    newMaterial.type = type.def_assettype;
    newMaterial.downloadLink = "formdata:file0";
    newMaterial.workflow = Utils.getWorkflowId(newMaterial.type);
    newMaterial.workflowStep = Utils.getDefaultWorkflowStep(newMaterial.type);

    if (parents) newMaterial.parents = parents;

    delete newMaterial.created;
    delete newMaterial.selected;
    delete newMaterial.preview;

    fileFormData.append("entity", JSON.stringify(newMaterial));
    fileFormData.append("file0", file);

    let route = Utils.getEntity(newMaterial.type);
    return await this.hcmsService.get().all(route).post(fileFormData).toPromise();
  }

  /**
   * Save a new material
   * @param material, the new material
   * @param fileForm, the file form info of the material
   * @returns the new material
   */
  public async saveMaterial(material, fileForm, hideMessage = false) {

    let result = await new Promise((resolve, reject) => {
      setTimeout(() => {
        let route = Utils.getEntity(material.type);
        resolve(this.hcmsService.get().all(route).post(fileForm).toPromise().then(material => {
          //this.sendNewMaterialMail(material);

          let link = null;
          let linkMessage = null;

          if (material.parents && material.parents.length > 0) {
            link = ['/jobs', 'detail', material.parents[0], 'materials', material.id];
            linkMessage = this.translateService.instant('open_material');
          }

          if (!hideMessage) {
            this.notifications.show(this.translateService.instant('material_created') + ': ' + material.name, link, linkMessage, 5000);
          }

        }));
      }, 2000);
    });
    return result;
  }

  /**
   * Save a new material as showreels for companies
   * @param material, the new material data
   * @param fileForm, the file form of the material
   * @returns the new material
   */
  public async saveShowReel(material, fileForm) {

    let result = await new Promise((resolve, reject) => {
      setTimeout(() => {
        let route = Utils.getEntity(material.type);
        resolve(this.hcmsService.get().all(route).post(fileForm).toPromise().then(material => {
          //this.sendNewMaterialMail(material);

          let link = null;
          let linkMessage = null;

          if (material.parents && material.parents.length > 0) {
            link = ['/jobs', 'detail', material.parents[0], 'materials', material.id];
            linkMessage = this.translateService.instant('open_material')
          }

          // this.notifications.show(this.translateService.instant('material_created') + ': ' + material.name, link, linkMessage, 5000);

        }));
      }, 2000);
    });
    return result;
  }

  /**
   * Updates the material file of one material
   * @param type, the type of the material
   * @param material, the material data
   * @param file, the file of the material
   * @returns the updated material
   */
  public updateMaterialFile(type, material, file) {

    let route = Utils.getEntity(type);

    return this.hcmsService.get().one(route, material.id).get().toPromise().then(x => {
      x = Object.assign(x, material);

      x.downloadLink = "formdata:file0";
      x.reloadPreview = true;

      let fileFormData = new FormData();
      fileFormData.append("entity", JSON.stringify(x));
      fileFormData.append("file0", file);

      return x.customPUT(fileFormData, '', {}, {}).toPromise().then(y => {
        //this.sendNewMaterialMail(y);
      });
    });

  }

  /**
   * Removes the parent of one material
   * @param material, the material
   * @param parentId, the parentId to remove from the material
   * @returns the updated material
   */
  public removeParent(material:Material, parentId: number){

    let route = Utils.getEntity(material.type);
    return this.hcmsService.get().one(route, material.id).get().toPromise().then( item => {
      item.removeParentRelation = +parentId;
      return item.put().toPromise();
    })
  }
  /*private sendNewMaterialMail (material) {
    if (material && material.id) {

      material.parents.forEach(actionId => {

        this.hcmsService.get().one('entity/action/', actionId).get().subscribe(action => {

          if (action && AppSettings.NEW_MATERIAL_MAIL_STEPS.includes(action.workflowStep)) {
            let owner = this.userService.getUserData(action[UserService.OWNER]);

            if (owner && owner.id) {
              let mail;

              mail = new Mail(AppSettings.MAIL_NEWMATERIAL + '_' + owner.id, AppSettings.MAIL_NEWMATERIAL, +action.id, +owner.id);
              this.mailService.saveMail(mail);
            }

            if (action[UserService.CO_OWNER] && action[UserService.CO_OWNER].length > 0) {
              action[UserService.CO_OWNER].forEach(co_owner => {

                if (co_owner != owner.id) {
                  let mail;
                  mail = new Mail(AppSettings.MAIL_NEWMATERIAL + '_' + co_owner, AppSettings.MAIL_NEWMATERIAL, +action.id, +co_owner);
                  this.mailService.saveMail(mail);
                }
              });
            }
          }
        });
      });
    }

    return material;
  }*/

  /**
   * Gets the workflow status for the list of materials
   * @param materials, the material list
   * @returns the workflow status: approved, rejected or pending
   */
  public getIndicator(materials: Array<any>): string {
    if (materials.every(mat => mat.workflowStep == Utils.getApprovedWorkflowStep(mat.type))) {
      return WorkflowService.STATUS_APPROVED;
    } else if (!materials.some(mat => mat.workflowStep == Utils.getDefaultWorkflowStep(mat.type))) {
      return WorkflowService.STATUS_REJECTED;
    }

    return WorkflowService.STATUS_PENDING;

  }

  /**
   * @ignore
   */
  private isActionInrApproval(action: Action): boolean {
    return action.workflow && action.workflowStep && action.workflow === AppSettings.WORKFLOW_ACTION

  }

  /**
   * Edits the material passed as param
   * @param material, the material to be edited
   * @returns the edited material
   */
  public async editMaterial(material) {
    let newMaterial = Object.assign(new Material, this.hcmsService.get().copy(material).plain());
    delete newMaterial.downloadLink;
    delete newMaterial.approvalTime;
    delete newMaterial.approvalFeedback;

    let updateMaterial = await this.hcmsService.get().one(Utils.getEntity(newMaterial.type), newMaterial.id).get().toPromise();
    updateMaterial = Object.assign(updateMaterial, newMaterial);
    return updateMaterial.save().toPromise()
  }

  /**
   * Updates the marker of the material with a new colour
   * @param material, the material to be updated
   * @param marker, the marker of the material
   * @param colour, the new colour
   * @returns the updated material
   */
  public async updateMarkerColour(material: Material, marker: Marker, colour: string) {

    let newMaterial = await this.hcmsService.get().one(Utils.getEntity(material.type), material.id).get().toPromise();
    newMaterial.communication.forEach(commnunication => {
      if (commnunication['timestamp'] && commnunication['timestamp'] == marker.chat['timestamp']) {
        commnunication['markers'].forEach((current: Marker) => {
          if (marker['initialRelativePosition']['x'] == current['x'] && marker['initialRelativePosition']['y'] == current['y']) {
            current.color = colour;
          }
        });
      }
    });
    return newMaterial.save().toPromise();

  }

  /**
   * Updates the marker of the material with a new shape
   * @param material, the material to be updated
   * @param marker, the marker of the material
   * @param shape, the new shape
   * @param position, the new position of the sahpe
   * @param endpoint, the new endpoint of the shape
   * @returns the updated material
   */
  public async updateMarkerShape(material: Material, marker: Marker, shape: string, position: Vector2d = null, endpoint: Vector2d = null) {

    let newMaterial = await this.hcmsService.get().one(Utils.getEntity(material.type), material.id).get().toPromise();
    newMaterial.communication.forEach(commnunication => {
      if (commnunication['timestamp'] && commnunication['timestamp'] == marker.chat['timestamp']) {
        commnunication['markers'].forEach((current: Marker, index: number) => {
          let id = commnunication.userAsset + '_' + commnunication.timestamp + '_' + index;
          if (id == marker.id) {
            current.shape = shape;
            if (shape === 'mode_comment' && position) {
              current['x'] = position.x;
              current['y'] = position.y;
              current.endpoint = endpoint;
            }
          }
        });
      }
    });
    return newMaterial.save().toPromise();

  }

  /**
   * Updates the marker of the material with a new deleted flag
   * @param material, the material to be updated
   * @param marker, the marker of the material
   * @param deleted, the new value of the deleted flag
   * @returns the updated material
   */
  public async updateMarkerDeletedFlag(material: Material, marker: Marker, deleted: boolean) {

    let newMaterial = await this.hcmsService.get().one(Utils.getEntity(material.type), material.id).get().toPromise();
    newMaterial.communication.forEach(commnunication => {
      if (commnunication['timestamp'] && commnunication['timestamp'] == marker.chat['timestamp']) {
        commnunication['markers'].forEach((current: Marker) => {
          if (marker['initialRelativePosition']['x'] == current['x'] && marker['initialRelativePosition']['y'] == current['y']) {
            current.deleted = deleted;
          }
        });
      }
    });
    return newMaterial.save().toPromise();

  }

  /**
   * Save a new canvas draw
   * @param fileForm, the file form of the canvas draw
   * @returns the new canvas draw
   */
  public async saveCanvasDraw(fileForm: FormData) {
    let route = 'entity/canvasdraw';
    let result = await this.hcmsService.get().all(route).post(fileForm).toPromise();

    return result;
  }

  /**
   * Gets the canvas draw
   * @param id, the id of the canvas draw
   * @returns the canvas draw which has the id passed as param
   */
  public getCanvasDraw(id) {
    let route = 'entity/view';
    return this.hcmsService.get().one(route, id).get().toPromise();
  }

}
