import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, Renderer2, ViewChildren } from '@angular/core';
import { Filter, FilterRange } from 'app/shared/model/filter.model';
import { TrackingService } from 'app/shared/services/tracking/tracking.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MomentCustomAdapter } from 'app/shared/utils/MomentCustomAdapter';
import { UserService } from 'app/shared/services/user/user.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { CommonService } from 'app/shared/services/common/common.service';
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { WorkflowService } from 'app/shared/services/workflows/workflow.service';
import { AssetFeaturesService } from 'app/shared/services/asset_features/asset-features.service';
const _ = deepdash(lodash);

/**
 * Constant with the formats of the date
 */
export const DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: { dateInput: 'DD.MM.YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
};

/**
 * This component defines the filters view where we can define which filters to display
 */
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentCustomAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class FiltersComponent implements OnInit {

  lodash = _;

  /**
   * Element of the view. Used to close the filter dialog if clicked out of it.
   */
  @ViewChild('filterButton', { read: ElementRef, static: true }) filterButton: ElementRef;
  /**
   * Element of the view. Used to close the filter dialog if clicked out of it.
   */
  @ViewChild('filterContainer', { read: ElementRef, static: true }) filterContainer: ElementRef;

  /**
   * List of objects to filter
   */
  @Input() list = [];

  /**
   * Filters to show
   */
  @Input() filters: Array<Filter> = [];
  /**
   * Event triggered when changing the filter
   */
  @Output() filterChanged: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Text filter.
   */
  textFilter: string = null;
  /**

  /**
   * If true, displays the filters.
   */
  showFilters: boolean = false;
  /**
   * It is true if any of the filters has "text" as name.
   */
  textAvailable: boolean = false;
  /**
   * Last keyword.
   */
  lastKeyword: string = '';

  isOperator = false;

  /**
   * Array of channels
   */
  hubzChannels: any[] = [];

  /**
   * Array of clients
   */
  clients: any[] = [];

  /**
   * Constructor
   * @param trackingService Service with the functions related to the tracking.
   * @param renderer The renderer that will be used to apply the styling.
   * @param commonService Service with the common functions.
   * @param assetService Service with the functions related to the assets.
   * @param workflowsService Service with the functions related to the workflow.
   * @param assetFeatureService Service with the functions related to the properties of assets..
   */
  constructor(
    private trackingService: TrackingService,
    private usersService: UserService,
    private rolesService: RolesService,
    private renderer: Renderer2,
    protected commonService: CommonService,
    private assetService: AssetService,
    private workflowsService: WorkflowService,
    private assetFeatureService: AssetFeaturesService,
  ) { }

  /**
   * Update the filters and get the available text
   */
  ngOnInit() {

    this.isOperator = this.rolesService.isOperator(this.usersService.getCurrentUser());
    this.updateFilters();
    this.textAvailable = this.filters.some(x => x.name === 'text');
  }

  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (e)=> {
      if(!this.filterContainer) return;

      const {left, bottom, top, right} = this.filterContainer.nativeElement.getBoundingClientRect();
      
      if( !this.filterButton.nativeElement.contains(e.target) && !( e.x > left && e.x < right && e.y < bottom && e.y > top) ) {
        this.showFilters = false;
      }
    })
  }

  /**
   * Add a filter to the fliters list
   * 
   * @param filterName The filter name
   * @param value The filter value
   */
  addFilter(filterName, value) {
    if (filterName === 'text') {
      setTimeout(() => {
        var value = this.filters.filter(filter => filter.name == filterName)[0].value;
        if (value != null && value !== this.lastKeyword) {
          this.lastKeyword = value;
          this.filterChanged.emit();
          if (value.length > 3) {
            this.trackingService.sendMatomoSearch(value);
          }
        }
      }, 1500);
    }

    this.filters.filter(filter => filter.name == filterName)[0].value = value;
  }

  /**
   * Sets or updates available values for all filters
   */
  updateFilters() {
    let team = [];

    /*
    this.list.filter(item => item.id !== this.usersService.getCurrentUser().id && item.user_targets).map(item => item.user_targets).forEach(target => team = [...team, ...target]);
    this.list.filter(item => item.id !== this.usersService.getCurrentUser().id && item.owner && item.owner.display_name).map(item => item.owner).forEach(owner => team = [...team, owner]);
    this.list.filter(item => item.id !== this.usersService.getCurrentUser().id && item.manager && item.manager.display_name).map(item => item.manager).forEach(manager => team = [...team, manager]);


    team = [this.usersService.getCurrentUser()].concat(team.sort((x,y) => (x.name > y.name ? 1 : -1)));*/

    if (this.isOperator) {
      team = this.usersService.getUsers();
      team = team.filter(x => +this.usersService.getCurrentUser().id !== x.id)
      team = [this.usersService.getCurrentUser()].concat(team);
    } else {
      team = this.usersService.getPartnersId(this.usersService.getCurrentUser());
      team = team.filter(x => +this.usersService.getCurrentUser().id !== x).map(x => this.usersService.getUserData(x));
      team = [this.usersService.getCurrentUser()].concat(team.sort((x, y) => (x.name > y.name ? 1 : -1)));
    }
    if(this.list.length > 0){

      this.setValues('team', Array.from(new Set(team)));
  
      this.setValues('task', Array.from(new Set(this.list.filter(item => item.type).map(item => item.type))));
      this.workflowsService.getWorkflowSteps().subscribe(data => {
        this.setValues('step', data.filter(item => item.enabled === 1 && item.wf_id === _.first(this.list).workflow));
      });
      this.setValues('priority', Array.from(new Set(this.list.filter(item => item.priority).map(item => item.priority))));
      this.assetService.getPriorityValues().subscribe(data => {
        this.setValues('priorityCntr', _.map(_.filter(data, 'name'), 'name'));
      });
  
      this.setValues('jobs', _.uniqBy(_.flattenDeep(this.list.filter(item => item.jobs).map(item => _.map(item.jobs))), 'name'));
  
      this.setValues('startDate', Array.from(new Set(this.list.filter(item => item.startDate).map(item => item.startDate))));
      this.setValues('deadline', Array.from(new Set(this.list.filter(item => item.deadline).map(item => item.deadline))));
  
      this.commonService.getFeatureValues(CommonService.CNTR_HUBZ_CHANNEL).subscribe(data => {
        const channelValues = data.filter(x => x.enabled).sort((a, b) => (a.sorting > b.sorting) ? 1 : -1);
        const channelsFirstLevel = this.assetFeatureService.getFeatureValueByParentAndLevel(channelValues, 'root.', 2);
        _.forEach(this.list, (item) => {
          const requestData: any = _.findDeep(item.filtersData, (child, i, parent, ctx) => {
            return i === 'type' && child === 'request.' ? true : false;
          });
          if (requestData && requestData.parent.channel_hubz) {
            this.hubzChannels.push(_.first(channelsFirstLevel.filter(el => _.includes(requestData.parent.channel_hubz, el.value_key))));
          } else if (item.channel) {
            this.hubzChannels.push(_.first(channelsFirstLevel.filter(el => _.includes(item.channel, el.value_key))));
          }
        });
        this.hubzChannels = _.compact(_.uniqBy(this.hubzChannels, 'name'));
        this.setValues('hubzChannels', Array.from(new Set(this.hubzChannels.map(item => item.name))));
      });
  
      this.commonService.getFeatureValues(CommonService.CNTR_HUBZ_CLIENTS).subscribe(data => {
        _.forEach(this.list, (item) => {
          const requestData: any = _.findDeep(item.filtersData, (child, i, parent, ctx) => {
            return i === 'type' && child === 'request.' ? true : false;
          });
          if (requestData && requestData.parent.client) {
            this.clients.push(_.first(data.filter(el => el.value_key === requestData.parent.client)));
          } else if (item.client) {
            this.clients.push(_.first(data.filter(el => _.includes(item.client, el.value_key))));
          }
        });
        this.setValues('clients', Array.from(new Set(this.clients.map(item => item.name))));
      });
  
      this.setValues('assignedTo', Array.from(new Set(team)));
      this.setValues('createdBy', Array.from(new Set(team)));
    }
  }

  /**
   * Emits that filters has changed
   */
  applyFilters() {
    this.filterChanged.emit();
  }

  /**
   * Resets values of filters applyed
   */
  resetFilters() {

    if (this.textFilter != null) {
      this.textFilter = '';
      // this.renderer.setProperty(this.textFilter.nativeElement, 'value', '');
    }
    this.filters.forEach(filter => {
      if (filter.type === 'range') {

        filter.value = new FilterRange();

      } else {

        filter.value = filter.defaultValue;

      }

    });
    this.filterChanged.emit();
  }

  /**
   * Add a dates range ({@link FilterRange}) for a filter 
   * 
   * @param filter The filter do add the range
   * @param element Form field that defines the range
   * @param event Value to filter
   */
  addRange(filter: Filter, element: string, event: any) {

    let value: moment.Moment = event.value;
    filter.value[element] = value
  }

  /**
   * Sets available values for a specific filter
   */
  setValues(filterName, values, displayValues = null) {

    let filtered = this.filters.find(filter => filter.key == filterName);
    if (filtered) {
      filtered.displayValues = filtered.values = values;
      if (displayValues) {
        filtered.displayValues = displayValues;
      }
      if (!filtered.value) {
        filtered.valueMin = filtered.values[0];
        filtered.valueMax = filtered.values[filtered.values.length - 1];
      }
    }
  }

}
