/**
 * Recently viewed.
 */
export class RecentlyViewed {
    /**
     * Projects.
     */
    projects: Array<RecentlyViewedDetail> = [];
    /**
     * Jobs.
     */
    jobs: Array<RecentlyViewedDetail> = [];
    /**
     * Tasks.
     */
    tasks: Array<RecentlyViewedDetail> = [];
}

/**
 * Recently viewed detail.
 */
export class RecentlyViewedDetail{
    /**
     * ID
     */
    id: number = 0;
    /**
     * Type
     */
    type: string = '';
    /**
     * Title
     */
    title: string = '';
    /**
     * Rp image
     */
    rp_image: string = '';
    /**
     * Rp name
     */
    rp_name: string = '';
    /**
     * Rp initials
     */
    rp_initials: string = '';
    /**
     * Rp id
     */
    rp_id = 0;
    /**
     * link
     */
    link: string = '';
}
