import { ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { DetailedRequestComponent } from './detailed-request/detailed-request.component';

/**
 * This component defines the creation of a new {@link Request} (request)
 */
@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss']
})
export class NewRequestComponent implements OnInit {

  @ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;
  @ViewChild('detailedRequest', { static: true }) detailedRequest: DetailedRequestComponent;

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialog.
   * @param data Object with request id
   */
  constructor(
    public dialogRef: MatDialogRef<NewRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  /**
   * If you have received the data, set the selected tab.
   */
  ngOnInit() {
    // if (this.data) {
    //   this.tabGroup.selectedIndex = 2;
    // }

    // call save dialog on click outside modal
    this.dialogRef.backdropClick().subscribe(result => {
      this.detailedRequest.saveDetailedRequestPrompt();
    });
  }

  /**
   * Close dialog.
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Close dialog -> show dialog.
   */
   onCloseClick(): void {
    this.detailedRequest.saveDetailedRequestPrompt();
  }
  

  /**
   * Prevents default behavior.
   * @param event 
   */
  preventDefaultEvent(event){
    event.preventDefault();
  }

  /**
   * Save the request
   */
  onSaveClick() {
    this.detailedRequest.saveDetailedRequest();
  }

}