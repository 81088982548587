import { Injectable } from '@angular/core';
import { LiquidCache } from 'ngx-liquid-cache';
import { Observable } from 'rxjs';
import { OCService } from '../satellites/oc.service';

/**
 * Service defined to manage the different features of the assets.
 */
@Injectable({
  providedIn: 'root'
})
export class AssetFeaturesService {

  siku_brands = [];
  siku_scales = [];
  siku_licensedbrands = [];


  constructor(private ocService: OCService) {
    this.getCachedBrands().subscribe(x => this.siku_brands = x);
    this.getCachedScales().subscribe(x => this.siku_scales = x);
    this.getCachedLicensedbrands().subscribe(x => this.siku_licensedbrands = x);
  }
  
  //@LiquidCache('feature_value_{key}')
  /**
   * Get feature value
   * @param {string} key 
   * @returns Feature value.
   */
  public getFeatureValue(key): Promise<any> {

    if (key === 'censhare:asset.domain') {
      return this.ocService.get().all('tables/domain').getList().toPromise().then(x => {

        x.forEach(value => value.value_key = value.pathid);

        return x;
      });
    }

    if (key === 'censhare:asset.domain2') {
      return this.ocService.get().all('tables/domain2').getList().toPromise().then(x => {

        x.forEach(value => value.value_key = value.pathid);

        return x;
      });
    }

    if (key === 'censhare:asset.type') {
      return this.ocService.get().all('tables/asset_typedef').getList().toPromise().then(x => {

        x.forEach(value => value.value_key = value.asset_type);

        return x;
      });
    }

    if (key === 'censhare:asset.wf_id') {
      return this.ocService.get().all('tables/workflow').getList().toPromise().then(x => {

        x.forEach(value => value.value_key = value.id);

        return x;
      });
    }

    if (key === 'censhare:asset.wf_step') {
      return this.ocService.get().all('tables/workflowStep').getList().toPromise().then(x => {

        x.forEach(value => value.value_key = value.wf_step);

        return x;
      });
    }

    return this.ocService.get().all('tables/feature_value/feature/' + key).getList().toPromise();
  }

  // @LiquidCache('feature_{key}')
  /**
   * Get feature
   * @param {string} key 
   * @returns Feature
   */
  public getFeature(key): Observable<any> {
    return this.ocService.get().one('tables/feature/key/' + key).get();
  }

  @LiquidCache('siku_brands')
  private getCachedBrands(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:cntr.brand').getList();
  }

  getBrands() {
    return this.siku_brands.sort((x, y) => (x.value_key > y.value_key ? 1 : -1));
  }

  @LiquidCache('siku_scales')
  private getCachedScales(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:cntr.scale').getList();
  }

  getScales() {
    return this.siku_scales.sort((x, y) => (x.value_key > y.value_key ? 1 : -1));
  }

  @LiquidCache('siku_licensedbrands')
  private getCachedLicensedbrands(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:cntr.licensedbrand').getList();
  }

  getLicensedbrands() {
    return this.siku_licensedbrands.sort((x, y) => (x.value_key > y.value_key ? 1 : -1));
  }


  /**
   * Get feature value by parent and level
   * @param {string} featuresValues Channels values
   * @param {string} parent 
   * @param {number} level 
   * @returns 
   */
  getFeatureValueByParentAndLevel(featuresValues, parent: string, level: number) {
    let values = featuresValues.filter(x => (!parent || x.value_key.startsWith(parent)) && x.value_key.split('.').length === level + 1);
    return values;
  }

  /**
   * Get list level value.
   * @param x 
   * @param list 
   * @param level 
   * @returns 
   */
  static getListLevelValue(x, list, level) {
    let content = '';

    if (x && x['value_key']) {
      content = x['value_key'];
    } else {
      content = x;
    }

    if (content) {
      const arr = content.split('.');
      if (arr.length > level) {
        const val = list.find(z => z['value_key'] === arr.filter((y, index) => index <= level).reduce((acc, y) => acc + '.' + y) + '.');
        if (val) {
          return val;
        }
      }
    }
  }

  /**
   * Get list by parent and level.
   * @param list 
   * @param parent 
   * @param level 
   * @returns 
   */
  getListByParentAndLevel(list, parent: string, level: number) {
    let vals = list.filter(x => !x.value_key.endsWith('.none.') && x.value_key.startsWith(parent) && x.value_key.split('.').length === level + 2);

    if (vals && vals.length > 1) {
      vals = vals.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }

    return vals;
  }

  /**
   * Get list by level
   * @param list 
   * @param level 
   * @returns 
   */
  getListByLevel(list, level: number) {
    let vals = list.filter(x => x.value_key.split('.').length === level + 2);

    if (vals && vals.length > 1) {
      vals = vals.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }

    return vals;
  }

  getLevelValue(value: string, level: number) {
    if (value) {
      return value.split('.').filter((x, i) => i < level).reduce((acc, x) => acc + '.' + x) + '.';
    }
  }

}
