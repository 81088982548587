import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewRequestComponent } from 'app/layout/requests/new-request/new-request.component';

/**
 * @ignore
 */
@Component({
  selector: 'app-new-request-widget',
  templateUrl: './new-request-widget.component.html',
  styleUrls: ['./new-request-widget.component.scss']
})
export class NewRequestWidgetComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {}

  /**
   * Opens a new request dialog
   */
  openNewRequestDialog(): void {
    const newRequestDialog = this.dialog.open(NewRequestComponent, { panelClass: 'newRequestDialog' });
  }

}
