import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AppSettings } from 'app/shared/app.settings';


// declare const ATInternet: any;
// export type Tag = any;
/**
 * window to be analyzed with matomo
 */
declare var window: {
  [key: string]: any;
  prototype: Window;
  new (): Window;
};
/**
 * Define the page information
 */
export interface PageInfo {
  /**
   * Name of page
   */
  name: string;
  /**
   * level2 of page info
   */
  level2?: string;
  /**
   * chapter1 of page info
   */
  chapter1?: string;
  /**
   * chapter2 of page info
   */
  chapter2?: string;
  /**
   * chapter3 of page info
   */
  chapter3?: string;
  /**
   * custom Object of page info
   */
  customObject?: any;
}
/**
 * @ignore
 */
export interface ClickInfo {
  elem: any;
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  type: string;
  event?: any;
}
/**
 * Defines class TrackingService
 */
@Injectable()
export class TrackingService {

  // private tag: Tag;
  /**
   * true si matomo has already been created
   */
  private matomoCreated: boolean = false;
  /**
   * Constructor
   */
  constructor(){
    window._paq = window._paq || [];
    // try {
      // this.tag = new ATInternet.Tracker.Tag();
    // } catch (e) {
      // console.log(e);
    // }
  }

  // click(info: ClickInfo): boolean {
  //   if (environment.production) {
  //     return this.tag.click.send(info);
  //   } else {
  //     console.log('would track CLICK on live', info);
  //   }
  // }

  // clickListener(info: ClickInfo): void {
  //   if (environment.production) {
  //     this.tag.clickListener.send(info);
  //   } else {
  //     console.log('would track CLICKLISTENER on live', info);
  //   }
  // }

  // pageSend(info: PageInfo): void {
  //   if (environment.production) {
  //     this.tag.page.send(info);
  //   } else {
  //     console.log('would track PAGESEND on live', info);
  //   }
  // }

  /**
   * 
   * @returns true if the environment allows matomo to be activated
   */
  isMatomoAvailable() : boolean {
    return AppSettings.MATOMO_URL != null && AppSettings.MATOMO_URL != ''
      && AppSettings.MATOMO_ID != null && AppSettings.MATOMO_ID != ''
      && environment.production;
  }
  /**
   * if the environment allows matomo to be activated, it activates matomo and initialises the data analysis.
   */
  addMatomo(){

    if (this.isMatomoAvailable()){
      // <!-- Matomo -->
      // tracker methods like "setCustomDimension" should be called before "trackPageView" /
      window._paq.push(['trackPageView']);
      window._paq.push(['enableLinkTracking']);
      (function() {
        var u=AppSettings.MATOMO_URL;
        window._paq.push(['setTrackerUrl', u+'matomo.php']);
        window._paq.push(['setSiteId', AppSettings.MATOMO_ID]);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();

      this.matomoCreated = true;
      // <!-- End Matomo Code -->
    }
}
  /**
   * Send matomo information about a view
   * @param url the view
   */
  sendMatomoView(url: string){
      if (this.isMatomoAvailable()){
        if (!this.matomoCreated) this.addMatomo();
        else {
          window._paq.push(['setCustomUrl', url]);
          window._paq.push(['trackPageView']);
        }
    }
  }
  /**
   * Submit matomo's information on a search
   * @param keyword the keyword that has been searched for
   */
  sendMatomoSearch(keyword){
    if (this.isMatomoAvailable()){
      if (!this.matomoCreated) this.addMatomo();
      window._paq.push(['trackSiteSearch', keyword, , ]);
    }
  }
  /**
   * Send the information about the download of an asset to matomo.
   * @param assetID The id of the downloaded asset
   */
  sendMatomoDownload(assetID){
    if (this.isMatomoAvailable()){
      if (!this.matomoCreated) this.addMatomo();
      var trackVariable = AppSettings.MATOMO_URL  + 'download ' + assetID + '';
      window._paq.push(['trackLink', trackVariable , 'download']);
    }
  }

}
