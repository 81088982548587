import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { emailValidator } from 'app/shared/utils/app-validators';
import { RolesService } from 'app/shared/services/user/roles.service';
import { DomainService } from 'app/shared/services/domain/domain.service';
import { CommonService } from 'app/shared/services/common/common.service';
import { UserService } from 'app/shared/services/user/user.service';
import { NewUser } from 'app/shared/model/newUser.model';
import { AuthService } from 'app/shared/guard/auth.service';
import { NotificationsService } from 'app/shared/services/notifcations/notifications.service';
import { TranslateService } from '@ngx-translate/core';
/**
 * This component displays the the creation of a new user
 */
@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
   /**
   * Form with the data of the new o update user
   */
  form: FormGroup;
  /**
   * True if is creating a user
   */
  creatingUser: boolean = false;
  /**
   * Array of roles
   */
  roles;
  /**
   * The domains of user
   */
  domains;
  /**
   * The domains 2 of user
   */
  domains2;
  /**
   * The avaible languages
   */
  languages;
  /**
   * The constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param formBuilder Service with the functions related to the forms.
   * @param rolesService Service with the functions related to the roles.
   * @param domainsService Service with the functions related to the domains. 
   * @param commonService Service with the common functions.
   * @param usersService Service with the functions related to the users.
   * @param authService Service with the functions related to the authentications.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   */
  constructor(
    public dialogRef: MatDialogRef<NewUserComponent>,
    private formBuilder : FormBuilder,
    private rolesService: RolesService,
    private domainsService: DomainService,
    private commonService: CommonService,
    private usersService: UserService,
    private authService: AuthService,
    private notifications: NotificationsService,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      'firstname': [null, Validators.compose([Validators.required])],
      'lastname': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required, emailValidator])],
      'language': [null, Validators.compose([Validators.required])],
      'domain': [null, Validators.compose([Validators.required])],
      'domain2': [null, Validators.compose([Validators.required])],
      'roles': [[], Validators.compose([Validators.required])],
      'invitationMail': [false, Validators.compose([])],
    });
  }
  /**
     * Initialize the component and retrieve the data.
     */
  ngOnInit() {
    this.fillRoles();
    this.domains = this.domainsService.getDomains().concat(this.domainsService.getMainDomain()).filter(x => x && x.name).sort((x,y) => (x.name > y.name ? 1 : -1));
    this.domains2 = this.domainsService.getDomains2().concat(this.domainsService.getMainDomain2()).filter(x => x && x.name).sort((x,y) => (x.name > y.name ? 1 : -1));
    this.languages = this.commonService.getLanguages();
  }

  /**
   * Get and fill the roles
   */
  fillRoles(){
    this.roles = this.rolesService.getRoles().filter(role => role &&  role.value_key !== RolesService.MAIN_ROLE)
    if (!this.roles || this.roles.length == 0) setTimeout(() => this.fillRoles(), 1000);
  }
  /**
   * Close the dialog (this component)
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Get the data from the form and create a new user
   */
  public saveData() {
    if (this.form.valid) {
      this.creatingUser = true;

      let user  : NewUser = new NewUser();

      if (this.form.value.firstname) user.firstName = this.form.value.firstname;
      if (this.form.value.lastname) user.lastName = this.form.value.lastname;
      if (this.form.value.email) user.email = this.form.value.email;
      if (this.form.value.language) user.language = this.form.value.language;
      if (this.form.value.domain) user.domain = this.form.value.domain;
      if (this.form.value.domain2) user.domain2 = this.form.value.domain2;
      if (this.form.value.roles) user.roles = this.form.value.roles;

      this.usersService.createUser(user).then(userCreated => {
        this.creatingUser = false;
        this.usersService.checkLastUser();

        if (this.form.value.invitationMail)
          this.authService.sendInvitationMail(user.email, false);

        let link = ['/admin', 'users', 'detail', userCreated.id];
        this.notifications.show(this.translateService.instant('user.created'), link, this.translateService.instant('user.open'), 20000);

        this.dialogRef.close(userCreated.plain());
      });
    }

  }

}
