import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

/**
 * Saves to control if the user is logged in and the routes that can be visited by the user.
 */
@Injectable()
export class AuthGuard implements CanActivate {
    /**
     * 
     * @param authService Service with the functions related to the authentications.
     * @param router Service with the functions related to the router.
     */
    constructor(private authService: AuthService, private router: Router) { }
    /**
   * Checks if a url of the routing cand be activated
   * @param next, the next activated route snapshot
   * @param state, the state that contains the url
   * @returns true if user is logged in
   */
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkLogin(url);
    }
    /**
   * Checks if a child url of the routing cand be activated
   * @param route, the activated route snapshot
   * @param state, the state that contains the url
   * @returns true if user can see and access to the url
   */
    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
    /**
   * Checks if an user is logged in
   * @param url, the url
   * @returns true if the user is logged in
   */
    checkLogin(url: string): boolean {
        if (this.authService.checkLoggedIn()) { return true; }

        // Store the attempted URL for redirecting
        this.authService.redirectUrl = url;
        AuthService.redirectUrl = url;

        // Navigate to the login page with extras

        // this.router.navigate(['/login']);
        let env = environment.env;
        env = (env != 'live') ? '&env=' + env : '';

        this.router.navigate(['/login']);
        return false;
      }
}
