import { Component, Inject, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CollectionsService } from 'app/shared/services/collections/collections.service';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UserService } from 'app/shared/services/user/user.service';
import { Collection } from 'app/shared/model/collection.model';
import { Subscription } from 'rxjs';
import { MatOption } from '@angular/material';
import { AssetFeaturesService } from 'app/shared/services/asset_features/asset-features.service';

/**
 * This component defines the collections component.
 */
@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnDestroy {

  @ViewChild('allBrands', { static: false }) private allBrands: MatOption;

  /**
   * subscription that brings all collections
   */
  private collectionsSubcription: Subscription;

  /**
   * True if collection is uploading
   */
  updatingCollection = false;

  /**
   * Empty name for new collection
   */
  newCollectionName = '';

  /**
   * True if collection is editing
   */
  editingCollection = -1;

  /**
   * From group
   */
  form: FormGroup;

  /**
   * Collections
   */
  collections: Collection[] = [];

  /**
   * All assets brands;
   */
  completedBrands = [];

  /**
   * Brands available
   */
  brands = new FormControl('');

  /**
   * {@link User} with the current user that is logged in.
   */
  currentUser;

  /**
   * @param dialogRef {@link MatDialogRef} with the functions related to the dialog.
   * @param collectionsService {@link CollectionsService} with the functions relate with different features of the collections.
   * @param _changeDetectorRef {@link ChangeDetectorRef} with the functions related to the change detector.
   * @param formBuilder {@link FormBuilder} with the functions related to the forms.
   * @param usersService {@link UserService} with the functions related to the users.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CollectionsComponent>,
    private collectionsService: CollectionsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private AssetFeatureService: AssetFeaturesService,
    private usersService: UserService
  ) {
    this.collectionsSubcription = this.collectionsService.collections.subscribe(collections => { this.collections = collections });
    this.completedBrands = this.AssetFeatureService.getBrands().filter(x => !x.value_key.endsWith('.none.') && x.parent_key == 'root.');

    if (!data.assets && data.collectionId) {
      this.editingCollection = data.collectionId;
      this.collections = this.collections.filter(col => col.id === data.collectionId);
      this.brands.setValue(this.collections[0].brands);
    }
    this.form = this.formBuilder.group({
      'newCollectionName': [name, Validators.compose([Validators.required])],
      'collectionName': [name, Validators.compose([Validators.required])]
    });
    this.currentUser = this.usersService.getCurrentUser();

    dialogRef.afterClosed().subscribe(x => {
    });
  }

  /**
   * Cleanup pending resources.
   */
  ngOnDestroy() {
    if (this.collectionsSubcription) {
      this.collectionsSubcription.unsubscribe();
    }
  }

  /**
   * Update collection
   * @param col Collection
   */
  updateCollection(col: Collection) {
    if (this.isInCollection(col)) {
      this.removeAssetsFromCollection(col);
    } else {
      this.addAssetsToCollection(col, this.brands.value && this.brands.value !== '' ? this.brands.value.filter(brand => brand !== 0) : []);
    }
  }

  /**
   * Remove asset from collection
   * @param col Collection
   */
  removeAssetsFromCollection(col: Collection) {
    this.updatingCollection = true;
    this.dialogRef._containerInstance._config.disableClose = true;

    this.collectionsService.removeAssetsFromCollection(this.data.assets.map(asset => +asset.id), col).then(result => {
      this.data.assets.forEach(asset => {
        if (asset.inCollection.includes(col.id)) {
          const indexCol = asset.inCollection.indexOf(col.id);
          asset.inCollection.splice(indexCol, 1);
        }

        let index;
        if (col.assetsObject) index = col.assetsObject.findIndex(assetObj => asset.id == assetObj.id);
        if (index > -1) col.assetsObject.splice(index, 1);

        index = col.assets.indexOf(this.data.assets[0].id);
        if (index > -1) col.assets.splice(index, 1);
      });

      this.updatingCollection = false;
      this.dialogRef._containerInstance._config.disableClose = false;
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * Add asset to collection
   * @param col Collection
   */
  addAssetsToCollection(col: Collection, brands: Array<String>) {
    this.updatingCollection = true;
    this.dialogRef._containerInstance._config.disableClose = true;

    this.collectionsService.addAssetsToCollection(this.data.assets.map(asset => +asset.id), col, brands).then(result => {
      this.data.assets.forEach(asset => {
        if (!asset.inCollection.includes(col.id)) {
          asset.inCollection.push(col.id);
        }

        if (col.assetsObject && col.assetsObject.filter(x => +x.id === +asset.id).length === 0) {
          col.assets.push(asset.id);
          col.assetsObject.push(asset);
        }
      });

      this.updatingCollection = false;
      this.dialogRef._containerInstance._config.disableClose = false;
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * Add collection
   */
  addCollection() {
    let name = this.form.value.newCollectionName;
    if (name) {
      this.updatingCollection = true;
      this.dialogRef._containerInstance._config.disableClose = true;

      this.collectionsService.createCollection(this.currentUser.id, name).then(col => {
        this.newCollectionName = '';
        this.form.value.newCollectionName = '';
        this.form.reset();

        this.updatingCollection = false;
        this.dialogRef._containerInstance._config.disableClose = false;
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  /**
   * Delete collection
   * @param collection Collection
   * @param event Event
   */
  deleteCollection(collection: Collection, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.updatingCollection = true;
    this.dialogRef._containerInstance._config.disableClose = true;
    this.collectionsService.deleteCollection(collection).then(col => {
      this.updatingCollection = false;
      this.dialogRef._containerInstance._config.disableClose = false;

      if (this.fromEditCollection()) {
        this.dialogRef.close();
      }
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * Checks if the collection can be deleted by the current user.
   * 
   * @param collection 
   * @returns true if user can delete the collection, false in other case
   */
  canDeleteCollection(collection: any) {
    return collection.createdByNew === this.currentUser.id;
  }

  /**
   * Edit collection
   * @param collection Collection
   * @param event Event
   */
  editCollection(collection: Collection, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.editingCollection = collection.id;
  }

  selectAllBrands() {
    if (this.allBrands.selected) {
      this.brands.patchValue([...this.completedBrands.map(item => item.value_key), 0]);
    } else {
      this.brands.patchValue([]);
    }
  }

  selectBrand() {
    if (this.allBrands.selected) {
      this.allBrands.deselect();
      return false;
    }

    if (this.brands.value.length == this.completedBrands.length)
      this.allBrands.select();
  }

  /**
   * Save collection
   * @param collection Collection
   * @param event Event
   */
  saveCollection(collection: Collection, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    let name = this.form.value.collectionName;
    let brandsToSave = this.brands.value && this.brands.value !== '' ? this.brands.value.filter(brand => brand !== 0) : [];

    if (name || JSON.stringify(brandsToSave) !== JSON.stringify(collection.brands)) {
      this.updatingCollection = true;
      this.dialogRef._containerInstance._config.disableClose = true;

      if (name) collection.name = name;

      if (this.fromEditCollection()) collection.brands = brandsToSave;
      
      this.collectionsService.saveCollection(collection).then(x => {
        this.updatingCollection = false;
        this.dialogRef._containerInstance._config.disableClose = false;

        if (this.fromEditCollection()) {
          this.dialogRef.close();
        }

        this._changeDetectorRef.markForCheck();
      });
    }

    this.editingCollection = -1;
  }

  /**
   *True if the component is open from edit collection action
   */
  fromEditCollection() {
    return !this.data.assets && this.data.collectionId;
  }

  /**
   * Close pop up
   */
  close() {
    this.dialogRef.close();
  }

  /**
   *True if asset is in collection
   */
  isInCollection(col: Collection) {
    return this.data.assets && this.data.assets.every(asset => asset.inCollection && asset.inCollection.includes(col.id));
  }
}
