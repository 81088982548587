import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationsComponent } from 'app/shared/components/notifications/notifications.component';

/**
 * Service defined to manage the snack notifications
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  /**
   * Default duration.
   */
  static readonly DEFAULT_DURATION : number = 2500;
  /**
   * Default panel class.
   */
  static readonly DEFAULT_PANEL_CLASS : string = 'success-snack';
  /**
   * Default vertical position.
   */
  static readonly DEFAULT_VERTICAL_POSITION: any = 'top';
  /**
   * Default horizontal position.
   */
  static readonly DEFAULT_HORIZONTAL_POSITION: any = 'start'

  /**
   * Array of current notifications.
   */
  currentNotifications : Array<NotificationData> = [];
  /**
   * Indicates if a notification is being displayed. True if it is being displayed.
   */
  showing : Boolean = false;

  /**
   * Constructor.
   * @param snackbar Service with the functions related to the snack bar.
   */
  constructor(private snackbar: MatSnackBar) { }

  /**
   * Show notification.
   * @param message Message to be showed.
   * @param link Link. (optional)
   * @param linkMessage Link message. (optional)
   * @param duration Notification duration
   * @param panelClass Panel class.
   * @param verticalPosition Vertical position.
   * @param horizontalPosition Horizontal position.
   */
  show(message:string, link: Array<string> = null, linkMessage: string = null,
    duration: number = NotificationsService.DEFAULT_DURATION,
    panelClass: string = NotificationsService.DEFAULT_PANEL_CLASS,
    verticalPosition: any = NotificationsService.DEFAULT_VERTICAL_POSITION,
    horizontalPosition: any = NotificationsService.DEFAULT_HORIZONTAL_POSITION ) {

    let notificationData: NotificationData = new NotificationData(message, link, linkMessage, duration, panelClass, verticalPosition, horizontalPosition);
    this.currentNotifications.push(notificationData);
    this.showNotification(notificationData);
  }

  /**
   * Open the snackbar with the component defined for that and with Notification Data and config.
   * 
   * @param notificationData The info needed to display the snackbar
   */
  private showNotification(notificationData: NotificationData) {
    if (!this.showing) {
      this.showing = true;
      this.snackbar.openFromComponent(NotificationsComponent, {
        data: notificationData.data,
        duration: notificationData.duration,
        panelClass: notificationData.panelClass,
        verticalPosition: notificationData.verticalPosition,
        horizontalPosition: notificationData.horizontalPosition
      }).afterDismissed().subscribe(() => {
        this.currentNotifications.shift();
        this.showing = false;
        if(this.currentNotifications.length > 0) {
          this.showNotification(this.currentNotifications[0]);
        }
      });
    }
  }
}

/**
 * Notification data.
 */
class NotificationData {
  /**
   * Data
   */
  data:any;
  /**
   * Duration
   */
  duration: number;
  /**
   * Panel class
   */
  panelClass: string;
  /**
   * Vertical position.
   */
  verticalPosition: any;
  /**
   * Horizontal position
   */
  horizontalPosition: any;

  /**
   * Constructor
   * @param message Message
   * @param link Link
   * @param linkMessage Link message 
   * @param duration Duration
   * @param panelClass Panel class
   * @param verticalPosition Vertical position
   * @param horizontalPosition Horizontal position
   */
  constructor(
    message: string, link: Array<string>,
    linkMessage: string,
    duration: number,
    panelClass: string,
    verticalPosition: any,
    horizontalPosition: any
  ) {
      let data = {message:message};
      if (link) data['link'] = link;
      if (linkMessage) data['linkMessage'] = linkMessage;

      this.data = data;
      this.duration = duration;
      this.panelClass = panelClass;
      this.verticalPosition = verticalPosition;
      this.horizontalPosition = horizontalPosition;
  }
}
