import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * This component defines the view to select files to check out
 */
@Component({
  selector: 'app-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.scss']
})
export class SelectFileComponent {
  
  /**
   * array with selected files
   */
  selectedFiles = [];

  /**
   * The constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data Data needed to show the selectable files
   */
  constructor(private dialogRef: MatDialogRef<SelectFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  /**
   * Method executed to close the dialog
   */
  onNoClick(val=false): void {
    this.dialogRef.close(val ? this.selectedFiles : false);
    this.dialogRef.afterClosed();
  }
}
