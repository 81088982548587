import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { RolesService } from '../user/roles.service';

/**
 * Service defined to operate with {@link communication}.
 */
@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   * @param rolesService Service with the functions related to the roles.
   */
  constructor(
    private hcmsService: HCMSService,
    private rolesService: RolesService
  ) { }

  /**
   * Checks if the user passed as parameter can write internal comments.
   * @param user User to check.
   * @returns {boolean} True if can write internal comments, else false.
   */
  canWriteInternalComments(user): boolean {
    return this.rolesService.checkRole(user, RolesService.ADMIN_ROLE)
      || this.rolesService.checkRole(user, RolesService.PROJECT_MANAGER_ROLE, true);
  }

/**
   * Checks if the user passed as parameter can read internal comments.
   * @param user User to check.
   * @returns {boolean} True if can read internal comments, else false.
   */
  canReadInternalComments(user): boolean {
    return this.rolesService.checkRole(user, RolesService.ADMIN_ROLE)
      || this.rolesService.checkRole(user, RolesService.PROJECT_MANAGER_ROLE, true)
      || this.rolesService.checkRole(user, RolesService.PRODUCTION_ROLE);
  }

 /**
  * Get communication type.
  * @param {string} assetType Asset type.
  * @returns {string} Partial route for api calls
  */
  getCommunicationType(assetType: string) {
    const COMMUNICATION_TYPE_OPTIONS = {
      // Tasks and jobs
      'project.': 'entity/communication_project',
      'order.': 'entity/communication_job',
      'task.cost_estimate.': 'entity/communication_task_cost_estimate',
      'task.coordination.': 'entity/communication_task_coordination',
      'task.final_approval.': 'entity/communication_task_final_approval',
      'task.questions.': 'entity/communication_task_questions',
      'task.metadata.': 'entity/communication_task_metadata',
      'task.others.': 'entity/communication_task_others',
      'task.text.': 'entity/communication_task_text',
      'task.proof_reading.': 'entity/communication_task_proof_reading',
      'task.creation.': 'entity/communication_task_creation',
      'task.adaptation.': 'entity/communication_task_adaptation',
      'task.final_art.': 'entity/communication_task_final_art',
      'task.image_editing.': 'entity/communication_task_image_editing',
      'task.translation.': 'entity/communication_task_translation',
      'task.moving_image.': 'entity/communication_task_moving_image',
      'task.programming.': 'entity/communication_task_programming',
      'task.online.': 'entity/communication_task_online',
      'task.pagination.': 'entity/communication_task_production',

      // Assets
      'picture.': 'entity/communication_picture',
      'video.': 'entity/communication_video',
      'presentation.': 'entity/communication_presentation',
      'office.': 'entity/communication_office',
      'pdf.': 'entity/communication_pdf',
      'layout.': 'entity/communication_layout',
      'zip.': 'entity/communication_picture',
      'extended-media.html.': 'entity/communication_extended-media_html',
      'document.design.': 'entity/communication_document_design.',
      'spreadsheet.': 'entity/communication_spreadsheet',
      'audio.': 'entity/communication_audio',
      'group.recommendation.': 'entity/communication_group_recommendation',
      'text.': 'entity/communication_text',
    };

    const DEFAULT_OPTION = 'entity/communication';
    
    return COMMUNICATION_TYPE_OPTIONS[assetType] || DEFAULT_OPTION;
  }

  /**
   * Get communication.
   * @param {string} id Asset ID.
   * @param {string} type Asset type.
   * @returns 
   */
  getCommunication(id, type: string = '') {
    let route = this.getCommunicationType(type);
    return this.hcmsService.get().one(route, id).get().toPromise();
  }

  /**
   * Edit comment.
   * @param {Asset} asset Asset with comment to edit.
   * @param comment Comment to edit.
   * @returns Promise.
   */
  editComment(asset, comment) {
    return this.getCommunication(asset.id, asset.type).then(current => {

      if (!current.communication) {
        current.communication = [];
      }

      const oldComment = current.communication.find(x => x.timestamp === comment.timestamp);

      if (oldComment) {
        oldComment.entry = comment.entry;
      }

      return current.put().toPromise();
    });
  }

  /**
   * Delete comment.
   * @param {Asset} asset Asset with comment to delete.
   * @param comment Comment to delete.
   * @returns Promise.
   */
  deleteComment(asset, comment) {
    return this.getCommunication(asset.id, asset.type).then(current => {

      if (!current.communication) {
        current.communication = [];
      }

      current.communication.splice(current.communication.indexOf(comment));

      return current.put().toPromise();
    });
  }

  /**
   * Save comment.
   * @param {Asset} asset Asset to which a comment is to be added
   * @param comment Comment to added.
   * @param status Asset status (optional).
   * @returns Asset with comment added.
   */
  saveComment(asset, comment, status?) {
    return this.getCommunication(asset.id, asset.type).then(current => {

      if (!current.communication) {
        current.communication = [];
      }
      if(comment.entry != null){

        current.communication.push(comment);
      }

      if (asset.responsible){
        current.responsible = asset.responsible;
      }

      current.workflowStep = asset.workflowStep;

      return current.put().toPromise();
    });
  }

  /**
   * 
   * @param {Asser} asset Asset to which a comment is to be replied.
   * @param comment Comment to reply.
   * @param reply Reply.
   * @returns Asset with reply added.
   */
  saveReply(asset, comment, reply) {
    return this.getCommunication(asset.id, asset.type).then(current => {

      let commentIndex = this.findCommentParent(current, comment);

      if (!current.communication[commentIndex].reply) {
        current.communication[commentIndex].reply = [];
      }

      current.communication[commentIndex].reply.push(reply);

      return current.put().toPromise();
    });
  }

  /**
   * Find comment parent.
   * @param {string} asset Asset ID
   * @param {string} comment Comment parent.
   * @returns Comment parent.
   */
  findCommentParent(asset, comment) {
    let index = -1;
    let found = false;
    let countC = 0;
    let countR = 0;
    let roundTimestamp = Math.round(comment.timestamp / 1000) * 1000;

    while (!found && countC < asset.communication.length) {
      if (roundTimestamp == asset.communication[countC].timestamp) {
        index = countC;
        found = true;
      } else {
        countR = 0;
        if (asset.communication[countC].reply) {
          while (!found && countR < asset.communication[countC].reply.length) {
            if (roundTimestamp == asset.communication[countC].reply[countR].timestamp) {
              index = countC;
              found = true;
            }
            countR++;
          }
        }
      }
      countC++;
    }

    return index;
  }
}
