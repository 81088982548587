import { AppSettings } from "../app.settings";
/**
 * This class defines the structure of Request objects
 */
export class RequestObject {
    /**
     * id of RequestObject
     */
    id: number;
    /**
     * name of RequestObject
     */
    name: string;
    /**
     * domain of RequestObject
     */
    domain: string = AppSettings.DOMAIN;
    /**
     * Identifier of the creator of the RequestObject
     */
    createdByNew: number;
    /**
     * Identifier of the modified of the RequestObject
     */
    modifiedByNew: number;
    /**
     * workflow step of RequestObject
     */
    workflowStep: number;
    /**
     * Identifier of the manager of the RequestObject
     */
    manager_rel: Array<Number> = [];
    /**
     * Identifier of the manager of the RequestObject
     */
    mainManager: Number;
    /**
     * owner_rel of RequestObject
     */
    owner_rel: Array<Number> = [];
    /**
     * info of RequestObject
     */
    info: string;
    /**
     * priority of RequestObject
     */
    priority: string;
    /**
     * priority of RequestObject
     */
    priorityCntr: string;
    /**
     * date the RequestObject starts 
     */
    startDate: string;
    /**
     * deadline of RequestObject
     */
    deadline: string;
    /**
     * jobs of RequestObject
     */
    jobs: Array<number>;
    /**
     * Array with the ids of the parents of the RequestObject
     */
    parents: Array<number>;
    /**
     * {@link CustomerData}  of RequestObject
     */
    customerdata : Array<CustomerData> = [new CustomerData()];
    /**
     * {@link Consulting}  of RequestObject
     */
    consulting : Array<Consulting> = [new Consulting()];
}

/**
 * This class defines the shorcut for requests
 */
export class DetailedRequest extends RequestObject {
    /**
     * type of detailed request
     */
    type: string = 'request.';
    /**
     * workflow of detailed request
     */
    workflow: number = AppSettings.WORKFLOW_BRIEFING;
    /**
     * priorityCntr of detailed request
     */
    priorityCntr: string;
    /**
     * Array with the ids of the elements from which the relationship
     *  with this request is to be deleted
     */
    removeRelation: Array<number>;
    /**
     * channel of detailed request
     */
    channel: string;
    /**
     * budget of detailed request
     */
    budget: number;
    /**
     * channel hubz of detailed request
     */
    channel_hubz: string;
    /**
     * customer of detailed request
     */
    client: string;
    /**
     * Files of agency data
     */
    agencydata: Array<AgencyData> = [new AgencyData()];
    /**
     * Files of final data
     */
    finaldata: Array<FinalData> = [new FinalData()];
    /**
     * Files of customer data
     */
    customerdata: Array<CustomerData> = [new CustomerData()];
    /**
     * Files of consulting data
     */
    consulting: Array<Consulting> = [new Consulting()];
    /**
     * Files of estimations data
     */
    costestimate: Array<CostEstimate> = [new CostEstimate()];
}

/**
 * Defines the Request project structure
 */
export class RequestProject extends RequestObject {
    /**
     * type of RequestJob
     */
    type: string = 'project.';
    /**
     * workflow of RequestJob
     */
    workflow: number = AppSettings.WORKFLOW_PROJECT;
}

/**
 * Defines the Request job structure
 */
export class RequestJob extends RequestObject {
    /**
     * type of RequestJob
     */
    type: string = 'order.';
    /**
     * workflow of RequestJob
     */
    workflow: number = AppSettings.WORKFLOW_ACTION;

    /**
     * {@link AgencyData}  of RequestJob
     */
    agencydata : Array<AgencyData> = [new AgencyData()];
    /**
     * {@link FinalData} of RequestJob
     */
    finaldata : Array<FinalData> = [new FinalData()];
    /**
     * {@link CostEstimate}  of RequestJob
     */
    costestimate : Array<CostEstimate> = [new CostEstimate()];
}

/**
 * Defines customer file for requests
 */
export class CustomerData {
    /**
     * id of CustomerData
     */
    id: number;
    /**
     * type of CustomerData
     */
    type: string = 'group.';
    /**
     * name of CustomerData
     */
    name: string = "01 Customer data";
    /**
     * domain  of CustomerData
     */
    domain : string = AppSettings.DOMAIN;
    /**
     *  Array with the ids of the elements from which the relationship with this Customer is to be deleted
     */
    removeRelation: Array<number>;
}

/**
 * Defines agency file for requests
 */
export class AgencyData {
    /**
     * id of AgencyData
     */
    id: number;
    /**
     * type of AgencyData
     */
    type: string = 'group.';
    /**
     * name of AgencyData
     */
    name: string = "02 Agency data";
    /**
     * domain  of AgencyData
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines final file for requests
 */
export class FinalData {
    /**
     * id of FinalData
     */
    id: number;
    /**
     * type of FinalData
     */
    type: string = 'group.';
    /**
     * name of FinalData
     */
    name: string = "03 Final data";
    /**
     * domain  of FinalData
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines estimation file for requests
 */
export class CostEstimate {
    /**
     * id of CostEstimate
     */
    id: number;
    /**
     * type of CostEstimate
     */
    type: string = 'group.';
    /**
     * name of CostEstimate
     */
    name: string = "04 Cost Estimate";
    /**
     * domain  of CostEstimate
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines consulting file for requests
 */
export class Consulting {
    /**
     * id of Consulting
     */
    id: number;
    /**
     * type of Consulting
     */
    type: string = 'group.';
    /**
     * name of Consulting
     */
    name: string = "05 Consulting";
    /**
     * domain  of Consulting
     */
    domain : string = AppSettings.DOMAIN;
}

