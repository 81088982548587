import { Asset } from "./asset.model";
import { AppSettings } from "../app.settings";

/**
 * This class defines the structure for the email data
 */
export class Mail extends Asset {
    /**
     * usage of Mail
     */
    usage: string;
    /**
     * id of asset of the Mail
     */
    asset: number;
    /**
     * id user of Mail
     */
    user: number;
    /**
     * data of Mail
     */
    data: string;
    /**
     * array of material ids of Mail
     */
    materials: Array<number>;
    /**
     * domain of Mail
     */
    domain: string = AppSettings.DOMAIN;
    
    /**
     * message of Mail
     */
    message: string;
    /**
     * The constructor
     * @param name name of mail 
     * @param usage usage of mail 
     * @param asset asset of mail 
     * @param user user of mail 
     * @param message message of mail 
     */
    constructor(name: string = null, usage: string = null, asset: number = 0, user: number = 0, message: string = '') {
        super();

        this.name = name;
        this.usage = usage;
        this.asset = asset;
        this.user = user;
        if (asset != 0)
            this.materials = [asset];

        this.message = message;
    }
}
