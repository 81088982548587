import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * This component defines the main view of the confirmation component.
 */
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  /**
   * Prefix
   */
  prefix = '';
  /**
   * Params
   */
  params: any = {};

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data Data required for the component.
   */
  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      if (typeof this.data === 'string') {
        this.prefix = this.data;
      } else {
        this.prefix = this.data.prefix;
        this.params = this.data.params;
      }
  }

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

}
