import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { saveAs } from 'file-saver';
import Utils from 'app/shared/utils/utils';
import { MimetypeService } from 'app/shared/services/mimetype/mimetype.service';

import * as JSZip from 'jszip';
import { DatePipe } from '@angular/common';
import { TrackingService } from 'app/shared/services/tracking/tracking.service';

/**
 * This component defines the view of the download component.
 */
@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss']
})
export class DownloadDialogComponent {

  /**
   * Download formats
   */
  public downloadFormats: string[] = [];
  /**
   * Format file
   */
  public format: string;
  /**
   * JSZip library
   */
  private jszip: JSZip;

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data Data required for the component.
   * @param assetService Service with the functions related to the assets.
   * @param mimetypeService Service with the functions related to the mime type.
   * @param datepipe Pipe with the date pipe used to format dates.
   * @param trackingService Service with the functions related to the tracking. 
   */
  constructor(
    private dialogRef: MatDialogRef<DownloadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private assetService: AssetService,
    private mimetypeService: MimetypeService,
    private datepipe: DatePipe,
    private trackingService: TrackingService
  ) {
    Object.keys(data.assets[0].downloads).forEach(x => {
      if (data.assets.every(y => y.downloads[x])) {
        this.downloadFormats.push(x);
      }
    });

    if (this.downloadFormats.length === 1) {
      this.format = this.downloadFormats[0];
      this.dialogRef.close(true);
    }

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {

        if (data.assets.length > 1) {
          this.jszip = new JSZip();
        }

        data.assets.forEach(x => {

          data.downloading.push(x.id);
          setTimeout(() => {
            this.assetService.getFileBlob(x.downloads[this.format].link + '?r=' + Utils.randomHash()).subscribe(fileData => {
              const blob = new Blob([fileData], { type: x.downloads[this.format].mime });
              const mimetype = this.mimetypeService.getMimetypeByMimetype(x.downloads[this.format].mime);
              const appendix = this.format.split('_')[1] !== 'original' ? '_' + this.format.split('_')[1] : '';

              if (data.assets.length > 1) {
                this.jszip.file(x.name.replace(/\.[^/.]+$/, '') + appendix + (mimetype ? mimetype.extension : ''), blob);

                if (data.downloading.length === 1) {
                  this.jszip.generateAsync({ type: "blob" })
                    .then(function (content) {
                      let date = datepipe.transform(new Date(), 'yyyyMMdd_hhmm');
                      saveAs(content, data.zipName + date + '.zip');
                      data.downloading.splice(data.downloading.indexOf(x.id), 1);
                      this.trackingService.sendMatomoDownload(x.id);
                    });
                } else {
                  data.downloading.splice(data.downloading.indexOf(x.id), 1);
                }
              } else {
                saveAs(blob, x.name.replace(/\.[^/.]+$/, '') + appendix + (mimetype ? mimetype.extension : ''));
                data.downloading.splice(data.downloading.indexOf(x.id), 1);
                this.trackingService.sendMatomoDownload(x.id);
              }
            });
          }, 100);
        });
      }
    });
  }

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Get size
   * @param sizeBytes Size bytes
   * @returns Size bytes / 1024 / 1024
   */
  getSize(sizeBytes) {
    return sizeBytes / 1024 / 1024;
  }

}
