import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'app/shared/services/user/user.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { MailService } from 'app/shared/services/mail/mail.service';

@Component({
  selector: 'app-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.scss']
})
export class EditRoleDialogComponent{

  /**
   * Form with the data for change roles.
   */
  form: FormGroup;
  /**
   * Indicates that data is being saved
   */
  saving: boolean = false;
  /**
   * @ignore
   */
  cancel: boolean = false;
  /**
   * Asset
   */
  asset = null;
  /**
   * User
   */
  user = null;
  /**
   * Asset Id
   */
  assetId = null;

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data Object with user, team and assetId.
   * @param usersService Service with the functions related to the users.
   * @param formBuilder Service with the functions related to the forms.
   * @param actionService Service with the functions related to the actions.
   * @param mailService Service with the functions related to the mails.
   */
  constructor(
    private dialogRef: MatDialogRef<EditRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UserService,
    private formBuilder : FormBuilder,
    private actionService: ActionsService,
    private mailService: MailService
  ) {
      this.form = this.formBuilder.group({
        'role': [data.user.key, Validators.compose([Validators.required])]
      });

      if (data && data['user']) this.user = data['user'];
      if (data && data['team']) this.asset = data['team'];
      if (data && data['assetId']) this.assetId = data['assetId'];
  }

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.cancel = true;
    this.dialogRef.close();
  }

  /**
   * Get the available roles to change
   * @returns roles
   */
  getAddMemberRoles () {
    let roles = this.usersService.getFilteredMemberRoles (this.user.id, this.asset.owner, this.asset.pm);
    roles = roles.filter(role => role.key == UserService.OWNER || role.key == UserService.PROJECT_MANAGER || role.key == UserService.PRODUCTION || role.key == UserService.CO_MANAGER || role.key == UserService.CO_OWNER);
    return roles;
  }

  /**
   * Save data
   */
  saveData () {
    if (!this.cancel && this.form.value.role) {
        this.saving = true;
        this.usersService.changeRoleInTeam(this.asset, this.user.role, this.form.value.role, this.user.id ).then(result => {
          if (this.form.value.role == UserService.PROJECT_MANAGER) {
            this.mailService.sendProjectManagerMail(this.assetId, this.user.id);
          }

          this.dialogRef.close(result.plain())
        });
    }
  }
}
