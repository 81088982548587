import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { Collection } from 'app/shared/model/collection.model';
import { UserService } from '../user/user.service';
import { AppSettings } from 'app/shared/app.settings';
import { Mail } from 'app/shared/model/mail.model';
import { MailService } from '../mail/mail.service';
import { BehaviorSubject } from 'rxjs';
import { NotificationsService } from '../notifcations/notifications.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Service defined to operate with {@link Collections}.
 */
@Injectable({
  providedIn: 'root'
})
export class CollectionsService {
  /**
   * Array of collections 
   */
  collections: BehaviorSubject<Collection[]> = new BehaviorSubject<Collection[]>([]);

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   * @param usersService Service with the functions related to the users.
   * @param mailService Service with the functions related to the mails.
   */
  constructor(
    private hcmsService: HCMSService,
    private usersService: UserService,
    private mailService: MailService,
    private notifications: NotificationsService,
    private translateService: TranslateService
  ) {
    this.loadUserCollection();
  }

  /**
   * Get collection by user.
   * @param {string} userid User ID.
   * @returns User collections.
   */
  public getCollectionByUser(userid) {
    return this.hcmsService.get().one('entity/collection?query=user=' + userid + '%26markDeletion!=1').get().toPromise().then((result) => {
      return result;
    })
  }

  /**
   * Load user collections.
   */
  public loadUserCollection() {
    let user = this.usersService.getCurrentUser();
    if (user) {
      this.getCollectionByUser(user.id).then(col => {
        if (col.result && col.result.length > 0) {
          let userCollections = col.result.filter(x => !x.markForDeletion);

          if (user.brand && user.brand.length > 0) {
            userCollections = userCollections.filter(x => !x.brands || x.brands.some(y => user.brand.includes(y)));
          }

          this.collections.next(userCollections);
        } else {
          this.createCollection(user.id);
        }

        this.collections.value.map(collection => {
          if (!collection.assets) collection.assets = [];
          if (!collection.assetsObject) collection.assetsObject = [];
        })

      });
    }
  }

  /**
   * Get collection by ID.
   * @param {string} id Collection ID.
   * @returns Collection
   */
  public getCollectionById(id) {
    return this.hcmsService.get().one('entity/collection', id).get().toPromise();
  }

  /**
   * Create collection.
   * @param {string} userid User ID.
   * @param {string} name Collection name.
   * @returns Collection created.
   */
  createCollection(userid, name = 'Collection') {
    let user = this.usersService.getCurrentUser();
    let collection = new Collection();
    collection.name = name;
    collection.user = [+userid];

    delete collection.created;
    delete collection.createdAttr;

    return this.hcmsService.get().all('entity/collection').post(collection).toPromise().then(result => {
      if (result) {
        user.collection = result.id;
        this.usersService.updateCurrentUser(user);

        if (!result.assets) result.assets = [];
        if (!result.assetsObject) result.assetsObject = [];

        this.collections.value.push(result);
        return result;
      }
    });
  }

  /**
   * Add assets to collection.
   * @param {number[]} assets Assets to be added.
   * @param {Collection} collection Collection to which they will be added.
   * @returns Collection updated (promise).
   */
  addAssetsToCollection(assets: Array<Number>, collection: Collection, brands: Array<String>) {
    return this.getCollectionById(collection.id).then(col => {
      if (!col.assets) {
        col.assets = [];
      }

      if (!col.brands) {
        col.brands = [];
      }

      assets.forEach(asset => {
        if (!col.assets.includes(asset)) {
          col.assets.push(asset);
        }
      });

      brands.forEach(brand => {
        if (!col.brands.includes(brand)) {
          col.brands.push(brand);
        }
      });

      const indexCol = this.collections.value.findIndex(col => col.id === collection.id);
      this.collections.value.splice(indexCol, 1, collection);
      return col.put().toPromise();
    });
  }

  /**
   * Remove assets from collection.
   * @param {number[]} assets Assets to be deleted.
   * @param {Collection} collection Collection to which they will be deleted.
   * @returns Collection updated (promise).
   */
  removeAssetsFromCollection(assets: Array<Number>, collection: Collection) {
    return this.getCollectionById(collection.id).then(col => {
      col.removeRelation = [];
      if (!col.assets) {
        col.assets = [];
      }

      assets.forEach(assetId => {
        if (col.assets.includes(assetId)) {
          const index = col.assets.indexOf(assetId);
          col.assets.splice(index, 1);
          col.removeRelation.push(+assetId);
        }
      });

      this.collections.value.map(item => {
        if (collection.id === item.id) {
          item.assets = col.assets;
        }
      })

      return col.put().toPromise();
    });
  }

  /**
   * Delete collection.
   * @param {Collection} collection  Collection to be deleted.
   * @returns Collection deleted.
   */
  deleteCollection(collection: Collection) {
    let user = this.usersService.getCurrentUser();

    return this.getCollectionById(collection.id).then(col => {
      col.markForDeletion = true;

      let currentCollections = this.collections.value;
      const index = currentCollections.findIndex(col => col.id === collection.id);
      currentCollections.splice(index, 1);

      if (currentCollections.length === 0) this.createCollection(user.id);

      this.collections.next(currentCollections);

      col.put().toPromise();
      return col;
    });
  }

  /**
   * Save collection.
   * @param {Collection} collection Collection to be saved. 
   * @returns Collection updated.
   */
  async saveCollection(collection: Collection) {
    let newCollection = Object.assign(new Collection, this.hcmsService.get().copy(collection).plain());
    delete newCollection.assets;

    let updateCollection = await this.hcmsService.get().one('entity/collection', newCollection.id).get().toPromise();
    updateCollection = Object.assign(updateCollection, newCollection);

    return updateCollection.save().toPromise();
  }

  /**
   * Share collection.
   * @param {Collection} collection Collection to be shared. 
   * @param {string} recipients Array recipients.
   */
  async shareCollection(collection: Collection, recipients) {
    /* let recipentsArray = recipients.split(',');
    recipentsArray = [].concat(...recipentsArray.map(x => x.split(';')));
    recipentsArray = recipentsArray.map(x => x.trim());*/

    if (collection.brands && collection.brands.length > 0) {
      recipients = recipients.filter(user => !user.brand || user.brand.some(brand => collection.brands.includes(brand)))
    }


    let recipentsArray = recipients.map(x => x.email);

    const mailsTo = [];

    let col = await this.hcmsService.get().one('entity/collection', collection.id).get().toPromise();

    for (let recipent of recipentsArray) {
      let assetIds = await this.usersService.getUserByEmail(recipent);

      if (assetIds) {
        if (assetIds.result) {
          assetIds = assetIds.result.map(asset => asset.id);
        } else {
          assetIds = assetIds.map(asset => asset.id);
        }

        assetIds.forEach(id => {
          if (!col.user.includes(id)) {
            col.user.push(id);
            mailsTo.push(id);
          } else {
            this.notifications.show(this.translateService.instant('sammlung_already_created') + recipent, null, null, 2500, 'error-snack');
          }
        });
      }
    }

    this.hcmsService.get().one('entity/collection', collection.id).get().subscribe(newCol => {
      newCol.user = col.user;

      newCol.put().subscribe(result => {
        mailsTo.forEach(y => {
          let mail: Mail = new Mail(AppSettings.MAIL_SHARE_COLLECTION + '_' + collection.id + '_' + y, AppSettings.MAIL_SHARE_COLLECTION, +collection.id, +y);
          this.mailService.saveMail(mail, true, 'sammlung_erfolgreich_geteilt');
        });
      });
    });
  }
}
