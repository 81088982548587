import { Component, Input, OnInit } from '@angular/core';

/**
 * @ignore
 */
@Component({
  selector: 'app-notes-widget',
  templateUrl: './notes-widget.component.html',
  styleUrls: ['./notes-widget.component.scss']
})
export class NotesWidgetComponent implements OnInit {

  /**
   * The asset to which the notes belong
   */
  @Input() asset;

  constructor() { }

  ngOnInit() {
  }
}
