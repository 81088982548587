import { Injectable } from '@angular/core';
import { HCMSService } from 'app/shared/services/satellites/hcms.service';
import { AppSettings } from 'app/shared/app.settings';
import { AssetModel, TypeValidation } from '../model/asset-model';
import { NotificationsService } from 'app/shared/services/notifcations/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { MimetypeService } from 'app/shared/services/mimetype/mimetype.service';
import { DamUtils } from '../utils/dam-utils';
import { UserService } from 'app/shared/services/user/user.service';
import { WorkflowService } from 'app/shared/services/workflows/workflow.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { DatePipe } from '@angular/common';
import { LiquidCache, LiquidCacheService } from 'ngx-liquid-cache';
import { Observable, from } from 'rxjs';
import { CheckOut } from '../model/check-out';
import { BaseService } from 'app/shared/services/base/base.service';

/**
 * Service defined to manage operations of DAM.
 */
@Injectable({
  providedIn: 'root',
})
export class DamService extends BaseService<any> {

  /* Public */
  public static MULTIPLE_VALUES = " -- Multiple Values -- ";

  /* Private */
  private MULTIDAM_KEY = "_multidam";
  /**
   * @ignore
   */
  private DEFAULT_CREATION_DATE_DATA = 0;
  /**
   * Array with all the dam
   */
  private static list = [];
  /**
   * The keywords by which you can filter the dam
   */
  keywords;

  // private static list = [];

  protected entity = 'entity/dam';
  protected options = {
    query: 'show=true',
    limit: AppSettings.LIMIT,
    offset: 0,
    order: '-createdAttr'
  };

  /**
   * 
   * @param hcmsService Service with the functions related to the censhare.
   * @param notifications Service with the functions related to the notifications.
   * @param workflowsService Service with the functions related to the workflows.
   * @param datepipe Pipe with the date pipe used to format dates.
   * @param translateService Service with the functions related to the translations.
   * @param mimetypeService Service with the functions related to the mime types.
   * @param usersService  Service with the functions related to the users.
   * @param rolesService Service with the functions related to the roles.
   * @param cache Service with the functions related to the cache.
   */
  constructor(protected hcmsService: HCMSService, private notifications: NotificationsService, private workflowsService: WorkflowService, public datepipe: DatePipe,
    private translateService: TranslateService, private mimetypeService: MimetypeService, protected usersService: UserService, private rolesService: RolesService,
    private cache: LiquidCacheService) {

    super(hcmsService, usersService);

    this.init();

    this.fillKeywords();
  }

  protected init() {
    super.init();

    this.getAssets();
  }

  /**
   * Set the list
   * @param list {@link list}
   */
  /*public setList(list) {
    DamService.list = list;
  }*/

  /**
   * Get list
   * @returns list
   */
  /*public getList() {
    return DamService.list;
  }*/

  /**
   * Fill keywords
   */
  public fillKeywords() {
    this.getAllKeywords().subscribe(keywords => {
      this.keywords = keywords;
    });
  }

  /**
   * Reset keywords
   */
  public resetKeywords() {
    this.cache.remove('keywords');
    this.fillKeywords();
  }

  /**
   * Get dam elements
   * @param {number} page Number of page 
   * @returns Dam elements
   */
  /*public getDamElements(page = 0) {
    return this.hcmsService.get().one('entity/dam?query=show=true&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Get dam elements by ids
   * @param {Array} ids Element dam ids
   * @returns Promise of response
   */
  /*public getDamElementsByIds(ids) {
    let query = "";
    ids.forEach(id => query += 'id=' + id + '|');
    query = query.substring(0, query.length - 1);
    return this.hcmsService.get().one('entity/dam?query=' + query).get().toPromise();
  }*/

  /**
   * Get Keywords
   * @returns keywords
   */
  protected fillData(asset) {

    asset.item_name = asset.name;
    asset.checked = false;

    if (asset.cntr_modified) {
      asset.modifiedDate = asset.cntr_modified;
    } else if (asset.modifiedAttr) {
      asset.modifiedDate = asset.modifiedAttr;
    }

    if (asset.workflow && asset.workflowStep) {
      asset.step = this.getWorkflowStep(asset);
      asset.state = this.getStateWithWorkflowStep(asset);
      if (asset.step && asset.step.wf_step) {
        asset.status = this.getWorkflowStatus(asset.step.wf_step);
      }
    }

    if (asset.valid_until) {
      let validDate: Date = new Date(asset.valid_until);
      asset.valid_until = this.datepipe.transform(validDate, 'yyyy-MM-dd');
    }

    asset.rawType = asset.type;
    if (asset.mimetype) {
      asset.type = this.getMimetypeByMimetype(asset.mimetype).name;
      asset.file = asset.type;
    }

    if (asset.modifiedByNew) {
      asset.user = this.usersService.getUserData(asset.modifiedByNew);
    } else if (asset.createdByNew) {
      asset.user = this.usersService.getUserData(asset.createdByNew);
    }

    if (asset.multidic) {
      asset.multidic = asset.multidic.filter(x => x.dic_master);
    }

    if (asset.dic || (asset.multidic && asset.multidic.length > 0)) {
      asset.backgroundImage = asset.image + '/thumbnail';
    } else if (asset.preview || (asset.pages && asset.pages.length > 0)) {
      asset.backgroundImage = asset.image + '/storage_preview'
    } else if (asset.mainPictures && asset.mainPictures.length > 0) {
      asset.backgroundImage = asset.mainPictures[0].image + '/teaser';
    }

  }
  getKeywords() {
    return this.keywords;
  }

  @LiquidCache('keywords')

  /** 
   * Get all the keywords and returns them
   */
  getAllKeywords(): Observable<any> {
    return from(this.getKeywordsData());
  }

  /**
   * Obtains keyword data
   * @param {number} page Page number
   * @returns Keywords
   */
  async getKeywordsData(page = 0) {
    let keywords = []
    await this.hcmsService.get().one('entity/keyword?limit=500&offset=' + page).get().toPromise().then(async data => {
      keywords = keywords.concat(data.result);
      if (data.offset + data.limit < data['total-count']) {
        await this.getKeywordsData(data.offset + data.limit).then(data => {
          keywords = keywords.concat(data);
        });
      }
    });

    return keywords;
  }

  /**
   * Gets an asset to be updated
   * @param {string} id Asset id
   * @param {string} type Asset type
   * @returns Promise of response
   */
  getAssetToUpdate(id, type) {
    let route = DamUtils.getEntity(type);
    return this.hcmsService.get().one(route, id).get().toPromise();
  }

  /**
   * Get mimetype by mimetype
   * @param mimetype Mimetype
   * @returns Mimetype
   */
  getMimetypeByMimetype(mimetype) {
    return this.mimetypeService.getMimetypeByMimetype(mimetype);
  }

  /**
   * Get workflow step
   * @param asset Asset from which it is obtained
   * @returns Workflow step
   */
  getWorkflowStep(asset) {
    return this.workflowsService.getWorkflowStep(asset.workflow, asset.workflowStep);
  }

  /**
   * Get status and workflow step
   * @param asset Asset from which it is obtained
   * @returns Workflow and workflow step
   */
  getStateWithWorkflowStep(asset) {
    return this.workflowsService.getStateWithWorkflowStep(asset.workflow, asset.workflowStep);
  }

  /**
   * Get workflow step
   * @param step Step to obtain the workflow
   * @returns Workflow step
   */
  getWorkflowStatus(step) {
    return this.workflowsService.getWorkflowStatus(step);
  }

  /**
   * Create a new asset
   * @param file Asset 
   * @returns Asset
   */
  async newAsset(file) {

    let currentUser = this.usersService.getCurrentUser();
    let fileFormData = new FormData();
    let mime = file.type;
    let extension = DamUtils.getFileExtension(file.name);
    let type = this.mimetypeService.getMimetypeByMimetype(mime);
    if (!type && extension) {
      type = this.mimetypeService.getMimetypeByExtension(extension);
    }

    let newAsset: AssetModel = new AssetModel();
    if (type && type.extension == '.svg') newAsset.svgfile = true;

    newAsset.name = file.name;
    newAsset.type = type.def_assettype;
    newAsset.downloadLink = "formdata:file0";
    newAsset.domain = AppSettings.APP_DEFAULT_DOMAIN;
    newAsset.show = true;
    newAsset.workflow = DamUtils.getWorkflowId(newAsset.type);
    newAsset.workflowStep = DamUtils.WORKFLOW_INITIAL_STEP;

    fileFormData.append("entity", JSON.stringify(newAsset));
    fileFormData.append("file0", file);

    let route = DamUtils.getEntity(newAsset.type);
    return await this.hcmsService.get().all(route).post(fileFormData).toPromise().then(asset => {
      return asset;
    })
      .catch(error => {
        if (error.statusText.toLowerCase() === 'unknown error') {
          this.notifications.show(this.translateService.instant('dam.asset_created') + ': ' + newAsset.name, null, null, 5000);

          setTimeout(() => {
            return newAsset;
          }, 5000);
        }
      });
  }

  /**
   * Updates the content of an asset. Executes the {@link getAssetToUpdate} method
   * @param asset 
   * @param type 
   * @param file 
   * @returns 
   */
  public updateAssetFileContent(asset, type, file) {

    return this.getAssetToUpdate(asset.id, type).then(current => {

      let updated: any = Object.assign({}, current.plain());
      updated.workflow = DamUtils.getWorkflowId(type);
      updated.workflowStep = DamUtils.WORKFLOW_INITIAL_STEP;
      updated.downloadLink = "formdata:file0";
      updated.reloadPreview = true;

      let extension = DamUtils.getFileExtension(asset.name);
      if (updated.mimetype == "image/svg+xml" || extension == '.svg') asset.svgfile = true;

      let route = DamUtils.getEntity(type);
      let fileFormData = new FormData();
      fileFormData.append("entity", JSON.stringify(updated));
      fileFormData.append("file0", file);

      return this.hcmsService.get().all(route + '/' + updated.id).customPUT(fileFormData, '', {}, {}).toPromise()
        .then(x => {
          this.notifications.show(this.translateService.instant('dam.asset_updated') + ': ' + updated.name, null, null, 5000);
          return updated;
        });
    });
  }

  /**
   * Change the mark to be removed
   * @param asset Asset to be changed
   * @param type Asset Type
   * @param deletion Boolean decides whether to be marked for deletion or not
   * @returns Asset updated
   */
  public changeDeletionMark(asset, type, deletion) {

    return this.getAssetToUpdate(asset.id, type).then(updated => {

      delete updated.planForDeletion;
      updated.markForDeletion = deletion;
      return updated.put();

    });
  }

  /**
   * Change planned mark
   * @param asset Asset to be changed
   * @param type Asset Type
   * @param planned Boolean decides whether to be marked for planned or not
   * @returns Asset updated
   */
  public changePlannedMark(asset, type, planned) {

    return this.getAssetToUpdate(asset.id, type).then(updated => {

      delete updated.markForDeletion;
      updated.planForDeletion = planned;
      return updated.put();

    });
  }

  /**
   * Changes the asset status and updates it
   * @param asset Asset to be changed
   * @param type Asset Type
   * @param status State to be changed to
   * @returns Asset updated
   */
  public changeAssetStatus(asset, type, status) {

    return this.getAssetToUpdate(asset.id, type).then(updated => {

      updated.workflowStep = status;
      return updated.put().toPromise();

    });
  }

  /**
   * Remove checked out flag
   * @param asset Asset to be changed
   * @returns Promise of response 
   */
  public removeCheckedOutFLag(asset) {

    let checkout: CheckOut = new CheckOut(asset.id);
    return this.hcmsService.get().all('entity/checkout').post(checkout).toPromise()
  }

  /**
   * Save in the database the metadata of this asset
   * @param asset Asset to be changed
   * @param {boolean} multi  
   * @returns 
   */
  public saveMetadata(asset, multi: boolean = false) {

    return this.getAssetToUpdate(asset.id, asset.type).then(current => {

      /* if (multi) {
        current.workflowStep = (asset.status) ? DamUtils.WORKFLOW_STEP_APPROVED : DamUtils.WORKFLOW_INITIAL_STEP;
      }*/

      if (asset.workflowStep != null && asset.workflowStep !== DamService.MULTIPLE_VALUES) current.workflowStep = asset.workflowStep;


      if (asset.name != null && asset.name !== DamService.MULTIPLE_VALUES) current.name = asset.name;
      if (asset.info != null && asset.info !== DamService.MULTIPLE_VALUES) current.info = asset.info;
      if (!current.designation) current.designation = {};
      if (asset.designation && asset.designation.en != null && asset.designation.en !== DamService.MULTIPLE_VALUES) current.designation.en = asset.designation.en;
      if (asset.designation && asset.designation.de != null && asset.designation.de !== DamService.MULTIPLE_VALUES) current.designation.de = asset.designation.de;
      if (asset.title != null && asset.title !== DamService.MULTIPLE_VALUES) current.title = asset.title;
      if (asset.headline && asset.headline !== DamService.MULTIPLE_VALUES) current.headline = asset.headline;
      if (asset.usage_terms && asset.usage_terms !== DamService.MULTIPLE_VALUES) current.usage_terms = asset.usage_terms;
      if (asset.copyright && asset.copyright !== DamService.MULTIPLE_VALUES) current.copyright = asset.copyright;
      if (asset.credit && asset.credit !== DamService.MULTIPLE_VALUES) current.credit = asset.credit;
      if (asset.instructions && asset.instructions !== DamService.MULTIPLE_VALUES) current.instructions = asset.instructions;
      if (asset.job_id && asset.job_id !== DamService.MULTIPLE_VALUES) current.job_id = asset.job_id;
      if (asset.source && asset.source !== DamService.MULTIPLE_VALUES) current.source = asset.source;
      if (asset.writer_editor && asset.writer_editor !== DamService.MULTIPLE_VALUES) current.writer_editor = asset.writer_editor;
      if (asset.confidential != null && asset.confidential !== DamService.MULTIPLE_VALUES) current.confidential = asset.confidential;

      if (asset.zielgruppe != null && asset.zielgruppe !== DamService.MULTIPLE_VALUES) current.zielgruppe = asset.zielgruppe;
      if (asset.unternehmen != null && asset.unternehmen !== DamService.MULTIPLE_VALUES && (asset.unternehmen.length > 1 || asset.unternehmen.indexOf(DamService.MULTIPLE_VALUES) < 0)) current.unternehmen = asset.unternehmen.filter(x => x !== DamService.MULTIPLE_VALUES);
      if (asset.mediennutzung_list != null && asset.mediennutzung_list !== DamService.MULTIPLE_VALUES) current.mediennutzung_list = asset.mediennutzung_list;
      if (asset.mediennutzung_string != null && asset.mediennutzung_string !== DamService.MULTIPLE_VALUES) current.mediennutzung_string = asset.mediennutzung_string;
      if (asset.exklusivitat_list != null && asset.exklusivitat_list !== DamService.MULTIPLE_VALUES) current.exklusivitat_list = asset.exklusivitat_list;
      if (asset.exklusivitat_string != null && asset.exklusivitat_string !== DamService.MULTIPLE_VALUES) current.exklusivitat_string = asset.exklusivitat_string;
      if (asset.dateigrobe && asset.dateigrobe !== DamService.MULTIPLE_VALUES) current.dateigrobe = +asset.dateigrobe;
      if (asset.auflosung && asset.auflosung !== DamService.MULTIPLE_VALUES) current.auflosung = +asset.auflosung;
      if (asset.druckprofil != null && asset.druckprofil !== DamService.MULTIPLE_VALUES) current.druckprofil = asset.druckprofil;
      if (asset.dateiArt != null && asset.dateiArt !== DamService.MULTIPLE_VALUES && (asset.dateiArt.length > 1 || asset.dateiArt.indexOf(DamService.MULTIPLE_VALUES) < 0)) current.dateiArt = asset.dateiArt.filter(x => x !== DamService.MULTIPLE_VALUES);
      if (asset.dateiType != null && asset.dateiType !== DamService.MULTIPLE_VALUES) current.dateiType = asset.dateiType;
      if (asset.format_width && asset.format_width !== DamService.MULTIPLE_VALUES) current.format_width = +asset.format_width;
      if (asset.format_height && asset.format_height !== DamService.MULTIPLE_VALUES) current.format_height = +asset.format_height;
      if (asset.farbraum != null && asset.farbraum !== DamService.MULTIPLE_VALUES) current.farbraum = asset.farbraum;
      if (asset.fotograf != null && asset.fotograf !== DamService.MULTIPLE_VALUES) current.fotograf = asset.fotograf;
      if (asset.erstelldatum != null && asset.erstelldatum !== DamService.MULTIPLE_VALUES) current.erstelldatum = asset.erstelldatum;
      if (asset.ansprechpartner != null && asset.ansprechpartner !== DamService.MULTIPLE_VALUES) current.ansprechpartner = asset.ansprechpartner;
      if (asset.kontakt != null && asset.kontakt !== DamService.MULTIPLE_VALUES) current.kontakt = asset.kontakt;
      if (asset.letzteAktualisierung != null && asset.letzteAktualisierung !== DamService.MULTIPLE_VALUES) current.letzteAktualisierung = asset.letzteAktualisierung;
      if (asset.metaTags != null && asset.metaTags !== DamService.MULTIPLE_VALUES) current.metaTags = asset.metaTags;
      if (asset.sonstiges != null && asset.sonstiges !== DamService.MULTIPLE_VALUES) current.sonstiges = asset.sonstiges;
      if (asset.bildArt != null && asset.bildArt !== DamService.MULTIPLE_VALUES && (asset.bildArt.length > 1 || asset.bildArt.indexOf(DamService.MULTIPLE_VALUES) < 0)) current.bildArt = asset.bildArt.filter(x => x !== DamService.MULTIPLE_VALUES);
      if (asset.category && asset.category !== DamService.MULTIPLE_VALUES) current.category = asset.category;
      if (asset.subject && asset.subject !== DamService.MULTIPLE_VALUES) current.subject = asset.subject;
      if (asset.channel && asset.channel !== DamService.MULTIPLE_VALUES) current.channel = asset.channel;
      if (asset.license && asset.license !== DamService.MULTIPLE_VALUES) current.license = asset.license;
      if (asset.country && asset.country !== DamService.MULTIPLE_VALUES) current.country = asset.country;
      if (asset.content && asset.content.description && asset.content.description !== DamService.MULTIPLE_VALUES) current.content.description = asset.content.description;

      if (asset.antrieb && asset.antrieb !== DamService.MULTIPLE_VALUES) current.antrieb = asset.antrieb;
      if (asset.trim && asset.trim !== DamService.MULTIPLE_VALUES) current.trim = asset.trim;
      if (asset.subcategory && asset.subcategory !== DamService.MULTIPLE_VALUES) current.subcategory = asset.subcategory;

      if (asset.brand && asset.brand !== DamService.MULTIPLE_VALUES) current.brand = asset.brand;
      if (asset.scale && asset.scale !== DamService.MULTIPLE_VALUES) current.scale = asset.scale;
      if (asset.licensedbrand && asset.licensedbrand !== DamService.MULTIPLE_VALUES) current.licensedbrand = asset.licensedbrand;

      if (asset.articleNumber && asset.articleNumber !== DamService.MULTIPLE_VALUES) current.articleNumber = asset.articleNumber;
      if (asset.publicationYear && asset.publicationYear !== DamService.MULTIPLE_VALUES) current.publicationYear = asset.publicationYear;


      if (asset.domain) current.domain = asset.domain;

      if (asset.domain2) current.domain2 = asset.domain2;

      if (asset.rawType && asset.rawType !== DamService.MULTIPLE_VALUES) current.type = asset.rawType;

      if (asset.valid_until) {
        let validUntil = new Date(asset.valid_until);
        current.valid_until = this.datepipe.transform(validUntil, 'yyyy-MM-ddTHH:mm:ss');
      }

      if ((asset.channel_hubz || current.channel_hubz) && asset.channel_hubz !== DamService.MULTIPLE_VALUES) current.channel_hubz = asset.channel_hubz;
      if ((asset.client || current.client) && asset.client !== DamService.MULTIPLE_VALUES) current.client = asset.client;

      if (asset.date_created) {

        let createdDate = new Date(asset.date_created);
        if (!asset.date_created_time) {
          createdDate.setHours(this.DEFAULT_CREATION_DATE_DATA);
          createdDate.setMinutes(this.DEFAULT_CREATION_DATE_DATA);
        } else {
          let createdDateTokens = asset.date_created_time.split(':');
          createdDate.setHours(createdDateTokens[0]);
          createdDate.setMinutes(createdDateTokens[1]);
        }

        createdDate.setSeconds(this.DEFAULT_CREATION_DATE_DATA);
        current.date_created = this.datepipe.transform(createdDate, 'yyyy-MM-ddTHH:mm:ss');
        current.created = current.date_created;
      }

      if ((asset.was_ist_bild_video || current.was_ist_bild_video) && asset.was_ist_bild_video !== DamService.MULTIPLE_VALUES) current.was_ist_bild_video = asset.was_ist_bild_video;
      if ((asset.modelljahr || current.modelljahr) && asset.modelljahr !== DamService.MULTIPLE_VALUES) current.modelljahr = asset.modelljahr;
      if ((asset.besondere_austattungsmerkmale || current.besondere_austattungsmerkmale) && asset.besondere_austattungsmerkmale !== DamService.MULTIPLE_VALUES) current.besondere_austattungsmerkmale = asset.besondere_austattungsmerkmale;
      if ((asset.fahrzeugfarbe || current.fahrzeugfarbe) && asset.fahrzeugfarbe !== DamService.MULTIPLE_VALUES) current.fahrzeugfarbe = asset.fahrzeugfarbe;
      if ((asset.fuer_alle_sichtbar || current.fuer_alle_sichtbar) && asset.fuer_alle_sichtbar !== DamService.MULTIPLE_VALUES) current.fuer_alle_sichtbar = asset.fuer_alle_sichtbar;
      if ((asset.nur_fuer_admin_sichtbar || current.nur_fuer_admin_sichtbar) && asset.nur_fuer_admin_sichtbar !== DamService.MULTIPLE_VALUES) current.nur_fuer_admin_sichtbar = asset.nur_fuer_admin_sichtbar;

      //Image Features
      if (current.type === 'picture.' && asset.image_features) {

        if (!current.image_features) {
          current.image_features = {};
        }

        if (asset.image_features.buyout_informationen && asset.image_features.buyout_informationen.nutzungsrechte) {

          if (!current.image_features.buyout_informationen) {
            current.image_features.buyout_informationen = {};
          }
          if (!current.image_features.buyout_informationen.nutzungsrechte) {
            current.image_features.buyout_informationen.nutzungsrechte = {};
          }

          if ((asset.image_features.buyout_informationen.nutzungsrechte.ansprechpartner_bei_buyout_fragen || current.image_features.buyout_informationen.nutzungsrechte.ansprechpartner_bei_buyout_fragen) && asset.image_features.buyout_informationen.nutzungsrechte.ansprechpartner_bei_buyout_fragen !== DamService.MULTIPLE_VALUES) {
            current.image_features.buyout_informationen.nutzungsrechte.ansprechpartner_bei_buyout_fragen = asset.image_features.buyout_informationen.nutzungsrechte.ansprechpartner_bei_buyout_fragen;
          }
          if ((asset.image_features.buyout_informationen.nutzungsrechte.dauer_zeitraum || current.image_features.buyout_informationen.nutzungsrechte.dauer_zeitraum) && asset.image_features.buyout_informationen.nutzungsrechte.dauer_zeitraum !== DamService.MULTIPLE_VALUES) {
            current.image_features.buyout_informationen.nutzungsrechte.dauer_zeitraum = asset.image_features.buyout_informationen.nutzungsrechte.dauer_zeitraum;
          }
          if (asset.image_features.buyout_informationen.nutzungsrechte.expiry_date) {
            let expiry_date = new Date(asset.image_features.buyout_informationen.nutzungsrechte.expiry_date);
            current.image_features.buyout_informationen.nutzungsrechte.expiry_date = this.datepipe.transform(expiry_date, 'yyyy-MM-dd');
          }

          if ((asset.image_features.buyout_informationen.nutzungsrechte.verbreitung_kanaele || current.image_features.buyout_informationen.nutzungsrechte.verbreitung_kanaele) && asset.image_features.buyout_informationen.nutzungsrechte.verbreitung_kanaele !== DamService.MULTIPLE_VALUES) {
            current.image_features.buyout_informationen.nutzungsrechte.verbreitung_kanaele = asset.image_features.buyout_informationen.nutzungsrechte.verbreitung_kanaele;
          }
        }

        if (asset.image_features.informationen) {
          if (!current.image_features.informationen) {
            current.image_features.informationen = {};
          }

          if ((asset.image_features.informationen.version || current.image_features.informationen.version) && asset.image_features.informationen.version !== DamService.MULTIPLE_VALUES) current.image_features.informationen.version = asset.image_features.informationen.version;


          if ((asset.image_features.informationen.bildquelle || current.image_features.informationen.bildquelle)) {

            const bildquelleValue = asset.image_features.informationen.bildquelle.value || '';

            if ((asset.image_features.informationen.bildquelle.value || (current.image_features.informationen.bildquelle && current.image_features.informationen.bildquelle.value)) && asset.image_features.informationen.bildquelle.value !== DamService.MULTIPLE_VALUES) {

              if (!current.image_features.informationen.bildquelle) {
                current.image_features.informationen.bildquelle = {};
              }

              current.image_features.informationen.bildquelle.value = bildquelleValue;
            }

            if (bildquelleValue === 'cap_iif' && asset.image_features.informationen.bildquelle.cap_iif) {

              current.image_features.informationen.bildquelle.eigenproduktion_fotoshooting = {};
              current.image_features.informationen.bildquelle.stock = {};
              current.image_features.informationen.bildquelle.sonstige = '';

              if (!current.image_features.informationen.bildquelle.cap_iif) {
                current.image_features.informationen.bildquelle.cap_iif = {};
              }
              if ((asset.image_features.informationen.bildquelle.cap_iif.bild_titel || current.image_features.informationen.bildquelle.cap_iif.bild_titel) && asset.image_features.informationen.bildquelle.cap_iif.bild_titel !== DamService.MULTIPLE_VALUES) {
                current.image_features.informationen.bildquelle.cap_iif.bild_titel = asset.image_features.informationen.bildquelle.cap_iif.bild_titel;
              }
              if ((asset.image_features.informationen.bildquelle.cap_iif.reference || current.image_features.informationen.bildquelle.cap_iif.reference) && asset.image_features.informationen.bildquelle.cap_iif.reference !== DamService.MULTIPLE_VALUES) {
                current.image_features.informationen.bildquelle.cap_iif.reference = asset.image_features.informationen.bildquelle.cap_iif.reference;
              }
            } else if (bildquelleValue === 'eigenproduktion_fotoshooting' && asset.image_features.informationen.bildquelle.eigenproduktion_fotoshooting) {
              current.image_features.informationen.bildquelle.cap_iif = {};
              current.image_features.informationen.bildquelle.stock = {};
              current.image_features.informationen.bildquelle.sonstige = '';

              if (!current.image_features.informationen.bildquelle.eigenproduktion_fotoshooting) {
                current.image_features.informationen.bildquelle.eigenproduktion_fotoshooting = {};
              }
              if ((asset.image_features.informationen.bildquelle.eigenproduktion_fotoshooting.fotograf_agentur || current.image_features.informationen.bildquelle.eigenproduktion_fotoshooting.fotograf_agentur) && asset.image_features.informationen.bildquelle.eigenproduktion_fotoshooting.fotograf_agentur !== DamService.MULTIPLE_VALUES) {
                current.image_features.informationen.bildquelle.eigenproduktion_fotoshooting.fotograf_agentur = asset.image_features.informationen.bildquelle.eigenproduktion_fotoshooting.fotograf_agentur;
              }
              if ((asset.image_features.informationen.bildquelle.eigenproduktion_fotoshooting.talent_agentur || current.image_features.informationen.bildquelle.eigenproduktion_fotoshooting.talent_agentur) && asset.image_features.informationen.bildquelle.eigenproduktion_fotoshooting.talent_agentur !== DamService.MULTIPLE_VALUES) {
                current.image_features.informationen.bildquelle.eigenproduktion_fotoshooting.talent_agentur = asset.image_features.informationen.bildquelle.eigenproduktion_fotoshooting.talent_agentur;
              }
            } else if (bildquelleValue === 'stock' && asset.image_features.informationen.bildquelle.stock) {

              current.image_features.informationen.bildquelle.cap_iif = {};
              current.image_features.informationen.bildquelle.eigenproduktion_fotoshooting = {};
              current.image_features.informationen.bildquelle.sonstige = '';

              if (!current.image_features.informationen.bildquelle.stock) {
                current.image_features.informationen.bildquelle.stock = {};
              }
              if ((asset.image_features.informationen.bildquelle.stock.agentur_stockportal || current.image_features.informationen.bildquelle.stock.agentur_stockportal) && asset.image_features.informationen.bildquelle.stock.agentur_stockportal !== DamService.MULTIPLE_VALUES) {
                current.image_features.informationen.bildquelle.stock.agentur_stockportal = asset.image_features.informationen.bildquelle.stock.agentur_stockportal;
              }
              if ((asset.image_features.informationen.bildquelle.stock.bildnummer || current.image_features.informationen.bildquelle.stock.bildnummer) && asset.image_features.informationen.bildquelle.stock.bildnummer !== DamService.MULTIPLE_VALUES) {
                current.image_features.informationen.bildquelle.stock.bildnummer = asset.image_features.informationen.bildquelle.stock.bildnummer;
              }
              if ((asset.image_features.informationen.bildquelle.stock.bildtitel || current.image_features.informationen.bildquelle.stock.bildtitel) && asset.image_features.informationen.bildquelle.stock.bildtitel !== DamService.MULTIPLE_VALUES) {
                current.image_features.informationen.bildquelle.stock.bildtitel = asset.image_features.informationen.bildquelle.stock.bildtitel;
              }
            } else if (bildquelleValue === 'sonstige' && (asset.image_features.informationen.bildquelle.sonstige || current.image_features.informationen.bildquelle.sonstige) && asset.image_features.informationen.bildquelle.sonstige !== DamService.MULTIPLE_VALUES) {
              current.image_features.informationen.bildquelle.cap_iif = {};
              current.image_features.informationen.bildquelle.eigenproduktion_fotoshooting = {};
              current.image_features.informationen.bildquelle.stock = {};

              current.image_features.informationen.bildquelle.sonstige = asset.image_features.informationen.bildquelle.sonstige;
            } else {
              delete current.image_features.informationen.bildquelle;
            }
          } else {
            delete current.image_features.informationen.bildquelle;
          }
        }

        if (asset.image_features.informationen.technische_daten) {
          if (!current.image_features.informationen.technische_daten) {
            current.image_features.informationen.technische_daten = {};
          }

          if ((asset.image_features.informationen.technische_daten.aufloesung_dpi || current.image_features.informationen.technische_daten.aufloesung_dpi) && asset.image_features.informationen.technische_daten.aufloesung_dpi !== DamService.MULTIPLE_VALUES) {
            current.image_features.informationen.technische_daten.aufloesung_dpi = asset.image_features.informationen.technische_daten.aufloesung_dpi;
          }
          if ((asset.image_features.informationen.technische_daten.bild_groese_pixel || current.image_features.informationen.technische_daten.bild_groese_pixel) && asset.image_features.informationen.technische_daten.bild_groese_pixel !== DamService.MULTIPLE_VALUES) {
            current.image_features.informationen.technische_daten.bild_groese_pixel = asset.image_features.informationen.technische_daten.bild_groese_pixel;
          }
        }
      }

      //Video Feature
      if (current.type === 'video.' && asset.video_features) {

        if (!current.video_features) {
          current.video_features = {};
        }

        if (asset.video_features.buyout_informationen) {

          if (!current.video_features.buyout_informationen) {
            current.video_features.buyout_informationen = {};
          }

          if (asset.video_features.buyout_informationen.musik_sound) {
            if (!current.video_features.buyout_informationen.musik_sound) {
              current.video_features.buyout_informationen.musik_sound = {};
            }

            if ((asset.video_features.buyout_informationen.musik_sound.composer || current.video_features.buyout_informationen.musik_sound.composer) && asset.video_features.buyout_informationen.musik_sound.composer !== DamService.MULTIPLE_VALUES) {
              current.video_features.buyout_informationen.musik_sound.composer = asset.video_features.buyout_informationen.musik_sound.composer;
            }
            if ((asset.video_features.buyout_informationen.musik_sound.label || current.video_features.buyout_informationen.musik_sound.label) && asset.video_features.buyout_informationen.musik_sound.label !== DamService.MULTIPLE_VALUES) {
              current.video_features.buyout_informationen.musik_sound.label = asset.video_features.buyout_informationen.musik_sound.label;
            }
            if ((asset.video_features.buyout_informationen.musik_sound.performer || current.video_features.buyout_informationen.musik_sound.performer) && asset.video_features.buyout_informationen.musik_sound.performer !== DamService.MULTIPLE_VALUES) {
              current.video_features.buyout_informationen.musik_sound.performer = asset.video_features.buyout_informationen.musik_sound.performer;
            }
            if ((asset.video_features.buyout_informationen.musik_sound.title || current.video_features.buyout_informationen.musik_sound.title) && asset.video_features.buyout_informationen.musik_sound.title !== DamService.MULTIPLE_VALUES) {
              current.video_features.buyout_informationen.musik_sound.title = asset.video_features.buyout_informationen.musik_sound.title;
            }
            if (asset.video_features.buyout_informationen.musik_sound.nutzungsrechte) {

              if (!current.video_features.buyout_informationen.musik_sound.nutzungsrechte) {
                current.video_features.buyout_informationen.musik_sound.nutzungsrechte = {};
              }
              if ((asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.ansprechpartner_bei_buyout_fragen || current.video_features.buyout_informationen.musik_sound.nutzungsrechte.ansprechpartner_bei_buyout_fragen) && asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.ansprechpartner_bei_buyout_fragen !== DamService.MULTIPLE_VALUES) {
                current.video_features.buyout_informationen.musik_sound.nutzungsrechte.ansprechpartner_bei_buyout_fragen = asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.ansprechpartner_bei_buyout_fragen;
              }
              if ((asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.dauer_zeitraum || current.video_features.buyout_informationen.musik_sound.nutzungsrechte.dauer_zeitraum) && asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.dauer_zeitraum !== DamService.MULTIPLE_VALUES) {
                current.video_features.buyout_informationen.musik_sound.nutzungsrechte.dauer_zeitraum = asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.dauer_zeitraum;
              }
              if (asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.expiry_date) {
                let expiry_date = new Date(asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.expiry_date);
                current.video_features.buyout_informationen.musik_sound.nutzungsrechte.expiry_date = this.datepipe.transform(expiry_date, 'yyyy-MM-dd');
              }

              if ((asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.verbreitung_kanaele || current.video_features.buyout_informationen.musik_sound.nutzungsrechte.verbreitung_kanaele) && asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.verbreitung_kanaele !== DamService.MULTIPLE_VALUES) {
                current.video_features.buyout_informationen.musik_sound.nutzungsrechte.verbreitung_kanaele = asset.video_features.buyout_informationen.musik_sound.nutzungsrechte.verbreitung_kanaele;
              }
            }
          }

          if (asset.video_features.buyout_informationen.talents) {
            if (!current.video_features.buyout_informationen.talents) {
              current.video_features.buyout_informationen.talents = {};
            }

            if (asset.video_features.buyout_informationen.talents.nutzungsrechte) {
              if (!current.video_features.buyout_informationen.talents.nutzungsrechte) {
                current.video_features.buyout_informationen.talents.nutzungsrechte = {};
              }

              if ((asset.video_features.buyout_informationen.talents.nutzungsrechte.ansprechpartner_bei_buyout_fragen || current.video_features.buyout_informationen.talents.nutzungsrechte.ansprechpartner_bei_buyout_fragen) && asset.video_features.buyout_informationen.talents.nutzungsrechte.ansprechpartner_bei_buyout_fragen !== DamService.MULTIPLE_VALUES) {
                current.video_features.buyout_informationen.talents.nutzungsrechte.ansprechpartner_bei_buyout_fragen = asset.video_features.buyout_informationen.talents.nutzungsrechte.ansprechpartner_bei_buyout_fragen;
              }
              if ((asset.video_features.buyout_informationen.talents.nutzungsrechte.name_talent_agentur || current.video_features.buyout_informationen.talents.nutzungsrechte.name_talent_agentur) && asset.video_features.buyout_informationen.talents.nutzungsrechte.name_talent_agentur !== DamService.MULTIPLE_VALUES) {
                current.video_features.buyout_informationen.talents.nutzungsrechte.name_talent_agentur = asset.video_features.buyout_informationen.talents.nutzungsrechte.name_talent_agentur;
              }
              if ((asset.video_features.buyout_informationen.talents.nutzungsrechte.dauer_zeitraum || current.video_features.buyout_informationen.talents.nutzungsrechte.dauer_zeitraum) && asset.video_features.buyout_informationen.talents.nutzungsrechte.dauer_zeitraum !== DamService.MULTIPLE_VALUES) {
                current.video_features.buyout_informationen.talents.nutzungsrechte.dauer_zeitraum = asset.video_features.buyout_informationen.talents.nutzungsrechte.dauer_zeitraum;
              }
              if (asset.video_features.buyout_informationen.talents.nutzungsrechte.expiry_date) {
                let expiry_date = new Date(asset.video_features.buyout_informationen.talents.nutzungsrechte.expiry_date);
                current.video_features.buyout_informationen.talents.nutzungsrechte.expiry_date = this.datepipe.transform(expiry_date, 'yyyy-MM-dd');
              }
              if (asset.video_features.buyout_informationen.talents.nutzungsrechte.first_airing) {
                let first_airing = new Date(asset.video_features.buyout_informationen.talents.nutzungsrechte.first_airing);
                current.video_features.buyout_informationen.talents.nutzungsrechte.first_airing = this.datepipe.transform(first_airing, 'yyyy-MM-dd');
              }
              if ((asset.video_features.buyout_informationen.talents.nutzungsrechte.verbreitung_kanaele || current.video_features.buyout_informationen.talents.nutzungsrechte.verbreitung_kanaele) && asset.video_features.buyout_informationen.talents.nutzungsrechte.verbreitung_kanaele !== DamService.MULTIPLE_VALUES) {
                current.video_features.buyout_informationen.talents.nutzungsrechte.verbreitung_kanaele = asset.video_features.buyout_informationen.talents.nutzungsrechte.verbreitung_kanaele;
              }
            }
          }

          if (asset.video_features.buyout_informationen.voice_over) {
            if (!current.video_features.buyout_informationen.voice_over) {
              current.video_features.buyout_informationen.voice_over = {};
            }

            if ((asset.video_features.buyout_informationen.voice_over.sprecher_agentur || current.video_features.buyout_informationen.voice_over.sprecher_agentur) && asset.video_features.buyout_informationen.voice_over.sprecher_agentur !== DamService.MULTIPLE_VALUES) {
              current.video_features.buyout_informationen.voice_over.sprecher_agentur = asset.video_features.buyout_informationen.voice_over.sprecher_agentur;
            }
            if (asset.video_features.buyout_informationen.voice_over.nutzungsrechte) {

              if (!current.video_features.buyout_informationen.voice_over.nutzungsrechte) {
                current.video_features.buyout_informationen.voice_over.nutzungsrechte = {};
              }

              if ((asset.video_features.buyout_informationen.voice_over.nutzungsrechte.ansprechpartner_bei_buyout_fragen || current.video_features.buyout_informationen.voice_over.nutzungsrechte.ansprechpartner_bei_buyout_fragen) && asset.video_features.buyout_informationen.voice_over.nutzungsrechte.ansprechpartner_bei_buyout_fragen !== DamService.MULTIPLE_VALUES) {
                current.video_features.buyout_informationen.voice_over.nutzungsrechte.ansprechpartner_bei_buyout_fragen = asset.video_features.buyout_informationen.voice_over.nutzungsrechte.ansprechpartner_bei_buyout_fragen;
              }
              if ((asset.video_features.buyout_informationen.voice_over.nutzungsrechte.dauer_zeitraum || current.video_features.buyout_informationen.voice_over.nutzungsrechte.dauer_zeitraum) && asset.video_features.buyout_informationen.voice_over.nutzungsrechte.dauer_zeitraum !== DamService.MULTIPLE_VALUES) {
                current.video_features.buyout_informationen.voice_over.nutzungsrechte.dauer_zeitraum = asset.video_features.buyout_informationen.voice_over.nutzungsrechte.dauer_zeitraum;
              }
              if (asset.video_features.buyout_informationen.voice_over.nutzungsrechte.expiry_date) {
                let expiry_date = new Date(asset.video_features.buyout_informationen.voice_over.nutzungsrechte.expiry_date);
                current.video_features.buyout_informationen.voice_over.nutzungsrechte.expiry_date = this.datepipe.transform(expiry_date, 'yyyy-MM-dd');
              }
              if ((asset.video_features.buyout_informationen.voice_over.nutzungsrechte.verbreitung_kanaele || current.video_features.buyout_informationen.voice_over.nutzungsrechte.verbreitung_kanaele) && asset.video_features.buyout_informationen.voice_over.nutzungsrechte.verbreitung_kanaele !== DamService.MULTIPLE_VALUES) {
                current.video_features.buyout_informationen.voice_over.nutzungsrechte.verbreitung_kanaele = asset.video_features.buyout_informationen.voice_over.nutzungsrechte.verbreitung_kanaele;
              }
            }
          }
        }
      }


      if (asset.tags) {

        let keywords = "";
        let tags: Array<number> = [];

        asset.tags.forEach((tag, index) => {
          tags.push(tag.id);
          keywords += tag.name;

          if (index < asset.tags.length - 1)
            keywords += ',';
        });

        current.keywords = keywords
        current.iptc_keywords = keywords
        current.tags = tags;
      }

      return current.put().toPromise().then(na => {
        /*if (!DamService.list) {
          DamService.list = [];
        }

        DamService.list.filter(la => la.id === na.id).forEach(oa => {
          oa = Object.assign(oa, na.plain());
        });*/

        this.getAsset(na.id, true);

        return na;

      });

    });
  }

  /**
   * add a keyword
   * @param asset Asset to be added from
   * @param keyword Keyword to be added
   */
  public addKeyword(asset, keyword) {
    if (!asset.tags) asset.tags = [];
    asset.tags.push(keyword);

    if (!asset.keywords) asset.keywords = keyword.name;
    else asset.keywords += ', ' + keyword.name;

    if (!asset.iptc_keywords) asset.iptc_keywords = keyword.name;
    else asset.iptc_keywords += ', ' + keyword.name;
  }

  /**
   * Remove a keyword
   * @param asset Asset to be deleted from
   * @param keyword Keyword to be deleted
   */
  public removeKeyword(asset, keyword) {
    if (!asset.tags) asset.tags = [];

    const index = asset.tags.indexOf(keyword);
    if (index > -1) asset.tags.splice(index, 1);

    if (asset.keywords) asset.keywords = asset.keywords.replace(', ' + keyword.name, '').replace(keyword.name + ', ', '');
    if (asset.iptc_keywords) asset.iptc_keywords = asset.iptc_keywords.replace(', ' + keyword.name, '').replace(keyword.name + ', ', '');
  }

  /**
   * Save the keywords
   * @param {object} asset Asset from which metadata is to be saved
   * @param {string} type Asset type
   * @returns Promise of response
   */
  public saveKeywords(asset, type) {
    return this.getAssetToUpdate(asset.id, type).then(current => {
      if (asset.tags) {

        let keywords = "";
        let tags: Array<number> = [];

        asset.tags.forEach((tag, index) => {
          tags.push(tag.id || tag);
          keywords += tag.name;

          if (index < asset.tags.length - 1)
            keywords += ',';
        });

        current.keywords = keywords
        current.iptc_keywords = keywords
        current.tags = tags;
      }
      return current.put().toPromise();
    });
  }

  /**
   * Validates the asset type with the original
   * @param file File to check
   * @param originalAsset Asset with which to test
   * @returns 
   */
  public validateTypeWithOriginal(file, originalAsset): TypeValidation {
    let extension = DamUtils.getFileExtension(file.name);
    let filetype = this.mimetypeService.getMimetypeByMimetype(file.type);
    if (!filetype && extension) {
      filetype = this.mimetypeService.getMimetypeByExtension(extension);
    }

    if (!filetype) {
      return new TypeValidation("dam.update_asset_unknown_type");
    }

    let type = filetype.def_assettype;
    let mimeType = filetype.mimetype;
    if (mimeType && mimeType != originalAsset.mimetype) {

      let message = "dam.update_asset_error_type";
      let fileType = null;

      let originalExtension = DamUtils.getFileExtension(originalAsset.name);
      let original = this.mimetypeService.getMimetypeByMimetype(originalAsset.mimetype);
      if (!original && originalExtension) {
        original = this.mimetypeService.getMimetypeByExtension(originalExtension);
      }

      if (original) {
        fileType = original.name;
      }

      return new TypeValidation(message, fileType);
    }

    return new TypeValidation(null, originalAsset.rawType);
  }

  /**
   * Checks if the current user is DAM user ( Project Manager | Admin )
   * @returns {boolean} true | false if is Dam user.
   */
  isDamUser() {
    let currentUser = this.usersService.getCurrentUser();
    return this.rolesService.checkRole(currentUser, RolesService.ADMIN_ROLE) || this.rolesService.checkRole(currentUser, RolesService.PROJECT_MANAGER_ROLE, true);
  }

  /**
   * Check if you can change the status
   * @returns {Boolean} true | false if can change status
   */
  canChangeStatus() {
    let currentUser = this.usersService.getCurrentUser();
    return this.rolesService.checkRole(currentUser, RolesService.ADMIN_ROLE);
  }

  /**
   * Create a keyword
   * @param keyword keyword to be created
   * @returns Promise of response
   */
  createKeyword(keyword) {
    return this.hcmsService.get().all('entity/keyword').post(keyword).toPromise()
  }

  /**
   * Set Dam List at localstorage
   * @param {Array} ids Ids of a assets
   */
  setDamList(ids: Array<number>) {
    localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + this.MULTIDAM_KEY, JSON.stringify(ids));
  }

  /**
   * Get Dam List of localstorage
   * @returns Dam list
   */
  getDamList(): Array<number> {
    return JSON.parse(localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + this.MULTIDAM_KEY));
  }

  /**
   * Creates the preview of a Asset
   * @param asset Asset from which the preview is to be created
   * @param type Asset type from which the preview is to be created
   * @returns Asset preview
   */
  public createPreview(asset, type) {
    return this.getAssetToUpdate(asset.id, type).then(current => {
      this.notifications.show(this.translateService.instant('createPreview'));
      current.reloadPreview = true;
      return current.put().toPromise();
    });
  }
}
