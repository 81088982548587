import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * This component defines the view for construction sites
 */
@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {

  /**
   * Title shown
   */
  title;

  /**
   * Constructor
   * @param activatedRoute Service with the activated route.
   */
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  /**
   * Gets the title through the path parameters
   */
  ngOnInit() {
    if (this.activatedRoute.routeConfig && this.activatedRoute.routeConfig.data && this.activatedRoute.routeConfig.data.title){
      this.title = this.activatedRoute.routeConfig.data.title;
    }
  }

}
