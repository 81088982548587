import { Component, Input, OnInit } from '@angular/core';
import { AppSettings } from 'app/shared/app.settings';
import { TasksService } from 'app/shared/services/tasks/tasks.service';
import { UserService } from 'app/shared/services/user/user.service';
import { WorkflowService } from 'app/shared/services/workflows/workflow.service';
import Utils from 'app/shared/utils/utils';
import * as moment from 'moment';

/**
 * @ignore
 */
@Component({
  selector: 'app-item-widget',
  templateUrl: './item-widget.component.html',
  styleUrls: ['./item-widget.component.scss']
})
export class ItemWidgetComponent implements OnInit {

  @Input() item;
  @Input() type?;
  @Input() showChildren: true;

  taskPriorities = [...TasksService.PRIORITIES];
  taskStatus = [];
  currentUser;
  utils = Utils;

  constructor(private workflowsService: WorkflowService,
    private taskService: TasksService,
    private userService: UserService) { }

  ngOnInit() {
    this.currentUser = this.userService.getCurrentUser();
    this.taskStatus = this.workflowsService.getWorkflowStepsByWorkflow(AppSettings.WORKFLOW_TASK)
      .sort((a,b) => (a.wf_step - b.wf_step));

    if (this.item.type.startsWith('task.')){
      this.item.internal = this.taskService.isInternal(this.item.type);
    }
  }

  getPriority(priority) {
    let priorityName = '';

    if (priority && priority > 0) {
      priority = priority !== 1? priority : 4;
      let priorityItem = this.taskPriorities.find(x => x.key == priority);

      if (priorityItem) {
        priorityName = priorityItem.name;
      }
    }

    return priorityName;
  }

  updateTaskStatus(item, statusKey) {
    this.taskService.changeWorkflow(item, statusKey).then(update => {
      update.subscribe(data => {
        let tasks;

        item = Object.assign(item, data);
        tasks = this.taskService.fillData(this.currentUser, [item]);

        this.item = tasks[0];

      });
    });
  }

  checkExpiration( deadLine ) {
    const hoursLeft = moment(deadLine).diff( Date.now(), 'hours' )
    return hoursLeft < 0 ? 'expired' : hoursLeft <= 24? 'aboutExpired' : 'normal';
  }
}
