import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { Material } from 'app/shared/model/material.model';
import Utils from 'app/shared/utils/utils';
import { MimetypeService } from 'app/shared/services/mimetype/mimetype.service';
import { MaterialsService } from 'app/shared/services/materials/materials.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'app/shared/services/notifcations/notifications.service';

/**
 * This component defines the view of a new material.
 */
@Component({
  selector: 'app-new-material',
  templateUrl: './new-material.component.html',
  styleUrls: ['./new-material.component.scss']
})
export class NewMaterialComponent {

  /**
   * True when user is dragging a file
   */
  onDrag = false;

  /**
   * Form with the data of the new material
   */
  form: FormGroup;

  /**
   * @ignore
   */
  actions = [];

  /**
   * Array of files that the user has uploaded
   */
  files = [];

  /**
   * Array of {@link Material} of the job
   */
  materials = [];

  /**
   * True if the material is being created
   */
  creatingMaterial = false;

  /**
   * The current material
   */
  currentMaterial;

  /**
   * Message to show if there are errors in the form
   */
  message;

  /**
   * The file tipye error
   */
  fileTypeError;

  /**
   * Names of the files uploaded
   */
  names: string = '';

  /**
   * True if there are duplicated files in the files uploaded
   */
  duplicatedFile = false;

  /**
   * Array of files that are duplicated
   */
  duplicatedList = [];

  /**
   * The constructor
   * @param dialogRef Service with the functions related to the dialog.
   * @param formBuilder Service with the functions related to the forms.
   * @param notifications Service with the functions related to the notifications.
   * @param actionsService Service with the functions related to the actions.
   * @param mimetypeService Service with the functions related to the mime types.
   * @param materialsService Service with the functions related to the materials.
   * @param material, material injected in the dialog.
   * @param translateService Service with the functions related to the translations.
   */
  constructor(public dialogRef: MatDialogRef<NewMaterialComponent>, private formBuilder : FormBuilder,
    private notifications: NotificationsService,
    private actionsService:ActionsService, private mimetypeService:MimetypeService, private materialsService : MaterialsService,
    @Inject(MAT_DIALOG_DATA) public material: any, private translateService: TranslateService) {

      let name = null;
      let url;
      if (material && material.id) {
        name = material.name;
        if (material.externalUrl) {
          url = material.externalUrl;
        }
        this.currentMaterial = material;
      }

      let actionId = this.checkCurrentAction();
      this.form = this.formBuilder.group({
        'materialname': [name, Validators.compose([Validators.required])],
        'materialurl': [url, Validators.compose([Validators.required])],
        'action': [+actionId, Validators.compose([Validators.required])]
      });

      this.actionsService.getActions().then(async data => {
        if (data.result) {

          let over = false;
          this.actions = [];
          Utils.loadingData = true;
          while (!over) {

            this.actions.push(...data.result);

            if (data.offset + data.limit < data['total-count']) {
              data = await this.actionsService.getActions(data.offset + data.limit);
            } else {
              over = true;
              Utils.loadingData = false;
            }

          }
        }
      });

   }

   /**
    * Gets the job of te current material
    * @returns the job of the material
    */
  private checkCurrentAction() {

    if (this.currentMaterial && this.currentMaterial.parents && this.currentMaterial.parents.length > 0)
      return this.currentMaterial.parents[0];

    if (window.location.pathname) {
      if (window.location.pathname.startsWith('/jobs/detail/')) {
        let path = window.location.pathname.split('/');
        if (path.length >= 4) {
          return path[3];
        }
      }
    }
  }

  /**
   * Checks the duplicate list to reload the data and closes the dialog
   */
  onNoClick(): void {
    if (this.duplicatedList && this.duplicatedList.length > 0) {
      Utils.reloadData = this.checkCurrentAction();
    }

    this.dialogRef.close();
  }

  /**
   * Cancels the dragover event
   * @param event, the dragover event
   */
  public dragFileUpload (event) {
    event.preventDefault();
  }

  /**
   * Removes the selecte file from the list
   * @param file, the selected file
   */
  public deleteFile(file) {

    this.files.splice(this.files.indexOf(file), 1);
    this.message = null;
  }

  /**
   * Adds all files (selected or dropped) to the list
   * @param event, the drop event
   */
  public showFile(event) {
    event.preventDefault();

    if (event.dataTransfer != null && event.dataTransfer.files != null && event.dataTransfer.files.length > 0) {

      Array.from(event.dataTransfer.files).forEach(file => this.files.push(file));
      this.message = null;
      this.fileTypeError = null;
    } else if(event.srcElement != null && event.srcElement.files != null && event.srcElement.files.length > 0) {
      Array.from(event.srcElement.files).forEach(file => this.files.push(file));
      event.target.value = '';
      this.message = null;
      this.fileTypeError = null;
    }

  }

  /**
   * Updates a existing material or creates a new one.
   */
  public saveData() {
    if (this.form.value.action && ((!this.form.value.materialurl && this.files) || (this.form.value.materialurl && this.form.value.materialname))) {

      if (this.currentMaterial) {
        this.updateMaterial();
      } else {
        this.createNewMaterial();
      }
    }
  }

  /**
   * Updates a existing material
   */
  private updateMaterial() {
    this.creatingMaterial = true;

    let material : any = {};
    material.id = this.currentMaterial.id;
    material.workflow = Utils.getWorkflowId(this.currentMaterial.rawType);
    material.workflowStep = Utils.getDefaultWorkflowStep(this.currentMaterial.rawType);
    if (this.form.value.materialname) material.name = this.form.value.materialname;

    let filetype;
    let type;

    if (this.files && this.files.length > 0) {
      let extension = Utils.getFileExtension(this.files[0].name);
      filetype = this.mimetypeService.getMimetypeByMimetype(this.files[0].type);
      if (!filetype && extension) {
        filetype = this.mimetypeService.getMimetypeByExtension(extension);
      }
    } else {
      type=this.currentMaterial.type;
      material.externalUrl = this.form.value.materialurl;
    }

    if (filetype && filetype.extension == '.svg') {
      material.svgfile = true;
    }



    if (!filetype && !type) {
      this.message = "update_material_unknown_type";
      this.creatingMaterial = false;
    } else if (!type) {
      type = filetype.def_assettype;
    }
    
    if (type && type != this.currentMaterial.rawType) {
      this.message = "update_material_error_type";
      this.creatingMaterial = false;

      let originalExtension = Utils.getFileExtension(this.currentMaterial.name);
      let original = this.mimetypeService.getMimetypeByMimetype(this.currentMaterial.mimetype);
      if (!original && originalExtension) {
        original = this.mimetypeService.getMimetypeByExtension(originalExtension);
      }

      if (original) {
        this.fileTypeError = original.name;
      }

    } else if(type && type == this.currentMaterial.rawType){
      this.materialsService.updateMaterialFile(type, material, this.files[0])
      .then(data => {
        this.creatingMaterial = false;
        this.dialogRef.close(data);
        Utils.reloadData = this.checkCurrentAction();
      });
    }
  }

  /**
   * Creates a new material
   */
  private async createNewMaterial () {
    this.creatingMaterial = true;
    this.names = '';

    if (this.files && this.files.length > 0) {
      await this.getMaterials(+this.form.value.action);

      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];

        this.checkDuplicateFile(file.name, this.materials);

        if (!this.duplicatedFile) {
          this.storeMaterial(file, i, false);
        } else {
          this.creatingMaterial = false;
          this.message = "duplicate_files";
          this.duplicatedList.push(file);

          if (!this.names) {
            this.names = this.names.concat(file.name);
          } else {
            this.names = this.names.concat(", " + file.name);
          }

          this.duplicatedFile = false;
        }

      }

    } else {

      let fileFormData = new FormData();
      let newMaterial : Material = new Material();
      newMaterial.name = this.form.value.materialname;

      if (this.form.value.action) {
        newMaterial.parents.push(+this.form.value.action);
      }

      newMaterial.type = 'web-page.';

      delete newMaterial.created;
      delete newMaterial.selected;
      delete newMaterial.preview;
      delete newMaterial.step_time;

      newMaterial.workflow = Utils.getWorkflowId(newMaterial.type);
      newMaterial.workflowStep = Utils.getDefaultWorkflowStep(newMaterial.type);

      newMaterial.externalUrl = this.form.value.materialurl;

      fileFormData.append("entity", JSON.stringify(newMaterial));

      this.materialsService.saveMaterial(newMaterial, fileFormData).then(result => {
        this.creatingMaterial = false;
        this.dialogRef.close(result);

        setTimeout(() => {
          Utils.reloadData = this.checkCurrentAction();
        }, 1500);

      });

    }

  }

  /**
   * Gets the material list of the job
   * @param jobId
   */
  private async getMaterials(jobId) {
    let materials = [];

    await this.materialsService.getMaterialsByAction(jobId).then(async data => {
      if (data.result) {

        let over = false;

        Utils.loadingData = true;
        while (!over) {
          materials.push(...data.result);

          if (data.offset + data.limit < data['total-count']) {
            data = await this.materialsService.getMaterialsByAction(jobId, data.offset + data.limit);
          } else {
            over = true;
            this.materials = materials;
          }
        }
      }
    });
  }

  /**
   * Checks if there is a material with the same name in the list
   * @param name, the name of the file
   * @param materials, the material list
   */
  private checkDuplicateFile(name, materials) {
    if (name && materials) {
      this.duplicatedFile = materials.filter(x => x.name == name).length > 0;
    }
  }

  /**
   * Duplicates all the materials from the duplicate list
   */
  duplicateMaterial() {
    this.creatingMaterial = true;
    this.duplicatedList.forEach((dup, index) => this.storeMaterial(dup, index, true));
  }

  /**
   * Creates a new material
   * @param file, the selected file
   * @param index, it is used to know when it is time to finish the method
   * @param duplicated, true if the file will be a copy of an existing material
   */
  private async storeMaterial(file, index : number, duplicated : boolean) {
    let fileFormData = new FormData();
    let type;
    let mime = file.type;
    let extension = Utils.getFileExtension(file.name);
    let materialList = duplicated ? this.duplicatedList : this.files;

    type = this.mimetypeService.getMimetypeByMimetype(mime);
    if (!type && extension) {
      type = this.mimetypeService.getMimetypeByExtension(extension);
    }

    let newMaterial : Material = new Material();
    if (type && type.extension == '.svg') {
      newMaterial.svgfile = true;
    }

    newMaterial.name = duplicated ? "Copy of " + file.name : file.name;

    if (this.form.value.action) {
      newMaterial.parents.push(+this.form.value.action);
    }

    newMaterial.type = type.def_assettype;

    newMaterial.downloadLink = "formdata:file0";

    delete newMaterial.created;
    delete newMaterial.selected;
    delete newMaterial.preview;
    delete newMaterial.step_time;

    newMaterial.workflow = Utils.getWorkflowId(newMaterial.type);
    newMaterial.workflowStep = Utils.getDefaultWorkflowStep(newMaterial.type);

    fileFormData.append("entity", JSON.stringify(newMaterial));
    fileFormData.append("file0", file);


    let result = await this.materialsService.saveMaterial(newMaterial, fileFormData);
    if(index == (materialList.length-1)) {
      this.creatingMaterial = false;

      if (duplicated) {
        this.duplicatedList = [];
      }

      if (this.duplicatedList && this.duplicatedList.length == 0) {
        this.dialogRef.close(result);
        Utils.reloadData = this.checkCurrentAction();
      }
    }
  }

}
