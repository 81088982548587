import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from './shared.module';
import { NewProjectComponent } from 'app/layout/projects/new-project/new-project.component';
import { NewActionComponent } from 'app/layout/actions/new-action/new-action.component';
import { HistoryComponent } from '../history/history.component';
import { NewMaterialComponent } from 'app/layout/actions/materials/new-material/new-material.component';
import { CommunicationComponent } from '../communication/communication.component';
import { ConnectAssetsComponent } from '../components/connect-assets/connect-assets.component';
import { TextTransformPipe } from '../pipes/text.transform.pipe';
import { UserCircleComponent } from '../components/user-circle/user-circle.component';
import { QuickTeamComponent } from '../components/team/quick-team/quick-team.component';
import { TeamTabComponent } from '../components/team/team-tab/team-tab.component';
import { EditRoleDialogComponent } from '../components/team/edit-role/edit-role-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation/confirmation-dialog.component';
import { NewTaskComponent } from 'app/layout/tasks/new-task/new-task.component';
import { SecureUrlPipe } from '../pipes/secure-url.pipe';
import { NewUserComponent } from 'app/layout/user/new-user/new-user.component';
import { TableFiltersComponent } from '../components/table-filters/table-filters.component';
import { RecentlyViewedComponent } from '../component/recently-viewed/recently-viewed.component';
import { RouterModule } from '@angular/router';
import { TrackingService } from '../services/tracking/tracking.service';
import { InfoDialogComponent } from '../dialogs/info/info-dialog.component';
import { PreviewWidgetComponent } from '../components/preview-widget/preview-widget.component';
import { RatingComponent } from '../components/rating/rating.component'
import { CompareDialogComponent } from '../dialogs/compare/compare-dialog.component';
import { SearchDialogComponent } from '../dialogs/search/search-dialog.component';

import { ComponentDialogComponent } from '../dialogs/component/component-dialog.component';
import { ShowElementPipe } from '../pipes/show.Element.pipe';
import { DownloadDialogComponent } from '../dialogs/download/download-dialog.component';
import { TagListComponent } from 'app/layout/assets/asset-detail/tag-list/tag-list.component';
import { UnderConstructionComponent } from '../components/under-construction/under-construction.component';
import { NotificationDialogComponent } from '../dialogs/notification/notification-dialog.component';
import { SafeHtmlPipe } from '../pipes/safeHtml.pipe';
import { AccordionComponent } from '../components/accordion/accordion.component';
import { WidgetComponent } from '../components/widgets/widget/widget.component';
import { TasksWidgetComponent } from '../components/widgets/tasks-widget/tasks-widget.component';
import { MaterialsWidgetComponent } from '../components/widgets/materials-widget/materials-widget.component';
import { ItemWidgetComponent } from '../components/widgets/item-widget/item-widget.component';
import { InfoCenterWidgetComponent } from '../components/widgets/info-center-widget/info-center-widget.component';
import { BudgetWidgetComponent } from '../components/widgets/budget-widget/budget-widget.component';
import { AllBudgetsWidgetComponent } from '../components/widgets/all-budgets-widget/all-budgets-widget.component';
import { HistoryWidgetComponent } from '../components/widgets/history-widget/history-widget.component';
import { FilesWidgetComponent } from '../components/widgets/files-widget/files-widget.component';
import { NotesWidgetComponent } from '../components/widgets/notes-widget/notes-widget.component';
import { TeamWidgetComponent } from '../components/widgets/team-widget/team-widget.component';
import { JobsWidgetComponent } from '../components/widgets/jobs-widget/jobs-widget.component';
import { ProjectsWidgetComponent } from '../components/widgets/projects-widget/projects-widget.component';
import { BriefingWidgetComponent } from '../components/widgets/briefing-widget/briefing-widget-data.component';
import { NewBriefingWidgetComponent } from '../components/widgets/new-briefing-widget/new-briefing-widget.component';
import { LinkWidgetComponent } from '../components/widgets/link-widget/link-widget.component';
import { FileItemComponent } from '../components/widgets/files-widget/file-item/file-item.component';
import { ListsComponent } from '../components/lists/lists.component';
import { FiltersComponent } from '../components/filters/filters.component';
import { NotesComponent } from '../components/widgets/notes-widget/notes/notes.component';
import { KanbanComponent } from '../components/kanban/kanban.component';
import { NewBriefingComponent } from 'app/layout/briefings/new-briefing/new-briefing.component';
import { ShortcutBriefingComponent } from 'app/layout/briefings/new-briefing/shortcut-briefing/shortcut-briefing.component';
import { DetailedBriefingComponent } from 'app/layout/briefings/new-briefing/detailed-briefing/detailed-briefing.component';
import { TruncatePipe } from '../pipes/truncate.pipe';

//Bryntum - Gantt
import { PanelComponent } from 'app/bryntum/gantt/panel.component';
import { SchedulerManagerComponent } from 'app/bryntum/scheduler-manager/scheduler-manager.component';
import { NewRequestComponent } from 'app/layout/requests/new-request/new-request.component';
import { DetailedRequestComponent } from 'app/layout/requests/new-request/detailed-request/detailed-request.component';
import { NewRequestWidgetComponent } from '../components/widgets/new-request-widget/new-request-widget.component';
import { RequestsWidgetComponent } from '../components/widgets/requests-widget/requests-widget.component';
import { NewJobComponent } from 'app/layout/jobs/new-job/new-job.component';
import { NewRequestTaskComponent } from 'app/layout/tasks/new-request-task/new-request-task.component';

//Charts
import { ChartsModule } from 'ng2-charts';
import { InfoTableComponent } from 'app/layout/dashboard/component/info-table/info-table.component';
import { InfoBarChartComponent } from 'app/layout/dashboard/component/info-bar-chart/info-bar-chart.component';
import { InfoCakeChartComponent } from 'app/layout/dashboard/component/info-cake-chart/info-cake-chart.component';
import { InfoLineChartComponent } from 'app/layout/dashboard/component/info-line-chart/info-line-chart.component';
import { ChartWidgetsComponent } from 'app/shared/chart-widgets/chart-widgets.component';
import { AssetDialogComponent } from '../dialogs/asset/asset-dialog.component';
import { TagsDialogComponent } from '../components/tags-dialog/tags-dialog.component';
import { AssetListComponent } from 'app/layout/assets/asset-list/asset-list.component';
import { SelectFileComponent } from '../dialogs/select-file/select-file.component';
import { SelectFileItemComponent } from '../dialogs/select-file/select-file-item/select-file-item.component';
import { NotAvailableComponent } from '../components/not-available/not-available.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ChartsModule
  ],
  exports: [
    ChartsModule, TagListComponent, TagsDialogComponent, NotificationDialogComponent, DownloadDialogComponent, ShowElementPipe, SecureUrlPipe, ConfirmationDialogComponent, ComponentDialogComponent, HistoryComponent, CommunicationComponent, TextTransformPipe, UserCircleComponent, QuickTeamComponent, TeamTabComponent, TableFiltersComponent, RecentlyViewedComponent, PreviewWidgetComponent, RatingComponent, CompareDialogComponent, SearchDialogComponent,
    UnderConstructionComponent, AccordionComponent, WidgetComponent, TasksWidgetComponent, MaterialsWidgetComponent, ItemWidgetComponent, InfoCenterWidgetComponent, TeamWidgetComponent, FilesWidgetComponent, FileItemComponent,
    BudgetWidgetComponent, AllBudgetsWidgetComponent, NotesWidgetComponent, NotesComponent, HistoryWidgetComponent, SafeHtmlPipe, JobsWidgetComponent, ProjectsWidgetComponent, BriefingWidgetComponent, NewBriefingWidgetComponent, LinkWidgetComponent, ListsComponent, FiltersComponent,
    KanbanComponent, ShortcutBriefingComponent, PanelComponent, SchedulerManagerComponent, NewRequestComponent, DetailedRequestComponent,DetailedBriefingComponent, NewRequestWidgetComponent, RequestsWidgetComponent, TruncatePipe,
    ChartWidgetsComponent, InfoBarChartComponent, InfoCakeChartComponent, InfoLineChartComponent, InfoTableComponent, AssetDialogComponent, AssetListComponent, SelectFileComponent, SelectFileItemComponent, NotAvailableComponent
  ],
  declarations: [TagListComponent, TagsDialogComponent, NotificationDialogComponent, DownloadDialogComponent, ShowElementPipe, SecureUrlPipe, ConfirmationDialogComponent, ComponentDialogComponent, TextTransformPipe, NewUserComponent,
    NewProjectComponent, NewActionComponent, NewTaskComponent, NewBriefingComponent, ShortcutBriefingComponent, DetailedBriefingComponent, NewMaterialComponent, HistoryComponent, CommunicationComponent, ConnectAssetsComponent, UserCircleComponent, ListsComponent, KanbanComponent,
    EditRoleDialogComponent, QuickTeamComponent, TeamTabComponent, TableFiltersComponent, RecentlyViewedComponent, InfoDialogComponent, PreviewWidgetComponent, FiltersComponent, NewJobComponent, NewRequestTaskComponent,
    RatingComponent, CompareDialogComponent, SearchDialogComponent, UnderConstructionComponent, AccordionComponent, WidgetComponent, TasksWidgetComponent, MaterialsWidgetComponent, ItemWidgetComponent, InfoCenterWidgetComponent, TeamWidgetComponent, FilesWidgetComponent, FileItemComponent, BudgetWidgetComponent, AllBudgetsWidgetComponent, NotesWidgetComponent, NotesComponent, HistoryWidgetComponent,
    SafeHtmlPipe, JobsWidgetComponent, ProjectsWidgetComponent, BriefingWidgetComponent, NewBriefingWidgetComponent, LinkWidgetComponent, PanelComponent, SchedulerManagerComponent, NewRequestComponent, DetailedRequestComponent, NewRequestWidgetComponent, RequestsWidgetComponent, TruncatePipe,
    ChartWidgetsComponent, InfoBarChartComponent, InfoCakeChartComponent, InfoLineChartComponent, InfoTableComponent, AssetDialogComponent, AssetListComponent, SelectFileComponent, SelectFileItemComponent, NotAvailableComponent
  ],
  entryComponents: [TagListComponent, NotificationDialogComponent, DownloadDialogComponent, ConfirmationDialogComponent, ComponentDialogComponent, NewUserComponent, NewProjectComponent, NewActionComponent, NewTaskComponent, NewBriefingComponent,
    NewMaterialComponent, HistoryComponent, CommunicationComponent, ConnectAssetsComponent, UserCircleComponent, EditRoleDialogComponent, InfoDialogComponent, CompareDialogComponent, SearchDialogComponent,
    NewRequestComponent, NewJobComponent, NewRequestTaskComponent, AssetDialogComponent, SelectFileComponent, NotAvailableComponent
  ],
  providers: [DatePipe, TextTransformPipe, TrackingService, ShowElementPipe, SafeHtmlPipe, SecureUrlPipe, TruncatePipe]
})
export class CommonComponentsModule { }
