import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';

/**
 * Service defined to manage the updating of assets
 */
@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  /**
   * 
   * @param hcmsService Service with the functions related to the censhare.
   */
  constructor(private hcmsService: HCMSService) {}

  /**
   * Check if an asset or one of its children is not updated
   * 
   * @param asset asset
   * @param children children of asset
   * @returns true if the asset is up to date
   */
  public async isUpdated(asset, children=null) {

    let result = true;

    if (asset) {
      result = false;

      let modifiedDate = await this.hcmsService.get().one('entity/update/' + asset.id + '?limit=9999').get().toPromise();
      result = modifiedDate.modifiedAttr !== asset.modifiedAttr;

      if (!result && children && modifiedDate.children) {
        result = modifiedDate.children.length !== children.length || modifiedDate.children.some(x => !children.some(y => +y.id === +x.id && y.modifiedAttr === x.modifiedAttr));
      }
  
    }
    
    return result;
  }
}
