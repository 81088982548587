import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from 'app/shared/app.settings';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';
import { Project } from 'app/shared/model/project.model';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { BriefingService } from 'app/shared/services/briefing/briefing.service';
import { NotificationsService } from 'app/shared/services/notifcations/notifications.service';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { TasksService } from 'app/shared/services/tasks/tasks.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { UserService } from 'app/shared/services/user/user.service';
import { NotesService } from 'app/shared/services/notes/notes.service';
import { WorkflowService } from 'app/shared/services/workflows/workflow.service';
import { MomentCustomAdapter } from 'app/shared/utils/MomentCustomAdapter';
import Utils from 'app/shared/utils/utils';

import * as moment from 'moment';
import { NgxTimepickerFieldComponent } from 'ngx-material-timepicker';
import * as CustomEditor from '../../../../ckeditor/ckeditor';
import { RequestService } from '../../../services/request/request.service';

/**
 * Constant with the formats of the date
 */
export const DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: { dateInput: 'DD.MM.YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
};

/**
 * This component defines the widget for info center of projects and jobs
 */
@Component({
  selector: 'app-info-center-widget',
  templateUrl: './info-center-widget.component.html',
  styleUrls: ['./info-center-widget.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentCustomAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
})
export class InfoCenterWidgetComponent implements OnInit {

  /**
   * Job
   */
  @Input() job;
  /**
   * Project
   */
  @Input() project;
  /**
   * Emits an event when editing
   */
  @Output() isEditing = new EventEmitter<boolean>();

  /**
   * Custom editor
   */
  public Editor = CustomEditor;
  /** 
   * {@link AppSettings} imported class
   * This is the file where the configuration variables of the different elements 
   * (request, asset, job, tasks, etc) are located. 
   */
  public AppSettings = AppSettings;

  /**
   * 
   */
  checked = false;
  /**
   * Project name
   */
  projectName;
  /**
   * Current user
   */
  currentUser;
  /**
   * True if power user.
   */
  isPowerUser;
  /**
   * True if manager.
   */
  isManager;
  /**
   * True if operator.
   */
  isOperator : boolean = false;
  /**
   * User creator.
   */
  userCreator;
  /**
   * Workflow steps.
   */
  workflowSteps = [];
  /**
   * True if loading data.
   */
  loadingData = false;
  /**
   * True if updating step.
   */
  updatingStep = false;
  /**
   * Step key.
   */
  stepKey = '';
  /**
   * Project id.
   */
  projectId = 0;

  /**
   * Asset.
   */
  asset : any = {};

  /**
   * Form with the data of info center.
   */
  infocenterForm: FormGroup;

  /**
   * True if data loaded.
   */
  dataLoaded : boolean = false;

  /**
   * True if confidential.
   */
  confidential: boolean = false;
  /**
   * Default start time.
   */
  defaultStartTime : string = '00:00';
  /**
   * Default dead line time.
   */
  defaultDeadlineTime : string = '23:59';
  /**
   * Default first alignment time.
   */
  defaultFirstAlignmentTime : string = '00:00';
  /**
   * Saving start date.
   */
  savingStartDate: boolean = false;
  /**
   * Saving deadline.
   */
  savingDeadline: boolean = false;
  /**
   * All channels.
   */
  allChannels = [];
  /**
   * Channel.
   */
  channel;
  /**
   * Channel key.
   */
  channelKey = '';
  /**
   * Channel values.
   */
  channelValues = [];
  /**
   * All hubz channels.
   */
  allHubzChannels = [];
  /**
   * Hubz channel.
   */
  hubzChannel;
  /**
   * Hubz Objective.
   */
  hubzObjective;
  /**
   * Hubz deliverable.
   */
  hubzDeliverable;
  /**
   * Client.
   */
  client = '';
  /**
   * Clients.
   */
  clients = [];
  /**
   * Material type key.
   */
  materialTypeKey = '';
  /**
   * Material type.
   */
  materialType;
  /**
   * Material type values.
   */
  materialTypeValues = [];
  /**
   * Unit key.
   */
  unitKey = '';
  /**
   * Unit.
   */
  unit;
  /**
   * Unit values.
   */
  unitValues = [];
  /**
   * Priority key.
   */
  priorityKey = '';
  /**
   * Priority.
   */
  priority;
  /**
   * Message.
   */
  message: string = '';
  /**
   * Priority values.
   */
  priorityValues = [];
  /**
   * Edit mode.
   */
  editMode = false;

  /**
   * Projects.
   */
  projects = [];

  /**
   * Related assets.
   */
  relatedAssets: Array<any> = [];
  /**
   * Previous asset.
   */
  previousAsset : any;
  /**
   * Next asset.
   */
  nextAsset: any;
  /**
   * Assets notes.
   */
  assetsNotes;
  /**
   * Parent request
   */
  request;
  
  /**
   * Start time date picker.
   */
  @ViewChild('startTime', { static: false }) startTime: NgxTimepickerFieldComponent;
  /**
   * End time date picker.
   */
  @ViewChild('endTime', { static: false }) endTime: NgxTimepickerFieldComponent;
  /**
   * First alignment date picker.
   */
  @ViewChild('firstAlignmentTime', { static: false }) firstAlignmentTime: NgxTimepickerFieldComponent;

  /**
   * Constructor
   * @param projectService Service with the functions related to the projects.
   * @param userService Service with the functions related to the users.
   * @param rolesService Service with the functions related to the roles.
   * @param workflowsService Service with the functions related to the workflows.
   * @param actionService Service with the functions related to the actions.
   * @param formBuilder Service with the functions related to the forms.
   * @param dialog Service with the functions related to the dialogs.
   * @param datepipe Pipe with the date pipe used to format dates.
   * @param assetService Service with the functions related to the assets.
   * @param briefingService Service with the functions related to the briefings.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   * @param notesService Service with the functions related to the notes.
   */
  constructor(private projectService: ProjectsService,
    private userService: UserService,
    private rolesService: RolesService,
    private workflowsService: WorkflowService,
    private actionService: ActionsService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private assetService: AssetService,
    private briefingService: BriefingService,
    private notifications: NotificationsService,
    private translateService: TranslateService,
    private notesService: NotesService,
    private requestService: RequestService
    ) {
      this.infocenterForm = this.formBuilder.group({
        'startDateTime': ['', Validators.compose([])],
        'startDate': [null, Validators.compose([])],
        'deadline': [null, Validators.compose([])],
        'deadlineTime': ['', Validators.compose([])],
        'firstAlignment': [null, Validators.compose([])],
        'firstAlignmentTime': ['', Validators.compose([])],
        'objective': [null, Validators.compose([])],
        'heightCntr': [null, Validators.compose([])],
        'widthCntr': [null, Validators.compose([])],
        'depthCntr': [null, Validators.compose([])],
        'maxFileSize': [null, Validators.compose([])],
        'previousAsset': [null, Validators.compose([])],
        'nextAsset': [null, Validators.compose([])]
      });
  }

  /**
   * Obtains the start date of the asset (project or job) in a correct format
   * 
   * @returns The start date
   */
  getStartTime(){
    let start = null;

    if (this.infocenterForm.value.startDate) {
      let date : moment.Moment;
      if (typeof this.infocenterForm.value.startDate === 'string') {
        date = moment(this.infocenterForm.value.startDate, "YYYY-MM-DD+00:00");
      } else {
        date = this.infocenterForm.value.startDate;
      }

      if (this.infocenterForm.value.startDateTime) {
        date.hour(+this.infocenterForm.value.startDateTime.split(':')[0]);
        date.minute(+this.infocenterForm.value.startDateTime.split(':')[1]);
      } else {
        date.hour(+this.defaultStartTime.split(':')[0]);
        date.minute(+this.defaultStartTime.split(':')[1]);
      }

      start = date.format('yyyy-MM-DDTHH:mm:00+0000');
      start = start.replace('+0000', 'Z');
    }

    return start;
  }

  /**
   * Obtains the deadline of the asset (project or job) in a correct format
   * 
   * @returns The deadline
   */
  getDeadline(){
    let deadline = null;

    if (this.infocenterForm.value.deadline) {
      let date : moment.Moment;
      if (typeof this.infocenterForm.value.deadline === 'string') {
        date = moment(this.infocenterForm.value.deadline, "YYYY-MM-DD+00:00");
      } else {
        date = this.infocenterForm.value.deadline;
      }

      if (this.infocenterForm.value.deadlineTime) {
        date.hour(+this.infocenterForm.value.deadlineTime.split(':')[0]);
        date.minute(+this.infocenterForm.value.deadlineTime.split(':')[1]);
      } else {
        date.hour(+this.defaultDeadlineTime.split(':')[0]);
        date.minute(+this.defaultDeadlineTime.split(':')[1]);
      }

      deadline = date.format('yyyy-MM-DDTHH:mm:59+0000');
      deadline = deadline.replace('+0000', 'Z');
    }

    return deadline;
  }

  /**
   * Obtains the first aligment of the asset (project or job) in a correct format
   * 
   * @returns The first aligment
   */
  getFirstAlignment(){
    let firstAlignment = null;

    if (this.infocenterForm.value.firstAlignment) {
      let date : moment.Moment;
      if (typeof this.infocenterForm.value.firstAlignment === 'string') {
        date = moment(this.infocenterForm.value.firstAlignment, "YYYY-MM-DD+00:00");
      } else {
        date = this.infocenterForm.value.firstAlignment;
      }

      if (this.infocenterForm.value.firstAlignmentTime) {
        date.hour(+this.infocenterForm.value.firstAlignmentTime.split(':')[0]);
        date.minute(+this.infocenterForm.value.firstAlignmentTime.split(':')[1]);
      } else {
        date.hour(+this.defaultFirstAlignmentTime.split(':')[0]);
        date.minute(+this.defaultFirstAlignmentTime.split(':')[1]);
      }

      firstAlignment = date.format('yyyy-MM-DDTHH:mm:00+0000');
      firstAlignment = firstAlignment.replace('+0000', 'Z');
    }

    return firstAlignment;
  }

  /**
   * Sets all necessary data for component
   */
  ngOnInit() {
    this.loadingData = true;
    this.currentUser = this.userService.getCurrentUser();
    this.isPowerUser = this.rolesService.checkRole(this.currentUser, RolesService.POWER_ROLE);
    this.isManager = this.rolesService.checkRole(this.currentUser, RolesService.PROJECT_MANAGER_ROLE, true);
    this.isOperator = this.rolesService.checkRole(this.currentUser, RolesService.OPERATOR_ROLE);
    if(this.job){

      const requestParent = this.job.parents_data.find( item => item.type === 'request.');
      if (requestParent) {
        this.requestService.getRequest( requestParent.id ).then( request => {
          this.request = request;
          this.getChannelRequest();
        });
      }
    }
    

    this.assetService.getChannelValues().subscribe(data => {
      this.allChannels = data;
      this.channelValues = this.allChannels.filter(x => x.enabled && x.parent_key == 'root.');
      this.channelValues.sort((x,y) => (x.name.toUpperCase() > y.name.toUpperCase() ? 1 : -1));
      this.updateChannelAndMaterialType();
    });

    this.briefingService.getChannelValues().subscribe(data => {
      this.allHubzChannels = data.filter(x => x.enabled).sort((a, b) => (a.sorting > b.sorting) ? 1 : -1);
      this.updateChannelAndMaterialType();
    });

    this.briefingService.getClientValues().subscribe(data => {
      this.clients = data.filter(x => x.enabled).sort((a, b) => (a.name > b.name) ? 1 : -1);
      this.updateChannelAndMaterialType();
    });

    this.assetService.getUnitValues().subscribe(data => {
      this.unitValues = data;
      this.unitValues = this.unitValues.filter(x => x.enabled);
      this.unitValues.sort((x,y) => (x.name.toUpperCase() > y.name.toUpperCase() ? 1 : -1));
      this.unitKey = this.asset.unit;
      this.updateUnit();
    });

    this.assetService.getPriorityValues().subscribe(data => {
      this.priorityValues = data;
      this.priorityValues = this.priorityValues.filter(x => x.enabled && x.value_key !== 'urgent');
      this.priorityValues.sort((x,y) => (x.name.toUpperCase() > y.name.toUpperCase() ? 1 : -1));
      this.priorityKey = this.asset.priorityCntr == 'urgent' ? 'low' : this.asset.priorityCntr;
      this.updatePriority();
    });


    // this.projectService.getProjectsByUser(this.currentUser.id).then(projects => {
    this.projectService.getProjects().then(projects => {
      if (projects.result && projects.result.length > 0){
        this.projects = [...projects.result];
      }
    });

    this.fillRelatedData();
  }

  /**
   * Fills the related assets (projects or jobs) and set the previous and next asset if they exist
   */
  fillRelatedData(){
    if (this.job && this.job && this.job.parents_data){
      for (let parent of this.job.parents_data){
        if (parent && parent.type === 'project.' && parent.childs_data){
          this.relatedAssets.push(...parent.childs_data.filter(asset => asset && asset.type === 'order.' && +asset.id !== +this.job.id));
        }
      }
    }

    if (this.project && this.project.previousAsset && this.relatedAssets){
      this.previousAsset = this.relatedAssets.find(asset => +asset.id === +this.project.previousAsset);
    } else if (this.job && this.job.previousAsset){
      this.previousAsset = this.relatedAssets.find(asset => +asset.id === +this.job.previousAsset);
    }

    if (this.previousAsset){
      this.infocenterForm.controls['previousAsset'].patchValue(+this.previousAsset.id);
    }

    if (this.project && this.project.nextAsset && this.relatedAssets){
      this.nextAsset = this.relatedAssets.find(asset => +asset.id === +this.project.nextAsset);
    } else if (this.job && this.job.nextAsset){
      this.nextAsset = this.relatedAssets.find(asset => +asset.id === +this.job.nextAsset);
    }

    if (this.nextAsset){
      this.infocenterForm.controls['nextAsset'].patchValue(+this.nextAsset.id);
    }
  }

  /**
   * Updates view-info if a change is detected
   */
  ngDoCheck() {
    if (!this.dataLoaded) {
      if (this.job) {
        this.asset = Object.assign({}, this.job);
        if (this.job.parents_data){
          this.getProjectName(this.job.parents_data);
          this.getNotesRequest(this.job.parents_data);
        }
        this.workflowSteps = this.workflowsService.getWorkflowStepsByWorkflow(AppSettings.WORKFLOW_ACTION)
          .filter(x => x.enabled && +x.enabled == 1)
          .sort((a,b) => (a.name.localeCompare(b.name)));
          this.message = this.asset.info;
      } else if (this.project){
        this.asset = Object.assign({}, this.project);
        this.workflowSteps = this.workflowsService.getWorkflowStepsByWorkflow(AppSettings.WORKFLOW_PROJECT)
        .filter(x => x.enabled && +x.enabled == 1)
        .sort((a,b) => a.sorting - b.sorting);
        this.message = this.asset.info;
      }
      this.confidential = this.asset.confidential ? this.asset.confidential : false;

      if (this.asset && !this.checked) {

        if (this.asset.createdByNew) {
          this.userCreator = this.userService.getUserData(this.asset.createdByNew);
        }

        if (this.asset.deadline){
          let date : string = this.datepipe.transform(this.asset.deadline, 'yyyy-MM-dd', '+0000');
          let time : string = this.datepipe.transform(this.asset.deadline, 'HH:mm', '+0000');
          this.defaultDeadlineTime = time;
          this.infocenterForm.controls['deadline'].patchValue(date);
          this.infocenterForm.controls['deadlineTime'].patchValue(time);
        }

        if (this.asset.startDate){
          let date = this.datepipe.transform(this.asset.startDate, 'yyyy-MM-dd', '+0000');
          let time = this.datepipe.transform(this.asset.startDate, 'HH:mm', '+0000');
          this.defaultStartTime = time;
          this.infocenterForm.controls['startDate'].patchValue(date);
          this.infocenterForm.controls['startDateTime'].patchValue(time);
        }

        if (this.asset.firstAlignment){
          let date = this.datepipe.transform(this.asset.firstAlignment, 'yyyy-MM-dd', '+0000');
          let time = this.datepipe.transform(this.asset.firstAlignment, 'HH:mm', '+0000');
          this.defaultFirstAlignmentTime = time;
          this.infocenterForm.controls['firstAlignment'].patchValue(date);
          this.infocenterForm.controls['firstAlignmentTime'].patchValue(time);
        }

        this.updateWorkflowStep();

        this.checked = true;
        this.loadingData = false;
      }

      this.dataLoaded = true;
    }
  }

  /**
   * Updates the 'Confidential' value for the asset
   * 
   * @param $event The event of change
   */
  toggleConfidential($event) {
    this.confidential = $event.checked;
    let infocenter = {
      "confidential": this.confidential
    };

    if (this.asset.type === 'project.') {
      this.projectService.saveInfoCenterData(this.asset.id, infocenter).then(data => {
        this.asset.confidential = data.confidential;
      });
    } else if (this.asset.type === 'order.') {
      this.actionService.saveInfoCenterData(this.asset.id, infocenter).then(data => {
        this.asset.confidential = data.confidential;
      })
    }
  }

  /**
   * Updates the asset's step depending on the workflow info
   */
  updateWorkflowStep() {
    let step = this.workflowsService.getWorkflowStep(this.asset.workflow, this.asset.workflowStep);
    if (step) {
      this.stepKey = step.wf_step;
      this.asset.step = step;
    }
  }

  /**
   * Obtains the project info for the asset through info from its parents
   * 
   * @param parents the asset's parents
   */
  getProjectName(parents) {
    if(parents && parents.length > 0) {
      const parentProject = parents.find( item => item.type === 'project.');
      if(parentProject) {
        this.projectService.getProject(parentProject.id).subscribe(data => {
          let project = Object.assign(new Project, data);
          if (project) {
            this.asset.project = project;
            this.projectName = project.name;
            this.projectId = project.id;
          }
        });
      }
    }
  }

  /**
   * Updates the channel and material type in the asset
   */
  updateChannelAndMaterialType() {
    if (this.asset.channel) {
      let splitChannel = this.asset.channel.split('.');

      if (splitChannel && splitChannel.length >= 2) {
        this.channelKey = splitChannel[0] + '.' + splitChannel[1] + '.';
        let channelFiltered = this.channelValues.filter(x => x.value_key === this.channelKey);

        if (channelFiltered && channelFiltered.length > 0) {
          this.channel = channelFiltered[0];
        }
      }

      if (splitChannel && splitChannel.length >= 3) {
        this.updateMaterialType(splitChannel[2]);
      }
    }

    if (this.asset.channel_hubz) {
      const splitChannelHubz = this.asset.channel_hubz.split('.');
      if (splitChannelHubz) {
        if (splitChannelHubz.length >= 2) {
          this.hubzChannel = this.allHubzChannels.filter(x => x.value_key === splitChannelHubz.slice(0, 2).join('.') + '.')[0];
        }
        if (splitChannelHubz.length >= 3) {
          this.hubzObjective = this.allHubzChannels.filter(x => x.value_key === splitChannelHubz.slice(0, 3).join('.') + '.')[0];
        }
        if (splitChannelHubz.length >= 4) {
          this.hubzDeliverable = this.allHubzChannels.filter(x => x.value_key === splitChannelHubz.slice(0, 4).join('.') + '.')[0];
        }
      }
    }

    if (this.asset.client && this.clients.length) {
      this.client = this.asset.client;
      this.asset.clientName = this.clients.filter(x => x.value_key === this.asset.client)[0]['name'];
    }
  }

  /**
   * Updates the material type according to the channel type
   * 
   * @param mtype The channel type
   */
  updateMaterialType(mtype) {
    this.materialTypeValues = this.allChannels;
    this.materialTypeValues = this.materialTypeValues.filter(x => x.enabled && x.parent_key == this.channelKey);
    this.materialTypeValues.sort((x,y) => (x.name.toUpperCase() > y.name.toUpperCase() ? 1 : -1));

    if (mtype) {
      this.materialTypeKey = this.channelKey + mtype + '.';
      let materialTypeFiltered = this.materialTypeValues.filter(x => x.value_key === this.materialTypeKey);

      if (materialTypeFiltered && materialTypeFiltered.length > 0) {
        this.materialType = materialTypeFiltered[0];
      }
    }
  }

  /**
   * Sets the unit (technical info) of the asset
   */
  updateUnit() {
    let unitFiltered = this.unitValues.filter(x => x.value_key === this.asset.unit);

    if (unitFiltered && unitFiltered.length > 0) {
      this.unit = unitFiltered[0];
    }
  }

  /**
   * Sets the priority according ot priorityCntr data of the asset
   */
  updatePriority() {
    if(!this.asset.priorityCntr) {
      const priority = ActionsService.PRIORITIES.find( priority => priority.key === this.asset.priority );
      if(priority) this.asset.priorityCntr = priority.name.toLowerCase();  
    };

    let assetPriority = this.asset.priorityCntr == 'urgent' ? 'low' : this.asset.priorityCntr;
    let priorityFiltered = this.priorityValues.filter( priority => priority.value_key === assetPriority);

    if (priorityFiltered && priorityFiltered.length > 0) {
      this.priority = priorityFiltered[0];
    }
  }

  /**
   * Change view to edit mode.
   * 
   * @param editMode event to set true o false de edit mode
   */
  updateEditMode(editMode) {
    this.editMode = editMode;
    this.isEditing.emit(editMode);

    setTimeout(() => {
      this.initTimers();
    }, 250);
  }

  /**
   * Sets the dates info in the form
   */
  initTimers() {
    if (this.editMode && (this.isManager || (!this.isManager && this.project))) {
      if (this.startTime) {
        this.startTime.registerOnChange((event) => {
          this.infocenterForm.controls['startDateTime'].patchValue(event);
        });
      }
      if (this.endTime) {
        this.endTime.registerOnChange((event) => {
          this.infocenterForm.controls['deadlineTime'].patchValue(event);
        });
      }
      if (this.firstAlignmentTime) {
        this.firstAlignmentTime.registerOnChange((event) => {
          this.infocenterForm.controls['firstAlignmentTime'].patchValue(event);
        });
      }
    }
  }

  /**
   * Update message
   * @param param0 
   */
  updateMessage( { editor }: ChangeEvent ) {
     const data = editor.getData();
     this.message = data;
  }

  /**
   * Save the data in the info cetner form
   */
  saveInfoCenter() {
    let startTime = this.getStartTime();
    let deadline = this.getDeadline();
    let firstAlignment = this.getFirstAlignment();
    let channelCntr = (this.materialTypeKey ? this.materialTypeKey : this.channelKey);
    let finalChannel = channelCntr != this.asset.channel ? channelCntr : null;

    let infocenter = {
      "step": this.stepKey != this.asset.workflowStep ? Number(this.stepKey) : null,
      "startDate": startTime,
      "deadline": deadline,
      "firstAlignment": firstAlignment,
      "channel": finalChannel,
      "info": this.message != this.asset.info ? this.message : null,
      "objective": this.infocenterForm.value.objective,
      "unit": this.unitKey != this.asset.unit ? this.unitKey : null,
      "priorityCntr": this.priorityKey != this.asset.priorityCntr ? this.priorityKey : null,
      "heightCntr": this.infocenterForm.value.heightCntr,
      "widthCntr": this.infocenterForm.value.widthCntr,
      "depthCntr": this.infocenterForm.value.depthCntr,
      "maxFileSize": this.infocenterForm.value.maxFileSize,
      "confidential": this.confidential,
      "client": this.client != this.asset.client ? this.client : null,
      "previousAsset": this.infocenterForm.value.previousAsset != undefined? this.infocenterForm.value.previousAsset : -1,
      "nextAsset": this.infocenterForm.value.nextAsset != undefined? this.infocenterForm.value.nextAsset : -1,
    };

    if (this.projectId && ((this.asset.project &&  +this.asset.project.id != +this.projectId) || !this.asset.project)){
      infocenter['new_project'] = +this.projectId;
      if (this.asset.project){
        infocenter['remove_project'] = +this.asset.project.id;
      }
    }
    if (this.hasChanges(infocenter)) {
      Utils.updatingWidget = true;
      if (this.asset.type === 'project.') {
        this.projectService.saveInfoCenterData(this.asset.id, infocenter).then(data => {
          this.asset = data;
          this.reloadInfoCenterData();
          this.editMode = false;
          Utils.reloadData = Utils.RELOAD_DATA_INFOCENTER_WIDGET;
        });
      } else if (this.asset.type === 'order.') {
        if (this.checkOpenTasksAndJobStep(infocenter.step)) {
          let projectSelected = null;
          if (this.projectId && this.projects.some(x => x.id === this.projectId)) projectSelected = this.projects.find(x => x.id === this.projectId);
          if (this.projectId && this.asset.project && this.asset.project.id === this.projectId) projectSelected = this.asset.project;
          if (projectSelected && this.checkIfOutsideTheProjectPeriod(projectSelected, infocenter)) {
            this.saveByAdjustingProjectPeriod(projectSelected, infocenter);
          } else {
            this.saveActionInfocenter(infocenter);
          }
        } else {
          Utils.reloadData = Utils.RELOAD_DATA_INFOCENTER_WIDGET;
          this.notifications.show(this.translateService.instant('invalid_job_closed'), null, null, 5000, 'error-snack');
        }
      }
    }
  }

  /**
   * Check if job is outside of its project period
   * 
   * @param projectSelected The project to check its period
   * @param infocenter Data of the job
   * @returns True if job is outside of the project period. False in other case.
   */
  checkIfOutsideTheProjectPeriod(projectSelected, infocenter) {
    let startDateJob = infocenter.startDate;
    let deadlineDateJob = infocenter.deadline;

    return (startDateJob < projectSelected.startDate || deadlineDateJob > projectSelected.deadline)? true : false;
  }

  /**
   * Opens a Confirmation dialog and save (if confirmed) the job and its project with the new limit dates
   * 
   * @param projectSelected The project parent
   * @param infocenter The job
   */
  saveByAdjustingProjectPeriod (projectSelected, infocenter) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'adjustProjectPeriod'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (infocenter.startDate < projectSelected.startDate) {
          this.projectService.saveStartDate(projectSelected.id, infocenter.startDate);
        }
        if (infocenter.deadline > projectSelected.deadline) {
          this.projectService.saveDeadline(projectSelected.id, infocenter.deadline);
        }
      }
      this.saveActionInfocenter(infocenter);
      dialogRef = null;
    });
  }

  /**
   * Saves the job infocenter data
   * 
   * @param infocenter The job to be saved
   */
  saveActionInfocenter(infocenter) {
    this.actionService.saveInfoCenterData(this.asset.id, infocenter).then(data => {
      this.asset = data;
      this.reloadInfoCenterData();
      this.editMode = false;

      if(infocenter['new_project']){
        let project = this.projects.find(x => +x.id === +infocenter['new_project']);
        if (project){
          this.asset.project = project;
          this.projectName = project.name;
        }
      } else if (this.asset.parents) {
        this.getProjectName(this.asset.parents_data);
      }

      this.updatePreviousAndNextAsset(data);

      Utils.reloadData = Utils.RELOAD_DATA_INFOCENTER_WIDGET;
    });
  }

  /**
   * Updates the old and the new previous assets as well as the old and the new next assets linked to the current Asset.
   * In addition to this, update the feature previousAsset and nextAsset of the current Asset. 
   * 
   * Next assets are updated independently using the "updateNextAsset" method to avoid an HTTP 412 error.
   * 
   * @param updatedAsset The current asset updated with new previousAsset and new nextAsset
   */
  private updatePreviousAndNextAsset(updatedAsset) {
    if (this.previousAsset && updatedAsset.previousAsset && this.previousAsset.id != updatedAsset.previousAsset) {
      this.actionService.saveInfoCenterData(this.previousAsset.id, { "nextAsset": -1 }).then(asset => {
        this.actionService.saveInfoCenterData(updatedAsset.previousAsset, { "nextAsset": updatedAsset.id }).then(asset => {
          this.updateNextAsset(updatedAsset);
        });
      });

    } else if (this.previousAsset && !updatedAsset.previousAsset) {
      this.actionService.saveInfoCenterData(this.previousAsset.id, { "nextAsset": -1 }).then(asset => {
        this.updateNextAsset(updatedAsset);
      });

    } else if (!this.previousAsset && updatedAsset.previousAsset) {
      this.actionService.saveInfoCenterData(updatedAsset.previousAsset, { "nextAsset": updatedAsset.id }).then(asset => {
        this.updateNextAsset(updatedAsset);
      });
    } else {
      this.updateNextAsset(updatedAsset);
    }
    
    if (!updatedAsset.previousAsset) this.previousAsset = null;
    else this.previousAsset = this.relatedAssets.find(x => x.id === +updatedAsset.previousAsset);
  }

  /**
   * Update the old and the new next assets linked to the current Asset.
   * In addition to this, update the feature nextAsset of the current Asset. 
   * 
   * @param updatedAsset The current asset updated with new nextAsset
   */
  private updateNextAsset(updatedAsset) {
    if (this.nextAsset && updatedAsset.nextAsset && this.nextAsset.id != updatedAsset.nextAsset) {
      this.actionService.saveInfoCenterData(this.nextAsset.id, { "previousAsset": -1 }).then(asset => {
        this.actionService.saveInfoCenterData(updatedAsset.nextAsset, { "previousAsset": updatedAsset.id });
      });

    } else if (this.nextAsset && !updatedAsset.nextAsset) {
      this.actionService.saveInfoCenterData(this.nextAsset.id, { "previousAsset": -1 });

    } else if (!this.nextAsset && updatedAsset.nextAsset) {
      this.actionService.saveInfoCenterData(updatedAsset.nextAsset, { "previousAsset": updatedAsset.id });
    }
    
    if (!updatedAsset.nextAsset) this.nextAsset = null;
    else this.nextAsset = this.relatedAssets.find(x => x.id === +updatedAsset.nextAsset);
  }

  /**
   * Checks if the job has open tasks and is done or closed
   * 
   * @param step the step of the step
   * @returns True if the job is not closed or done and has no open tasks. False in other case.
   */
  checkOpenTasksAndJobStep(step) {
    let openTasks = this.job.task_childs? this.job.task_childs.filter(task => task.workflowStep
      && (task.workflowStep != +TasksService.TASK_WORKFLOW_ON_HOLD && task.workflowStep != +TasksService.TASK_WORKFLOW_DONE)) : null;

    if ((step && step == +ActionsService.JOB_WORKFLOW_DONE || step == +ActionsService.JOB_WORKFLOW_CLOSED) && openTasks && openTasks.length > 0) return false;
    return true;
  }

  /**
   * Recharges all info data necessary from the asset
   */
  reloadInfoCenterData() {
    this.updateWorkflowStep();
    this.updateChannelAndMaterialType();
    this.updateUnit();
    this.updatePriority();
  }

  /**
   * Checks if data of the asset has changed in the form
   * 
   * @param infocenter the asset form data
   * @returns True if any data has changed. False in other case.
   */
  hasChanges(infocenter) {
    let changes = false;

    if (infocenter.step || infocenter.startDate || infocenter.deadline || infocenter.firstAlignment ||
      infocenter.channel || infocenter.info || infocenter.objective || infocenter.unit ||
      infocenter.priorityCntr || infocenter.new_project || infocenter.client || infocenter.confidential || infocenter.previousAsset || infocenter.nextAsset) {
      changes = true;
    } else {
      if (!this.project && (infocenter.heightCntr || infocenter.widthCntr || infocenter.depthCntr || infocenter.maxFileSize)) {
        changes = true;
      }
    }

    return changes;
  }

  /**
   * Obtains the notes if the job's parent is a request.
   * 
   * @param parents parents of the job
   */
  private getNotesRequest (parents: any): void {
    const parentRequest = parents.find( item => item.type === 'request.');
    if (parentRequest){
      this.assetsNotes = {
        id: parentRequest.id,
        type: parentRequest.type
      };
    }
  }

  private getChannelRequest(): void {
    this.asset.channel = this.request.channel_hubz;
  }
}