import { Component, OnInit, Inject, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { AssetService } from 'app/shared/services/asset/asset.service';
import { Media } from 'app/shared/model/media.model';
import { ShareDialogComponent } from 'app/shared/dialogs/share/share-dialog.component';

import { CollectionsService } from 'app/shared/services/collections/collections.service';

import Utils from 'app/shared/utils/utils';
import { CollectionsComponent } from '../collections/collections.component';
import { Collection } from 'app/shared/model/collection.model';
import { RecommendationService } from 'app/shared/services/recommendation/recommendation.service';
import { DownloadDialogComponent } from 'app/shared/dialogs/download/download-dialog.component';
import { DamService } from 'app/layout/dam/service/dam.service';
import { DamUtils } from 'app/layout/dam/utils/dam-utils';
import { ASSET_DIALOG_DATA } from 'app/shared/dialogs/asset/asset-dialog.component';
import { Subscription } from 'rxjs';
import { COMPONENT_DIALOG_DATA } from '../../../shared/dialogs/component/component-dialog.component';

@Component({
  selector: 'app-asset-detail',
  templateUrl: './asset-detail.component.html',
  styleUrls: ['./asset-detail.component.scss']
})
export class AssetDetailComponent implements OnInit {

  /**
  * Array of files
  */
  downloading = [];

  /**
  * {@link Media} The Asset
  */
  asset: Media;

  /**
  * Index selected
  */
  selectedIndex;

  /**
   * Array collection
   */
  collections = [];

  /**
   * True if it is a dam
   */
  dam: boolean = false;

  /**
   * True if dam is enabled
   */
  damEnabled: boolean = false;

  /**
   * True if dam is being edited
   */
  damEditing: boolean = DamUtils.damEditing;

  /**
   * {@link Utils} imorted class
   */
  damUtils = DamUtils;
  /**
   * Parent id
   */
  parentId;

  /**
   * True if asset is beign saved
   */
  saving = false;
  /**
   * True if dialog is open
   */
  isDialog = false;

  /**
   * True if file is available to download
   */
  downloadAvailable = true;

  /**
   * Id
   */
  private id;

  /**
   * Current asset
   */
  private currentAsset: number;

  /**
   * subscription that brings all collections
   */
  private collectionsSubcription: Subscription;

  /**
   * Constructor.
   * @param route {@link ActivatedRoute} with the functions related to the activated route.
   * @param assetService {@link AssetService} with the functions related to the assets.
   * @param dialog {@link MatDialog} with the functions related to the dialog.
   * @param collectionsService {@link CollectionsService} with the functions relate with different features of the collections
   * @param damService {@link DamService} with the functions related to the dam.
   * @param recommendationService {@link RecommendationService} with the functions related to the recommendation.
   */
  constructor(
    private route: ActivatedRoute,
    private assetService: AssetService,
    private dialog: MatDialog,
    private collectionsService: CollectionsService,
    protected damService: DamService,
    private recommendationService: RecommendationService,
    @Optional() @Inject(COMPONENT_DIALOG_DATA) private injectAssetData
  ) {
    this.damEnabled = this.damService.isDamUser();
  }

  /**
   * Initialize the component.
   */
  ngOnInit() {
    this.parentId = this.route.snapshot.paramMap.get('parentId') || (this.injectAssetData ? this.injectAssetData.parentId : null);
    this.id = this.route.snapshot.paramMap.get('id') || (this.injectAssetData ? this.injectAssetData.assetId : null);

    this.getAsset();
    if (this.route.snapshot.queryParamMap.get('dam')) {
      this.dam = true;
    }

    if (this.injectAssetData) {
      this.isDialog = true;
    }

    this.collectionsSubcription = this.collectionsService.collections.subscribe(collections => {this.collections = collections});
  }

  /**
   * Method that is called when the component is destroyed.
   */
  ngOnDestroy() {
    if( this.collectionsSubcription ) {
      this.collectionsSubcription.unsubscribe();
    }
  }

  /**
   * Open pop up
   */
  openDialog() {
    this.dialog.open(ShareDialogComponent, {
      width: '500px',
      data: [this.asset]
    });
  }

  /**
   * Get Asset by id
   */
  getAsset() {
    if (!this.parentId) {
      this.getAssetObject();
    } else {
      this.recommendationService.getRecommendation(this.parentId).then(data => {

        if (data.assets.find(x => x.id == this.id)) {
          this.recommendationService.getRecommendationDetaill(this.id).then( result => {
            this.asset = result;
            // if (this.asset.tags)
            //   this.asset.tags = this.asset.tags.filter(x => x.id != AppSettings.LOGIN_BKG_TAG_ID);
            this.saving = false;

            if (this.asset.valid_until) {
              let today: Date = new Date();
              let expired: Date = new Date(this.asset.valid_until);
              let difference = Utils.getNumDaysBetween(today, expired);
              if (difference < 1) {
                this.downloadAvailable = false;
              }
            }
          });

        }
      });
    }
  }

  getAssetObject() {
    this.currentAsset = this.id;
    this.assetService.getAsset(this.id).subscribe(async data => {
      this.asset = Object.assign(new Media, data);

      // this.asset.tags = this.asset.tags.filter(x => x.id != AppSettings.LOGIN_BKG_TAG_ID);

      this.collections.forEach(userCol => {
        let newCol = Object.assign(new Collection, userCol);
        if (!newCol.assets) {
          newCol.assets = [];
        }
        this.collections.push(newCol);
      });

      if (this.asset.downloadLink) {
        this.asset['downloadLinkFixed'] = this.asset.downloadLink.replace('http://', 'https://').replace('entity/media', 'entity/view');
      }

      if (this.asset.image) {
        this.asset['imageFixed'] = this.asset.image.replace('http://', 'https://').replace('entity/media', 'entity/view') + '/storage_preview';
      }

      if (this.collections) {
        this.asset.inCollection = [];

        this.collections.forEach(col => {
          if (col.assets && col.assets.includes(this.asset.id)) {
            this.asset.inCollection.push(col.id);
            col.assets[col.assets.indexOf(this.asset.id)] = this.asset;
          }
        });

      }
      this.saving = false;

      if (this.asset.valid_until) {
        let today: Date = new Date();
        let expired: Date = new Date(this.asset.valid_until);
        let difference = Utils.getNumDaysBetween(today, expired);
        if (difference < 1) {
          this.downloadAvailable = false;
        }
      }

    });
  }

  /**
   * Download file
   */
  downloadFile() {
    this.dialog.open(DownloadDialogComponent, {
      data: { assets: [this.asset], downloading: this.downloading },
      panelClass: 'download'
    });
  }

  /**
   * Open bottom sheet
   * @param assetsToCollection Assets to collection
   */
  openBottomSheet(assetsToCollection: Media[]) {
    this.dialog.open(CollectionsComponent, {
      data: { collections: this.collections, assets: assetsToCollection },
      panelClass: 'addCollections'
    });
  }

  /**
   * Change dam editing
   * @param event Event
   */
  changeDamEditing(event) {
    DamUtils.damEditing = event.checked;
  }
}
