import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HCMSService } from '../satellites/hcms.service';

/**
 * This number defines the millisecs in a day.
 */
 const dayInMillisecs = 86400000;

/**
 * Service defined to manage all operations needed with Scheduler Teams
 *
 */
@Injectable({
  providedIn: 'root'
})
export class SchedulerTeamsService {


  /**
   * Constructor.
   * 
   * @param hcmsService Service whit the functions related to censhare using restangular.
   */
  constructor(private hcmsService: HCMSService) { }

  /**
  * Get a team by id
   *
   * @param id The team id
   * @returns The scheduler required
   */
  public async getScheduler(id) {
    return this.hcmsService.get().one('entity/schedulerresource', +id).get().toPromise();
  }

  /**
  * Get a team by id
  *
  * @param id Team ID
  * @returns The team with Team tasks, members and events
  */
  public async getSchedulerByTeamId(id) {
    let scheduler = await this.getScheduler(id);
    this.fillData(scheduler);

    return scheduler.plain();
  }

  /**
   * Fill necesary data into scheduler.
   * 
   * @param scheduler the scheduler object
   */
  private fillData(scheduler) {
    // let events = [];

    if (scheduler.members) {
      scheduler.members.forEach(member => {
        member.name = member.firstName + ' ' + member.lastName;

        /*if (member.tasks && member.tasks.length > 0) {
          member.tasks.forEach(task => task.resourceId = member.id);
          events = [...events, ...member.tasks];
        }*/
      });
    }
    // scheduler.events = events;

    // if (scheduler.team_tasks) scheduler.team_tasks.forEach(task => task.duration = moment(task.endDate).diff(moment(task.startDate)) / dayInMillisecs);
  }

  /**
   * Save all scheduler data
   * 
   * @param schedulerData scheduler data
   * @returns the scheduler updated
   */
  public saveScheduler(schedulerData) {
    return this.getScheduler(schedulerData.id).then(updated => {

      updated.members = schedulerData.members;


      updated.team_tasks = schedulerData.team_tasks.map(x => x.data);

      return updated.put().toPromise();
    });
  }

  /**
   * Gets the number of millisecs that a day have
   * 
   * @returns number of millisecs in a day
   */
  public getDaysInMillisecs() {
    return dayInMillisecs;
  }
}
