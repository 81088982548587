import { Component, OnInit, Input } from '@angular/core';
/**
 * @ignore
 */
@Component({
  selector: 'app-info-cake-chart',
  templateUrl: './info-cake-chart.component.html',
  styleUrls: ['./info-cake-chart.component.scss']
})
export class InfoCakeChartComponent implements OnInit {

  @Input() data: any;
  @Input() label: string;

  barChartLabels: string[] = [];
  barChartData: number[] = [];

  barChartType = 'doughnut';
  barChartLegend = true;
  barChartOptions = {
    responsive: true,
    legend: {
      position: "bottom"
    }
  };

  public colors: Array<any> = [
    {
      backgroundColor: ['#e30614', '#000000', '#4d4d4d'],
      borderWidth: 0
    }];

  constructor() { }

  ngOnInit() {
    if (this.data) {
      this.barChartLabels = Object.keys(this.data);
      this.barChartData = Object.values(this.data);
    }
  }

}
