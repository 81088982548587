import { Component, OnInit, HostListener, Inject, InjectionToken, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Material } from 'app/shared/model/material.model';
import { MaterialsService } from 'app/shared/services/materials/materials.service';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { NewMaterialComponent } from './new-material/new-material.component';
import { ConnectAssetsComponent } from 'app/shared/components/connect-assets/connect-assets.component';
import { SaveStateComponent } from 'app/shared/saveState/saveState';
import { LiquidCacheService } from 'ngx-liquid-cache';
import Utils from 'app/shared/utils/utils';
import { UserService } from 'app/shared/services/user/user.service';
import { MailService } from 'app/shared/services/mail/mail.service';
import { Mail } from 'app/shared/model/mail.model';
import { AppSettings } from 'app/shared/app.settings';
import { TranslateService } from '@ngx-translate/core';
import { CanComponentDeactivate } from 'app/shared/guard/leaving/leaving.service';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';
import { environment } from 'environments/environment';
import { NotificationsService } from 'app/shared/services/notifcations/notifications.service';
import { UpdateService } from 'app/shared/services/update/update.service';
import { COMPONENT_DIALOG_DATA } from 'app/shared/dialogs/component/component-dialog.component';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { TrackingService } from 'app/shared/services/tracking/tracking.service';
import { saveAs } from 'file-saver';

/**
 * This component defines the main view of materials.
 */
@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent extends SaveStateComponent implements OnInit, CanComponentDeactivate {

  /**
   * Id of the action it belongs to. Received in the query params.
   */
  actionId;

  /**
   * Id of the task it belongs to. Received in the query params.
   */
  taskId;

  /**
   * Id of the material to show the detail. Received in the query params.
   */
  materialId;

  /**
   * @ignore
   */
  showMailOption = false;

  /**
   * {@link User} with the current user that is logged in.
   */
  currentUser;

  /**
   * id of the {@link User} that is owner of the material.
   */
  ownerId;

  /**
   * {@link MatPaginator} used to navigate throught the materials.
   */
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  /**
   * the list of {@link Material}
   */
  materials: Material[] = [];

  /**
   * {@link Material} that is currently showed.
   */
  materialSelected: Material;

  /**
   * list of materials retrieved in the assetData.
   */
  files: any[];

  /**
   * @ignore
   */
  loaded: boolean = false;

  /**
   * True if the current {@link User} can edit the material.
   */
  canEditMaterial: boolean = false;

  /**
   * True if the current {@link User} can download the material.
   */
  canDownloadMaterial: boolean = true;

  /**
   * True if it is a request task.
   */
  isRequestTask: boolean = false;

  /**
   * List of {@link User} related to the material (owner, project manager, etc).
   */
  team;

  /**
   * @ignore
   */
  newMaterialLoaded: number = 0;

  /**
   * True if the current {@link User} is downloading the material.
   */
  downloading = false;

  /**
   * True if the refresh data option is active.
   */
  private refresh = true;

  /**
   * The {@link Action} of the material.
   */
  action;

  /**
   * Array with the different permissions (compare versions, material download, replace material, edit material) and its value (true => activated, false => not activated).
   */
  permissions: any = {};

  /**
   * True if the approval is allowed for the material.
   */
  approvalAllowed : boolean = false;

  /**
   * True if the task status is editable.
   */
  taskStatusEditable : boolean = false;

  /**
   * True if the task admit to update parent relationship
   */
  updateAllParentRelations : boolean = false;

  /**
   * all parents of task (job, project, request)
   */
  allParents = [];

  /**
   * The constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param route Service with the functions related to the activated route.
   * @param router Service with the functions related to the router.
   * @param materialService Service with the functions related to the materials.
   * @param actionService Service with the functions related to the actions.
   * @param userService Service with the functions related to the users.
   * @param mailService Service with the functions related to the mails.
   * @param dialog Service with the functions related to the dialog.
   * @param cache Service with the functions related to the cache.
   * @param trackingService Service with the functions related to the tracking.
   * @param assetService Service with the functions related to the assets.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   * @param updateService Service with the functions related to the updating of assets.
   * @param assetData data injected when a new dialog is opened to show a material.
   */
  constructor(
    private dialogRef: MatDialogRef<MaterialsComponent>,
    private route: ActivatedRoute,
    router: Router,
    private materialService: MaterialsService,
    private actionService: ActionsService,
    protected userService: UserService,
    private mailService: MailService,
    public dialog: MatDialog,
    cache: LiquidCacheService,
    private trackingService: TrackingService,
    private assetService: AssetService,
    private notifications: NotificationsService,
    private translateService: TranslateService,
    private updateService: UpdateService,
    @Optional() @Inject(COMPONENT_DIALOG_DATA) private assetData
    ) {
      super(cache, router, userService);
    }

    /**
     * method that is called after the view of the component has started to be displayed.
     */
    ngAfterViewInit(): void {
      this.paginatorEvents()
    }

    /**
     * Initialize the component and retrieve the data.
     */
    ngOnInit() {
      super.ngOnInit();
      this.actionId = this.route.snapshot.paramMap.get('actionId') || (this.assetData ? this.assetData.actionId : null);
      this.taskId = this.route.snapshot.paramMap.get('taskId') || (this.assetData ? this.assetData.taskId : null);
      this.getTeam();
      this.materialId = this.route.snapshot.paramMap.get('materialId') || (this.assetData ? this.assetData.materialId : null);
      this.files = (this.assetData && this.assetData.materials) ? this.assetData.materials : [];

      this.approvalAllowed = (this.assetData && this.assetData.approvalAllowed);
      this.taskStatusEditable = (this.assetData && this.assetData.taskStatusEditable);

      this.currentUser = this.userService.getCurrentUser();
      this.isRequestTask = this.assetData.isRequestTask;

      this.permissions.compareVersions = this.userService.getPermissions('approval', 'compareVersions');
      this.permissions.materialDownload = this.userService.getPermissions('material', 'materialDownload');
      this.permissions.replaceMaterial = this.userService.getPermissions('material', 'replaceMaterial');
      this.permissions.editMaterial = this.userService.getPermissions('material', 'editMaterial');
      this.materialSelected = this.assetData.materials.find( material => material.id === this.assetData.materialId );
      this.materials = this.assetData.materials;
      this.updateAllParentRelations = this.assetData.updateAllParentRelations;
      this.allParents = this.assetData.allParents;

      this.refreshData();
  }

  // ngDoCheck() {
  //   super.ngDoCheck();

  //   if (!this.injectAssetData && this.materialId && this.materialId != this.route.snapshot.paramMap.get('materialId') && this.actionId == this.route.snapshot.paramMap.get('actionId')) {
  //     this.materialId = this.route.snapshot.paramMap.get('materialId');
  //
  //     this.getMaterials();
  //   }

  // }

  /**
   * method that is called when the component is destroyed.
   */
  ngOnDestroy() {
    this.refresh = false;
    this.dialogRef.close( this.materials )
  }

  /**
   * Refreshs the data of the action, team and materials.
   */
  async refreshData() {
    if (this.refresh && !Utils.iframeOpened) {
      if ((!this.materialSelected || !this.materialSelected.saving) && await this.updateService.isUpdated(this.materialSelected)) {

        this.actionService.getAction(this.actionId).then(action => {
          this.action = action;
          this.canDownloadMaterial = action && action.workflowStep != AppSettings.WORKFLOW_STEP_NO_DOWNLOAD;

          if (action.owner_rel && action.owner_rel.length > 0) {
            this.ownerId = action.owner_rel[0];
            this.showMailOption = this.currentUser.id != this.ownerId;
          }
        })

        this.getMaterials();

        this.getTeam();
      }

      setTimeout(() => {
        this.refreshData();
      }, 10000);
    }
  }

  /**
   * Gets the {@link User}s related to the materials.
   */
  getTeam() {
    if (this.actionId) {
      this.usersService.getTeam(this.actionId).then(team => {
        this.team = team.plain();
        this.canEditMaterial = this.usersService.canEditMaterials(this.currentUser, team.plain());
      });
    }
  }

  /**
   * Gets all the data related the materials.
   */
  getMaterials() {
    let index = this.materials.indexOf(this.materialSelected);
    if (this.taskId) {
      this.materialService.getMaterialsByParent(this.taskId).then(async data => {
        let over = false;
        this.materials = [];
        Utils.loadingData = true;
        while (!over) {
          data.result.forEach(x => x.autoUpdated = Date.now());
          this.materials.push(...data.result.map(x => (Object.assign(new Material, x))));

          if (index < 0 && this.materialId) {
            index = this.materials.indexOf(this.materials.find(x => x.id == this.materialId));
          }

          if (data.offset + data.limit < data['total-count']) {
            data = await this.materialService.getMaterialsByParent(this.taskId, data.offset + data.limit);
          } else {
            over = true;
            Utils.loadingData = false;
          }

          if (this.materials.length > 0 && over) {

            index = index < 0 ? 0 : index;

            this.setSelected(index);
          }
        }
      });
    } else if (this.actionId) {
      this.materialService.getMaterialsByAction(this.actionId).then(async data => {

        let over = false;
        this.materials = [];
        Utils.loadingData = true;
        while (!over) {

          data.result.forEach(x => x.autoUpdated = Date.now());

          this.materials.push(...data.result.map(x => (Object.assign(new Material, x))));

          if (index < 0 && this.materialId) {
            index = this.materials.indexOf(this.materials.find(x => x.id == this.materialId));
          }

          this.actionService.getAction(this.actionId).then(action => this.materials.forEach(material => material.action = action));

          if (data.offset + data.limit < data['total-count']) {
            data = await this.materialService.getMaterialsByAction(this.actionId, data.offset + data.limit);
          } else {
            over = true;
            Utils.loadingData = false;
          }

          if (this.materials.length > 0 && over) {
            index = index < 0 ? 0 : index;

            this.setSelected(index);
          }
        }
      });
    } else if (!this.files) {
      this.materialService.getMaterial(this.materialId).then(data => {
        this.materials = [data];
        this.setSelected(0);
      });
    } else if (this.materials.length === 0) {
      this.materials = [];
      this.files.forEach(async (x, index) => {
        await this.materialService.getMaterial(x.id).then(data => {
          const mat = Object.assign(new Material, data);
          mat.index = index;
          if (x.id === this.materialId) {
            mat.selected = true;
          }
          this.materials.push(mat);
        });
      });
    }
  }

  /**
   * Opens a new dialog and show the {@link ConnectAssetsComponent}.
   */
  openConnectAssetDialog(): void {
    let data = { "asset": this.materialSelected, "type": "material" };

    const connectAssetDialog = this.dialog.open(ConnectAssetsComponent, { width: '900px', data: data });
    connectAssetDialog.afterClosed().subscribe(result => { });
  }

  /**
   * Opens a new dialog and show the {@link NewMaterialComponent}.
   */
  openNewMaterialDialog(): void {
    let material = this.materialSelected;
    Utils.iframeOpened = true;
    this.materialSelected['rawType'] = material.type;

    const newMaterialDialog = this.dialog.open(NewMaterialComponent, { width: '900px', data: material });
    newMaterialDialog.afterClosed().subscribe(result => {
      this.materialService.getMaterial(material.id).then(updated => {
        Utils.iframeOpened = false;
        setTimeout(() => {
          //this.updateActiveData(updated);
          this.refreshData();
        }, 4000);
      });
    });
  }

  /**
   * Downloads the selected material.
   */
  downloadFile() {
    let material = this.materialSelected;

    this.downloading = true;
    this.assetService.getFileBlob(material.downloadLink + '?r=' + Utils.randomHash()).subscribe(data => {

      const blob = new Blob([data], { type: material.mimetype });
      saveAs(blob, material.name);
      this.trackingService.sendMatomoDownload(material.id);
      this.downloading = false;
    });
  }

  /**
   * @ignore
   */
  private updateActiveData(updated) {
    let index = this.materials.indexOf(this.materialSelected);

    updated = Object.assign(new Material, updated).plain();

    if (updated.preview && updated.preview.downloadLink) {
      updated.preview.downloadLink = updated.preview.downloadLink + '?' + Utils.randomHash();
    }
    if (updated.downloadLink) {
      updated.downloadLink = updated.downloadLink + '?' + Utils.randomHash();
    }
    if (updated.pages && updated.pages.length > 0) {
      updated.pages.forEach(x => {
        if (x.preview) {
          x.preview = x.preview + '?' + Utils.randomHash();
        }
      });
    }

    this.materials[index] = updated;
    this.setSelected(index);
  }

  /**
   * Changes the material selected.
   * @param event with the material info.
   */
  changeMaterial(event) {
    // let index = this.materials.indexOf(this.materialSelected);
    // index = (index + event);
    this.materialSelected.selected = false;

    this.setSelected(event.pageIndex);
  }

  /**
   * @ignore
   */
  sendNotificationManual() {
    let mail: Mail = new Mail(AppSettings.MAIL_MATERIALACTIONUPDATED + '_' + this.materialId, AppSettings.MAIL_MATERIALACTIONUPDATED, +this.materialId, +this.ownerId);
    this.mailService.saveMail(mail);
  }

  /**
   * Sets the material as selected.
   * @param index, the index of the material in the list.
   */
  private setSelected(index) {
    this.materialSelected = this.materials[index];
    this.materials[index].selected = true;
  }

  /**
   * marks the material to be deleted.
   */
  markForDeletion() {
    let activeMaterial = this.materialSelected;

    this.materialService.getMaterial(activeMaterial.id).then(x => {
      if (x.id) {
        x.markForDeletion = activeMaterial.markDeletion != 1;
        this.materialService.editMaterial(x).then(y => {
          if (y.id) {
            this.notifications.show(this.translateService.instant('material_updated'), null, null, 4000);

            activeMaterial = Object.assign({}, activeMaterial, y);

            this.getMaterials();
          }
        });
      }
    });
  }

  /**
   * method to calculate if it is possible to deactivate or not.
   * @param $event
   * @returns true if enviroment is not production.
   */
  @HostListener('window:beforeunload', ['$event'])
  canDeactivateEvent($event) {
    return !environment.production;
  }

  /**
   * Opens a new dialog to show {@link ConfirmationDialogComponent} if there are materials in the default workflow step and the environment is production.
   * @returns true if the environment is not production, current user is not the same than the owner or any material is not in the default step. In other case, the resusult of the confirmation.
   */
  canDeactivate() {
    if (this.currentUser.id == this.ownerId && this.materials.some(x => x.workflowStep == AppSettings.DEFAULT_WORKFLOW_STEP) && environment.production) {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: 'leave'
      });

      return dialogRef.afterClosed().toPromise().then(result => {
        dialogRef = null;
        if (result) {
          return true;
        }
        return false;
      });
    }
    else
      return true;
  }

  /**
   * Gets the id of the material that it is selected.
   * @returns the id of the selected material.
   */
  getAssetId() {
    return this.materialSelected.id;
  }

  /**
   * Change material when buttons of the paginator are clicked.
   */
  paginatorEvents(){
    let index = this.materials.indexOf(this.materialSelected);
    this.paginator.page.subscribe(event => {
        if (event.previousPageIndex > event.pageIndex) {
          this.changeMaterial(event);
          this.paginator.pageIndex = index -1;
        } else if (event.previousPageIndex < event.pageIndex) {
          this.changeMaterial(event);
          this.paginator.pageIndex = index +1;
        }
        this.changeMaterial(event);
        this.paginator.pageIndex= event.pageIndex;

      });
      this.paginator.pageIndex = index;
  }

}
