import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
const _ = deepdash(lodash);

/**
 * This class defines the methods and attributes to work with the data of {@link Table}.
 */
export class CustomDataSource implements DataSource<any> {

    /**
     * Constant array with the different values of the deadline
     */
    static readonly DEADLINES = ['12h', '24h', '48h', '7d', '14d', '30d'];

    /**
     * Array with the date values of the deadlines
     */
    private deadlinetimes = [
        { id: '12h', time: new Date().getTime() + (12 * 60 * 60 * 1000) },
        { id: '24h', time: new Date().getTime() + (24 * 60 * 60 * 1000) },
        { id: '48h', time: new Date().getTime() + (48 * 60 * 60 * 1000) },
        { id: '7d', time: new Date().getTime() + (7 * 24 * 60 * 60 * 1000) },
        { id: '14d', time: new Date().getTime() + (14 * 24 * 60 * 60 * 1000) },
        { id: '30d', time: new Date().getTime() + (30 * 24 * 60 * 60 * 1000) }
    ];

    /**
     * Array with the different columns for the sorting
     */
    private sortingMap = [
        { key: 'step', value: 'wf_step', type: 'number' },
        { key: 'progress', value: 'completion_percentage', type: 'number' },
        { key: 'status', value: 'name' },
        { key: 'owner', value: 'initials' },
        { key: 'pm', value: 'initials' },
        { key: 'user', value: 'initials' },
        { key: 'startDate', type: 'date' },
        { key: 'finishDate', type: 'date' },
        { key: 'modifiedDate', type: 'date' },
        { key: 'deadline', type: 'date' },
        { key: 'role', value: 'role' },
        { key: 'canDelete'},
        { key: 'jobs', value: 'name' }
    ];

    /**
     * Stores the values of the data displayed in the table
     */
    data = new BehaviorSubject<any>([]);

    /**
     * Calculates the list adding the childs an item passed as param
     * @param item The item that is displaying the childs
     * @param childs The childs to be displayed
     */
    addData(item: any, childs: Array<any>) {

        let currentValues: Array<any> = this.data.value;
        let index = currentValues.findIndex(x => +x.id == +item.id);

        let startElements = currentValues.slice(0, index + 1);
        let endElements = currentValues.slice(index + 1, currentValues.length);
        let newList = [...startElements, ...childs, ...endElements];
        this.data.next(newList);

    }

    /**
     * Removes data that has the id as parent
     * @param id
     */
    removeParentData(id: number, isResourceTeam = false) {
        let currentValues: Array<any> = this.data.value;
        if (isResourceTeam) {
            currentValues = currentValues.filter(x => !x.user_targets_ids || !x.user_targets_ids.includes(+id));
        } else {
            currentValues = currentValues.filter(x => !x.parents || !x.parents.includes(+id));
        }
        this.data.next(currentValues);
    }

    /**
     * Gets the data applying the filters and sorting
     * @param results The current data
     * @param sort The sorting
     * @param filters The filters
     * @param defaultSorting The defult sorting
     * @param defaultDirection The defult direction of the sorting
     * @param component The component
     * @returns The data after applying the filters and the sorting
     */
    getData(results: any[], sort: MatSort, filters, defaultSorting, defaultDirection, component, hubzChannels?: any[], clients?: any[]): Array<any> {
        //filter
        if (filters) {
            filters.forEach(filter => {
                if (filter.value) {
                    /// if (filter.name == 'text') results = results.filter(x => x.item_name.toLowerCase().indexOf(filter.value.trim().toLowerCase()) != -1 || +x.id === +filter.value);

                    if (filter.name == 'text' || filter.name == 'textArray') {

                        const textValues = filter.name == 'text' ? [filter.value] : filter.value;

                        textValues.forEach(textValue => {
                            results = results.filter(x =>
                                (x.item_name && x.item_name.toLowerCase().indexOf(textValue.trim().toLowerCase()) != -1)
                                || +x.id === +textValue
                                || (x.tags_data && x.tags_data.length > 0 && (x.tags_data.some(y => y.name && y.name.toLowerCase().includes(textValue.toLowerCase()))))
                                || (x.dateiType && x.dateiType.toLowerCase().includes(textValue.toLowerCase()))
                                || (x.status && x.status.toLowerCase().includes(textValue.toLowerCase()))
                                || (x.scale && x.scale.toLowerCase().includes(textValue.toLowerCase()))
                                || (x.type && x.type.toLowerCase().includes(textValue.toLowerCase()))
                                || (x.info && x.info.toLowerCase().includes(textValue.toLowerCase()))
                                || (x.designation && x.designation.en && x.designation.en.toLowerCase().includes(textValue.toLowerCase()))
                                || (x.designation && x.designation.de && x.designation.de.toLowerCase().includes(textValue.toLowerCase()))
                                || (x.rawType && x.rawType.toLowerCase().includes(textValue.toLowerCase()))
                                || (x.brand && x.brand.some(y => y.includes(textValue.toLowerCase())))
                                || (x.licensedbrand && x.licensedbrand.toLowerCase().includes(textValue.toLowerCase()))
                                || x.name.toLowerCase().includes(textValue.toLowerCase())
                                || (x.tags_objects && x.tags_objects.length > 0 && (x.tags_objects.some(y => y.name && y.name.toLowerCase().includes(textValue.toLowerCase()))))
                            );
                        });
                    }

                    if (filter.name == 'item_name') results = results.filter(x => x.item_name.toLowerCase().indexOf(filter.value.trim().toLowerCase()) != -1);
                    if (filter.name == 'owner') results = results.filter(x => x.owner && x.owner.id == filter.value.id);
                    if (filter.name == 'pm') results = results.filter(x => x.pm && x.pm.id == filter.value.id);
                    if (filter.name == 'start') results = results.filter(x => x.startDate == filter.value);
                    if (filter.name == 'end') results = results.filter(x => x.finishDate == filter.value);
                    if (filter.name == 'progress') results = results.filter(x => x.step && x.step.completion_percentage == filter.value);
                    if (filter.name == 'projects') results = results.filter(x => x.parents && x.parents.indexOf(filter.value.id) > -1);
                    if (filter.name == 'jobs') results = results.filter(x => x.jobs && _.find(x.jobs, ['name', filter.value.name]));
                    if (filter.name == 'step') results = results.filter(x => +x.step.wf_id == +filter.value.wf_id && +x.step.wf_step == +filter.value.wf_step);
                    if (filter.name == 'indicator') results = results.filter(x => x.indicator == filter.value);
                    if (filter.name == 'type') results = results.filter(x => x.type.trim().toLowerCase() == filter.value.trim().toLowerCase());
                    if (filter.name == 'task') results = results.filter(x => x.type.trim().toLowerCase() == filter.value.trim().toLowerCase());
                    
                    
                    if (filter.name == 'status' && typeof filter.value == 'string') {
                        results = results.filter(x => x.status && x.status.trim().toLowerCase() == filter.value.trim().toLowerCase());
                    } 
                    if (filter.name == 'status' && !(typeof filter.value == 'string')) {
                        results = results.filter(x => x.status && x.status && x.status.id == filter.value.id);
                    } 
                    if (filter.name == 'role') results = results.filter(x => x.role.toLowerCase().indexOf(filter.value.trim().toLowerCase()) != -1);

                    if (filter.name == 'rawType') results = results.filter(x => x.rawType.trim().toLowerCase().includes(filter.value.trim().toLowerCase()));
                    if (filter.name == 'file') results = results.filter(x => x.file.trim().toLowerCase() == filter.value.trim().toLowerCase());

                    // if (filter.name == 'category') results = results.filter(x => x.subcategory && filter.value.value_key && x.subcategory.trim().toLowerCase().includes(filter.value.value_key.trim().toLowerCase()));

                    if (filter.name == 'category') {
                        results = results.filter(x => x.subcategory && filter.value.filter(y => y['value_key']).every(y => x.subcategory.some(z => z.includes(y['value_key']))));
                    }

                    if (filter.name == 'channel') {
                        results = results.filter(x => x.channels && filter.value.filter(y => y['value_key']).every(y => x.channels.includes(y['value_key'])));
                    }

                    if (filter.name == 'antrieb') results = results.filter(x => x.antrieb && filter.value.value_key && x.antrieb.trim().toLowerCase().includes(filter.value_key.trim().toLowerCase()));
                    // if (filter.name == 'trim') results = results.filter(x => x.trim && filter.value.value_key && x.trim.trim().toLowerCase().includes(filter.value.value_key.trim().toLowerCase()));
                    if (filter.name == 'trim') {
                        results = results.filter(x => x.trim && filter.value.filter(y => y['value_key']).every(y => x.trim.includes(y['value_key'])));
                    }

                    if (filter.name == 'brand' || filter.name == 'subbrand') {
                        results = results.filter(x => x.brand && filter.value.value_key && x.brand.some(y => y.includes(filter.value.value_key.trim())));
                    }

                    if (filter.name == 'licensedbrand') results = results.filter(x => x.licensedbrand && filter.value.value_key && x.licensedbrand.trim().toLowerCase().includes(filter.value.value_key.trim().toLowerCase()));
                    if (filter.name == 'scale') results = results.filter(x => x.scale && filter.value.value_key && x.scale.trim().toLowerCase().includes(filter.value.value_key.trim().toLowerCase()));
                    if (filter.name == 'articletype') results = results.filter(x => x.articletype && filter.value.value_key && x.articletype.trim().toLowerCase().includes(filter.value.value_key.trim().toLowerCase()));

                    // Start date filter for projects, jobs and tasks in overviews and kanbans (planner)
                    if (filter.name == 'startDate') {
                        if (filter.value.from) results = results.filter(x => x.startDate && new Date(x.startDate) >= filter.value.from.toDate());
                        if (filter.value.to) results = results.filter(x => x.startDate && new Date(x.startDate) <= filter.value.to.toDate());
                    }

                    // Deadlines filter for projects, jobs and tasks in overviews and kanbans (planner)
                    if (filter.name == 'deadline') {
                        if (filter.value.from) results = results.filter(x => x.deadline && new Date(x.deadline) >= filter.value.from.toDate());
                        if (filter.value.to) results = results.filter(x => x.deadline && new Date(x.deadline) <= filter.value.to.toDate());
                    }

                    if (filter.name == 'priorityCntr') results = results.filter(x => x.priorityCntr && x.priorityCntr == _.toLower(filter.value));
                    if (filter.name == 'priority') results = results.filter(x => x.priority && x.priority == filter.value);

                    if (filter.name === 'hubzChannels') {
                        results = _.filter(results, (x) => {
                            const requestData: any = _.findDeep(x.filtersData, (child, i, parent, ctx) => {
                                return i === 'type' && child === 'request.' ? true : false;
                            });
                            return ((requestData && requestData.parent.channel_hubz &&
                                _.includes(requestData.parent.channel_hubz, _.find(hubzChannels, ['name', filter.value]).value_key)) ||
                                _.includes(x.channel, _.find(hubzChannels, ['name', filter.value]).value_key)) ? true : false;
                        });
                    }

                    if (filter.name === 'clients') {
                        results = _.filter(results, (x) => {
                            const requestData: any = _.findDeep(x.filtersData, (child, i, parent, ctx) => {
                                return i === 'type' && child === 'request.' ? true : false;
                            });
                            return (requestData && requestData.parent.client &&
                                _.includes(requestData.parent.client, _.find(clients, ['name', filter.value]).value_key)) ||
                                _.includes(x.client, _.find(clients, ['name', filter.value]).value_key) ? true : false;
                        });
                    }

                    if (filter.name == 'assignedTo') results = results.filter(x => x.user_targets && _.find(x.user_targets, ['display_name', filter.value.name]));
                    if (filter.name == 'createdBy') results = results.filter(x => x.responsibleData && x.responsibleData.name == filter.value.name);

                    // if (filter.name == 'deadline') {
                    //     let selected = this.deadlinetimes.find(x => x.id === filter.value);
                    //     results = results.filter(x => x.deadline && new Date(x.deadline).getTime() >= new Date().getTime() && new Date(x.deadline).getTime() <= selected.time);
                    // }

                    if (filter.name == 'valid_until') {
                        let newValidUntil: Date | string = new Date((<any>(filter.value)));
                        newValidUntil.setDate(newValidUntil.getDate());
                        newValidUntil = newValidUntil.toLocaleString('en-GB').substring(0, 10).split('/').reverse().join('-');
                        results = results.filter(x => x.valid_until && (x.valid_until.localeCompare(newValidUntil) >= 0));
                    }

                    if (filter.name == 'created') {
                        let newCreated: Date | string = new Date((<any>(filter.value)));
                        newCreated.setDate(newCreated.getDate());
                        newCreated = newCreated.toLocaleString('en-GB').substring(0, 10).split('/').reverse().join('-');
                        results = results.filter(x => x.created && x.created === newCreated /* (x.created.localeCompare(newCreated) >= 0) */);
                    }

                    if (filter.name == 'client') results = results.filter(x => x.client == filter.value.value_key);
                    if (filter.name == 'channel_hubz') results = results.filter(x => x.channel_hubz && x.channel_hubz.includes(filter.value.value_key));
                    if (filter.name == 'deliverable') results = results.filter(x => x.channel_hubz && x.channel_hubz.includes(filter.value.value_key));

                    // Team Member filtering
                    if (filter.name == 'team') {

                        results = results.filter(x => (!x.owner && !x.manager && !x.user_targets) ||
                            (x.owner_ids && x.owner_ids.includes(filter.value.id)) ||
                            (x.manager_ids && x.manager_ids.includes(filter.value.id)) ||
                            (x.user_targets && x.user_targets.filter(y => y).map(y => y.id).includes(filter.value.id)));
                    }
                }
            });
        }
            
        //Sorting
        let sorting = sort.active === 'parent' ? 'jobs' : sort.active;
        if (!sorting && defaultSorting) {
            sorting = defaultSorting;
        }

        if (sorting) {

            let direction: number = (sort.direction == 'asc') ? 1 : -1;
            if (!sort.direction && defaultDirection) {
                direction = (defaultDirection == 'asc') ? 1 : -1;
            }

            let customSorting = this.sortingMap.find(x => x.key === sorting);
            return results.sort((a, b) => {

                let aSorting = this.getSortValue(a, sorting, customSorting);
                let bSorting = this.getSortValue(b, sorting, customSorting);
                
                if (customSorting && customSorting.key === 'canDelete') {

                    let aCanDelete = a.createdByNew === component.id && a.step.wf_step === 10 ? 1 : 0;
                    let bCanDelete = b.createdByNew === component.id && b.step.wf_step === 10 ? 1 : 0;
                    return ( bCanDelete - aCanDelete ) * direction;
                }
                if (!aSorting || !bSorting) return this.getEmptySorting(a, b, sorting, direction);

                if (customSorting && customSorting.type === 'date')
                    return (this.parseDate(aSorting).getTime() - this.parseDate(bSorting).getTime()) * direction;

                if (customSorting && (customSorting.value === 'wf_step' || customSorting.value === 'completion_percentage') && aSorting === bSorting && a[sorting].name && b[sorting].name)
                    return a[sorting].name.toLowerCase().localeCompare(b[sorting].name.toLowerCase()) * direction;

                if (typeof aSorting === 'string')
                    return aSorting.toLowerCase().localeCompare(bSorting.toLowerCase()) * direction;

                return (aSorting > bSorting ? direction : direction * -1);

            });

        }

        return results;

    }

    /**
     * Gets the value of the element for sorting
     * @param element The element to be sorted
     * @param sorting The attribute used to sort
     * @param customSorting The second attribute used to sort
     * @returns The value used to sort the data
     */
    private getSortValue(element, sorting, customSorting): any {
        let sortValue = (customSorting && customSorting.value && _.isArray(element[sorting])) ? _.first(element[sorting])[customSorting.value] :
        (customSorting && customSorting.value && typeof element[sorting] === 'object') ? element[sorting][customSorting.value] : element[sorting];
        if (customSorting && customSorting.type === 'number') sortValue = +sortValue;

        return sortValue;
    }

    /**
     * Gets direction when one of the elements has no value
     * @param a The a element
     * @param b The b element
     * @param sorting The attribute used to sort
     * @param direction The direction
     * @returns 0 if both elements have value or don't have value. The value of direction if
     */
    private getEmptySorting(a, b, sorting, direction): number {
        if (a[sorting] && !b[sorting]) return direction * -1;
        if (!a[sorting] && b[sorting]) return direction;

        return 0;
    }

    /**
     * Calculates the next data to display
     * @param results The current data displayed
     * @param paginator The paginator
     * @param defaultPageSize The default size of the page
     * @param showNewButton True if the new button must to be displayed
     * @param pages True if there are pages
     */
    setData(results: any[], paginator: MatPaginator, defaultPageSize: number, showNewButton: boolean = false, pages = true) {

        let start = 0;
        let end = defaultPageSize;

        if (paginator && paginator.pageSize) {
            start = paginator.pageIndex * paginator.pageSize;
            end = start + paginator.pageSize;
        } else if (!paginator && !pages) {
            end = results.length;
        }

        let values = [];

        if (showNewButton) {
            values.push({ newButton: true });
        }

        values = values.concat(results.slice(start, end));

        this.data.next(values);
    }

    /**
     * Gets the data of one page
     * @param results The current data
     * @param paginator The paginator to know the size and the index
     * @param size Default sizw of the page
     * @returns The data of the selected page
     */
    getSelectableData(results: any[], paginator: MatPaginator, size: number) {
        let start = 0;
        let end = size;

        if (paginator) {
            start = paginator.pageIndex * paginator.pageSize;
            end = start + size;
        }

        return results.slice(start, end);

    }

    /**
     * Gets the date of the input passed as param
     * @param input The input to parse
     * @returns The parsed date
     */
    parseDate(input) {
        var parts = input.match(/(\d+)/g);
        var hours: number = (parts.length > 3) ? parts[3] : 0;
        var minutes: number = (parts.length > 4) ? parts[4] : 0;
        var seconds: number = (parts.length > 5) ? parts[5] : 0;
        return new Date(parts[0], parts[1] - 1, parts[2], hours, minutes, seconds); // months are 0-based
    }

    /**
     * Connects the datasource
     * @returns The current data
     */
    connect() {
        return this.data;
    }

    /**
     * @ignore
     */
    disconnect() { }
}
