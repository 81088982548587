import { Asset } from './asset.model';
import { User } from './user.model';
import { Briefing } from './briefing.model';
import { AppSettings } from '../app.settings';

/**
 * This class defines the structure of jobs
 */
export class Action extends Asset {
    /**
     * id of the project manager of the job.
     */
    projectmanager: number;

    /**
     * deadline of the job.
     */
    deadline: string;

    /**
     * id of the owner of the job.
     */
    owner: number;

    /**
     * Array with the ids of the parents of the job
     */
    parents: Array<number>;

    /**
     * approval project id of the job.
     */
    approvalprojectId: string;

    /**
     * watchlist of the job.
     */
    watchlist: boolean;

    /**
     * {@link User} with the data of the owner.
     */
    ownerUser: User;

    /**
     * {@link User} with the data of the project manager.
     */
    pmUser: User;

    /**
     * workflow of the job.
     */
    workflow: number = AppSettings.WORKFLOW_ACTION;

    /**
     * Workflow step of the job.
     */
    workflowStep: number = 10;

    /**
     * Briefings type {@link Briefing} (briefing.model)
     */
    briefings : Array<Briefing>;

    /**
     * Files of agency data
     */
    agencydata : Array<AgencyData> = [new AgencyData()];
    /**
     * Files of final data
     */
    finaldata : Array<FinalData> = [new FinalData()];
    /**
     * Files of customer data
     */
    customerdata : Array<CustomerData> = [new CustomerData()];
    /**
     * Files of consulting data
     */
    consulting : Array<Consulting> = [new Consulting()];
    /**
     * Files of estimations data
     */
    costestimate : Array<CostEstimate> = [new CostEstimate()];

    /**
     * step of the job.
     */
    step;

    /**
     * selected index of the job.
     */
    selectedIndex;

    /**
     * annotation of the job.
     */
    annotation;

    /**
     * Array with the ids of all {@link User} that are project managers of the job.
     */
    projectmanager_rel: number[] = [];

    /**
     * Array with the ids of all {@link User} that are owners of the job.
     */
    owner_rel: number[] = [];

    /**
     * Value of the feature jobCreatedBy.
     */
    jobCreatedBy;

    /**
     * Value of the feature layoutDate.
     */
    layoutDate: string;

    /**
     * Value of the feature deliverDate.
     */
    deliverDate: string;

    /**
     * Value of the feature deliverDateFinal.
     */
    deliverDateFinal: string;

    /**
     * Value of the feature deliverDateChanged.
     */
    deliverDateChanged: boolean;

    /**
     * Value of the feature cluster.
     */
    cluster;

    /**
     * Value of the feature salesChannel.
     */
    salesChannel;

    /**
     * Value of the feature country.
     */
    country;

    /**
     * Value of the feature tour.
     */
    tour;

    /**
     * Value of the feature marketingMonth.
     */
    marketingMonth;

    /**
     * Value of the feature marketingYear.
     */
    marketingYear;

    /**
     * Value of the feature advertisingMaterial.
     */
    advertisingMaterial;

    /**
     * Value of the feature costCenterDomain.
     */
    costCenterDomain;

    /**
     * Value of the feature costCenterBrand.
     */
    costCenterBrand;

    /**
     * Value of the feature costCenterGLAccount.
     */
    costCenterGLAccount;

    /**
     * Value of the feature costCenterPromoCode.
     */
    costCenterPromoCode;

    /**
     * Value of the feature ratingAccesible.
     */
    ratingAccessible;

    /**
     * Value of the feature ratingRealisation.
     */
    ratingRealisation;

    /**
     * Value of the feature ratingSatisfaction.
     */
    ratingSatisfaction;

    /**
     * Value of the feature heigth.
     */
    height;

    /**
     * Value of the feature width.
     */
    width;

    /**
     * Value of the feature depth.
     */
    depth;

    /**
     * Value of the feature printer.
     */
    printer;

}

/**
 * Defines customer file
 */
export class CustomerData {

    /**
     * Id of the CustomerData folder.
     */
    id: number;

    /**
     * Name of the CustomerData folder.
     */
    name: string = "01 Customer data";

    /**
     * Domain of the CustomerData folder.
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines agency file
 */
export class AgencyData {

    /**
     * Id of the AgencyData folder.
     */
    id: number;

    /**
     * Name of the AgencyData folder.
     */
    name: string = "02 Agency data";

    /**
     * Domain of the AgencyData folder.
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines final file
 */
export class FinalData {

    /**
     * Id of the FinalData folder.
     */
    id: number;

    /**
     * Name of the FinalData folder.
     */
    name: string = "03 Final data";

    /**
     * Domain of the FinalData folder.
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines estimation file
 */
export class CostEstimate {

    /**
     * Id of the CostEstimate folder.
     */
    id: number;

    /**
     * Name of the CostEstimate folder.
     */
    name: string = "04 Cost Estimate";

    /**
     * Domain of the CostEstimate folder.
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines consulting file
 */
export class Consulting {

    /**
     * Id of the Consulting folder.
     */
    id: number;

    /**
     * Name of the Consulting folder.
     */
    name: string = "05 Consulting";

    /**
     * Domain of the Consulting folder.
     */
    domain : string = AppSettings.DOMAIN;
}