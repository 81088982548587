import { Pipe, PipeTransform } from '@angular/core';
/**
 * @ignore
 */
@Pipe({name: 'textTransform'})
export class TextTransformPipe implements PipeTransform {

    transform(value: string): string {

        let transform = value.replace(new RegExp('\n', 'g'), '<br />');
        return transform;

    }
}