import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from 'app/shared/dialogs/info/info-dialog.component';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { MimetypeService } from 'app/shared/services/mimetype/mimetype.service';
import Utils from 'app/shared/utils/utils';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { Material } from 'app/shared/model/material.model';
import { MaterialsService } from 'app/shared/services/materials/materials.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'app/shared/services/notifcations/notifications.service';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { AssetDetailComponent } from 'app/layout/assets/asset-detail/asset-detail.component';
import { ComponentDialogComponent } from 'app/shared/dialogs/component/component-dialog.component';
import { MaterialsComponent } from 'app/layout/actions/materials/materials.component';
import { NewMaterialComponent } from 'app/layout/actions/materials/new-material/new-material.component';
import { RequestService } from 'app/shared/services/request/request.service';

/**
 * This component defines the operation of the projects file container.
 */
@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss']
})
export class FileItemComponent implements OnInit {

  JSON = JSON;

  /**
   * Data
   */
  @Input() data;
  /**
   * Type
   */
  @Input() type;
  /**
   * Asset ID
   */
  @Input() assetId;
  /**
   * Indicates power user
   */
  @Input() isPowerUser = false;
  /**
   * Indicates operator user
   */
  @Input() isOperator = false;
  /**
   * Indicates manager user
   */
  @Input() isManager = false;
  /**
   * Project
   */
  @Input() project = false;

    /**
   * Request
   */
     @Input() request = false;
  /**
   * Approval
   */
  @Input() approval = false;
  /**
   * Indicates whether the download button is displayed
   */
  @Input() download = true;

  /**
   * Indicates whether the remove button is displayed
   */
   @Input() remove = false;

   /**
   * Indicates whether the remove button is displayed
   */
    @Input() addNewFile = false;

  /**
   * Indicates whether the file name is displayed
   */
  @Input() showFileName = true;
  /**
   * Indicates whether the file name is displayed
   */
  @Input() maxDisplay = 100;

  loading = false;


  /**
   * Zip final zata name.
   */
  readonly ZIP_FINAL_DATA_NAME: string = "FinalDataFiles";
  /**
   * Zip customer data name.
   */
  readonly ZIP_CUSTOMER_DATA_NAME: string = "CustomerDataFiles";
  /**
   * Zip agency data name.
   */
  readonly ZIP_AGENCY_DATA_NAME: string = "AgencyDataFiles";
  /**
   * Zip consulting name.
   */
  readonly ZIP_CONSULTING_NAME: string = "ConsultingFiles";
  /**
   * Zip cost estimate name.
   */
  readonly ZIP_COST_ESTIMATE_NAME: string = "CostEstimateFiles";

  /**
   * On drag mat.
   */
  onDragMat = false;
  /**
   * JS Zip library.
   */
  private jszip: JSZip;
  /**
   * True if show upload.
   */
  showUpload = true;
  /**
   * Names.
   */
  names: string = '';
  /**
   * Message.
   */
  message: string = '';
  /**
   * Updating.
   */
  updating = false;
  /**
   * Load error preview.
   */
  loadErrorPreview = [];
  /**
   * {@link Utils} imorted class
   */
  utils = Utils;

  /**
   * Constructor
   * @param dialog Service with the functions related to the dialogs.
   * @param mimetypeService Service with the functions related to the mime types.
   * @param assetService Service with the functions related to the assets.
   * @param datepipe Pipe with the date pipe used to format dates.
   * @param actionsService Service with the functions related to the actions.
   * @param materialsService Service with the functions related to the materials.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   * @param projectService Service with the functions related to the projects.
   */
  constructor(
    public dialog: MatDialog,
    private mimetypeService: MimetypeService,
    private assetService: AssetService,
    public datepipe: DatePipe,
    private actionsService: ActionsService,
    private materialsService: MaterialsService,
    private notifications: NotificationsService,
    private translateService: TranslateService,
    private projectService: ProjectsService,
    private requestService: RequestService
  ) { }

  /**
   * Initializes the component and obtains the necessary data.
   */
  ngOnInit() {
    this.showUpload = (!this.isPowerUser && !this.isOperator) || (this.type == 'customer-data' && (this.isPowerUser || this.isOperator));
  }

  /**
   * Get zip file name
   * @returns {string} File name
   */
  getZipFileName() {
    let name = "Files";

    switch (this.type) {
      case 'consulting':
        name = this.ZIP_CONSULTING_NAME;
        break;
      case 'cost-estimate':
        name = this.ZIP_COST_ESTIMATE_NAME;
        break;
      case 'agency-data':
        name = this.ZIP_AGENCY_DATA_NAME;
        break;
      case 'customer-data':
        name = this.ZIP_CUSTOMER_DATA_NAME;
        break;
      case 'final-data':
        name = this.ZIP_FINAL_DATA_NAME;
        break;
    }

    return name;
  }

  /**
   * Upload the dragged files
   * @param event Event drag.
   */
  public dragFilesUpload(event) {
    event.preventDefault();
  }

  /**
   * Upload a file
   * @param event 
   */
  public uploadFile(event) {
    this.loading = true;
    event.preventDefault();
    let files;
    if (event.dataTransfer != null && event.dataTransfer.files != null && event.dataTransfer.files.length > 0) {
      files = event.dataTransfer.files;
    } else if (event.srcElement != null && event.srcElement.files != null && event.srcElement.files.length > 0) {
      files = event.srcElement.files;
    }

    if (files && files.length > 0) {
      this.updating = true;

      if (this.project) {
        this.projectService.getProject(this.assetId).subscribe(data => {
          this.addFiles(data, files);
        });
      } else if (this.request) {
        this.requestService.getRequest(this.assetId).then(data => {
          this.addFiles(data, files);
        });
      } else {
        this.actionsService.getAction(this.assetId).then(data => {
          this.addFiles(data, files);
        });
      } 
    }
  }

  /**
   * Add file to folder
   * @param parent 
   * @param domain 
   * @param file 
   */
  public async addFileToFolder(parent, domain, file, showMaterialMessage = false) {
    let duplicateFile = this.checkDuplicateFile(file.name);

    if (!duplicateFile) {
      let fileFormData = new FormData();

      let mime = file.type;
      let extension = Utils.getFileExtension(file.name);

      let type = this.mimetypeService.getMimetypeByMimetype(mime);
      if (!type && extension) {
        type = this.mimetypeService.getMimetypeByExtension(extension);
      }

      let newMaterial: Material = new Material();

      if (type && type.extension == '.svg') {
        newMaterial.svgfile = true;
      }

      newMaterial.name = file.name;
      newMaterial.parents.push(+parent);
      newMaterial.type = type.def_assettype;
      newMaterial.downloadLink = "formdata:file0";
      newMaterial.domain = domain;
      delete newMaterial.created;
      delete newMaterial.selected;
      delete newMaterial.preview;
      delete newMaterial.step_time;

      newMaterial.workflow = Utils.getWorkflowId(newMaterial.type);
      newMaterial.workflowStep = Utils.getDefaultWorkflowStep(newMaterial.type);

      fileFormData.append("entity", JSON.stringify(newMaterial));
      fileFormData.append("file0", file);

      let result = await this.materialsService.saveMaterial(newMaterial, fileFormData, showMaterialMessage);
      setTimeout(() => {
        if (this.project) {
          this.projectService.getProject(this.assetId).subscribe(project => {
            this.refrehsFolders(project);
          });
        } else if (this.request) {
          this.requestService.getRequest(this.assetId).then(request => {
            this.refrehsFolders(request);
          });
        } else {
          this.actionsService.getAction(this.assetId).then(action => {
            this.refrehsFolders(action);
          });
        }

        this.loading = false;
      }, 1500);
    } else {
      if (!this.names) {
        this.names = this.names.concat(file.name);
      } else {
        this.names = this.names.concat(", " + file.name);
      }

      this.message = this.translateService.instant('duplicate_files') + " " + this.names;
    }

    if (this.message) {
      this.notifications.show(this.message, null, null, 3500, 'error-snack');

      this.message = '';
      this.names = '';
      this.updating = false;
      this.onDragMat = false;
    }
  }

  /**
   * Refresh folders
   * @param asset Project | action
   */
  refrehsFolders(asset) {
    switch (this.type) {
      case 'consulting':
        this.data = asset.consulting[0].files.filter(x => x && x.type != 'group.');
        break;
      case 'cost-estimate':
        this.data = asset.costestimate[0].files.filter(x => x && x.type != 'group.');
        break;
      case 'agency-data':
        this.data = asset.agencydata[0].files.filter(x => x && x.type != 'group.');
        break;
      case 'customer-data':
        this.data = asset.customerdata[0].files.filter(x => x && x.type != 'group.');
        break;
      case 'final-data':
        this.data = asset.finaldata[0].files.filter(x => x && x.type != 'group.');
        break;
    }
    this.updating = false;
    this.onDragMat = false;
    Utils.loadingData = false;
  }

  /**
   * Check if this file is duplicated
   * @param name File name
   * @returns Duplicate file
   */
  checkDuplicateFile(name) {
    let duplicateFile = false;

    if (name && this.data) {
      duplicateFile = this.data.filter(x => x.name == name).length > 0;
    }

    return duplicateFile;
  }

  /**
   * Add files to folder
   * @param asset Asset to which the files are to be added
   * @param files Files to be added
   */
  addFiles(asset, files) {
    Array.from(files).forEach((file) => {
      switch (this.type) {
        case 'consulting':
          if (asset.consulting && asset.consulting.length > 0 && asset.consulting[0]) {
            this.addFileToFolder(asset.consulting[0].id, asset.domain, file);
          }
          break;
        case 'cost-estimate':
          if (asset.costestimate && asset.costestimate.length > 0 && asset.costestimate[0]) {
            this.addFileToFolder(asset.costestimate[0].id, asset.domain, file);
          }
          break;
        case 'agency-data':
          if (asset.agencydata && asset.agencydata.length > 0 && asset.agencydata[0]) {
            this.addFileToFolder(asset.agencydata[0].id, asset.domain, file);
          }
          break;
        case 'customer-data':
          if (asset.customerdata && asset.customerdata.length > 0 && asset.customerdata[0]) {
            this.addFileToFolder(asset.customerdata[0].id, asset.domain, file);
          }
          break;
        case 'final-data':
          if (asset.finaldata && asset.finaldata.length > 0 && asset.finaldata[0]) {
            this.addFileToFolder(asset.finaldata[0].id, asset.domain, file);
          }
          break;
      }
    });
  }

  /**
   * Opens a dialog box with the file information.
   * @param file File
   */
  openInfoDialog(file) {
    this.openInfoDialogArray([file]);
  }

  /**
   * Opens a dialog box with the file information.
   * @param fileArray File from array
   */
  openInfoDialogArray(fileArray): void {
    this.dialog.open(InfoDialogComponent, { width: '900px', height: '40%', data: fileArray });
  }

  /**
   * Download file
   * @param file File to download
   */
  downloadFile(file) {
    this.downloadFiles([file]);

    let message = 'file-downloaded,' + file.name + ',' + this.type;
    let assetType = this.project ? 'project.' : 'order.';

    this.assetService.updateEventRelationMessage(this.assetId, assetType, message);
  }

  /**
   * Dowenload files
   * @param fileList List of files to download
   */
  downloadFiles(fileList) {
    let files = 0;

    if (fileList && fileList.length > 0) {
      if (fileList.length > 1) {
        this.jszip = new JSZip();
      }

      fileList.forEach((x: any, index) => {
        setTimeout(() => {
          this.assetService.getFileBlob(x.downloadLink + '?r=' + Utils.randomHash()).subscribe(fileData => {
            const blob = new Blob([fileData], { type: x.mimetype });
            const mimetype = this.mimetypeService.getMimetypeByMimetype(x.mimetype);

            if (fileList.length > 1) {
              this.jszip.file(x.name.replace(/\.[^/.]+$/, '') + (mimetype ? mimetype.extension : ''), blob);
              files++;

              if (fileList.length === files) {
                this.jszip.generateAsync({ type: "blob" }).then(content => {
                  let date = this.datepipe.transform(new Date(), 'yyyyMMdd_hhmm');
                  saveAs(content, this.assetId + this.getZipFileName() + date + '.zip');
                });
              }

            } else {
              saveAs(blob, x.name.replace(/\.[^/.]+$/, '') + (mimetype ? mimetype.extension : ''));
            }

          });
        }, 100);
      });
    }
  }

  /**
   * Load error
   * @param media Id is obtained to display the error
   */
  loadError(media) {
    this.loadErrorPreview[media.id.toString()] = true;
  }

  /**
   * Opens a dialog with aset information
   * @param fileId File id
   */
  openAssetDialog(fileId) {
    let data = {};

    if (!this.approval) {
      data = {
        component: AssetDetailComponent,
        assetData: {
          assetId: fileId,
          disableDam: true
        }
      }
    } else {
      data = {
        component: MaterialsComponent,
        assetData: {
          // actionId: this.assetId,
          materialId: fileId,
          materials: this.data
        }
      }
    }

    const assetDialog = this.dialog.open(ComponentDialogComponent, { width: '90vw', height: '90vh', data: data });
    assetDialog.afterClosed().subscribe(result => {
    });
  }

  /**
   * Open a dialog to create a material
   * @param item data from material
   */
  openNewMaterialDialog(item = null): void {
    let material: any = {};
    if (item) material = item;

    const newMaterialDialog = this.dialog.open(NewMaterialComponent, { width: '900px', data: material });
    newMaterialDialog.afterClosed().subscribe(result => {
      setTimeout(() => {
        Utils.reloadData = Utils.RELOAD_DATA_FILE_WIDGET;
      }, 3000);

    });
  }

  /**
   * Mark an item for deletion
   * @param item Item to be marked to deleted
   */
  markForDeletion(item) {
    const markForDeletion = item.markDeletion != 1;
    this.materialsService.getMaterial(item.id).then(x => {
      if (x.id) {
        x.markForDeletion = markForDeletion;
        this.materialsService.editMaterial(x).then(y => {
          if (y.id) {
            setTimeout(() => {
              Utils.reloadData = Utils.RELOAD_DATA_FILE_WIDGET;
            }, 3000);
          }
        });
      }
    });
  }

  removeFile(file) {

    this.loading = true;

    this.materialsService.getMaterial(file.id).then(x => {
      if (x.id) {

        if (!x.parents) {
          x.parents = [];
        }

        if (this.project) {
          this.projectService.getProject(this.assetId).subscribe(project => {
            this.removeFileFromFolder(project,x);
          });
        } else if (this.request){
          this.requestService.getRequest(this.assetId).then(request => {
            this.removeFileFromFolder(request,x);
          });
        } else {
          this.actionsService.getAction(this.assetId).then(action => {
            this.removeFileFromFolder(action,x);
          });
        }
        
        this.materialsService.editMaterial(x).then(y => {
          if (y.id) {
            setTimeout(() => {

              this.data.splice(this.data.findIndex(d => d.id === x.id), 1);
              this.loading = false;
              Utils.reloadData = Utils.RELOAD_DATA_FILE_WIDGET;
            }, 3000);
          }
        });
        
      }
    });

  }

  private removeFileFromFolder (asset, material) {
    switch (this.type) {
      case 'consulting':
        if (asset.consulting && asset.consulting.length > 0 && asset.consulting[0]) {
          material.parents.splice(material.parents.indexOf(asset.consulting[0].id), 1);
        }
        break;
      case 'cost-estimate':
        if (asset.costestimate && asset.costestimate.length > 0 && asset.costestimate[0]) {
          material.parents.splice(material.parents.indexOf(asset.costestimate[0].id), 1);
        }
        break;
      case 'agency-data':
        if (asset.agencydata && asset.agencydata.length > 0 && asset.agencydata[0]) {
          material.parents.splice(material.parents.indexOf(asset.agencydata[0].id), 1);
        }
        break;
      case 'customer-data':
        if (asset.customerdata && asset.customerdata.length > 0 && asset.customerdata[0]) {
          material.parents.splice(material.parents.indexOf(asset.customerdata[0].id), 1);
        }
        break;
      case 'final-data':
        if (asset.finaldata && asset.finaldata.length > 0 && asset.finaldata[0]) {
          material.parents.splice(material.parents.indexOf(asset.finaldata[0].id), 1);
        }
        break;
    }
  }
}