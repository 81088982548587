import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * This component defines the main view of tags dialog
 */
@Component({
  selector: 'app-tags-dialog',
  templateUrl: './tags-dialog.component.html',
  styleUrls: ['./tags-dialog.component.scss']
})
export class TagsDialogComponent {

  /**
   * Download formats
   */
  public downloadFormats: string[] = [];
  /**
   * Format
   */
  public format: string;

  /**
   * Tag dialog contructor
   * @param dialogRef {@link MatDialogRef} with the functions related to the dialog.
   */
  constructor(
    private dialogRef: MatDialogRef<TagsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
    ) {

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  /**
   * Get tags
   */
  get tags () {
    return this.data.c.filterValue ? this.data.c.filterValue.tags : this.data.c.filters.find(x => x.name === 'tags').values;
  }

  /**
   * Close Pop up
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Change filter
   * @param tag Tag
   */
  changeFilter(tag) {

    if (this.isSelected(tag)) {

      if (this.data.c.filter) {
        this.data.c.filter.tags.splice(this.data.c.filter.tags.indexOf(tag), 1)
      } else {
        this.data.c.getFilterValue('tags').splice(this.data.c.getFilterValue('tags').indexOf(tag), 1);
      }

    } else {

      if (this.data.c.filter) {
        this.data.c.filter.tags.push(tag);
      } else {
        if (!this.data.c.getFilterValue('tags')) {
          this.data.c.addFilter('tags',[tag]);
        } else {
          this.data.c.getFilterValue('tags').push(tag);
        }
      }

    }
    this.data.c.paginator.firstPage();
    this.data.c.getAssets();
  }

  /**
   * 
   * @param tag Tag
   * @returns True if Tag is selected
   */
  isSelected(tag) {
    return this.data.c.filter ? this.data.c.filter.tags.some(x => x.id === tag.id) : this.data.c.getFilterValue('tags') && this.data.c.getFilterValue('tags').some(x => x.id === tag.id);
  }
}
