import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../notifcations/notifications.service';
import { Authentication } from 'app/shared/guard/authentication/Authentication';
import { Mail } from 'app/shared/model/mail.model';
import { AppSettings } from 'app/shared/app.settings';

/**
 * Service defined to manage the necessary operations for asset notes ({@link Note})
 */
@Injectable({
  providedIn: 'root'
})
export class MailService {

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   */
  constructor(
    private hcmsService: HCMSService,
    private notifications: NotificationsService,
    private translateService: TranslateService
  ) { }

  /**
   * Save mail
   * @param mail Mail to be saved.
   * @param {boolean} showMessage Show a message if true (optional)
   * @param {string} message Message to display (optional)
   * @returns 
   */
  public saveMail(mail, showMessage = true, message = '') {
    return this.hcmsService.get().all('entity/mail').post(mail, {}, { 'Authorization': Authentication.getAuthToken() }).toPromise().then(() => {
      if (showMessage) {
        if (!message) {
          this.notifications.show(this.translateService.instant('mails.success'));
        } else {
          this.notifications.show(this.translateService.instant(message));
        }
      }
    });
  }

  /**
   * Edit mail
   * @param mail New mail. 
   * @returns Mail edited.
   */
  public editMail (mail) {
    return this.hcmsService.get().one('entity/mail', mail.id).get({}, {'Authorization': Authentication.getAuthToken()}).toPromise().then(x => {
      x = Object.assign(x, mail);

      return x.put();
    });
  }

  /**
   * Get all mails.
   * @param {string} usage (optional)
   * @param {number} asset (optional)
   * @param {number} user (optional) 
   * @param {boolean} sent (optional) 
   * @returns 
   */
  public getMails (usage: string = '', asset: number = null, user: number = null, sent = null) {
    let params = [];

    if (usage) {
      params.push('usage="' + usage + '"');
    }
    if (asset) {
      params.push('asset=' + asset);
    }
    if (user) {
      params.push('user=' + user);
    }
    if (sent != null) {
      params.push('sent!=' + sent);
    }

    return this.hcmsService.get().one('entity/mail?query=' + params.join('%26')).get({}, {'Authorization': Authentication.getAuthToken()}).toPromise();

  }

  /**
   * Send project manager mail.
   * @param {string} assetId Asset ID.
   * @param {string} userId User ID.
   */
  public sendProjectManagerMail(assetId, userId) {
    let mail: Mail = new Mail(AppSettings.MAIL_PM_ASSIGNED + '_' + assetId + '_' + userId, AppSettings.MAIL_PM_ASSIGNED, +assetId, +userId);
    this.saveMail(mail, false);
  }

  /**
   * Send project manager mail.
   * @param {string} assetId Asset ID.
   * @param {string} userId User ID.
   */
   public sendAddToTeamMail(assetId, userId, type) {

    let mail: Mail = new Mail(AppSettings.MAIL_NEW_TEAM_ADDED + '_' + assetId + '_' + userId, AppSettings.MAIL_NEW_TEAM_ADDED, +assetId, +userId);
    if (type) {
      const typeSet = type.split('.')[0];
      mail.message = typeSet === 'order' ? 'job' : typeSet;
    }
    
    this.saveMail(mail, false);
  }
}
