import { AppSettings } from "app/shared/app.settings";

/**
 * This class defines the structure of the keywords used to filter the assets.
 */
export class AssetModel {
    /**
     * The name of the asset
     */
    name: string;
    /**
     * The unique asset identifier
     */
    id: number;
    /**
     * The domain of the asset
     */
    domain: string = AppSettings.DOMAIN;
    /**
     * The domain of the asset
     */
    domain2: string = AppSettings.DEFAULT_SECOND_DOMAIN_ROOT;
    /**
     * The type of the asset
     */
    type: string;
    /**
     * The link to download the asset
     */
    downloadLink: string;

    show: boolean;
    /**
     * If it is a file of type svg
     */
    svgfile: boolean;
    /**
     * The status of this asset
     */
    workflow: number;
    /**
     * This property sets the status of the asset
     */
    workflowStep: number = 10;
    /**
     * True if asset preview needs to be reloaded
     */
    reloadPreview: boolean;
    /**
     * This property marks the asset to be removed 

     */
    markForDeletion: boolean;
    /**
     * Identifier of the creator of the new asset
     */
    createdByNew: number;
    /**
     * Identifier of the person who modified the asset
     */
    
    modifiedByNew: number;
    /**
     * Output channels
     */
    outputChannels: string[] = [];
}
/**
 * This class defines the structure of type validation of file
 */
export class TypeValidation {
    /**
     * Constructor
     * @param message Warning text if there is an error in the form
     * @param fileType The name of the file to be uploaded
     */
    constructor(message: string, fileType: string = null) {
        this.message = message;
        this.fileType = fileType;
    }
    /**
   * Warning text if there is an error in the form
   */
    message: string;
    /**
     * The name of the file to be uploaded
     */
    fileType: string;
}
