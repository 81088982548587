import { CustomDataSource } from "./dataSource/custom-datasource";
import { ViewChild, AfterViewInit, Input } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { UserService } from "../services/user/user.service";
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SaveStateComponent } from "../saveState/saveState";
import { LiquidCacheService } from "ngx-liquid-cache";
import { Router } from "@angular/router";
import Utils from "../utils/utils";
import { TrackingService } from "../services/tracking/tracking.service";

import { SearchService } from "../services/search/search.service";

/**
 * This class defines the methods and attributes to list assets as a table.
 */
export class Table extends SaveStateComponent implements AfterViewInit {

    /**
     * General view key
     */
    general_view = 'general';

    /**
     * Personal view key
     */
    personal_view = 'personal';

    /**
     * Watchlist view key
     */
    watchlist_view = 'watchlist';

    /**
     * The current view key. The default value is the general view
     */
    @Input() current_view = this.general_view;

    /**
     * True if a new button row is needed
     */
    isNewButtonRow = (index, item) => item.newButton;

    /**
     * MatSort to control the sorting
     */
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    /**
     * MatPaginator to control the paginator
     */
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    /**
     * {@link CustomDataSource} with the data of the table
     */
    dataSource: CustomDataSource;

    /**
     * The original size. Default value is 0
     */
    originalSize: number = 0;

    /**
     * The size of the list
     */
    listSize: number;

    /**
     * The size of the page. Default value is 10
     */
    pageSize: number = 10;

    /**
     * True if all elements of the list are selected
     */
    selectAll = false;

    /**
     * True if the data is loaded
     */
    loaded: boolean = false;

    /**
     * The current page index
     */
    pageIndex: number = 0;

    /**
     * The default column to sort the list
     */
    defaultSortColumn;

    /**
     * The default direction to sort the list
     */
    defaultSortDirection;

    /**
     * True if user can see the select checkbox
     */
    showSelectCheckbox: boolean = false;

    /**
     * True if user can see the new button
     */
    showNewButton: boolean = false;

    /**
     * Array with the list of elements
     */
    list = [];

    /**
     * Array with the elements that are currently showed
     */
    currentList = [];

    /**
     * Array with the filters
     */
    filters = [];

    /**
     * Array with the elements of the general view
     */
    general = [];

    /**
     * Array with the elements of the personal view
     */
    personal = [];

    /**
     * Array with the elements of the watchlist view
     */
    watchlist = [];

    /**
     * @ignore
     */
    protected id = -1;

    /**
     * The width of the filters. Default value is 20
     */
    filterWidth: number = 20;

    /**
     * Minimum value for the width of the filters
     */
    minFilterWidth: number = 4;

    /**
     * Maximum value for the width of the filters
     */
    maxFilterWidth: number = 20;

    /**
     * The last keyword used in the search
     */
    private lastKeyword: string = '';

    /**
     * True if the facets search is enabled
     */
    facetsSearch = false;

    /**
     * Array with the facets ids
     */
    facetsIds: number[] = [];

    /**
     * Array with the facets elements
     */
    facets: string[] = [];

    /**
     * Array with the ids of the elements searched by the query
     */
    searchQueryIds: number[] = [];

    /**
     * True if it's the first time that the data is loaded
     */
    firstLoad = true;

    /**
     * Array with the filtered elements
     */
    filteredList = [];

    /**
     * @ignore
     */
    myList = [];

    /**
     * {@link User} with the current user that is logged in.
     */
    protected currentUser;

    /**
     * The constructor
     * @param usersService Service with the functions related to the users.
     * @param cache Service with the functions related to the cache.
     * @param router Service with the functions related to the router.
     * @param trackingService Service with the functions related to the tracking.
     * @param searchService Service with the functions related to the search.
     */
    constructor(usersService: UserService,
        cache: LiquidCacheService,
        router: Router,
        protected trackingService?: TrackingService,
        protected searchService?: SearchService) {
        super(cache, router, usersService);
        this.dataSource = new CustomDataSource();
    }

    /**
     * Changes the value of the current view and the list and loads the data
     * @param view The new view
     */
    changeListView(view) {
        this.current_view = view;
        if (view === this.general_view) {
            this.list = this.general;
        } else if (view === this.personal_view) {
            this.list = this.personal;
        } else if (view === this.watchlist_view) {
            this.list = this.watchlist;
        }

        this.loadData();
    }

    /**
     * Checks for changes in the list of elements to reload the list
     */
    ngDoCheck() {
        super.ngDoCheck();

        if (Utils.RELOAD_DATA_NONE != Utils.reloadData && Utils.reloadData == this.id) {

            setTimeout(() => {
                this.ngOnInit();
            }, 1500);

            Utils.reloadData = Utils.RELOAD_DATA_NONE;
        }
    }

    /**
     * Initialize the class and retrieve the data.
     */
    ngOnInit() {
        super.ngOnInit();
        this.currentUser = this.usersService.getCurrentUser();
        Utils.reloadData = Utils.RELOAD_DATA_NONE;

        this.addItem(this.filterWidth, 'filterWidth');
        this.addItem(this.filters, 'filters');

        this.addItem(this.pageIndex, 'pageIndex');

        if (this.firstLoad) {
            if ((this.facetsSearch && this.getFilterValue('text'))) {
                this.searchFacetsChanged();
            }

            if (this.getFilterValue('querySearch')) {
                this.searchByQuery();
            }

            this.firstLoad = false;
        }

        this.loadData();

    }

    /**
     * Method that is called after the view of the component has started to be displayed.
     */
    ngAfterViewInit(): void {

        if (this.sort) {
            this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

            merge(this.sort.sortChange, this.paginator.page)
                .pipe(
                    tap(() => this.loadData())
                )
                .subscribe();
        }

        if (this.paginator) {
            this.paginator.page.subscribe(event => {
                this.pageIndex = event.pageIndex;
            });
        }
    }

    /**
     * Checks for changes in the size of the list
     */
    checkNewData() {
        if (this.list.length == 0 && this.general.length > 0) {
            this.list = this.general;
        }

        if (this.originalSize != this.list.length && this.list.length >= 0) {
            this.originalSize = this.list.length;
            this.changeList();
        }
    }

    /**
     * Loads the data
     */
    loadData() {

        if (this.list.length == 0) {
            this.list = this.general;
        }

        this.filteredList = this.dataSource.getData(this.list, this.sort, this.filters, this.defaultSortColumn, this.defaultSortDirection, this.currentUser);

        if ((this.facetsSearch && this.getFilterValue('text'))) {
            this.filteredList = this.filteredList.filter(x => this.facetsIds.includes(x.id));
        }

        if (this.getFilterValue('querySearch')) {
            this.filteredList = this.filteredList.filter(x => this.searchQueryIds.includes(x.id));
        }

        this.currentList = this.filteredList;
        this.dataSource.setData(this.filteredList, this.paginator, this.pageSize, this.showNewButton);

        this.listSize = this.filteredList.length;
        this.updateFilters();
        this.refreshFilters(this.currentList);
    }

    refreshFilters(currentData) { }

    /**
     * Fills the data, updates the filters and loads the data
     */
    changeList() {
        this.fillData();
        this.updateFilters();
        this.loadData();
    }

    /**
     * Updates the selection of all elements
     */
    updateSelectAll() {
        this.filteredList.filter(x => x && !x.checked_out).map(x => x.checked = !this.selectAll);
    }

    /**
     * Checks if the filter exists and has a value
     * @param filterName The name of the filter
     * @returns True if the filter exists and has a value
     */
    checkFilter(filterName) {
        return this.filters.filter(filter => filter.name == filterName)[0] && this.filters.filter(filter => filter.name == filterName)[0].value;
    }

    /**
     * Reset the filters to have no value and loads the data
     */
    resetFilters() {
        this.filters.forEach(filter => {
            if (filter.name != 'user') {
                filter.value = null;
            }
        });
        this.facetsIds = [];
        this.loadData();
    }

    /**
     * Sets the values for a filter
     * @param filterName The name of the filter
     * @param values The values
     * @param displayValues The values to display
     */
    setValues(filterName, values, displayValues = null) {

        let filtered = this.filters.find(filter => filter.key == filterName);
        if (filtered) {
            filtered.displayValues = filtered.values = values;
            if (displayValues) {
                filtered.displayValues = displayValues;
            }
            if (!filtered.value) {
                filtered.valueMin = filtered.values[0];
                filtered.valueMax = filtered.values[filtered.values.length - 1];
            }
        }
    }

    /**
     * Changes the paginator, loads the data and updates the filtes when any filter changed
     */
    filterChanged() {
        this.paginator.firstPage();
        this.loadData();
        this.updateFilters();
    }

    /**
     * Changes the paginator, search for facets and loads the data when the facets search is changed
     */
    searchFacetsChanged() {
        this.paginator.firstPage();

        this.searchService.search(this.getFilterValue('text'), 'order.').then(result => {

            if (result) {
                this.facetsIds = result.result.map(x => x.id);
                this.facets = result.facets.type.values;
            }

            this.loadData();

        });
    }

    /**
     * Searchs by query and reloads the list
     */
    searchByQuery() {
        this.paginator.firstPage();

        if (this.getFilterValue('querySearch')) {
            const result = this.getFilterValue('querySearch');

            let query = Object.keys(result).reduce((acc, x) => {
                let q = acc;

                if (Array.isArray(result[x])) {
                    if (result[x].length > 1) {
                        q += '&' + x + '>"' + result[x][result[x].length - 1] + 'a"';
                        q += '&' + x + '<"' + result[x][result[x].length - 1] + 'zz"';
                    }
                } else if (result[x] && result[x].startDate) {
                    q += '&' + x + '>="' + result[x].startDate + '"';

                    if (result[x].endDate) {
                        q += '&' + x + '<="' + result[x].endDate + '"';
                    } else {
                        q += '&' + x + '<="' + result[x].startDate.replace('00:00:00', '23:59:59') + '"';
                    }
                } else if (typeof result[x] === 'boolean') {
                    q += '&' + x + '=' + (result[x] ? 1 : 0);
                } else if (typeof result[x] === 'string') {
                    q += '&' + x + '="' + result[x] + '"';
                } else {
                    q += '&' + x + '=' + result[x];
                }

                return q;
            }, '');

            if (query) {
                query = query.substring(1).replace('&', '%26');

                this.searchService.searchByQuery(query).then(result => {

                    if (result) {
                        this.searchQueryIds = result.result.map(x => x.id);
                    }
                    this.loadData();

                });
            }


        }
    }

    /**
     * Gets the value of a filter
     * @param name The name of the filter
     * @returns The value of the filter
     */
    getFilterValue(name) {
        if (this.filters.some(x => x.name == name)) {
            return this.filters.find(x => x.name == name).value;
        }
    }

    /**
     * Sets the value of a filter
     * @param filterName The name of the filter
     * @param value The value of the filter
     * @param reloadData True if the list of elements needs to be reloaded
     */
    addFilter(filterName, value, reloadData = true) {

        this.paginator.firstPage();
        // this.filters.filter(filter => filter.name == filterName)[0].value = value;
        const filter = this.filters.filter(filter => filter.key == filterName)[0];

        if (filter) {
            filter.value = value;
            filter.displayValue = filter.displayValues[filter.values.indexOf(value)];
        }

        if (reloadData) {
            this.loadData();
        }

        if (filterName === 'text') {
            setTimeout(() => {
                var value = this.filters.filter(filter => filter.name == filterName)[0].value;
                if (value != null && value.length > 3 && value !== this.lastKeyword) {
                    this.lastKeyword = value;
                    this.trackingService.sendMatomoSearch(value);
                }
            }, 5000);
        }
    }

    /**
     * Removes a filter
     * @param filterName The name of the filter
     * @param value The value of the filter
     */
    removeFilter(filterName, value) {
        this.paginator.firstPage();
        const vals = this.filters.filter(filter => filter.name == filterName)[0].value;
        this.filters.filter(filter => filter.name == filterName)[0].value = vals.filter(x => x.value_key ? x.value_key !== value.value_key : x !== value);
        this.loadData();
    }

    /**
     * Checks if there is a filter with value
     * @returns True if there is a filter with value
     */
    existFilter() {
        return this.filters.filter(filter => filter.value != null).length > 0;
    }

    /**
     * Checks if the reset filter button can be showed
     * @returns True if there is more than one filter
     */
    showResetFilter() {
        return this.filters.filter(filter => filter.value != null).length > 1;
    }

    /**
     * Adds the project to the watchlist
     * @param project The project to be added
     */
    addWatchlist(project) {
        project.watchlist = true;
        this.usersService.addWatchlist(project.id);
        this.watchlist.push(project);
    }

    /**
     * Removes the project from the watchlist
     * @param project The project to be removed
     */
    removeWatchlist(project) {
        project.watchlist = false;
        this.usersService.removeWatchlist(project.id);

        const index = this.watchlist.indexOf(project);
        if (index > -1) {
            this.watchlist.splice(index, 1);
        }
    }

    /**
     * Gets the value of the item
     * @param item The item
     * @param name The name of the filter
     * @returns The item or the value of the filter that has the item id
     */
    getItemValue(item, name) {

        let result = item;

        if (!(item !== Object(item))) {
            result = this.filters.find(x => x.name == name).values.find(x => x.id == item.id);
        }

        return result;
    }

    /**
     * Updates the filters
     */
    updateFilters() { }

    /**
     * Fill the data
     */
    fillData() { }

    /**
     * Gets the selected assets
     */
    get selectedAssets() {
        return this.list.filter(x => x.checked);
    }
}
