import { environment } from "environments/environment";
import * as CryptoJS from 'crypto-js';

/**
 * This component defines common configurations
 */
export class AppSettings {

    /**
     * App default language.
     */
    static readonly APP_DEFAULT_LANGUAGE = 'en';
    /**
     * App default domain.
     */
    static readonly APP_DEFAULT_DOMAIN: string = 'root.media_library.';
    /**
     * App prefix.
     */
    static readonly APP_PREFIX: string = 'cntr-siku-';
    /**
     * App login prefix.
     */
    static readonly APP_LOGGIN_PREFIX: string = 'siku-';
    /**
     * Domain
     */
    static readonly DOMAIN: string = 'root.media_library.';
    static readonly DEFAULT_DOMAIN: string = 'root.media_library.';
    /**
     * Domain 2
     */
    static readonly DOMAIN2: string = 'root.';
    /**
     * Workflow ID
     */
    static readonly WORKFLOW_ID: number = 300;
    /**
     * User Storage.
     */
    static readonly USER_STORAGE: string = "LoggedInProfile";
    /**
     * OC Site
     */
    static readonly OC_SITE: string = "siku";

    /**
     * Default output channels
     */
    static readonly DEFAULT_OUTPUTCHANNELS = ['root.cntr.hubz.'];

    //Workflows
    /**
     * Workflow company
     */
    static readonly WORKFLOW_COMPANY: number = 70000;
    /**
     * Workflow company pending
     */
    static readonly WORKFLOW_COMPANY_PENDING: number = 10;
    /**
     * Workflow company send
     */
    static readonly WORKFLOW_COMPANY_SEND: number = 20;

    /**
     * Workflow webuser
     */
    static readonly WORKFLOW_WEBUSER: number = 60000;
    /**
     * Workflow project
     */
    static readonly WORKFLOW_PROJECT: number = 17500;
    /**
     * Workflow action
     */
    static readonly WORKFLOW_ACTION: number = 17000;
    /**
     * Workflow task
     */
    static readonly WORKFLOW_TASK: number = 17600;
    /**
     * Workflow briefing
     */
    static readonly WORKFLOW_BRIEFING: number = 17700;

    /**
     * Workflow id picture
     */
    static readonly WORKFLOW_ID_PICTURE: number = 6050;
    /**
     * Workflow id video
     */
    static readonly WORKFLOW_ID_VIDEO: number = 50;
    /**
     * Workflow id pdf
     */
    static readonly WORKFLOW_ID_PDF: number = 6010;
    /**
     * Workflow id audio
     */
    static readonly WORKFLOW_ID_AUDIO: number = 15;
    /**
     * Workflow id briefing
     */
    static readonly WORKFLOW_ID_BRIEFING: number = 9051;
    /**
     * Workflow id office
     */
    static readonly WORKFLOW_ID_OFFICE: number = 6060;
    /**
     * Workflow id presentation
     */
    static readonly WORKFLOW_ID_PRESENTATION: number = 1130;
    /**
     * Workflow id zip
     */
    static readonly WORKFLOW_ID_ZIP: number = 65;
    /**
     * Workflow id webpage
     */
    static readonly WORKFLOW_ID_WEBPAGE: number = 20;
    /**
     * Workflow id spreadsheet
     */
    static readonly WORKFLOW_ID_SPREADSHEET: number = 5065;
    /**
     * Workflow id text
     */
    static readonly WORKFLOW_ID_TEXT: number = 6030;
    /**
     * Workflow invalid states
     */
    static readonly WORKFLOW_INVALID_STATES = [30, 40, 85, 100];
    /**
     * Workflow step no download
     */
    static readonly WORKFLOW_STEP_NO_DOWNLOAD = 22;
    /**
     * Workflow step final data approval
     */
    static readonly WORKFLOW_STEP_FINAL_DATA_APPROVAL = 23;

    // Initial Workflow Step
    /**
     * Default workflow step picture
     */
    static readonly DEFAULT_WORKFLOW_STEP_PICTURE: number = 20;
    /**
     * Default workflow step pdf
     */
    static readonly DEFAULT_WORKFLOW_STEP_PDF: number = 15;
    /**
     * Default workflow step office
     */
    static readonly DEFAULT_WORKFLOW_STEP_OFFICE: number = 15;
    /**
     * Default workflow step text
     */
    static readonly DEFAULT_WORKFLOW_STEP_TEXT: number = 20;
    /**
     * Default workflow step
     */
    static readonly DEFAULT_WORKFLOW_STEP: number = 500;

    // Rejected Workflow Step
    /**
     * Rejected workflow step picture
     */
    static readonly REJECTED_WORKFLOW_STEP_PICTURE: number = 30;
    /**
     * Rejected workflow step pdf
     */
    static readonly REJECTED_WORKFLOW_STEP_PDF: number = 17;
    /**
     * Rejected workflow step office
     */
    static readonly REJECTED_WORKFLOW_STEP_OFFICE: number = 17;
    /**
     * Rejected workflow step text
     */
    static readonly REJECTED_WORKFLOW_STEP_TEXT: number = 25;
    /**
     * Rejected workflow step
     */
    static readonly REJECTED_WORKFLOW_STEP: number = 510;

    // Approved Workflow Step
    /**
     * Approved workflow step picture
     */
    static readonly APPROVED_WORKFLOW_STEP_PICTURE: number = 40;
    /**
     * Approved workflow step pdf
     */
    static readonly APPROVED_WORKFLOW_STEP_PDF: number = 20;
    /**
     * Approved workflow step office
     */
    static readonly APPROVED_WORKFLOW_STEP_OFFICE: number = 20;
    /**
     * Approved workflow step text
     */
    static readonly APPROVED_WORKFLOW_STEP_TEXT: number = 30;
    /**
     * Approved workflow step
     */
    static readonly APPROVED_WORKFLOW_STEP: number = 520;

    //GET DATA
    /**
     * Limit
     */
    static readonly LIMIT: number = 500;
    /**
     * Preview limit
     */
    static readonly PREVIEW_LIMIT: number = 9;

    //Mails
    /**
     * Mail pending materials.
     */
    static readonly MAIL_PENDINGMATERIALS: string = 'pendingMaterials';
    /**
     * Mail new material
     */
    static readonly MAIL_NEWMATERIAL: string = 'newMaterial';
    /**
     * Mail no material action
     */
    static readonly MAIL_NOMATERIALACTION: string = 'noMaterialsAction';
    /**
     * Mail no material action approved
     */
    static readonly MAIL_NOMATERIALACTIONAPPROVED: string = 'noMaterialsActionApproved';
    /**
     * Mail new user
     */
    static readonly MAIL_NEWUSER: string = 'newUser';
    /**
     * Mail new user admin
     */
    static readonly MAIL_NEWUSERADMINISTRATOR: string = 'newUserAdministrator';
    /**
     * Mail start in approval
     */
    static readonly MAIL_STARTINAPPROVAL: string = 'startInApproval';
    /**
     * Mail confirmation
     */
    static readonly MAIL_CONFIRMATION: string = 'confirmation';
    /**
     * Mail material action updated
     */
    static readonly MAIL_MATERIALACTIONUPDATED: string = 'materialActionUpdated';
    /**
     * Mail recovery
     */
    static readonly MAIL_RECOVERY: string = 'recovery';
    /**
     * Mail invitation
     */
    static readonly MAIL_INVITATION: string = 'invitation';
    /**
     * Mail action pm
     */
    static readonly MAIL_ACTION_PM: string = 'actionPM';
    /**
     * Mail new task assigned
     */
    static readonly MAIL_NEWTASKASSIGNED: string = 'newTaskAssigned';
    /**
     * Mail new task assigned
     */
    static readonly MAIL_NEW_REQUEST: string = 'newRequest';
    /**
     * Mail update Request
     */
    static readonly MAIL_UPDATE_REQUEST: string = 'updateRequest';
    /**
     * Mail when a cost estimate task of request change status to approved
     */
    static readonly MAIL_COST_ESTIMATE_APPROVED: string = 'costEstimateApproved';
    /**
     * Mail when a cost estimate task of request change status to declined
     */
    static readonly MAIL_COST_ESTIMATE_DECLINED: string = 'costEstimateDeclined';
    /**
     * Mail when a cost estimate task of request change status to approved and the user is manager
     */
     static readonly MAIL_COST_ESTIMATE_MANAGER_APPROVED: string = 'costEstimateManagerApproved';
     /**
      * Mail when a cost estimate task of request change status to declined and the user is manager
      */
     static readonly MAIL_COST_ESTIMATE_MANAGER_DECLINED: string = 'costEstimateManagerDeclined';
    /**
     * Mail when a request gets converted to job
     */
    static readonly MAIL_NEW_JOB_FROM_REQUEST: string = 'newJobFromRequest';
    /**
     * Mail when a request gets converted to job informs to power user
     */
    static readonly MAIL_NEW_JOB_FROM_REQUEST_POWER_USER: string = 'newJobFromRequestPowerUser';
    /**
     * Mail new password
     */
    static readonly MAIL_NEW_PASSWORD: string = 'newPassword';
    /**
     * Mail new company user
     */
    static readonly MAIL_NEW_COMPANY_USER: string = 'newCompanyUser';
    /**
     * Mail confirm company user
     */
    static readonly MAIL_CONFIRM_COMPANY_USER: string = 'confirmCompanyUser';
    /**
     * Mail update profile
     */
    static readonly MAIL_UPDATE_PROFILE: string = 'updateProfile';
    /**
     * Mail update compoany profile
     */
    static readonly MAIL_UPDATE_COMPANY_PROFILE: string = 'updateCompanyProfile';

    /**
     * New feedback mail steps
     */
    static readonly NEW_FEEDBACK_MAIL_STEPS = [21, 23, 24, 25];
    /**
     * Check material mail steps
     */
    static readonly CHECK_MATERIAL_MAIL_STEPS = [22, 27];
    /**
     * Job assigned mail production steps
     */
    static readonly JOB_ASSIGNED_MAIL_PRODUCTION_STEPS = [20, 28];
    /**
     * Empty fields job mail step
     */
    static readonly EMPTY_FIELDS_JOB_MAIL_STEP = 23;
    /**
     * Mail new feedback
     */
    static readonly MAIL_NEW_FEEDBACK: string = 'newFeedback';
    /**
     * Mail job assigned
     */
    static readonly MAIL_JOB_ASSIGNED: string = 'jobAssigned';
    /**
     * Mail check material
     */
    static readonly MAIL_CHECK_MATERIAL: string = 'checkMaterial';
    /**
     * Mail job comment
     */
    static readonly MAIL_JOB_COMMENT: string = 'jobComment';
    /**
     * Mail pm assigned
     */
    static readonly MAIL_PM_ASSIGNED: string = 'pmAssigned';
    /**
     * Mail notification
     */
    static readonly MAIL_NOTIFICATION: string = 'notificationCntr';
    /**
     * Mail share collection
     */
    static readonly MAIL_SHARE_COLLECTION: string = 'shareCollection';
    /**
     * Mail new project from request, Power user gets informed.
     */
    static readonly MAIL_NEW_PROJECT_FROM_REQUEST_POWER_USER: string = 'newProjectFromRequestPowerUser';

    /**
     * Mail add to team .
     */
    static readonly MAIL_NEW_TEAM_ADDED: string = 'newTeamAdded';
    static readonly MAIL_RECOVERY_CONFIRMATION: string = 'recoveryConfirmation';

    // Tracking
    /**
     * Ati tracking id
     */
    static readonly ATI_TRACKING_ID: string = ''; // '605337';

    // MATOMO
    /**
     * Matomo url
     */
    static readonly MATOMO_URL: string = 'https://matomo.web.pixels-lhgroup.com/';
    /**
     * Matomo id
     */
    static readonly MATOMO_ID: string = ''; //CNTR - 4

    //SIDEBAR
    /**
     * Sidebar config
     */
    static readonly SIDEBAR_CONFIG = [
        { key: 'logo', visible: true },
        { key: 'assets', visible: true },
        { key: 'quickbutton', visible: true },
        { key: 'messages', visible: true },
        { key: 'search', visible: true },
        { key: 'profile', visible: true }
    ]

    // Collaboration menu
    /**
     * Dashboard navlist item
     */
    private static readonly DASHBOARD_NAVLIST_ITEM = { key: 'dashboard', icon: 'view_compact', text: 'dashboard', tooltip: 'dashboard', class: 'icon-menu-special', routerLink: 'dashboard', items: [], visible: true }
    /**
     * Projects navlist item
     */
    private static readonly PROJECTS_NAVLIST_ITEM = { key: 'projects', icon: 'assignment_ind', text: 'projects', tooltip: 'all_projects', class: '', routerLink: 'projects', items: [], visible: true }
    /**
     * Jobs navlist item
     */
    private static readonly JOBS_NAVLIST_ITEM = { key: 'jobs', icon: 'assignment', text: 'sidebar.jobs', tooltip: 'all_actions', class: '', routerLink: 'jobs', items: [], visible: true }
    /**
     * Tasks navlist item
     */
    private static readonly TASKS_NAVLIST_ITEM = { key: 'tasks', icon: 'assignment_turned_in', text: 'tasks', tooltip: 'all_tasks', class: '', routerLink: 'tasks', items: [], visible: true }
    /**
     * Briefings navlist item
     */
    private static readonly BRIEFINGS_NAVLIST_ITEM = { key: 'briefings', icon: 'task', text: 'sidebar.briefings', tooltip: 'briefings', class: '', routerLink: 'briefings', items: [], visible: false }
    /**
     * Request navlist item
     */
    private static readonly REQUEST_NAVLIST_ITEM = { key: 'requests', icon: 'insert_drive_file', text: 'sidebar.requests', tooltip: 'sidebar.requests', class: '', routerLink: 'requests', items: [], visible: true }
    /**
     * Reportings navlist item
     */
    private static readonly REPORTINGS_NAVLIST_ITEM = { key: 'reports', icon: 'bar_chart', text: 'sidebar.reportings', tooltip: 'sidebar.reportings', class: '', routerLink: 'reporting', items: [], visible: true }
    /**
     * Resource Manager navlist item
     */
    private static readonly RESOURCE_MANAGER_NAVLIST_ITEM = { key: 'resource_manager', icon: 'clear_all', text: 'sidebar.planning', tooltip: 'sidebar.planning', class: '', routerLink: 'resource-manager', items: [], visible: true }

    // Library menu
    /**
     * Assets navlist items
     */
    private static readonly ASSETS_NAVLIST_ITEM = { key: 'assets', icon: 'view_module', text: 'overview', tooltip: 'all_assets', class: '', routerLink: 'assets', items: [], visible: true }
    /**
     * Collections navlist items
     */
    private static readonly COLLECTIONS_NAVLIST_ITEM = { key: 'collections', icon: 'folder', text: 'sidebar.collections', tooltip: 'sidebar.collections', class: '', routerLink: 'collections', items: [], visible: true }
    /**
     * Upload navlist items
     */
    private static readonly UPLOAD_NAVLIST_ITEM = { key: 'upload', icon: 'arrow_circle_up', text: 'sidebar.upload', tooltip: 'sidebar.upload', class: '', routerLink: 'dam', items: [], visible: true }

    // Brand menu
    /**
     * Brand overview item
     */
    private static readonly BRAND_OVERVIEW_NAVLIST_ITEM = { key: 'brand-overview', icon: 'globe', text: 'overview', tooltip: 'overview', class: '', routerLink: 'brand/overview', items: [], visible: true }
    /**
     * Brand general info navlist item
     */
    private static readonly BRAND_GENERAL_INFO_NAVLIST_ITEM = { key: 'brand-general-info', icon: 'info-circle', text: 'sidebar.general-info', tooltip: 'sidebar.general-info', class: '', routerLink: 'brand/general-info', items: [], visible: true }
    /**
     * Brand navlist item
     */
    private static readonly BRAND_NAVLIST_ITEM = { key: 'brand', icon: 'star', text: 'sidebar.brand', tooltip: 'sidebar.brand', class: '', routerLink: '', items: { key: 'brand-submenu', items: [AppSettings.BRAND_OVERVIEW_NAVLIST_ITEM, AppSettings.BRAND_GENERAL_INFO_NAVLIST_ITEM] }, visible: true, option: "submenu" }

    /**
     * Basic elements logo navlist item
     */
    private static readonly BASIC_ELEMENTS_LOGO_NAVLIST_ITEM = { key: 'logo', icon: 'vision', text: 'sidebar.logo', tooltip: 'sidebar.logo', class: 'icon-menu-special', routerLink: 'basic-elements/logo', items: [], visible: true }
    /**
     * 
     */
    private static readonly BASIC_ELEMENTS_TYPOGRAPHY_NAVLIST_ITEM = { key: 'be-typography', icon: 'type', text: 'sidebar.typography', tooltip: 'sidebar.typography', class: '', routerLink: 'basic-elements/typography', items: [], visible: true }
    /**
     * Basic elements colour navlist item
     */
    private static readonly BASIC_ELEMENTS_COLOUR_NAVLIST_ITEM = { key: 'colour', icon: 'brush', text: 'sidebar.colour', tooltip: 'sidebar.colour', class: '', routerLink: 'basic-elements/colour', items: [], visible: true }
    /**
     * Basic elements imagery navlist item
     */
    private static readonly BASIC_ELEMENTS_IMAGERY_NAVLIST_ITEM = { key: 'imagery', icon: 'image', text: 'sidebar.imagery', tooltip: 'sidebar.imagery', class: '', routerLink: 'basic-elements/imagery', items: [], visible: true }
    /**
     * Basic elements icons navlist item
     */
    private static readonly BASIC_ELEMENTS_ICONS_NAVLIST_ITEM = { key: 'icons', icon: 'flower1', text: 'sidebar.icons', tooltip: 'sidebar.icons', class: '', routerLink: 'basic-elements/icons', items: [], visible: true }
    /**
     * Basic elements eye catchers navlist item
     */
    private static readonly BASIC_ELEMENTS_EYE_CATCHERS_NAVLIST_ITEM = { key: 'eye-catchers', icon: 'eye', text: 'sidebar.eye-catchers', tooltip: 'sidebar.eye-catchers', class: '', routerLink: 'basic-elements/eye-catchers', items: [], visible: true }
    /**
     * Basic elements info graphics navlist item
     */
    private static readonly BASIC_ELEMENTS_INFO_GRAPHICS_NAVLIST_ITEM = { key: 'info-graphics', icon: 'pie-chart', text: 'sidebar.info-graphics', tooltip: 'sidebar.info-graphics', class: '', routerLink: 'basic-elements/info-graphics', items: [], visible: true }
    /**
     * Basic elements additional elements navlist items
     */
    private static readonly BASIC_ELEMENTS_ADDITIONAL_ELEMENTS_NAVLIST_ITEM = { key: 'be-additional-elements', icon: 'inbox', text: 'sidebar.additional-elements', tooltip: 'sidebar.additional-elements', class: '', routerLink: 'basic-elements/additional-elements', items: [], visible: true }
    /**
     * Brand basic elements navlist item
     */
    private static readonly BRAND_BASIC_ELEMENTS_NAVLIST_ITEM = { key: 'basic-elements', icon: 'icon-jobs', text: 'sidebar.basic-elements', tooltip: 'sidebar.basic-elements', class: '', routerLink: '', items: { key: 'basic-elements-submenu', items: [AppSettings.BASIC_ELEMENTS_LOGO_NAVLIST_ITEM, AppSettings.BASIC_ELEMENTS_TYPOGRAPHY_NAVLIST_ITEM, AppSettings.BASIC_ELEMENTS_COLOUR_NAVLIST_ITEM, AppSettings.BASIC_ELEMENTS_IMAGERY_NAVLIST_ITEM, AppSettings.BASIC_ELEMENTS_ICONS_NAVLIST_ITEM, AppSettings.BASIC_ELEMENTS_EYE_CATCHERS_NAVLIST_ITEM, AppSettings.BASIC_ELEMENTS_INFO_GRAPHICS_NAVLIST_ITEM, AppSettings.BASIC_ELEMENTS_ADDITIONAL_ELEMENTS_NAVLIST_ITEM] }, visible: true, option: "submenu" }

    /**
     * Digital experience web colours navlist item
     */
    private static readonly DIGITAL_EXPERIENCE_WEB_COLOURS_NAVLIST_ITEM = { key: 'web-colours', icon: 'brush', text: 'sidebar.web-colours', tooltip: 'sidebar.web-colours', class: '', routerLink: 'digital-experience/web-colours', items: [], visible: true }
    /**
     * Digital experience typography navlist item
     */
    private static readonly DIGITAL_EXPERIENCE_TYPOGRAPHY_NAVLIST_ITEM = { key: 'de-typography', icon: 'type', text: 'sidebar.typography', tooltip: 'sidebar.typography', class: '', routerLink: 'digital-experience/typography', items: [], visible: true }
    /**
     * Digital experience responsive web navlist item
     */
    private static readonly DIGITAL_EXPERIENCE_RESPONSIVE_WEB_NAVLIST_ITEM = { key: 'responsive-web', icon: 'pip', text: 'sidebar.responsive-web', tooltip: 'sidebar.responsive-web', class: '', routerLink: 'digital-experience/responsive-web', items: [], visible: true }
    /**
     * Digital experience native app navlist item
     */
    private static readonly DIGITAL_EXPERIENCE_NATIVE_APP_NAVLIST_ITEM = { key: 'native-app', icon: 'phone', text: 'sidebar.native-app', tooltip: 'sidebar.native-app', class: '', routerLink: 'digital-experience/native-app', items: [], visible: true }
    /**
     * Digital experience ditial marketplace navlist item
     */
    private static readonly DIGITAL_EXPERIENCE_DIGITAL_MARKETPLACE_NAVLIST_ITEM = { key: 'digital-marketplace', icon: 'at', text: 'sidebar.digital-marketplace', tooltip: 'sidebar.digital-marketplace', class: '', routerLink: 'digital-experience/digital-marketplace', items: [], visible: true }
    /**
     * Digital experience pattern lab navlist item
     */
    private static readonly DIGITAL_EXPERIENCE_PATTERN_LAB_NAVLIST_ITEM = { key: 'pattern-lab', icon: 'ui-radios-grid', text: 'sidebar.pattern-lab', tooltip: 'sidebar.pattern-lab', class: '', routerLink: 'digital-experience/pattern-lab', items: [], visible: true }
    /**
     * Digital experience digital mails newsletters navlist item
     */
    private static readonly DIGITAL_EXPERIENCE_DIGITAL_MAILS_NEWSLETTERS_NAVLIST_ITEM = { key: 'digital-mails-newsletters', icon: 'mailbox', text: 'sidebar.digital-mails-newsletters', tooltip: 'sidebar.digital-mails-newsletters', class: '', routerLink: 'digital-experience/digital-mails-newsletters', items: [], visible: true }
    /**
     * Digital experience email signature navlist item
     */
    private static readonly DIGITAL_EXPERIENCE_EMAIL_SIGNATURE_NAVLIST_ITEM = { key: 'email_signature', icon: 'pen', text: 'sidebar.email-signature', tooltip: 'sidebar.email-signature', class: '', routerLink: 'digital-experience/email-signature', items: [], visible: true }
    /**
     * Digital experience additional elements navlist item
     */
    private static readonly DIGITAL_EXPERIENCE_ADDITIONAL_ELEMENTS_NAVLIST_ITEM = { key: 'de-additional-elements', icon: 'inbox', text: 'sidebar.additional-elements', tooltip: 'sidebar.additional-elements', class: '', routerLink: 'digital-experience/additional-elements', items: [], visible: true }
    /**
     * Brand digital experience navlist item
     */
    private static readonly BRAND_DIGITAL_EXPERIENCE_NAVLIST_ITEM = { key: 'digital-experience', icon: 'display', text: 'sidebar.digital-experience', tooltip: 'sidebar.digital-experience', class: '', routerLink: '', items: { key: 'digital-experience-submenu', items: [AppSettings.DIGITAL_EXPERIENCE_WEB_COLOURS_NAVLIST_ITEM, AppSettings.DIGITAL_EXPERIENCE_TYPOGRAPHY_NAVLIST_ITEM, AppSettings.DIGITAL_EXPERIENCE_RESPONSIVE_WEB_NAVLIST_ITEM, AppSettings.DIGITAL_EXPERIENCE_NATIVE_APP_NAVLIST_ITEM, AppSettings.DIGITAL_EXPERIENCE_DIGITAL_MARKETPLACE_NAVLIST_ITEM, AppSettings.DIGITAL_EXPERIENCE_PATTERN_LAB_NAVLIST_ITEM, AppSettings.DIGITAL_EXPERIENCE_DIGITAL_MAILS_NEWSLETTERS_NAVLIST_ITEM, AppSettings.DIGITAL_EXPERIENCE_EMAIL_SIGNATURE_NAVLIST_ITEM, AppSettings.DIGITAL_EXPERIENCE_ADDITIONAL_ELEMENTS_NAVLIST_ITEM] }, visible: true, option: "submenu" }

    /**
     * Brand communications magazines
     */
    private static readonly BRAND_COMMUNICATIONS_MAGAZINES_REPORTS_NAVLIST_ITEM = { key: 'magazines-reports', icon: 'graph-up', text: 'sidebar.magazines-reports', tooltip: 'sidebar.magazines-reports', class: '', routerLink: 'brand-communications/magazines-reports', items: [], visible: true }
    /**
     * Brand communications brochureware navlist item
     */
    private static readonly BRAND_COMMUNICATIONS_BROCHUREWARE_NAVLIST_ITEM = { key: 'brochureware', icon: 'book', text: 'sidebar.brochureware', tooltip: 'sidebar.brochureware', class: '', routerLink: 'brand-communications/brochureware', items: [], visible: true }
    /**
     * Brand communications newsletters factsheets navlist item
     */
    private static readonly BRAND_COMMUNICATIONS_NEWSLETTERS_FACTSHEETS_NAVLIST_ITEM = { key: 'newsletters-factsheets', icon: 'newspaper', text: 'sidebar.newsletters-factsheets', tooltip: 'sidebar.newsletters-factsheets', class: '', routerLink: 'brand-communications/newsletters-factsheets', items: [], visible: true }
    /**
     * Brand communications print out home navlist item
     */
    private static readonly BRAND_COMMUNICATIONS_PRINT_OUT_HOME_NAVLIST_ITEM = { key: 'print-out-home', icon: 'printer', text: 'sidebar.print-out-home', tooltip: 'sidebar.print-out-home', class: '', routerLink: 'brand-communications/print-out-home', items: [], visible: true }
    /**
     * Brand communications posters navlist item
     */
    private static readonly BRAND_COMMUNICATIONS_POSTERS_NAVLIST_ITEM = { key: 'posters', icon: 'map', text: 'sidebar.posters', tooltip: 'sidebar.posters', class: '', routerLink: 'brand-communications/posters', items: [], visible: true }
    /**
     * Brand communications stationery navlist item
     */
    private static readonly BRAND_COMMUNICATIONS_STATIONERY_NAVLIST_ITEM = { key: 'stationery', icon: 'house', text: 'sidebar.stationery', tooltip: 'sidebar.stationery', class: '', routerLink: 'brand-communications/stationery', items: [], visible: true }
    /**
     * Brand communications card navlist item
     */
    private static readonly BRAND_COMMUNICATIONS_CARDS_NAVLIST_ITEM = { key: 'cards', icon: 'card-text', text: 'sidebar.cards', tooltip: 'sidebar.cards', class: '', routerLink: 'brand-communications/cards', items: [], visible: true }
    /**
     * Brand communications digital banners navlist item
     */
    private static readonly BRAND_COMMUNICATIONS_DIGITAL_BANNERS_NAVLIST_ITEM = { key: 'digital-banners', icon: 'badge-ad', text: 'sidebar.digital-banners', tooltip: 'sidebar.digital-banners', class: '', routerLink: 'brand-communications/digital-banners', items: [], visible: true }
    /**
     * Brand communications social media navlist item
     */
    private static readonly BRAND_COMMUNICATIONS_SOCIAL_MEDIA_NAVLIST_ITEM = { key: 'social-media', icon: 'hand-thumbs-up', text: 'sidebar.social-media', tooltip: 'sidebar.social-media', class: '', routerLink: 'brand-communications/social-media', items: [], visible: true }
    /**
     * Brand communications motion design navlist item
     */
    private static readonly BRAND_COMMUNICATIONS_MOTION_DESIGN_NAVLIST_ITEM = { key: 'motion-design', icon: 'camera-reels', text: 'sidebar.motion-design', tooltip: 'sidebar.motion-design', class: '', routerLink: 'brand-communications/motion-design', items: [], visible: true }
    /**
     * Band communications internal communications navlist item
     */
    private static readonly BRAND_COMMUNICATIONS_INTERNAL_COMMUNICATIONS_NAVLIST_ITEM = { key: 'internal-communications', icon: 'chat-left-text', text: 'sidebar.internal-communications', tooltip: 'sidebar.internal-communications', class: '', routerLink: 'brand-communications/internal-communications', items: [], visible: true }
    /**
     * Brand communications navlist item
     */
    private static readonly BRAND_BRAND_COMMUNICATIONS_NAVLIST_ITEM = { key: 'brand-communications', icon: 'chat-square-text', text: 'sidebar.brand-communications', tooltip: 'sidebar.brand-communications', class: '', routerLink: '', items: { key: 'brand-communications-submenu', items: [AppSettings.BRAND_COMMUNICATIONS_MAGAZINES_REPORTS_NAVLIST_ITEM, AppSettings.BRAND_COMMUNICATIONS_BROCHUREWARE_NAVLIST_ITEM, AppSettings.BRAND_COMMUNICATIONS_NEWSLETTERS_FACTSHEETS_NAVLIST_ITEM, AppSettings.BRAND_COMMUNICATIONS_PRINT_OUT_HOME_NAVLIST_ITEM, AppSettings.BRAND_COMMUNICATIONS_POSTERS_NAVLIST_ITEM, AppSettings.BRAND_COMMUNICATIONS_STATIONERY_NAVLIST_ITEM, AppSettings.BRAND_COMMUNICATIONS_CARDS_NAVLIST_ITEM, AppSettings.BRAND_COMMUNICATIONS_DIGITAL_BANNERS_NAVLIST_ITEM, AppSettings.BRAND_COMMUNICATIONS_SOCIAL_MEDIA_NAVLIST_ITEM, AppSettings.BRAND_COMMUNICATIONS_MOTION_DESIGN_NAVLIST_ITEM, AppSettings.BRAND_COMMUNICATIONS_INTERNAL_COMMUNICATIONS_NAVLIST_ITEM] }, visible: true, option: "submenu" }

    /**
     * Sidebar navlist config
     */
    static readonly SIDEBAR_NAVLIST_CONFIG: any[] = [
        { key: 'collaboration', menuId: 0 , items: [AppSettings.DASHBOARD_NAVLIST_ITEM, AppSettings.PROJECTS_NAVLIST_ITEM, AppSettings.JOBS_NAVLIST_ITEM, AppSettings.TASKS_NAVLIST_ITEM, AppSettings.BRIEFINGS_NAVLIST_ITEM, AppSettings.REQUEST_NAVLIST_ITEM, AppSettings.REPORTINGS_NAVLIST_ITEM, AppSettings.RESOURCE_MANAGER_NAVLIST_ITEM], visible: true },
        { key: 'library', menuId:1 , items: [AppSettings.ASSETS_NAVLIST_ITEM, AppSettings.COLLECTIONS_NAVLIST_ITEM, AppSettings.UPLOAD_NAVLIST_ITEM]},
        { key: 'brand', menuId:2 , items: [AppSettings.BRAND_NAVLIST_ITEM, AppSettings.BRAND_BASIC_ELEMENTS_NAVLIST_ITEM, AppSettings.BRAND_DIGITAL_EXPERIENCE_NAVLIST_ITEM, AppSettings.BRAND_BRAND_COMMUNICATIONS_NAVLIST_ITEM] },
        { key: 'order', menuId:3 },
        { key: 'deliver', menuId:4 }
    ]

    //FOOTER
    /**
     * Footer config
     */
    static readonly FOOTER_CONFIG = [
        { key: 'logo', visible: false },
        { key: 'copyright', visible: false }
    ]

    /**
     * Deletion mark deletion
     */
    static readonly DELETION_MARK_DELETION: string = '1';
    /**
     * Deletion none
     */
    static readonly DELETION_NONE: string = '0';

    /**
     * Show errors
     */
    static readonly SHOW_ERRORS: boolean = false;

    /**
     * Time to cancel
     */
    static readonly TIME_TO_CANCEL: number = 180;

    /**
     * Default second domain root
     */
    static readonly DEFAULT_SECOND_DOMAIN_ROOT = 'root.';
    static readonly DEFAULT_SECOND_DOMAIN_USER = ["root."];
    static readonly LOGIN_BKG_TAG_ID = 15378;

    /**
     * Number pages
     */
    static readonly NUMBER_PAGES: number = 9;

    /**
     * Days check expire soon
     */
    static readonly DAYS_CHECK_EXPIRE_SOON: number = 90;

    // PartyLogin
    /**
     * Key party login
     */
    private static key = CryptoJS.enc.Utf8.parse('bPeShVmYq3t6w9z$');
    /**
     * Party login
     */
    static readonly partyLogin = {
        options: {
            keySize: 16,
            iv: AppSettings.key,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        },
        key: AppSettings.key
    };

    //SCHEMAS - MAIN DOMAIN
    /**
     * Check main domain
     */
    static readonly CHECK_MAIN_DOMAIN: Array<string> =
        [
            'entity/webuser'
        ]

    // CKEDITOR
    /**
     * Editor config
     */
    static readonly editorConfig = {
        codeBlock: {
            languages: [
                { language: 'code', label: 'Code', class: 'code' },
                { language: 'plain', label: 'Plain Text', class: 'plain' },
                { language: 'html', label: 'Html', class: 'html html-code' },
                { language: 'java', label: 'Java', class: 'java java-code' },
                { language: 'javascript', label: 'JavaScript', class: 'js javascript js-code' },
                { language: 'css', label: 'CSS', class: 'css' }
            ]
        },
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
            ]
        },
        toolbar: ['bold', 'italic', 'underline', '|', 'alignment:left', 'alignment:center', 'alignment:right', '|', 'heading', 'link', 'codeBlock', 'numberedList', 'bulletedList', '|', 'insertTable', '|', 'removeFormat']
    };

    /**
     * Editor config no toolbar
     */
    static readonly editorConfigNoToolbar = {
        removePlugins: ['toolbar']
    };

    /**
     * Editor config minimal toolbar
     */
    static readonly editorConfigMnml = {
        toolbar: ['bold', 'italic', 'underline','|','alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify']
    };

    /**
     * Expertises
     */
    static readonly EXPERTISES = [
        { key: 'animals-pets', name: 'Animals & Pets', checked: false },
        { key: 'art-culture', name: 'Art & Culture', checked: false },
        { key: 'assoc-org-found', name: 'Associations, Organisation & Foundations', checked: false },
        { key: 'automotive', name: 'Automotive', checked: false },
        { key: 'cleaning-hygiene', name: 'Cleaning & Hygiene', checked: false },
        { key: 'computer-it', name: 'Computer & IT', checked: false },
        { key: 'consulting', name: 'Consulting', checked: false },
        { key: 'cosmetics', name: 'Cosmetics', checked: false },
        { key: 'education', name: 'Education', checked: false },
        { key: 'electronics', name: 'Electronics', checked: false },
        { key: 'energy', name: 'Energy', checked: false },
        { key: 'fashion-lifestyle', name: 'Fashion & Lifestyle', checked: false },
        { key: 'finance-insurance', name: 'Finance & Insurance', checked: false },
        { key: 'food-beverages', name: 'Food & Beverages', checked: false },
        { key: 'gov-admin-social', name: 'Government, Administration & Social Services', checked: false },
        { key: 'healthcare-pharma', name: 'Healthcare & Pharma', checked: false },
        { key: 'home-garden', name: 'Home & Garden', checked: false },
        { key: 'industrial-construction', name: 'Industrial & Construction Goods', checked: false },
        { key: 'leisure-sport-entertainmet', name: 'Leisure, Sports & Entertainment', checked: false },
        { key: 'media-publishing-advertisment', name: 'Media, Publishing & Advertisment', checked: false },
        { key: 'real-estate', name: 'Real Estate', checked: false },
        { key: 'telecommunication', name: 'Telecommunication', checked: false },
        { key: 'tobacco', name: 'Tobacco', checked: false },
        { key: 'tourism-gastro', name: 'Tourism & Gastro', checked: false },
        { key: 'traffic-logistics', name: 'Traffic & Logistics', checked: false },
    ];

    /**
     * Services
     */ 
    static readonly SERVICES = [
        { value_key: 'root.advertising.', name: "Advertising", checkable: false, size: 100, noSubtitles: true,
            childs: [
                { value_key: 'root.advertising.mob-ban-dev.', name: "Online/Mobile Banner Development", parent_key: "root.advertising.", checkable: true, checked: false },
                { value_key: 'root.advertising.soc-med-asset-prod.', name: "Social Media Asset Production", parent_key: "root.advertising.", checkable: true, checked: false },
            ]
        },
        { value_key: 'root.development.', name: "Development", checkable: false, size: 100,
            childs: [
                    { value_key: 'root.development.e-commerce-solutions.', name: "E-Commerce Solutions", parent_key: "root.development.", checkable: false, childs: [
                        { value_key: 'root.development.e-commerce-solutions.sap-commerce.', name: "SAP Commerce", parent_key: "root.development.e-commerce-solutions.", checkable: true, checked: false },
                        { value_key: 'root.development.e-commerce-solutions.magento.', name: "Magento", parent_key: "root.development.e-commerce-solutions.", checkable: true, checked: false },
                        { value_key: 'root.development.e-commerce-solutions.woo-commerce.', name: "WooCommerce", parent_key: "root.development.e-commerce-solutions.", checkable: true, checked: false },
                        { value_key: 'root.development.e-commerce-solutions.nop-commerce.', name: "NopCommerce", parent_key: "root.development.e-commerce-solutions.", checkable: true, checked: false },
                        { value_key: 'root.development.e-commerce-solutions.shopify.', name: "Shopify", parent_key: "root.development.e-commerce-solutions.", checkable: true, checked: false },
                        { value_key: 'root.development.e-commerce-solutions.shopware.', name: "Shopware", parent_key: "root.development.e-commerce-solutions.", checkable: true, checked: false },
                        ]
                    },
                    { value_key: 'root.development.web-portal.', name: "Web Portal with Content Management System", parent_key: "root.development.", checkable: false , childs: [
                        { value_key: 'root.development.web-portal.aem.', name: "AEM", parent_key: "web-portal", checkable: true },
                        { value_key: 'root.development.web-portal.sitecore.', name: "Sitecore", parent_key: "root.development.web-portal.", checkable: true, checked: false },
                        { value_key: 'root.development.web-portal.typo3.', name: "Typo3", parent_key: "root.development.web-portal.", checkable: true, checked: false },
                        { value_key: 'root.development.web-portal.umbraco.', name: "Umbraco", parent_key: "root.development.web-portal.", checkable: true, checked: false },
                        { value_key: 'root.development.web-portal.wordpress.', name: "WordPress", parent_key: "root.development.web-portal.", checkable: true, checked: false },
                        { value_key: 'root.development.web-portal.drupal.', name: "Drupal", parent_key: "root.development.web-portal.", checkable: true, checked: false },
                        { value_key: 'root.development.web-portal.pimcore.', name: "Pimcore", parent_key: "root.development.web-portal.", checkable: true, checked: false },
                        { value_key: 'root.development.web-portal.contao.', name: "Contao", parent_key: "root.development.web-portal.", checkable: true, checked: false },
                        { value_key: 'root.development.web-portal.magnolia.', name: "Magnolia", parent_key: "root.development.web-portal.", checkable: true, checked: false },
                    ]
                },
                {
                    value_key: 'root.development.frontend.', name: "Frontend", parent_key: "root.development.", checkable: false, childs: [
                        { value_key: 'root.development.frontend.java.', name: "Java (Frontend)", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.php.', name: "PHP", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.msoft-net.', name: "Microsoft .Net", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.angularjs.', name: "AngularJs", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.reactjs.', name: "ReactJs", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.vuejs.', name: "VueJs", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.html5.', name: "HTML5", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.css.', name: "CSS", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.jquery.', name: "jQuery", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.sass.', name: "SASS", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.scss.', name: "SCSS", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.bootstrap.', name: "Bootstrap", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        { value_key: 'root.development.frontend.tealium.', name: "Tealium", parent_key: "root.development.frontend.", checkable: true, checked: false },
                        ]
                    },
                    { value_key: 'root.development.backend.', name: "Backend", parent_key: "root.development.", checkable: false , childs: [
                        { value_key: 'root.development.backend.php.', name: "PHP", parent_key: "root.development.backend.", checkable: true, checked: false },
                        { value_key: 'root.development.backend.java.', name: "Java (Backend)", parent_key: "root.development.backend.", checkable: true, checked: false },
                        { value_key: 'root.development.backend.nodejs.', name: "NodeJS", parent_key: "root.development.backend.", checkable: true, checked: false },
                        { value_key: 'root.development.backend.symfony.', name: "Symfony", parent_key: "root.development.backend.", checkable: true, checked: false },
                        { value_key: 'root.development.backend.spring.', name: "Spring", parent_key: "root.development.backend.", checkable: true, checked: false },
                        { value_key: 'root.development.backend.jsp.', name: "JSP", parent_key: "root.development.backend.", checkable: true, checked: false },
                        { value_key: 'root.development.backend.sql.', name: "SQL", parent_key: "root.development.backend.", checkable: true, checked: false },
                        { value_key: 'root.development.backend.mysql.', name: "MySQL", parent_key: "root.development.backend.", checkable: true, checked: false },
                        { value_key: 'root.development.backend.oracle.', name: "Oracle", parent_key: "root.development.backend.", checkable: true, checked: false },
                        ]
                    },
                    { value_key: 'root.development.mobile.', name: "Mobile", parent_key: "root.development.", checkable: false , childs: [
                        { value_key: 'root.development.mobile.android.', name: "Android", parent_key: "root.development.mobile.", checkable: true, checked: false },
                        { value_key: 'root.development.mobile.java-swift,.', name: "Java & Swift", parent_key: "root.development.mobile.", checkable: true, checked: false },
                        { value_key: 'root.development.mobile.react-native.', name: "React Native", parent_key: "root.development.mobile.", checkable: true, checked: false },
                        { value_key: 'root.development.mobile.ionic.', name: "Ionic", parent_key: "root.development.mobile.", checkable: true, checked: false },
                        { value_key: 'root.development.mobile.xamarin.', name: "Xamarin", parent_key: "root.development.mobile.", checkable: true, checked: false },
                        { value_key: 'root.development.mobile.ios.', name: "iOS", parent_key: "root.development.mobile.", checkable: true, checked: false },
                        { value_key: 'root.development.mobile.flutter.', name: "Flutter", parent_key: "root.development.mobile.", checkable: true, checked: false },
                        { value_key: 'root.development.mobile.objective-c.', name: "Objective-C", parent_key: "root.development.mobile.", checkable: true, checked: false },
                        ]
                    },
                    { value_key: 'root.development.maintenance.', name: "Maintenance", parent_key: "root.development.", checkable: false, checked: false, childs: [
                        { value_key: 'root.development.maintenance.', name: "Maintenance", parent_key: "root.development.", checkable: true, checked: false },
                        ]
                    },
                    { value_key: 'root.development.crm-solutions.', name: "CRM Solutions", parent_key: "root.development.", checkable: false , childs: [
                        { value_key: 'root.development.crm-solutions.saleforce.', name: "Saleforce", parent_key: "root.development.crm-solutions.", checkable: true, checked: false },
                        { value_key: 'root.development.crm-solutions.individual-crm.', name: "Individual CRM (Apache OFBiz)", parent_key: "root.development.crm-solutions.", checkable: true, checked: false },
                        ]
                    },
                    { value_key: 'root.development.cdp.', name: "CDP (Customer Data Platform)", parent_key: "root.development.", checkable: false , childs: [
                        { value_key: 'root.development.cdp.', name: "CDP (Customer Data Platform)", parent_key: "root.development.", checkable: true, checked: false },
                        ]
                    },
                    { value_key: 'root.development.marketing.', name: "Marketing", parent_key: "root.development.", checkable: false , childs: [
                        { value_key: 'root.development.marketing.salesforce-marketing.', name: "Salesforce Marketing", parent_key: "root.development.marketing.", checkable: true, checked: false },
                        { value_key: 'root.development.marketing.salesforce-pardot.', name: "Salesforce Pardot", parent_key: "root.development.marketing.", checkable: true, checked: false },
                        { value_key: 'root.development.marketing.salesforce-emarsys.', name: "Emarsys", parent_key: "root.development.marketing.", checkable: true, checked: false },
                        { value_key: 'root.development.marketing.salesforce-evalanche.', name: "Evalanche", parent_key: "root.development.marketing.", checkable: true, checked: false },
                        { value_key: 'root.development.marketing.sap-marketing.', name: "SAP Marketing", parent_key: "root.development.marketing.", checkable: true, checked: false },
                        ]
                    },
            ],
        },
        { value_key: 'root.performance.', name: "Performance", checkable: false, noSubtitles: true,
            childs: [
                { value_key: 'root.performance.seo.', name: "SEO", parent_key: "root.performance.", checkable: true, checked: false },
                { value_key: 'root.performance.sea.', name: "SEA (Paid Search, Display, YouTube Advertisement)", parent_key: "root.performance.", checkable: true, checked: false },
                { value_key: 'root.performance.data-feed-management.', name: "Data Feed Management", parent_key: "root.performance.", checkable: true, checked: false },
                { value_key: 'root.performance.cro.', name: "Conversion Rate Optimization (CRO)", parent_key: "root.performance.", checkable: true, checked: false },
                { value_key: 'root.performance.amazon-marketplaces.', name: "Amazon & Marketplaces", parent_key: "root.performance.", checkable: true, checked: false },
                { value_key: 'root.performance.analytics-services-solutions.', name: "Analytics Services & Solutions", parent_key: "root.performance.", checkable: true, checked: false },
            ]
        },
        { value_key: 'root.print.', name: "Print", checkable: false, noSubtitles: true,
            childs: [
                { value_key: 'root.print.artwork.', name: "Artwork", parent_key: "root.print.", checkable: true, checked: false },
                { value_key: 'root.print.layout.', name: "Layout", parent_key: "root.print.", checkable: true, checked: false },
                { value_key: 'root.print.retouching.', name: "Retouching", parent_key: "root.print.", checkable: true, checked: false },
                { value_key: 'root.print.toolkit-design.', name: "Toolkit design", parent_key: "root.print.", checkable: true, checked: false },
                { value_key: 'root.print.pos.', name: "POS asset development", parent_key: "root.print.", checkable: true, checked: false },
                { value_key: 'root.print.packaing-asset-dev.', name: "Packaging asset development", parent_key: "root.print.", checkable: true, checked: false },
                { value_key: 'root.print.illustration.', name: "Illustration", parent_key: "root.print.", checkable: true, checked: false },
                { value_key: 'root.print.creation-management-layout.', name: "Creation and management of layouts", parent_key: "root.print.", checkable: true, checked: false },
                { value_key: 'root.print.proofing.', name: "Proofing", parent_key: "root.print.", checkable: true, checked: false },
                { value_key: 'root.print.final-artwork.', name: "Final Artwork", parent_key: "root.print.", checkable: true, checked: false },
            ]
        },
        { value_key: 'root.content.', name: "Content", checkable: false, noSubtitles: true,
            childs: [
                { value_key: 'root.content.content-strategy.', name: "Content strategy", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.asset-planning.', name: "Asset planning", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.storyboarding.', name: "Storyboarding", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.shoot-management.', name: "Shoot management", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.casting.', name: "Casting", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.video-production.', name: "Video production", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.video-editing.', name: "Video editing", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.2d-animation.', name: "2D Animation", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.3d-animation.', name: "3D Animation", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.cgi.', name: "CGI", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.audio.', name: "Audio", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.adaptation.', name: "Adaptation", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.cultural-consultation.', name: "Cultural consultation", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.transcreation.', name: "Transcreation", parent_key: "root.content.", checkable: true, checked: false },
                { value_key: 'root.content.ar-vr.', name: "AR / VR", parent_key: "root.content.", checkable: true, checked: false }
            ]
        },

    ]

    /**
     * Base color
     */
    static readonly BASECOLOR = 'e30614';

    static ALLOWED_TYPES = [
        {
            key: 'picture.', children: [
                { key: 'picture.diorama.', padding: 25 },
                { key: 'picture.mood.', padding: 25 },

                { key: 'picture.accessoiries.', padding: 25 },
                { key: 'picture.socialmedia.', padding: 25 },
                { key: 'picture.illustration.', padding: 25 },
                { key: 'picture.other.', padding: 25 },
                { key: 'picture.amazon.', padding: 25 },

                { key: 'picture.productshot.', padding: 25 },
                { key: 'picture.packshot.', padding: 25 },
                { key: 'picture.detail.', padding: 25 },
                { key: 'picture.360.', padding: 25 },

                { key: 'picture.logo.', padding: 25 },
            ]
        },
        {
            key: 'layout.', children: [
                { key: 'layout.catalouge.', padding: 25 },
                { key: 'layout.manual.', padding: 25 },
                { key: 'layout.packaging.', padding: 25 },
                { key: 'layout.pos.', padding: 25 }
            ]
        }
    ]
}
