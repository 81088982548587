import { Component, OnInit, Input } from '@angular/core';
/**
 * @ignore
 */
@Component({
  selector: 'app-info-bar-chart',
  templateUrl: './info-bar-chart.component.html',
  styleUrls: ['./info-bar-chart.component.scss']
})
export class InfoBarChartComponent implements OnInit {

  @Input() data: any;
  @Input() label: string;

  barChartLabels: string[] = [];
  barChartData: number[] = [];

  barChartType = 'bar';
  barChartLegend = false;
  barChartOptions = {responsive: true};

  public colors: Array<any> = [
    {
      backgroundColor: ['#e30614', '#000000', '#4d4d4d'],
      borderWidth: 0
    }];

  constructor() { }

  ngOnInit() {
    if (this.data) {
      this.barChartLabels = Object.keys(this.data);
      this.barChartData = Object.values(this.data);

      this.barChartOptions['scales'] = {
        yAxes: [{
          ticks: {
            min: 0,
            max: Math.max(...this.barChartData),
          }
        }]
      };

    }
  }

}
