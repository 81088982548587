import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';
import { MailService } from 'app/shared/services/mail/mail.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { UserService } from 'app/shared/services/user/user.service';
import Utils from 'app/shared/utils/utils';

/**
 * @ignore
 */
@Component({
  selector: 'app-team-widget',
  templateUrl: './team-widget.component.html',
  styleUrls: ['./team-widget.component.scss']
})
export class TeamWidgetComponent implements OnInit {

  /**
   * The asset ({@link Action}, {@link Project}, {@link RequestObject}) id
   */
  @Input() assetId;
  @Output() updatedTeam: EventEmitter<any> = new EventEmitter();
  @Output() loadedTeam: EventEmitter<any> = new EventEmitter();

  loadingData = false;
  currentUser;

  isManager = false;
  isPowerUser = false;
  isProduction = false;
  isOperator = false;

  team;
  owner;
  mainManager;
  pm;
  type;

  restTeam;
  addMember;
  isShowStars: boolean;

  teamSize = 0;
  adding = false;
  selectableUsers = [];
  selectableUsersFiltered = [];

  constructor(private usersService: UserService,
    private rolesService: RolesService,
    private dialog: MatDialog,
    private mailService: MailService) { }

  /**
   * Initialize team, roles and rest of necessary data.
   */
  ngOnInit() {
    this.loadingData = true;

    this.loadInitData();
  }

  loadInitData() {

    this.currentUser = this.usersService.getCurrentUser();
    let users = this.usersService.getUsers();

    if (this.currentUser && users && users.length > 0) {
      this.resetMember();
      this.currentUser = this.usersService.getCurrentUser();
      this.isManager = this.rolesService.checkRole(this.currentUser, RolesService.PROJECT_MANAGER_ROLE, true);
      this.isPowerUser = this.rolesService.checkRole(this.currentUser, RolesService.POWER_ROLE);
      this.isOperator = this.rolesService.checkRole(this.currentUser, RolesService.OPERATOR_ROLE);
      this.isProduction = this.rolesService.checkRole(this.currentUser, RolesService.PRODUCTION_ROLE);
      this.selectableUsers = this.usersService.getSelectablesUsers();
      this.usersService.getTeam(this.assetId).then(result => {
        this.type = result.type;
        this.refreshTeam(result);

        this.loadingData = false;
      });

      
    } else {

      this.usersService.loadUsers();
      setTimeout(() => {
        this.loadInitData();
      }, 1000);
    }
    
  }

  /**
   * Opens the confirmation dialog to remove a member from the team. If confirmed, removes him and refresh the team.
   * 
   * @param item Member to remove
   * @param role Role of the member to remove
   */
  openConfirmationRemoveDialog(item, role) {

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'removeMember'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        item.remove = true;
        this.usersService.removeUserFromTeam(this.team, role, item.id).then(result => {
          this.refreshTeam(result);
          item.remove = false;
          setTimeout(() => {
            this.updatedTeam.emit();
          }, 500);
        });
      }
      dialogRef = null;
    });
  }

  /**
   * Checks if the logged-in user has access to edit the team
   * 
   * @returns True if can edit, false in other case
   */
  canEdit() {
    return this.usersService.canEditTeam(this.currentUser, this.team);
  }

  /**
   * Adds the member selected to the team. Sends the corresponding mail notification to the manager
   */
  addNewMember() {

    if (this.addMember.person && this.addMember.person.id != -1) {
      this.adding = true;
      if (this.addMember.person.roles && this.addMember.person.roles.some(x => x.indexOf('power') > -1)) {
        this.addMember.role = UserService.OWNER;
      } else {
        this.addMember.role = UserService.PROJECT_MANAGER;
      }

      this.addMember.person = this.addMember.person.id;
      // if (this.isManager) {
      //   if (this.pm) {
      //     this.addMember.role = UserService.CO_MANAGER;
      //   } else {
      //     this.addMember.role = UserService.PROJECT_MANAGER;
      //   }
      // } else if (this.isPowerUser) {
      //   if (this.owner) {
      //     this.addMember.role = UserService.CO_OWNER;
      //   } else {
      //     this.addMember.role = UserService.OWNER;
      //   }
      // } else {
      //   this.addMember.role = UserService.PRODUCTION;
      // }

      if (this.addMember.person) {
        this.usersService.saveNewUserInTeam(this.team, this.addMember).then(result => {

          // if (this.addMember.role == UserService.PROJECT_MANAGER) {
          //   this.mailService.sendProjectManagerMail(this.assetId, this.addMember.person);
          // }

          this.mailService.sendAddToTeamMail(this.assetId, this.addMember.person, this.type);

          this.refreshTeam(result);
          this.adding = false;
          this.resetMember();
          this.updatedTeam.emit();
        });
      } else {
        this.adding = false;
      }
    }
  }

  /**
   * Reset the addMember property
   */
  resetMember() {
    this.addMember = {
      editing: false,
      step: 2,
      //step: 1,
      type: 1,
      person: -1,
      role: '',
      next: false,
      update: true
    }
  }

  /**
   * Refresh the team from {@link UserService} (when some change happens)
   * 
   * @param team 
   */
  refreshTeam(team) {
    this.owner = null;
    this.pm = null;
    this.team = team;
    let teams = this.usersService.getTeams(team);
    let flatTeam = [];
    this.selectableUsersFiltered = Utils.cloneArray(this.selectableUsers);

    teams.forEach((value, key) => {
      // if (key != UserService.OWNER && key != UserService.PROJECT_MANAGER && value.length > 0) {
      value.forEach(val => {
        flatTeam.push({
          key: key,
          value: val,
          isShowStar: this.rolesService.isOperator(val)
        });
        this.selectableUsersFiltered = this.selectableUsersFiltered.filter(x => x.id != val.id);
      });

      if (this.type === 'request.' && !this.isOperator) {
        this.selectableUsersFiltered = this.selectableUsersFiltered.filter(x => this.isAgencyPartnerOfCurrenUser(x));
      }

      if (this.type === 'order.' && this.isPowerUser) {
        this.selectableUsersFiltered = this.selectableUsersFiltered.filter(x => x.roles && x.roles.includes('root.cntr.power.'));
      }

      // }

      // if (key == UserService.OWNER && value.length > 0) {
      //   this.owner = value[0];
      //   this.owner.role = UserService.OWNER;
      //   this.selectableUsersFiltered = this.selectableUsersFiltered.filter(x => x.id != this.owner.id);
      // }
      // if (key == UserService.PROJECT_MANAGER && value.length > 0) {
      //   this.pm = value[0];
      //   this.pm.role = UserService.PROJECT_MANAGER;
      //   this.selectableUsersFiltered = this.selectableUsersFiltered.filter(x => x.id != this.pm.id);
      // }

    });

    this.restTeam = flatTeam;

    this.isShowStars = this.restTeam.filter(user => user.key === 'projectmanager_rel').length > 1;

    if (this.team.mainManager || (this.team.projectmanager_rel && this.team.projectmanager_rel[0])) {
      this.mainManager = this.team.mainManager ? this.team.mainManager : this.team.projectmanager_rel[0];
    }


    this.teamSize = 0;
    teams.forEach((value, key) => {
      if (value.length > 0) {
        this.teamSize += value.length;
      }
    });

    this.loadedTeam.emit(this.restTeam.map(user => user.value.id));
    this.addMember.update = true;
  }

  /**
   * Check if a specific user belongs to the same agency of current user.
   * 
   * @param user the user to check
   * @returns true if both users are agency partners, false in other case.
   */
  isAgencyPartnerOfCurrenUser(user) {
    let result = false;

    if (user.agencyEmployee && user.agencyEmployee.length > 0 && this.currentUser.agencyEmployee && this.currentUser.agencyEmployee.length > 0) {

      this.currentUser.agencyEmployee.forEach(currentUserAgency => {
        user.agencyEmployee.forEach(agency => {
          if (currentUserAgency.id === agency.id) result = true;
        });
      });
    }

    return result;
  }

  /**
   * Sets or updates the main manager of the team.
   * 
   * @param user The user selected to be main manager
   */
  changeMainManager(user) {
    this.usersService.setMainManagerInTeam(this.team, user.value).then(result => {
      Utils.reloadData = Utils.RELOAD_DATA_TASK_WIDGET;
      this.refreshTeam(result);
    });
  }

}