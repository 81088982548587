import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { BriefingService } from 'app/shared/services/briefing/briefing.service';

/**
 * This component contains the form for the creation of a new {@link Briefing} 
 */
@Component({
  selector: 'app-new-briefing',
  templateUrl: './new-briefing.component.html',
  styleUrls: ['./new-briefing.component.scss']
})
export class NewBriefingComponent implements OnInit {
  /**{@link MatTabGroup} used to control the different views. */
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  /**
   * The constructor
   * @param dialogRef 
   * @param briefingService {@link BriefingService} with the functions related to the briefings.
   * @param data The data of {@link Briefing}
   */
  constructor(
    public dialogRef: MatDialogRef<NewBriefingComponent>,
    private briefingService: BriefingService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

     }
  /**Initialises the component  */
  ngOnInit() {

    /** "Shortcut" is deprecated - always show detailed briefing tab */
    this.tabGroup.selectedIndex = 1;

    if (!isNaN(this.data)) {
      this.briefingService.getShortcut(this.data).then(data => {
        const isDetailed = data.result[0].briefings[0].channel_hubz;
        if (isDetailed) {
          this.tabGroup.selectedIndex = 1;
        } else {
          this.tabGroup.selectedIndex = 2;
        }
      });
    }
  }
  /**
   * Close the dialog (this component)
   */
  onNoClick(): void {
    this.dialogRef.close();
  }
  /**
   * Prevents the default behavior of drag and drop events 
   * @param event drop and drag
   */
  preventDefaultEvent(event) {
    event.preventDefault();
  }
  /**
   * This function is executed when an {@link DetailedBriefingComponent} event
   *  is executed which when executed changes the displayed component to 
   * {@link ShortcutBriefingComponent}
   */
  shortcut() {
    this.tabGroup.selectedIndex = 2;
  }

}
