import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';
import { Note } from 'app/shared/model/note.model';
import { NotesService } from 'app/shared/services/notes/notes.service';
import { UserService } from 'app/shared/services/user/user.service';

/**
 * Component to define the structure of the asset's notes
 */
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  /**
   * The asset to which the notes belong
   */
  @Input() asset;
  /**
   * Widget
   */
  @Input() widget = true;
  /**
  * Indicates if you can write notes. true if you are part of the team.
  */
  @Input() canWrite: boolean = true;

  /**
   * The notes listing
   */
  notes;
  /**
   * True if loading data
   */
  loadingData = true;
  /**
   * True if you are updating a widget comment.
   */
  updatingWidgetComment = false;
  /**
   * True if sending.
   */
  sending: boolean = false;
  /**
   * True if sending reply.
   */
  sendingReply: boolean = false;
  /**
   * Current user.
   */
  currentUser;
  /**
   * Reply panel state.
   */
  public replyPanelState = [];

  /**
   * Note form.
   */
  public form: FormGroup;
  /**
   * Edit comment form.
   */
  public editCommentForm: FormGroup;
  /**
   * Reply comment form.
   */
  public replyCommentForm: FormGroup;

  /**
   * Constructor
   * @param notesService Service with the functions related to the notes.
   * @param formBuilder Service with the functions related to the forms.
   * @param dialog Service with the functions related to the dialog.
   * @param usersService Service with the functions related to the users.
   */
  constructor(
    public notesService: NotesService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public usersService: UserService
  ) { }

  /**
   * Initializes the component and obtains the necessary data.
   */
  ngOnInit() {
    this.currentUser = this.usersService.getCurrentUser();

    this.notesService.getNotes(this.asset.id, this.asset.type).then(data => {
      if (data && data.notes){
        this.notes = data.notes.sort((a,b) => b.timestamp - a.timestamp);
      }
      this.loadingData = false;
    });

    this.form = this.formBuilder.group({
      'message': [null, Validators.required]
    });

    this.editCommentForm = this.formBuilder.group({
      'message': [null, Validators.required]
    });

    this.replyCommentForm = this.formBuilder.group({
      'message': [null, Validators.required]
    });
  }

  /**
   * Saves a note.
   */
  async saveNote() {
    this.sending = true;

    let note : Note = {
      'entry': this.form.value.message,
      'user': +this.usersService.getCurrentUser().id,
      'timestamp': Math.round(Date.now() / 1000) * 1000
    };

    this.notesService.saveNote(this.asset, note).then(data => {
      this.form.reset();
      if (data && data.notes) {
        this.notes = data.notes.sort((a, b) => b.timestamp - a.timestamp);
      }
      this.sending = false;
    });
  }

  /**
   * Update a existing note
   * @param note 
   */
  updateNote(note) {
    this.updatingWidgetComment = true;
    note.entry = this.editCommentForm.value.message;

    this.notesService.updateNote(this.asset, note).then(data => {
      this.editCommentForm.reset();
      if (data && data.notes){
        this.notes = data.notes.sort((a,b) => b.timestamp - a.timestamp);
      }
      this.updatingWidgetComment = false;
    });
  }

  /**
   * Sets the note in the form to be edited
   * @param comment The comment (note) to edit
   */
  setEditForm(comment) {
    this.notes.forEach(x => x.editing = false);
    this.editCommentForm.setValue({ message: comment.entry });
  }

  /**
   * Opens a confirm dialog to remove or delete a note. If confirmed, delete the note
   * @param note The note to delete
   * @returns 
   */
  deleteNote(note) {

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'deleteComment'
    });
    return dialogRef.afterClosed().toPromise().then(result => {

      dialogRef = null;

      if (result) {
        this.sending = true;

        this.notesService.deleteNote(this.asset, note).then(data => {
          if (data && data.notes){
            this.notes = data.notes.sort((a, b) => b.timestamp - a.timestamp);
          } else {
            this.notes = [];
          }

          this.sending = false;
        });
      }

      return false;
    });

  }

  /**
   * Saves the reply of a note.
   * @param comment The note to save, with the reply
   */
  async saveReply(comment) {
    this.sendingReply = true;

    let note : Note = {
      'entry': comment.entry,
      'user': +this.usersService.getCurrentUser().id,
      'timestamp': comment.timestamp,
      'reply': [{
        'entry': this.replyCommentForm.value.message,
        'user': +this.usersService.getCurrentUser().id,
        'timestamp': Math.round(Date.now() / 1000) * 1000
      }]
    };

    this.notesService.updateNote(this.asset, note).then(data => {

      this.replyCommentForm.reset();
      if (data && data.notes) {
        this.notes = data.notes.sort((a, b) => b.timestamp - a.timestamp);
      }
      this.sendingReply = false;
    });
  }

  /**
   * Add reply.
   */
  addReply() {
    this.notes.forEach(x => x.replying = false);
  }

  /**
   * Opens a confirm dialog to delete a reply from a note. If confirmed, delete the reply
   * @param note The note
   * @param reply The reply to delete
   * @returns 
   */
  deleteReply(note, reply) {

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'deleteReply'
    });
    return dialogRef.afterClosed().toPromise().then(result => {

      dialogRef = null;

      if (result) {
        this.sendingReply = true;

        this.notesService.deleteNote(this.asset, note, reply).then(data => {
          if (data && data.notes) {
            this.notes = data.notes.sort((a, b) => b.timestamp - a.timestamp);
          } else {
            this.notes = [];
          }

          this.sendingReply = false;
        });

      }
      return false;
    });
  }

  /**
   * Sets the reply in the form to be edited
   * @param reply 
   */
  setEditFormReply(reply) {
    this.notes.forEach(x => x.editing = false);
    this.replyCommentForm.setValue({ message: reply.entry });
  }

  /**
   * Update a existing reply from a note
   * @param note The note
   * @param reply The reply to update
   */
  updateReply(note, reply) {
    this.sendingReply = true;
    note.reply[note.reply.indexOf(reply)].entry = this.replyCommentForm.value.message;

    this.notesService.updateNote(this.asset, note).then(data => {

      this.replyCommentForm.reset();
      if (data && data.notes) {
        this.notes = data.notes.sort((a, b) => b.timestamp - a.timestamp);
      }
      this.sendingReply = false;
    });
  }

}
