// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Environment variables for development
 */
export const environment = {
  env: 'dev',
  production: false,

  baseAppUrl: 'https://sat.siku.dev.c3n.cloud',
  baseHCSMUrl: 'https://sat.siku.dev.c3n.cloud/hcms/v1.0/',
  baseOCUrl: 'https://sat.siku.dev.c3n.cloud/oc/',
  recaptchaKey: '6Lf8r6kUAAAAADmuoi35MZLjwjXLXU5c92xPmbcT',
  ocSatelliteAccess: '',
  cloudCheckr: 'https://api.cloudcheckr.com/api/inventory.json/',

  // baseHCSMUrl: 'http://localhost:57200/',
  // baseOCUrl: 'http://localhost:57300/',

  hcmsAuth: 'Basic c3lzdGVtOnF3ZXJ0eg==',

  webdavBaseUrl: 'https://webdav.c3n.cloud/checkout/'

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
