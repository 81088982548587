import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
/**
 * This component defines the errors that are displayed.
 */
@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent {
  /**
   * The error message
   */
  message;
  /**
   * The error message if the error is an internal error.
   */
  internal_message;
  /**
   * The error stack
   */
  stack;
  /**
   * 
   * @param data loaded from the dialog, info about error.
   * @param ref angular material component
   * @param translate Service with the functions related to the translations.
   */
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, @Inject(MatSnackBarRef) public ref: any, private translate:TranslateService) {

    if (data.internal_error && data.internal_error.message) {
      this.internal_message = data.internal_error.message;
    }

    if (data.internal_error && data.internal_error.stack) {
      this.stack = data.internal_error.stack;
    }

    if (data.action) {
      this.message = this.translate.instant(data.action);
    }
  }

}
