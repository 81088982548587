import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { OCService } from '../satellites/oc.service';
import { AppSettings } from 'app/shared/app.settings';
import { UserService } from '../user/user.service';
import { RolesService } from '../user/roles.service';
import { LiquidCache } from 'ngx-liquid-cache';
import { WorkflowService } from '../workflows/workflow.service';

/**
 * Service defined to manage all operations needed with projects ({@link Project})
 *
 * The project listings are defined in a separate service for the listing, {@link ListsService}.
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  /**
   * constant with the value of the workflow step 'Planned'.
   */
  static readonly WF_PROJECT_PLANNED : number = 10;

  /**
   * constant with the value of the workflow step 'In Progress'.
   */
  static readonly WF_PROJECT_IN_PROGRESS : number = 20;

  /**
   * constant with the value of the workflow step 'Campaign Finished'.
   */
  static readonly WF_PROJECT_CAMPAIGN_FINISHED : number = 30;

  /**
   * constant with the value of the workflow step 'Closed'.
   */
  static readonly WF_PROJECT_CLOSED : number = 40;

  /**
   * Constant array with the values of the priorities of the project
   */
  static readonly PRIORITIES = [
    // { key: 1, name: "Urgent" },
    { key: 2, name: "High" },
    { key: 3, name: "Normal" },
    { key: 4, name: "Low" }
  ];

  /**
   * The constructor
   * @param hcmsService Service whit the functions related to censhare using restangular.
   * @param usersService Service with the functions related to the users.
   * @param rolesService Service with the functions related to the roles.
   * @param ocService Service whit the functions related to the online channel.
   * @param workflowsService Service with the functions related to the workflows.
   */
  constructor(private hcmsService : HCMSService,
              private usersService : UserService,
              private rolesService : RolesService,
              private ocService: OCService,
              private workflowsService: WorkflowService) { }

  /**
   * Saves a project
   * @param project, the project to save
   * @returns the project saved
   */
  public saveProject(project) {
    return this.hcmsService.get().all('entity/project').post(project).toPromise();
  }

  /**
   * Edits a project
   * @param project, the project to be edited
   * @returns the edited project
   */
  public editProject(project) {
    return this.hcmsService.get().copy(project).save().toPromise();
  }

  /**
   * Creates a filter for a restangular call
   * @param {number} id, the id of the user
   * @returns the query for the call filtering by owner_rel and project_rel
   */
  filterByUserRelation(id: number = 0) {
    if (id == 0) id = this.usersService.getCurrentUser().id;
    return "owner_rel=" + id + "|projectmanager_rel=" + id;
  }

  /**
   * Gets all projects
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns the list of projects
   */
  public getProjects(page=0) {
    let query = '';
    let user = this.usersService.getCurrentUser();
    if (this.rolesService.checkRole(user, RolesService.POWER_ROLE))
      query = this.filterByUserRelation();

    return this.hcmsService.get().one('entity/project?query='+query+'&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Obtain the projects paginated where user is owner o manager.
   *
   * @param {number} id The user id
   * @param {number} page The page required (optional)
   * @returns A list of projects
   */
  public getProjectsByUser(id: number, page=0) {
    return this.hcmsService.get().one('entity/project?query=(' + UserService.OWNER + '=' + id + '%7C' + UserService.PROJECT_MANAGER +'=' + id + ')&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Obtain the projects paginated where user is only manager.
   *
   * @param {number} id The user id
   * @param {number} page The page required (optional)
   * @returns A list of projects
   */
  public getProjectsByPM(id: number, page=0) {
    return this.hcmsService.get().one('entity/project?query=' + UserService.PROJECT_MANAGER +'=' + id + '&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Gets one project and stores it in the cache
   * @param id, the project id to get.
   * @returns the project with the id of the param
   */
  @LiquidCache('project{id}', { duration: 60 })
  public getCachedProject(id) {
    return this.hcmsService.get().one('entity/project', id).get();
  }

  /**
   * Gets one project
   * @param id, the project id to get.
   * @returns the project with the id of the param
   */
  public getProject(id) {
    return this.hcmsService.get().one('entity/project', id).get();
  }

  /**
   * Gets a copy of the project
   * @param project, the project to copy
   * @returns the copy of the project
   */
 public getCopy (project) {
    return this.hcmsService.get().copy(project);
  }

  /**
   * Change the wokrflow step of the project
   * @param assetId, the project id to be updated
   * @param step, the new step
   * @returns the updated project
   */
  public changeWorkflow(assetId, step) {
    return this.hcmsService.get().one('entity/project', assetId).get().toPromise().then(newAsset => {
      newAsset.workflow = +step.wf_id;
      newAsset.workflowStep = +step.wf_step;
      return newAsset.put();
    });
  }

  /**
   * Set additional fields or in other format in the projects that make easier operate with them
   *
   * @param projects A list of projects to fill
   * @returns The list of projects filled
   */
  public fillData(projects) {

    projects.forEach(project => {

      project.item_name = project.name;
      project.pms = this.usersService.getUsersByRelation(project, UserService.PROJECT_MANAGER, UserService.REMOVE_PROJECT_MANAGER);
      project.owner = this.usersService.getRelationUser(project, UserService.OWNER, UserService.REMOVE_OWNER);

      if (project.pms && project.pms.lenght > 0) {
        project.pm = project.pms.slice(0, 1);
      }

      if (project.workflow && project.workflowStep) {
        project.step = this.workflowsService.getWorkflowStep(project.workflow, project.workflowStep);
        project.status = this.workflowsService.getStateWithWorkflowStep(project.workflow, project.workflowStep);
      }


    });

    return projects;
  }

  /**
   * Update only the project deadline
   *
   * @param id The project id
   * @param {string} deadline new deadline
   * @returns The project updated
   */
  public saveDeadline(id, deadline){
    return this.getProject(id).toPromise().then(updated => {
      updated.deadline = deadline;
      return updated.put();
    });
  }

  /**
   * Update only the project start date
   *
   * @param id The project id
   * @param {string} startDate new start date
   * @returns The project updated
   */
  public saveStartDate(id, startDate){
    return this.getProject(id).toPromise().then(updated => {
      updated.startDate = startDate;
      return updated.put();
    });
  }

  /**
   * Update the start date and the deadline of the project
   * 
   * @param id The project id
   * @param {string} startDate new start date
   * @param {string} deadline new deadline
   * @returns The project updated
   */
  public async saveStartDateAndDeadline(id, startDate, deadline) {
    return await this.getProject(id).toPromise().then(async updated => {
      updated.startDate = startDate;
      updated.deadline = deadline;
      return await updated.put().toPromise().catch((error) => {
        console.error(error);
      });
    });
  }

  /**
   * Update the project info from Info Center
   *
   * @param id The project id
   * @param infocenter The info from infocenter
   * @returns The project updated.
   */
  public saveInfoCenterData(id, infocenter){
    return this.getProject(id).toPromise().then(updated => {
      if (infocenter && infocenter.step) {
        updated.workflowStep = infocenter.step;
      }
      if (infocenter && infocenter.hasOwnProperty('confidential')) {
        updated.confidential = infocenter.confidential;
      }
      if (infocenter && infocenter.startDate) {
        updated.startDate = infocenter.startDate;
      }
      if (infocenter && infocenter.deadline) {
        updated.deadline = infocenter.deadline;
      }
      if (infocenter && infocenter.firstAlignment) {
        updated.firstAlignment = infocenter.firstAlignment;
      }
      if (infocenter && infocenter.info) {
        updated.info = infocenter.info;
      }
      if (infocenter && infocenter.channel) {
        updated.channel = infocenter.channel;
      }
      if (infocenter && infocenter.objective) {
        updated.objective = infocenter.objective;
      }
      if (infocenter && infocenter.unit) {
        updated.unit = infocenter.unit;
      }
      if (infocenter && infocenter.priorityCntr) {
        updated.priorityCntr = infocenter.priorityCntr;
      }
      return updated.put().toPromise();
    });
  }

  /**
   * Remove a job (action) from a project
   *
   * @param projectId The project id
   * @param actionId The job id
   * @returns The project updated
   */
  public removeAction(projectId, actionId) {
    return this.getProject(projectId).toPromise().then(data => {
      data.removeRelation = [actionId];

      return data.put().toPromise();
    });
  }

  /**
   * Get the widget data for the dashboard
   * @returns the data for the widgets
   */
  public getWidgetData() {
    return this.hcmsService.get().one('entity/widget_data').get().toPromise();
  }
}
