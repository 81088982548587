import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';

/**
 * Service defined to manage all operations needed with recommendations.
 */
@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

  /**
   * Constructor.
   * @param hcmsService Service whit the functions related to censhare using restangular.
   */
  constructor(private hcmsService: HCMSService) { }

  /**
   * Get recommendation
   * @param id Recommendation id.
   * @returns Recommendation.
   */
  getRecommendation(id) {
    return this.hcmsService.get().one('entity/recommendation', id).get().toPromise();
  }

  /**
   * Get recommendation detail.
   * @param id Recommendation id.
   * @returns Recomendation detailed.
   */
  getRecommendationDetaill(id) {
    return this.hcmsService.get().one('entity/recommendation_detail', id).get().toPromise();
  }

  /**
   * Get file blob
   * @param downloadLink Download link. 
   * @returns File blob.
   */
  getFileBlob(downloadLink) {
    return this.hcmsService.get().one(downloadLink.substring(downloadLink.indexOf('entity/recommendation'))).withHttpConfig({responseType: 'blob'}).get();
  }
}
