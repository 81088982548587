import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { LiquidCache } from 'ngx-liquid-cache';
import { AssetFeaturesService } from '../asset_features/asset-features.service';

/**
 * Service defined to manage the snack search.
 */
@Injectable({
  providedIn: 'root'
})
export class SearchService {

  /**
   * Schema properties.
   */
  public schemaProperties;

  /**
   * Entity
   */
  private entity = 'entity/search';
  /**
   * Replaces
   */
  private replaces: any = {
    text: '##TEXT##',
    type: '##TYPE##'
  };
  /**
   * Config
   */
  private config: any = {
    order: 'name',
    limit: 100,
    'facets-limit': 5,
    'facets-order': 'count,value',
    query: '%24text(%22censhare%3Atext.meta%22%2C+%22' + this.replaces.text + '%22%2C+%22%22)%23type %26 type=%22' + this.replaces.type + '%22'
  };

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   * @param assetFeatureService Service with the functions related to the assets.
   */
  constructor(
    private hcmsService: HCMSService,
    private assetFeatureService: AssetFeaturesService
  ) {
    this.getSchema().subscribe(x => {

      this.schemaProperties = x.properties;

      Object.keys(x.properties).forEach(key => {

        if (x.properties[key]['cs:feature.key'] && !x.properties[key]['search:hide']) {
          this.assetFeatureService.getFeature(x.properties[key]['cs:feature.key']).subscribe(data => {
            x.properties[key].feature = data[0];

            if (data[0].value_type === '1' || data[0].value_type === '2' || x.properties[key]['search:isCachedTable']) {
              x.properties[key].type = data[0].value_type === '1' ? 'hierarchy' : 'select';
              this.assetFeatureService.getFeatureValue(x.properties[key]['cs:feature.key']).then(values => {
                x.properties[key].featureValues = values;
              });
            } else if (data[0].value_type === '5') {
              x.properties[key].type = 'date';
            }
          });
        }

      });
    });
  }

  /**
   * Search
   * @param {string} text Text to search
   * @param {string} type type
   * @returns Result from search. (Promise)
   */
  search(text: string, type: string) {
    let url = this.entity + '?';

    Object.keys(this.config).forEach((key, index) => {
      if (index > 0) {
        url += '&'
      }
      url += key + '=' + this.config[key];
    });

    url = url.replace(this.replaces.text, text).replace(this.replaces.type, type);


    return this.hcmsService.get().one(url).get().toPromise();
  }

  /**
   * Search by query.
   * @param query Query to search.
   * @returns Result from search. (Promise)
   */
  searchByQuery(query: string) {
    return this.hcmsService.get().one(this.entity + '?query=' + query).get().toPromise();
  }

  /**
   * Get schema
   * @returns Schema
   */
  @LiquidCache('searchSchema')
  getSchema() {
    let url: string = 'schema/search';
    return this.hcmsService.get().one(url).get();
  }

}