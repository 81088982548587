import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NewBriefingComponent } from 'app/layout/briefings/new-briefing/new-briefing.component';
import { NewRequestComponent } from 'app/layout/requests/new-request/new-request.component';

import { ActionsService } from 'app/shared/services/actions/actions.service';
import { BriefingService } from 'app/shared/services/briefing/briefing.service';
import { ListsService } from 'app/shared/services/lists/lists.service';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { RequestService } from 'app/shared/services/request/request.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { UserService } from 'app/shared/services/user/user.service';
import { CustomDataSource } from 'app/shared/tables/dataSource/custom-datasource';
import Utils from 'app/shared/utils/utils';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';

/**
 * This component defines the listings view for different assets
 */
@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ListsComponent implements OnInit {

  /**
   * MatSort to control the sorting
   */
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  /**
   * {@link paginator}
   */
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  /**
   * List of objects to show
   */
  @Input() list;

  /**
   * Filters to apply
   */
  @Input() filters;

  /**
   * Type of objects to list
   */
  @Input() type;

  /**
   * Columns to show in the listing
   */
  @Input() columns = [];
  /**
   * @ignore
   */
  @Input() view: string = '';
  /**
   * Pages
   */
  @Input() pages: boolean = true;
  /**
   * Custom sort column
   */
  @Input() customSortColumn: string = '';
  /**
   * Custom sort direction
   */
  @Input() customSortDirection: string = '';

  /**
   * Event that is emitted when the data is loaded.
   */
  @Output() dataLoaded: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
  /**
   * Event emitted when trying to reload view.
   */
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Event that is emitted when a team is clicked (selected).
   */
  @Output() teamSelected: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  /**
   * Filtered list.
   */
  filteredList = [];

  /**
   * {@link Utils} imorted class
   */
  utils = Utils;

  /**
   * Data source
   */
  dataSource: CustomDataSource;
  /**
   * Default sort column
   */
  defaultSortColumn;
  /**
   * Default sort direction
   */
  defaultSortDirection;

  /**
   * List size. Total number of elements.
   */
  listSize: number;
  /**
   * Page size. Number of pages.
   */
  pageSize: number = 10;
  /**
   * Page index. Current page.
   */
  pageIndex: number = 0;

  /**
   * Function for executing custom functions.
   */
  customFunctions = {};

  /**
   * Current user
   */
  currentUser;
  /**
   * @ignore
   */
  opened: Array<number> = [];
  /**
   * True when it is being duplicated.
   */
  duplicating: boolean = false;
  /**
   * @ignore
   */
  dupId: number;

  /**
   * Contains the permissions of the associated materials to edit and replace.
   */
  permissions: any = {};
  /**
   * Indicates if the user is an power user.
   */
  isPower: boolean = false;
  /**
   * Indicates if the user is an operator.
   */
  isOperator: boolean = false;
  /**
   * Helper for highlighting rows
   */
  children: Array<number> = [];

  /**
   * Constructor
   * @param usersService Service with the functions related to the users.
   * @param listsService Service with the functions related to the lists.
   * @param briefingService Service with the functions related to the briefing.
   * @param actionsService Service with the functions related to the actions.
   * @param dialog Service with the functions related to the dialog
   * @param rolesService Service with the functions related to the roles.
   * @param requestService Service with the functions related to the requests.
   */
  constructor(
    private usersService: UserService,
    private listsService: ListsService,
    private briefingService: BriefingService,
    private actionsService: ActionsService,
    public dialog: MatDialog,
    private rolesService: RolesService,
    private requestService: RequestService
  ) {
    this.dataSource = new CustomDataSource();
  }

  /**
   * Initializes the component and obtains the necessary data.
   */
  ngOnInit() {
    this.currentUser = this.usersService.getCurrentUser();
    this.isPower = this.rolesService.checkRole(this.currentUser, RolesService.POWER_ROLE);
    this.isOperator = this.rolesService.checkRole(this.currentUser, RolesService.OPERATOR_ROLE);

    if (this.customSortColumn) this.defaultSortColumn = this.customSortColumn;
    if (this.customSortDirection) this.defaultSortDirection = this.customSortDirection;
    this.loadData();

    this.permissions.replaceMaterial = this.usersService.getPermissions('material', 'replaceMaterial');
    this.permissions.editMaterial = this.usersService.getPermissions('material', 'editMaterial');
  }

  /**
   * Loads all data applying filters
   */
  public loadData(hubzChannels?: any[], clients?: any[]) {
    this.filteredList = this.dataSource.getData(this.list, this.sort, this.filters, this.defaultSortColumn, this.defaultSortDirection, this.currentUser, hubzChannels, clients);
    if (this.type == "jobs") {
      let filtered = this.filters.find(filter => filter.key == 'step');
      if (window.location.search !== "?showCompletedJobs=true" && filtered && filtered.value === null || (filtered && filtered.value !== null && +filtered.value.wf_step != ActionsService.JOB_WORKFLOW_DONE && +filtered.value.wf_step != ActionsService.JOB_WORKFLOW_CLOSED)) {
        this.filteredList = this.filteredList.filter(x => x.workflowStep != ActionsService.JOB_WORKFLOW_DONE && x.workflowStep != ActionsService.JOB_WORKFLOW_CLOSED);
      }
    }

    if (this.type == "projects") {
      let filtered = this.filters.find(filter => filter.key == 'step');
      if (window.location.search !== "?showCompletedProjects=true" && filtered && filtered.value === null || (filtered && filtered.value !== null && +filtered.value.wf_step != ProjectsService.WF_PROJECT_CLOSED)) {
        this.filteredList = this.filteredList.filter(x => x.workflowStep != ProjectsService.WF_PROJECT_CLOSED);
      }
    }

    // to do: verify if this is the smartest way to load the team members...
    if (this.type == "jobs" || this.type == "projects") {
      this.filteredList.forEach(x => {
        this.usersService.getTeam(x.id).then(result => {
          let flatTeam = [];
          let teams = this.usersService.getTeams(result);
          teams.forEach((value, key) => {
            value.forEach(val => {
              flatTeam.push({
                key: key,
                value: val
              });
            });
          });
          x.team = flatTeam;
        });
      });

    }

    this.filteredList.filter(x => !x.itemCount).forEach(x => {
      if (x.type === 'person.group.') {
          const childs = this.listsService.getTasksAssignedToTeam(x.id).then(y => {
            x.itemCount = y.length;
          });
      } else {
        
        if (x.childs) {
          x.itemCount = x.childs.length
        } else if (x.user_targets_parents_ids) {
          x.itemCount = x.user_targets_parents_ids.length;
        }
      }
    });

    
    this.dataSource.setData(this.filteredList, this.paginator, this.pageSize, false, this.pages);
    this.listSize = this.filteredList.length;
    this.dataLoaded.emit(this.filteredList);
  }

  /**
   * Adds to the listing all childs to a apecific item
   * 
   * @param item Item to add its childs
   */
  async getItemChilds(item) {

    this.opened.push(+item.id);

    let childs: any[];
    if (item.type === 'person.group.') {
      childs = await this.listsService.getTasksAssignedToTeam(item.id);
      this.dataSource.addData(item, childs);
    } else {
      childs = await this.listsService.getChilds(item);
      this.dataSource.addData(item, childs);
    }

    if (childs)
      for (let child of childs) this.children.push(child.id);
  }

  /**
   * Broadcasts that team has been selected
   * 
   * @param itemId 
   */
  openTeamScheduler(itemId) {
    this.teamSelected.emit(itemId);
  }

  /**
   * Removes to the listing all childs to a specific item
   * 
   * @param item Item to remove its childs
   */
  revemoItemChilds(item) {

    this.opened.splice(this.opened.indexOf(+item.id), 1);
    if (item.type === 'person.group.') {
      this.dataSource.removeParentData(+item.id, true);
    } else {
      this.dataSource.removeParentData(+item.id);
    }
  }

  /**
   * Paginator logic. This method is executed after you start the view.
   */
  ngAfterViewInit(): void {
    if (this.paginator) {
      if (this.sort) {
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
          .pipe(
            tap(() => this.loadData())
          )
          .subscribe();
      }

      this.paginator.page.subscribe(event => {
        this.pageIndex = event.pageIndex;
        this.opened = [];
      });
    } else {
      if (this.sort) {
        this.sort.sortChange.subscribe(() => this.loadData());
      }
    }
  }

  /**
   * To avoid warning on HTML
   * 
   * @param func The function to execute
   * @param params The params of the function
   * @returns The function
   */
  executeFunction(func, params = null) {
    return func(params);
  }

  /**
   * Reset paginator
   */
  resetPaginator() {
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  /**
   * Sets available values for a specific filter
   * 
   * @param filterName The filter name
   * @param values The availables values to set 
   * @param displayValues
   */
  setFilterValues(filterName, values, displayValues = null) {
    let filtered = this.filters.find(filter => filter.key == filterName);
    if (filtered) {
      filtered.displayValues = filtered.values = values;
      if (displayValues) {
        filtered.displayValues = displayValues;
      }
      if (!filtered.value) {
        filtered.valueMin = filtered.values[0];
        filtered.valueMax = filtered.values[filtered.values.length - 1];
      }
    }
  }

  /**
   * Checks if a user can open an item
   * 
   * @param item The item to open
   * @returns True if the user can open the item, false in other case
   */
  canOpenItem(item) {
    if (item.type.startsWith('task.') && !this.canOpenTask(item)) {
      return false;
    }
    return true;
  }

  /**
   * Checks if a user can open a task item
   * 
   * @param item The task item to open
   * @returns True if the user can open the task item, false in other case
   */
  canOpenTask(item) {
    if (this.type !== "requests" || (this.type === "requests" && (this.isPower ||
      ((item.user_targets_ids && item.user_targets_ids.includes(this.currentUser.id)) ||
        (item.owner_rel && item.owner_rel.includes(this.currentUser.id)))))) {
      return true;
    }
    return false;
  }

  /**
   * Duplicates a specific briefing
   * 
   * @param briefing The briefing to duplicate
   */
  duplicateBriefing(briefing) {
    this.duplicating = true;
    this.dupId = briefing.id;
    this.briefingService.duplicateBriefing(briefing, this.currentUser).then(data => {
      this.reload.emit(true);
      this.duplicating = false;
    });
  }

  /**
   * Duplicates a specific request
   * 
   * @param briefing The request to duplicate
   */
  duplicateRequest(request) {
    // this.duplicating = true;
    // this.dupId = briefing.id;
    // this.briefingService.duplicateBriefing(briefing, this.currentUser).then(data => {
    //   this.reload.emit(true);
    //   this.duplicating = false;
    // });
  }

  /**
   * Opens the Briefing Dialog with the briefing selected data
   * 
   * @param id The id of the briefing to open
   */
  openNewBriefingDialog(id): void {
    const newBriefingDialog = this.dialog.open(NewBriefingComponent, { panelClass: 'newBriefingDialog', data: id });
    newBriefingDialog.afterClosed().subscribe(result => {
      this.reload.emit(true);
    });
  }

  /**
   * Opens the Request Dialog with the request selected data
   * 
   * @param id The id of the request to open
   */
  openNewRequestDialog(id): void {
    const newRequestDialog = this.dialog.open(NewRequestComponent, { width: '1520px', height: '90%', panelClass: 'newRequestDialog', disableClose: true, data: id });
    newRequestDialog.afterClosed().subscribe(result => {
      this.reload.emit(true);
    });
  }
  markForDeletion(request){
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'deleteRequest'
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      const markForDeletion = request.markDeletion != 1;
      this.requestService.getRequest(request.id).then(requestUpdate =>{
        if(requestUpdate){
          requestUpdate = requestUpdate.plain()
          requestUpdate.markForDeletion = markForDeletion;
          this.requestService.updateRequest(requestUpdate.id, requestUpdate).then(requestNew =>{
            if(requestNew) {
              request = Object.assign(request, requestNew.plain());

              this.list = this.list.filter(x => x.id !== request.id);
              
              this.loadData();
            } 
          })
        }
      })
    }

    dialogRef = null;
  });
  }
}
