import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { UserService } from '../user/user.service';
import { WorkflowService } from '../workflows/workflow.service';
import { MaterialsService } from '../materials/materials.service';
import { OCService } from '../satellites/oc.service';
import { AppSettings } from 'app/shared/app.settings';
import { CommonService } from '../common/common.service';
import { Mail } from 'app/shared/model/mail.model';
import { MailService } from '../mail/mail.service';
import { RolesService } from '../user/roles.service';
import { NotificationsService } from '../notifcations/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { LiquidCache } from 'ngx-liquid-cache';

/**
 * Service defined to manage all operations needed with Jobs ({@link Action})
 *
 * The job listings are defined in a separate service for the listing, {@link ListsService}.
 */
@Injectable({
  providedIn: 'root'
})
export class ActionsService {

  /**
   * constant with the value of the workflow step 'Planned'.
   */
  static readonly JOB_WORKFLOW_PLANNED: number = 10;

  /**
   * constant with the value of the workflow step 'In Progress'.
   */
  static readonly JOB_WORKFLOW_IN_PROGRESS: number = 20;

  /**
   * constant with the value of the workflow step 'Done'.
   */
  static readonly JOB_WORKFLOW_DONE: number = 50;

  /**
   * constant with the value of the workflow step 'Closed'.
   */
  static readonly JOB_WORKFLOW_CLOSED: number = 60;

  /**
   * constant with the value of the workflow step 'Closed'.
   */
  static readonly WF_CLOSED_JOBS : number = 60;

  /**
   * Constant array with the values of the priorities of the job
   */
  static readonly PRIORITIES = [
    // { key: 1, name: "Urgent" },
    { key: 2, name: "High" },
    { key: 3, name: "Normal" },
    { key: 4, name: "Low" }
  ];

  /**
   * The constructor
   * @param hcmsService Service whit the functions related to censhare using restangular.
   * @param ocService Service whit the functions related to the online channel.
   * @param usersService Service with the functions related to the users.
   * @param workflowsService Service with the functions related to the workflows.
   * @param materialsService Service with the functions related to the materials.
   * @param commonService Service with the common functions.
   * @param mailService Service with the functions related to the mails.
   * @param rolesService Service with the functions related to the roles.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   * @param datepipe Pipe with the date pipe used to format dates.
   */
  constructor(private hcmsService : HCMSService, private ocService:OCService,
      private usersService:UserService, private workflowsService:WorkflowService,
      private materialsService:MaterialsService, private commonService:CommonService,
      private mailService:  MailService, private rolesService : RolesService,
      private notifications: NotificationsService,
      private translateService: TranslateService,
      public datepipe: DatePipe) {}

      /**
       * Saves a new job
       * @param action, the job to save.
       * @returns the job saved.
       */
  public saveAction(action) {
    return this.hcmsService.get().all('entity/action').post(action).toPromise().then(action => {

      let link = ['/jobs', 'detail', action.id];
      this.notifications.show(this.translateService.instant('action_created'), link, this.translateService.instant('open_action'), 20000);

      this.sendNewActionAssinedMail(action);
      return action;
    });
  }

  /**
   * Create new {@link Mail} objects to send mail notifications to the project managers when the job is assigned.
   * @param action
   */
  sendNewActionAssinedMail(action) {
    if (action[UserService.PROJECT_MANAGER] && action[UserService.PROJECT_MANAGER].length > 0) {
      let mail: Mail = new Mail(AppSettings.MAIL_ACTION_PM + '_' + action.id + '_' + +action[UserService.PROJECT_MANAGER][0], AppSettings.MAIL_ACTION_PM, +action.id, +action[UserService.PROJECT_MANAGER][0]);
      this.mailService.saveMail(mail);
    }

    if (action[UserService.CO_MANAGER] && action[UserService.CO_MANAGER].length > 0) {
      action[UserService.CO_MANAGER].forEach(co_pm => {
        let mail: Mail = new Mail(AppSettings.MAIL_ACTION_PM + '_' + action.id + '_' + +co_pm, AppSettings.MAIL_ACTION_PM, +action.id, +co_pm);
        this.mailService.saveMail(mail);
      });
    }
  }

  /**
   * Gets one job and stores it in the cache
   * @param id, the job id to get.
   * @returns the job with the id of the param
   */
  @LiquidCache('job{id}', { duration: 10 })
  public getCachedAction(id) {
    return this.hcmsService.get().one('entity/action/', id).get().toPromise()
    .catch((err) => {
      this.notifications.show(this.translateService.instant('error_get_asset') + id, null, null, 2500, 'error-snack');
    });
  }

  /**
   * Gets one job
   * @param id, the job id to get.
   * @returns the job with the id of the param
   */
  public getAction(id) {
    return this.hcmsService.get().one('entity/action/', id).get().toPromise();
  }

  /**
   * gets all jobs
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns the list of jobs
   */
  public getActions(page=0) {
    let query = '';
    let user = this.usersService.getCurrentUser();
    if (this.rolesService.checkRole(user, RolesService.POWER_ROLE))
      query = this.filterJobsByUserRelation();

    return this.hcmsService.get().one('entity/action?query='+query+'&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Creates a filter for a restangular call
   * @param id, the id of the user
   * @returns the query for the call filtering by owner_rel and project_rel
   */
  filterJobsByUserRelation(id: number = 0) {
    if (id == 0) id = this.usersService.getCurrentUser().id;
    return "owner_rel=" + id + "|projectmanager_rel=" + id;
  }

  /**
   * Gets the jobs for the dam list
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns the list of jobs
   */
  public getDamActions(page=0) {
    let query = '';
    let user = this.usersService.getCurrentUser();

    let availableRelations = [UserService.OWNER, UserService.CO_OWNER, UserService.PROJECT_MANAGER, UserService.CO_MANAGER];
    // if (!this.rolesService.checkRole(user, RolesService.PRODUCTION_ROLE))
    //   query = this.usersService.filterBySpecificUserRelation(availableRelations);

    return this.hcmsService.get().one('entity/action?query='+query+'&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Gets the jobs filtered by the project
   * @param project, the project id
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns the list of jobs that belongs to the project
   */
  public getActionsByProject(project,page=0) {
    let roleQuery = '';
    let user = this.usersService.getCurrentUser();
    if (!this.rolesService.checkRole(user, RolesService.PRODUCTION_ROLE))
      roleQuery = '(' + this.filterJobsByUserRelation() + ')%26';

    return this.hcmsService.get().one('entity/action?query='+roleQuery+'parents=' + project + '&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Gets the jobs filtered by the owner
   * @param owner, the id of the owner
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns the list of jobs that have the onwer passed as param as owner
   */
  public getActionsByOwner(owner,page=0) {
    return this.hcmsService.get().one('entity/action?query=' + UserService.OWNER +'=' + owner + '&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Gets the jobs filtered by the project manager
   * @param owner, the id of the project manager
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns the list of jobs that have the project managar passed as param as project manager
   */
  public getActionsByPM(owner,page=0) {
    return this.hcmsService.get().one('entity/action?query=' + UserService.PROJECT_MANAGER +'=' + owner + '&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Gets the jobs filtered by project manager or owner
   * @param user, the id of the user
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns the list jo jobs that have the user as owner or project manager
   */
  public getUserActions(user, page=0){
    return this.hcmsService.get().one('entity/action?query=(' + UserService.OWNER +'=' + user + '%7C' + UserService.PROJECT_MANAGER +'=' + user + ')&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Gets a copy of the job
   * @param action, the job to copy
   * @returns the copy of the job
   */
  public getCopy (action) {
    return this.hcmsService.get().copy(action);
  }

  /**
   * Edits a job
   * @param actionUpdate, the job to edit
   * @returns the job edited
   */
  public editAction(actionUpdate) {
    //action.route = 'entity/actionupdate';
    //return this.hcmsService.get().copy(action).save().toPromise().then(action => {
      return this.getAction(actionUpdate.id).then(x => {

        x = Object.assign(x, this.hcmsService.get().copy(actionUpdate).plain());
        x.route = 'entity/actionupdate';

        return x.save().toPromise().then(action => {
          if (action.projectmanager_rel && action.projectmanager_rel.length > 0) {
            this.mailService.getMails(AppSettings.MAIL_ACTION_PM, action.id).then(mails => {

              if (mails.count == 0) {

                let mail: Mail = new Mail(AppSettings.MAIL_ACTION_PM + '_' + action.id + '_' + +action.projectmanager_rel[0], AppSettings.MAIL_ACTION_PM, +action.id, +action.projectmanager_rel[0]);
                this.mailService.saveMail(mail);

                if (action[UserService.CO_MANAGER] && action[UserService.CO_MANAGER].length > 0) {
                  action[UserService.CO_MANAGER].forEach(co_pm => {
                    let mail: Mail = new Mail(AppSettings.MAIL_ACTION_PM + '_' + action.id + '_' + +co_pm, AppSettings.MAIL_ACTION_PM, +action.id, +co_pm);
                    this.mailService.saveMail(mail);
                  });
                }
              }
            });
          }

          return action;
        });


    });
  }

  /**
   * Gets the jobs filtered by parents
   * @param ids, array of ids to check as parents
   * @returns the list of jobs that have a parent in the list of ids
   */
  public getActionsByParents(ids : number[]){

    let query = 'entity/action?query=';
    ids.forEach(id => query += 'parents='+id+'|');
    query = query.substring(0, query.length - 1);

    return this.hcmsService.get().one(query).get().toPromise();

  }

  /**
   * Starts the job in approval
   * @param action, the job to start in approval
   * @returns the updated job
   */
  startInApproval(action) {

    let assetsString = [];
    assetsString.push(action.id);

    if (action[UserService.OWNER]) {
      let owner = this.usersService.getUserData(action[UserService.OWNER]);

      if (owner) {
        let mail: Mail = new Mail(AppSettings.MAIL_STARTINAPPROVAL + '_' + action.id + '_' + owner.id, AppSettings.MAIL_STARTINAPPROVAL, +action.id, +owner.id);
        this.mailService.saveMail(mail);
      }
    }

    //Co-owner

    if (action[UserService.CO_OWNER] && action[UserService.CO_OWNER].length > 0) {
      action[UserService.CO_OWNER].forEach(co_owner => {
        if (co_owner) {
          let mail: Mail = new Mail(AppSettings.MAIL_STARTINAPPROVAL + '_' + action.id + '_' + co_owner, AppSettings.MAIL_STARTINAPPROVAL, +action.id, +co_owner);
          this.mailService.saveMail(mail);
        }
      });
    }



    let step = this.workflowsService.getWorkflowStep(this.workflowsService.actionInApproval.workflow, this.workflowsService.actionInApproval.step);
    return this.changeWorkflow(action.id, step);
  }

  /**
   * Changes the kanban workflow
   * @param asset, the job to change the workflow step
   * @param stepId, the step id
   * @returns the updated job
   */
  public changeKanbanWorflow(asset, stepId){
    let step = this.workflowsService.getWorkflowStep(AppSettings.WORKFLOW_ACTION, stepId);
    return this.changeWorkflow(asset.id, step);
  }

  /**
   * Change the wokrflow step of the job
   * @param assetId, the job id to be updated
   * @param step, the new step
   * @returns the updated job
   */
  public changeWorkflow(assetId, step) {
    return this.hcmsService.get().one('entity/actionupdate', assetId).get().toPromise().then(newAsset => {
      newAsset.workflow = +step.wf_id;
      newAsset.workflowStep = +step.wf_step;

      if (AppSettings.CHECK_MATERIAL_MAIL_STEPS.includes(+step.wf_step) || AppSettings.EMPTY_FIELDS_JOB_MAIL_STEP == +step.wf_step) {
        let nowDate = Date.now();
        let tempUTCDate = new Date(nowDate);
        let tempLocalDate = new Date();
        tempLocalDate.setFullYear(tempUTCDate.getUTCFullYear());
        tempLocalDate.setMonth(tempUTCDate.getUTCMonth());
        tempLocalDate.setDate(tempUTCDate.getUTCDate());
        tempLocalDate.setHours(tempUTCDate.getUTCHours());
        tempLocalDate.setMinutes(tempUTCDate.getUTCMinutes());
        newAsset.emailReminderTime = this.datepipe.transform(tempLocalDate, 'yyyy-MM-ddTHH:mm:ss');
      } else {
        delete newAsset.emailReminderTime;
      }

      return newAsset.put();
  });
  }

  /**
   * Fills needed fields of the jobs
   * @param currentUser, the current {@link User} that is logged in
   * @param actions, array with the list of jobs to fill
   * @returns the list of jobs with the new data
   */
  public fillData(currentUser, actions) {

    actions.forEach(action => {

      action.item_name = action.name;
      action.checked = false;

      if (currentUser.watchlist) {
        action.watchlist = currentUser && currentUser.watchlist.indexOf(action.id) > -1;
      }

      action.pms = this.usersService.getUsersByRelation(action, UserService.PROJECT_MANAGER, UserService.REMOVE_PROJECT_MANAGER);
      action.owner = this.usersService.getRelationUser(action, UserService.OWNER, UserService.REMOVE_OWNER);

      if (action.pms && action.pms.lenght > 0) {
        action.pm = action.pms.slice(0, 1);
      }

      if (action.workflow && action.workflowStep) {
        action.step = this.workflowsService.getWorkflowStep(action.workflow, action.workflowStep);
        action.status = this.workflowsService.getStateWithWorkflowStep(action.workflow, action.workflowStep);
        if (action.step) action.progress = action.step;
      }

      if (action.materials) {
        action.indicator = this.materialsService.getIndicator(action.materials);
        let materials = action.materials.filter(x => x.type && this.materialsService.getMaterialTypes().indexOf(x.type) > -1 ).map(x => this.workflowsService.getWorkflowStatus(x.workflowStep, x.type));
        action.materials_count = materials.length;
      }

    });

    return actions;
  }

  /**
   * Gets the list of jobs that not are in the invalid states
   * @param page, the page number if the number of jobs is greather than the limit
   * @returns the list of jobs filtered by workflow step
   */
  public getPreviewJobs(page = 0) {
    let query = 'workflow=' + AppSettings.WORKFLOW_ACTION;

    if (AppSettings.WORKFLOW_INVALID_STATES) {
      AppSettings.WORKFLOW_INVALID_STATES.forEach(step => {
        query = query + '%26workflowStep!=' + step;
      })
    }
    return this.hcmsService.get().one('entity/action?query=' + query + '&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise();
  }

  /**
   * Get the values of the printer feature
   * @returns values of the printer feature
   */
  public getPrinterValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:loreal-printer-benelux').getList();
  }

  /**
   * Get the values of the cluster feature
   * @returns values of the cluster feature
   */
  public getClusterValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:cluster').getList();
  }

  /**
   * Get the values of the salesChannel feature
   * @returns values of the salesChannel feature
   */
  public getSalesChannelValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:cpd-sales-channel-benelux').getList();
  }

  /**
   * Get the values of the country feature
   * @returns values of the country feature
   */
  public getCountryValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:country-benelux').getList();
  }

  /**
   * Get the values of the tour feature
   * @returns values of the tour feature
   */
  public getTourValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:loreal-tour').getList();
  }

  /**
   * Get the values of the advertising feature
   * @returns values of the advertising feature
   */
  public getAdvertisingValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:job-type-benelux').getList();
  }

  /**
   * Get the values of the costCenterBrand feature
   * @returns values of the costCenterBrand feature
   */
  public getCostCenterBrandValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:costcenter-benelux-brand').getList();
  }

  /**
   * Get the values of the costCenterDomain feature
   * @returns values of the costCenterDomain feature
   */
  public getCostCenterDomainValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:costcenter-benelux-domain').getList();
  }

  /**
   * Get the values of the costCenterGLAccount feature
   * @returns values of the costCenterGLAccount feature
   */
  public getCostCenterGLAccountValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:costcenter-benelux-gl-account').getList();
  }

  /**
   * Get the values of the costCenterPromoCode feature
   * @returns values of the costCenterPromoCode feature
   */
  public getCostCenterPromoCodeValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:costcenter-benelux-promo-code').getList();
  }

  /**
   * Update only the action deadline
   *
   * @param id The action id
   * @param {string} deadline new deadline
   * @returns The action updated
   */
  public saveDeadline(id, deadline){
    return this.getAction(id).then(updated => {
      updated.route = 'entity/actionupdate';
      updated.deadline = deadline;
      return updated.put();
    });
  }

  /**
   * Update only the action start date
   *
   * @param id The action id
   * @param {string} startDate new start date
   * @returns The action updated
   */
  public saveStartDate(id, startDate){
    return this.getAction(id).then(updated => {
      updated.route = 'entity/actionupdate';
      updated.startDate = startDate;
      return updated.put();
    });
  }

  /**
   * Update the start date and the deadline of the action
   * 
   * @param id The action id
   * @param {string} startDate new start date
   * @param {string} deadline new deadline
   * @returns The action updated
   */
  public async saveStartDateAndDeadline(id, startDate, deadline) {
    return await this.getAction(id).then(async updated => {
      updated.route = 'entity/actionupdate';
      updated.startDate = startDate;
      updated.deadline = deadline;
      return await updated.put().toPromise().catch((error) => {
        console.error(error);
      });
    });
  }

  /**
   * Update the action info from Info Center
   *
   * @param id The action id
   * @param infocenter The info from infocenter
   * @returns The action updated.
   */
  public saveInfoCenterData(id, infocenter){
    return this.getAction(id).then(updated => {
      updated.route = 'entity/actionupdate';
      if (infocenter && infocenter.step) {
        updated.workflowStep = infocenter.step;
      }
      if (infocenter && infocenter.hasOwnProperty('confidential')) {
        updated.confidential = infocenter.confidential;
      }
      if (infocenter && infocenter.startDate) {
        updated.startDate = infocenter.startDate;
      }
      if (infocenter && infocenter.deadline) {
        updated.deadline = infocenter.deadline;
      }
      if (infocenter && infocenter.firstAlignment) {
        updated.firstAlignment = infocenter.firstAlignment;
      }
      if (infocenter && infocenter.info) {
        updated.info = infocenter.info;
      }
      if (infocenter && infocenter.channel) {
        updated.channel = infocenter.channel;
      }
      if (infocenter && infocenter.objective) {
        updated.objective = infocenter.objective;
      }
      if (infocenter && infocenter.unit) {
        updated.unit = infocenter.unit;
      }
      if (infocenter && infocenter.priorityCntr) {
        updated.priorityCntr = infocenter.priorityCntr;
      }
      if (infocenter && infocenter.heightCntr) {
        updated.heightCntr = +infocenter.heightCntr;
      }
      if (infocenter && infocenter.widthCntr) {
        updated.widthCntr = +infocenter.widthCntr;
      }
      if (infocenter && infocenter.depthCntr) {
        updated.depthCntr = +infocenter.depthCntr;
      }
      if (infocenter && infocenter.maxFileSize) {
        updated.maxFileSize = +infocenter.maxFileSize;
      }
      if (infocenter && infocenter.new_project) {
        updated.parents = [+infocenter.new_project];
      }
      if (infocenter && infocenter.remove_project) {
        updated.removeParentRelation = +infocenter.remove_project;
      }
      if (infocenter && infocenter.client) {
        updated.client = infocenter.client;
      }

      if (infocenter && infocenter.previousAsset){
        if (infocenter.previousAsset == -1) delete updated.previousAsset;
        else updated.previousAsset = infocenter.previousAsset;
      }

      if (infocenter && infocenter.nextAsset){
        if (infocenter.nextAsset == -1) delete updated.nextAsset;
        else updated.nextAsset = infocenter.nextAsset;
      }

      return updated.put().toPromise();
    });
  }

}
