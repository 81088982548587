import { Component, OnInit, Input } from '@angular/core';
import { Sort } from '@angular/material/sort';
import Utils from 'app/shared/utils/utils';

/**
 * @ignore
 */
@Component({
  selector: 'app-info-table',
  templateUrl: './info-table.component.html',
  styleUrls: ['./info-table.component.scss']
})
export class InfoTableComponent implements OnInit {

  Utils = Utils;

  @Input() data = [];
  @Input() keys = [];
  @Input() showHeader = false;
  @Input() url: string;

  sortedData = [];
  loaded: boolean = false;

  constructor() { }

  ngOnInit() {
    this.sortedData = this.data;
    this.loaded = true;
  }
/**
 * 
 * @param sort table sort direction
 * @returns avoid
 */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return compare(a[sort.active], b[sort.active], isAsc);
    });
  }
}
/**
 * Compares two elements of the table in order to sort them according to the chosen order
 * @param a first element
 * @param b second element
 * @param isAsc ascendant table sort 
 * @returns 
 */
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}