import { Asset } from "./asset.model";

/**
 * This class defines the structure for the canvas drows, a kind of asset
 */
export class CanvasDraw extends Asset {
    /**
     * download link of asset
     */
    downloadLink: string;
    /**
     * array of asset parent ids
     */
    parent: number[] = new Array();
}
