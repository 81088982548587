import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';

/**
 * Service defined to manage the necessary operations for asset notes ({@link Note})
 */
@Injectable({
  providedIn: 'root'
})
export class NotesService {

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   */
  constructor(private hcmsService: HCMSService) { }

  /**
   * Get notes.
   * @param {string} id Note ID.
   * @param {string} type Note type.
   * @returns 
   */
  getNotes(id, type: string = '') {
    let route = this.getNotesType(type);
    return this.hcmsService.get().one(route, id).get().toPromise();
  }

  /**
   * Obtain the entity route depending on note type
   * 
   * @param {string} assetType The note type
   * @returns The entity route required.
   */
  getNotesType(assetType: string) {
    switch (assetType) {
      case 'project.':
        return 'entity/notes_project';
      case 'order.':
        return 'entity/notes_job';
      case 'briefing.':
        return 'entity/notes_briefing';
      case 'request.':
        return 'entity/notes_request';
      default:
        return 'entity/notes';
    }
  }

  /**
   * Save note.
   * @param asset Asset to which the note will be added.
   * @param note Note to be added.
   * @returns 
   */
  saveNote(asset, note) {
    return this.getNotes(asset.id, asset.type).then(current => {
      if (!current.notes) {
        current.notes = [];
      }

      current.notes.push(note);

      return current.put().toPromise();
    });
  }

  /**
   * Delete note.
   * @param asset Asset to which the note will be deleted.
   * @param note Note to be deleted.
   * @param reply Reply (optional)
   * @returns 
   */
  deleteNote(asset, note, reply?) {
    return this.getNotes(asset.id, asset.type).then(current => {
      if (!current.notes) {
        current.notes = [];
      }

      if (reply) {
        const noteWithReplies = current.notes.find(x => x.timestamp === note.timestamp);
        noteWithReplies.reply.splice(noteWithReplies.reply.indexOf(reply.timestamp));
      } else {
        current.notes.splice(current.notes.indexOf(note));
      }

      return current.put().toPromise();
    });

  }

  /**
   * Update Note.
   * @param asset Asset to which the note will be updated.
   * @param note Note to be updated.
   * @returns 
   */
  updateNote(asset, note) {
    return this.getNotes(asset.id, asset.type).then(current => {
      if (!current.notes) {
        current.notes = [];
      }

      const oldNote = current.notes.find(x => x.timestamp === note.timestamp);

      if (oldNote) {
        oldNote.entry = note.entry;
      }

      if (note.reply) {
        oldNote.reply = note.reply;
      }

      return current.put().toPromise();
    });
  }
}
