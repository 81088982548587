import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Media } from 'app/shared/model/media.model';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * 
 */
@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent {

  /**
   * Recipients
   */
  recipients: string = "";
  /**
   * Message
   */
  message: string = "";

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data Data required for the component. 
   * @param assetService Service with the functions related to the assets.
   * @param translateService Service with the functions related to the translate.
   */
  constructor(
    private dialogRef: MatDialogRef<ShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Media[],
    private assetService: AssetService,
    private translateService: TranslateService
  ) {
    this.dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'send' && this.recipients) {
        if (!this.message) this.message = this.translateService.instant('message');
        this.assetService.shareAssets(this.recipients, this.message, this.data);
      }
    });
  }

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

}
