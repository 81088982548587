import { AppSettings } from "../app.settings";

/**
 * This class defines the Detailed Briefings structure
 */
export class DetailedBriefing {
    /**
     * id of DetailedBriefing
     */
    id: number;
    /**
     * name of DetailedBriefing
     */
    name: string;
    /**
     * domain of DetailedBriefing
     */
    domain: string = AppSettings.DOMAIN;
    /**
     * workflow of DetailedBriefing
     */
    workflow: number;
    /**
     * workflow step of DetailedBriefing
     */
    workflowStep: number;
    /**
     * type of DetailedBriefing
     */
    type: string;
    /**
     * date the briefing starts
     */
    startDate: string;
    /**
     * deadline of DetailedBriefing
     */
    deadline: string;
    /**
     * info of DetailedBriefing
     */
    info: string;
    /**
     * id of the {@link User} who created the detaildBriefing
     */
    createdByNew: number;
    /**
     * id of the {@link User} who modified the detaildBriefing
     */
    modifiedByNew: number;
    /**
     * Manager id of DetailedBriefing
     */
    manager_rel: Array<Number> = [];
    /**
     * Owner id of DetailedBriefing
     */
    owner_rel: Array<Number> = [];
    /**
     * priority of DetailedBriefing
     */
    priorityCntr: string;
    /**
     * projects parents of detaildBriefing
     */
    parents: Array<number>;
    /**
     * Array with the ids of the elements from which the relationship
     *  with this Briefing is to be deleted
     */
    removeRelation: Array<number>;
    /**
     * channel of DetailedBriefing
     */
    channel: string;
    /**
     * width of DetailedBriefing
     */
    width: number;
    /**
     * height of DetailedBriefing
     */
    height: number;
    /**
     * depth of DetailedBriefing
     */
    depth: number;
    /**
     * unit of DetailedBriefing
     */
    unit: string;
    /**
     * budget of DetailedBriefing
     */
    budget: number;
    /**
     * channel hubz of DetailedBriefing
     */
    channel_hubz: string;
    /**
     * customer of DetailedBriefing
     */
    client: string;

    /**
     * Briefings (defined below) associated with the detailed briefing
     */
    briefings: Array<Briefing> = [];
    /**
     * Files of agency data
     */
    agencydata: Array<AgencyData> = [new AgencyData()];
    /**
     * Files of final data
     */
    finaldata: Array<FinalData> = [new FinalData()];
    /**
     * Files of customer data
     */
    customerdata: Array<CustomerData> = [new CustomerData()];
    /**
     * Files of consulting data
     */
    consulting: Array<Consulting> = [new Consulting()];
    /**
     * Files of estimations data
     */
    costestimate: Array<CostEstimate> = [new CostEstimate()];
}

/**
 * This class defines the briefing structure needed for the detailed briefings
 */
export class Briefing {
    /**
     * id of briefing
     */
    id: number;
    /**
     * name of briefing
     */
    name: string = 'Briefing';
    /**
     * type of briefing
     */
    type: string = 'briefing.';
    /**
     * domain of briefing
     */
    domain: string = AppSettings.DOMAIN;
    /**
     * workflow of briefing
     */
    workflow: number = AppSettings.WORKFLOW_BRIEFING;
    /**
     * workflow step of briefing
     */
    workflowStep: number;
    /**
     * info of briefing
     */
    info: string;
    /**
     * id of the {@link User} who created the briefing
     */
    createdByNew: number;
    /**
     * id of the {@link User} who modified the briefing
     */
    modifiedByNew: number;
    /**
     * user related to the briefing
     */
    owner_rel: Array<Number> = [];
    /**
     * priority of briefing
     */
    priorityCntr: string;
    /**
     * channel hubz of briefing
     */
    channel_hubz: string;
    /**
     * client of briefing
     */
    client: string;
}

/**
 * Defines customer file for briefing
 */
export class CustomerData {
    /**
     * Id of the CustomerData folder.
     */
    id: number;
    /**
     * type of CustomerData folder
     */
    type: string = 'group.';
    /**
     * Name of the CustomerData folder.
     */
    name: string = "01 Customer data";
    /**
     * Domain of the CustomerData folder.
     */
    domain : string = AppSettings.DOMAIN;
    /**
     * Array with the ids of the elements from which the relationship with this user is to be deleted
     */
    removeRelation: Array<number>;
}

/**
 * Defines agency file for briefing
 */
export class AgencyData {
    /**
     * Id of the AgencyData folder.
     */
    id: number;
    /**
     * Type of the AgencyData folder.
     */
    type: string = 'group.';
    /**
     * Name of the AgencyData folder.
     */
    name: string = "02 Agency data";
    /**
     * Domain of the AgencyData folder.
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines final file for briefing
 */
export class FinalData {
    /**
     * Id of the FinalData folder.
     */
    id: number;
    /**
     * Type of the FinalData folder.
     */
    type: string = 'group.';
    /**
     * Name of the FinalData folder.
     */
    name: string = "03 Final data";
    /**
     * Domain of the FinalData folder.
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines estimation file for briefing
 */
export class CostEstimate {
    /**
     * Id of the CostEstimate folder.
     */
    id: number;
     /**
     * Type of the CostEstimate folder.
     */
    type: string = 'group.';
     /**
     * Name of the CostEstimate folder.
     */
    name: string = "04 Cost Estimate";
     /**
     * Domain of the CostEstimate folder.
     */
    domain : string = AppSettings.DOMAIN;
}

/**
 * Defines consulting file for briefing
 */
export class Consulting {
    /**
     * Id of the Consulting folder.
     */
    id: number;
    /**
     * Type of the Consulting folder.
     */
    type: string = 'group.';
    /**
     * Name of the Consulting folder.
     */
    name: string = "05 Consulting";
    /**
     * Domain of the Consulting folder.
     */
    domain : string = AppSettings.DOMAIN;
}
