import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActionsService } from '../services/actions/actions.service';
import { ProjectsService } from '../services/projects/projects.service';
import { CHARTS_TYPE, ReportingService, REPORTING_CHARTS } from '../services/reporting/reporting.service';
import { RequestService } from '../services/request/request.service';
import { UserService } from '../services/user/user.service';

/**
 * This component defines the widget for reporting charts about projects, jobs and requests
 */
@Component({
  selector: 'app-chart-widgets',
  templateUrl: './chart-widgets.component.html',
  styleUrls: ['./chart-widgets.component.scss']
})
export class ChartWidgetsComponent implements OnInit {

  /**
   * Chart to be displayed {@link REPORTING_CHARTS}
   */
  @Input() chart : REPORTING_CHARTS;

   /**
   * Indicates if widget is displayed in dashboard
   */
  @Input() dashboard: boolean = false;
  

  /**
   * Type of chart {@link CHARTS_TYPE}
   */
  chartTypes = CHARTS_TYPE;

  /**
   * Event that is issued when changing a favorite
   */
  @Output() favoriteChanged = new EventEmitter<void>();

  // URL Definition
  /**
   * Url projects
   */
  private url_projects = '/projects';
  /**
   * Url jobs
   */
  private url_jobs = '/jobs';
  /**
   * Url request
   */
  private url_requests = '/requests';

  /**
   * Current user.
   */
  currentUser;
  /**
   * Indicates whether a widget is a favorite
   */
  isFavorite : boolean = false;
  /**
   * widget data.
   */
  data;

  /**
   * Today
   */
  today = new Date();
  /**
   * One year
   */
  oneyear = this.today.setFullYear(this.today.getFullYear() - 1);

  /**
   * URL selected depending on jobs/projects
   */ 
  public url_redirect: string;

  /**
   * Constructor.
   * @param userService Service with the functions related to the users.
   * @param reportingService Service with the functions related to the reportings.
   */
  constructor(
    private userService : UserService,
    private reportingService : ReportingService,
  ) {
      this.currentUser = this.userService.getCurrentUser();
  }

  /**
   * Initialize component.
   */
  ngOnInit() {
    this.reportingService.favoriteCharts.subscribe(favorites => {
      this.isFavorite = favorites.indexOf(this.chart.key) > -1;
    });

    this[this.chart.dataFunction]();
  }

  /**
   * Obtains the jobs of last 12 months
   */
  getJobsLast12Months(){
    this.reportingService.jobs.subscribe(data => {
      if (data && data.length > 0){
        let filteredData = data.filter(job => job.createdAttr && new Date(job.createdAttr).getTime() >= this.oneyear);
        this.data = this.getStatistics(filteredData, 'dateSegment');
      }
    });
  }

  /**
   * Obtains my jobs (in which logged user is owner) of last 12 months
   */
  getMyJobsLast12Months(){
    this.reportingService.jobs.subscribe(data => {
      if (data && data.length > 0){
        let filteredData = data.filter(job => job.owner_ids && job.owner_ids.includes(this.currentUser.id) && job.createdAttr && new Date(job.createdAttr).getTime() >= this.oneyear);
        this.data = this.getStatistics(filteredData, 'dateSegment');
      }
    });
  }

  /**
   * Obtains only the open jobs in which logged user is involved
   */
  getOpenJobs() {
    this.reportingService.jobs.subscribe(data => {
      if (data && data.length > 0){
        let filteredData = data.filter(job => job.workflowStep == ActionsService.JOB_WORKFLOW_IN_PROGRESS &&
          ((job.owner_ids != null && job.owner_ids.includes(+this.currentUser.id)) ||
          (job.manager_ids != null && job.manager_ids.includes(+this.currentUser.id)) ||
          (job.user_targets_ids != null && job.user_targets_ids.includes(+this.currentUser.id))) );
        this.data = filteredData;
        this.url_redirect = this.url_jobs;
      }
    });
  }

  /**
   * Obtains only the closed jobs in which logged user is involved
   */
  getClosedJobs() {
    this.reportingService.jobs.subscribe(data => {
      if (data && data.length > 0){
        let filteredData = data.filter(job => job.workflowStep == ActionsService.JOB_WORKFLOW_CLOSED &&
          ((job.owner_ids != null && job.owner_ids.includes(+this.currentUser.id)) ||
          (job.manager_ids != null && job.manager_ids.includes(+this.currentUser.id)) ||
          (job.user_targets_ids != null && job.user_targets_ids.includes(+this.currentUser.id))));
        this.data = filteredData;
        this.url_redirect = this.url_jobs;
      }
    });
  }

  /**
   * Obtains only the open projects in which logged user is involved
   */
  getOpenProjects() {
    this.reportingService.projects.subscribe(data => {
      if (data && data.length > 0){
        let filteredData = data.filter(project => project.workflowStep == ProjectsService.WF_PROJECT_IN_PROGRESS &&
          ((project.owner_ids != null && project.owner_ids.includes(+this.currentUser.id)) ||
          (project.manager_ids != null && project.manager_ids.includes(+this.currentUser.id)) ||
          (project.user_targets_ids != null && project.user_targets_ids.includes(+this.currentUser.id))));
        this.data = filteredData;
        this.url_redirect = this.url_projects;
      }
    });
  }

  /**
   * Obtains only the closed projects in which logged user is involved
   */
  getClosedProjects() {
    this.reportingService.projects.subscribe(data => {
      if (data && data.length > 0){
        let filteredData = data.filter(project => project.workflowStep == ProjectsService.WF_PROJECT_CLOSED &&
          ((project.owner_ids != null && project.owner_ids.includes(+this.currentUser.id)) ||
          (project.manager_ids != null && project.manager_ids.includes(+this.currentUser.id)) ||
          (project.user_targets_ids != null && project.user_targets_ids.includes(+this.currentUser.id))));
        this.data = filteredData;
        this.url_redirect = this.url_projects;
      }
    });
  }

  /**
   * Obtains only the open jobs with channel
   */
  getOpenJobsByChannels(){
    this.reportingService.jobs.subscribe(data => {
      if (data && data.length > 0){
        let filteredData = data.filter(job => job.workflowStep < ActionsService.JOB_WORKFLOW_CLOSED && job.channel);
        this.data = this.getStatistics(filteredData, 'channelName');
      }
    });
  }

  /**
   * Obtains only the open jobs with channel in which logged user is involved
   */
  getMyOpenJobsByChannel(){
    this.reportingService.jobs.subscribe(data => {
      if (data && data.length > 0){
        let filteredData = data.filter(job => job.owner_ids && job.owner_ids.includes(this.currentUser.id) && job.workflowStep < ActionsService.JOB_WORKFLOW_CLOSED && job.channel);
        this.data = this.getStatistics(filteredData, 'channelName');
      }
    });
  }

  /**
   * Obtains only the open requests in which logged user is involved
   */
  getOpenRequests() {
    this.reportingService.requests.subscribe(data => {
      if (data && data.length > 0){
        let filteredData = data.filter(request => request.workflowStep == RequestService.STEP_IN_PROGRESS &&
          ((request.owner_ids != null && request.owner_ids.includes(+this.currentUser.id)) ||
          (request.manager_ids != null && request.manager_ids.includes(+this.currentUser.id)) ||
          (request.user_targets_ids != null && request.user_targets_ids.includes(+this.currentUser.id))) );
        this.data = filteredData;
        this.url_redirect = this.url_requests;
      }
    });
  }

  /**
   * emits a 'favourite' event on the chart. If it was favourite, it'll no longer be after event and viceversa.
   */
  changeFavorite(){
    this.reportingService.changeFavoriteChart(this.chart.key);
    this.favoriteChanged.emit();
  }

  /**
   * Obtains the statistics to print the chart properly.
   * 
   * @param data Data to print (jobs, projects, requests)
   * @param group data grouping
   * @returns The statistics of data grouped
   */
  getStatistics(data, group){
    return data.reduce((acc, it) => {
      acc[it[group]] = acc[it[group]] + 1 || 1;
      return acc;
    }, {});
  }
}
