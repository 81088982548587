import { ElementRef, Renderer2, TemplateRef } from "@angular/core";
import { RolesService } from "app/shared/services/user/roles.service";
import { UserService } from "app/shared/services/user/user.service";

/**
 * This is where you find the configuration of the widgets that are displayed in the {@link ActionDetailComponent},
 * in the {@link ProjectDetailComponent} and in the {@link DashboardComponent}.
 */
export class WidgetsConfig {
    /**
     * Checks the widget width and the resize and save the config in the user cache
     * 
     * @param component 
     * @param userService 
     * @param renderer 
     * @param event 
     * @param card 
     */
    static checkResizeWidth(component:any, userService: UserService, renderer: Renderer2, event: any, card: CardContent) {
        let data : ElementRef = renderer.selectRootElement(component[card.list + 'Element']);
        let totalWidth = data.nativeElement.offsetWidth;

        let sliceWidth : number = totalWidth * 0.33;
        let newWidth = event.size.width;

        if (+newWidth <= sliceWidth){
          newWidth = sliceWidth - 12; //gap & resize bar
        } else if( +newWidth <= sliceWidth * 2){
          newWidth = (sliceWidth * 2) - 12; //gap & resize bar
        } else {
          newWidth = totalWidth;
        }

        if(newWidth < 300) newWidth = 300;

        document.getElementById(card.key).style.width = newWidth + 'px';
        card.width = newWidth + 'px';

        userService.saveUserCardWidgetConfig(card);
    }

    /**
     * Configuration object where the default sizes and positions of widgets are set in the panels where they are defined.
     */
    static readonly config = {
        "project_detail": {
            "favorites": {
                "jobs": {width: '48%', position: 0},
                "tasks": {width: '32%', position: 1}
            },
            "moreFuntions": {
                "infocenter": {width: '48%', position: 0},
                "files": {width: '48%', position: 1},
                "team": {width: '48%', position: 2},
                "allbudgets": {width: '48%', position: 3},
                "notes": {width: '48%', position: 4},
                "history": {width: '48%', position: 5}
            }
        },
        "job_detail": {
            "favorites": {
                "tasks": {width: '75', position: 0},
                "materials": {width: '25', position: 1}
            },
            "moreFuntions": {
                "infocenter": {width: '50', position: 0},
                "files": {width: '50', position: 1},
                "team": {width: '50', position: 2},
                "budget": {width: '50', position: 2},
                "notes": {width: '50', position: 3},
                "history": {width: '50', position: 4}
            }
        },
        "dashboard": {
            [RolesService.PROJECT_MANAGER_ROLE]: {
                "favorites": {
                    "tasks": {width: '75', position: 1, maxDisplay: 6},
                    "projects": {width: '25', position: 2},
                },
                "moreFuntions": {
                    "myrequests": {width: '25', position: 1, maxDisplay: 2},
                    "jobs": {width: '75', position: 2, maxDisplay: 6},
                    "completedprojects": {width: '25', position: 3},
                    "completedjobs": {width: '25', position: 4},
                    "open_jobs_channel": {width: '25', position: 5},
                    "my_jobs_last_12_months": {width: '25', position: 6},
                },
            },
            [RolesService.PROJECT_MANAGER_PLUS_ROLE]: {
                "favorites": {
                    "tasks": {width: '75', position: 1, maxDisplay: 6},
                    "projects": {width: '25', position: 2},
                },
                "moreFuntions": {
                    "myrequests": {width: '25', position: 1, maxDisplay: 2},
                    "jobs": {width: '75', position: 2, maxDisplay: 6},
                    "completedprojects": {width: '25', position: 3},
                    "completedjobs": {width: '25', position: 4},
                    "open_jobs_channel": {width: '25', position: 5},
                    "my_jobs_last_12_months": {width: '25', position: 6},
                },
            },
            [RolesService.POWER_ROLE]: {
                "favorites": {
                    "tasks": {width: '75', position: 1, maxDisplay: 6},
                    "projects": {width: '25', position: 2},
                },
                "moreFuntions": {
                    "myrequests": {width: '25', position: 1, maxDisplay: 2},
                    "jobs": {width: '75', position: 2, maxDisplay: 6},
                    "completedprojects": {width: '25', position: 3},
                    "completedjobs": {width: '25', position: 4},
                    "open_jobs_channel": {width: '25', position: 5},
                    "my_jobs_last_12_months": {width: '25', position: 6},
                },
            },
            [RolesService.OPERATOR_ROLE]: {
                "favorites": {
                    "newrequests": {width: '50', position: 1, maxDisplay: 4},
                    "tasks": {width: '50', position: 2, maxDisplay: 4},
                },
                "moreFuntions": {
                    "myrequests": {width: '50', position: 1, maxDisplay: 4},
                    "jobs": {width: '25', position: 2},
                    "projects": {width: '25', position: 3},
                    "completedrequests": {width: '50', position: 4, maxDisplay: 6},
                    "completedjobs": {width: '25', position: 5},
                    "completedprojects": {width: '25', position: 6},
                },
            },
            [RolesService.PRODUCTION_ROLE]: {
                "favorites": {
                    "tasks": {width: '75', position: 1, maxDisplay: 6},
                    "projects": {width: '25', position: 2},
                },
                "moreFuntions": {
                    "myrequests": {width: '25', position: 1, maxDisplay: 2},
                    "jobs": {width: '75', position: 2},
                    "completedprojects": {width: '25', position: 3},
                    "completedjobs": {width: '25', position: 4},
                    "open_jobs_channel": {width: '25', position: 5},
                    "my_jobs_last_12_months": {width: '25', position: 6},
                },
            },
            [RolesService.RESOURCE_MANAGER_ROLE]: {
                "favorites": {
                    "tasks": {width: '75', position: 1, maxDisplay: 6},
                    "projects": {width: '25', position: 2},
                },
                "moreFuntions": {
                    "myrequests": {width: '25', position: 1, maxDisplay: 2},
                    "jobs": {width: '75', position: 2},
                    "completedprojects": {width: '25', position: 3},
                    "completedjobs": {width: '25', position: 4},
                    "open_jobs_channel": {width: '25', position: 5},
                    "my_jobs_last_12_months": {width: '25', position: 6},
                },
            }
            /*
            "moreFuntions": {
                "myrequests": {width: '25%', position: 1, exclude: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PRODUCTION_ROLE, RolesService.POWER_ROLE]},
                "newprojects": {width: '32%', position: 0, exclude: [RolesService.POWER_ROLE]},
                "newjobs": {width: '32%', position: 1, exclude: [RolesService.POWER_ROLE]},
                "jobs": {width: '32%', position: 3, exclude: [RolesService.OPERATOR_ROLE]},
                // "newbriefingdata": {width: '32%', position: 0, exclude: [RolesService.OPERATOR_ROLE]},
                // "mybriefingdata": {width: '32%', position: 1, exclude: [RolesService.OPERATOR_ROLE]},
                "completedprojects": {width: '32%', position: 4},
                "completedjobs": {width: '32%', position: 5},
                "newrequests": {width: '32%', position: 6, exclude: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PRODUCTION_ROLE, RolesService.POWER_ROLE]},
                
            }*/
        }
    }
}

/**
 * Cards where the widgets are located
 */
export class CardContent{
    /**
     * Component
     */
    component: string;
    /**
     * List
     */
    list: string;
    /**
     * Key
     */
    key: string;
    /**
     * Width
     */
    width: string;
    /**
     * Position
     */
    position: number;
    /**
     * Template
     */
    template: TemplateRef<any>;
    /**
     * Exclude
     */
    exclude: Array<string>;
    /**
     * max display
     */         
    maxDisplay: number;

    /**
     * Constructor
     * @param component Component
     * @param list List
     * @param key Key
     * @param template Template 
     * @param width Width
     * @param position Position
     * @param exclude Exclude
     * @param maxDisplay Max number of items
     */
    constructor(component: string, list: string, key: string, template: TemplateRef<any>, width: string, position: number, exclude : Array<string> = [], maxDisplay: number = 10){
      this.component = component;
      this.list = list;
      this.key = key;
      this.template = template;
      this.width = width;
      this.position = position;
      this.exclude = exclude;
      this.maxDisplay = maxDisplay;
    }
}