export class DamUtils {

    /**
     * WORKFLOWS
     */
    /**
     * Number of workflow project
     */
    static readonly WORKFLOW_PROJECT : number = 300;
    /**
     * Number of workflow action
     */
    static readonly WORKFLOW_ACTION : number =  17000;
    /**
     * Number of workflow picture
     */
    static readonly WORKFLOW_ID_PICTURE : number = 20;
    /**
     * Number of workflow vide
     */
    static readonly WORKFLOW_ID_VIDEO : number = 50;
    /**
     * Number of workflow pdf
     */
    static readonly WORKFLOW_ID_PDF : number = 4005;
    /**
     * Number of workflow audio
     */
    static readonly WORKFLOW_ID_AUDIO : number = 15;
    /**
     * Number of workflow briefing
     */
    static readonly WORKFLOW_ID_BRIEFING : number = 9051;
    /**
     * Number of workflow office
     */
    static readonly WORKFLOW_ID_OFFICE : number = 1120;
    /**
     * Number of workflow presentation
     */
    static readonly WORKFLOW_ID_PRESENTATION : number = 1130;
    /**
     * Number of workflow zip
     */
    static readonly WORKFLOW_ID_ZIP : number = 65;
    /**
     * Number of workflow webpage
     */
    static readonly WORKFLOW_ID_WEBPAGE : number = 20;
    /**
     * Number of workflow spreadsheet
     */
    static readonly WORKFLOW_ID_SPREADSHEET : number = 5065;
    /**
     * Number of workflow document design
     */
    static readonly WORKFLOW_ID_DOCUMENT_DESIGN : number = 50000;
    /**
     * Number of workflow keyword
     */
    static readonly WORKFLOW_ID_KEYWORD : number = 600;

    /**
     * STEPS
     */
    /**
     * Initial step
     */
    static readonly WORKFLOW_INITIAL_STEP = 500;
    /**
     * Rejected step
     */
    static readonly WORKFLOW_STEP_REJECTED = 510;
    /**
     * Approved step
     */
    static readonly WORKFLOW_STEP_APPROVED = 520;

    static readonly WORKFLOW_STEP_EXPIRED = 530;
    static readonly WORKFLOW_STEP_ARCHIVE = 540;

    /**
     * Initial step keywords
     */
    static readonly WORKFLOW_INITIAL_STEP_KEYWORDS = 10;

    /**
     * STATUS
     */
    /** 
     * Status pending
     */
    static readonly STATUS_PENDING: string = 'Pending';
    /** 
     * Status rejected
     */
    static readonly STATUS_REJECTED: string = 'Rejected';
    /** 
     * Status approved
     */
    static readonly STATUS_APPROVED: string = 'Approved';

    static readonly STATUS_EXPIRED: string = 'Expired';
    static readonly STATUS_ARCHIVE: string = 'Archive';

    static readonly SELECT_STATUSES = [
        { label: DamUtils.STATUS_PENDING.toLowerCase(), key: DamUtils.WORKFLOW_INITIAL_STEP },
        { label: DamUtils.STATUS_APPROVED.toLowerCase(), key: DamUtils.WORKFLOW_STEP_APPROVED },
        { label: DamUtils.STATUS_EXPIRED.toLowerCase(), key: DamUtils.WORKFLOW_STEP_EXPIRED },
        { label: DamUtils.STATUS_ARCHIVE.toLowerCase(), key: DamUtils.WORKFLOW_STEP_ARCHIVE }
    ];

    /**
     * True if is editing a dam
     */
    static damEditing: boolean = false;
    /**
     * Limit number of ladies that can be selected
     */
    static multiDamLimit: number = 10;

    /**
     * Get the file extension
     * @param filename File to obtain its extension
     * @returns {string} File extension
     */
    static getFileExtension(filename) {
        return '.' + filename.split('.').pop();
    }

    /**
     * Get the file name with the extension
     * @param filename File to obtain its extension
     * @returns {string} File name and file extension
     */
    static getFilenameWithoutExtension (filename) {
        return filename.split('.').slice(0, -1).join('.');
    }

    /**
     * Checks for duplicate files
     * @param list List to check
     * @param name File name
     * @returns {boolean} true | false if there are duplicate files
     */
    static checkDuplicateFile(list, name) {
        let duplicateFile = false;
        if (name) {
          duplicateFile = list.filter(x => x.name == name).length > 0;
        }
        return duplicateFile;
    }

      /**
       * Get entity of an asset
       * @param assetType Asset from which
       * @returns {string} Entity
       */
    static getEntity(assetType: string) {
        switch (assetType) {
            case 'picture.':
                return 'entity/dam_image';
            case 'picture.diorama.':
                return 'entity/dam_image_diorama';

            case 'picture.logo.':
                return 'entity/dam_image_logo';

            case 'picture.mood.':
                return 'entity/dam_image_mood';

            case 'picture.accessoiries.':
                return 'entity/dam_image_accessoiries';
            case 'picture.socialmedia.':
                return 'entity/dam_image_socialmedia';
            case 'picture.illustration.':
                return 'entity/dam_image_illustration';
            case 'picture.other.':
                return 'entity/dam_image_other';
            case 'picture.amazon.':
                return 'entity/dam_image_amazon';

            case 'picture.productshot.':
                return 'entity/dam_image_productshot';
            case 'picture.packshot.':
                return 'entity/dam_image_packshot';
            case 'picture.detail.':
                return 'entity/dam_image_detail';
            case 'picture.360.':
                return 'entity/dam_image_360';
            case 'video.':
                return 'entity/dam_video';
            case 'pdf.':
                return 'entity/dam_pdf';
            case 'audio.':
                return 'entity/dam_audio';
            case 'presentation.':
                return 'entity/dam_presentation';
            case 'office.':
                return 'entity/dam_office';
            case 'spreadsheet.':
                return 'entity/dam_spreadsheet';
            case 'zip.':
                return 'entity/dam_zip';
            case 'document.':
                return 'entity/dam_document';
            case 'document.design.':
                return 'entity/dam_document_design';
            case 'extended-media.html.':
                return 'entity/dam_html';
            case 'layout.catalouge.':
                return 'entity/dam_layout_catalouge';
            case 'layout.mood.':
                return 'entity/dam_layout_mood';
            case 'layout.manual.':
                return 'entity/dam_layout_manual';
            case 'layout.packaging.':
                return 'entity/dam_layout_packaging';
            case 'layout.pos.':
                return 'entity/dam_layout_pos';
        }
    }

    /**
     * Gets the workflow id based on the asset type
     * @param assetType Asset from which
     * @returns {string} Workflow id
     */
    static getWorkflowId(assetType: string) {
        switch (assetType) {
            case 'picture.':
                return DamUtils.WORKFLOW_ID_PICTURE;
            case 'video.':
                return DamUtils.WORKFLOW_ID_VIDEO;
            case 'pdf.':
                return DamUtils.WORKFLOW_ID_PDF;
            case 'audio.':
                return DamUtils.WORKFLOW_ID_AUDIO;
            case 'office.':
                return DamUtils.WORKFLOW_ID_OFFICE;
            case 'spreadsheet.':
                return DamUtils.WORKFLOW_ID_SPREADSHEET;
            case 'presentation.':
                return DamUtils.WORKFLOW_ID_PRESENTATION;
            case 'zip.':
                return DamUtils.WORKFLOW_ID_ZIP;
            case 'web-page.':
                return DamUtils.WORKFLOW_ID_WEBPAGE;
            case 'document.':
                return DamUtils.WORKFLOW_ID_PICTURE;
            case 'document.design.':
                return DamUtils.WORKFLOW_ID_DOCUMENT_DESIGN;
        }
    }

    /**
     * Get the asset type icon
     * @param assetType Asset from which
     * @returns {string} Asset type icon
     */
    static getIcon(assetType: string) {
        switch (assetType) {
            case 'web-page.':
                return 'web';
            case 'pdf.':
                return 'picture_as_pdf';
            case 'audio.':
                return 'music_video';
            case 'group.':
                return 'folder';
            case 'picture.':
                return 'photo';
            case 'video.':
                return 'movie';
            case 'presentation.':
                return 'photo_library';
            case 'zip.':
                return 'create_new_folder';
            default:
                return 'attachment';
        }
    }
}
