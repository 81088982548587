import { Component, Inject, InjectionToken, Injector, ReflectiveInjector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * This component defines the main view of an asset dialog.
 */
export const ASSET_DIALOG_DATA = new InjectionToken<any>('app.asset-data');

@Component({
  selector: 'app-asset-dialog',
  templateUrl: './asset-dialog.component.html',
  styleUrls: ['./asset-dialog.component.scss']
})
export class AssetDialogComponent {

  /**
   * {@link Injector}
   */
  myInjector: Injector;

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data Object with {@link AssetDetailComponent} and assetData (AssetId)
   * @param injector The injector to use as the parent for the new component.
   */
  constructor(
    private dialogRef: MatDialogRef<AssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector
  ) {
    this.myInjector = ReflectiveInjector.resolveAndCreate([{ provide: ASSET_DIALOG_DATA, useValue: data.assetData }], injector);
  }
  
  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
    this.dialogRef.afterClosed()
  }
}
