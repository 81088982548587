import { Component, OnInit, Input } from '@angular/core';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import Utils from 'app/shared/utils/utils';

/**
 * This component defines the preview view of a widget.
 */
@Component({
  selector: 'app-preview-widget',
  templateUrl: './preview-widget.component.html',
  styleUrls: ['./preview-widget.component.scss']
})
export class PreviewWidgetComponent implements OnInit {

  @Input() style: string;
  /**
   * PreviewJobs
   */
  jobList = [];
  /**
   * If true, you can get the list.
   */
  getList = false;

  /**
   * Constructor
   * @param actionService Service with the functions related to the actions.
   */
  constructor(
    private actionService: ActionsService
  ) { }

  /**
   * Execute {@link getPreviewJobs}
   */
  ngOnInit() {
    this.getPreviewJobs();
  }

  /**
   * Get preview jobs
   */
  getPreviewJobs() {
    this.getList = false;
    this.actionService.getPreviewJobs().then(async data => {
      let over = false;
      this.jobList = [];
      Utils.loadingData = true;
      while (!over) {
        this.jobList.push(...data.result);

        if (data.offset + data.limit < data['total-count']) {
          data = await this.actionService.getPreviewJobs(data.offset + data.limit);
        } else {
          over = true;
          Utils.loadingData = false;
        }
      }
      let currentYear = new Date().getFullYear();
      let customDate = currentYear + '-01-01'
      this.jobList = this.jobList.filter(x => x.preview && x.deadline && x.deliverDateFinal && x.deliverDateFinal > customDate);
      this.jobList = this.jobList.sort((a,b) => a.deadline.localeCompare(b.deadline));

      if (this.jobList && this.jobList.length > 9) {
        this.jobList = this.jobList.slice(0, 9);
      }

      this.getList = true;

      return this.jobList;
    });
  }

}
