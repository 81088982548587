import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserService } from 'app/shared/services/user/user.service';
import { TasksService } from 'app/shared/services/tasks/tasks.service';
import { DatePipe } from '@angular/common';
import { Task } from 'app/shared/model/task.model';
import { NotificationsService } from 'app/shared/services/notifcations/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import * as CustomEditor from '../../../ckeditor/ckeditor';

import * as moment from 'moment';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { NgxTimepickerFieldComponent } from 'ngx-material-timepicker';
import { AppSettings } from 'app/shared/app.settings';
import { MaterialsService } from 'app/shared/services/materials/materials.service';
import { MomentCustomAdapter } from 'app/shared/utils/MomentCustomAdapter';
import { RolesService } from 'app/shared/services/user/roles.service';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';
import Utils from 'app/shared/utils/utils';
import { ProfessionalTeamService } from 'app/shared/services/professionalTeam/professional-team.service';

/**
 * Constant with the formats of the date
 */
export const DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: { dateInput: 'DD/MM/YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
};

/**
 * This component defines the data to create a new task.
 */
@Component({
  selector: 'app-new-task',
  templateUrl: './new-task.component.html',
  styleUrls: ['./new-task.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentCustomAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
})
export class NewTaskComponent implements OnInit {

  AppSettings = AppSettings;
  /** 
   * {@link CustomEditor} that shows the ckEditor
   */
  public Editor = CustomEditor;
  /**
   * Form with the data of the new task
   */
  form: FormGroup;
  /** 
   * {@link User} with the current user that is logged in.
   */
  currentUser;
  /**
   * @ignore
   */
  actionsDisabled: boolean = false;
  /**
   * True if a project has already been added to the task
   */
  projectsDisabled: boolean = false;
  /**
   * @ignore
   */
  onDrag = false;
  /**
   * True if is creating a task
   */
  creatingTask = false;
  /**
   * True if the task is out of date from job period
   */
  isOutOfDateTask: boolean = false;

  /** the default start hour  */
  private DEFAULT_STARTDATE_HOUR = "00";

  /** the default start minute  */
  private DEFAULT_STARTDATE_MINUTE = "00";

  /** the default start second  */
  private DEFAULT_STARTDATE_SECOND = "00";

  /** the default deadline hour  */
  private DEFAULT_DEADLINE_HOUR = '23';

  /** the default deadline minute  */
  private DEFAULT_DEADLINE_MINUTE = '59';

  /** the default deadline second  */
  private DEFAULT_DEADLINE_SECOND = '59';
  /**
   * Selectable users for the team
   */
  all_users = [];
  /**
   * Selectable teams
   */
  all_teams = [];
  /**
   * boolean indicating if the new task is of type production
   */
  isProductionTask: Boolean = false;
  /**
   * Users to be added to the new task
   */
  users = [];
  /**
   * Array with all types of tasks
   */
  taskTypes: any[] = TasksService.ALL_TASKS;
  /**
   *  Array with the priorities that a briefing can have 
   */
  priorities = [];
  /**
   * Array with the data of the actions of the project to which the task belongs.
   */
  all_actions = [];
  /**
   * Array with the data of the actions of the users to which the task belongs.
   */
  actions = [];
  /**
   * Array with the data of the project of the users 
   */
  projects = [];
  /**
   * Default workflow step
   */
  defaultStep = TasksService.TASK_WORKFLOW_PLANNED;
  /**
   * Open workflow step
   */
  openStep = TasksService.TASK_WORKFLOW_OPEN;
  /**
   * The default time
   */
  defaultTime = '00:00';
  /**
   * The default deadline time
   */
  defaultDeadlineTime: string = '23:59';
  /**
   * Array of materials belonging to the action
   */
  materials = [];
  /** 
   * Array of materials to be attached to the new task
  */
  selectedMaterials = [];
  /** 
   * Array of files belonging to the request
  */
  files = [];
  /**
   * True if the start and end date needs to be updated
   */
  updatePeriods: boolean = false;
  /**
   * The data of the current {@link Action} 
   */
  currentAction;
  /**
   * True if the task type allows adding a new file.
   */
  canAddMaterials: boolean = false;
  /**
   * True if the task type allows upload a file.
   */
  canUploadFile: boolean = false;
  /**
   * Array with project materials
   */
  materialFolders = [];
  /**
  * The target of task materials
  */
  uploadTarget = [];
  /**
   * True if the task type allows asigned the task to other user
   */
  canAsignedTo = true;
  /**
   * The dates of start and end of a job
   */
  startEndJob = "";
  /**
   * The dates of start and end of a project
   */
  startEndProject = "";
  /**
   * Current date
   */
  today: Date = new Date();
  /**
   * The alert message if the files are duplicated
   */
  message;
  /**
   * The name of the file to be uploaded
   */
  names: string = '';
  /** array with the files to be added to the request */
  mediaLibraryFiles: any[] = [];

  /**
   * The previous task type
   */
  prevTaskType = '';
  /** Indicates the last date on which the assets were obtained.*/
  lastRefresh = {
    value: new Date()
  };
  /** Indicates the parameter by which the assets are to be sorted.*/
  sorting: string = "date";

  /** Indicates the output order of the assets whether ascending or descending.  */
  direction: number = -1;

  /**list of all customers */
  customerDataList: any[] = [];
  /** the current target selected */
  currentTarget= null;
  
  /** all targets of task */
  targets = [];

  loaded = false;
  /**
   * {@link ElementRef} that shows the favourite widgets.
   */
  @ViewChild('targetOptions', { static: false }) targetOptions: ElementRef;
  /**
   *  {@link NgxTimepickerFieldComponent} used to control the start time picker.  */
  @ViewChild('startTime', { static: true }) startTime: NgxTimepickerFieldComponent;
  /** 
 * {@link NgxTimepickerFieldComponent} used to control the end time picker. 
 */
  @ViewChild('endTime', { static: false }) endTime: NgxTimepickerFieldComponent;
  /**
   * 
   * @param dialogRef Service with the functions related to the dialogs. 
   * @param userService Service with the functions related to the users.
   * @param formBuilder Service with the functions related to the forms.
   * @param tasksService Service with the functions related to the tasks
   * @param datepipe Service with the date pipe used to format dates.
   * @param dialog Service with the functions related to the dialog.
   * @param notifications Service with the functions related to the notifications.
   * @param translateService Service with the functions related to the translations.
   * @param actionsService Service with the functions related to the jobs.
   * @param projectsService Service with the functions related to the projects.
   * @param materialsService Service with the functions related to the materials.
   * @param rolesService Service with the functions related to the roles.
   */
  constructor(public dialogRef: MatDialogRef<NewTaskComponent>,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private tasksService: TasksService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private notifications: NotificationsService,
    private translateService: TranslateService,
    private actionsService: ActionsService,
    private projectsService: ProjectsService,
    private materialsService: MaterialsService,
    private rolesService: RolesService,
    private teamsService: ProfessionalTeamService
  ) {

    this.defaultTime = this.datepipe.transform(new Date(), 'HH') + ':' + this.datepipe.transform(new Date(), 'mm');
    this.currentUser = this.userService.getCurrentUser();
    this.all_users = this.userService.getSelectablesUsers();

    this.priorities = [...TasksService.PRIORITIES];

    if (this.rolesService.checkRole(this.currentUser, RolesService.POWER_ROLE)) {
      this.taskTypes = [...TasksService.CUSTOMER_TASKS];
      this.defaultStep = TasksService.TASK_WORKFLOW_OPEN;
    }

    this.projectsService.getProjectsByUser(this.currentUser.id).then(projects => {
      if (projects.result && projects.result.length > 0) {
        this.projects = [...projects.result];
      }
    });

    this.actionsService.getUserActions(this.currentUser.id).then(async data => {
      let actions = [];
      let over = false;
      Utils.loadingData = true;

      while (!over) {
        actions.push(...data.result.filter(x => x.workflowStep && (x.workflowStep == ActionsService.JOB_WORKFLOW_PLANNED || x.workflowStep == ActionsService.JOB_WORKFLOW_IN_PROGRESS)));

        if (data.offset + data.limit < data['total-count']) {
          data = await this.actionsService.getUserActions(this.currentUser.id, data.offset + data.limit);
        } else {
          over = true;
          Utils.loadingData = false;
          this.actions = [...actions];
          this.all_actions = [...actions];
        }
      }

      this.checkCurrentProjectOrAction();
    });

    this.form = this.formBuilder.group({
      'tasktype': [null, Validators.compose([Validators.required])],
      'taskname': [null, Validators.compose([Validators.required])],
      'priority': [3, Validators.compose([Validators.required])],
      'action': [null, Validators.compose([Validators.required])],
      'project': [null, Validators.compose([])],
      'startDate': [moment(), Validators.compose([Validators.required])],
      'startDateTime': [null, Validators.compose([])],
      'deadline': [null, Validators.compose([Validators.required])],
      'deadlineTime': [null, Validators.compose([])],
      'targets': [null, Validators.compose([Validators.required])],
      'info': [null, Validators.compose([])],
      'files': [null, Validators.compose([])],
      'plannedHours': [null, null],
    });
    this.form.get('tasktype').valueChanges.subscribe( value =>{
      if(value && value === 'task.production.'){
        this.form.get('plannedHours').setValidators(Validators.required)
      }
    } )
    if (this.taskTypes && this.taskTypes.length === 1) {
      this.form.controls['tasktype'].patchValue(this.taskTypes[0].key);
      this.form.controls['taskname'].patchValue(this.taskTypes[0].name);
      this.changeTask();
    }
  }
  /**
   * Initialize the component and listen to events dates of the projects and jobs 
   */
  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.startTime) {
      this.startTime.registerOnChange((event) => {
        this.form.controls['startDateTime'].patchValue(event);
      });
    }

    if (this.endTime) {
      this.endTime.registerOnChange((event) => {
        this.form.controls['deadlineTime'].patchValue(event);
      });
    }

    setTimeout(() => {
      this.loaded = true;
    }, 150 );
    
  }


  /**
   * Checks the current project or action and executes method {@link getDefaultActionData} with that project
   */
  private async checkCurrentProjectOrAction() {
    if (window.location.href && window.location.href.indexOf('/tasks?jobs=') > -1) {
      let path = window.location.href.split('/tasks?jobs=');
      let actionid = path[1];
      this.getDefaultActionData(actionid);
    } else if (window.location.pathname) {
      if (window.location.pathname.startsWith('/projects/detail/')) {
        let path = window.location.pathname.split('/');
        if (path.length >= 4) {
          let projectid = path[3];
          let project = this.projects.find(x => x.id == projectid);
          if (project) {
            this.form.controls['project'].patchValue(+projectid);
            this.projectsDisabled = true;
            this.checkAvailableActions();
          }
        }
      } else if (window.location.pathname.startsWith('/jobs/detail/')) {
        let path = window.location.pathname.split('/');
        if (path.length >= 4) {
          let actionid = path[3];
          this.getDefaultActionData(actionid);
        }
      }
    }
  }

  /**
   * Gets the data of the current project/action
   * 
   * @param {number} actionid ID of the current project/action.
   */
  public async getDefaultActionData(actionid) {
    let action = this.actions.find(x => x.id == actionid);
    if (action) {
      this.currentAction = action;
      this.form.controls['action'].patchValue(+actionid);
      this.checkActionData();
      this.checkProjectParent();
      this.getMaterials();
      this.actionsDisabled = true;
    } else {
      let actionData = await this.actionsService.getAction(actionid);
      if (actionData.id) {
        this.actions.push(actionData);
        this.all_actions.push(actionData);
        this.currentAction = actionData;
        this.form.controls['action'].patchValue(+actionData.id);
        this.checkActionData();
        this.checkProjectParent();
        this.getMaterials();
        this.actionsDisabled = true;
      }
    }
  }

  /**
   * Checks the parent project and executes the {@link getMaterials} and {@link getProjectJobRangeDates} methods.
   */
  public checkProjectParent() {
    this.projectsDisabled = true;
    if (this.currentAction && this.currentAction.parents && this.currentAction.parents.length > 0) {
      let projectId = +this.currentAction.parents[0];

      if (this.projects.some(x => +x.id == projectId)) {
        this.form.controls['project'].patchValue(+this.currentAction.parents[0]);
      } else {
        this.projectsService.getProject(projectId).toPromise().then(projectData => {
          if (projectData.id) {
            this.projects.push(projectData);
            this.form.controls['project'].patchValue(+projectData.id);
          }
        });
      }

    }
    this.getMaterials();
    this.getProjectJobRangeDates();
  }

  /**
   * Check available actions and executes the {@link getProjectJobRangeDates} method.
   */
  public checkAvailableActions() {
    if (this.form.value.project) {
      this.actions = this.all_actions.filter(x => x.parents && x.parents.includes(this.form.value.project));
    } else {
      this.actions = this.all_actions;
    }

    if (this.form.value.action && !this.actions.map(x => x.id).includes(this.form.value.action)) {
      this.form.controls['action'].patchValue(null);
      this.actionsDisabled = false;
    }
    this.getProjectJobRangeDates();
  }

  /**
   * Check the current action data and executes the {@link getProjectJobRangeDates} method.
   */
  public checkActionData() {
    this.users = [];
    this.materials = [];
    this.selectedMaterials = [];
    if (this.form.value.action) {
      let action = this.actions.find(x => x.id === this.form.value.action);
      if (action) {
        this.currentAction = action;
        let users = [];
        UserService.TEAM_RELATIONS.forEach(relation => {
          if (action[relation]) {
            users = [...users, ...action[relation]];
          };
        });
        users = Array.from(new Set(users));
        this.users = this.all_users.filter(x => x.id && users.includes(x.id));
        this.users = this.users.filter(x => +x.id != +this.currentUser.id);
        this.getMaterials();

        Object.keys(this.form.controls).filter(x => x.startsWith('target')).forEach(target => {
          let value = this.form.controls[target].value;
          if (value && !this.users.map(x => x.id).includes(value)) {
            this.form.controls[target].patchValue(null);
          }
        });
        if (action && action.parents && action.parents.length > 0) {
          let projectIndex = 0;
          if (action.parents.length > 1) {
            this.projects.forEach(project => {
              let index = action.parents.findIndex(x => x === project.id);
              if (index > -1) projectIndex = index;
            });
          }

          this.form.controls['project'].patchValue(+action.parents[projectIndex]);
          this.projectsDisabled = true;
        } else {
          this.form.controls['project'].patchValue(null);
          this.projectsDisabled = false;
        }
      } else {
        this.projectsDisabled = false;
        Object.keys(this.form.controls).filter(x => x.startsWith('target')).forEach(target => this.form.controls[target].patchValue(null));
      }
    } else {
      this.projectsDisabled = false;
      Object.keys(this.form.controls).filter(x => x.startsWith('target')).forEach(target => this.form.controls[target].patchValue(null));
    }
    this.getProjectJobRangeDates();
  }

  /**
   *  Get the date range of the jobs
   */
  getProjectJobRangeDates() {
    this.startEndJob = "";
    this.startEndProject = "";

    if (this.form.value.action) {
      let job = this.actions.find(x => x.id === this.form.value.action);
      this.startEndJob = moment(job.startDate).format('DD.MM.YYYY') + ' - ' + moment(job.deadline).format('DD.MM.YYYY');
    }

    if (this.form.value.project) {
      let project = this.projects.find(x => x.id === this.form.value.project);
      this.startEndProject = project ?
        moment(project.startDate).format('DD.MM.YYYY') + ' - ' + moment(project.deadline).format('DD.MM.YYYY') :
        "";
    }
  }

  /**
   * Get materials from the database
   */
  getMaterials() {
    this.materials = [];
    if (this.currentAction && this.materialFolders && this.materialFolders.length > 0) {

      let folders = this.tasksService.getWorkFolders(this.currentAction, this.materialFolders);
      if (folders.length > 0) {
        this.materialsService.getMaterialsByParents(folders).then(data => {
          if (data.result && data.result.length > 0) {
            this.materials = data.result;
          }
        });
      }
    }
  }

  /**
   * Closes the dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  editTarget(target, action = 'add'){
    if(action === 'delete'){
      this.targets = this.targets.filter(item => target.id !== item.id)
    }else{
      this.targets.push(target)
    }
    this.form.controls.targets.patchValue(this.targets.map(item => item.id))
    this.currentTarget= null
  }

  /**
   * Checks if the task type exists in the taskTypes array, if true it allows you to upload materials.
   */
  public changeTask() {
    if (this.form.value.tasktype) {
      let type = this.taskTypes.find(x => x.key === this.form.value.tasktype);
      if (type) {
        this.form.patchValue({ taskname: type.name });
        this.canAddMaterials = type.addMaterial;
        this.canUploadFile = type.newFile;
        this.materialFolders = type.materialsSelection;
        this.uploadTarget = type.materialsTarget;

        this.currentTarget= null;

        if (type.key !== "task.milestone.") {
          this.canAsignedTo = true;
          this.form.updateValueAndValidity();
        } else {
          this.canAsignedTo = false;
          this.form.updateValueAndValidity();
        }


        if (type.key === 'task.production.') {
          this.targets= [];
          this.form.controls.targets.patchValue(null)
          this.isProductionTask = true;
          this.getAllTeams();
        } else {
          this.isProductionTask = false;
          this.form.get('plannedHours').setValue(null);
          if (this.prevTaskType === 'task.production.') {
            this.targets= [];
            this.form.controls.targets.patchValue(null)
          }
        }

        this.prevTaskType = type.key;
        this.getMaterials();
      }
    } else {
      this.canAddMaterials = false;
      this.canUploadFile = false;
      this.materialFolders = [];
      this.uploadTarget = [];
      this.canAsignedTo = true;
    }

    this.replicateStartDate(null);
  }

  /**
   * If true store current time in startTime, else in endTime
   * 
   * @param {Boolean} start 
   */
  public saveCurrentTime(start: boolean = true) {

    let currentHour: number = +this.datepipe.transform(new Date(), 'HH');
    let currentMinutes: number = +this.datepipe.transform(new Date(), 'mm');
    let date: string = (start) ? 'startDate' : 'deadline';

    this.form.controls[date].patchValue(moment.utc());

    if (start) {
      this.startTime.changeHour(currentHour);
      this.startTime.changeMinute(currentMinutes);
    } else {
      this.endTime.changeHour(currentHour);
      this.endTime.changeMinute(currentMinutes);
    }
  }

  /**
   * Saves the task information and creates it.
   * 
   * @param {Number} step Workflow step of the material.
   * @returns 
   */
  async saveData(step: number) {
    if (this.form.valid) {

      this.creatingTask = true;

      if (!this.isMilestoneTask()) {
        await this.materialsService.createNewMaterialsInTask(this.files, this.selectedMaterials, this.currentAction, this.uploadTarget);
      }

      let task: Task = new Task();
      if (this.form.value.tasktype) task.type = this.form.value.tasktype;
      if (this.form.value.taskname) task.name = this.form.value.taskname;
      if (this.form.value.priority) task.priority = +this.form.value.priority;
      // if (this.form.value.info) task.info = this.form.value.info;
      if (this.form.value.action) task.parents = [this.form.value.action];

      if (this.currentAction) task.jobs = [this.currentAction];

      if (this.form.value.info) {
        let comment = {
          'userAsset': +this.currentUser.id,
          'timestamp': Math.round(Date.now() / 1000) * 1000,
          'entry': this.form.value.info,
        };

        task.communication = [comment];
      }

      if (this.isMilestoneTask()) {
        this.form.value.deadline = this.form.value.startDate;
      }

      if (this.form.value.plannedHours) task.plannedHours = this.form.value.plannedHours;

      if (this.form.value.startDate && this.form.value.deadline) {
        let startDate = this.form.value.startDate;
        let deadline = this.form.value.deadline;

        startDate.hour(0);
        startDate.minute(0);
        startDate.second(0);

        deadline.hour(23);
        deadline.minute(59);
        deadline.second(59);
        

        if (deadline < startDate) {
          this.notifications.show(this.translateService.instant('invalid_date_range'), null, null, 5000, 'error-snack');
          this.creatingTask = false;
          return '';
        }

        if (!this.form.value.startDateTime) {
          startDate.hour(this.DEFAULT_STARTDATE_HOUR);
          startDate.minute(this.DEFAULT_STARTDATE_MINUTE);
        } else {
          let startDateTokens = this.form.value.startDateTime.split(':');
          startDate.hour(startDateTokens[0]);
          startDate.minute(startDateTokens[1]);
        }
        startDate.second(this.DEFAULT_STARTDATE_SECOND);
        task.startDate = this.datepipe.transform(startDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');

        if (!this.isMilestoneTask()) {
          if (!this.form.value.deadlineTime) {
            deadline.hour(this.DEFAULT_DEADLINE_HOUR);
            deadline.minute(this.DEFAULT_DEADLINE_MINUTE);
          } else {
            let deadlineTokens = this.form.value.deadlineTime.split(':');
            deadline.hour(deadlineTokens[0]);
            deadline.minute(deadlineTokens[1]);
          }
          deadline.second(this.DEFAULT_DEADLINE_SECOND);
          task.deadline = this.datepipe.transform(deadline.toDate(), 'yyyy-MM-ddTHH:mm:ss');
        } else {
          task.deadline = task.startDate;
        }

      }


      task.targets = Array.from(new Set(this.form.value.targets));
      task.owner_rel = [this.currentUser.id];
      task.unopened = true;

      task.workflowStep = step;

      if (task.targets.length > 0) {
        task['responsible'] = task.targets[0];
      }

      if (this.selectedMaterials) {
        task.files = Array.from(new Set(this.selectedMaterials));
      }
      
      if (this.currentAction && this.checkIfOutsideTheJobPeriod(task)) {
        this.saveByAdjustingJobPeriod(task);
      } else {
        this.createTask(task);
      }
    } else {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  /**
   * Check if the task is outside the work period..
   * 
   * @param task Task to be checked
   * @returns {Boolean} True if within working range, else false.
   */
  checkIfOutsideTheJobPeriod(task) {
    return (task.startDate < this.currentAction.startDate || task.deadline > this.currentAction.deadline) ? true : false;
  }

  /**
   * Save the task by adjusting the work period
   * 
   * @param task task to be saved
   */
  saveByAdjustingJobPeriod(task) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'adjustJobPeriod'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updatePeriods = true;
        this.createTask(task);
      } else {
        this.updatePeriods = false;
        this.isOutOfDateTask = true;
        this.creatingTask = false;
      }
      dialogRef = null;
    });
  }

  /**
   * Gets the start time for projects and jobs.
   * 
   * @returns Start date.
   */
  getStartTimeToProjectsAndJobs() {
    let start = null;

    if (this.form.value.startDate) {
      let date: moment.Moment;
      if (typeof this.form.value.startDate === 'string') {
        date = moment(this.form.value.startDate, "YYYY-MM-DD+00:00");
      } else {
        date = this.form.value.startDate;
      }

      if (this.form.value.startDateTime) {
        date.hour(+this.form.value.startDateTime.split(':')[0]);
        date.minute(+this.form.value.startDateTime.split(':')[1]);
      } else {
        date.hour(+this.defaultTime.split(':')[0]);
        date.minute(+this.defaultTime.split(':')[1]);
      }

      start = date.format('yyyy-MM-DDTHH:mm:00+0000');
      start = start.replace('+0000', 'Z');
    }

    return start;
  }

  /**
   * Gets the end time for projects and jobs.
   * 
   * @returns End date.
   */
  getDeadlineToProjectsAndJobs() {
    let deadline = null;

    if (this.form.value.deadline) {
      let date: moment.Moment;
      if (typeof this.form.value.deadline === 'string') {
        date = moment(this.form.value.deadline, "YYYY-MM-DD+00:00");
      } else {
        date = this.form.value.deadline;
      }

      if (this.form.value.deadlineTime) {
        date.hour(+this.form.value.deadlineTime.split(':')[0]);
        date.minute(+this.form.value.deadlineTime.split(':')[1]);
      } else {
        date.hour(+this.defaultDeadlineTime.split(':')[0]);
        date.minute(+this.defaultDeadlineTime.split(':')[1]);
      }

      deadline = date.format('yyyy-MM-DDTHH:mm:59+0000');
      deadline = deadline.replace('+0000', 'Z');
    }

    return deadline;
  }

  /**
   * Creates a task by executing the {@link newTask} method of the {@link s} and when it is finished executes {@link finishProcess}
   * 
   * @param task Task to be created
   */
  createTask(task) {
    this.tasksService.newTask(task).then(result => {
      this.finishProcess(result);
    });
  }

  /**
   * Show a file
   * 
   * @param event 
   */
  public showFile(event) {
    event.preventDefault();
    this.message = '';
    this.names = '';

    if (event.dataTransfer != null && event.dataTransfer.files != null && event.dataTransfer.files.length > 0) {

      Array.from(event.dataTransfer.files).forEach(file => {
        if (!this.isDuplicatedFile(file)) {
          this.files.push(file);
        }
      });
    } else if (event.srcElement != null && event.srcElement.files != null && event.srcElement.files.length > 0) {
      Array.from(event.srcElement.files).forEach(file => {
        if (!this.isDuplicatedFile(file)) {
          this.files.push(file);
        } else {
          this.showDuplicatedMessage(file);
        }
      });
      event.target.value = '';
    }
  }

  /**
   * Check if a file is duplicated
   * 
   * @param file File to be checked.
   * @returns {Boolean} true | false if duplicated
   */
  private isDuplicatedFile(file) {
    let isDuplicated = false;

    if (this.files && file) {
      isDuplicated = this.files.filter(x => x.name == file.name).length > 0;
    }

    return isDuplicated;
  }

  /**
   * Check if a file is duplicated
   * 
   * @param file 
   */
  private showDuplicatedMessage(file) {
    this.message = "duplicate_files";

    if (!this.names) {
      this.names = this.names.concat(file.name);
    } else {
      this.names = this.names.concat(", " + file.name);
    }
  }

  /**
   * Delete a file
   * 
   * @param file file to be deleted.
   */
  public deleteFile(file) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  /**
   * Completes the process of creating a task
   * 
   * @param result Result of task creation in the methods {@link createTask}
   */
  private finishProcess(result) {
    if (result && this.updatePeriods) {
      let project = this.projects.find(x => x.id === this.form.value.project);
      let start = this.getStartTimeToProjectsAndJobs();
      if (start < this.currentAction.startDate) {
        this.actionsService.saveStartDate(this.currentAction.id, start);
        if (this.form.value.project && project && start < project.startDate) {
          this.projectsService.saveStartDate(project.id, start);
        }
      }
      let end = this.getDeadlineToProjectsAndJobs();
      if (end > this.currentAction.deadline) {
        this.actionsService.saveDeadline(this.currentAction.id, end);
        if (this.form.value.project && project && end > project.deadline) {
          this.projectsService.saveDeadline(project.id, end);
        }
      }
    }
    this.updatePeriods = false;
    this.creatingTask = false;
    this.isOutOfDateTask = false;
    this.dialogRef.close(result);

    // Utils.reloadData = this.checkCurrentProjectOrAction() ? +this.checkCurrentProjectOrAction() : Utils.RELOAD_DATA_TASKS_LIST;
    // Utils.reloadData = Utils.RELOAD_DATA_TASKS_LIST;
  }

  /**
   * Prevents the default behavior of the event
   * 
   * @param event 
   */
  preventDefaultEvent(event) {
    event.preventDefault();
  }

  /**
   * Checks if the choesen task is Milestone
   * 
   * @returns true if tasktype is Milestone, false in other case.
   */
  isMilestoneTask() {
    return this.form.value.tasktype === "task.milestone.";
  }

  /**
   * Replicate start date
   * 
   * @param event 
   */
  public replicateStartDate(event: any): void {
    if (this.isMilestoneTask()) {
      this.form.patchValue({ ['deadline']: event && event instanceof moment ? event : this.form.value.startDate });
      this.form.controls['deadline'].disable();
    } else {
      this.form.controls['deadline'].enable();
    }
  }
  private async getAllTeams() {


    this.all_teams = [];

    for (const agencyId of this.userService.getAgenciesId(this.currentUser)) {
      const teams = await this.teamsService.getTeamsByAgency(agencyId)
      this.all_teams.push(...teams.result);
    }

  }
  /**
     * Add library {@link Assets} to the new request library
     * @param event list of assets selected
     */
   updateLibraryFiles(event) {
    if (event && event.length) {
      this.files = this.files.filter(x => !event.some(y => y.id === x.id));
      this.selectedMaterials = Array.from(new Set(event.map(material => +material.id)));
    }
    
    this.mediaLibraryFiles = event;
    
  }
}
