import { Component, ContentChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'app/shared/app.settings';

import * as CustomEditor from '../../../ckeditor/ckeditor';
import * as moment from 'moment';
import { NgxTimepickerFieldComponent } from 'ngx-material-timepicker';
import { ViewChild } from '@angular/core';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import Utils from 'app/shared/utils/utils';
import { RequestJob } from 'app/shared/model/request.model';
import { RequestService } from 'app/shared/services/request/request.service';
import { UserService } from 'app/shared/services/user/user.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { DatePipe } from '@angular/common';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { MomentCustomAdapter } from 'app/shared/utils/MomentCustomAdapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { FileItemComponent } from 'app/shared/components/widgets/files-widget/file-item/file-item.component';
import { Mail } from 'app/shared/model/mail.model';
import { MailService } from 'app/shared/services/mail/mail.service';
import * as _ from 'lodash';

/**
 * Constant with the formats of the date
 */
export const DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: { dateInput: 'YYYY-MM-DD', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
};

/**
 * This component defines the information for creating a new job.
 */
@Component({
  selector: 'app-new-job',
  templateUrl: './new-job.component.html',
  styleUrls: ['./new-job.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentCustomAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ]
})
export class NewJobComponent {
  /** 
   * {@link NgxTimepickerFieldComponent} used to control the start time picker.  
   */
  @ViewChild('startTime', { static: true }) startTime: NgxTimepickerFieldComponent;
  /**
   *  {@link NgxTimepickerFieldComponent} used to control the end time picker.  
   **/
  @ViewChild('endTime', { static: true }) endTime: NgxTimepickerFieldComponent;
  /**
   * {@link MatSelect} used to control the team select.
   */
  @ViewChild('teamSelect', { static: true }) teamSelect: MatSelect;
  /**
   * the default deadline hour
   */
  private DEFAULT_DEADLINE_HOUR = "23";
  /**
   * the default deadline minute
   */
  private DEFAULT_DEADLINE_MINUTE = "59";
  /**
   * the default deadline second
   */
  private DEFAULT_DEADLINE_SECOND = "59";
  /**
   * {@link AppSettings} imported class
   */
  AppSettings = AppSettings;
  /**
   * {@link CustomEditor} that shows the ckEditor
   */
  public Editor = CustomEditor;
  /**
   * @ignore
   */
  onDrag = false;
  /**
   * Form with the data of the job 
   */
  form: FormGroup;
  /**
   * @ignore
   */
  defaultStep = ActionsService.JOB_WORKFLOW_PLANNED;
  /**
   * The request selected
   */
  request;
  /**
   * {@link User} with the current user that is logged in.
   */
  currentUser;
  /**
   * True if the job is being created
   */
  creatingJob = false;
  /**
   * @ignore
   */
  files = [];
  /**
   * The different values of the project priority
   */
  priorities = [];
  /**
  * Default time used in the start time picker
  */
  defaultTime = '00:00';
  /**
   * The date of today
   */
  today: Date = new Date();
  /**
   * Array with the data of the project of the users 
   */
  projects = [];
  /**
   * Selectable users for the team
   */
  selectableUsers = [];
  /**
   * True if the user selected is being added to the team
   */
  addingMember: boolean = false;
  /**
   * The selected user to add in the team
   */
  selectedUser;
  /**
   * {@link User} with the data of the owner.
   */
  owner;
  /**
   * Array of {@link Users} with the project managers data
   */
  managers = [];
  /**
   * True if the current user is operator. False in other case.
   */
  isOperator: boolean = false;
  /**
   * True if it is posible to create the job, false in other case.
   */
  canCreateJob: boolean = false;

  isManagerPlus = false;

  /**
   * Array with all available file types
   */
  fileTypes = [
    {
      title: 'widget.consulting',
      type: 'consulting'
    },
    {
      title: 'widget.cost-estimate',
      type: 'costestimate'
    },
    {
      title: 'widget.customer-data',
      type: 'customerdata'
    },
    {
      title: 'widget.agency-data',
      type: 'agencydata'
    },
    {
      title: 'widget.final-data',
      type: 'finaldata'
    }
  ];

  /**
   * Array of the file types already uploaded
   */
  fileTypesUploaded = [];

  /**
   * File type selected
   */
  fileTypeSelected = '';

  /**
   * The target of task materials
   */
  uploadTarget = [];

  /**
   * Array of materials to be attached to the new task
  */
  selectedMaterials = [];

  /**
   * Show attach file depending on role
   */
  managerPlus = false;

  /**
   * True if all users are loaded
   */
  teamReady = false;

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data Object with request
   * @param userService Service with the functions related to the users.
   * @param mailService Service with the functions related to the mails.
   * @param rolseService Service with the functions related to the roles.
   * @param formBuilder Service with the functions related to the forms.
   * @param datepipe Pipe with the date pipe used to format dates.
   * @param projectsService Service with the functions related to the projects.
   * @param requestService Service with the functions related to the request.
   * @param fileItem: Component with functions related with files.
   */
  constructor(
    public dialogRef: MatDialogRef<NewJobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
    private rolseService: RolesService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    public projectsService: ProjectsService,
    private requestService: RequestService,
    private fileItem: FileItemComponent,
    private mailService: MailService,

  ) {
    this.defaultTime = this.datepipe.transform(new Date(), 'HH') + ':' + this.datepipe.transform(new Date(), 'mm');

    this.request = data.request;

    // Find power users and if there isnt, owner_rel will be the owner
    if (this.request && this.request.owner_rel && this.request.owner_rel.length > 0) {
      _.forEach(this.request.owner_rel, (userId) => {
        userService.getUpdatedUser(userId).then(user => {
          if (_.includes(_.first(user.roles), 'power.') || _.isUndefined(this.owner)) {
            if (_.isUndefined(this.owner)) {
              this.owner = user;
            } else if (_.includes(_.first(this.owner.roles), 'power.')) {
              this.managers.push(user);
            } else {
              this.managers.push(this.owner);
              this.owner = user;
            }
          }
        });
      });
    }

    // Get all managers as object, not id
    if (this.request && this.request.manager_rel && this.request.manager_rel.length > 0) {
      this.request.manager_rel.forEach( (manager, key) => {
        userService.getUpdatedUser(manager).then(user => {
          this.managers.push(user);
          this.canCreateTheJob();
          this.teamReady = this.request.manager_rel.length - 1 === key ? true : false;
        });
      });
    }

    this.currentUser = userService.getCurrentUser();
    this.isManagerPlus = this.rolseService.isManagerPlus(this.currentUser);
    this.isOperator = this.rolseService.isOperator(this.currentUser);
    this.priorities = [...ActionsService.PRIORITIES];

    this.form = this.formBuilder.group({
      'info': [(this.request && this.request.info) ? this.request.info : null, Validators.compose([])],
      'name': [(this.request && this.request.name) ? this.request.name : null, Validators.compose([Validators.required])],
      'priority': [3, Validators.compose([Validators.required])],
      'startDate': [moment.utc(), Validators.compose([])],
      'startDateTime': [null, Validators.compose([])],
      'deadline': [this.request && this.request.deadline ? moment(this.request.deadline) : null, Validators.compose([Validators.required])],
      'deadlineTime': [null, Validators.compose([])],
      'project': [null, Validators.compose([])],
      'fileType': [null, Validators.compose([])],
    });

    if (!this.isManagerPlus) {
      this.selectableUsers = userService.getSelectablesUsers();
    } else {
      this.selectableUsers = userService.getPartnersId(this.currentUser).map(x => userService.getUserData(x));
    }

    projectsService.getProjects().then(async data => {
      let over = false;
      this.projects = [];
      Utils.loadingData = true;
      while (!over) {
        this.projects.push(...data.result);

        if (this.isManagerPlus) {
          this.projects = this.projects.filter(x => (x.owner_rel || x.projectmanager_rel) && (
            (x.owner_rel && x.owner_rel.some(y => userService.getPartnersId(this.currentUser).includes(y))) ||
            (x.projectmanager_rel && x.projectmanager_rel.some(y => userService.getPartnersId(this.currentUser).includes(y)))));

        }


        if (data.offset + data.limit < data['total-count']) {
          data = await projectsService.getProjects(data.offset + data.limit);
        } else {
          over = true;
          Utils.loadingData = false;
        }
      }
    });

  }

  /**
   * Update selected member
   * @param event Event
   */
  updateSelectedMember(event) {
    this.selectedUser = (event && event.value) ? event.value : null;
  }

  /**
   * Add new member to the array {@link managers}
   */
  addNewMember() {
    if (this.selectedUser) {
      this.selectedUser.new = true;
      this.managers.push(this.selectedUser);
    }
    if (this.teamSelect) {
      this.teamSelect.value = null;
    }
    this.selectedUser = null;
    this.canCreateTheJob();
  }

  /**
   * Remove new member to the array {@link managers}
   * @param manager Manager to delete
   */
  removeManager(manager) {
    let index = this.managers.findIndex(current => +current.id === +manager.id);
    this.managers.splice(index, 1);
    this.canCreateTheJob();
  }

  /**
   * Remove the owner
   */
  removeOwner() {
    this.owner = null;
  }

  canCreateTheJob() {
    let managers = this.managers.filter(manager => this.rolseService.isManager(manager) || this.rolseService.isOperator(manager));
    this.canCreateJob = this.managers.length > 0 && managers.length > 0 ? true : false;
  }

  /**
   * Close to dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Checks if a user exists in the array {@link managers} by its id
   * @param {string} user User ID
   * @returns {boolean} 
   */
  checkManagerId(user) {
    return (this.managers && this.managers.some(current => +current.id === +user));
  }

  /**
   * Gets the information from the form and creates a new job.
   */
  public async saveData() {

    if (this.form.valid) {
      this.creatingJob = true;

      let job: RequestJob = new RequestJob();
      job.createdByNew = +this.currentUser.id;
      job.modifiedByNew = +this.currentUser.id;
      job.workflowStep = ActionsService.JOB_WORKFLOW_PLANNED;

      if (this.form.value.name) {
        job.name = this.form.value.name;
      }

      if (this.form.value.info) {
        job.info = this.form.value.info;
      }

      if (this.form.value.priority) {
        job.priority = this.form.value.priority;
        const priority = this.priorities.find( priority => priority.key === job.priority);
        if(priority) job.priorityCntr = priority.name.toLowerCase();
      }

      if (this.owner) {
        job.owner_rel.push(+this.owner.id);
      } else {
        job.owner_rel.push(+this.currentUser.id);
      }

      if (this.managers) {
        this.managers.forEach(manager => {
          if (manager instanceof Object) {
            job.manager_rel.push(+manager.id);
          } else {
            job.manager_rel.push(+manager);
          }
        });
        job.mainManager = job.manager_rel[0];
      }

      if (this.form.value.deadline) {
        let deadline = this.form.value.deadline;
        if (!this.form.value.deadlineTime) {
          deadline.hour(this.DEFAULT_DEADLINE_HOUR);
          deadline.minute(this.DEFAULT_DEADLINE_MINUTE);
        } else {
          let deadlineTokens = this.form.value.deadlineTime.split(':');
          deadline.hour(deadlineTokens[0]);
          deadline.minute(deadlineTokens[1]);
        }
        deadline.second(this.DEFAULT_DEADLINE_SECOND);
        job.deadline = this.datepipe.transform(deadline.toDate(), 'yyyy-MM-ddTHH:mm:ss');
      }

      if (this.form.value.startDate) {
        let startDate = this.form.value.startDate;
        if (!this.form.value.startDateTime) {
          startDate.hour(this.DEFAULT_DEADLINE_HOUR);
          startDate.minute(this.DEFAULT_DEADLINE_MINUTE);
        } else {
          let startDateTokens = this.form.value.startDateTime.split(':');
          startDate.hour(startDateTokens[0]);
          startDate.minute(startDateTokens[1]);
        }
        startDate.second(this.DEFAULT_DEADLINE_SECOND);
        job.startDate = this.datepipe.transform(startDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');
      }

      if (this.request && this.request.id) {
        job.parents = [+this.request.id];
      }

      if (this.request.customerdata && this.request.customerdata.length > 0 && this.request.customerdata[0].files && this.request.customerdata[0].files.length > 0) {
        (job.customerdata[0] as any).files = this.request.customerdata[0].files;
      }

      if (this.request.costestimate && this.request.costestimate.length > 0 && this.request.costestimate[0].files && this.request.costestimate[0].files.length > 0) {
        (job.costestimate[0] as any).files = this.request.costestimate[0].files;
      }

      if (this.form.value.project) {
        job.parents = [];
        job.parents.push(+this.form.value.project);
      }

      this.requestService.createRequestJob(job).then(result => {

        if (this.request && this.request.id) {
          this.finsihProcess();
        } else {
          // UPLOAD FILE
          Utils.accordionType = this.fileTypeSelected;
          if (this.fileItem && this.isManagerPlus) {
            if (this.files && this.files.length > 0) {
              Array.from(this.files).forEach((file) => {
                this.fileItem.addFileToFolder(result[file.folder][0].id, result.domain, file, true);
              });
            }
          }
          this.finsihProcess();
        }
        this.sendNewJobEmail(result.plain())
      });
    }
  }

  /**
   * Set {@link creatingJob} to false and close dial
   */
  private finsihProcess() {
    this.creatingJob = false;
    this.dialogRef.close(true);
  }

  /**
   * If true store current time in startTime, else in endTime
   * @param {Boolean} start 
   */
  public saveCurrentTime(start: boolean = true) {

    let currentHour: number = +this.datepipe.transform(new Date(), 'HH');
    let currentMinutes: number = +this.datepipe.transform(new Date(), 'mm');
    let date: string = (start) ? 'startDate' : 'deadline';

    this.form.controls[date].patchValue(moment.utc());

    if (start) {
      this.startTime.changeHour(currentHour);
      this.startTime.changeMinute(currentMinutes);
    } else {
      this.endTime.changeHour(currentHour);
      this.endTime.changeMinute(currentMinutes);
    }
  }

  /**
   * Show a file
   *
   * @param event
   */
  public showFile(event) {
    event.preventDefault();

    if (event.dataTransfer != null && event.dataTransfer.files != null && event.dataTransfer.files.length > 0) {

      if (!this.isManagerPlus) {
        Array.from(event.dataTransfer.files).forEach(file => this.files.push(file));
      } else {
        Array.from(event.dataTransfer.files).forEach(file => {
          (file as any).folder = this.fileTypeSelected;
          this.files.push(file);
          if (!this.fileTypesUploaded.find(x => x.type === this.fileTypeSelected)) {
            this.fileTypesUploaded.push(... this.fileTypes.filter(fileType => this.fileTypeSelected === fileType.type));
          }
        });
      }
    } else if (event.srcElement != null && event.srcElement.files != null && event.srcElement.files.length > 0) {

      if (!this.isManagerPlus) {
        Array.from(event.srcElement.files).forEach(file => this.files.push(file));
      } else {
        Array.from(event.srcElement.files).forEach(file => {
          (file as any).folder = this.fileTypeSelected;
          this.files.push(file);
          if (!this.fileTypesUploaded.find(x => x.type === this.fileTypeSelected)) {
            this.fileTypesUploaded.push(...this.fileTypes.filter(fileType => this.fileTypeSelected === fileType.type));
          }
        });
      }

      event.target.value = '';
    }
  }

  /**
   * Delete a file
   *
   * @param file file to delete.
   */
  public deleteFile(file) {
    if (this.isManagerPlus && this.fileTypesUploaded.length > 0 && this.files.filter(f => f.folder == file.folder).length < 2) {
      this.fileTypesUploaded.splice(this.fileTypesUploaded.findIndex(x => x.type === file.folder), 1);
    }

    this.files.splice(this.files.indexOf(file), 1);
  }

  /**
   * Prevents the default behavior of an event
   *
   * @param event
   */
  preventDefaultEvent(event) {
    event.preventDefault();
  }

  /**
   * Assign the type of task.
   */
  public changeFileType(event) {
    this.fileTypeSelected = event.value;
  }
  private sendNewJobEmail(job){
    // Power users , production and managers must receive an email
    // Get all users of job

    let usersOfJob = [...this.managers];
    usersOfJob.push(this.owner);


    // Check if there are any roles among the users who have to receive the email.
    let usersToNotify = usersOfJob.filter(user => user.roles.includes(RolesService.PRODUCTION_ROLE) || user.roles.includes(RolesService.PROJECT_MANAGER_ROLE) || user.roles.includes(RolesService.POWER_ROLE));
    
    // Send emails to Power users , production and manager
    usersToNotify.forEach((user, index) => {
      let template;
      if(user.roles.includes(RolesService.POWER_ROLE)){
        template = AppSettings.MAIL_NEW_JOB_FROM_REQUEST_POWER_USER;
      }else{
        template = AppSettings.MAIL_NEW_JOB_FROM_REQUEST;
      }
      let mail: Mail = new Mail(template + '_' + job.id + '_' +user.id, template, + job.id, +user.id);
      this.mailService.saveMail(mail, index+1 === usersToNotify.length)
    })
    
  }

}
