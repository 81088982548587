import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { AsyncHelper, DateHelper, Gantt, PresetManager, ProjectModel, ViewPreset } from 'bryntum-gantt/gantt.module.js';
import Utils from '../../shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { TasksService } from 'app/shared/services/tasks/tasks.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { TaskTooltip } from 'bryntum-gantt';

/**
 * This component defines the gantt panel to visualization of projects, jobs and task listing
 */
@Component({
    selector: 'gantt-panel',
    template: `
      <div id="container"></div>`
})
export class PanelComponent implements OnInit {

    /**
     * The list of items to show in gantt
     */
    @Input() list;

    /**
     * The type of gantt to show (project, job or task gantt)
     */
    @Input() type;

    /**
     * THe filters to apply
     */
    @Input() filters;

    /**
     * The list of items with filters applied
     */
    filteredList = [];

    /**
     * The list of dependencies between items
     */
    dependencies = [];

    /**
     * The gantt diagram to show
     */
    gantt: Gantt;

    /**
     * Element
     */
    private elementRef: ElementRef;
    /**
     * Moment date.
     */
    private moment: any;

    // private startDate: any;
    // private endDate: any;

    /**
     * Array to check dependencies updating
     */
    static alreadyDone = [];

    /**
     * The constructor
     * 
     * @param element 
     * @param datepipe Pipe with the date pipe used to format dates.
     * @param projectsService Service with the functions related to the projects.
     * @param actionsService Service with the functions related to the actions.
     * @param tasksService Service with the functions related to the tas.
     * @param assetService  Service with the functions related to the assets
     * @param dialog Service with the functions related to the dialogs.
     * @param translateService Service with the functions related to the translations.
     */
    constructor(element: ElementRef,
        public datepipe: DatePipe,
        private projectsService: ProjectsService,
        private actionsService: ActionsService,
        private tasksService: TasksService,
        private assetService: AssetService,
        private dialog: MatDialog,
        private translateService: TranslateService) {
        this.elementRef = element;
    }

    /**
     * Initializes the component and obtains the required data.
     */
    ngOnInit(): void {
        const _this = this;
        this.moment = moment;

        const customDayPreset = new ViewPreset({
            id: 'customDayPreset',              // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'myPreset'
            name: 'Day View',        // A human-readable name provided to be used in GUI, e.i. preset picker, etc.
            tickWidth: 30,                // Time column width in horizontal mode
            tickHeight: 30,                // Time column height in vertical mode
            shiftIncrement: 1,             // Controls how much time to skip when calling shiftNext and shiftPrevious.
            shiftUnit: 'day',         // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
            // defaultSpan: 24,            // By default, if no end date is supplied to a view it will show 12 hours
            timeResolution: {              // Dates will be snapped to this resolution
                unit: 'minute',       // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
                increment: 1
            },
            headers: [      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
                {
                    unit: 'day',
                    dateFormat: 'dddd, DD.MM.YYYY'
                },
                {
                    unit: 'hour',
                    dateFormat: 'HH'
                }

            ],
            columnLinesFor: 1              // Defines header level column lines will be drawn for. Defaults to the last level.
        });

        const customWeekPreset = new ViewPreset({
            id: 'customWeekPreset',              // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'myPreset'
            name: 'Week View',        // A human-readable name provided to be used in GUI, e.i. preset picker, etc.
            tickWidth: 30,                // Time column width in horizontal mode
            tickHeight: 30,                // Time column height in vertical mode
            shiftIncrement: 1,             // Controls how much time to skip when calling shiftNext and shiftPrevious.
            shiftUnit: 'week',         // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
            // defaultSpan: 24,            // By default, if no end date is supplied to a view it will show 12 hours
            timeResolution: {              // Dates will be snapped to this resolution
                unit: 'minute',       // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
                increment: 1
            },
            headers: [      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
                {
                    unit: 'week',
                    renderer(startDate, endDate, headerConfig, cellIdx) {
                        return 'CW ' + _this.moment(startDate).week() + '(' + DateHelper.format(startDate, 'DD') + '-' + DateHelper.format(DateHelper.add(endDate, -1, 'day'), 'DD') + ')';
                    }
                },
                {
                    unit: 'day',
                    dateFormat: 'dd'
                },
                {
                    unit: 'day',
                    dateFormat: 'DD'
                }

            ],
            columnLinesFor: 1              // Defines header level column lines will be drawn for. Defaults to the last level.
        });

        const customMonthPreset = new ViewPreset({
            id: 'customMonthPreset',              // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'myPreset'
            name: 'Month View',        // A human-readable name provided to be used in GUI, e.i. preset picker, etc.
            tickWidth: 30,                // Time column width in horizontal mode
            tickHeight: 30,                // Time column height in vertical mode
            shiftIncrement: 1,             // Controls how much time to skip when calling shiftNext and shiftPrevious.
            shiftUnit: 'month',         // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
            // defaultSpan: 30,            // By default, if no end date is supplied to a view it will show 12 hours
            timeResolution: {              // Dates will be snapped to this resolution
                unit: 'minute',       // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
                increment: 1
            },
            headers: [      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
                {
                    unit: 'month',
                    dateFormat: 'MMMM,YYYY'
                },
                {
                    unit: 'week',
                    renderer(startDate, endDate, headerConfig, cellIdx) {
                        return 'CW ' + _this.moment(startDate).week();
                    }
                },
                {
                    unit: 'day',
                    dateFormat: 'DD'
                }

            ],
            columnLinesFor: 2              // Defines header level column lines will be drawn for. Defaults to the last level.
        });

        PresetManager.add(customDayPreset);
        PresetManager.add(customWeekPreset);
        PresetManager.add(customMonthPreset);

        this.applyFilters();

        // Register this widget type with its Factory
        this.gantt = new Gantt({
            appendTo: 'container',
            emptyText: '',
            showTooltip: true,
            viewPreset: 'customDayPreset',
            workingTime: {
                fromHour: 8,
                toHour: 20
            },
            toggleParentTasksOnClick: false,
            weekStartDay: 1,
            timeAxis: {
                autoAdjust: false
            },
            features: {
                /* taskTooltip: {
                    template(data) {
                        const
                            me = this,
                            { taskRecord } = data;
                        // Return the result of the feature's default template, with custom markup appended
                        return `<h4>${taskRecord.name}</h4>`;
                    }
                },*/
                taskMenu: {
                    disabled: true
                },
                taskEdit: {
                    disabled: true
                },
                timeRanges: {
                    showCurrentTimeLine: {
                        name: 'Today',
                        type: 'string'
                    }
                },
                percentBar: {
                    allowResize: false,
                    showPercentage: false,
                    disabled: true
                },
                taskDrag: {
                    showExactDropPosition: true,
                }
            },
            columns: [
                {
                    type: 'name', field: 'name', text: 'Name',
                    width: 350,
                    cls: 'gantt-field-header',
                    cellCls: 'gantt-name-field',
                    sortable: false,
                    renderer: ({ record }) => {
                        if (record.type === 'project.') {
                            return `<div class="logo-menu-btn mat-icon ng-star-inserted" role="img" svgicon="briefcase" aria-hidden="true" style="margin-right: 10px;">
                                        <svg width="100%" height="100%" viewBox="0 0 16 16" class="bi bi-briefcase" fill="currentColor" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                          <path fill-rule="evenodd" d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6h-1v6a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-6H0v6z"></path>
                                          <path fill-rule="evenodd" d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5v2.384l-7.614 2.03a1.5 1.5 0 0 1-.772 0L0 6.884V4.5zM1.5 4a.5.5 0 0 0-.5.5v1.616l6.871 1.832a.5.5 0 0 0 .258 0L15 6.116V4.5a.5.5 0 0 0-.5-.5h-13zM5 2.5A1.5 1.5 0 0 1 6.5 1h3A1.5 1.5 0 0 1 11 2.5V3h-1v-.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V3H5v-.5z"></path>
                                        </svg>
                                        </div>
                                        <a class="inherit-anchor-style" href="/projects/detail/${record.id}">${record.name}</a>
                                   </span>`;
                        }
                        if (record.type === 'order.') {
                            return `<div class="logo-menu-btn mat-icon ng-star-inserted" role="img" svgicon="briefcase" aria-hidden="true" style="margin-right: 10px;">
                                            <svg width="100%" height="100%" viewBox="0 0 16 16" class="bi bi-briefcase" fill="currentColor" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                <path fill-rule="evenodd" d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6h-1v6a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-6H0v6z"></path>
                                                <path fill-rule="evenodd" d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5v2.384l-7.614 2.03a1.5 1.5 0 0 1-.772 0L0 6.884V4.5zM1.5 4a.5.5 0 0 0-.5.5v1.616l6.871 1.832a.5.5 0 0 0 .258 0L15 6.116V4.5a.5.5 0 0 0-.5-.5h-13zM5 2.5A1.5 1.5 0 0 1 6.5 1h3A1.5 1.5 0 0 1 11 2.5V3h-1v-.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V3H5v-.5z"></path>
                                            </svg>
                                          </div>
                                         <a class="inherit-anchor-style" href="/jobs/detail/${record.id}">${record.name}</a>
                                     </span>`;
                        }
                        if (record.type.startsWith('task.')) {
                            return `<div class="logo-menu-btn mat-icon ng-star-inserted" role="img" svgicon="briefcase" aria-hidden="true" style="margin-right: 10px;">
                                             <svg width="100%" height="100%" viewBox="0 0 16 16" class="bi bi-journal-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"></path>
                                                  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"></path>\n' +
                                                  <path fill-rule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
                                              </svg>
                                      </div>
                                         <a class="inherit-anchor-style" href="/tasks/detail/${record.id}">${record.name}</a>
                                     </mat-icon>`;
                        }
                        return record.name;
                    }
                },
                {
                    type: 'aggregate',
                    text: 'Status',
                    cls: 'gantt-field-header',
                    cellCls: 'gantt-status-field',
                    htmlEncode: false,
                    sortable: false,
                    renderer: ({ record }) => record.step ? `${record.step.name}` : ``
                },
                {
                    type: 'aggregate',
                    text: 'Priority',
                    cls: 'gantt-field-header',
                    cellCls: 'gantt-status-field',
                    htmlEncode: false,
                    sortable: false,
                    renderer: ({ record }) => {
                        return record.step ? `${_this.translateService.instant('priority_ids.' + record.priorityCntr)}` : ``;
                    }
                },
                {
                    type: 'aggregate',
                    text: 'Assigned to',
                    width: 50,
                    cls: 'gantt-field-header',
                    htmlEncode: false,
                    sortable: false,
                    renderer: ({ record }) => {
                        const user = record.manager ? record.manager : record.user_targets && record.user_targets[0] ? record.user_targets[0] : null;
                        if (user !== null) {
                            if (user.mainPicture) {
                                return `<span class="circle" data-btip="${user.display_name ? user.display_name : ''}">
                                       <img src="${PanelComponent.getTeaserImage(user.mainPicture.image)}"  alt=""/>
                                     </span>`;
                            } else if (user.initials) {
                                return `<span class="circle initials" fxLayoutAlign="center center" style="place-content: center;align-items: center;display: flex;" data-btip="${user.display_name ? user.display_name : ''}">
                                       ${user.initials}
                                     </span>`;

                            }
                        }
                        return '';
                    }
                },
                {
                    type: 'aggregate',
                    text: 'Created by',
                    width: 50,
                    sortable: false,
                    cls: 'gantt-field-header',
                    htmlEncode: false,
                    renderer: ({ record }) => {
                        const user = record.owner ? record.owner : null;
                        if (user !== null) {
                            if (user.mainPicture) {
                                return `<span class="circle" data-btip="${user.display_name ? user.display_name : ''}">
                                       <img src="${PanelComponent.getTeaserImage(user.mainPicture.image)}"  alt=""/>
                                     </span>`;
                            } else if (user.initials) {
                                return `<span class="circle initials" fxLayoutAlign="center center" style="place-content: center;align-items: center;display: flex;" data-btip="${user.display_name ? user.display_name : ''}">
                                       ${user.initials}
                                     </span>`;

                            }
                        }
                        return '';
                    }
                },
                {
                    type: 'aggregate',
                    text: '',
                    width: 40,
                    sortable: false,
                    cls: 'gantt-field-header jump-bar',
                    htmlEncode: false,
                    renderer: ({ record }) => {
                        return `<div data-btip="Jump to the bar" class="pointer logo-menu-btn mat-icon ng-star-inserted" role="img" svgicon="eye" aria-hidden="true" style="margin-right: 10px;">
                                    <svg viewBox="0 0 16 16" class="bi bi-eye" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"/>
                                        <path fill-rule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                    </svg>
                                </div>`;
                    }
                }
            ],

            columnLines: false,


            // If you try approach B or C below you need to define a project here
            // project : {
            //     enableProgressNotifications : true,
            //     taskStore                   : { useRawData : true },
            //     dependencyStore             : { useRawData : true }
            // },

            async generateDataset() {
                if (_this.filteredList && Array.isArray(_this.filteredList) && _this.filteredList.length === 0) {
                    return;
                }

                const startDate = _this.list.reduce((a, b) => (a.startDate < b.startDate ? a : b)).startDate; // Min Start Date
                const endDate = _this.list.reduce((a, b) => (a.endDate > b.endDate ? a : b)).endDate;         // Max End Date

                const config = {
                    startDate: startDate,
                    endDate: endDate,
                    tasksData: _this.filteredList,
                    dependenciesData: _this.dependencies
                };
                // Required to allow browser to update DOM before calculation starts
                await AsyncHelper.sleep(10);

                //
                // Alternative approaches that should have similar performance:
                //

                // A) Replace entire project with a new project
                _this.gantt.project && _this.gantt.project.destroy();

                _this.gantt.project = new ProjectModel(Object.assign({
                    enableProgressNotifications: true,
                    taskStore: { useRawData: true },
                    dependencyStore: {
                        useRawData: true,
                        isValidDependency: function (dependencyOrFromId, toId, type) {
                            if (_this.validDependencyBeforeCreation(dependencyOrFromId, toId, type)) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                }, config));

                _this.gantt.project.on('load', (event) => {
                    _this.gantt.endDate = DateHelper.add(_this.gantt.project.endDate, +3, 'days');
                    _this.gantt.scrollToNow({ block: "start", edgeOffset: 200 });
                    _this.setWeekendInterval();

                });
            },

            tbar: [
                {
                    text: '<<',
                    ref: 'previousRef',
                    onClick() {
                        _this.onShiftPreviousClick();
                    }
                },
                {
                    text: 'Today',
                    ref: 'todayRef',
                    onClick() {
                        _this.gantt.scrollToNow({ block: "start", edgeOffset: 200 });
                    }
                }
                ,
                {
                    text: '>>',
                    ref: 'nextRef',
                    onClick() {
                        _this.onShiftNextClick();
                    }
                },

                '->',
                {
                    type: 'buttongroup',
                    toggleGroup: 'tasks',
                    items: [
                        {
                            text: 'Day',
                            pressed: true,
                            ref: 'dayRef',
                            onClick() {
                                _this.applyViewPreset('customDayPreset');
                            }
                        },
                        {
                            text: 'Week',
                            ref: 'weekRef',
                            onClick() {
                                _this.applyViewPreset('customWeekPreset');
                            }
                        },
                        {
                            text: 'Month',
                            ref: 'monthRef',
                            onClick() {
                                _this.applyViewPreset('customMonthPreset');
                            }
                        }

                    ]
                }
            ]
        });


        this.gantt.on('afterTaskDrop', async (event) => {

            if (!event.alreadyDone) PanelComponent.alreadyDone = [];

            event.source.masked = { text: 'Loading data' };

            if (event && event.taskRecords && event.taskRecords.length > 0) {
                let record = event.taskRecords[0];

                if (!PanelComponent.alreadyDone.includes(record.id)) {

                    PanelComponent.alreadyDone.push(record.id);

                    if (this.hasAdjustParentsDateByDropping(record)) {
                        this.saveByAdjustingProjectJobPeriod(record, false);
                    }

                    let difDate = event.difDate ? event.difDate : event.context.timeDiff;
                    await this.assetService.saveStartDateAndDeadlineOfAssetById(record.id, record.startDate.getTime(), record.endDate.getTime()).then(async () => {

                        record.commitAsync().then(async () => {
                            if (record.children && record.children.length > 0) {
                                record.children.forEach(x => {
                                    x.setStartDate(this.moment(x.startDate).add(difDate, 'milliseconds').toDate(), true);
                                    if (x.type === "task.milestone.") {
                                        x.setEndDate(x.startDate, false);
                                    } /*else {
                                        x.setEndDate(this.moment(x.endDate).add(difDate, 'milliseconds').toDate(), false);
                                    }*/
                                    x.commitAsync().then((y) => {
                                        this.gantt.trigger('afterTaskDrop', { alreadyDone: PanelComponent.alreadyDone, source: event.source, context: event.context, taskRecords: [x], isChild: true, isPredecessor: false, isSuccessor: false });
                                    });
                                });
                            }


                            if (record.successors && record.successors.length > 0 && !event.isChild && !event.isPredecessor) {
                                record.successors.forEach(x => {

                                    if (x.toTask && !PanelComponent.alreadyDone.includes(x.toTask.id)) {
                                        x.toTask.setStartDate(record.endDate, true);
                                        if (x.toTask.type === "task.milestone.") {
                                            x.toTask.setEndDate(record.endDate, false);
                                        } /*else {
                                            x.toTask.setEndDate(this.moment(x.toTask.endDate).add(difDate, 'milliseconds').toDate(), false);
                                        }*/
                                        x.toTask.commitAsync().then((y) => {
                                            this.gantt.trigger('afterTaskDrop', { alreadyDone: PanelComponent.alreadyDone, source: event.source, context: event.context, taskRecords: [x.toTask], isChild: false, isPredecessor: false, isSuccessor: true });
                                        });
                                    }
                                });
                            }


                            if (record.predecessors && record.predecessors.length > 0 && !event.isChild && !event.isSuccessor) {
                                record.predecessors.forEach(x => {
                                    if (x.fromTask && !PanelComponent.alreadyDone.includes(x.fromTask.id)) {
                                        // x.fromTask.manuallyScheduled = true;
                                        x.fromTask.setEndDate(record.startDate, true);
                                        if (x.fromTask.type === "task.milestone.") {
                                            x.fromTask.setStartDate(record.startDate, false);
                                        } /* else {
                                            x.fromTask.setStartDate(this.moment(x.fromTask.startDate).add(difDate, 'milliseconds').toDate(), false);
                                        }*/
                                        x.fromTask.commitAsync().then((y) => {
                                            this.gantt.trigger('afterTaskDrop', { alreadyDone: PanelComponent.alreadyDone, source: event.source, context: event.context, taskRecords: [x.fromTask], isChild: false, isPredecessor: true, isSuccessor: false });
                                        });
                                    }
                                });
                            }
                        });

                        event.source.masked = null;
                    });
                }
            }

            return true;
        });

        this.gantt.on('taskResizeEnd', async (task) => {
            task.source.masked = { text: 'Loading data' };

            if (task && task.taskRecord) {
                if (this.hasAdjustingEndDateParentsByResizing(task.taskRecord)) {
                    this.saveByAdjustingProjectJobPeriod(task.taskRecord, true);
                }

                let difDate = task.difDate ? task.difDate : this.moment(task.taskRecord.endDate).diff(this.moment(task.taskRecord.originalData.endDate));
                await this.assetService.saveStartDateAndDeadlineOfAssetById(task.taskRecord.id, task.taskRecord.startDate.getTime(), task.taskRecord.endDate.getTime()).then(async () => {
                    task.taskRecord.commitAsync().then(async () => {
                        if (task.taskRecord.children && task.taskRecord.children.length > 0) {
                            task.taskRecord.children.forEach(x => {
                                x.setStartDate(this.moment(x.startDate).add(difDate, 'milliseconds').toDate(), true);
                                if (x.type === "task.milestone.") {
                                    x.setEndDate(x.startDate, false);
                                } /*else {
                                    x.setEndDate(this.moment(x.endDate).add(difDate, 'milliseconds').toDate(), false);
                                }*/
                                x.commitAsync().then((y) => {
                                    this.gantt.trigger('taskResizeEnd', { taskRecord: x, difDate: difDate, onlyChildren: true });
                                });
                            });
                        }
                        if (task.taskRecord.successors && task.taskRecord.successors.length > 0 && !task.onlyChildren) {
                            task.taskRecord.successors.forEach(x => {
                                x.toTask.setStartDate(task.taskRecord.endDate, true);
                                if (x.toTask.type === "task.milestone.") {
                                    x.toTask.setEndDate(task.taskRecord.endDate, false);
                                } /*else {
                                    x.toTask.setEndDate(this.moment(x.toTask.endDate).add(difDate, 'milliseconds').toDate(), false);
                                }*/
                                x.toTask.commitAsync().then((y) => {
                                    this.gantt.trigger('taskResizeEnd', { taskRecord: x.toTask, difDate: difDate, onlyChildren: false });
                                });
                            });
                        }
                    });

                    task.source.masked = null;
                });
            }

            return true;
        });

        this.gantt.on('afterDependencyCreateDrop', (event) => {
            let data = event.data;
            if (data.valid) {
                let fromAsset = _this.getAssetNode(null, +data.source.id);
                let toAsset = _this.getAssetNode(null, +data.target.id);

                if (data.fromSide === 'right') {
                    data.source.nextAsset = data.target.id;
                    fromAsset.nextAsset = data.target.id;
                    data.target.previousAsset = data.source.id;
                    toAsset.previousAsset = data.source.id;
                } else {
                    data.source.previousAsset = data.target.id;
                    fromAsset.previousAsset = data.target.id;
                    data.target.nextAsset = data.source.id;
                    toAsset.nextAsset = data.source.id;
                }

                _this.updateRelatedAsset(data.source, data.target, data.fromSide);
            }
        });

        this.gantt.on('cellClick', function (event) {
            if (event.column.cls.includes('jump-bar')) {
                _this.gantt.scrollToDate(event.record.startDate, { block: "start", edgeOffset: 100, animate: true });
            }
        });

        this.gantt.generateDataset();

        // this.startDate = this.gantt.startDate;
        // this.endDate = this.gantt.endDate;
    }

    /**
     * Update the related asset
     * 
     * @param asset The asset to update
     * @param target The target related
     * @param direction The direccion of relation
     */
    private updateRelatedAsset(asset, target, direction) {
        if (direction === 'right') {
            if (asset.type.startsWith("task.")) {
                this.tasksService.saveRelatedAssets(asset.id, asset.type, target.id, null).then(x => {
                    setTimeout(() => {
                        this.tasksService.saveRelatedAssets(target.id, target.type, null, asset.id);
                    }, 100);
                });
            } else {
                this.actionsService.saveInfoCenterData(asset.id, { "nextAsset": target.id }).then(x => {
                    this.actionsService.saveInfoCenterData(target.id, { "previousAsset": asset.id });
                });
            }
        } else {
            if (asset.type.startsWith("task.")) {
                this.tasksService.saveRelatedAssets(asset.id, asset.type, null, target.id).then(x => {
                    setTimeout(() => {
                        this.tasksService.saveRelatedAssets(target.id, target.type, asset.id, null);
                    }, 100);
                });
            } else {
                this.actionsService.saveInfoCenterData(asset.id, { "previousAsset": target.id }).then(x => {
                    this.actionsService.saveInfoCenterData(target.id, { "nextAsset": asset.id });
                });
            }
        }
    }

    /**
     * Get the asset node of the general tree gantt list.
     * 
     * @param list the list where search
     * @param assetId the asset id to find
     * @returns The asset object
     */
    private getAssetNode(list, assetId) {
        let currentList = list != null ? list : this.list;
        let asset = _.find(currentList, ['id', assetId]);

        if (!asset) {
            currentList.some(element => {
                if (element.children && element.children.length > 0) {
                    asset = this.getAssetNode(element.children, assetId);
                }
                if (asset) return true;
            });
        }

        return asset;
    }

    /**
     * Validate the creation of a dependency.
     *
     * It is only allowed to create dependencies type 1 and 2, of events type 'jobs' and whose parent is the sane project.
     *
     * @param dependencyOrFromId The dependency or the event from
     * @param toId The event from
     * @param type Type of dependency. 0 StartToStart. 1 StartToEnd. 2 EndToStart. 3 EndToEnd
     * @returns true if its valid, false in other case.
     */
    private validDependencyBeforeCreation(dependencyOrFromId, toId, type) {
        if (type === 0 || type === 3) return false;
        if (dependencyOrFromId.id === toId.id) return false;
        if (this.isAlreadyConnected(dependencyOrFromId, toId, type)) return false;
        if (!this.isJobOrTaskRelation(dependencyOrFromId, toId)) return false;
        if (!this.assetsWithSameParent(dependencyOrFromId, toId)) return false;
        return true;
    }

    /**
     * Check if bars to connect are already connected to each other or to others.
     * 
     * @param fromId The from gantt bar to connect
     * @param toId The to gantt bar to connect
     * @param type Type of dependency. 0 StartToStart. 1 StartToEnd. 2 EndToStart. 3 EndToEnd
     */
    private isAlreadyConnected(fromId, toId, type) {
        if (type === 1 && (fromId.originalData.previousAsset || toId.originalData.nextAsset)) return true;
        if (type === 2 && (fromId.originalData.nextAsset || toId.originalData.previousAsset)) return true;
        if (fromId.originalData.previousAsset && fromId.originalData.next && fromId.originalData.previousAsset === fromId.originalData.next) return true;

        return false;
    }

    /**
     * Check if two assets have the same parent
     *
     * @param fromId First asset to check
     * @param toId Second asset to check
     * @returns true if both assets have the same parent, false in other case.
     */
    private assetsWithSameParent(fromId, toId) {
        if (this.hasNoGanttParent(fromId) && this.hasNoGanttParent(toId) && fromId.parents[0].id === toId.parents[0].id) {
            return true;
        }
        if (this.type === "actions" && fromId.parent && toId.parent && fromId.parent.id === toId.parent.id) {
            return true;
        }
        if (this.type === "projects" && fromId.parent && toId.parent && fromId.parent.id === toId.parent.id) {
            return true;
        }

        return false;
    }

    /**
     * Check if both assets are of type "jobs" or "task"
     *
     * @param fromData
     * @param target
     * @returns
     */
    private isJobOrTaskRelation(fromData, target) {
        return ((fromData.type === 'order.' && fromData.type === target.type) ||
            (fromData.type.startsWith('task.') && target.type.startsWith('task.')));
    }

    /**
     * Check whether the start or end date of parent(s) of a record (project, job or task) should be adjusted
     * after the record start or end date has been changed by dropping
     * 
     * @param record The context with record, dates and parents info
     * @returns True if adjustment is possible, false in other case
     */
    private hasAdjustParentsDateByDropping(record) {
        if (this.hasNoGanttParent(record)) {
            let parent = record.parents[0];
            if ((new Date(parent.startDate) > record.startDate) ||
                (new Date(parent.endDate) < record.endDate)) return true;

            let granddad = parent.parents && parent.parents.length > 0 ? parent.parents[0] : null;
            if ((granddad && new Date(granddad.startDate) > record.startDate) ||
                (granddad && new Date(granddad.endDate) < record.endDate)) return true;
        }

        if (this.type === "actions" && record.type.startsWith('task.') && record.parent && !record.parent.isRoot) {
            let parent = record.parent;
            if ((parent.originalData.startDate > record.startDate) ||
                (parent.originalData.endDate < record.endDate)) return true;

            let granddad = parent.parents && parent.parents.length > 0 ? parent.parents[0] : null;
            if ((granddad && new Date(granddad.startDate) > record.startDate) ||
                (granddad && new Date(granddad.endDate) < record.endDate)) return true;
        }

        if (this.type === "projects" && record.parent && !record.parent.isRoot) {
            let parent = record.parent;
            if ((parent.originalData.startDate > record.startDate) ||
                (parent.originalData.endDate < record.endDate)) return true;

            let granddad = parent.parent;
            if ((granddad && !granddad.isRoot && granddad.originalData.startDate > record.startDate) ||
                (granddad && !granddad.isRoot && granddad.originalData.endDate < record.endDate)) return true;
        }

        return false;
    }

    /**
     * Check whether the end date of parent(s) of a record (project, job or task) should be adjusted
     * after the record end date has been changed by resizing
     * 
     * @param record The record with end date changed
     * @returns True if adjustment is possible, false in other case
     */
    private hasAdjustingEndDateParentsByResizing(record) {
        if (this.hasNoGanttParent(record)) {
            let parent = record.parents[0];
            if (new Date(parent.endDate) < record.endDate) return true;
            let granddad = parent.parents && parent.parents.length > 0 ? parent.parents[0] : null;
            if (granddad && new Date(granddad.endDate) < record.endDate) return true;
        }

        if (this.type === "actions" && record.type.startsWith('task.') && record.parent && !record.parent.isRoot) {
            let parent = record.parent;
            if (parent.endDate < record.endDate) return true;
            let granddad = parent.parents && parent.parents.length > 0 ? parent.parents[0] : null;
            if (granddad && new Date(granddad.endDate) < record.endDate) return true;
        }

        if (this.type === "projects" && record.parent && !record.parent.isRoot) {
            let parent = record.parent;
            if (parent.endDate < record.endDate) return true;
            if (parent.parent && !parent.parent.isRoot && parent.parent.endDate < record.endDate) return true;
        }

        return false;
    }

    /**
     * Check if a record has parents on the gantt panel. If parents are in the
     *
     * @param record The record (project, job or task) to check
     * @returns True if the record has parents in the gantt panel False if has no parents or if has parents only in its structure
     */
    private hasNoGanttParent(record) {
        return ((this.type === "tasks" || (this.type === "actions" && record.type === 'order.'))
            && record.parents && record.parents.length > 0);
    }

    /**
     * Open a Confirmation dialog and save (if confirmed) the record and its parents with the date
     * 
     * @param record The record with the new end date.
     */
    private saveByAdjustingProjectJobPeriod(record, isResized) {
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: 'adjustProjectPeriod'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (isResized) {
                    this.saveByAdjustingAfterResize(record);
                } else {
                    this.saveByAdjustingAfterDrop(record);
                }
            }
            dialogRef = null;
        });
    }

    /**
     * Save the new record end date to its parents
     * 
     * @param record The record with the new date
     */
    private saveByAdjustingAfterResize(record) {
        if (this.hasNoGanttParent(record)) {
            let parent = record.parents[0];
            if (new Date(parent.endDate) < record.endDate) this.saveRecordEndDateAdjusted(parent, record.endDate);
            let granddad = parent.parents && parent.parents.length > 0 ? parent.parents[0] : null;
            if (granddad && new Date(granddad.endDate) < record.endDate) this.saveRecordEndDateAdjusted(granddad, record.endDate);
        }
        if (this.type === "actions" && record.type.startsWith('task.') && record.parent && !record.parent.isRoot) {
            let parent = record.parent;
            if (parent.endDate < record.endDate) {
                this.saveRecordEndDateAdjusted(parent, record.endDate);
                parent.setEndDate(record.endDate, false);
            }
            let granddad = parent.parents && parent.parents.length > 0 ? parent.parents[0] : null;
            if (granddad && new Date(granddad.endDate) < record.endDate) this.saveRecordEndDateAdjusted(granddad, record.endDate);
        }
        if (this.type === "projects" && record.parent && !record.parent.isRoot) {
            let granddad = record.parent.parent;
            if (record.parent.endDate < record.endDate) {
                this.saveRecordEndDateAdjusted(record.parent, record.endDate);
                record.parent.setEndDate(record.endDate, false);
            }
            if (granddad && !granddad.isRoot && granddad.endDate < record.endDate) {
                this.saveRecordEndDateAdjusted(granddad, record.endDate);
                granddad.setEndDate(record.endDate, false);
            }
        }
    }

    /**
     * Save the new record dates to its parents
     * 
     * @param record The record with the new date
     */
    private saveByAdjustingAfterDrop(record) {
        if (this.hasNoGanttParent(record)) {
            let parent = record.parents[0];
            if (new Date(parent.startDate) > record.startDate) this.saveRecordStartDateAdjusted(parent, record.startDate);
            if (new Date(parent.endDate) < record.endDate) this.saveRecordEndDateAdjusted(parent, record.endDate);

            let granddad = parent.parents && parent.parents.length > 0 ? parent.parents[0] : null;
            if (granddad && new Date(granddad.startDate) > record.startDate) this.saveRecordStartDateAdjusted(granddad, record.startDate);
            if (granddad && new Date(granddad.endDate) < record.endDate) this.saveRecordEndDateAdjusted(granddad, record.endDate);
        }

        if (this.type === "actions" && record.type.startsWith('task.') && record.parent && !record.parent.isRoot) {
            let parent = record.parent;
            if (parent.originalData.startDate > record.startDate) {
                this.saveRecordStartDateAdjusted(parent, record.startDate);
                parent.setStartDate(record.startDate, false);
            }
            if (parent.originalData.endDate < record.endDate) {
                this.saveRecordEndDateAdjusted(parent, record.endDate);
                parent.setEndDate(record.endDate, false);
            }

            let granddad = parent.parents && parent.parents.length > 0 ? parent.parents[0] : null;
            if (granddad && new Date(granddad.startDate) > record.startDate) {
                this.saveRecordStartDateAdjusted(granddad, record.startDate);
            }
            if (granddad && new Date(granddad.endDate) < record.endDate) {
                this.saveRecordEndDateAdjusted(granddad, record.endDate);
            }
        }

        if (this.type === "projects" && record.parent && !record.parent.isRoot) {
            let granddad = record.parent.parent;
            if (record.parent.originalData.startDate > record.startDate) {
                this.saveRecordStartDateAdjusted(record.parent, record.startDate);
                record.parent.setStartDate(record.startDate, false);
            }
            if (record.parent.originalData.endDate < record.endDate) {
                this.saveRecordEndDateAdjusted(record.parent, record.endDate);
                record.parent.setEndDate(record.endDate, false);
            }

            if (granddad && !granddad.isRoot && granddad.originalData.startDate > record.startDate) {
                this.saveRecordStartDateAdjusted(granddad, record.startDate);
                granddad.setStartDate(record.startDate, false);
            }
            if (granddad && !granddad.isRoot && granddad.originalData.endDate < record.endDate) {
                this.saveRecordEndDateAdjusted(granddad, record.endDate);
                granddad.setEndDate(record.endDate, false);
            }
        }
    }

    /**
     * Save the new start date of a specific asset (record) depending on its type
     * 
     * @param record The asset
     * @param date The new start date to be saved
     */
    private saveRecordStartDateAdjusted(record, date) {
        let startDate = this.datepipe.transform(date, 'yyyy-MM-ddTHH:mm:ss');

        if (record.type.startsWith('task.')) this.tasksService.saveStartDate(record.id, record.type, startDate);
        if (record.type === 'order.') this.actionsService.saveStartDate(record.id, startDate + 'Z');
        if (record.type === 'project.') this.projectsService.saveStartDate(record.id, startDate);
    }

    /**
     * Save the new deadline of a specific asset (record) depending on its type
     * 
     * @param record The asset
     * @param date The new deadline to be saved
     */
    private saveRecordEndDateAdjusted(record, date) {
        let endDate = this.datepipe.transform(date, 'yyyy-MM-ddTHH:mm:ss');

        if (record.type.startsWith('task.')) this.tasksService.saveDeadline(record.id, record.type, endDate);
        if (record.type === 'order.') this.actionsService.saveDeadline(record.id, endDate + 'Z');
        if (record.type === 'project.') this.projectsService.saveDeadline(record.id, endDate);
    }

    /**
     * Save the new deadline of a specific asset (record) depending on its type
     * 
     * @param record The asset
     * @param date The new deadline to be saved
     */
    private async saveRecordStartDateAndEndDateAdjusted(record, startDate, endDate) {
        let start = this.datepipe.transform(startDate, 'yyyy-MM-ddTHH:mm:ss');
        let end = this.datepipe.transform(endDate, 'yyyy-MM-ddTHH:mm:ss');

        if (record.type.startsWith('task.')) return await this.tasksService.saveStartDateAndDeadline(record.id, record.type, start, end);
        if (record.type === 'order.') return await this.actionsService.saveStartDateAndDeadline(record.id, start + 'Z', end + 'Z');
        if (record.type === 'project.') return await this.projectsService.saveStartDateAndDeadline(record.id, start, end);
    }

    /**
     * Set the weekend interval as non working days
     */
    private setWeekendInterval() {
        const calendar = this.gantt.project.defaultCalendar;

        calendar.addInterval({
            recurrentStartDate: 'on Sat at 0:00',
            recurrentEndDate: 'on Mon at 0:00',
            isWorking: false
        });

        calendar.addInterval({
            recurrentStartDate: 'every weekday at 20:00',
            recurrentEndDate: 'every weekday at 08:00',
            isWorking: false
        });

        


        this.gantt.project.taskStore.allRecords.forEach(t => {
            t.setDuration(calendar.calculateDurationMs(t.originalData.startDate, t.originalData.endDate) / 1000 / 60 / 60 / 24);
            t.setStartDate(this.moment(t.originalData.startDate), false);
            t.setEndDate(this.moment(t.originalData.endDate), false);
        });
    }

    /**
     * Set a different view on the gantt panel (day, week, month)
     *
     * @param view The view to set
     */
    private applyViewPreset(view) {
        this.gantt.viewPreset = view;
    }

    private static getTeaserImage(imageUrl) {
        let teaserUrl = imageUrl + '/teaser';
        const patt = '/entity/.*?/';
        const result = teaserUrl.match(patt);
        if (result && result[0] && teaserUrl.indexOf('/entity/recommendation') < 0) {
            teaserUrl = teaserUrl.replace(result[0], '/entity/view/');
        }
        teaserUrl = Utils.replaceHttp(teaserUrl);
        return teaserUrl;
    }

    /**
     * Moves horizontally the gantt view one unit time (day, week, month) to the left
     */
    onShiftPreviousClick() {
        this.gantt.shiftPrevious();
    }

    /**
     * Moves horizontally the gantt view one unit time (day, week, month) to the right
     */
    onShiftNextClick() {
        this.gantt.shiftNext();
    }

    /**
     * Apply the filters imposed in the Gantt panel
     */
    applyFilters() {
        this.filteredList = this.list;
        this.dependencies = [];

        if (this.filters) {
            this.filters.forEach(filter => {
                if (filter.value) {
                    if (filter.name == 'text' || filter.name == 'textArray') {

                        const textValues = filter.name == 'text' ? [filter.value] : filter.value;

                        textValues.forEach(textValue => {
                            this.filteredList = this.filteredList.filter(x =>
                                (x.item_name && x.item_name.toLowerCase().indexOf(textValue.trim().toLowerCase()) != -1)
                                || (x.name && x.name.toLowerCase().indexOf(textValue.trim().toLowerCase()) != -1)
                                || +x.id === +textValue
                                || (x.tags_data && x.tags_data.length > 0 && (x.tags_data.some(y => y.name && y.name.toLowerCase().includes(textValue.toLowerCase()))))
                            );
                        });

                    }

                    if (filter.name == 'name') this.filteredList = this.filteredList.filter(x => x.name.toLowerCase().indexOf(filter.value.trim().toLowerCase()) != -1);
                    if (filter.name == 'item_name') this.filteredList = this.filteredList.filter(x => x.item_name.toLowerCase().indexOf(filter.value.trim().toLowerCase()) != -1);
                    if (filter.name == 'owner') this.filteredList = this.filteredList.filter(x => x.owner && x.owner.id == filter.value.id);
                    if (filter.name == 'pm') this.filteredList = this.filteredList.filter(x => x.pm && x.pm.id == filter.value.id);
                    if (filter.name == 'start') this.filteredList = this.filteredList.filter(x => x.startDate == filter.value);
                    if (filter.name == 'end') this.filteredList = this.filteredList.filter(x => x.finishDate == filter.value);
                    if (filter.name == 'progress') this.filteredList = this.filteredList.filter(x => x.step && x.step.completion_percentage == filter.value);
                    if (filter.name == 'projects') this.filteredList = this.filteredList.filter(x => x.parents && x.parents.indexOf(filter.value.id) > -1);
                    if (filter.name == 'step') this.filteredList = this.filteredList.filter(x => +x.step.wf_id == +filter.value.wf_id && +x.step.wf_step == +filter.value.wf_step);
                    if (filter.name == 'indicator') this.filteredList = this.filteredList.filter(x => x.indicator == filter.value);
                    if (filter.name == 'type') this.filteredList = this.filteredList.filter(x => x.type.trim().toLowerCase() == filter.value.trim().toLowerCase());
                    if (filter.name == 'status' && typeof filter.value == 'string') this.filteredList = this.filteredList.filter(x => x.status.trim().toLowerCase() == filter.value.trim().toLowerCase());
                    if (filter.name == 'status' && !(typeof filter.value == 'string')) this.filteredList = this.filteredList.filter(x => x.status && x.status.id == filter.value.id);
                    if (filter.name == 'role') this.filteredList = this.filteredList.filter(x => x.role.toLowerCase().indexOf(filter.value.trim().toLowerCase()) != -1);

                    if (filter.name == 'rawType') this.filteredList = this.filteredList.filter(x => x.rawType.trim().toLowerCase() == filter.value.trim().toLowerCase());
                    if (filter.name == 'file') this.filteredList = this.filteredList.filter(x => x.file.trim().toLowerCase() == filter.value.trim().toLowerCase());

                    // if (filter.name == 'category') this.filteredList = this.filteredList.filter(x => x.subcategory && filter.value.value_key && x.subcategory.trim().toLowerCase().includes(filter.value.value_key.trim().toLowerCase()));

                    if (filter.name == 'category') {
                        this.filteredList = this.filteredList.filter(x => x.subcategory && filter.value.filter(y => y['value_key']).every(y => x.subcategory.some(z => z.includes(y['value_key']))));
                    }

                    if (filter.name == 'channel') {
                        this.filteredList = this.filteredList.filter(x => x.channels && filter.value.filter(y => y['value_key']).every(y => x.channels.includes(y['value_key'])));
                    }

                    if (filter.name == 'antrieb') this.filteredList = this.filteredList.filter(x => x.antrieb && filter.value.value_key && x.antrieb.trim().toLowerCase().includes(filter.value_key.trim().toLowerCase()));
                    // if (filter.name == 'trim') this.filteredList = this.filteredList.filter(x => x.trim && filter.value.value_key && x.trim.trim().toLowerCase().includes(filter.value.value_key.trim().toLowerCase()));
                    if (filter.name == 'trim') {
                        this.filteredList = this.filteredList.filter(x => x.trim && filter.value.filter(y => y['value_key']).every(y => x.trim.includes(y['value_key'])));
                    }

                    // if (filter.name == 'brand' || filter.name == 'model' || filter.name == 'sondermodelle') this.filteredList = this.filteredList.filter(x => x.brand && filter.value.value_key && x.brand.trim().toLowerCase().includes(filter.value.value_key.trim().toLowerCase()));
                    if (filter.name == 'brand' || filter.name == 'model' || filter.name == 'sondermodelle') {
                        this.filteredList = this.filteredList.filter(x => x.brand && filter.value.value_key && x.brand.some(y => y.includes(filter.value.value_key.trim())));
                    }

                    if (filter.name == 'startDate') {
                        if (filter.value.from) this.filteredList = this.filteredList.filter(x => x.startDate && x.startDate >= filter.value.from.toDate());
                        if (filter.value.to) this.filteredList = this.filteredList.filter(x => x.startDate && x.startDate <= filter.value.to.toDate());
                    }

                    if (filter.name == 'deadline') {
                        if (filter.value.from) this.filteredList = this.filteredList.filter(x => x.endDate && x.endDate >= filter.value.from.toDate());
                        if (filter.value.to) this.filteredList = this.filteredList.filter(x => x.endDate && x.endDate <= filter.value.to.toDate());
                    }

                    if (filter.name == 'priorityCntr') this.filteredList = this.filteredList.filter(x => x.priorityCntr && x.priorityCntr == filter.value);

                    if (filter.name == 'valid_until') {
                        let newValidUntil: Date | string = new Date((<any>(filter.value)));
                        newValidUntil.setDate(newValidUntil.getDate());
                        newValidUntil = newValidUntil.toLocaleString('en-GB').substring(0, 10).split('/').reverse().join('-');
                        this.filteredList = this.filteredList.filter(x => x.valid_until && (x.valid_until.localeCompare(newValidUntil) >= 0));
                    }

                    if (filter.name == 'created') {
                        let newCreated: Date | string = new Date((<any>(filter.value)));
                        newCreated.setDate(newCreated.getDate());
                        newCreated = newCreated.toLocaleString('en-GB').substring(0, 10).split('/').reverse().join('-');
                        this.filteredList = this.filteredList.filter(x => x.created && x.created === newCreated /* (x.created.localeCompare(newCreated) >= 0) */);
                    }

                    // Team Member filtering
                    if (filter.name == 'team') {

                        this.filteredList = this.filteredList.filter(x => (!x.owner && !x.manager && !x.user_targets) ||
                            (x.owner_ids && x.owner_ids.includes(filter.value.id)) ||
                            (x.manager_ids && x.manager_ids.includes(filter.value.id)) ||
                            (x.user_targets && x.user_targets.filter(y => y).map(y => y.id).includes(filter.value.id)));
                    }
                }
            });
        }

        this.filteredList.forEach(x => this.getDependencies(x, true));
    }

    /**
     * Obtain the dependencies of an asset (and its children) to set in the gantt panel depending on the filters.
     * 
     * @param asset The asset to obtain its dependencies and those of its children
     * @param isRoot Flag that indicates: True if is root in gantt panel, false if it's a child
     */
    getDependencies(asset, isRoot) {
        if (!isRoot || (isRoot && asset.previousAsset && this.filteredList.find(x => x.id === asset.previousAsset))) {
            this.dependencies.push({ fromTask: asset.previousAsset, toTask: asset.id });
        }
        if (asset.children && asset.children.length > 0) asset.children.forEach(x => this.getDependencies(x, false));
    }
}
