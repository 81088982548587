/**
 * This class defines the MsTeamsMessageCard structure
 */
export class MsTeamsMessageCard {

    /* Private */
    /**
     * Message (MsTeamsMessageCard) configuration for a job 
     */
    private basePotentialActionObject = {
        '@type': 'OpenUri',
        'name': '',
        'targets': [
            {
                'os': 'default',
                'uri': ''
            }
        ]
    };
    /**
     * Basic message configuration
     */
    private baseObject = {
        '@context': 'https://schema.org/extensions',
        '@type': 'MessageCard',
        'themeColor': '',
        'title': '',
        'text': '',
        'potentialAction': []
    }

    /* Public */
    /**
     * ThemeColo of MsTeamsMessageCard
     */
    themeColor: string ;
    /**
     * Title of MsTeamsMessageCard
     */
    title: string;
    /**
     * Text of MsTeamsMessageCard
     */
    text: string;

    /**
     * link label of MsTeamsMessageCard
     */
    linkLabel: string;
    /**
     * link of MsTeamsMessageCard
     */
    link: string;
    /**
     * The constructor
     * @param title title of MsTeamsMessageCard 
     * @param text text of MsTeamsMessageCard 
     * @param linkLabel linkLabel of MsTeamsMessageCard 
     * @param link link of MsTeamsMessageCard 
     * @param themeColor themeColor of MsTeamsMessageCard 
     */
    constructor(title='', text='', linkLabel='', link='', themeColor='') {
        this.themeColor = themeColor;
        this.title = title;
        this.text = text;
        this.linkLabel = linkLabel;
        this.link = link;
    }
    /**
     * Sets the object of the message
     * @returns the message object in JSON format
     */
    getRequest() {
        if (this.themeColor) {
            this.baseObject.themeColor = this.themeColor;
        } else {
            delete this.baseObject.themeColor;
        }

        if (this.title) {
            this.baseObject.title = this.title;
        } else {
            delete this.baseObject.title;
        }

        if (this.text) {
            this.baseObject.text = this.text;
        } else {
            delete this.baseObject.text;
        }

        if (this.link && this.linkLabel) {

            this.basePotentialActionObject.name = this.linkLabel;
            this.basePotentialActionObject.targets.forEach(x => x.uri = this.link);

            this.baseObject.potentialAction = [];
            this.baseObject.potentialAction.push(this.basePotentialActionObject);

        } else {
            delete this.baseObject.potentialAction;
        }

        return JSON.stringify(this.baseObject);
    }
}