import { AppSettings } from "app/shared/app.settings";
import { UserService } from "app/shared/services/user/user.service";
/**
 * This class defines the auth user structure
 */
export class AuthUser {
    /**
     * id of user 
     */
    id: number;
    /**
     * type of auth user
     */
    type: string;
    /**
     * domain of auth user
     */
    domain: string = AppSettings.DOMAIN;
    /**
     * domain2 of auth user
     */
    domain2: string = AppSettings.DOMAIN2;
    /**
     * email of auth user
     */
    email: string;
    /**
     * name of auth user
     */
    name: string;
    /**
     * gender of auth user
     */
    gender: string;
    /**
     * first Name of auth user
     */
    firstName: string;
    /**
     * last Name of auth user
     */
    lastName: string;
    /**
     * agency main contact of auth user
     */
    agencyMainContact: number[];
    /**
     * agency where auth user works
     */
    agencyEmployee: number[];
    /**
     * position of auth user
     */
    position: string;
    /**
     * work Message of auth user
     */
    workMessage: string;
    /**
     * encoded Password of auth user
     */
    encodedPassword: string;
    /**
     * language of auth user
     */
    language: string = 'en';
    /**
     * {@link Auth} of auth user
     */
    auth: Auth;
    /**
     * email Struct of auth user
     */
    emailStruct: EmailStruct;
    /**
     * password Recovery {@link GeneratedKey}
     */
    passwordRecovery: GeneratedKey;
    /**
     * confirmation {@link GeneratedKey}
     */
    confirmation: GeneratedKey;
    /**
     * roles of auth user
     */
    roles: string[] = [];
    /**
     * roles granted of auth user
     */
    rolesGranted: string[] = [];
    /**
     * flag that indicates the user status
     */
    active: boolean = false;
    ///activatedEmailSent : boolean = false;
    /**
     * workflow of auth user
     */
    workflow: number = AppSettings.WORKFLOW_WEBUSER;
    /**
     * workflow Step of auth user
     */
    workflowStep: number = UserService.WORKFLOW_WEBUSER_STEP_NOT_CONFIRMED;
    /**
     * true if the user is the main contact of the company
     */
    companyMainContact: boolean = false;
    /**
     * User Company
     */
    company: string;
    /**
     * The contact person of the user company
     */
    companyContact: string;
}
/**
 * This class defines the Auth structure
 */
export class Auth {
    /**
     * type of Auth
     */
    type: string = 'website';
    /**
     * email of Auth
     */
    login: string
    /**
     * password of Auth
     */
    password: string;
}
/**
 * This class defines the Email struct
 */
export class EmailStruct {
    /**
     * type of EmailStruct
     */
    type: string = 'work';
    /**
     * email of EmailStruct
     */
    email: string;
}
/**
 * This class defines the GeneratedKey
 */
export class GeneratedKey {
    /**
     * kay of GeneratedKey
     */
    key: string;
    /**
     * timestamp of GeneratedKey
     */
    timestamp: string;
}