import { Component, Input, OnInit } from '@angular/core';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { UserService } from 'app/shared/services/user/user.service';
import Utils from 'app/shared/utils/utils';

/**
 * @ignore
 */
@Component({
  selector: 'app-projects-widget',
  templateUrl: './projects-widget.component.html',
  styleUrls: ['./projects-widget.component.scss']
})
export class ProjectsWidgetComponent implements OnInit {

  /**
   * The projects list. May come empty
   */
  @Input() projects;

  /**
   * The title of widget according to listing displayed
   */
  @Input() label;

  /**
   * The footer text (link) of widget according to listing displayed
   */
  @Input() footerText = 'all_projects';

  /**
   * Different params depending on listing to display
   */
  @Input() queryParams = {};

  /**
   * Amount of items to show
   */
  @Input() maxDisplay = 10;

  utils = Utils;

  currentUser;
  projectslist = [];
  list = [];

  constructor(
    private usersService: UserService,
    private projectsService: ProjectsService
  ) { }

  /**
   * Initialize the listing to display, depending on projects input is empty or not
   */
  ngOnInit() {
    this.currentUser = this.usersService.getCurrentUser();

    if (!this.projects) {
      this.projectsService.getProjects().then(async data => {
        this.projectslist = [];
        let over = false;
        Utils.loadingData = true;
        while (!over) {
          this.projectslist.push(...this.projectsService.fillData(data.result));

          if (data.offset + data.limit < data['total-count']) {
            data = await this.projectsService.getProjects(data.offset + data.limit);
          } else {
            over = true;
            Utils.loadingData = false;
            this.sorting(1);
          }
        }
      });
    } else {
      this.projectslist = this.projects;
      Utils.loadingData = false;
      this.sorting(1);
    }
  }

  /**
   * Sort the jobs list according to the the option
   * 
   * @param option The option to be sorted. 1: by date. 2: by priority. 3: by name
   */
  sorting(option) {
    switch (option) {
      case 1:
        this.list = this.projectslist.sort((a, b) => new Date(b.createdAttr).getTime() - new Date(a.createdAttr).getTime());
        break;
      case 2:
        this.list = this.projectslist.sort((a, b) => a.priority - b.priority);
        break;
      case 3:
        this.list = this.projectslist.sort((a, b) => a.name.localeCompare(b.name));
        break;
    }
  }

}
