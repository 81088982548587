import { Injectable } from '@angular/core';
import { OCService } from '../satellites/oc.service';
import { LiquidCache } from 'ngx-liquid-cache';
import Utils from 'app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';

/**
 * Service defined to operate with {@link Mimetypes}.
 */
@Injectable({
  providedIn: 'root'
})
export class MimetypeService {

  /**
   * Array of mimetypes.
   */
  private mimetypes = [];

  /**
   * Constructor.
   * @param ocService 
   * @param translateService Service with the functions related to the translations.
   */
  constructor(
    private ocService: OCService,
    private translateService: TranslateService
  ) {
    this.getMimetypes().subscribe (x => this.mimetypes = x);
  }

  /**
   * Get mimetypes
   * @returns Mimetype list.
   */
  @LiquidCache('mimetypes')
  getMimetypes() {
    let url : string = 'tables/mimetype';
    if (this.translateService.currentLang){
      url += "?lang=" + this.translateService.currentLang;
    }
    return this.ocService.get().all(url).getList();
  }

  getActualMimetypes() {
    return this.mimetypes;
  }

  /**
   * Get mimetype by mimetype
   * @param mime Mimetype
   * @returns Mimetype
   */
  getMimetypeByMimetype (mime) {
    return this.mimetypes.find(mimetype => mimetype.mimetype === mime);
  }

  /**
   * Get mimetype by extension.
   * @param extension Extesion.
   * @returns Mimetype
   */
  getMimetypeByExtension (extension) {
    return this.mimetypes.find(mimetype => mimetype.extension === extension);
  }

  /**
   * Get file mimetype.
   * @param {Asset} newAsset Asset
   * @returns Mimetype.
   */
  getFileMimetype(newAsset) {
    let mime = newAsset.type;
    let type = this.getMimetypeByMimetype(mime);
    let extension = Utils.getFileExtension(newAsset.name);

    if (!type && extension) {
       type = this.getMimetypeByExtension(extension);
    }

    return type;
  }
}
