import { Component, OnInit, Input, Output, EventEmitter, DoCheck } from '@angular/core';
import { setTimeout } from 'timers';
import { TrackingService } from 'app/shared/services/tracking/tracking.service';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { MimetypeService } from 'app/shared/services/mimetype/mimetype.service';
import { DatePipe } from '@angular/common';
import Utils from 'app/shared/utils/utils';
import { async } from '@angular/core/testing';
import { InfoDialogComponent } from 'app/shared/dialogs/info/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CompareDialogComponent } from 'app/shared/dialogs/compare/compare-dialog.component';
import { SearchDialogComponent } from 'app/shared/dialogs/search/search-dialog.component';
import { SearchService } from 'app/shared/services/search/search.service';

/**
 * @ignore
 */
@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit {

  @Input() filters = [];
  @Input() showBulk = true;
  @Input() bulkDownload = true;
  @Input() bulkCompare = false;
  @Input() showSearchText = true;

  @Input() facetsSearch = false;
  @Input() facets: string[] = [];

  @Input() list = [];
  @Output() bulkChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filterChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() searchFacetsChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchByQuery: EventEmitter<any> = new EventEmitter<any>();



  static readonly ZIP_NAME: string = "Materials";

  bulkEnabled: boolean = false;
  //downloading = [];
  isDownload = false;

  private lastKeyword: string = '';
  public format: string;
  private jszip: JSZip;

  constructor(private trackingService: TrackingService,
    private assetService: AssetService,
    private mimetypeService: MimetypeService,
    public datepipe: DatePipe,
    public dialog: MatDialog, private searchService: SearchService) { }

  ngOnInit() { }

  getChecked() {
    return this.list.filter(x => x.checked).length;
  }

  getFilterValue(name) {
    if (this.filters.find(x => x.name == name)) {
      return this.filters.find(x => x.name == name).value;
    }
    return null;
  }

  addFilter(filterName, value) {
    if (filterName === 'text') {
      setTimeout(() => {
        var value = this.filters.filter(filter => filter.name == filterName)[0].value;
        if (value != null && value.length > 3 && value !== this.lastKeyword) {
          this.lastKeyword = value;
          this.trackingService.sendMatomoSearch(value);
        }
      }, 5000);
    }

    this.filters.filter(filter => filter.name == filterName)[0].value = value;
    this.filterChanged.emit();
  }

  resetFilter(filterName) {
    this.filters.filter(filter => filter.name == filterName)[0].value = null;
    this.filterChanged.emit();
  }


  resetFilters() {
    this.filters.forEach(filter => filter.value = null);
    this.filterChanged.emit();
  }

  getItemValue(item, name) {
    let result = item;
    if (!(item !== Object(item))) {
      result = this.filters.find(x => x.name == name).values.find(x => x.id == item.id);
    }

    return result;
  }

  showResetFilter() {
    return this.filters.filter(filter => filter.value != null).length > 0;
  }

  changeBulkOptions() {
    this.bulkEnabled = !this.bulkEnabled;
    this.bulkChanged.emit(this.bulkEnabled);
  }

  downloadSelection() {
    let checkedList = this.list.filter(x => x.checked);
    let files = 0;
    //this.isDownload = true;
    //this.downloading = checkedList;

    if (checkedList && checkedList.length > 0) {
      if (checkedList.length > 1) {
        this.jszip = new JSZip();
      }

      checkedList.forEach((x, index) => {
        setTimeout(() => {
          this.assetService.getFileBlob(x.downloadLink + '?r=' + Utils.randomHash()).subscribe(fileData => {
            const blob = new Blob([fileData], { type: x.mimetype });
            const mimetype = this.mimetypeService.getMimetypeByMimetype(x.mimetype);

            if (checkedList.length > 1) {
              this.jszip.file(x.name.replace(/\.[^/.]+$/, '') + (mimetype ? mimetype.extension : ''), blob);
              files++;

              if (checkedList.length === files) {
                this.jszip.generateAsync({ type: "blob" }).then(content => {
                  let date = this.datepipe.transform(new Date(), 'yyyyMMdd_hhmm');
                  saveAs(content, TableFiltersComponent.ZIP_NAME + date + '.zip');
                });
              }

            } else {
              saveAs(blob, x.name.replace(/\.[^/.]+$/, '') + (mimetype ? mimetype.extension : ''));
            }

          });
        }, 100);
      });
      //this.isDownload = false;
    }
  }

  openInfoDialog(): void {
    this.dialog.open(InfoDialogComponent, { width: '900px', height: '90%', data: this.list.filter(x => x.checked) });
  }

  openCompareDialog() {
    const compareDialog = this.dialog.open(CompareDialogComponent, {
      data: { histories: this.list.filter(x => x.checked) },
      panelClass: 'preview'
    });
  }

  searchFacets(value) {

    setTimeout(() => {
      var value = this.filters.filter(filter => filter.name == 'text')[0].value;

      if (value != null && value.length > 3 && value !== this.lastKeyword) {
        this.lastKeyword = value;
        // this.trackingService.sendMatomoSearch(value);
      }
    }, 5000);

    this.filters.filter(filter => filter.name == 'text')[0].value = value;
    this.searchFacetsChanged.emit();
  }

  openSearchDialog(key: string) {
    const searchDialog = this.dialog.open(SearchDialogComponent, {
      width: '600px',
      height: '90%',
      data: { prefix: 'detailed', filters: this.filters.filter(filter => filter.name == 'querySearch')[0].value },
      panelClass: 'search'
    });

    searchDialog.afterClosed().subscribe(result => {

      if (result) {
        this.filters.filter(filter => filter.name == 'querySearch')[0].value = result;
        this.searchByQuery.emit();
      }

    });
  }


}
