import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OCService } from '../satellites/oc.service';
import { LiquidCache, LiquidCacheService } from 'ngx-liquid-cache';
import { AppSettings } from 'app/shared/app.settings';
import { User, UserPicture } from 'app/shared/model/user.model';
import { HCMSService } from '../satellites/hcms.service';
import { RolesService } from './roles.service';
import Utils from 'app/shared/utils/utils';
import { Auth, EmailStruct, NewUser } from 'app/shared/model/newUser.model';
import { CardContent, WidgetsConfig } from 'app/shared/components/widgets/widgetsConfig';
import * as _ from 'lodash';

/**
 * Service defined to manage the necessary operations for users ({@link User})
 */
@Injectable()
export class UserService {
  /**
   * Widget config key
   */
  static readonly WIDGET_CONFIG_KEY = "widgetsConfig";
  /**
   * Relations limit
   */
  static readonly RELATIONS_LIMIT = 150;
  /**
   * last user time key
   */
  static readonly LAST_USER_TIME = "last_user_time";
  /**
   * key of owner
   */
  static readonly OWNER = "owner_rel";
  /**
   * key of pject manager
   */
  static readonly PROJECT_MANAGER = "projectmanager_rel";
  /**
   * key of main manager
   */
  static readonly MAIN_MANAGER = "mainManager";
  /**
   * key of co-owner
   */
  static readonly CO_OWNER = "co-owner";
  /**
   * key of co-manager
   */
  static readonly CO_MANAGER = "co-manager";
  /**
   * key of decision maker
   */
  static readonly DECISION_MAKER = "decision-maker";
  /**
   * key of contributor
   */
  static readonly CONTRIBUTOR = "contributor";
  /**
   * key of reader
   */
  static readonly READER = "reader";
  /**
   * key of production
   */
  static readonly PRODUCTION = "production";
  /**
   * key of remove owner
   */
  static readonly REMOVE_OWNER = "removeOwner";
  /**
   * key of remove project manager
   */
  static readonly REMOVE_PROJECT_MANAGER = "removeProjectmanager";
  /**
   * key of remove co-owner
   */
  static readonly REMOVE_CO_OWNER = "removeCoowner";
  /**
   * key of remove co-manager
   */
  static readonly REMOVE_CO_MANAGER = "removeComanager";
  /**
   * key of remove decision maker
   */
  static readonly REMOVE_DECISION_MAKER = "removeDecisionmaker";
  /**
   * key of remove contributor
   */
  static readonly REMOVE_CONTRIBUTOR = "removeContributor";
  /**
   * key of remove reader
   */
  static readonly REMOVE_READER = "removeReader";
  /**
   * key of remove production
   */
  static readonly REMOVE_PRODUCTION = "removeProduction";

  /**
   * user config owner relation 
   */
  static readonly OWNER_RELATION = { 'key': UserService.OWNER, 'remove': UserService.REMOVE_OWNER };
  /**
   * user config deputy relation
   */
  static readonly DEPUTY_RELATION = { 'key': UserService.CO_OWNER, 'remove': UserService.REMOVE_CO_OWNER };
  /**
   * user config project manager relation
   */
  static readonly PROJECT_MANAGER_RELATION = { 'key': UserService.PROJECT_MANAGER, 'remove': UserService.REMOVE_PROJECT_MANAGER };
  /**
   * user config assistan manager relation
   */
  static readonly ASSISTANT_MANAGER_RELATION = { 'key': UserService.CO_MANAGER, 'remove': UserService.REMOVE_CO_MANAGER };
  /**
   * user config decision maker relation
   */
  static readonly DECISION_MAKER_RELATION = { 'key': UserService.DECISION_MAKER, 'remove': UserService.REMOVE_DECISION_MAKER };
  /**
   * user config contributor relation
   */
  static readonly CONTRIBUTOR_RELATION = { 'key': UserService.CONTRIBUTOR, 'remove': UserService.REMOVE_CONTRIBUTOR };
  /**
   * user config reader relation
   */
  static readonly READER_RELATION = { 'key': UserService.READER, 'remove': UserService.REMOVE_READER };
  /**
   * user config production relation
   */
  static readonly PRODUCTION_RELATION = { 'key': UserService.PRODUCTION, 'remove': UserService.REMOVE_PRODUCTION };

  /**
   * Workflow web user step : NOT_CONFIRMED
   */
  static readonly WORKFLOW_WEBUSER_STEP_NOT_CONFIRMED: number = 10;
  /**
   * Workflow web user step : PENDING_TO_APPROVE
   */
  static readonly WORKFLOW_WEBUSER_STEP_PENDING_TO_APPROVE: number = 20;
  /**
   * Workflow web user step : APPROVED
   */
  static readonly WORKFLOW_WEBUSER_STEP_APPROVED: number = 30;
  /**
   * Workflow web user step : ACCOUNT_DISABLED
   */
  static readonly WORKFLOW_WEBUSER_STEP_ACCOUNT_DISABLED: number = 40;

  /**
   * all steps
   */
  static readonly POSSIBLE_STEPS = [
    { "key": UserService.WORKFLOW_WEBUSER_STEP_PENDING_TO_APPROVE, "available": [UserService.WORKFLOW_WEBUSER_STEP_APPROVED, UserService.WORKFLOW_WEBUSER_STEP_ACCOUNT_DISABLED] },
    { "key": UserService.WORKFLOW_WEBUSER_STEP_APPROVED, "available": [UserService.WORKFLOW_WEBUSER_STEP_ACCOUNT_DISABLED] },
    { "key": UserService.WORKFLOW_WEBUSER_STEP_ACCOUNT_DISABLED, "available": [UserService.WORKFLOW_WEBUSER_STEP_APPROVED] }
  ];
  /**
   * all relations
   */
  static readonly TEAM_RELATIONS = [
    UserService.OWNER,
    UserService.PROJECT_MANAGER,
    UserService.CO_OWNER,
    UserService.CO_MANAGER,
    UserService.DECISION_MAKER,
    UserService.CONTRIBUTOR,
    UserService.READER,
    UserService.PRODUCTION
  ];
  /**
   * Check for types
   */
  static readonly CHECK_FOR_TYPES = [
    'project.',
    'order.',
    'task.cost_estimate.',
    'task.coordination.',
    'task.final_approval.',
    'task.questions.',
    'task.metadata.',
    'task.milestone.',
    'task.others.',
    'task.text.',
    'task.proof_reading.',
    'task.creation.',
    'task.adaptation.',
    'task.final_art.',
    'task.image_editing.',
    'task.translation.',
    'task.moving_image.',
    'task.programming.',
    'task.online.',
    'task.production.',
  ];

  /**
   * List of types of ROLES that there are in a team
   */
  private teams: Array<any> = [
    //OWNER
    {
      'key': UserService.OWNER, 'remove': UserService.REMOVE_OWNER, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.POWER_ROLE, RolesService.LIMITED_ROLE]
    },
    //DEPUTY
    {
      'key': UserService.CO_OWNER, 'remove': UserService.REMOVE_CO_OWNER, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.POWER_ROLE, RolesService.LIMITED_ROLE, RolesService.CREW_ROLE]
    },
    //MANAGER
    {
      'key': UserService.PROJECT_MANAGER, 'remove': UserService.REMOVE_PROJECT_MANAGER, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE]
    },
    //ASSISTANT MANAGER
    {
      'key': UserService.CO_MANAGER, 'remove': UserService.REMOVE_CO_MANAGER, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE]
    },
    //DECISION MAKER
    {
      'key': UserService.DECISION_MAKER, 'remove': UserService.REMOVE_DECISION_MAKER, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.POWER_ROLE, RolesService.CREW_ROLE]
    },
    //CONTRIBUTOR
    {
      'key': UserService.CONTRIBUTOR, 'remove': UserService.REMOVE_CONTRIBUTOR, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.POWER_ROLE, RolesService.LIMITED_ROLE, RolesService.CREW_ROLE, RolesService.CONTRIBUTOR_ROLE]
    },
    //READER
    { 'key': UserService.READER, 'remove': UserService.REMOVE_READER },
    //PRODUCTION
    { 'key': UserService.PRODUCTION, 'remove': UserService.REMOVE_PRODUCTION }
  ];
  /**
   * users
   */
  private users = [];

  /**
   * Access rights to the different functionalities of approva material, widgets an dashboard, depending on role
   */
  private rights = {
    approval: {
      createMarker: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.POWER_ROLE, RolesService.OPERATOR_ROLE],
      editMarker: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.POWER_ROLE, RolesService.OPERATOR_ROLE],
      replyMarker: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.POWER_ROLE, RolesService.OPERATOR_ROLE],
      deleteOwnMarker: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.POWER_ROLE, RolesService.OPERATOR_ROLE],
      markAsDone: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.POWER_ROLE, RolesService.OPERATOR_ROLE],
      hideMarker: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.POWER_ROLE, RolesService.OPERATOR_ROLE],
      compareVersions: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.POWER_ROLE, RolesService.OPERATOR_ROLE]
    },
    material: {
      addToMediaLibrary: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.OPERATOR_ROLE],
      materialUpload: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.OPERATOR_ROLE],
      replaceMaterial: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.OPERATOR_ROLE],
      materialDownload: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.POWER_ROLE, RolesService.OPERATOR_ROLE],
      editMaterial: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.OPERATOR_ROLE],
    },
    widgets: {
      infoCenter: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.PRODUCTION_ROLE, RolesService.OPERATOR_ROLE],
      infoCenterRequest: [RolesService.OPERATOR_ROLE, RolesService.POWER_ROLE],
      allBudgets: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.OPERATOR_ROLE],
      budgets: [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.OPERATOR_ROLE, RolesService.PRODUCTION_ROLE]
    },
    dashboard: {
      briefing: [RolesService.POWER_ROLE]
    }
  }

  favoriteReports;

  /**
   * Types of reporting charts widgets
   */
  reportingCharts = [
    'jobs_last_12_months',
    'my_jobs_last_12_months',
    'open_jobs_channel',
    'my_open_jobs_channel',
    'open_jobs',
    'open_projects',
    'closed_jobs',
    'closed_projects',
    'open_requests'
  ];
  /**
   * Constructor
   * @param ocService Service whit the functions related to the online channel. 
   * @param hcmsService Service with the functions related to the censhare.
   * @param rolesService Service with the functions related to the roles.
   * @param cache Service with the functions related to the cache.
   */
  constructor(private ocService: OCService, private hcmsService: HCMSService, private rolesService: RolesService, private cache: LiquidCacheService) {

    if (this.getCurrentUser()) {
      this.checkLastUser();
      this.loadUsers();
    }
  }

  @LiquidCache('webusers', { duration: 14400 })
  /**
   * Get web users
   */
  getWebUsers(): Observable<any> {
    return this.hcmsService.get().one('entity/webuser').get();
  }

  @LiquidCache('languages')
  /**
   * Get languages
   */
  getLanguages(): Observable<any> {
    return this.ocService.get().all('tables/language/').getList();
  }
  /**
   * Get user favorite charts
   * @returns charts
   */
  async getUserFavoriteCharts() {
    let favorites = await this.hcmsService.get().one('entity/webuser_charts', +this.getCurrentUser().id).get().toPromise().then(data => {
      return data && data.charts ? data.charts : [];
    });

    return favorites;
  }
  /**
   * @ignore
   */
  filterByUserRelation(id: number = 0) {

    if (id == 0) id = this.getCurrentUser().id;

    let query = "";
    this.teams.forEach(team => query += team.key + '=' + id + '|');

    return query.substring(0, query.length - 1);

  }
  /**
   * @ignore
   */
  filterBySpecificUserRelation(relations: Array<any>, id: number = 0) {

    if (id == 0) id = this.getCurrentUser().id;

    let query = "";
    relations.forEach(relation => query += relation + '=' + id + '|');

    return query.substring(0, query.length - 1);

  }
  /**
   * Get users in order
   * @returns users 
   */
  getUsers() {
    return this.users.sort((x, y) => (x.name > y.name ? 1 : -1));
  }
  /**
   * Get users with admins role
   * @returns admins users
   */
  getAdmins() {
    return this.hcmsService.get().one('entity/admin?query=roles="' + RolesService.ADMIN_ROLE + '"').get();
  }
  /**
   * Load Users
   */
  loadUsers() {
    if (this.getCurrentUser()) {

      this.getWebUsers().subscribe(data => {
        if (data.result) {
          data.result.forEach(user => this.fillUserData(user));
          this.users = data.result;
          this.saveLastUserTime();

          const cu = this.users.find(x => x.id === this.getCurrentUser().id);
          if (cu) {
            this.updateCurrentUser(cu);
          }
        }
      });
    }
  }
  /**
   * Check last user 
   */
  async checkLastUser() {

    let time = await this.ocService.get().one('last_user_time').get().toPromise()
      .then(result => {
        return result.time;
      });

    let current = this.getLastUserTime();
    if (time > current) {
      this.cache.remove('webusers');
      localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + UserService.LAST_USER_TIME);
      this.loadUsers();
    }
  }
  /**
   * Save last user time
   */
  saveLastUserTime() {

    if (this.users.length > 0) {
      let usersChanged = this.users.filter(user => user.modifiedAttr).map(user => user.modifiedAttr)
        .sort((a, b) => {
          return new Date(b).getTime() - new Date(a).getTime();
        });

      if (usersChanged.length > 0) {
        let time = new Date(usersChanged[0]).getTime();
        localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + UserService.LAST_USER_TIME, JSON.stringify(time));
      }
    }
  }
  /**
   * Get last user time
   */
  getLastUserTime() {
    let time = localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + UserService.LAST_USER_TIME);
    if (!time) return 0;

    return time;
  }
  /**
   * Get selectables users by role
   * @param role role
   * @returns users
   */
  getSelectablesUsersByRole(role: string, hierarchy: boolean = false) {
    let selectableUsers = this.getSelectablesUsers();
    return selectableUsers.filter(user => this.rolesService.checkRole(user, role, hierarchy));
  }
  /**
   * Get selectables Users
   * @returns users
   */
  getSelectablesUsers() {
    return this.getUsers().filter(x => this.isSelectable(x));
  }

  /**
   * Get selectables Users by agency
   * @returns users
   */
  getSelectablesUsersByAgency(agencyId) {
    return this.getUsers().filter(x => (x.agencyEmployee && x.agencyEmployee.some(a => a.id === agencyId)) || (x.agencyMainContact && x.agencyMainContact.some(a => a.id === agencyId)));
  }

  /**
   * Checks whether a user is selectable
   * @param user user
   * @returns true if is selectable
   */
  isSelectable(user: User): boolean {
    return !(user.roles && user.roles.length === 1 && (user.roles[0].toLowerCase().indexOf('admin') > -1 || user.roles[0].toLowerCase().indexOf('production') > -1));
  }
  /**
   * Get current user
   * @returns user
   */
  getCurrentUser() {
    return JSON.parse(localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE));
  }
  /**
   * Update current user
   * @param user user
   */
  updateCurrentUser(user) {
    localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE, JSON.stringify(user));
  }
  /**
   * Complete current user with all data
   * @param user user
   */
  setCurrenUser(user) {
    this.fillUserData(user);
    localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE, JSON.stringify(user));
  }
  /**
   * Get user language
   * @returns user languages
   */
  getUserLanguage() {
    let user = this.getCurrentUser();
    if (user && user.language) return user.language;
  }
  /**
   * Remove current user of localstorage
   */
  removeCurrenUser() {
    localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE);
  }
  /**
   * Get user data
   * @param id id of user
   * @returns user data
   */
  getUserData(id) {

    if (typeof id === 'number' || typeof id === 'string') {
      return this.users.find(user => user.id == id);
    } else if (id instanceof Array && id.length > 0) {
      return this.users.find(user => user.id == id[0]);
    }

  }
  /**
   * Add an user to watch list
   * @param id id of user
   */
  addWatchlist(id) {
    let user = this.getCurrentUser();

    if (!user.watchlist) {
      user.watchlist = [];
    }

    user.watchlist.push(id);
    this.updateWatchlist(user);
  }
  /**
   * Remove an user to watch list
   * @param id id of user
   */
  removeWatchlist(id) {
    let user = this.getCurrentUser();

    if (!user.watchlist) {
      user.watchlist = [];
    }

    const index = user.watchlist.indexOf(id);
    if (index > -1) {
      user.watchlist.splice(index, 1);
    }
    this.updateWatchlist(user);
  }

  /**
   * Obtain the team of an asset (projecto, job, request, etc.)
   * 
   * @param asset The asset from wich we require the team
   * @returns A team
   */
  getTeams(asset): Map<string, Array<any>> {

    let team: Map<string, Array<any>> = new Map();
    this.teams.forEach(x => {
      let usersTeam = this.getRelationUsers(asset, x.key, x.remove);
      if (usersTeam) {
        team.set(x.key, usersTeam);
      }
    });

    return team;
  }
  /**
   * Gets the users related to an asset
   * @param asset asset
   * @param relation relation
   * @param removeField remove 
   * @returns users
   */
  getRelationUsers(asset, relation, removeField): Array<User> {
    let users: Array<User> = new Array();

    if (asset[relation] && asset[relation].length > 0) {
      asset[relation].forEach(current => {
        if (current && (!asset[removeField] || !asset[removeField].includes(current))) {
          let userData = this.getUserData(current);
          if (userData) {
            users.push(userData);
          }
        }
      });
    }

    return users;
  }
  /**
   * 
   * Gets the user related to an asset
   * @param asset asset
   * @param relation relation
   * @param removeField remove 
   * @returns user
   */
  getRelationUser(asset, relation, removeField) {
    let user;
    if (asset[relation] && asset[relation].length > 0) {
      asset[relation].forEach(current => {
        if (current && (!asset[removeField] || asset[removeField] != current)) {
          user = this.getUserData(current);
        }
      });
    }

    if (user) {
      asset[relation] = [];
      asset[relation].push(user.id);
    }

    return user;
  }
  /**
   * Gets the users related to an asset
   * @param asset asset
   * @param relation relation
   * @param removeField remove 
   * @returns users
   */
  getUsersByRelation(asset, relation, removeField) {
    let users = [];
    if (asset[relation] && asset[relation].length > 0) {
      asset[relation].forEach(current => {
        if (current && (!asset[removeField] || asset[removeField] != current)) {
          users.push(this.getUserData(current));
        }
      });
    }

    return users;
  }
  /**
   * Store the relation of a user to an asset
   * @param asset asset
   * @param relation relation
   * @param valueField value of field
   * @param removeField remove field
   */
  saveRelationUser(asset, relation, valueField, removeField) {

    if (asset[valueField] && (!asset[relation] || asset[relation].length == 0 || (asset[valueField] != asset[relation][0]))) {

      if (asset[relation] && asset[relation].length > 0) {
        asset[removeField] = asset[relation][0];
      }

      asset[relation] = [];
      asset[relation].push(asset[valueField]);

    }
  }
  /**
   * Update watch list
   * @param user user
   */
  private updateWatchlist(user) {
    this.setCurrenUser(user);
    this.getUser(user).then(data => {
      data.watchlist = user.watchlist;
      data.put();
    });
  }
  /**
   * Get user
   * @param user user
   * @returns request
   */
  private getUser(user: User) {
    return this.hcmsService.get().one('entity/webuser', user.id).get().toPromise();
  }
  /**
   * Get user by id
   * @param userId user id
   * @returns request
   */
  getUserById(userId) {
    return this.hcmsService.get().one('entity/webuser', userId).get().toPromise();
  }
  /**
   * Get user by email
   * @param userEmail  user's email
   * @returns request
   */
  getUserByEmail(userEmail) {
    return this.hcmsService.get().one('entity/webuser?query=email="' + userEmail + '"').get().toPromise();
  }

  /**
   * Fill some additional info of a user easy to print.
   * 
   * @param user User from whom we required additional info
   */
  private fillUserData(user) {

    user.initials = '';
    user.display_name = '';
    user.customer = this.rolesService.isCustomer(user);

    if (user.pictures && user.pictures.length > 0 && user.pictures[0].downloadLink
      && (!user.removeMainPicture || +user.removeMainPicture !== +user.pictures[0].id)) {
      user.mainPicture = user.pictures[0];
      user.mainPicture.downloadLink = user.mainPicture.downloadLink + '?version=' + user.mainPicture.version;

    }

    if (user.type && user.type.indexOf('webuser') > -1) {

      if (user.firstName) {
        user.initials += user.firstName.charAt(0);
        user.display_name += user.firstName + ' ';
      }

      if (user.lastName) {
        user.initials += user.lastName.charAt(0);
        user.display_name += user.lastName;
      }
    } else {

      if (user.firstname) {
        user.initials += user.firstname.charAt(0);
        user.display_name += user.firstname + ' ';
      }

      if (user.name) {
        user.initials += user.name.charAt(0);
        user.display_name += user.name;
      }
    }

  }
  /**
   * Change picture of user
   * @param user user
   * @param file new picture
   * @param type type
   * @returns response to request
   */
  changePicture(user: User, file, type) {
    return this.getUserToUpdate(user.id).then(updated => {

      let picture: UserPicture = new UserPicture();
      picture.type = type.def_assettype;
      picture.name = file.name;
      picture.downloadLink = "formdata:file0";
      picture.domain = user.domain;
      picture.reloadPreview = true;
      updated.pictures = [picture];

      let fileFormData = new FormData();
      fileFormData.append("entity", JSON.stringify(updated));
      fileFormData.append("file0", file);

      return this.hcmsService.get().all('entity/webuser/' + user.id).customPUT(fileFormData, '', {}, {}).toPromise().then((newUser) => {
        this.changeUser(newUser.plain());
      });
    });
  }
  /**
   * Delete picture of user
   * @param user user
   * @returns response to request
   */
  deletePicture(user: User) {
    return this.getUserToUpdate(user.id).then(updated => {
      if (user && user.pictures && user.pictures.length > 0) {
        let pictureId = user.pictures[0].id;
        updated.removeMainPicture = +pictureId;
      }
      return updated.put().toPromise().then((newUser) => {
        this.changeUser(newUser.plain());
      });
    });
  }
  /**
   * Change user
   * @param user user 
   * @returns user
   */
  changeUser(user: User) {

    this.fillUserData(user);

    let currentUser = this.getCurrentUser();
    if (+currentUser.id === +user.id)
      localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE, JSON.stringify(user));

    for (var i in this.users) {
      if (+this.users[i].id === +user.id) {
        this.users[i] = user;
        break;
      }
    }
    setTimeout(() => {
      Utils.reloadData = Utils.RELOAD_DATA_CURRENT_USER_DATA;
    }, 1500);

    return user;
  }
  /**
   * Get add member roles
   * @param userid id of user
   * @returns users
   */
  getAddMemberRoles(userid: number = 0) {
    let user: User = this.getCurrentUser();
    if (userid > 0) user = this.getUserData(userid);
    return this.teams.filter(x => !x.roles || x.roles.some(y => this.rolesService.checkRole(user, y)));
  }
  /**
   * Get team by role
   * @param role role kay
   * @returns teams
   */
  getTeamByRole(role) {
    return this.teams.find(x => x.key === role);
  }
  /**
   * Check if it has a role
   * @param key key of role
   * @returns true if has role
   */
  hasRole(key) {

    let currentUser = this.getCurrentUser();
    return this.teams.some(x => x.key == key && (!x.roles || x.roles.some(y => this.rolesService.checkRole(currentUser, y))));
  }
  /**
   * Get team
   * @param id id
   * @returns request
   */
  getTeam(id: number) {
    return this.hcmsService.get().one('entity/team', id).get().toPromise();
  }

  async saveNewUserInTeam(team, member) {

    this.addUserToTeam(team, member.role, member.person);

    let route = Utils.getTeamEntity(team.type);
    let updateTeam = await this.hcmsService.get().one(route, team.id).get().toPromise();
    updateTeam = Object.assign(updateTeam, team.plain ? team.plain() : team);

    if (updateTeam.workflow && (+updateTeam.workflow === AppSettings.WORKFLOW_ACTION || +updateTeam.workflow === AppSettings.WORKFLOW_PROJECT)
      && member.role == UserService.PROJECT_MANAGER) {
      updateTeam.workflowStep = 20;
    }

    return updateTeam.save().toPromise()

  }
  /**
   * Remove user from team
   * @param team team
   * @param role role of user
   * @param user user data
   * @returns request
   */
  async removeUserFromTeam(team, role, user) {

    this.removeUserToTeam(team, role, user);

    let route = Utils.getTeamEntity(team.type);
    let updateTeam = await this.hcmsService.get().one(route, team.id).get().toPromise();
    updateTeam = Object.assign(updateTeam, team.plain ? team.plain() : team);

    return updateTeam.save().toPromise();

  }
  /**
   * Change Role in team
   * @param team team
   * @param oldRole old role
   * @param role new role
   * @param user user data
   * @returns request
   */
  async changeRoleInTeam(team, oldRole, role, user) {

    this.addUserToTeam(team, role, user);
    this.removeUserToTeam(team, oldRole, user);

    let route = Utils.getTeamEntity(team.type);
    let updateTeam = await this.hcmsService.get().one(route, team.id).get().toPromise();
    updateTeam = Object.assign(updateTeam, team);
    return updateTeam.save().toPromise()

  }
  /**
   * Set main manager in team
   * @param team team
   * @param user user
   * @returns request
   */
  async setMainManagerInTeam(team, user) {
    let route = Utils.getTeamEntity(team.type);
    let updateTeam = await this.hcmsService.get().one(route, team.id).get().toPromise();

    if (updateTeam.type === 'order.' && updateTeam.workflowStep && updateTeam.workflowStep === 10) {
      updateTeam.workflowStep = 20;
    }

    updateTeam[UserService.MAIN_MANAGER] = user.id;
    return updateTeam.save().toPromise();
  }

  private addUserToTeam(team, role, user) {
    if (team[role] == null) team[role] = [];
    team[role].push(+user);

    let field = Utils.getSchemaRemoveFeature(role);
    if (team[field] != null && team[field].includes(+user)) {
      const index = team[field].indexOf(+user);
      if (index > -1) {
        team[field].splice(index, 1);
      }
    }
  }
  /**
   * Remove User to team
   * @param team team
   * @param role role of user
   * @param user user data
   */
  private removeUserToTeam(team, role, user) {
    let field = Utils.getSchemaRemoveFeature(role);
    if (team[field] == null) team[field] = [];
    team[field].push(+user);

    if (team.mainManager && team.mainManager == user) {
      team.mainManager = -1;
    }
  }
  /**
   * Checks if a user can edit a team 
   * @param user user
   * @param team team
   * @returns true if can edit
   */
  canEditTeam(user, team) {
    let roles = [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.POWER_ROLE];
    let relations = [UserService.OWNER_RELATION, UserService.DEPUTY_RELATION, UserService.PROJECT_MANAGER_RELATION, UserService.ASSISTANT_MANAGER_RELATION];
    return this.canEdit(user, team, roles, relations);
  }
  /**
  * Checks if a user can edit materials 
  * @param user user
  * @param team team
  * @returns true if can edit
  */
  canEditMaterials(user, team) {
    let roles = [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE];
    let relations = [UserService.PROJECT_MANAGER_RELATION, UserService.ASSISTANT_MANAGER_RELATION];
    return this.canEdit(user, team, roles, relations);
  }
  /**
  * Checks if a user can edit a approval 
  * @param user user
  * @param team team
  * @returns true if can edit
  */
  canEditApproval(user, team) {
    let roles = [RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE, RolesService.POWER_ROLE, RolesService.CREW_ROLE];
    let relations = [UserService.PROJECT_MANAGER_RELATION, UserService.ASSISTANT_MANAGER_RELATION, UserService.OWNER_RELATION, UserService.DEPUTY_RELATION];
    return this.canEdit(user, team, roles, relations);
  }
  /**
   * Checks if a user has editing permission
   * @param user user
   * @param team team
   * @param roles roles
   * @param relations relation
   * @returns true if have permission to edit
   */
  canEdit(user, team, roles, relations) {
    if (this.rolesService.checkRole(user, RolesService.ADMIN_ROLE)) return true;
    if (!team) return false;
    return roles.some(role => this.rolesService.checkRole(user, role)) && relations.some(relation => this.getRelationUsers(team, relation.key, relation.remove).some(x => x.id == user.id));
  }
  /**
   * checks if the user is of type admin
   * @param user user
   * @param roles roles
   * @returns true if user is admin
   */
  hasPermission(user, roles) {
    return this.rolesService.checkRole(user, RolesService.ADMIN_ROLE)
      || roles.some(x => this.rolesService.checkRole(user, x))
  }
  /**
   * Get permisions
   * @param key the key element to be edited
   * @param value the action to be taken
   * @returns true if has permission
   */
  getPermissions(key, value?): any {
    
    if (value) {
      return this.rights[key] && this.rights[key][value] && this.hasPermission(this.getCurrentUser(), this.rights[key][value]);
    } else {
      const result = {};
      Object.keys(this.rights[key]).forEach(x =>
        result[x] = this.hasPermission(this.getCurrentUser(), this.rights[key][x])
      );

      return result;
    }

  }
  /**
   * Copy team
   * @param parentid parent id
   * @param child child 
   * @returns request
   */
  copyTeam(parentid, child) {
    return this.getTeam(parentid).then(async parentTeam => {
      if (parentTeam) {

        let route = Utils.getTeamEntity(child.type);
        let childTeam = await this.hcmsService.get().one(route, child.id).get().toPromise();
        this.teams.forEach(team => {
          if (parentTeam[team.key] != null) {
            childTeam[team.key] = parentTeam[team.key];
          }
        });
        return childTeam.save().toPromise();
      }
    });
  }
  /**
   * Get filtered member roles
   * @param user user
   * @param owner owner of asset
   * @param pm asset pm
   * @returns roles
   */
  getFilteredMemberRoles(user, owner, pm) {
    let roles = this.getAddMemberRoles(user);

    if (owner) roles = roles.filter(role => role.key != UserService.OWNER);
    if (pm) roles = roles.filter(role => role.key != UserService.PROJECT_MANAGER);

    return roles;

  }
  /**
   * Geet Users by role
   * @param role role
   * @returns users
   */
  getUsersByRole(role) {
    return this.users.filter(user => user.roles.includes(role));
  }
  /**
   * Get user to update
   * @param id user id
   * @returns request
   */
  getUserToUpdate(id,) {
    return this.hcmsService.get().one('entity/webuser', id).get().toPromise();
  }
  /**
   * Add role
   * @param user user
   * @param role role
   * @returns request
   */
  addRole(user, role) {
    return this.getUserToUpdate(user.id).then(updated => {

      if (!updated.roles) updated.roles = [];
      updated.roles.push(role.value_key);
      return updated.put().toPromise();


    });
  }
  /**
   * Remove role
   * @param user user
   * @param role role
   * @returns request
   */
  removeRole(user, role) {
    return this.getUserToUpdate(user.id).then(updated => {

      if (!updated.roles) updated.roles = [];
      const index = updated.roles.indexOf(role.value_key);
      if (index > -1) updated.roles.splice(index, 1);

      return updated.put().toPromise();

    });
  }
  /**
   * Change Status
   * @param user user
   * @param step step
   * @returns request
   */
  changeStatus(user, step) {
    return this.getUserToUpdate(user.id).then(updated => {

      updated.workflow = +AppSettings.WORKFLOW_WEBUSER;
      updated.workflowStep = +step.wf_step;

      return updated.put().toPromise();

    });
  }
  /**
   * Save data
   * @param user user 
   * @param admin admin
   * @returns result of request
   */
  saveData(user, admin) {
    return this.getUserToUpdate(user.id).then(updated => {

      updated.name = this.fillUserName(user);

      if (user.firstName) updated.firstName = user.firstName;
      if (user.lastName) updated.lastName = user.lastName;
      if (user.language) updated.language = user.language;
      if (user.phone) updated.phone = user.phone;
      if (user.plannedVacations) updated.plannedVacations = user.plannedVacations;

      if (user.vacations) updated.vacations = user.vacations;

      const availabilityFields = _.filter(_.keys(user), (item) => {
        if (_.includes(item, 'availability-')) {
          return item;
        }
      });
      _.forEach(availabilityFields, (item: any) => {
        updated[item] = user[item];
      });

      if (admin) {
        if (user.email) updated.email = user.email;
        if (user.domain) updated.domain = user.domain;
        if (user.domain2) updated.domain2 = user.domain2;
      }

      return updated.put().toPromise().then((newUser) => {
        return this.changeUser(newUser.plain());
      });

    });
  }
  /**
   * Get user name complete
   * @param user user
   * @returns complete name of user
   */
  fillUserName(user) {

    let name = user.name;

    if (user.firstName || user.lastName) name = '';
    if (user.firstName) name += user.firstName;
    if (user.lastName) name += (user.firstName) ? ' ' + user.lastName : user.lastName;

    return name.trim();

  }
  /**
   * Create new user
   * @param user user
   * @returns request
   */
  createUser(user: NewUser) {
    user.name = this.fillUserName(user);

    let authData: Auth = new Auth();
    authData.login = user.email;

    let emailStruct: EmailStruct = new EmailStruct();
    emailStruct.email = user.email;

    user.auth = authData;
    user.emailStruct = emailStruct;
    user.workflow = AppSettings.WORKFLOW_WEBUSER;
    user.workflowStep = UserService.WORKFLOW_WEBUSER_STEP_APPROVED;

    return this.hcmsService.get().all('entity/auth').post(user).toPromise();
  }
  /**
   * Get teams
   * @returns teams
   */
  getTeamRoles() {
    return this.teams;
  }
  /**
   * Edit user
   * @param userUpdate updated user
   */
  async editUser(userUpdate) {
    this.getUser(userUpdate).then(data => {
      data.notifications = userUpdate.notifications;
      data.put();

      setTimeout(() => {
        Utils.reloadData = Utils.RELOAD_DATA_NOTIFICATIONS;
      }, 1000);
    });
  }
  /**
   * Get update user
   * @param userId user id
   * @returns updated user
   */
  async getUpdatedUser(userId) {
    let updatedUser = await this.getUserById(userId);
    this.fillUserData(updatedUser);
    return updatedUser;
  }
  /**
   * @ignore
   * @param asset 
   * @param user 
   * @returns 
   */
  static shouldShow(asset, user) {
    return true;
    // return !UserService.CHECK_FOR_TYPES.includes(asset.type) ||
    //   RolesService.checkUserRole(user, RolesService.PRODUCTION_ROLE) ||
    //   RolesService.checkUserRole(user, RolesService.PROJECT_MANAGER_ROLE) ||
    //   UserService.TEAM_RELATIONS.some(relation => {
    //     return asset[relation] && asset[relation].includes(user.id);
    // });
  }
  /**
   * @ignore
   * @returns 
   */
  getGroups() {
    return this.hcmsService.get().one('entity/user_group').get().toPromise().then(data => {

      data.result.forEach(group => {
        group.users.forEach(user => {
          this.fillUserData(user);
        });
      });

      return data;
    });
  }

  /**
   * Obtain the widgets config cached for user logged
   * 
   * @param key The panel from wich we required the cached config
   * @returns The widgets config cached
   */
  async getUserWidgetsConfig(key) {

    // UPDATE - dashboard is now NOT configurable by the user, only by config file.
    // but I am sure this might come back, so I keep the code in comment below
    // SH, July 7, 2021
    let isDashboard = key === 'dashboard';
    let config = this.cache.get(UserService.WIDGET_CONFIG_KEY);

    if (config && config[key] && !isDashboard) return config[key];

    if (isDashboard && WidgetsConfig.config[key]) {
      return WidgetsConfig.config[key];
    }

    if (WidgetsConfig.config[key]) {
      let widgetsConfig = WidgetsConfig.config[key];
      return widgetsConfig;
    }

    return null;

    /*
    let isDashboard = key === 'dashboard';
    let config = this.cache.get(UserService.WIDGET_CONFIG_KEY);
    this.favoriteReports = isDashboard? await this.getUserFavoriteCharts() : [];

    if (config && config[key] && !isDashboard) return config[key];

    if (config && config[key] && isDashboard) {
      if (this.matchFavoriteReportsInCache(config, key)) {
        return config[key];
      } else {
        return this.addFavoriteReportsWidgetsToConfig(config[key], key);
      }
    }

    if (WidgetsConfig.config[key]) {
      let widgetsConfig = WidgetsConfig.config[key];
      if (isDashboard && this.favoriteReports && this.favoriteReports.length > 0) {
        widgetsConfig = this.addFavoriteReportsWidgetsToConfig(widgetsConfig, key);
      }
      return widgetsConfig;
    }
    
    return null;
    */
  }

  /**
   * Checks if there is a favorite reports charts to show in dashboard
   * 
   * @param config The widgets config cached
   * @param key The panel from wich we required the cached config
   * @returns True if there is some favorite report cached, false in other case
   */
  matchFavoriteReportsInCache(config, key) {
    let widgetsCached = Object.keys(config[key].moreFuntions);
    let reportsCached = widgetsCached.filter(widget => this.reportingCharts.includes(widget));

    reportsCached.forEach(report => {
      if (!this.favoriteReports.includes(report)) {
        delete config[key].moreFuntions[report];
      }
    });

    if (this.favoriteReports.filter(report => !widgetsCached.includes(report)).length > 0) return false;
    return true;
  }

  /**
   * Add the favorite reports widgets to the widgets config, at the end of the list (in "more functions" position)
   * 
   * @param widgetsConfig The widgets config cached
   * @param key The panel from wich we required the cached config
   * @returns The widgets config updated
   */
  addFavoriteReportsWidgetsToConfig(widgetsConfig, key) {
    let position = 8;

    this.reportingCharts.forEach(report => {
      if (this.favoriteReports.includes(report) && !widgetsConfig.moreFuntions[report]) {
        widgetsConfig.moreFuntions[report] = { width: '32%', position: position };
        position++;
      }
    });

    this.saveUserWidgetsConfig(key, widgetsConfig);
    return widgetsConfig;
  }
  /**
   * Save user widget config
   * @param key key of card
   * @param data data
   */
  saveUserWidgetsConfig(key, data) {

    let config = this.cache.get(UserService.WIDGET_CONFIG_KEY);
    if (!config) config = WidgetsConfig.config;

    config[key] = data;

    this.cache.set(UserService.WIDGET_CONFIG_KEY, config);
  }
  /**
   * Save user card widget config
   * @param card {@link CardComponent}
   */
  async saveUserCardWidgetConfig(card: CardContent) {

    let config = await this.getUserWidgetsConfig(card.component);
    config[card.list][card.key].width = card.width;
    config[card.list][card.key].position = card.position;
    this.saveUserWidgetsConfig(card.component, config);

  }
  /**
   * Save user list widget config
   * @param key key of card
   * @param list list of assets
   * @param data data of list
   */
  async saveUserListWidgetConfig(key, list, data) {

    let config = await this.getUserWidgetsConfig(key);
    data.forEach((card: CardContent) => {
      config[list][card.key].width = card.width;
      config[list][card.key].position = card.position;
    })
    this.saveUserWidgetsConfig(key, config);
  }
  /**
   * Delete Widget from user list widget config
   * @param key key of card
   * @param list list of assets
   * @param data data of list
   * @param widgetKey key of widget
   */
  async deleteWidgetFromUserListWidgetConfig(key, list, data, widgetKey) {

    let config = await this.getUserWidgetsConfig(key);
    delete config[list][widgetKey];

    data.forEach((card: CardContent) => {
      config[list][card.key].width = card.width;
      config[list][card.key].position = card.position;
    })
    this.saveUserWidgetsConfig(key, config);

  }

  /**
     * Get partners Ids
     * @param user user
     */
  getPartnersId(user) {

    let empArr = _.flatMap(user.agencyEmployee, (agency) => {

      const emp = agency.employees ? agency.employees : [];
      const mc = agency.mainContact ? agency.mainContact : [];

    
      return emp.concat(mc)
    });

    let mcArr = _.flatMap(user.agencyMainContact, (agency) => {

      const emp = agency.employees ? agency.employees : [];
      const mc = agency.mainContact ? agency.mainContact : [];

      return emp.concat(mc)
    });

    const result = _.uniq(empArr.concat(mcArr));

    return result && result.length > 0 ? result : [user.id];
  }

  /**
   * Get agencies Ids
   * @param user user
   */
  getAgenciesId(user) {
    return _.uniq(_.map(user.agencyEmployee, (agency) => agency.id).concat(_.map(user.agencyMainContact, (agency) => agency.id)));
  }
}