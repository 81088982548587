import { Component, OnInit, Input } from '@angular/core';
import { Asset } from 'app/shared/model/asset.model';

/**
 * This component defines the asset info view (type zip).
 */
@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {

  /**
   * {@link Asset} Tag
   */
  @Input() tags: Asset[] = [];

  constructor() { }

  /**
   * Initialize the component.
   */
  ngOnInit() {
    this.tags = this.tags.filter((x, index, self) => index === self.findIndex(y => y.id === x.id));
  }

}
