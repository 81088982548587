import { Component, Input, OnInit } from '@angular/core';

/**
 * @ignore
 */
@Component({
  selector: 'app-link-widget',
  templateUrl: './link-widget.component.html',
  styleUrls: ['./link-widget.component.scss']
})
export class LinkWidgetComponent implements OnInit {

  @Input() label : string;
  @Input() link : Array<string> | string;
  @Input() imageAsset;

  constructor() { }

  ngOnInit() {}

}
