import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from 'app/shared/app.settings';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { NotificationsService } from 'app/shared/services/notifcations/notifications.service';
import { TasksService } from 'app/shared/services/tasks/tasks.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { UserService } from 'app/shared/services/user/user.service';
import { WorkflowService } from 'app/shared/services/workflows/workflow.service';
import Utils from 'app/shared/utils/utils';
import * as moment from 'moment';

/**
 * This component defines the kanban view (planner panel) for different assets
 */
@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss']
})
export class KanbanComponent implements OnInit, OnDestroy {

  /**
   * List of objects to show
   */
  @Input() assets : Array<any> = [];
  /**
   * True if task kanban
   */
  @Input() tasksKanban : boolean = true;
  /**
   * True if request kanban
   */
  @Input() requestKanban : boolean = false;

  /**
   * Current user
   */
  currentUser;
  
  /**
   * {@link Utils} imorted class
   */
  utils = Utils;

  /**
   * Lists.
   */
  lists = [];
  /**
   * Steps.
   */
  steps : Array<String> = [];
  /**
   * True if a power user.
   */
  isPower: boolean = false;

  /**
   * True if a operator.
   */
   isOperator: boolean = false;

  /**
   * Defines the set of workflow steps of jobs
   */
  jobs_stepsIds : Array<String> = [
    ActionsService.JOB_WORKFLOW_PLANNED + "",
    ActionsService.JOB_WORKFLOW_IN_PROGRESS + "",
    ActionsService.JOB_WORKFLOW_DONE + "",
    ActionsService.JOB_WORKFLOW_CLOSED + "",
  ];

  /**
   * Defines the set of workflow steps of tasks
   */
  @Input() tasks_stepsIds : Array<String> = [
    TasksService.TASK_WORKFLOW_PLANNED + "",
    TasksService.TASK_WORKFLOW_OPEN + "",
    TasksService.TASK_WORKFLOW_VERIFICATION + "",
    TasksService.TASK_WORKFLOW_IN_PROGRESS + "",
    // TasksService.TASK_WORKFLOW_APPROVED + "",
    TasksService.TASK_WORKFLOW_DONE + "",
    TasksService.TASK_WORKFLOW_ON_HOLD + ""
  ];

  /**
   * Constructor
   * @param tasksService Service with the functions related to the tasks.
   * @param actionsService Service with the functions related to the actions.
   * @param workflowService Service with the functions related to the workflow.
   * @param translateService Service with the functions related to the translations.
   * @param notifications Service with the functions related to the notifications.
   * @param usersService Service with the functions related to the users.
   * @param rolesService Service with the functions related  to the roles.
   */
  constructor(
    private tasksService: TasksService,
    private actionsService: ActionsService,
    private workflowService: WorkflowService,
    private translateService: TranslateService,
    private notifications: NotificationsService,
    private usersService: UserService,
    private rolesService: RolesService
  ){}

  /**
   * Initializes the component and obtains the necessary data.
   */
  ngOnInit(){
    this.currentUser = this.usersService.getCurrentUser();
    this.isPower = this.rolesService.checkRole(this.currentUser, RolesService.POWER_ROLE);
    this.isOperator = this.rolesService.checkRole(this.currentUser, RolesService.OPERATOR_ROLE);

    this.assets.forEach(asset => {
      asset.workflowStepName = (asset.step != null)?asset.step.name:'';
      if (!asset.type.startsWith('task.')) {
        if (asset.priorityCntr) asset.priorityLabel = this.translateService.instant('priority_ids.' + asset.priorityCntr);
      }
      if (asset.type.startsWith('task.')){
        if (asset.priority) asset.priorityLabel = this.translateService.instant('priority_ids.' + asset.priority);
        if (asset.jobs && asset.jobs.length > 0)asset.parentJob = asset.jobs[0];
        asset.internal = this.tasksService.isInternal(asset.type);
      }
    });
    this.updateLists();
  }

  /**
   * Delete the component changes the unnopened field of all tasks
   */
  ngOnDestroy(): void {
    this.tasksService.uploadTaskUnopened(this.assets.filter(asset => asset.type.startsWith('task.') &&  asset.unopened));
  }

  /**
   * Sets or updates the list of objects to show according the steps of them
   */
  updateLists(){
    this.lists = [];

    this.steps = this.getAccesibleSteps();
    for (let step of this.steps){
      let items = this.assets.filter(x => x.workflowStep + '' == step);
      this.lists.push({'id': step, 'items': items});
    }
  }

  /**
   * Checks if a item can be opened
   * @param item The item to check
   * @returns True if task item can be opened, false in other case
   */
  canOpenTask(item) {
    if (!this.requestKanban || (this.requestKanban && (this.isOperator ||
      ((item.user_targets_ids && item.user_targets_ids.includes(this.currentUser.id)) ||
      (item.owner_rel && item.owner_rel.includes(this.currentUser.id)))))) {
      return true;
    }
    return false;
  }

  /**
   * Obtains the correct steps depending on type of assets and kanban
   * @returns the accesible steps to show in the panel
   */
  getAccesibleSteps() {
    if (this.currentUser.customer && !this.requestKanban){
      return (this.tasksKanban)?this.tasks_stepsIds.filter(x => x != TasksService.TASK_WORKFLOW_PLANNED + ""):this.jobs_stepsIds.filter(x => x != ActionsService.JOB_WORKFLOW_PLANNED + "");
    } else {
      return (this.tasksKanban)?this.tasks_stepsIds:this.jobs_stepsIds;
    }
  }

  /**
   * Method executed when a item is dropped in a step column. If possible, move the item to the (new) column
   * @param event The event with all necessary info about item, previous and next column, etc.
   */
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      let newId = event.item.element.nativeElement.getAttribute('id');
      let step = +event.container.element.nativeElement.getAttribute('id');
      let asset = this.assets.find(x => x.id == newId);

      if (asset.type == "order." && !this.checkOpenTasksAndJobStep(asset, step)) {
        this.notifications.show(this.translateService.instant('invalid_job_closed'), null, null, 5000, 'error-snack');
      } else {
        this.doTrasnfer(event, newId, step);
      }
    }
  }

  /**
   * Moves an item to a column step in the kanban panel
   * @param event The event with all necessary info about item, previous and next column, etc.
   * @param newId The asset id
   * @param step The new step to move
   */
  doTrasnfer(event, newId, step) {
    transferArrayItem(event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex);

    this.updateWorkflow(newId, step, false);
  }

  /**
   * Checks if the asset has open tasks and it's done or closed
   * @param asset The asset from which to check the step and open tasks
   * @param step The new step for the asset
   * @returns True if the job is not closed or done and has no open tasks. False in other case.
   */
  checkOpenTasksAndJobStep(asset, step) {
    let openTasks = asset.task_childs.filter(task => task.workflowStep
      && (task.workflowStep != +TasksService.TASK_WORKFLOW_ON_HOLD && task.workflowStep != +TasksService.TASK_WORKFLOW_DONE));

    if ((step == ActionsService.JOB_WORKFLOW_DONE || step == ActionsService.JOB_WORKFLOW_CLOSED) && openTasks.length > 0) return false;
    return true;
  }

  /**
   * Updates the asset workflow
   * 
   * @param newId The asset id
   * @param step The new step of asset
   * @param {boolean} updateList Flag to update or not the list
   */
  updateWorkflow(newId, step, updateList: boolean = true){
    let asset = this.assets.find(x => x.id == newId);

    if(this.tasksKanban) {
      this.tasksService.changeWorkflow(asset, step).then(result => {
          let newStep = this.workflowService.getWorkflowStep(AppSettings.WORKFLOW_TASK, step);
          let newState = this.workflowService.getStateWithWorkflowStep(AppSettings.WORKFLOW_TASK, step);

          asset.step = newStep;
          asset.state = newState;
          asset.workflow = AppSettings.WORKFLOW_TASK;
          asset.workflowStepName = newStep.name;
          asset.workflowStep = newStep.wf_step;

          if (updateList) {
            this.updateLists();
          }
      });
    } else {
      if (this.checkOpenTasksAndJobStep(asset, step)) {
        this.actionsService.changeKanbanWorflow(asset, step).then(result => {
          let newStep = this.workflowService.getWorkflowStep(AppSettings.WORKFLOW_ACTION, step);
          let newState = this.workflowService.getStateWithWorkflowStep(AppSettings.WORKFLOW_ACTION, step);

          asset.step = newStep;
          asset.state = newState;
          asset.workflow = AppSettings.WORKFLOW_ACTION;
          asset.workflowStepName = newStep.name;
          asset.workflowStep = newStep.wf_step;

          if (updateList) {
            this.updateLists();
          }
        });
      } else {
        this.notifications.show(this.translateService.instant('invalid_job_closed'), null, null, 5000, 'error-snack');
      }
    }
  }

  /**
   * Checks if the asset deadline has passed
   * @param deadLine The deadline to check
   * @returns A name of css class (string) depending on the deadline.
   * If expired: 'expired'. If expire today: 'aboutExpired'. Rest of cases: 'normal'
   */
  checkExpiration( deadLine ) {
    const hoursLeft = moment(deadLine).diff( Date.now(), 'hours' );
    return hoursLeft < 0 ? 'expired' : hoursLeft <= 24 ? 'aboutExpired' : 'normal';
  }
}
