import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DetailedRequest, RequestJob, RequestProject } from 'app/shared/model/request.model';
import { Observable } from 'rxjs';
import { RolesService } from '../user/roles.service';
import { HCMSService } from '../satellites/hcms.service';
import { OCService } from '../satellites/oc.service';
import { Mail } from 'app/shared/model/mail.model';
import { AppSettings } from 'app/shared/app.settings';
import { MailService } from '../mail/mail.service';

/**
 * Service defined to manage all operations needed with Requests ({@link RequestObject})
 * 
 * The request listings are defined in a separate service for the listing, {@link ListsService}.
 */
@Injectable({
  providedIn: 'root'
})
export class RequestService {

  /**
   * Step in progress.
   */
  static readonly STEP_IN_PROGRESS = 10;
  /**
   * Step sent.
   */
  static readonly STEP_SENT = 20;
  /**
   * Closed
   */
  static readonly CLOSED = 30;

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   * @param datepipe Pipe with the date pipe used to format dates.
   * @param materialsService Service with the functions related to the materials.
   * @param actionsService Service with the functions related to the actions.
   * @param mimetypeService Service with the functions related to the mimetype.
   * @param ocService Service with the functions related to the online channel.
   */
  constructor(
    private hcmsService: HCMSService,
    public datepipe: DatePipe,
    private rolesService: RolesService,
    private mailService:  MailService,
    private ocService: OCService
  ) {}

  /**
   * Get request by id.
   * @param id Request id.
   * @returns Request. (Promise)
   */
  public getRequest(id) {
    return this.hcmsService.get().one('entity/request', id).get().toPromise();
  }

  /**
   * Update request by id.
   * @param id Request id.
   * @param request Request.
   * @returns Request updated. (Promise)
   */
  public updateRequest(id, request, currentUser?) {
    return this.getRequest(id).then(updated => {
      updated.route = 'entity/request';
      if (request && request.step) {
        updated.workflowStep = +request.step;
      }
      /*if (request && request.channel) {
        updated.channel = request.channel;
      }
      if (request && request.objective) {
        updated.objective = request.objective;
      }*/
      if (request && request.priorityCntr) {
        updated.priorityCntr = request.priorityCntr;
      }
      if (request && request.markForDeletion) {
        updated.markForDeletion = request.markForDeletion;
      }
      if ( request && request.budget || typeof request.budget === 'number') {
        updated.budget = request.budget;
      }
      if ( request && request.info ) {
        updated.info = request.info;
      }
      /*if ( request && request.deliverable ) {
        updated.deliverable = request.deliverable;
      }*/
      if ( request && request.client ) {
        updated.client = request.client
      }
      if ( request && request.startDate ) {
        updated.startDate = request.startDate;
      }
      if ( request && request.deadline ) {
        updated.deadline = request.deadline;
      }

      if (request && request.channel_hubz) {
        updated.channel_hubz = request.channel_hubz;
      }

      if (!updated.removeNotification) {
        updated.removeNotification = [];
      }

      if (request && (!request.removeNotification || request.removeNotification.length === 0)) {
        updated.removeNotification.splice(updated.removeNotification.indexOf(currentUser.id), 1);
      }

      if (request && request.removeNotification && request.removeNotification.length > 0) {
        updated.removeNotification.push(currentUser.id);
      }

      return updated.put().toPromise().then( requestUpdated => {
        if(this.rolesService.checkRole(currentUser, RolesService.POWER_ROLE)){
          this.sendUpdateRequestEmail(requestUpdated.plain());
        }
        return requestUpdated;
      });
    });
  }



  /**
   * Create detailed request.
   * @param detailedRequest Detailed request.
   * @returns Request.
   */
  public createDetailedRequest(detailedRequest: DetailedRequest) {
    return this.hcmsService.get().all('entity/request').post(detailedRequest).toPromise();
  }

  /**
   * Update detailed request.
   * @param {string} id Detailed request ID. 
   * @param detailedRequest Detailed request.
   * @returns Detailed request updated. (promise)
   */
  public updateDetailedRequest(id, detailedRequest) {
    return this.hcmsService.get().one('entity/request', id).get().toPromise().then(updated => {
      updated = Object.assign(updated, detailedRequest);
      return updated.put().toPromise();
    });
  }

  /**
   * Change request workflow
   * @param {number} assetId Asset ID.
   * @param {number} step Request step
   * @returns Request edited.
   */
  public changeRequestWorkflow(assetId: number, step: number) {
    return this.hcmsService.get().one('entity/request', assetId).get().toPromise().then(updated => {
      updated.workflowStep = +step;
      return updated.put();
    });
  }

  /**
   * Create request project.
   * @param project Project to be created.
   * @returns Project created. (Promise)
   */
  public createRequestProject(project : RequestProject){;
    return this.hcmsService.get().all('entity/request_project').post(project).toPromise();
  }

  /**
   * Crete request job.
   * @param job Job to be created.
   * @returns Job created.
   */
  public createRequestJob(job : RequestJob){;
    return this.hcmsService.get().all('entity/request_job').post(job).toPromise();
  }

  /**
   * Get channel values.
   * @returns Channel values.
   */
  public getChannelValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:cntr.channel_hubz/enabled/1').getList();
  }

  /**
   * Get client values.
   * @returns Client values.
   */
  public getClientValues(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/sp:cntr.client').getList();
  }

  private sendUpdateRequestEmail(request) {
    let usersToNotify = request.manager_rel? [...request.owner_rel, ...request.manager_rel] : request.owner_rel;
    // let usersToNotify = request.removeNotification ? targets.filter(target => !request.removeNotification.includes(target)) : targets;

    if (usersToNotify.length > 0) {
        usersToNotify.forEach((user) => {
          
          let mail: Mail = new Mail(AppSettings.MAIL_UPDATE_REQUEST + '_' + request.id + '_' +user, AppSettings.MAIL_UPDATE_REQUEST, +request.id, +user);
          this.mailService.saveMail(mail);
        })
    }
  }
}