import { Asset } from "./asset.model";
import { Action } from "./action.model";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Page } from "./page.model";

/**
 * This class defines the material structure
 */
export class Material extends Asset{
    /**
     * The {@link Preview} of the material
     */
    preview: Preview = new Preview();

    /**
     * True if material is selected to display
     */
    selected: boolean = false;

    /**
     * The downloadLink to donwload the material
     */
    downloadLink : string;

    /**
     * The mime type of the material
     */
    mimetype: string;

    /**
     * Array of ids that contains the parents of the material
     */
    parents: number[] = new Array();

    /**
     * Array of objects that contains the info of the parents of the material
     */
    parents_info: any[] = new Array();

    /**
     * Array of {@link Page} if the material has more than one page
     */
    pages: Page [] = [];

    /**
     * The rawType of the material
     */
    rawType: string;

    /**
     * The date until the material is valid
     */
    valid_until: string;

    /**
     * The dpi of the material
     */
    dpi: number;

    /**
     * The dimensiions of the material
     */
    dimensions: string;

    /**
     * The index of the material
     */
    index: number;

    /**
     * True if the material is currently saving
     */
    saving: boolean = false;

    /**
     * True if the material has to reload the preview
     */
    reloadPreview: boolean = false;

    /**
     * The dic of the material
     */
    dic: string;

    /**
     * Array of multiple dic
     */
    multidic: Array<any>;

    // Conceptshare features
    /**
     * True if the asset is ready
     */
    approvalAssetReady: boolean;

    /**
     * The approvalAssetId of the material
     */
    approvalAssetId: string;

    /**
     * The approvalDeepLink of the material
     */
    approvalDeeplink: string;

    /**
     * The approvalReviewId of the material
     */
    approvalReviewId: string;

    /**
     * The approvalProjectId of the material
     */
    approvalprojectId: string;

    /**
     * The approvalBy of the material
     */
    approvalBy: string;

    /**
     * The approvalTime of the material
     */
    approvalTime: string;

    /**
     * Array of {@link ApprovalFeedback}
     */
    approvalFeedback: ApprovalFeedback[] = new Array();

    /**
     * The approvalContentVersion of the material
     */
    approvalContentVersion: number;

    /**
     * The {@link Action} that the material belongs to
     */
    action: Action;

    /**
     * The saveUrl of the material
     */
    safeUrl: SafeResourceUrl;

    /**
     * The default workflow step of the material
     */
    workflowStep: number;

    /**
     * The communication of the material
     */
    communication;

    /**
     * The external url of the material
     */
    externalUrl;

    /**
     * True if the material is a svg file
     */
    svgfile: boolean;

    /**
     * The file length of the material
     */
    filelength: number;

    /**
     * The step time of the material
     */
    step_time: number = -1;

    /**
     * The content version of the material
     */
    content_version;

}

/**
 * Defines the material preview data
 */
class Preview {
    /**
     * The downloadLink of the Preview
     */
    downloadLink : string;

    /**
     * The mime type of the Preview
     */
    mime : string;

    /**
     * The {@link Size} of the Preview
     */
    size : Size;
}

/**
 * Defines the material size data
 */
class Size {
    /**
     * The dpi of the Size
     */
    dpi : number;

    /**
     * The height of the Size
     */
    height : number;

    /**
     * The width of the Size
     */
    width : number;
}

/**
 * Defines the approval feedback preview data
 */
class ApprovalFeedback {
    /**
     * The date of the ApprovalFeedback
     */
    date: string;

    /**
     * The user of the ApprovalFeedback
     */
    user: string;

    /**
     * The comment of the ApprovalFeedback
     */
    comment: string;

    /**
     * The approvalState of the ApprovalFeedback
     */
    approvalState: string;

    /**
     * The approvalBy of the ApprovalFeedback
     */
    approvalBy: string;

    /**
     * The approvalTime of the ApprovalFeedback
     */
    approvalTime: string;

    /**
     * Array of ApprovalFeedback with the replies
     */
    reply: ApprovalFeedback[] = new Array();

}