import {Injectable} from '@angular/core';
import {AppSettings} from 'app/shared/app.settings';
import {BriefingService} from '../briefing/briefing.service';
import {HCMSService} from '../satellites/hcms.service';
import {TasksService} from '../tasks/tasks.service';
import {RolesService} from '../user/roles.service';
import {UserService} from '../user/user.service';
import {WorkflowService} from '../workflows/workflow.service';
import * as _ from 'lodash';
import { ActionsService } from '../actions/actions.service';

/**
 * Service defined to manage the necessary operations in the listings of the different assets
 *  ({@link Task}, {@link Action}, {@link Project}, etc).
 */
@Injectable({
  providedIn: 'root'
})
export class ListsService {

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   * @param workflowService Service with the functions related to the workflow.
   * @param usersService Service with the functions related to the users.
   * @param rolesService Service with the functions related to the roles.
   */
  constructor(
    private hcmsService: HCMSService,
    private workflowService: WorkflowService,
    private usersService: UserService,
    private rolesService: RolesService,
  ) {}

  /**
   * Obtain a list of projects filtered by user used by Project overview
   * 
   * @returns A list of projects
   */
  public async getProjects() : Promise<any> {
    let currentUser = this.usersService.getCurrentUser();
    let isPower = this.isPower(currentUser);
    let projects = await this.getList(currentUser, isPower, 'type="project."');
    return projects.filter(project => !project.briefings || (project.briefings && project.briefings.length > 0 && project.briefings[0].workflowStep == BriefingService.STEP_SENT));
  }

  /**
   * Obtain a list of jobs filtered by user used by Job overview and Job planner (kanban)
   * 
   * @returns A list of jobs
   */
  public async getJobs() : Promise<any> {
    let currentUser = this.usersService.getCurrentUser();
    let isPower = this.isPower(currentUser);
    let jobs = await this.getList(currentUser, isPower, 'type="order."');
    return jobs.filter(job => !job.briefings || (job.briefings && job.briefings.length > 0 && job.briefings[0].workflowStep == BriefingService.STEP_SENT));
  }

  /**
   * Obtain a list of tasks filtered by user used by Task overview and Task planner (kanban)
   * 
   * @returns A list of tasks
   */
  public async getTasks() : Promise<any> {
    let currentUser = this.usersService.getCurrentUser();
    let isPower = this.rolesService.checkRole(currentUser, RolesService.POWER_ROLE);
    return await this.getList(currentUser, isPower, '(type>"task."%26(!request_task %7C request_task))');
  }

  /**
   * Get a list of tasks filtered by team and agency
   *
   * @returns A list of tasks
   */
  public async getTasksAssignedToTeam(id): Promise<any> {

    /* const currentUser = this.usersService.getCurrentUser();
    let agencyIdsQuery = '%26responsible_object.agencies=';
    const agenciesIds = this.usersService.getAgenciesId(currentUser);

    _.forEach(agenciesIds, (agency) => {
      agencyIdsQuery += agency + '|';
    });

    const query = agenciesIds.length > 0 ? agencyIdsQuery.substring(0, agencyIdsQuery.length - 1) : '';

    console.log(query);

    const isPower = this.rolesService.checkRole(currentUser, RolesService.POWER_ROLE);
    return await this.getList(currentUser, isPower,
      '(type>"task.production."%26(!request_task %7C request_task=false)%26responsible_object.type="person.group."' + query + '%26user_targets_ids='+ id +')');*/

    
    let currentUser = this.usersService.getCurrentUser();
    let isPower = this.rolesService.checkRole(currentUser, RolesService.POWER_ROLE);
    return await this.getList(currentUser, isPower, '(type="task.production."%26(!request_task %7C request_task=false))%26responsible_object.type="person.group."%26user_targets_ids='+ id);

    
  }

  /**
   * Get a list of teams
   *
   * @returns A list of tasks
   */
  public async getTeams(): Promise<any> {
    const currentUser = this.usersService.getCurrentUser();
    const agencies = _.uniq(_.map(currentUser.agencyMainContact, (agency) => agency.id).concat(_.map(currentUser.agencyEmployee, (agency) => agency.id)));
    const isPower = this.rolesService.checkRole(currentUser, RolesService.POWER_ROLE);
    return await _.filter(await this.getList(currentUser, isPower, '(type="person.group.")'), (team) =>
      _.includes(agencies, _.first(team.agencies)));
  }

  /**
   * Obtain a list of briefings filtered by user used in briefings panel
   * 
   * @returns A list of briefings
   */
  public async getBriefings() : Promise<any> {
    let currentUser = this.usersService.getCurrentUser();
    let isPower = this.rolesService.checkRole(currentUser, RolesService.POWER_ROLE);
    return await this.getList(currentUser, isPower, 'type="briefing."');
  }

  /**
   * Obtain a list of requests filtered by user used in requests panel
   * 
   * @returns A list of requests
   */
  public async getRequests() : Promise<any> {
    let currentUser = this.usersService.getCurrentUser();
    // let isPower = this.rolesService.checkRole(currentUser, RolesService.POWER_ROLE);
    return await this.getList(currentUser, false, 'type="request."');
  }

  /**
   * Obtain the child asset of an asset
   * 
   * @param item The asset to obtain the children
   * @returns A list of an assets (children of item)
   */
  public async getChilds(item: any) : Promise<any> {

    let currentUser = this.usersService.getCurrentUser();
    let isPower = this.isPower(currentUser);
    let childs =  await this.getList(currentUser, isPower, 'parents=' + item.id +'|task_parents=' + item.id);
    childs = childs.filter(x => x.type == 'project.' || x.type == 'order.' || x.type.startsWith('task.'));
    let parents = [+item.id];
    if (item.parents) {
      parents = [...parents, ...item.parents];
    }
    childs.forEach(x => {
      x.parents = parents
      x.level = item.level? item.level+1 : 1;
    });
    return childs;
  }

  /**
   * Checks if the current user is a power user.
   * @param currentUser User to be checked.
   * @returns {boolean} True if a power user, else false.
   */
  isPower(currentUser) : boolean {
    return this.rolesService.checkRole(currentUser, RolesService.POWER_ROLE);
  }

  /**
   * Obtain a list of projects filtered by user and used by gantt panel
   * 
   * @returns A gantt list of pojects
   */
  public async getGanttProjects() : Promise<any> {
    const currentUser = this.usersService.getCurrentUser();
    const isPower = this.isPower(currentUser);
    return await this.getGanttListFromSchema(currentUser, isPower, 'entity/ganttProjects');
  }

  /**
   * Obtain a list of jobs filtered by user and used by gantt panel
   * 
   * @returns A gantt list of jobs
   */
  public async getGanttJobs() : Promise<any> {
    const currentUser = this.usersService.getCurrentUser();
    const isPower = this.isPower(currentUser);
    return await this.getGanttListFromSchema(currentUser, isPower, 'entity/ganttJobs');
  }

  /**
   * Obtain a list of tasks filtered by user and used by gantt panel
   * 
   * @returns A gantt list of tasks
   */
  public async getGanttTasks() : Promise<any> {
    const currentUser = this.usersService.getCurrentUser();
    const isPower = this.isPower(currentUser);
    return await this.getGanttListFromSchema(currentUser, isPower, 'entity/ganttTasks');
  }

  /**
   * The generic method of calling to any gantt list
   * 
   * @param currentUser The user logged
   * @param isPower Boolean value that clarifies if is a power user
   * @param {string} query The query where the item typ is defined by the route.
   * @param {numbre} page 
   * @returns A gantt list of items (projects, jobs or tasks)
   */
  private async getGanttListFromSchema(currentUser, isPower, query: string, page : number = 0) : Promise<any> {
    let items = await this.hcmsService.get().one(query + '?limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise().then(async data => {
      let result: Array<any> = data.result;
      if (data.offset + data.limit < data['total-count']) {
        let moreItems = await this.getGanttListFromSchema(currentUser, isPower, query,data.offset + data.limit);
        result = [...result, ...moreItems];
      }
      return result;
    });

    if (isPower){
      items = items.filter(x =>
          ((x.owner_ids != null && x.owner_ids.includes(+currentUser.id)) ||
              (x.manager_ids != null && x.manager_ids.includes(+currentUser.id)) ||
              (x.user_targets_ids != null && x.user_targets_ids.includes(+currentUser.id))
              && (x.workflowStep != null && x.workflowStep != TasksService.TASK_WORKFLOW_PLANNED))
      );
    }

    items.forEach(x => this.fillGanttListItemData(x));
    return items;
  }

  /**
   * The generic method of calling to any list
   * 
   * @param currentUser The user logged
   * @param isPower Boolean value that clarifies if is a power user
   * @param {string} query The query where the item typ is defined by the route.
   * @param {numbre} page 
   * @returns A list of items (projects, jobs or tasks)
   */
  private async getList(currentUser, isPower, query: string, page : number = 0) : Promise<any> {
    let items = await this.hcmsService.get().one('entity/list?query=' + query + '&limit=' + AppSettings.LIMIT + '&offset=' + page).get().toPromise().then(async data => {
      
      let result: Array<any> = data.result;
      if (data.offset + data.limit < data['total-count']) {
        let moreItems = await this.getList(currentUser, isPower, query, data.offset + data.limit);
        result = [...result, ...moreItems];
      }
      return result;
    });

    if (isPower){

      items = items.filter(x =>
          ((x.owner_ids != null && x.owner_ids.includes(+currentUser.id)) ||
          (x.manager_ids != null && x.manager_ids.includes(+currentUser.id)) ||
          (x.user_targets_ids != null && x.user_targets_ids.includes(+currentUser.id)))
      );

      if(query.indexOf('task.') > -1 ){
        items = items.filter(x => x.workflowStep != null && x.workflowStep != TasksService.TASK_WORKFLOW_PLANNED);
      }
    }

    items.forEach(x => this.fillListItemData(x));
    return items;
  }

  /**
   * Fill gantt list item data.
   * @param item item to be filled.
   */
  private fillGanttListItemData(item){
    item.step = this.workflowService.getWorkflowStep(item.workflow, item.workflowStep);
    item.priority = (item.priority!=null)?item.priority:4;
    item.priorityCntr= (item.priorityCntr!=null)?item.priorityCntr:'low';

    if (item.owner_ids != null && item.owner_ids.length > 0) {
      item.owner = this.usersService.getUserData(item.owner_ids[0]);
    }

    if (item.manager_ids != null && item.manager_ids.length > 0) {
      item.manager = this.usersService.getUserData(item.manager_ids[0]);
    }
    if (item.manuallyScheduled) item.manuallyScheduled = 'false';

    if (item.user_targets_ids != null && item.user_targets_ids.length > 0) {
      const targets = [];
      item.user_targets = Array.from(new Set(item.user_targets_ids));
      item.user_targets.forEach(target => targets.push(this.usersService.getUserData(target)));
      item.user_targets = targets;
      if (targets.length > 0){
        item.target = targets[0];
      }
    }

    if (item.children){
      item.children = item.children.filter(item => item.type && (item.type == 'order.' || item.type.startsWith('task.')));
    } else {
      item.children = [];
    }
    if (item.parents) {
      item.parents = item.parents.filter(item => item.type && (item.type == 'order.' || item.type == 'project.'));
    } else {
      item.parents = [];
    }
    item.children.forEach(x => this.fillGanttListItemData(x));
  }

  /**
   * Fill list item data.
   * @param item Item to be filled.
   */
  fillListItemData(item){

    item.item_name = item.name;
    item.step = this.workflowService.getWorkflowStep(item.workflow, item.workflowStep);
    item.priority = (item.priority!=null)?item.priority:4;
    
    const priority = ActionsService.PRIORITIES.find( priority => priority.key === item.priority );
    item.priorityCntr= (item.priorityCntr!=null)?item.priorityCntr: item.priority? priority.name.toLowerCase() : 'low';

    if (item.owner_ids != null && item.owner_ids.length > 0) {
      item.owner = this.usersService.getUserData(item.owner_ids[0]);
    }

    if (item.manager_ids != null && item.manager_ids.length > 0) {
      item.manager = this.usersService.getUserData(item.manager_ids[0]);
    }

    if (item.type == 'order.' && item.parents != null){
      item.projects = item.parents.filter(x => x.type == 'project.');
    }

    if (item.type.startsWith('task.') && item.user_targets_ids != null) {
      let targets = [];
      item.user_targets = Array.from(new Set(item.user_targets_ids));
      item.user_targets.forEach(target => targets.push(this.usersService.getUserData(target)));
      item.user_targets = targets;
      if (targets.length > 0){
        item.target = targets[0];
      }
    }

    if (item.type.startsWith('task.')){
      if(item.responsible){
        item.responsibleData = this.usersService.getUserData(item.responsible);
      } else if(item.owner){
        item.responsibleData = this.usersService.getUserData(item.owner);
      }

      if (item.task_parents != null){
        item.jobs = item.task_parents.filter(x => x.type == 'order.');
      }
    }

    if (item.childs){
      item.childs = item.childs.filter(item => item.type && (item.type == 'order.' || item.type.startsWith('task.')));
    } else {
      item.childs = [];
    }

    if (item.task_childs){
      item.childs = [...item.childs, ...item.task_childs.filter(item => item.type && (item.type == 'order.' || item.type.startsWith('task.')))];
    }
  }

}
