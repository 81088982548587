import { Component, OnInit } from '@angular/core';
import { AppSettings } from '../app.settings';

/**
 * @ignore
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

  isVisible(element:string) : boolean {
    let config = AppSettings.FOOTER_CONFIG.find(x => x.key === element);
    if (config) return config.visible;
    return false;
  }

}
