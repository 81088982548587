import { Asset } from './asset.model';
import { AppSettings } from '../app.settings';
/**
 * This class defines the briefings structure (for actions and projects)
 */
export class Briefing extends Asset {
    /**
     * Workflow of the briefing.
     */
    workflow: number = AppSettings.WORKFLOW_ID_BRIEFING;
    /**
     * {@link Preview} of briefing
     */
    preview: Preview;
    /**
     * link to download the asset of briefing
     */
    downloadLink: string;
    /**
     * parents of briefing
     */
    parents: number[] = new Array();
}

/**
 * Defines the structure for the briefing preview
 */
class Preview {
    /**
     * Link to download the assset
     */
    downloadLink: string;
    /**
     * preview asset mime type
     */
    mime: string;
    /**
     * preview asset size
     */
    size: Size;
}

/**
 * Defines the structure for the briefing size
 */
class Size {
    /**
     * dpi of an asset
     */
    dpi: number;
    /**
     * height of an asset
     */
    height: number;
    /**
     * width of an asset
     */
    width: number;
}


export class DetailedBriefing {

    /**
     * id of detaildBriefing
     */
    id: number;
    /**
     * name of detaildBriefing
     */
    name = 'Briefing';
    /**
     * type of detaildBriefing
     */
    type = 'briefing.';
    /**
     * domain of detaildBriefing
     */
    domain: string = AppSettings.DOMAIN;
    /**
     * workflow of detaildBriefing
     */
    workflow: number = AppSettings.WORKFLOW_BRIEFING;
    /**
     * workflow step of detaildBriefing
     */
    workflowStep: number;
    /**
     * info of detaildBriefing
     */
    info: string;
    /**
     * id of the {@link User} who created the detaildBriefing
     */
    createdByNew: number;
    /**
     * id of the {@link User} who modified the detaildBriefing
     */
    modifiedByNew: number;
    /**
     * user related to the detaildBriefing
     */
    owner_rel: Array<Number> = [];
    /**
     * priority of detaildBriefing
     */
    priorityCntr: string;
    /**
     * date deadline of detaildBriefing
     */
    deadline: string;

    // goes to: feature="sp:cntr.channel_hubz"
    /**
     * channel of detaildBriefing
     */
    channel: string;

    // this is "project" relation, I think?
    /**
     * projects parents of detaildBriefing
     */
    parents: Array<number>;

    // these might belong somewhere else:
    /**
     * width of (an asset from) detaildBriefing
     */
    width;
    /**
     * height of (an asset from) detaildBriefing
     */
    height;
    /**
     * depth of (an asset from) detaildBriefing
     */
    depth;
    /**
     * unit of (an asset from) detaildBriefing
     */
    unit;
    /**
     * depth of detaildBriefing
     */
    budget;
}
