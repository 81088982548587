import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { UserService } from 'app/shared/services/user/user.service';
import Utils from 'app/shared/utils/utils';

@Component({
  selector: 'app-all-budgets-widget',
  templateUrl: './all-budgets-widget.component.html',
  styleUrls: ['./all-budgets-widget.component.scss']
})
export class AllBudgetsWidgetComponent implements OnInit {
  
  /**
   * The asset to asseociate a budget
   */
   @Input() assetId;
  /**
   * Event to be launched when editing
   */
   @Output() isEditing = new EventEmitter<boolean>();

  /**
   * Indicates you are in edit mode
   */
   public editMode = false;
   /**
   * Indicates that data is loading
   */
  loadingData = false;
   /**
    * Indicates power user
    */
   public isPowerUser;
   /**
   /**
    * Indicates power user
    */
   public isManagerUser;
   /**
    * Current user
    */
   private currentUser;
   /**
    * Actions of a project
    */
   public actions
   /** 
    * Summary budgets
    */
   public summary;
   /**
   * Constructor
   * @param formBuilder Service with the functions related to the forms.
   * @param rolesService Service with the functions related to the roles.
   * @param userService Service with the functions related to the users.
   * @param actionsService Service with the functions related to the actions.
   */
  constructor(
    private formBuilder: FormBuilder,
    private rolesService: RolesService,
    private userService: UserService,
    private actionsService: ActionsService,
  ) { }

  ngOnInit() {
    this.loadingData = true;
    this.currentUser = this.userService.getCurrentUser();
    this.isManagerUser = this.rolesService.checkRole(this.currentUser, RolesService.PROJECT_MANAGER_ROLE);
    this.isPowerUser = this.rolesService.checkRole(this.currentUser, RolesService.POWER_ROLE);
    if(this.assetId){
      this.actionsService.getActionsByProject(this.assetId).then( actions => {
        if(actions){
          this.actions = actions.result
          this.getSummary();
          this.actions.forEach(action => {
            this.createForm(action)
          })
          this.loadingData= false;
        }
      })
    }
  }
  createForm(action){
    let {budget, additionalQuote, invoice, cost}= action
    action.budgetForm = this.formBuilder.group({
      'budget': [ {value: budget?  budget : 0, disabled: this.isManagerUser}, Validators.compose([])],
      'cost': [ cost?  cost : 0, Validators.compose([])],
      'additionalQuote': [ additionalQuote?  additionalQuote : 0, Validators.compose([])],
      'invoice': [ invoice?  invoice : 0, Validators.compose([])]
    })
    return action

  }
  /**
   * Change view to edit mode.
   * @param editMode event to set true o false de edit mode
   */
   public updateEditMode(editMode): void {
    this.editMode = editMode;
    this.isEditing.emit(editMode);
  }
  /**
   * Save the budget of an action.
   */
   public saveBudget(action): void {
    Utils.updatingWidget = true;
    action = Object.assign(action, action.budgetForm.value)
    let actionUpdate= {...action} 
    delete actionUpdate.budgetForm;
    this.actionsService.editAction(actionUpdate).then( data => {
      if (data) {
          let index = this.actions.findIndex(item=> action.id === item.id)
          this.actions.splice(index, 1, this.createForm(data))
          this.getSummary()
      }
    })
  }
    private getSummary(){
      this.summary = {
        invoice: 0,
        additionalQuote: 0,
        cost: 0,
        budget: 0,
      };
      this.actions.forEach(action=>{
        let {budget, additionalQuote, invoice, cost} = action

        this.summary.additionalQuote += additionalQuote ? additionalQuote : 0 
        this.summary.budget += budget ? budget : 0  
        this.summary.invoice += invoice ? invoice : 0 
        this.summary.cost += cost ? cost : 0 
      })
    }
  }
