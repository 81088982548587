import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { AssetService } from '../services/asset/asset.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Marker } from '../model/marker.model';
import { CommonService } from '../services/common/common.service';
import Utils from '../utils/utils';
import { MaterialsService } from '../services/materials/materials.service';
import { CanvasDraw } from '../model/canvasdraw.model';
import { ConfirmationDialogComponent } from '../dialogs/confirmation/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import * as CustomEditor from '../../ckeditor/ckeditor';

import { CommunicationService } from '../services/communication/communication.service';
import { AppSettings } from '../app.settings';
import { WorkflowService } from '../services/workflows/workflow.service';
import { TasksService } from '../services/tasks/tasks.service';
import { RolesService } from '../services/user/roles.service';
import { Router } from '@angular/router';
import { ApprovalService } from '../services/approval/approval.service';
/**
 * This component displays the comments and answers of an element (job, project, task, material, etc.).
 * It is also responsible for sending the task or assets with their respective bookmarks and comments from one user to another.
 */
@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {
  /** Object Math js */
  Math = Math;

  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;
  /** Item to be commented on */
  @Input() asset;
  /**  item parent element*/
  @Input() parent;
  /** Boolean true if it occupies a large part of the view, thus showing all user data.*/
  @Input() fullView;
  /** Markers added to a material to be sent together with comments. 
   * Only when the component is in a material view.
   */
  @Input() newMarkers: Marker[] = [];
  /** Boolean indicating when a marker is being added to the comment. */
  @Input() markerActive = false;
  /** page number on which you are located, **unused**  */
  @Input() page = 0;
  /** Boolean, indicating if there are multiple pages, because there are multiple elements. */
  @Input() multiPage = false;
  /** Boolean, indicating whether comments should be displayed. */
  @Input() showAllComments = false;
  /** True if the current {@link User} is the project manager of the team */
  @Input() isJobManager = false;
  /** Boolean, if true indicates that the asset has to be updated. */
  @Input() refresh = true;
  /** @ignore  */
  @Input() widget = false;
  /** Boolean, if true indicates that the user has permissions to attach files. */
  @Input() canAttach = false;
  /** Emits an event that tells the parent if it is attaching a file. */
  @Output() attachFileEvent = new EventEmitter<void>();
  /**
   * Emits an event to the parent to highlight the marker that corresponds to that comment.
   */
  @Output() commentEnter = new EventEmitter<string>();
  /** Emit an event to the parent to unhighlight the marker that corresponds to that comment */
  @Output() commentLeave = new EventEmitter<void>();
  /** Emits an event when the comment has been saved */
  @Output() commentSaved = new EventEmitter<any>();
  /** Emits an event when the comment has been changed */
  @Output() messageFilled = new EventEmitter<any>();
  /** Emits an event when comments have been uploaded. */
  @Output() loaded = new EventEmitter<void>();
  /** WYSIWYG editor */
  public Editor = CustomEditor;
  /** 
   * {@link AppSettings} imported class
   * This is the file where the configuration variables of the different elements 
   * (request, asset, job, tasks, etc) are located. 
   */
  public AppSettings = AppSettings;
  /** Boolean, is true if the asset being commented out requires some action */
  required: boolean = false;
  /**Boolean, is true if you are sending a comment or sending the asset to another user. */
  sending: boolean = false;
  /** Boolean, is true if a decision on the asset has already been made. */
  decisionTaken: boolean = true;
  /** Boolean, is true if page filtering is enabled. */
  filter: boolean = false;
  /** Boolean, is true if all comments with their answers are loaded. */
  historyLoaded = false;
  /** Content of an asset in a specific version */
  currentContentVersion;
  /** Boolean, is true when it is the first time the data is reloaded */
  firstRefresh = true;
  /**  id of current  material*/
  private currentMaterialId: number = -1;
  /**  status of current material */
  private currentWfStep: number = -1;
  /** new comment form*/
  public form: FormGroup;
  /** reply form*/
  public replyForm: FormGroup;
  /** update comment form */
  public formUpdate: FormGroup;
  /**edit comment form */
  public editCommentForm: FormGroup;

  private autoUpdated;
  private communication = [];
  private approvalFeedback = [];
  private sentNotificationTeam = [];
  /** boolean, is true if the new form is valid*/
  validForm = true;
  /**  boolean, is true if any of the elements of the asset are being updated.*/
  updating = false;
  /** array of answers */
  replyStatus = [];
  /** boolean, is true when a reply is being sent*/
  replying = false;
  /** boolean, is true if the reply form is valid*/
  validReplyForm = true;
  /** boolean, is true if the edit form is valid*/
  valideditCommentForm = true;
  /** timestamp of the new reply, it is used as identifier*/
  replyCommentTimestamp;
  /** commentaries with all the answers */
  orderCommunication = [];
  /** the current user*/
  public currentUser;
  /** boolean, is true if the asset manager has changed*/
  newResponsible: boolean = false;
  /** id of the new manager*/
  newResponsibleId;
  /** details of the new manager */
  newResponsibleData;
  /** id of the current responsible */
  responsibleId;
  /** grouping of task states */
  public taskStatuses = [];
  /** boolean, is true if you are editing a task */
  public editTaskStatus = false;
  /** boolean, is true if the comments widget is being updated. */
  updatingWidgetComment = false;
  /** the permissions that the current user has */
  permissions: any = {};
  
  /** boolean, true if you have write permissions */
  canWrite: boolean = true;
  /**
   * The constructor
   * @param usersService Service with the functions related to the users.
   * @param assetService Service with the functions related to the assets.
   * @param formBuilder Service with the functions related to the forms.
   * @param commonService Service with the common functions.
   * @param materialService Service with the functions related to the materials.
   * @param dialog  Service with the functions related to the dialog.
   * @param communicationService Service with the function reelated to comments
   * @param router Service with the functions related to the router.
   * @param rolesService Service with the functions related to the roles.
   * @param taskService Service with the functions related to the tasks.
   * @param workflowsService Service with the functions related to the workflows.
   * @param approvalService Service with the functions related to the approval.
   */
  constructor(private usersService: UserService,
    private assetService: AssetService,
    public formBuilder: FormBuilder,
    public commonService: CommonService,
    public materialService: MaterialsService,
    public dialog: MatDialog,
    public communicationService: CommunicationService,
    public router: Router,
    private rolesService: RolesService,

    private taskService: TasksService,
    private workflowsService: WorkflowService,
    private approvalService: ApprovalService 
  ) { }
  /**
   * Initialize the component,
   * create the forms and get the comments 
   */
  ngOnInit() {

    this.currentUser = this.usersService.getCurrentUser();

    this.currentContentVersion = this.asset.content_version;

    this.form = this.formBuilder.group({
      'message': [null, null]
    });

    this.replyForm = this.formBuilder.group({
      'message': [null, null]
    });

    this.formUpdate = this.formBuilder.group({
      'correctionDone': [null, null]
    });

    this.editCommentForm = this.formBuilder.group({
      'message': [null, Validators.required]
    });

    this.communicationService.getCommunication(this.asset.id, this.asset.type).then(data => {
      this.asset.communication = data.communication;
      this.initComments();
    });

    //this.refreshComments();

    this.permissions = this.usersService.getPermissions('approval');
    this.canWrite = !(this.rolesService.isPowerUser(this.currentUser) && this.asset && this.asset.responsible && +this.asset.responsible != +this.currentUser.id);
    this.responsibleId = this.asset.responsible;
    this.checkStatus();
    this.checkNewResponsible();
  }
  /** Method that is called when the component is destroyed. */
  ngOnDestroy() {
    this.refresh = false;
  }
  /** Emits an event that tells the parent if it is attaching a file. */
  attachFile() {
    this.attachFileEvent.emit();
  }
  /**
   * Checks the status of the asset in case it is a task {@link Task}
   */
  checkStatus(){
    // this.taskStatuses = TasksService.CHANGE_STATUS.map(x => this.workflowsService.getWorkflowStep(AppSettings.WORKFLOW_TASK, x));
    // this.editTaskStatus = [RolesService.POWER_ROLE].some(role => this.rolesService.checkRole(this.currentUser, role));

    if (this.asset.request_task) {
      this.editTaskStatus = (this.asset.responsible && +this.asset.responsible == +this.currentUser.id) && TasksService.REQUEST_TASK_STATUS.some(x => x.key == this.asset.status && x.change.some(y => this.currentUser.roles.indexOf(y) > -1));
    } else {
      this.editTaskStatus = (this.asset.responsible && +this.asset.responsible == +this.currentUser.id) && TasksService.TASK_STATUS.some(x => x.key == this.asset.status && this.currentUser.roles.indexOf(x.change) > -1);
    }

    const showable = ['task.cost_estimate.', 'task.final_approval.'].some(type => type === this.asset.type);
    if (showable && this.editTaskStatus && this.asset.status === 'open') {
      this.decisionTaken = false;
    }
    this.taskStatuses = this.asset.request_task? TasksService.REQUEST_TASK_STATUS.filter(x => x.selectable.some(y => this.currentUser.roles.indexOf(y) > -1))
                                                : TasksService.TASK_STATUS.filter(x => this.currentUser.roles.indexOf(x.selectable) > -1);
  }
  /**
   * Gets all comments and their respective responses from the asset.
   */
  initComments() {
    this.fillData();
    this.fillDataReply();
    this.orderCommunication = Utils.cloneArray(this.asset.communication);          
    this.orderCommunicationReply(this.orderCommunication);

    if (this.asset.type && this.asset.type !== 'project.' && this.asset.type !== 'order.' && !this.asset.type.startsWith('task.')) {
      this.commonService.getHistory(this.asset.id).subscribe(history => {

        let minVersion = Math.min.apply(null, history.versions ? history.versions.map(x => x.version) : []);
        let maxContentVersion = Math.max.apply(null, history.versions ?  history.versions.map(x => x.content_version): []);

        if (!this.asset.content_version) {
          this.currentContentVersion = maxContentVersion;
          this.asset.content_version = maxContentVersion;
        }

        history.versions.forEach(x => {

          if (x.version == x.content_version || (x.version == minVersion && minVersion > x.content_version)) {

            if (this.asset.communication && this.asset.communication.length > 0) {

              let comments = this.asset.communication
                .filter(y => y.timestamp > (new Date(x.modified_date)).getTime());

              if (comments) {
                comments.forEach(y => {
                  y.content_version = x.content_version;
                });

              }

              this.fillData();
              this.fillDataReply();

              this.orderCommunication = Utils.cloneArray(this.asset.communication);
              this.orderCommunicationReply(this.orderCommunication);

            } else if (this.asset.approvalFeedback && this.asset.approvalFeedback.length > 0) {

              let comments = this.asset.approvalFeedback
                .concat()
                .sort((a, b) => b.timestamp - a.timestamp)
                .filter(y => y.timestamp < (new Date(x.modified_date)).getTime());

              if (comments) {
                comments.forEach(y => {
                  y.content_version = x.content_version;
                });

              }

              this.approvalFeedback = this.asset.approvalFeedback;
            }
          }
        });
        this.historyLoaded = true;
        this.loaded.emit();
      });

    } else {
      this.historyLoaded = true;
      this.loaded.emit();
    }
    this.scrollToBottom();
  }

  // This function is generic and must be active
  /**
   * Check if the asset has changed and if so, update it.
   */
  refreshComments() {
    if (this.refresh) {
      if (this.asset && this.asset.id) {
        this.assetService.getUpdateAsset(this.asset).then(mat => {

          if (mat && mat.id) {
            this.asset = Object.assign(this.asset, mat.plain());
          }
        });
      }

      let refreshTime = 0;

      if (this.firstRefresh) {
        refreshTime = 1000;
        this.firstRefresh = false;
      } else {
        refreshTime = 10000;
      }

      setTimeout(() => {
        this.refreshComments();
      }, refreshTime);
    }
  }
  /**
   * Check for changes in the materials.
   */
  ngDoCheck() {
    if (this.asset.content_version && this.currentContentVersion != this.asset.content_version) {
      this.currentContentVersion = this.asset.content_version;
    }

    if (this.currentMaterialId != this.asset.id || this.currentWfStep != this.asset.workflowStep) {
      this.currentMaterialId = this.asset.id;
      this.currentWfStep = this.asset.workflowStep

      this.fillData();
      this.fillDataReply();
    }

    if (this.autoUpdated != this.asset.autoUpdated) {

      this.autoUpdated = this.asset.autoUpdated;

      if(this.responsibleId != +this.asset.responsible) {
        this.permissions = this.usersService.getPermissions('approval');
        this.canWrite = !(this.rolesService.isPowerUser(this.currentUser) && this.asset && this.asset.responsible && +this.asset.responsible != +this.currentUser.id);

        this.checkStatus();
        if (this.newResponsible) {
          this.newResponsible = false;
          this.newResponsibleId = null;
          this.newResponsibleData = null;
        }
        this.checkNewResponsible();
      }

      if (this.asset.communication) {

        this.asset.communication.forEach(x => {

          let comment = this.communication.find(y => y.userAsset == x.userAsset && y.entry == x.entry && y.timestamp <= x.timestamp);
          if (comment && comment.content_version) {
            x.content_version = comment.content_version;
          } else {
            x.content_version = this.asset.content_version;
          }

        });
      }


      if (this.asset.approvalFeedback) {
        this.asset.approvalFeedback.forEach(x => {
          let comment = this.approvalFeedback.find(y => y.userAsset == x.userAsset && y.entry == x.entry && y.timestamp <= x.timestamp);
          if (comment && comment.content_version) {
            x.content_version = comment.content_version;
          } else {
            x.content_version = this.asset.content_version;
          }
        });
      }
    }
  }
  /**
   * @ignore
   */
  statusChanged(event){
    if (event && event.value){
      let value = event.value;
      if ((value == 'approved' || value == 'rejected') && this.asset.owner != null){
        if (this.asset.request_task) {
          this.checkNewResponsible();
        } else {
          this.asset.responsible = +this.asset.owner.id;
        }
        this.decisionTaken = true;
      } else if(value == 'open' && this.asset.targets != null && this.asset.targets.length > 0) {
        this.checkNewResponsible();
        this.decisionTaken = false;
      }
    }
  }
  /**
   * Send a comment, if it is a material add and a marker has been set add it.
   */
  async sendComment() {

    this.sending = true;

    if (!this.asset.communication) {
      this.asset.communication = [];
    }

    if (this.asset.approvalTime) {
      this.asset.approvalTime = (new Date(this.asset.approvalTime)).getTime();
    }

    this.asset.communication.forEach(comunication => {
      if (!comunication.entry) {
        comunication.entry = '';
      }
    });

    let markers = [];

    if (this.newMarkers.length > 0) {
      markers = await Promise.all(this.newMarkers.map(async x => {

        let newMarker = { 'x': Math.round(x.getRelativePosition().x), 'y': Math.round(x.getRelativePosition().y), 'color': x.color, 'page': Math.round(x.page), 'shape': x.shape, 'deleted': x.deleted, 'assetId': '' };
        if (x.endpoint) {
          newMarker['endpoint'] = x.endpoint;
        }

        if (x.shape === 'hand_draw') {

          let fileFormData = new FormData();

          let newCanvasDraw: CanvasDraw = new CanvasDraw();

          newCanvasDraw.name = 'canvasDraw_' + (new Date()).getTime();
          newCanvasDraw.parent = [this.asset.id];

          newCanvasDraw.downloadLink = "formdata:file0";

          const file = await fetch(x.canvas.nativeElement.toDataURL())
            .then(res => res.blob())
            .then(blob => {
              return new File([blob], newCanvasDraw.name, { type: "image/png" });
            });

          fileFormData.append("entity", JSON.stringify(newCanvasDraw));
          fileFormData.append("file0", file);

          const canvasdraw: any = await this.materialService.saveCanvasDraw(fileFormData);

          newMarker.assetId = canvasdraw.id;

        } else {
          delete newMarker.assetId;
        }

        return newMarker;
      }));
    }

    let object = {
      'entry': this.form.value.message,
      'actionRequired': this.required,
      'userAsset': +this.usersService.getCurrentUser().id,
      'timestamp': Math.round(Date.now() / 1000) * 1000,
      'markers': markers,
      'page': this.page,
      'content_version': this.currentContentVersion
    };

    this.asset.communication.push(object);
    if (this.newResponsible && this.asset.type.startsWith('task.')) {
      this.setNewResponsible();
    }

    this.communicationService.saveComment(this.asset, object, this.asset.status).then(result => {

      this.form.reset();
      //this.required = false;
      let request_task = this.asset.request_task;

      this.editAsset(result);

      if (this.asset.type == 'project.' || this.asset.type == 'order.') {
        this.usersService.getTeam(this.asset.id).then(data => {
          this.usersService.getTeamRoles().forEach(role => {
            this.saveNotification(this.asset[role.key], object, this.asset);
          });
          this.sentNotificationTeam = [];
        });
      }

      if (this.asset.type.startsWith('task.')) {
        if (this.asset.jobs && this.asset.jobs.length > 0 && request_task) {
            this.router.navigate(['/', 'requests', 'detail', this.asset.jobs[0]]);
        } else if (this.asset.jobs && this.asset.jobs.length > 0 && !request_task) {
            this.router.navigate(['/', 'jobs', 'detail', this.asset.jobs[0]]);
        }
        this.commentSaved.emit(this.asset);

        if (this.asset.responsible) {
          this.taskService.sendNewTaskAssignedMail(this.asset);
        }

      } else {
        this.commentSaved.emit(this.asset);
        this.scrollToBottom()
      }
    });
  }

  /**
   * Checks if message change.
   */
  messageKeyPressed(event: KeyboardEvent): void {
    this.messageFilled.emit(this.form.value.message);
  }

  /**
   * Check who is responsible for the asset.
   */
  checkNewResponsible() {
    if (this.asset && this.asset.responsible && +this.asset.responsible == +this.currentUser.id) {
      let responsible : number = +this.asset.responsible;

      let nextResponsible = +this.asset.owner_rel[0];
      if (+this.currentUser.id === +this.asset.owner_rel[0]) {
        nextResponsible = +this.asset.targets[0];
      }

      if (responsible != nextResponsible) {
        this.newResponsibleId = nextResponsible;
        this.newResponsibleData = this.usersService.getUserData(nextResponsible);
        this.newResponsible = true;
      }
    }
  }
  /**
   * Change of the responsible of asest
   */
  async setNewResponsible(){
    let isManager : boolean = this.rolesService.isManager(this.newResponsibleData);
    let step : number = isManager? TasksService.TASK_WORKFLOW_VERIFICATION : TasksService.TASK_WORKFLOW_IN_PROGRESS;

    this.asset.responsible = this.newResponsibleId;
    this.asset.workflow = AppSettings.WORKFLOW_TASK;
    this.asset.workflowStep = +step;

  }

  /**
   * Send a reply to a comment.
   * @param comment Reply to comment
   */
  sendReply(comment) {
    if(comment.isReply){
      delete comment.isReply
    }
    let replyMessage = document.querySelector('.reply' + comment.timestamp + ' .ck-content').textContent;
    this.replyCommentTimestamp = comment.timestamp;

    if (!replyMessage) {
      this.validReplyForm = false;
    } else {
      this.validReplyForm = true;
    }

    if (this.validReplyForm) {
      this.replying = true;

      let reply = {
        'entry': replyMessage,
        'userAsset': +this.usersService.getCurrentUser().id,
        'timestamp': Math.round(Date.now() / 1000) * 1000,
        'content_version': this.currentContentVersion,
        'replyTo': comment.timestamp
      };

      let commentIndex = this.findCommentParent(comment);

      if (!this.asset.communication[commentIndex].reply) {
        this.asset.communication[commentIndex].reply = [];
      }
      this.asset.communication[commentIndex].reply.push(reply);

      delete this.asset.approvalTime;

      this.communicationService.saveReply(this.asset, comment, reply).then(result => {
        this.replyForm.reset();

        this.asset = result;
        this.fillData();
        this.fillDataReply();
        this.orderCommunication = Utils.cloneArray(this.asset.communication);
        this.orderCommunicationReply(this.orderCommunication);
        this.replying = false;
        this.removeReplyStatus(comment.timestamp);

        this.commentSaved.emit(this.asset.communication);

        if (this.asset.type == 'project.' || this.asset.type == 'order.') {
          this.usersService.getTeam(this.asset.id).then(data => {
            this.usersService.getTeamRoles().forEach(role => {
              this.saveNotification(this.asset[role.key], reply, this.asset);
            });
            this.sentNotificationTeam = [];
          });
        }
      });
    }
  }
  /**
   * removes the status that a comment is a reply
   * @param timestampId Id of a comment 
   */
  removeReplyStatus(timestampId) {

     let index = this.replyStatus.indexOf(timestampId);
    if (index  !== -1) {
      this.replyStatus.splice(index, 1);
    }

    if (timestampId == this.replyCommentTimestamp) {
      this.validReplyForm = true;
    }
  }
  /**
   * @ignore
   */
  updateValidForm() {
    this.validForm = true;
  }
  /**
   * @ignore
   */
  updateValidReplyForm() {
    this.validReplyForm = true;
  }
  /**
   * Sends a notification to users added to an asset
   * @param members Users who will receive a notification
   * @param chat Comment that has been sent
   * @param asset Comment that has been sent
   */
  saveNotification(members, chat, asset) {
    if (members) {
      members.forEach(member => {
        let webUserMember = this.usersService.getUserData(member);
        if (webUserMember && webUserMember.id != chat.userAsset && this.sentNotificationTeam.indexOf(webUserMember.id) == -1) {
          let type;

          if (asset.type == 'project.') {
            type = 'project';
          } else if (asset.type == 'order.') {
            type = 'job';
          }

          let notification = {
            'id': asset.id,
            'user': chat.userAsset,
            'comment': chat.entry,
            'timestamp': chat.timestamp,
            'type': type
          };

          if (!webUserMember.notifications) {
            webUserMember.notifications = [];
          }

          webUserMember.notifications.push(notification);
          this.sentNotificationTeam.push(webUserMember.id);
          this.usersService.editUser(webUserMember);
        }
      });
    }
  }
  /**
   * Completes the comments with the user information corresponding to each comment
   */
  private fillData() {
    if (this.asset.communication) {
      if (this.widget) {
        this.asset.communication = this.asset.communication.sort((a, b) => b.timestamp - a.timestamp);
      }

      this.asset.communication.forEach(comment => {

        if (!comment.page) comment.page = 0;

        if (comment.userAsset && this.usersService.getUserData(comment.userAsset)) {
          comment.userAssetO = this.usersService.getUserData(comment.userAsset);
        } else if (!comment.userAsset || (comment.userAsset && (!comment.userAssetO || !comment.userAssetO.initials))) {
          comment.userAssetO = {
            "initials": "SP",
            "display_name": "Serviceplan"
          };
        }

        if (comment.markers && comment.markers.length > 0) {

          if (comment.markers[0].page) {
            comment.page = comment.markers[0].page;
          }

          if (!comment.markers[0].done) {
            comment.markers[0].done = false;
          }

        }

      });
    }
  }
  /**
   * Complete the comments with the answers to each comment and the corresponding user.
   */
  private fillDataReply() {
    if (this.asset.communication) {
      this.asset.communication.forEach(comment => {
        if (comment && comment.reply) {
          if(comment.reply) comment.numberReplys = 0;
          comment.reply.forEach(rep => {
            comment.numberReplys++
            rep.isReply = true;
            if (rep.userAsset && this.usersService.getUserData(rep.userAsset)) {
              rep.userAssetO = this.usersService.getUserData(rep.userAsset);
            } else if (!rep.userAsset || (rep.userAsset && (!rep.userAssetO || !rep.userAssetO.initials))) {
              rep.userAssetO = {
                "initials": "SP",
                "display_name": "Serviceplan"
              };
            }
          });
        }
      });
    }
  }
  /**
   * Gets the page where the current asset is located.
   * @returns The page where the asset is located
   */
  getOnPages() {
    let pageCounter = 0;
    let pages = [];

    if (this.asset != null && this.asset.communication != null) {
      pages = Array.from(new Set(this.asset.communication.filter(x => this.showAllComments || (this.asset.content_version == x.content_version)).map(x => x.page)));

      if (pages) {
        pageCounter = pages.length;
      }
    }
    return pageCounter;
  }
  /**
   * Gets and returns the number of feedbacks of an asset
   */
  getFeedbacksNumber() {
    return this.asset.communication ? this.asset.communication.filter(x => this.showAllComments || (this.asset.content_version == x.content_version)).length : 0;
  }
  /**
   * Change the state of the filter boolean and update the comments
   */
  filterByPage() {
    this.filter = !this.filter;
    this.refreshComments();
  }
  /**
   * Controls whether to display the comments of a given page if there is a filter or not all of them.
   * @param page Commentary page
   * @returns Boolean, false if the comment is not found on the page being filtered (if the filter is active)
   */
  showFeedback(page) {
    
    let show = true;

    if (this.filter) {
      show = this.page == page;
    }

    return show;
  }
  /**
   * Update a comment marker
   * @param index Commentary index
   */
  updateMarker(index) {

    this.updating = true;

    if (this.asset.approvalTime) {
      this.asset.approvalTime = new Date(this.asset.approvalTime).getTime();
    }
    this.assetService.editAsset(this.asset).then(result => {

      this.asset = result;
      this.fillData();
      this.updating = false;
    });
  }
  /**
   * Manage replies to all comments
   * @param communication All comments of asset.
   */
  orderCommunicationReply(communication) {
    if (communication && communication.length > 0) {
      communication.forEach(comment => {
        this.orderCommentReply(comment);
      });
    }
  }
  /**
   * Complete the comments with their respective answers.
   * @param comment 
   */
  orderCommentReply(comment) {
    if (comment && comment.reply && comment.reply.length > 0) {
      let currentReply = comment.reply.filter(r => r.replyTo == comment.timestamp);
      let tempReply = comment.reply.filter(r => r.replyTo != comment.timestamp)
      if (currentReply && currentReply.length > 0) {
        comment.reply = currentReply;

        comment.reply.forEach(r => {
          r.reply = tempReply;
          this.orderCommentReply(r);
        });
      } else {
        delete comment.reply;
      }
    }
  }
  /**
   * Gets the index of the parent comment
   * @param comment An asset's commentary
   * @returns The index of the parent commentary
   */
  findCommentParent(comment) {
    let index = -1;
    let found = false;
    let countC = 0;
    let countR = 0;
    let roundTimestamp = Math.round(comment.timestamp / 1000) * 1000;

    while (!found && countC < this.asset.communication.length) {
      if (roundTimestamp == this.asset.communication[countC].timestamp) {
        index = countC;
        found = true;
      } else {
        countR = 0;
        if (this.asset.communication[countC].reply) {
          while (!found && countR < this.asset.communication[countC].reply.length) {
            if (roundTimestamp == this.asset.communication[countC].reply[countR].timestamp) {
              index = countC;
              found = true;
            }
            countR++;
          }
        }
      }
      countC++;
    }

    return index;
  }
  /**
   * Update comment
   * @param comment An asset's commentary
   */
  updateComment(comment) {

    if (!this.updatingWidgetComment) {
      this.sending = true;
    }

    this.asset.communication.find(x => x.timestamp === comment.timestamp).entry = this.editCommentForm.value.message;
    comment.entry = this.editCommentForm.value.message;

    if (this.asset.approvalTime) {
      this.asset.approvalTime = (new Date(this.asset.approvalTime)).getTime();
    }

    this.asset.communication.forEach(comunication => {
      if (!comunication.entry) {
        comunication.entry = '';
      }
    });

    this.communicationService.editComment(this.asset, comment).then(result => {
      this.editCommentForm.reset();
      this.editAsset(result);
    });

  }
  /**
   * Delete a comment
   * @param comment An asset's commentary
   * @returns void
   */
  deleteComment(comment) {

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'deleteComment'
    });
    return dialogRef.afterClosed().toPromise().then(result => {

      dialogRef = null;

      if (result) {
        this.sending = true;

        this.asset.communication.splice(this.asset.communication.indexOf(comment));

        if (this.asset.approvalTime) {
          this.asset.approvalTime = (new Date(this.asset.approvalTime)).getTime();
        }

        this.asset.communication.forEach(comunication => {
          if (!comunication.entry) {
            comunication.entry = '';
          }
        });

        this.communicationService.deleteComment(this.asset, comment).then(result => {
          this.editAsset(result);
        });

      }

      return false;

    });

  }
  /**
   * Finishes editing the comment and changes the value of the comment to the edited one
   * @param comment The edited commentary
   */
  setEditForm(comment) {
    this.orderCommunication.forEach(x => x.editing = false);
    this.editCommentForm.setValue({ message: comment.entry });
  }

  private editAsset(result) {
    this.asset = result;
    this.fillData();
    this.newMarkers = [];
    this.sending = false;
    this.updatingWidgetComment = false;
    this.markerActive = false;
    this.fillDataReply();
    this.orderCommunication = Utils.cloneArray(this.asset.communication);
    this.orderCommunicationReply(this.orderCommunication);

    // this.commentSaved.emit(this.asset.communication);
  }
  /**
   * Manages the comment update
   * @param comment 
   */
  updateWidgetComment(comment) {
    this.updatingWidgetComment = true;
    this.updateComment(comment);
  }
  /**
   * Maintains the scroll of the message container so that the last comment and the input of the new comment are always displayed.
   */
  scrollToBottom() {
    
    if(this.orderCommunication) {
      setTimeout(() => {
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
      }, 500);
    }
  }

}