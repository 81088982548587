import { Component, Input, OnInit } from '@angular/core';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { UserService } from 'app/shared/services/user/user.service';
import Utils from 'app/shared/utils/utils';

/**
 * This component is the widget that displays the files of an asset.
 */
@Component({
  selector: 'app-files-widget',
  templateUrl: './files-widget.component.html',
  styleUrls: ['./files-widget.component.scss']
})
export class FilesWidgetComponent implements OnInit {
  /**
   * The id of asset
   */
  @Input() assetId;
  /**
   * if it is a project type asset is true
   */
  @Input() project: boolean = false;
  /**
   * If it is loading data is true
   */
  loadingData = false;
  /**
   * Data of agency
   */
  agencyData = [];
  /**
   * Customer data
   */
  customerData = [];
  /**
   * Final data
   */
  finalData = [];
  /**
   * Data of consulting
   */
  consulting = [];
  /**
   * Data of cost estimate
   */
  costEstimate = [];
  /**
   * Data of current user
   */
  currentUser;
  /**
   * True if is power user
   */
  isPowerUser = false;
  /**
   * True if is operator user
   */
  isOperator = false;
  /**
   * True if is manager user
   */
  isManager = false;
  /**
   * 
   * @param actionService Service with the functions related to the actions.
   * @param userService Service with the functions related to the users.
   * @param rolesService Service with the functions related to the roles.
   * @param projectService Service with the functions related to the projects.
   */
  constructor(private actionService: ActionsService,
    private userService: UserService,
    private rolesService: RolesService,
    private projectService: ProjectsService) { }
  /**
   * Initializes the component and obtains the required data.
  */
  ngOnInit() {
    this.loadingData = true;
    this.currentUser = this.userService.getCurrentUser();
    this.isPowerUser = this.rolesService.checkRole(this.currentUser, RolesService.POWER_ROLE);
    this.isOperator = this.rolesService.checkRole(this.currentUser, RolesService.OPERATOR_ROLE);
    this.isManager = this.rolesService.checkRole(this.currentUser, RolesService.PROJECT_MANAGER_ROLE, true);

    this.getAssetFolders();

  }
  /**
   * Check for changes in the job data and update the info.
   */
  ngDoCheck() {
    if (Utils.reloadData == Utils.RELOAD_DATA_FILE_WIDGET) {
      Utils.reloadData = Utils.RELOAD_DATA_NONE;
      this.agencyData = [];
      this.customerData = [];
      this.finalData = [];
      this.consulting = [];
      this.costEstimate = [];
      this.getAssetFolders();
    }
  }
  /**
   * Calls the function to get data depending on whether the asset is a project or a job
   */
  getAssetFolders() {
    if (this.project){
      this.getProjectFolders(this.assetId);
    } else {
      this.getActionFolders(this.assetId);
    }
  }
  /**
   * Gets the files of a project
   * @param assetId id of the project
   */
  getProjectFolders(assetId) {
    this.projectService.getProject(assetId).subscribe(data => {
      if (data) {
        if (data.customerdata && data.customerdata.length > 0 && data.customerdata[0].files && data.customerdata[0].files.length >0) {
          this.customerData = [...this.customerData, ...data.customerdata[0].files];

          if (this.customerData && this.customerData.length > 0) {
            this.customerData = this.customerData.filter(x => x && x.type != 'group.');
          }
        }

        if (data.consulting && data.consulting.length > 0 && data.consulting[0].files && data.consulting[0].files.length >0) {
          this.consulting = [...this.consulting, ...data.consulting[0].files];

          if (this.consulting && this.consulting.length > 0) {
            this.consulting = this.consulting.filter(x => x && x.type != 'group.');
          }
        }

        this.loadingData = false;
      }
    });
  }
  /**
   * Gets the files of a job
   * @param assetId id of the job
   */
  getActionFolders(assetId){

    this.actionService.getAction(assetId).then(data => {

      if (data) {
        if (data.agencydata && data.agencydata.length > 0 && data.agencydata[0].files && data.agencydata[0].files.length >0) {
          this.agencyData = [...this.agencyData, ...data.agencydata[0].files];

          if (this.agencyData && this.agencyData.length > 0) {
            this.agencyData = this.agencyData.filter(x => x && x.type != 'group.');
          }
        }

        if (data.finaldata && data.finaldata.length > 0 && data.finaldata[0].files && data.finaldata[0].files.length >0) {
          this.finalData = [...this.finalData, ...data.finaldata[0].files];

          if (this.finalData && this.finalData.length > 0) {
            this.finalData = this.finalData.filter(x => x && x.type != 'group.');
          }
        }

        if (data.customerdata && data.customerdata.length > 0 && data.customerdata[0].files && data.customerdata[0].files.length >0) {
          this.customerData = [...this.customerData, ...data.customerdata[0].files];

          if (this.customerData && this.customerData.length > 0) {
            this.customerData = this.customerData.filter(x => x && x.type != 'group.');
          }
        }

        if (data.consulting && data.consulting.length > 0 && data.consulting[0].files && data.consulting[0].files.length >0) {
          this.consulting = [...this.consulting, ...data.consulting[0].files];

          if (this.consulting && this.consulting.length > 0) {
            this.consulting = this.consulting.filter(x => x && x.type != 'group.');
          }
        }

        if (data.costestimate && data.costestimate.length > 0 && data.costestimate[0].files && data.costestimate[0].files.length >0) {
          this.costEstimate = [...this.costEstimate, ...data.costestimate[0].files];

          if (this.costEstimate && this.costEstimate.length > 0) {
            this.costEstimate = this.costEstimate.filter(x => x && x.type != 'group.');
          }
        }

        if (!this.project && data.parents && data.parents.length > 0) {
          this.projectService.getProject(data.parents[0]).subscribe(project => {
            if (project.customerdata && project.customerdata.length > 0 && project.customerdata[0].files && project.customerdata[0].files.length > 0) {
              this.customerData = [...this.customerData, ...project.customerdata[0].files];

              if (this.customerData && this.customerData.length > 0) {
                this.customerData = this.customerData.filter(x => x && x.type != 'group.');
              }
            }

            if (project.consulting && project.consulting.length > 0 && project.consulting[0].files && project.consulting[0].files.length > 0) {
              this.consulting = [...this.consulting, ...project.consulting[0].files];

              if (this.consulting && this.consulting.length > 0) {
                this.consulting = this.consulting.filter(x => x && x.type != 'group.');
              }
            }

            this.loadingData = false;
          });
        }
      }
      this.loadingData = false;
    });
  }

}