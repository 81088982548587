import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { environment } from '../../../../environments/environment';

/**
 * @ignore
 */
@Injectable({
  providedIn: 'root'
})
export class HCMSService {

  private hcms : Restangular;

  constructor(private restangular: Restangular) {

      this.hcms = this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.baseHCSMUrl);
      });
  }

  public get() : Restangular{
    return this.hcms;
  }

}
