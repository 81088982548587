import { Component, Input, ViewChild, HostListener, ChangeDetectorRef, ElementRef, OnDestroy, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { Media } from 'app/shared/model/media.model';
import Utils from '../../../shared/utils/utils'
import { DatePipe, formatDate } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ShareDialogComponent } from 'app/shared/dialogs/share/share-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

import { LiquidCacheService } from 'ngx-liquid-cache';
import { SaveStateComponent } from 'app/shared/saveState/saveState';
import { Collection } from 'app/shared/model/collection.model';
import { CollectionsComponent } from '../collections/collections.component';
import { UserService } from 'app/shared/services/user/user.service';
import { AssetFeaturesService } from 'app/shared/services/asset_features/asset-features.service';
import { DownloadDialogComponent } from 'app/shared/dialogs/download/download-dialog.component';
import { TagsDialogComponent } from '../tags-dialog/tags-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MomentCustomAdapter } from 'app/shared/utils/MomentCustomAdapter';
import { ShareCollectionDialogComponent } from 'app/shared/dialogs/shareCollection/shareCollection-dialog.component';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TrackingService } from 'app/shared/services/tracking/tracking.service';
import { AssetDetailComponent } from '../asset-detail/asset-detail.component';
import { CollectionsService } from 'app/shared/services/collections/collections.service';
import { CommonService } from 'app/shared/services/common/common.service';
import { ComponentDialogComponent } from '../../../shared/dialogs/component/component-dialog.component';
import { MimetypeService } from 'app/shared/services/mimetype/mimetype.service';
import { DamService } from 'app/layout/dam/service/dam.service';
import { AppSettings } from 'app/shared/app.settings';

/**
 * Constant with the formats of the date
 */
export const DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: { dateInput: 'DD.MM.YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
};

/**
 * This component defines the list view of the asset list.
 */
@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentCustomAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class AssetListComponent implements OnDestroy {

  /**
   * Input goTo {@link paginator}
   */
  // @ViewChild("goTo", { static: true }) goToInput: ElementRef;
  @ViewChildren("goTo") goToInputs: QueryList<ElementRef>;

  /**
   * Pagination {@link paginator}
   */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**
   * {@link Utils} imorted class
   */
  utils = Utils;
  /**
   * Array with assets to be downloaded 
   */
  downloading = [];
  /**
   * Original size
   */
  originalSize = 0;
  /**
   * Current user
   */
  currentUser;

  /**
   * Min filter width
   */
  minFilterWidth: number = 4;
  /**
   * Max filter width
   */
  maxFilterWidth: number = 20;
  /**
   * Filter widthg
   */
  filterWidth: number = this.minFilterWidth;

  /**
   * Filtered assets
   */
  filteredAssets: Array<any> = [];

  /**
   * Breakpoint cols
   */
  breakpointCols;

  //filters
  /**
   * If true, filters are displayed
   */
  showFilters: boolean = false;
  /**
   * Array of collections
   */
  collections: Collection[] = [];

  /**
   * @ignore
   */
  @Input() damEnabled: boolean;
  /**
   * List mode
   */
  @Input() listMode: boolean;
  /**
   * Array of medias
   */
  @Input() assets: Media[] = [];
  /**
   * Index key
   */
  @Input() indexKey: string;
  /**
   * Sorting
   */
  @Input() sorting: string;
  /**
   * Collection Id
   */
  @Input() collectionId;
  /**
   * Flag used to execute a while loop
   */
  @Input() over: boolean = false;
  /**
   * Indicates read-only
   */
  @Input() onlyRead: boolean = false;
  /**
   * Last refresh
   */
  @Input() lastRefresh: any = {};
  /**
   * Briefing
   */
  @Input() briefing: boolean = false;
  /**
   * Customer data list
   */
  @Input() customerDataList: any[] = [];
  /**
   * With Filters. Flag that indicates if the widget must have filters or not. True as default.
   */
  @Input() withFilters: boolean = true;
  /**
 * True if the component is inside new task form
 */
  @Input() isForNewTask: Boolean = false;
  /**
   * Selected list
   */
  @Output() selectedList = new EventEmitter<any[]>();


  /**
   * Date of last update
   */
  lastUpdated = new Date('1970-01-01T00:00:00Z');

  /**
   * Filter values
   */
  filterValue = {
    tags: [],
    type: [],
    created: [],
    name: '',
    createDateFrom: null,
    createDateTo: (new Date()).getTime(),
    validUntil: null,
    mimetype: [],
    brand: [],
    scale: [],
    licensedbrand: []
    /* validUntil: {
      from: null,
      to: null
    } */
  }

  /**
   * Filters
   */
  filter = {
    text: '',
    tags: [],
    createdMin: null,
    createdMax: (new Date()).getTime(),
    type: '',
    name: '',
    createDateFrom: null,
    createDateTo: null,
    validUntil: null,
    mimetype: '',
    brand: '',
    subbrand: '',
    scale: '',
    licensedbrand: ['']

    /* validUntil: {
      from: null,
      to: null
    } */
  };
  /**
   * 
   */
  pageIndex = 0;
  /**
   * 
   */
  currentSort = '';

  /**
   * 
   */
  selectAll = false;
  /**
   * 
   */
  selectedAll = 'all';
  /**
   * 
   */
  pageSize = 12;

  /**
   * 
   */
  loadErrorPreview = [];
  /**
   * 
   */
  loadErrorMain = [];
  /**
   * 
   */
  loadedImage = [];
  /**
   * 
   */
  imageRetry = [];

  /**
   * Num of retries
   */
  numLoadRetries = 2;
  /**
   * Time to retry
   */
  timeToRetry = 2500;

  private lastKeyword: string = '';

  /**
   * subscription that brings all collections
   */
  private collectionsSubcription: Subscription;

  /**
   * Search tag param
   */
  searchTagParam;

  /**
   * Id
   */
  id: string;

  /**
   * Inner width
   */
  private innerWidth = 0;

  /**
   * Go to val
   */
  goToVal;
  /**
   * Clients
   */
  clients: any[];
  /**
   * Feature hubz channels values
   */
  featureHubzChannelsValues: any[] = [];
  /**
   * Hubz channels
   */
  hubzChannels: any[];
  /**
   * Deliverables
   */
  deliverables: any[] = [];
  /**
   * Customer Data Loaded
   */
  customerDataLoaded: boolean = false;
  /**
   * Filter configuration to be able to display the form and the filter values in the html by looping and not repeating each filter.
   */
  filterConfig: any = [
    { key: "name", type: "text", valueKey: null, label: "media-library.name", width: "50" },
    { key: "type", type: "select", options: "type", valueKey: null, placeholder: "all_types", label: "asset_type_label", width: "25" },
    { key: "mimetype", type: "select", options: "mimetype", valueKey: "name", placeholder: "all_types", label: "file", width: "25" },
    // { key: "licensedbrand", type: "multiselect", options: "licensedbrand", valueKey: "name", placeholder: "all_licensedbrands", label: "licensedbrand", width: "25" },
    { key: "brand", type: "selectDep", options: null, valueKey: "name", placeholder: "all_brands", label: "brand_label", width: "25", level: 1 },
    { key: "subbrand", type: "selectDep", options: null, valueKey: "name", placeholder: "all_subbrands", label: "subbrand_label", width: "25", level: 2 },
    { key: "articletype", type: "selectDep", options: null, valueKey: "name", placeholder: "all_articletypes", label: "articletype", width: "25", level: 3 },
    { key: "scale", type: "select", options: "scale", valueKey: "name", placeholder: "all_scales", label: "scale", width: "25" },
    {
      type: "group", label: "date_created_label", width: "25",
      group: [
        { key: "createDateFrom", type: "date", valueKey: null, label: "filter.from", inlineLabel: true },
        { key: "createDateTo", type: "date", valueKey: null, label: "filter.to", inlineLabel: true },
      ]
    },
    { key: "validUntil", type: "date", valueKey: null, label: "valid_date_label", width: "25" },
    { key: "tags", type: "array", valueKey: "name", label: "tags" },
  ]

  all_mimetypes = [];
  all_brands = [];
  all_scales = [];
  all_licensedbrands = [];

  changeDate: boolean = false;



  /**
   * Constructor.
   * @param dialog {@link MatDialog} with the functions related to the dialog.
   * @param route {@link ActivatedRoute} with the functions related to the activated route.
   * @param userService {@link UserService} with the functions related to the users.
   * @param cache {@link LiquidCacheService} with the functions related to the cache.
   * @param router {@link Router} with the functions related to the router.
   * @param translateService {@link TranslateService} with the functions related to the translations.
   * @param cd {@link ChangeDetectorRef} with the functions related to the Change detector.
   * @param el {@link ElementRef} with the functions related to the dom elements.
   * @param trackingService {@link TrackingService} with the functions related to the tracking.
   * @param collectionsService {@link CollectionsService} with the functions relate with different features of the collections
   * @param assetFeatureService {@link AssetFeaturesService} with the functions relate with different features of the assets
   * @param commonService {@link CommonService} with the common functions.
   */
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    protected usersService: UserService,
    cache: LiquidCacheService,
    router: Router,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private trackingService: TrackingService,
    private collectionsService: CollectionsService,
    private AssetFeatureService: AssetFeaturesService,
    private commonService: CommonService,
    private mimetypeService: MimetypeService,
    private damService: DamService,
    public datepipe: DatePipe,

  ) {
  }

  /**
   * Initialize the component.
   */
  ngOnInit() {
    this.collectionsSubcription = this.collectionsService.collections.subscribe(collections => { this.collections = collections });
    this.id = this.route.snapshot.paramMap.get('id');

    this.breakpointCols = (window.innerWidth <= 500) ? 1 : (window.innerWidth <= 800) ? 2 : (window.innerWidth <= 1000) ? 3 : 4;
    this.innerWidth = window.innerWidth;
    this.loadFilters();

    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.tagId) {
        this.searchTagParam = queryParams.tagId;
      }
    });

    this.commonService.getFeatureValues(CommonService.CNTR_HUBZ_CLIENTS).subscribe(data => {
      this.clients = data.filter(x => x.enabled).sort((a, b) => (a.name > b.name) ? 1 : -1);
    });

    this.commonService.getFeatureValues(CommonService.CNTR_HUBZ_CHANNEL).subscribe(data => {
      this.featureHubzChannelsValues = data;
      this.hubzChannels = this.featureHubzChannelsValues.filter(value => value.value_key.split('.').length == 3);
      this.deliverables = this.featureHubzChannelsValues.filter(value => value.value_key.split('.').length == 4);
    });

    this.changeSelectedAll('none');
    if (this.filter.createdMin !== null) this.changeDate = true;


  }

  /**
   * @ignore
   */
  loadFilters() {

    this.all_mimetypes = this.mimetypeService.getActualMimetypes();
    this.all_brands = this.AssetFeatureService.getBrands().filter(x => !x.value_key.endsWith('.none.'));
    this.all_scales = this.AssetFeatureService.getScales();
    this.all_licensedbrands = this.AssetFeatureService.getLicensedbrands();

    if (!this.all_mimetypes || this.all_mimetypes.length == 0
      || !this.all_brands || this.all_brands.length == 0
      || !this.all_scales || this.all_scales.length == 0
      || !this.all_licensedbrands || this.all_licensedbrands.length == 0
    ) {
      setTimeout(() => this.loadFilters(), 500);
    } else {
      this.filterValue.mimetype = this.all_mimetypes;
      this.filterValue.brand = this.all_brands;
      this.filterValue.scale = this.all_scales;
      this.filterValue.licensedbrand = this.all_licensedbrands;
    }

  }

  /**
   * method to resize window
   */
  @HostListener('window:resize')
  public onWindowResize(): void {
    this.breakpointCols = (window.innerWidth <= 500) ? 1 : (window.innerWidth <= 800) ? 2 : (window.innerWidth <= 1000) ? 3 : 4;
  }

  /**
   * Method that is called when the component is destroyed.
   */
  ngOnDestroy() {
    if (this.collectionsSubcription) {
      this.collectionsSubcription.unsubscribe();
    }
  }

  /**
   * Checks for changes
   */
  ngDoCheck() {

    if (this.originalSize != this.assets.length && this.assets.length >= 0 || this.currentSort != this.sorting || (this.lastRefresh && this.lastRefresh.value > this.lastUpdated)) {
      this.lastUpdated = this.lastRefresh.value;

      this.currentSort = this.sorting;

      this.originalSize = this.assets.length;
      this.changeList();
    }

    if (!this.customerDataLoaded && this.customerDataList && this.customerDataList.length > 0 && this.filteredAssets && this.filteredAssets.length > 0) {
      this.customerDataLoaded = true;
      let customerDataIdList: number[] = this.customerDataList.map(x => x.id);

      this.filteredAssets.forEach((asset, index) => {
        if (customerDataIdList.includes(+asset.id)) {
          asset.selected = true;
        }

        if (index + 1 == this.filteredAssets.length) {
          this.selectedList.emit(this.filteredAssets.filter(x => x.selected));
        }
      });
    }
  }

  /**
   * method that is called after the view of the component has started to be displayed.
   */
  ngAfterViewInit(): void {
    this.paginator.page.subscribe(event => {
      const topContainer = document.getElementById('top-container');
      topContainer.scrollIntoView({
        behavior: 'smooth'
      });
      this.pageIndex = event.pageIndex;
    });

    if (this.goToInputs) {
      this.goToInputs.forEach(goToInput => {
        fromEvent(goToInput.nativeElement, 'keyup').pipe(
          debounceTime(500)
        ).subscribe(value => this.goToPage());
      });
    }

  }

  /**
   * Move to page selected
   */
  goToPage() {
    if (this.goToVal > 0 && this.goToVal <= this.filteredAssets.length) {
      const pageNumber = Math.floor((this.goToVal - 1) / this.pageSize);

      this.paginator.pageIndex = this.pageIndex = pageNumber;

      this.getAssets();

      this.el.nativeElement.querySelector('.searched').scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }

  /**
   * Load assets in each section
   */
  private changeList() {
    this.getFilteredValues(this.assets);
    this.getAssets();
  }

  /**
   * Update search
   * @param event Event
   */
  updateSearchText(event) {
    this.filter.text = event;
    this.getAssets();

    setTimeout(() => {
      if (event != null && event.length > 3 && event !== this.lastKeyword) {
        this.lastKeyword = event;
        this.trackingService.sendMatomoSearch(event);
      }
    }, 5000);
  }

  /**
   * Update filter
   * @param value New value to update filter
   * @param typeFilter Filter to update
   */
  updateFilterText(value, typeFilter) {
    // switch( typeFilter ) {
    //   case 'name':
    //     this.filter.name = value;
    //     break;
    //   case 'type':
    //     this.filter.type = value;
    //     break;
    //   case 'fileType':
    //     this.filter.fileType = value;
    //     break;
    //   case 'client':
    //     this.filter.client = value;
    //     break;
    //   case 'hubzChannel':
    //     this.filter.hubzChannel = value;
    //     break;
    //   case 'deliverable':
    //     this.filter.deliverable = value;
    //     break;
    // }
  }

  /**
   * @ignore
   */
  private filterAssetList(val, list, propertyName, filter, parentProperty = '') {
    if (val) {

      if (parentProperty) {

        let result = false;
        let listVal = list.find(x => x[propertyName] === val);

        while (!result && listVal[parentProperty]) {
          if (listVal && !result) {
            result = listVal.name.toLowerCase().includes(filter.toLowerCase());
          }
          listVal = list.find(x => x[propertyName] === listVal[parentProperty]);
        }

        return result;

      } else {
        const listVal = list.find(x => x[propertyName] === val);

        if (listVal) {
          const result = listVal.name.toLowerCase().includes(filter.toLowerCase());
          return result;
        }
      }
    }

    return false;
  }

  /**
   * Get asset
   */
  getAssets() {
    this.filteredAssets = [];
    this.getFilteredValues(this.assets);
    this.filteredAssets = this.assets;
    if (!this.isEmptyFilter()) {
      if (this.filter.text) {
        this.filteredAssets = this.filteredAssets.filter(asset =>
          (this.filter.text && asset.name.toLowerCase().includes(this.filter.text.toLowerCase()))
          ||
          (this.filter.text && asset.designation && asset.designation.en && asset.designation.en.toLowerCase().includes(this.filter.text.toLowerCase()))
          ||
          (this.filter.text && asset.designation && asset.designation.de && asset.designation.de.toLowerCase().includes(this.filter.text.toLowerCase()))
          ||
          (this.filter.text && asset.id.toString().includes(this.filter.text))
          ||
          (this.filter.text && asset.info && asset.info.toLowerCase().includes(this.filter.text))
          ||
          (this.filter.text && (asset.tags && asset.tags.length > 0) && (asset.tags.some(tag => tag.name.toLowerCase().includes(this.filter.text.toLowerCase()))))
          ||
          (this.filter.text && asset.dateiType && asset.dateiType.toLowerCase().includes(this.filter.text.toLowerCase()))
          ||
          (this.filter.text && asset.scale && asset.scale.toLowerCase().includes(this.filter.text.toLowerCase()))
          ||
          (this.filter.text && asset.brand && asset.brand.some(y => y.includes(this.filter.text.toLowerCase())))
          ||
          (this.filter.text && asset.licensedbrand && asset.licensedbrand.toLowerCase().includes(this.filter.text.toLowerCase()))
          ||
          (this.translateService.instant(asset.type).toLowerCase().includes(this.filter.text.toLowerCase()))
        );
      }

      if (this.filter.name) {
        this.filteredAssets = this.filteredAssets.filter(asset =>
          (this.filter.name && asset.name.toLowerCase().includes(this.filter.name.toLowerCase()))
        );
      }

      this.filteredAssets = this.filteredAssets.filter(asset =>
        (!this.filter.type || asset.type.includes(this.filter.type))
        && Utils.getMillisDate(new Date(+asset.created.substring(0, 4), +asset.created.substring(5, 7), 0)) >= this.filter.createdMin
        && Utils.getMillis(asset.created.substring(0, 8) + '01') <= this.filter.createdMax
        && (
          this.filter.tags.length === 0 || this.filter.tags.every(filterTag => asset.tags && asset.tags.some(assetTag => assetTag.id === filterTag.id))
        )
      );

      if (this.filter.mimetype) this.filteredAssets = this.filteredAssets.filter(x => x.dateiType && x.dateiType.includes(this.filter.mimetype['mimetype']));

      if (this.filter.brand && this.filter.brand['value_key']) this.filteredAssets = this.filteredAssets.filter(x => x.brand && x.brand.some(y => y.includes(this.filter.brand['value_key'])));

      if (this.filter.scale) this.filteredAssets = this.filteredAssets.filter(x => x.scale && x.scale.includes(this.filter.scale['value_key']));

      if (this.filter.licensedbrand && this.filter.licensedbrand.length > 0 && this.filter.licensedbrand.some(x => x['value_key'])) this.filteredAssets = this.filteredAssets.filter(x => x.licensedbrand && this.filter.licensedbrand.some(y => y['value_key'] === x.licensedbrand));


      if (this.filter.createDateFrom) this.filteredAssets = this.filteredAssets.filter(asset => (this.filter.createDateFrom && asset.created.slice(0, 10) >= this.filter.createDateFrom.slice(0, 10)));

      if (this.filter.createDateTo) this.filteredAssets = this.filteredAssets.filter(asset => (this.filter.createDateTo && asset.created.slice(0, 10) <= this.filter.createDateTo.slice(0, 10)));

      if (this.filter.validUntil) {
        this.filteredAssets = this.filteredAssets.filter(asset => asset.valid_until && (asset.valid_until.localeCompare(this.filter.validUntil) >= 0));
      }

      // this.filterValue.tags.sort((a, b) => b.assetCount - a.assetCount);
      this.filterValue.tags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      if (this.searchTagParam && this.filterValue.tags.length > 0) {
        let tag = this.filterValue.tags.find(x => x.id === +this.searchTagParam);
        if (tag && this.filter.tags.map(x => +x.id).indexOf(+this.searchTagParam) < 0) {
          this.filter.tags.push(tag);
          this.filteredAssets = this.filteredAssets.filter(x => x.tags.some(tag => tag.id === +this.searchTagParam));
          this.searchTagParam = '';
        }
      }

      this.getFilteredValues(this.filteredAssets);
    }

    if (this.over && this.paginator && this.paginator.pageSize && this.filteredAssets.length <= this.paginator.pageSize) {
      this.paginator.firstPage();
    }
  }

  /**
   * Get values filtered
   * @param assets 
   */
  getFilteredValues(assets) {
    let currentMimetype = Array.from(new Set(assets.filter(x => x.dateiType).map(x => x.dateiType)));
    let currentBrand = Array.from(new Set(Array.prototype.concat(...assets.filter(x => x.brand).map(x => x.brand))));
    let currentScale = Array.from(new Set(assets.filter(x => x.scale).map(x => x.scale)));
    let currentLicensedbrand = Array.from(new Set(Array.prototype.concat(...this.assets.filter(x => x.licensedbrand).map(x => x.licensedbrand))));

    assets.forEach(asset => {
      if (!asset.created) asset.created = asset.createdAttr;
      asset.downloadAvailable = true;

      if (asset.valid_until) {
        let today: Date = new Date();
        let expired: Date = new Date(asset.valid_until);
        let difference = Utils.getNumDaysBetween(today, expired);
        if (difference < 1) {
          asset.showExpiredAlert = true;
          asset.downloadAvailable = false;
          asset.showExpiredMessage = this.translateService.instant('expired_warning');
        } else if (difference < AppSettings.DAYS_CHECK_EXPIRE_SOON) {
          asset.showExpiredAlert = true;
          asset.showExpiredMessage = this.translateService.instant('expired_soon');
        }
      }
      if (asset.workflow && asset.workflowStep) {
        asset.step = this.damService.getWorkflowStep(asset);
        asset.state = this.damService.getStateWithWorkflowStep(asset);
        if (asset.step && asset.step.wf_step) {
          asset.status = this.damService.getWorkflowStatus(asset.step.wf_step);
        }
      }

    });

    this.filterValue.type = Array.from(new Set(assets.map(asset => asset.type)));
    this.filterValue.created = Array.from(new Set(this.assets.map(asset => Utils.getMillis(asset.created.substring(0, 8) + '01')))).sort();
    if (this.filterValue.created.length > 0 && !this.changeDate) {
      this.filterValue.createDateFrom = this.filterValue.created[0];
      this.filter.createdMin = this.filterValue.createDateFrom;
    }

    this.filterValue.mimetype = this.all_mimetypes.filter(x => currentMimetype.includes(x.mimetype));
    this.filterValue.brand = this.all_brands.filter(x => currentBrand.includes(x.value_key));
    this.filterValue.scale = this.all_scales.filter(x => currentScale.includes(x.value_key));
    this.filterValue.licensedbrand = this.all_licensedbrands.filter(x => currentLicensedbrand.includes(x.value_key));


    this.filterValue.tags = [];
    [].concat.apply([], assets.filter(asset => asset.tags).map(asset => asset.tags)).forEach(asset => {
      if (!this.filterValue.tags.some(tag => tag.id === asset.id)) {
        asset.assetCount = 1;
        this.filterValue.tags.push(asset);
      } else {
        this.filterValue.tags.find(tag => tag.id === asset.id).assetCount++;
      }
    });
    this.filterValue.tags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  }

  /**
   * Get Filter values list
   * @param assets array of the assets
   * @param valuesField Values
   * @returns Array Filter values
   */
  getFilterValuesList(assets: Array<any>, valuesField: string): Array<any> {
    let cleanList = new Map<string, any>();
    assets.filter(item => item[valuesField]).map(item => item[valuesField]).reduce((acc, x) => acc.concat(x), []).forEach(value => {
      if (value['id']) cleanList.set(value['id'], value);
      else if (typeof value === 'string') cleanList.set(value, value);
    });
    return Array.from(cleanList.values());
  }

  /**
   * Add a dates range ({@link FilterRange}) for a filter
   * 
   * @param filter The filter do add the range
   * @param element Form field that defines the range
   * @param event Value to filter
   */
  addRange(event: any, field: string) {
    let formatDate: moment.Moment = event.value.format('YYYY-MM-DD');
    this.filter[field] = formatDate;
  }

  /**
   * Format label
   * @param value Value to format
   */
  formatLabel(value: number | null) {
    return formatDate(value, 'MMM yyyy', 'de-DE');
  }

  /**
   * Select/Unselect all fields
   */
  selectDeselectAll() {
    this.filteredAssets.forEach(x => x.selected = this.selectAll);
  }

  /**
   * Open pop up
   * @param media The media file
   */
  openDialog(media = null) {
    const shareDialog = this.dialog.open(ShareDialogComponent, {
      width: '500px',
      data: media ? [media] : this.assets.filter(x => x.selected)
    });
    shareDialog.afterClosed().subscribe(result => {
      this.changeSelectedAll('none')
    })
  }

  /**
   * Check if element is selected
   * @returns True if there is an element selected
   */
  anySelected() {
    return this.assets.some(x => x.selected);
  }

  /**
   * Return elements selected
   * @returns assets selected
   */
  getAssetsSelected() {
    return this.assets.filter(x => x.selected);
  }

  /**
   * Download file
   * @param file name
   */
  downloadFile(file) {
    this.dialog.open(DownloadDialogComponent, {
      data: { assets: [file], downloading: this.downloading },
      width: '500px',
      panelClass: 'download'
    });

  }

  /**
   * Downlad some files
   */
  downloadFiles() {
    let zipName = this.collectionId ? this.collections.find(x => x.id == +this.collectionId).name : this.translateService.instant('all_assets');
    const assets = this.assets.filter(x => x.selected && x.downloadAvailable);

    if (assets && assets.length > 0) {
      const downloadDialog = this.dialog.open(DownloadDialogComponent, {
        data: { assets: assets, downloading: this.downloading, zipName: zipName },
        width: '500px',
        panelClass: 'download'
      });
      downloadDialog.afterClosed().subscribe(result => {
        this.changeSelectedAll('none');
      })
    }
  }

  /**
   * Open Pop up to manage collections
   *
   * @param assetsToCollection
   */
  openBottomSheet(assetsToCollection: Media[]) {
    const collectionDialog = this.dialog.open(CollectionsComponent, {
      data: { assets: assetsToCollection },
      panelClass: 'addCollections'
    });
    collectionDialog.afterClosed().subscribe(result => {
      this.changeSelectedAll('none');
    })
  }

  /**
   * Clear filters
   */
  clearFilters() {
    this.filterValue.created = Array.from(new Set(this.assets.filter(x => x.created).map(x => Utils.getMillis(x.created.substring(0, 8) + '01')))).sort();

    this.filter.text = '';
    this.filter.type = null;
    this.filter.tags = [];
    this.filter.name = '';
    this.filter.createdMin = null;
    this.filter.createdMax = (new Date()).getTime();
    this.filter.createDateFrom = null;
    this.filter.createDateTo = null;
    this.filter.validUntil = null;
    this.filter.mimetype = '';
    this.filter.brand = '';
    this.filter.scale = '';
    this.filter.licensedbrand = [''];

    this.getAssets();
  }

  /**
   * Load error
   * @param media The media file that has the error
   */
  loadError(media) {
    this.loadErrorPreview[media.id.toString()] = true;
    if (!this.imageRetry[media.id.toString()]) {
      this.imageRetry[media.id.toString()] = 0;
    }
    this.imageRetry[media.id.toString()]++;

    if (!this.loadedImage[media.id.toString()] && (!this.imageRetry[media.id.toString()] || this.imageRetry[media.id.toString()] < this.numLoadRetries)) {

      setTimeout(() => {
        this.loadErrorPreview[media.id.toString()] = false;
        this.loadErrorMain[media.id.toString()] = false;
      }, this.timeToRetry);
    } else {
      this.loadedImage[media.id.toString()] = true;
    }

  }

  /**
   * True if there is an error loading main picture
   * @param media The media file that has the error
   */
  loadErrorMainPicture(media) {
    this.loadErrorMain[media.id.toString()] = true;
  }

  /**
   * Open Tag Pop up
   */
  openTagsDialog() {
    this.dialog.open(TagsDialogComponent, {
      data: { c: this, tags: this.filterValue.tags, filter: this.filter.tags },
      panelClass: 'tags'
    });
  }

  /**
   * Edit Collection
   */
  editCollection() {
    const collectionDialog = this.dialog.open(CollectionsComponent, {
      data: { collectionId: this.collectionId },
      panelClass: 'addCollections'
    });
    collectionDialog.afterClosed().subscribe(result => {
      this.changeSelectedAll('none');
    })
  }

  /**
   * Share Collection
   */
  shareCollection() {
    const shareCollectionDialog = this.dialog.open(ShareCollectionDialogComponent, {
      width: '500px',
      data: { collection: this.collections.find(x => x.id === this.collectionId) }
    });
    shareCollectionDialog.afterClosed().subscribe(result => {
      this.changeSelectedAll('none');
    })
  }

  /**
   * Remove Tag
   * @param tag  
   */
  removeTag(tag) {
    const index = this.filter.tags.indexOf(tag);
    if (index > -1) this.filter.tags.splice(index, 1);
    this.getAssets();
  }

  /**
   * Remove Filter
   * @param list Remove filter from this list
   * @param value Element to remove
   */
  removeFilter(list, value, byId = false) {
    if (!byId) {
      this.filter[list] = this.filter[list].filter(x => x !== value);
      if (this.filter[list].length == 0) {
        this.filter[list] = [''];
      }
    } else {
      this.filter[list] = this.filter[list].filter(x => x.id !== value);
    }
  }

  /**
   * Get list depending on level
   * @param currentList
   * @param list 
   * @param level 
   * @returns 
   */
  getListLevel(currentList, list, level, currentVal?) {
    const finalList = [];

    currentList.forEach(x => {
      const val = AssetFeaturesService.getListLevelValue(x, list, level);

      if (val && !finalList.includes(val) && (!currentVal || currentVal.parent_key === val.parent_key || val.value_key.includes(currentVal.value_key) || currentVal.value_key.includes(val.value_key))) {
        finalList.push(val);
      }
    });

    return finalList;
  }

  /**
   * Check if filter is empty
   * @returns values if not empty
   */
  isEmptyFilter() {
    return Object.values(this.filter).every((x: any) => !x || x.length === 0 || (x.length === 1 && !x[0]));
  }

  /**
   * Make selection
   * @param value depending on it, select all/none
   */
  changeSelectedAll(value) {
    if (value === 'none') {

      this.selectAll = false;
      this.assets.forEach(x => x.selected = false);
      this.filteredAssets.forEach(x => x.selected = false);

    } else if (value === 'all') {
      this.selectAll = true;

      this.assets.forEach(x => x.selected = false);
      this.filteredAssets.forEach(x => x.selected = true);

    } else if (value === 'page') {
      this.selectAll = true;

      this.assets.forEach(x => x.selected = false);
      this.filteredAssets.forEach((x, index) => {
        if (index >= this.pageIndex * this.pageSize && index < (this.pageIndex + 1) * this.pageSize)
          x.selected = true;
      });
    }

    this.updateSelected();
  }

  get brand() {
    return AssetFeaturesService.getListLevelValue(this.filter.brand, this.all_brands, 1)
  }

  get subbrand() {
    const val = AssetFeaturesService.getListLevelValue(this.filter.brand, this.all_brands, 2);
    if (val && !val.value_key.includes('.none.')) {
      return val;
    }

  }

  get articletype() {
    return AssetFeaturesService.getListLevelValue(this.filter.brand, this.all_brands, 3)
  }

  set brand(val) {
    this.filter.brand = val;
  }

  set subbrand(val) {
    this.filter.brand = val;
  }

  set articletype(val) {
    this.filter.brand = val;
  }


  /**
   * Open pop up asset
   * @param assetId Asset Id
   */
  openAssetDialog(assetId) {
    // this.enableDamEditing();
    const data = {
      component: AssetDetailComponent,
      assetData: {
        assetId: assetId,
        parentId: this.id
      }
    }
    const assetDialog = this.dialog.open(ComponentDialogComponent, { width: '90%', height: '90%', data: data });
    assetDialog.afterClosed().subscribe(result => {
    });
  }

  /**
   * Updated Asset selected
   */
  updateSelected() {
    if (this.briefing) {
      let selectedAssets: any[] = this.filteredAssets.filter(asset => asset.selected);
      this.selectedList.emit(selectedAssets);
    }
  }

  showTags() {
    const tagsDialog = this.dialog.open(TagsDialogComponent, {
      width: '800px',
      data: { c: this, tags: this.filterValue.tags, filter: this.filter.tags },
      panelClass: 'tags'
    });
    tagsDialog.afterClosed().subscribe(result => {
      this.changeSelectedAll('none');
    })
  }
}

/**
 * Multi options
 */
export class MultiOptions {
  /**
   * Constructor
   * @param id Id
   * @param elements Elements 
   */
  constructor(id, elements) {
    this.id = id;
    this.elements = elements;
  }

  /**
   * Id
   */
  id: number;
  /**
   * Path Id
   */
  pathid: string;
  /**
   * Label
   */
  label: string;
  /**
   * Elements
   */
  elements: Array<any>;
  /**
   * Selected
   */
  selected: any;
}