import { Injectable } from '@angular/core';
import { LiquidCache } from 'ngx-liquid-cache';
import { Observable } from 'rxjs';
import { OCService } from '../satellites/oc.service';
import { User } from 'app/shared/model/user.model';

/**
 * Service defined to manage the necessary operations with roles
 */
@Injectable({
  providedIn: 'root'
})
export class RolesService {
  /**
   * Main role
   */
  static readonly MAIN_ROLE = "root.cntr."
  /**
   * Power role
   */
  static readonly POWER_ROLE = "root.cntr.power.";
  /**
   * project manager role
   */
  static readonly PROJECT_MANAGER_ROLE = "root.cntr.manager.";
  /**
   * project manager+ role
   */
   static readonly PROJECT_MANAGER_PLUS_ROLE = "root.cntr.manager.plus.";
   
   /*
   * resource manager role
   */
  static readonly RESOURCE_MANAGER_ROLE = "root.cntr.resourceManager.";
  /**
   * admin role
   */
  static readonly ADMIN_ROLE = "root.cntr.admin.";
  /**
   * contributor role
   */
  static readonly CONTRIBUTOR_ROLE = "root.cntr.contributor.";
  /**
   * crew role
   */
  static readonly CREW_ROLE = "root.cntr.crew.";
  /**
   * limited role
   */
  static readonly LIMITED_ROLE = "root.cntr.limited.";
  /**
   * reader role
   */
  static readonly READER_ROLE = "root.cntr.reader.";
  /**
   * supervisor role
   */
  static readonly SUPERVISOR_ROLE = "root.cntr.supervisor.";
  /**
   * production role
   */
  static readonly PRODUCTION_ROLE = "root.cntr.production.";
  /**
   * operator role
   */
  static readonly OPERATOR_ROLE = "root.cntr.operator.";

  /**
   * default role
   */
  static readonly DEFAULT_ROLE = RolesService.POWER_ROLE;
  /**
   * roles
   */
  roles = [];
  /**
   * Constructor
   * @param ocService Service whit the functions related to the online channel.
   */
  constructor(private ocService: OCService) {
    this.getCachedRoles().subscribe(roles => {
      this.roles = roles;
      this.roles.forEach(role => role['label'] = this.getRoleName(role.value_key));
    });
  }

  @LiquidCache('roles')
  /**
   * Get cached roles
   */
  getCachedRoles(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/censhare:module.oc.permission.group/value_key/' + RolesService.MAIN_ROLE + '*').getList();
  }
  /**
   * Get Roles
   * @returns array of roles
   */
  getRoles(){
    return this.roles.filter(x => x.label).sort((x,y) => (x.label > y.label ? 1 : -1));
  }
  /**
   * Get the relationship between the user and the asset and the user's role.
   * @param asset the asset
   * @param user the user
   * @param relation the relation
   * @param role the role
   * @returns the relation and role
   */
  hasRelationAndWithRole(asset: any, user: User, relation: string, role: string, hirearchy: boolean = false) {
    return this.hasRelation(asset, user, relation) && this.checkRole(user, role, hirearchy);
  }
  /**
   * Check if the user's role is customer.
   * @param user user
   * @returns true if the role is customer
   */
  isCustomer(user){
    return this.checkRole(user, RolesService.POWER_ROLE) && !this.checkRole(user, RolesService.PROJECT_MANAGER_ROLE, true) && !this.checkRole(user, RolesService.PRODUCTION_ROLE);
  }
  /**
   * Check if the user's role is power user.
   * @param user user
   * @returns true if the role is power user
   */
  isPowerUser(user: User){
    return this.checkRole(user, RolesService.POWER_ROLE);
  }  
  /**
   * Check if the user's role is manager.
   * @param user user
   * @returns true if the role is manager
   */
  isManager(user: User){
    return this.checkRole(user, RolesService.PROJECT_MANAGER_ROLE, true);
  }
  /**
   * Check if the user's role is manager+.
   * @param user user
   * @returns true if the role is manager+
   */
   isManagerPlus(user: User){
    return this.checkRole(user, RolesService.PROJECT_MANAGER_PLUS_ROLE);
  }
  /**
   * Check if the user's role is resource manager.
   * @param user user
   * @returns true if the role is resource manager
   */
   isResourceManager(user: User){
    return this.checkRole(user, RolesService.RESOURCE_MANAGER_ROLE);
  }
  /**
   * Check if the user's role is operator.
   * @param user user
   * @returns true if the role is operator
   */
  isOperator(user: User){
    return this.checkRole(user, RolesService.OPERATOR_ROLE);
  }
  /**
   * Check if the user's role exists
   * @param user user
   * @param role role
   * @returns true if exists
   */
  static checkUserRole(user: User, role: string): boolean {
    if (user && user.roles) {
      return user.roles.indexOf(role) > -1;
    }

    return false;
  }

  /**
   * Check if the user has a specific role
   * 
   * @param {User} user The user from wich we want to check the role
   * @param {string} role The role to check
   * @returns True if the user has that role, false y other case.
   */
  checkRole(user: User, role: string, hierarchy: boolean = false): boolean {

    if (user && user.roles) {

      if (hierarchy) {
        return user.roles.some(x => x.includes(role));
      } else {
        return user.roles.indexOf(role) > -1;
      }

    }

    return false;
  }
  /**
   * Checks the relation between an asset and a user
   * @param asset asset
   * @param user user
   * @param relation relation
   * @returns true if has relation
   */
  hasRelation(asset: any, user: User, relation: string): boolean {
    if (user && asset && asset[relation]) {
      if (asset[relation] instanceof Array && asset[relation].length > 0) {
          return asset[relation][0] === user.id;
      }
    }

    return false;
  }

  /**
   * Obtain the role name from the value_key feature
   * 
   * @param roleKey The value_key feature
   * @returns The role name
   */
  getRoleName(roleKey: string) {
    const fullRole = this.roles.filter(role => role.value_key === roleKey);
    if (fullRole && fullRole.length > 0) {
      return fullRole[0].name;
    }
  }
  /**
   * Gets the role by the key
   * @param roleKey key of role
   * @returns role
   */
  getRole(roleKey: string) {
    return this.roles.find(x => x.value_key === roleKey);
  }

  /**
   * Obtain roles that have access to notifications
   * 
   * @returns A list of roles
   */
  getRolesNotification() {
    return [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.PROJECT_MANAGER_PLUS_ROLE];
  }
}
