import { Component, Input, OnInit } from '@angular/core';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { UserService } from 'app/shared/services/user/user.service';
import Utils from 'app/shared/utils/utils';

import * as _ from 'lodash';

/**
 * @ignore
 */
@Component({
  selector: 'app-briefing-data-widget',
  templateUrl: './briefing-widget-data.component.html',
  styleUrls: ['./briefing-widget-data.component.scss']
})
export class BriefingWidgetComponent implements OnInit {

  @Input() projects;
  @Input() label;
  @Input() footerText = '';
  @Input() queryParams = {};

  utils = Utils;

  currentUser;
  list = [];

  public projectJobsSorted: any;

  constructor(
  ) { }

  ngOnInit() {
    this.projectJobsSorted = this.projects;
    Utils.loadingData = false;
  }

}
