import { Asset } from "./asset.model";
import { User } from "./user.model";
import { Briefing } from "./briefing.model";

/**
 * This class defines the structure of projects
 */
export class Project extends Asset {

    /**
     * Id of the project manager of the project
     */
    projectmanager : number;

    /**
     * Id of the owner of the project
     */
    owner : number;

    /**
     * Start date of the project
     */
    startDate : string;

    /**
     * End date of the project
     */
    finishDate : string;

    /**
     * @ignore
     */
    more : string;

    /**
     * Status of the project
     */
    status: string;

    /**
     * True if the projects has a watchlist
     */
    watchlist: boolean;

    /**
     * Array with the ids of the project managers
     */
    projectmanager_rel: number[] = [];

    /**
     * Array with the ids of the owners
     */
    owner_rel: number[] = [];

    /**
     * Id of the owner that it is going to be removed
     */
    removeOwner: number;

    /**
     * Id of the project manager that it is going to be removed
     */
    removeProjectmanager: number;

    /**
     * {@link User} with the data of the owner.
     */
    ownerUser : User;

    /**
     * {@link User} with the data of the project manager.
     */
    pmUser : User;

    /**
     * Briefings type {@link Briefing} (briefing.model)
     */
    briefings : Array<Briefing>;
}