import { Component, Inject, ViewChild, ElementRef, QueryList, ViewChildren, HostListener, DoCheck, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AppSettings } from 'app/shared/app.settings';
import Utils from 'app/shared/utils/utils';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-compare-dialog',
  templateUrl: './compare-dialog.component.html',
  styleUrls: ['./compare-dialog.component.scss']
})
export class CompareDialogComponent implements DoCheck, AfterViewInit {

  /**
   * Static Math
   */
  Math = Math;

  /**
   * @ignore
   */
  @ViewChild('pagePag', { static: false }) pagPaginator: MatPaginator;
  /**
   * @ignore
   */
  @ViewChild('imageWrapper', { static: false }) imageWrapper: DragScrollComponent;
  /**
   * @ignore
   */
  @ViewChild('image', { static: false }) image: ElementRef;

  /**
   * @ignore
   */
  @ViewChildren('imageWrapper') imageWrappers: QueryList<DragScrollComponent>;

  /* Public */
  /**
   * Histories
   */
  histories = [];
  /**
   * Image preview
   */
  imagePreview;
  /**
   * @ignore
   */
  called = false;
  /**
   * Array of images loading
   */
  loadingImage = [];
  
  /**
   * Multipage types
   */
  multipageTypes = ['pdf.', 'presentation.', 'office.', 'layout.', 'spreadsheet.'];
  /**
   * If true, it indicates that it is multipage
   */
  isMultipage: boolean = false;

  /**
   * Zoom
   */
  zoom = 1;
  /**
   * Max zoom
   */
  maxZoom = 10;
  /**
   * Min zoom
   */
  minZoom = 1;

  /* Private */
  /**
   * Scroll left
   */
  private scrollLeft = 0;
  /**
   * Scroll top
   */
  private scrollTop = 0;

  /**
   * Constructor
   * @param dialogRef Service with the functions related to the dialogRef.
   * @param data data for use in the component
   */
  constructor(
    private dialogRef: MatDialogRef<CompareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.histories = data.histories;
    if (this.histories && this.histories.length > 0 && this.multipageTypes.includes(this.histories[0].type)) {
      this.isMultipage = true;
    }
    this.imagePreview = data.image;
    
    dialogRef.disableClose = true;

  }

  /**
   * Close to dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Define scroll and zoom
   */
  ngDoCheck() {
    if (this.imageWrappers) {
      /* console.log(this.imageWrappers);
      this.imageWrappers.forEach(x => {
        console.log(x._contentRef.nativeElement.scrollLeft, x._contentRef.nativeElement.scrollTop);
      })*/

      const scrolled = this.imageWrappers.find(x => x._contentRef.nativeElement.scrollLeft != this.scrollLeft || x._contentRef.nativeElement.scrollTop != this.scrollTop);

      if (scrolled) {
        this.scrollLeft = scrolled._contentRef.nativeElement.scrollLeft;
        this.scrollTop = scrolled._contentRef.nativeElement.scrollTop;

        this.imageWrappers.forEach(x => {
          x._contentRef.nativeElement.scrollLeft = this.scrollLeft;
          x._contentRef.nativeElement.scrollTop = this.scrollTop;
        });
      }
    }

    if (!this.called && this.image && this.image.nativeElement && this.image.nativeElement.offsetWidth > 0) {
      this.fillToScreen();
      this.minZoom = this.zoom;
      if (this.zoom > 1) {
        this.zoom = 1;
      }
    }
  }

  /**
   * After starting the component, fill the screen and set the zoom and paginator.
   */
  ngAfterViewInit() {
    this.fillToScreen();
    this.minZoom = this.zoom;
    if (this.zoom > 1) {
      this.zoom = 1;
    }

    if (this.pagPaginator) {
      this.pagPaginator.page.subscribe(event => {

        if (event.previousPageIndex > event.pageIndex) {
          this.loadingImage['prev'] = true;
        } else if (event.previousPageIndex < event.pageIndex) {
          this.loadingImage['next'] = true;
        }

        this.loadingImage['preview'] = true;
      });
    }
  }

  /**
   * Fill to screen
   */
  fillToScreen() {
    if (this.image.nativeElement.offsetWidth > 0) {
      if (this.imageWrapper._contentRef.nativeElement.offsetWidth <= this.image.nativeElement.offsetWidth) {
        while (this.imageWrapper._contentRef.nativeElement.offsetWidth < this.image.nativeElement.offsetWidth) {
          this.zoom -= 0.05;
          this.image.nativeElement.style.height = 100 * this.zoom + '%';
        }

        this.zoom = Math.round(this.zoom * 10) / 10;
        this.image.nativeElement.style.height = 100 * this.zoom + '%';
      } else {
        while (this.imageWrapper._contentRef.nativeElement.offsetWidth > this.image.nativeElement.offsetWidth) {
          this.zoom += 0.05;
          this.image.nativeElement.style.height = 100 * this.zoom + '%';
        }

        this.zoom = Math.round(this.zoom * 10) / 10;
        this.image.nativeElement.style.height = '100%';
      }
      this.called = true;
    }
  }

  /**
   * Get index
   * @returns Page index
   */
  getIndex() {
    return this.pagPaginator ? this.pagPaginator.pageIndex : 0;
  };

  /**
   * Set index
   * @param history History 
   * @param page Page
   */
  setIndex(history, page) {
    this.pagPaginator.pageIndex = history.preview.findIndex(x => x == page);
  }

  /**
   * Go to preivous page
   */
  leftArrowClick() {
    if (this.getIndex() > 0) {
      this.pagPaginator.pageIndex -= 1;
    }
  }

  /**
   * Go to next page
   * @param history History
   */
  rightArrowClick(history) {
    if (this.histories[0] && history.preview && this.getIndex() < history.preview.length - 1) {
      this.pagPaginator.pageIndex += 1;
    }
  }

  /**
   * Get minimun pages
   * @param history History
   * @returns Min pages
   */
  getMinPage(history) {
    let min = this.getIndex() - (AppSettings.NUMBER_PAGES / 2);
    let max = this.getIndex() + (AppSettings.NUMBER_PAGES / 2);

    if (max >= history.preview.length) {
      min = history.preview.length - AppSettings.NUMBER_PAGES;
    }

    return min;
  }

  /**
   * Get maximum pages
   * @returns Max pages
   */
  getMaxPage() {
    let min = this.getIndex() - (AppSettings.NUMBER_PAGES / 2);
    let max = this.getIndex() + (AppSettings.NUMBER_PAGES / 2);

    if (min < 0) {
      max = AppSettings.NUMBER_PAGES - 1;
    }

    return max;
  }
}
