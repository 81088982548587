import { Injectable, OnDestroy } from '@angular/core';
import { AppSettings } from 'app/shared/app.settings';
import { Asset } from 'app/shared/model/asset.model';
import Utils from 'app/shared/utils/utils';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { HCMSService } from '../satellites/hcms.service';
import * as _ from 'lodash';
import { UserService } from '../user/user.service';

/**
 * This is generic parent service with the basic methods, from which other HCMS services can inherit such as {@link AssetService}
 */
@Injectable({
  providedIn: 'root'
})
export class BaseService<T extends Asset> implements OnDestroy {

  /* Private */

  /**
   * Time to refresh.
   */
  private readonly timeToRefresh = 10000;
  /**
   * Cache expiration time
   */
  private readonly cacheExpire = 86400; // 1day

  /**
   * Assets
   */
  private assets: T[] = [];
  /**
   * @ignore
   */
  private behaviorSubjects: Map<string, BehaviorSubject<any>>;

  /**
   * Last time checked.
   */
  private lastTimeChecked = '1970-01-01T00:00:00Z';
  /**
   * True is stop loading.
   */
  private stopLoading = false;
  /**
   * True if first
   */
  private firstLoading = true;

  private loadingList = false;

  /* Protected */
  /**
   * Entity.
   */
  protected entity = '';
  /**
   * Options.
   */
  protected options = {
    query: '',
    limit: AppSettings.LIMIT,
    offset: 0,
    order: '-createdAttr'
  };

  /* Public */

  /**
   * Total count.
   */
  public totalCount = 0;

  /**
   * Constructor.
   * @param hcmsService Service with the functions related to the censhare.
   */
  constructor(protected hcmsService: HCMSService, protected userService: UserService) {
    this.behaviorSubjects = new Map<string, BehaviorSubject<T | T[]>>();
  }

  /**
   * Destroying the component indicates that loading has stopped and it is unsubscribed.
   */
  ngOnDestroy() {
    this.stopLoading = true;

    this.behaviorSubjects.forEach(s => s.unsubscribe());
    this.behaviorSubjects.clear();
  }

  /* Private methods */

  /**
   * Get hcms assets
   * @param {number} page Page.
   * @param lastTimeChecked Last time checked.
   */
  private getHcmsAssets(page, lastTimeChecked = new Date().toISOString()) {

    this.loadingList = true;

    if (this.firstLoading) {
      Utils.loadingData = true;
    }

    const hcmsServiceObject = this.hcmsService.get().one(this.entity);
    this.options.offset = page;
    this.options.query = this.options.query.replace(/&?\(modifiedAttr.*?".*?"\)?/, '&(modifiedAttr>"' + this.lastTimeChecked + '")');

    hcmsServiceObject.get(this.options)
      .subscribe(data => {

        if (data.result && data.result.length > 0) {

          if (this.firstLoading) {
            this.totalCount = data['total-count'];
          }

          data.result.forEach(result => {

            this.fillData(result);

            if (this.assets.some(ass => ass.id === result.id)) {
              this.assets.splice(this.assets.findIndex(ass => ass.id === result.id), 1);
            } else if (!this.firstLoading) {
              this.totalCount++;
            }

            if (!this.behaviorSubjects.has(result.id.toString())) {
              this.behaviorSubjects.set(result.id.toString(), new BehaviorSubject<T>(result));
            } else {
              this.behaviorSubjects.get(result.id.toString()).next(result);
            }

            result.createdAttrObject = new Date(result.createdAttr);
            this.assets.push(result);

          });

          this.assets = _.sortBy(this.assets, ['createdAttrObject', 'id']).reverse();

          this.behaviorSubjects.get('all').next(this.assets);
        }

        if (!this.stopLoading && data.offset + data.limit < data['total-count']) {

          let offset = this.options.order === '-createdAttr' ? data.offset - AppSettings.LIMIT : data.offset;
          this.options.order = 'id';
          this.getHcmsAssets(offset + data.limit, lastTimeChecked);

        } else {
          this.lastTimeChecked = lastTimeChecked;

          if (this.firstLoading) {
            this.firstLoading = false;
            Utils.loadingData = false;
          }

          setTimeout(() => {
            this.getHcmsAssets(0);
          }, this.timeToRefresh);
        }

        if (data.result && data.result.length > 0) {
          const currentTime = this.lastTimeChecked;
        }

      });
  }

  /* Protected methods */

  /**
   * @ignore
   */
  protected fillData(asset) { }

  /**
   * @ignore
   */
  protected init() {

    // Modified Param
    let modifiedParam = '';

    if (this.options.query && this.options.query.length > 0) {
      modifiedParam = '&';
    }

    modifiedParam += '(modifiedAttr>"' + this.lastTimeChecked + '")';

    this.options.query += modifiedParam;
  }

  /* Public methods */
  /**
   * Obtain all HCMS Assets
   */
  public getAssets() {
    const key = 'all';

    if (!this.behaviorSubjects.has(key)) {

      let user = this.userService.getCurrentUser(); 

      if (user && user.brand && user.brand.length > 0) {
        user.brand.forEach((brand, index) => {
          this.options.query += index === 0 ? '&(brand=^"' + brand + '"' : '|brand=^"' + brand + '"';

          if (user.brand.length - 1 === index) this.options.query += ')';
        });
      }

      this.behaviorSubjects.set(key, new BehaviorSubject<T[]>([]));
      this.getHcmsAssets(0);
    }

    return this.behaviorSubjects.get(key);
  }

  /**
   * Obtain the specific HCMS Assets
   */
  public getAsset(id, force = false) {

    if (force || !this.loadingList || !this.behaviorSubjects.has(id.toString()) || !this.assets.some(x => x.id === id)) {
      if (!this.behaviorSubjects.has(id.toString())) {
        this.behaviorSubjects.set(id.toString(), new BehaviorSubject<T>({} as T));
      }

      this.hcmsService.get().one(this.entity, id).get().subscribe(data => {
        const asset = data.plain();
        this.fillData(asset);
        asset.createdAttrObject = new Date(asset.createdAttr);

        if (!this.assets) {
          this.assets = [];
        }

        if (this.assets.some(la => la.id === asset.id)) {
          this.assets.filter(la => la.id === asset.id).forEach(oa => {
            oa = Object.assign(oa, asset);
          });
        } else {
          this.assets.push(asset);
        }

        if (this.behaviorSubjects.has('all')) {
          this.behaviorSubjects.get('all').next(this.assets);
        }

        this.behaviorSubjects.get(id.toString()).next(asset);
      });
    }

    return this.behaviorSubjects.get(id.toString());
  }

  public getAssetsByIds(ids, force = false) {
    return combineLatest(ids.map(id => this.getAsset(id, force)));
  }

}
