import { Component, OnInit, Input } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
/**
 * @ignore
 */
@Component({
  selector: 'app-info-line-chart',
  templateUrl: './info-line-chart.component.html',
  styleUrls: ['./info-line-chart.component.scss']
})
export class InfoLineChartComponent implements OnInit {

  @Input() data: any;
  @Input() label: string;

  lineChartLabels: string[] = [];
  // lineChartData: number[] = [];
  lineChartData: ChartDataSets[];

  lineChartType = 'line';
  lineChartLegend = true;
  lineChartOptions = {
    responsive: true,
    legend: {
      position: "bottom"
    }
  };

  public lineChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(255,0,0,0)',
      borderColor: '#e30614',
      borderWidth: 1
    },
    {
      backgroundColor: 'rgba(255,0,0,0)',
      borderColor: '#000000',
      borderWidth: 1
    },
    {
      backgroundColor: 'rgba(255,0,0,0)',
      borderColor: '#4d4d4d',
      borderWidth: 1
    },
    {
      backgroundColor: 'rgba(255,0,0,0)',
      borderColor: '#4d4d4d',
      borderWidth: 1
    },
    {
      backgroundColor: 'rgba(255,0,0,0)',
      borderColor: '#4d4d4d',
      borderWidth: 1
    }
  ];

  constructor(protected translateService: TranslateService) { }

  ngOnInit() {
    if (this.data) {
      this.lineChartLabels = this.data.labels;
      this.lineChartData = this.data.content;
    }
  }

}
