import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'app/shared/services/user/user.service';
import { ProjectsService } from 'app/shared/services/projects/projects.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Action } from 'app/shared/model/action.model';
import { DatePipe } from '@angular/common';
import { ActionsService } from 'app/shared/services/actions/actions.service';
import { BriefingService } from 'app/shared/services/briefing/briefing.service';
import Utils from 'app/shared/utils/utils';
import { MaterialsService } from 'app/shared/services/materials/materials.service';
import { Material } from 'app/shared/model/material.model';
import { MimetypeService } from 'app/shared/services/mimetype/mimetype.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { validateNewActionResponsible } from 'app/shared/utils/app-validators';
import { MomentCustomAdapter } from 'app/shared/utils/MomentCustomAdapter';

/**
 * @ignore
 */
export const DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: {dateInput: 'YYYY-MM-DD', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
};

/**
 * @ignore
 */
@Component({
  selector: 'app-new-action',
  templateUrl: './new-action.component.html',
  styleUrls: ['./new-action.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentCustomAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }}
  ],
})
export class NewActionComponent implements OnInit {

  private WORKFLOW_INITIAL_STEP = 500;
  onDrag = false;
  onDragMat = false;

  form: FormGroup;

  currentUser;
  users;
  pms;
  projects = [];
  currentProject = {};
  files = [];
  briefing = []

  creatingAction = false;

  private DEFAULT_DEADLINE_HOUR = "23";
  private DEFAULT_DEADLINE_MINUTE = "59";
  private DEFAULT_DEADLINE_SECOND = "59";

  constructor(public dialogRef: MatDialogRef<NewActionComponent>, private userService : UserService, private formBuilder : FormBuilder, private projectsService: ProjectsService, public datepipe: DatePipe, private actionsService: ActionsService, private briefingService: BriefingService, private materialsService: MaterialsService, private mimetypeService: MimetypeService) {
    this.currentUser = userService.getCurrentUser();
    this.users = userService.getSelectablesUsers();
    this.pms = userService.getSelectablesUsersByRole(RolesService.PROJECT_MANAGER_ROLE, true);

    projectsService.getProjects().then(async data => {
      let over = false;
      this.projects = [];
      Utils.loadingData = true;
      while (!over) {
        this.projects.push(...data.result);

        if (data.offset + data.limit < data['total-count']) {
          data = await projectsService.getProjects(data.offset + data.limit);
        } else {
          over = true;
          Utils.loadingData = false;
        }
      }
    })

    let project = this.checkCurrentProject();
    this.form = this.formBuilder.group({
      'actionname': [null, Validators.compose([Validators.required])],
      'projectname': [+project, Validators.compose([Validators.required])],
      'pm': [null, Validators.compose([])],
      'owner': [null, Validators.compose([])],
      'deadline': [null, Validators.compose([])],
      'deadlineTime': [null, Validators.compose([])],
      'useProjectTeam': [true, Validators.compose([])]
    },{validator: validateNewActionResponsible('projectname', 'owner')});
   }

   private checkCurrentProject() {
    if (window.location.pathname) {
      if (window.location.pathname.startsWith('/projects/detail/')) {
        let path = window.location.pathname.split('/');
        if (path.length >= 4) {
          return path[3];
        }
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  public dragFilesUpload (event) {
    event.preventDefault();
  }

  public dragBriefingUpload (event) {
    event.preventDefault();
  }

  public deleteFile (i) {
    this.files.splice(i,1);
  }

  public deleteBriefing (i) {
    this.briefing.splice(i,1);
  }

  public showFiles(event) {
    event.preventDefault();

    if (event.dataTransfer != null && event.dataTransfer.files != null && event.dataTransfer.files.length > 0) {
      Array.from(event.dataTransfer.files).forEach(file => this.files.push(file));
    } else if(event.srcElement != null && event.srcElement.files != null && event.srcElement.files.length > 0) {
      Array.from(event.srcElement.files).forEach(file => this.files.push(file));
      event.target.value = '';
    }
  }

  public showBriefing(event) {
    event.preventDefault();

    if (event.dataTransfer != null && event.dataTransfer.files[0] != null) {
      this.briefing.push(event.dataTransfer.files[0]);
    } else if(event.srcElement != null && event.srcElement.files[0] != null) {
      this.briefing.push(event.srcElement.files[0]);
      event.target.value = '';
    }
  }

  public saveData() {
    if (this.form.valid) {
      this.creatingAction = true;
      let action: Action = new Action();

      if (this.form.value.actionname) action.name = this.form.value.actionname;
      if (this.form.value.projectname) action.parents = [this.form.value.projectname];

      if (this.form.value.deadline) {

        let deadline = this.form.value.deadline;

        if (!this.form.value.deadlineTime) {
          deadline.hour(this.DEFAULT_DEADLINE_HOUR);
          deadline.minute(this.DEFAULT_DEADLINE_MINUTE);
        } else {
          let deadlineTokens = this.form.value.deadlineTime.split(':');
          deadline.hour(deadlineTokens[0]);
          deadline.minute(deadlineTokens[1]);
         }

        deadline.second(this.DEFAULT_DEADLINE_SECOND);
        action.deadline = this.datepipe.transform(deadline.toDate(), 'yyyy-MM-ddTHH:mm:ss');
      }

      if (!(this.form.value.useProjectTeam && this.form.value.projectname)) {

        if (this.form.value.pm) {
          action[UserService.PROJECT_MANAGER] = [];
          action[UserService.PROJECT_MANAGER].push(+this.form.value.pm);
        }

        if (this.form.value.owner) {
          action[UserService.OWNER] = [];
          action[UserService.OWNER].push(+this.form.value.owner);
          /*if (!action[UserService.PROJECT_MANAGER]) {
            action[UserService.PROJECT_MANAGER] = [];
            action[UserService.PROJECT_MANAGER].push(+this.form.value.owner);
          }*/
        }
      }

      action.customerdata.forEach(item => item.domain = action.domain);
      action.agencydata.forEach(item => item.domain = action.domain);
      action.finaldata.forEach(item => item.domain = action.domain);

      this.actionsService.saveAction(action).then(result => {
        if (result && this.briefing.length > 0) {
          this.briefingService.createBriefing(this.briefing[0], result).then(data => {
            this.setMaterialsAndFinish(this.files, data.parents[0]);
          })
        } else {
          this.setMaterialsAndFinish(this.files, result.id);
        }

        if (this.form.value.useProjectTeam && this.form.value.projectname && result) {
          this.userService.copyTeam(this.form.value.projectname, result).then(action => {
            this.actionsService.sendNewActionAssinedMail(action);
          });
        }
      });
    }
  }

  private async setMaterialsAndFinish(files: any, parent: any) {
    if (files && files.length > 0) {

      for (let i = 0; i < files.length; i++) {
        let create = await this.createNewMaterial(this.files[i], parent)
      }

    }
    this.finsihProcess();
  }

  private finsihProcess() {
    this.creatingAction = false;
    this.dialogRef.close();

    Utils.reloadData = this.checkCurrentProject() ? +this.checkCurrentProject() : Utils.RELOAD_DATA_ACTIONS_LIST;
  }

  private async createNewMaterial(file: any, parentId: any) {

    let fileFormData = new FormData();

    let type = this.mimetypeService.getFileMimetype(file);
    let newMaterial : Material = new Material();

    newMaterial.name = file.name;

    if (parent) {
      newMaterial.parents.push(parentId);
    }

    newMaterial.type = type.def_assettype;
    newMaterial.downloadLink = "formdata:file0";
    delete newMaterial.created;
    delete newMaterial.selected;
    delete newMaterial.preview;


    newMaterial.workflow = Utils.getWorkflowId(newMaterial.type);
    newMaterial.workflowStep = Utils.getDefaultWorkflowStep(newMaterial.type);

    fileFormData.append("entity", JSON.stringify(newMaterial));
    fileFormData.append("file0", file);

    let result = await this.materialsService.saveMaterial(newMaterial, fileFormData);
  }

}
