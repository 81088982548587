import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Collection } from 'app/shared/model/collection.model';
import { CollectionsService } from 'app/shared/services/collections/collections.service';
import { UserService } from 'app/shared/services/user/user.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { AssetService } from 'app/shared/services/asset/asset.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * @ignore
 */
@Component({
  selector: 'app-share-collection-dialog',
  templateUrl: './shareCollection-dialog.component.html',
  styleUrls: ['./shareCollection-dialog.component.scss']
})
export class ShareCollectionDialogComponent {

  @ViewChild('recipientInput', { static: true }) recipientInput: ElementRef<HTMLInputElement>;

  recipients: any[] = [];
  public collection: Collection;

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  members;
  users;

  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

  constructor(
    private dialogRef: MatDialogRef<ShareCollectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private collectionService: CollectionsService,
    private userService: UserService,
    private assetService: AssetService,
    private translateService: TranslateService) {

    this.users = this.userService.getUsers();

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(null),
        map(value => this._filter(value))
      );

    this.collection = data.collection;
    this.members = this.users.filter(user => this.collection.user.includes(user.id));

    this.dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'send' && this.recipients && this.recipients.length > 0) {

        // Internal user:
        if (this.recipients.some(user => user.allInternal)) {
          this.collectionService.shareCollection(this.collection, this.users.filter(option => !this.members.some(x => x.email === option.email))
          .filter(option => option.brand && this.collection.brands ? this.collection.brands.every(brand => option.brand.includes(brand)) : false));
        } else {
          const internalUsers = this.recipients.filter(user => !user.external);
          if (internalUsers && internalUsers.length > 0) this.collectionService.shareCollection(this.collection, internalUsers);
        }

        // External user: 
        const externalUsers = this.recipients.filter(user => user.external).map(value => value.email);
        if (externalUsers && externalUsers.length > 0) {
          const message = this.translateService.instant('message_collection');
          this.assetService.shareAssets(externalUsers.join(), message, this.collection.assetsObject);
        }
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  remove(recipient): void {
    const index = this.recipients.findIndex(x => x.email === recipient.email);

    if (index >= 0) {
      this.recipients.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value) {
      if (this.users.some(option => (option.display_name && option.display_name.toLowerCase().includes(value)) ||
        (option.email && option.email.toLowerCase().includes(value)))) {

        this.users.filter(option => (option.display_name && option.display_name.toLowerCase().includes(value)) ||
          (option.email && option.email.toLowerCase().includes(value)))
          .forEach(option => {
            this.recipients.push(option);
          });

      } else {
        const externalUser = {
          'email': value,
          'display_name': value,
          'external': true
        }
        this.recipients.push(externalUser);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.myControl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value === 'all') {
      const allUsers = {
        'display_name': 'All Users',
        'allInternal': true
      }
      this.recipients = this.recipients.filter(r => r.external);
      this.recipients.push(allUsers);

    } else {
      this.recipients.push(event.option.value);
    }

    this.recipientInput.nativeElement.value = '';
    this.myControl.setValue(null);
  }

  allUsersSelected() {
    return this.recipients.some(r => r.allInternal);
  }

  private _filter(value) {

    if (value && !value.email) {
      value = (value || '').toLowerCase();

      return this.recipients.some(r => r.allInternal) ? [] : this.users
        .filter(option => !this.recipients.some(y => y.email === option.email))
        .filter(option => !this.members.some(x => x.email === option.email))
        .filter(option => option.brand && this.collection.brands ? this.collection.brands.every(brand => option.brand.includes(brand)) : false)
        .filter(option => (option.display_name && option.display_name.toLowerCase().includes(value)) ||
          (option.email && option.email.toLowerCase().includes(value)));
    }
  }

}
