import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/shared/services/user/user.service';
import Utils from 'app/shared/utils/utils';

/**
 * @ignore
 */
@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {

  @Input() width;
  @Input() title;
  @Input() moreActions;
  @Input() menu;
  @Input() footer;
  @Input() footerText;
  @Input() footerLink;
  @Input() queryParams;
  @Input() double;
  @Input() bubble;
  @Input() isPowerUser;
  @Input() showFooterMessage = false;
  @Input() mainManager;
  @Input() dashboard = false;
  @Output() sortOption = new EventEmitter<number>();
  @Output() editMode = new EventEmitter<boolean>();
  @Output() saveMode = new EventEmitter<boolean>();

  editing = false;
  saving = false;

  permissions: any = {};
  message : string = '';

  constructor(public dialog: MatDialog,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.permissions = this.userService.getPermissions('widgets');
  }

  ngDoCheck() {
    if ((Utils.reloadData === Utils.RELOAD_DATA_INFOCENTER_WIDGET && this.menu === 'infoCenter') ||
        (Utils.reloadData === Utils.RELOAD_DATA_BUDGET_WIDGET && this.menu === 'budget')|| 
        (Utils.reloadData === Utils.RELOAD_DATA_ALL_BUDGETS_WIDGET && this.menu === 'allBudgets')) {
      Utils.updatingWidget = false;
      this.editing = false;
      this.saving = false;
      this.editMode.emit(false);
      Utils.reloadData = Utils.RELOAD_DATA_NONE;
    }
  }

  emitNewSorting(option) {
    this.sortOption.emit(option);
  }

  edit() {
    this.editing = true;
    this.editMode.emit(this.editing);
  }

  cancel() {
    this.editing = false;
    this.editMode.emit(this.editing);
  }

  save() {
    this.saving = true;
    this.saveMode.emit(true);
  }

  saved() {
    this.saving = false;
    this.editing = false;
  }

  navigateTo() {
    if (this.dashboard || !this.showFooterMessage || (this.showFooterMessage && this.mainManager)) {
      if (this.footerLink.includes('.')) {
        this.router.navigate([this.footerLink], {queryParams: this.queryParams, relativeTo: this.route});
      } else {
        this.router.navigate([this.footerLink], {queryParams: this.queryParams});
      }
    } else {
      this.message = 'main_manager_needed';
    }
  }

}