import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UserService } from 'app/shared/services/user/user.service';
import { RolesService } from 'app/shared/services/user/roles.service';
import { MailService } from 'app/shared/services/mail/mail.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirmation/confirmation-dialog.component';

/**
 * @ignore
 */
@Component({
  selector: 'app-quick-team',
  templateUrl: './quick-team.component.html',
  styleUrls: ['./quick-team.component.scss']
})
export class QuickTeamComponent implements OnInit {

  @Input() team;
  @Input() assetId;
  @Output() updatedTeam = new EventEmitter<any>();

  currentUser

  teamSize = 0;
  teamLimit = UserService.RELATIONS_LIMIT;

  owner;
  pm;
  restTeam;
  addMember;
  isManager = false;
  isPowerUser = false;
  isProduction = false;
  adding = false;

  constructor(private usersService:UserService,
    private rolesService:RolesService,
    private mailService: MailService,
    private dialog: MatDialog){}

  ngOnInit() {
    this.resetMember();
    this.currentUser = this.usersService.getCurrentUser();
    this.isManager = this.rolesService.checkRole(this.currentUser, RolesService.PROJECT_MANAGER_ROLE, true);
    this.isPowerUser = this.rolesService.checkRole(this.currentUser, RolesService.POWER_ROLE);
    this.isProduction = this.rolesService.checkRole(this.currentUser, RolesService.PRODUCTION_ROLE);

    this.refreshTeam(this.team);
  }

  refreshTeam(team) {
    this.owner = null;
    this.pm = null;
    this.team = team;
    let teams = this.usersService.getTeams(team);
    let flatTeam = [];
    teams.forEach( (value, key) => {
      if (key != UserService.OWNER && key != UserService.PROJECT_MANAGER && value.length > 0) {
        value.forEach(val => {
          flatTeam.push({
            key: key,
            value: val
          });
        });
      }

      if (key == UserService.OWNER && value.length > 0) {
        this.owner = value[0];
        this.owner.role = UserService.OWNER;
      }
      if (key == UserService.PROJECT_MANAGER && value.length > 0) {
        this.pm = value[0];
        this.pm.role = UserService.PROJECT_MANAGER;
      }

    });

    this.restTeam = flatTeam;
    this.teamSize = 0;
    teams.forEach((value, key) => {
      if (value.length > 0) {
        this.teamSize += value.length;
      }
    });

    this.addMember.update = true;
  }

  getAddMemberUsers () {
    return this.usersService.getSelectablesUsers();
  }

  getAddMemberRoles () {
    let roles = this.usersService.getFilteredMemberRoles (this.addMember.person, this.owner, this.pm);
    roles = roles.filter(role => role.key == UserService.OWNER || role.key == UserService.PROJECT_MANAGER || role.key == UserService.PRODUCTION || role.key == UserService.CO_MANAGER || role.key == UserService.CO_OWNER);
    return roles;
  }

  canEdit () {
    return  this.usersService.canEditTeam(this.currentUser, this.team);
  }

  addNewMember() {
    this.adding = true;

    if (this.isManager) {
      if (this.pm) {
        this.addMember.role = UserService.CO_MANAGER;
      } else {
        this.addMember.role = UserService.PROJECT_MANAGER;
      }
    } else if (this.isPowerUser) {
      if (this.owner) {
        this.addMember.role = UserService.CO_OWNER;
      } else {
        this.addMember.role = UserService.OWNER;
      }
    } else {
      this.addMember.role = UserService.PRODUCTION;
    }

    if (this.addMember.person) {
      this.usersService.saveNewUserInTeam(this.team, this.addMember).then(result => {
        if (this.addMember.role == UserService.PROJECT_MANAGER) {
          this.mailService.sendProjectManagerMail(this.assetId, this.addMember.person);
        }

        this.updatedTeam.emit(result.plain());
        this.adding = false;
        this.resetMember();
      });
    } else {
      this.adding = false;
    }
  }

  // AddMember
  nextStep () {
    if (this.addMember.next) {
      this.addMember.step = this.addMember.step + 1;
      this.addMember.next = false;
    }

    if (this.addMember.step > 3) {
      this.usersService.saveNewUserInTeam(this.team, this.addMember).then(result => {
        if (this.addMember.role == UserService.PROJECT_MANAGER) {
          this.mailService.sendProjectManagerMail(this.assetId, this.addMember.person);
        }

        this.updatedTeam.emit(result.plain());
        this.resetMember();
      });
    }

  }

  resetMember() {
    this.addMember = {
      editing: false,
      step: 2,
      //step: 1,
      type: 1,
      person: -1,
      role: '',
      next: false,
      update: true
    }
  }

  openConfirmationRemoveDialog(item, role) {

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'removeMember'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        item.remove = true;
        this.usersService.removeUserFromTeam(this.team, role, item.id).then(result => {
          this.updatedTeam.emit(result.plain());
          item.remove = false;
        });
      }
      dialogRef = null;
    });
  }

}
