import { FormGroup, FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validator to check a valid email
 * @param control The email to check
 * @returns An object with 'invalidEmail' property sets to true
 */
export function emailValidator(control: FormControl): {[key: string]: any} {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return {invalidEmail: true};
    }
}

/**
 * Validator to check project and owner
 * @param projectKey The function to get the project
 * @param ownerKey The function to get the owner
 * @returns An error if there are no project and no owner
 */
export function validateNewActionResponsible(projectKey: string, ownerKey: string) {
    return (group: FormGroup) => {
        let project= group.controls[projectKey];
        let owner= group.controls[ownerKey];

        if (!project.value && !owner.value)
            return owner.setErrors({requiredEmptyProject: true})
    }
}

/**
 * Validator to check action and owner
 * @param actionKey The function to get the action
 * @param ownerKey The function to get the owner
 * @returns An error if there are no action and no owner
 */
export function validateNewTaskResponsible(actionKey: string, ownerKey: string) {
    return (group: FormGroup) => {
        let action= group.controls[actionKey];
        let owner= group.controls[ownerKey];

        if (!action.value && !owner.value)
            return owner.setErrors({requiredEmptyAction: true})
    }
}

/**
 * Validator to check if the passwords match
 * @param passwordKey The password
 * @param passwordConfirmationKey The confirmation password
 * @returns An error if the password and the confirmation password are not equals
 */
export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password= group.controls[passwordKey];
        let passwordConfirmation= group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({mismatchedPasswords: true})
        }
    }
}

export function matchingEmails(email: string, repeatedEmail: string) {
    return (group: FormGroup) => {
        let emailData = group.controls[email];
        let repeatedEmailData = group.controls[repeatedEmail];
        if (emailData.value !== repeatedEmailData.value) {
            return repeatedEmailData.setErrors({ notRepeatedEmail: true })
        }
    }
}

/**
 * Validator to test a regular expression
 * @param regex The regular expression
 * @param error The error to return if the value don't match the regular expression
 * @returns null if there is no value. The error if the value don't match the regular expression
 */
export function patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            return null;
        }

        const valid = regex.test(control.value);

        return valid ? null : error;
    };
}

export function valueInList(list: any[], error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const val = control.value;

        return !val || list.some(x => x.value_key === val) ? null : error;
        // return 'Do stuff with the value because `input` and `categoryList` are now in scope!'
    }
}

/**
 * Validator to check derivable
 * @param actionKey The function to get the action
 * @param ownerKey The function to get the owner
 * @returns An error if there are no action and no owner
 */
 export function validateDeli(error: ValidationErrors) {
    return (group: FormGroup) => {
        console.log(group);
        return error;
    }
}
