import { Router } from "@angular/router";
import { OnInit, Input } from "@angular/core";
import Utils from "../utils/utils";
import { UserService } from "../services/user/user.service";
import { LiquidCacheService } from "ngx-liquid-cache";

/**
 * This component defines the functions to get and store in the cache.
 */
export abstract class SaveStateComponent implements OnInit {

    /**
     * @ignore
     */
    selectedIndex = 0;

    /**
     * The index to create the key
     */
    @Input() indexKey = "default";

    /**
     * Array to store the items
     */
    private stored = [];

    /**
     * Array to session store the items
     */
    private sessionStored = [];

    /**
     * Key to retrieve the stored item
     */
    private key: string = "";

    /**
     * The separator used in the key
     */
    private SEPARATOR: string = '##';

    /**
     * The constructor
     * @param cache {@link LiquidCacheService} cache service.
     * @param router {@link Router} router service.
     * @param usersService {@link UserService} with the functions related to the users.
     */
    constructor(protected cache: LiquidCacheService, protected router: Router, protected usersService: UserService) {
        this.usersService.checkLastUser();
    }

    /**
     * Adds a new item in the stored array
     * @param item, the item to store
     * @param name, the key
     */
    protected addItem(item, name, isSession = false) {

        const storedObject = isSession ? this.sessionStored : this.stored;

        if (item != null) {
            if (item !== Object(item)) {
                storedObject[name] = item;
            } else {
                storedObject[name] = Object.assign([], JSON.parse(JSON.stringify(item)));
            }
        }

    }

    /**
     * Initialize the component and retrieve all the stored items.
     */
    ngOnInit() {
        this.key = this.router.url.split('?')[0] + this.SEPARATOR + this.indexKey + this.SEPARATOR;

        [...Object.keys(this.cache.cachedElements), ...Object.keys(sessionStorage)].filter(x => x.startsWith(this.key)).forEach(x => {

            let valueSt = this.cache.get(x) ? this.cache.get(x) : sessionStorage.getItem(x);
            let valueName = x.split(this.SEPARATOR)[2];


            if (valueName) {

                if (valueSt !== Object(valueSt)) {
                    this[valueName] = valueSt;
                } else {
                    if (!this[valueName]) {
                        this[valueName] = {};
                    }
                    this[valueName] = Object.assign(this[valueName], valueSt);
                }
            }


        });

        //this.addItem(this.selectedIndex, 'selectedIndex');
    }

    /**
     * Updates the cache and the stored array if the item changes
     */
    ngDoCheck() {

        Object.keys(this.stored).forEach(element => {

            if (this[element] !== Object(this[element])) {

                if (!Utils.equal(this[element], this.stored[element])) {

                    this.stored[element] = this[element];
                    this.cache.set(this.key + element, this[element]);
                }

            } else {
                if (!Utils.equal(Object.assign([], JSON.parse(JSON.stringify(this[element]))), this.stored[element])) {

                    this.stored[element] = Object.assign([], JSON.parse(JSON.stringify(this[element])));
                    this.cache.set(this.key + element, this[element]);
                }
            }
        });

        Object.keys(this.sessionStored).forEach(element => {

            if (this[element] !== Object(this[element])) {

                if (!Utils.equal(this[element], this.sessionStored[element])) {

                    this.sessionStored[element] = this[element];
                    sessionStorage.setItem(this.key + element, this[element]);
                   
                }

            } else {
                if (!Utils.equal(Object.assign([], JSON.parse(JSON.stringify(this[element]))), this.sessionStored[element])) {

                    this.sessionStored[element] = Object.assign([], JSON.parse(JSON.stringify(this[element])));
                    sessionStorage.setItem(this.key + element, this[element]);
                }
            }
        });
    }

}